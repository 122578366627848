import { Grid, Stack, Container, Card, AppBar, Tabs, Tab, Divider, Rating } from "@mui/material";
import Avatar from "components/Avatar";
import Box from "components/Box";
import Button from "components/Button";
import StarRating from "components/Rating/StarRating";
import Typography from "components/Typography";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";

export default function Body(props) {
    const { user, avatar } = props;
    return (
        <Box >
            <Container>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}>
                    <Card
                        sx={{
                            position: "relative",
                            mt: -6.5,
                            mx: 3,
                            py: 2,
                            px: 2,
                            width: "100%",
                            opacity: "100%"
                        }}
                    >
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid item xs="auto" sx={{ margin: "auto" }}>
                                <Stack direction="row" spacing={3}
                                    alignItems="center" >
                                    <Box sx={{ position: "relative" }}>
                                        <Avatar src={avatar} alt="profile-image" size="xl" shadow="sm" />
                                    </Box>
                                    <Stack direction="column">
                                        <Box alignItems="center" >
                                            <Typography sx={{ fontSize: "18px", color: "primary", fontWeight: "bold" }} >{user?.name}</Typography>

                                        </Box>
                                        <Box alignItems="center" >
                                            <StarRating rating={3} color="primary" size="medium" />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs="auto" sx={{ margin: "auto" }}>
                                <Stack direction="row" spacing={3} alignItems="center">
                                    <Box sx={{ textAlign: "center" }}>
                                        <Typography>5</Typography>
                                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>Certifications</Typography>
                                    </Box>
                                    <Divider orientation="vertical">{" "}</Divider>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Typography>200</Typography>
                                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>Miles</Typography>
                                    </Box>

                                    <Divider orientation="vertical">{" "}</Divider>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Typography>15</Typography>
                                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>Landings</Typography>
                                    </Box>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Button variant="outlined" color="dark" >Follow</Button>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Card>
                </Stack>
                {/* <Box p={3} >
                    <Grid container spacing={2}  >
                        <Grid item container xs={12} md={6}  >
                            <Grid item xs={12} mb={1}>
                                 <Box sx={{ bgcolor: "whitesmoke" }} p={1} >
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="h6">ABOUT ME</Typography>
                                        <Typography variant="h6">Web Designer</Typography>
                                    </Stack>
                                    <Typography sx={{ fontSize: "14px" }} >23 Dec 1991</Typography>
                                    <Typography sx={{ fontSize: "12px" }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ bgcolor: "whitesmoke" }} p={1} mb={1}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="h6">ABOUT ME</Typography>
                                        <Typography variant="h6">Web Designer</Typography>
                                    </Stack>
                                    <Typography sx={{ fontSize: "14px" }} >23 Dec 1991</Typography>
                                    <Typography sx={{ fontSize: "12px" }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ bgcolor: "whitesmoke" }} p={1} mb={1}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="h6">ABOUT ME</Typography>
                                        <Typography variant="h6">Web Designer</Typography>
                                    </Stack>
                                    <Typography sx={{ fontSize: "14px" }} >23 Dec 1991</Typography>
                                    <Typography sx={{ fontSize: "12px" }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6} item >
                            <Stack spacing={1}>
                                <Box sx={{ bgcolor: "whitesmoke" }} p={1} >
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="h6">ABOUT ME</Typography>
                                        <Typography variant="h6">Web Designer</Typography>
                                    </Stack>
                                    <Typography sx={{ fontSize: "14px" }} >23 Dec 1991</Typography>
                                    <Typography sx={{ fontSize: "12px" }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, </Typography>
                                </Box>
                                <Box sx={{ bgcolor: "whitesmoke" }} p={1} >
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="h6">ABOUT ME</Typography>
                                        <Typography variant="h6">Web Designer</Typography>
                                    </Stack>
                                </Box>
                                <Box sx={{ bgcolor: "whitesmoke" }} p={1} mb={1}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="h6">ABOUT ME</Typography>
                                        <Typography variant="h6">Web Designer</Typography>
                                    </Stack>
                                    <Typography sx={{ fontSize: "14px" }} >23 Dec 1991</Typography>
                                    <Typography sx={{ fontSize: "12px" }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>*/}
                {
                    <Box mt={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <DefaultStatisticsCard
                                    title="Flight Hours"
                                    count="102 Hours"
                                    percentage={{
                                        color: "secondary",
                                        value: "",
                                        label: "",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DefaultStatisticsCard
                                    title="Solo "
                                    count="87 Hours"
                                    percentage={{
                                        color: "secondary",
                                        value: "85,29%",
                                        label: "of total amount",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DefaultStatisticsCard
                                    title="Dual"
                                    count="25 Hours"
                                    percentage={{
                                        color: "secondary",
                                        value: "14,71%",
                                        label: "of total amount",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DefaultStatisticsCard
                                    title="VFR Hours"
                                    count="102 Hours"
                                    percentage={{
                                        color: "secondary",
                                        value: "100%",
                                        label: "of total amount",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DefaultStatisticsCard
                                    title="NVFR Hours"
                                    count="0 Hours"
                                    percentage={{
                                        color: "secondary",
                                        value: "0%",
                                        label: "of total amount",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DefaultStatisticsCard
                                    title="IFR"
                                    count="0 Hours"
                                    percentage={{
                                        color: "secondary",
                                        value: "0%",
                                        label: "of total amount",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Container >
        </Box >
    )
}