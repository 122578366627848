import React from 'react'
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { Grid, Stack, FormGroup, FormControlLabel, Checkbox, AppBar, Tab, Tabs } from "@mui/material";
import ReactMarkdown from "react-markdown";
import Box from "components/Box";
import Input from "components/Input";
import { restGet } from "lib/rest";
import _ from "lodash";
import { restPost } from 'lib/rest';
import { useRefresher } from 'hooks/refreshHook';
import Cropper from 'react-easy-crop'
import { getBlobImageUrl } from "lib/functions";
import { getCroppedImg } from "lib/image";
import { useModal } from 'hooks/modalHook';

const CropmodalCircle = ({ onValueChange, img }) => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedArea, setCroppedArea] = useState(null);
    const [aircraftPreviewImage, setAircraftPreviewImage] = useState();
    const [data, setData] = useState();
    const [Allow, setAllow] = useState(true);


    const onCropComplete = useCallback((croppedArea, areaPixels) => {
        setCroppedArea(croppedArea)
        setCroppedAreaPixels(areaPixels)
    }, [])

    useEffect(() => {
        setAircraftPreviewImage(img)
    }, [])


    const handleClick = async () => {
        let blob = await fetch(data).then(r => r.blob()).then(blobFile => new File([blobFile], "editedimage", { type: "image/png" }))
        let res = await restPost(null, `identity/Avatar/Image`, blob, null, { "Content-Type": blob.type });
        onValueChange(data);

    };

    useEffect(() => {
        setAllow(false)
        const delayDebounceFn = setTimeout(() => {
            (async () => {
                var imageData = {
                    aircraftImage: aircraftPreviewImage,
                    croppedAreaPixels: croppedAreaPixels
                }
                let previewImage = await getCroppedImg(
                    imageData.aircraftImage,
                    imageData.croppedAreaPixels
                )
                setData(previewImage)
            })();
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [aircraftPreviewImage, croppedAreaPixels]);

    useEffect(() => {
        setAllow(true)

    }, [data]);


    const { refresh } = useRefresher();



    // setter auslagern in newsletter
    return (
        <DefaultModal
            title={"Crop Image"}
            confirm={{
                text: t("common:button.confirm"),
                color: "success",
                show: true,
                disabled: !Allow,
                action: () => { handleClick(); }

            }}>

            <Box sx={{
                "position": "relative",
                "width": "auto",
                "height": "512px"
            }}>
                <Box p={1} sx={{
                    "position": "absolute",
                    "top": "0px",
                    "color": "#ffff",
                    "zIndex": "100",

                }}>
                    Scroll to Zoom <br></br>
                    Drag to Reposition

                </Box>
                <Cropper
                    zoomSpeed={0.25}
                    minZoom={0.5}
                    image={aircraftPreviewImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    objectFit="horizontal-cover"
                    cropShape='round'
                />
            </Box>


        </DefaultModal>
    )
}

export default CropmodalCircle