import { useState, useEffect } from "react"
import { restGet } from "lib/rest";
import { Grid } from "@mui/material";
import { t } from "i18next";
import Typography from "components/Typography";
import LegalEntityCard from "./LegalEntityCard";

function Offices() {
    const [legalEntities, setlegalEntities] = useState()
    const [pageLang, setPageLang] = useState('en')

    useEffect(() => {
        restGet(setlegalEntities, 'dataset/legalEntity');
    }, [])

    return (
        <Grid container alignItems="top">

            <Grid item xs={12}>
                <Typography variant="h3">
                    {t("common:label.offices")}
                </Typography>
            </Grid>

            {legalEntities?.map((entity, i) => (
                <LegalEntityCard
                    key={i}
                    entity={entity}
                    pageLang={pageLang}
                />
            ))}
        </Grid>
    );
}

export default Offices;