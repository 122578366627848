import { Skeleton } from "@mui/material";
import { minutesToMilliseconds } from "lib/time";
import { hoursToMilliseconds } from "lib/time";
import { NumericFormat } from "react-number-format";

function OpsCounterHoursMinutes({ customInput, defaultValue, onChange }) {
    if (defaultValue !== null && defaultValue !== undefined) {
        return (
            <NumericFormat
                defaultValue={defaultValue}
                decimalScale={2}
                fixedDecimalScale
                decimalSeparator=":"
                customInput={customInput}
                onValueChange={(values) => {
                    const { floatValue } = values;
                    let hours = Math.round(floatValue)
                    let minutes = Math.round((floatValue % 1) * 100)
                    let milliseconds = hoursToMilliseconds(hours) + minutesToMilliseconds(minutes)
                    onChange(milliseconds)
                }}
                isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue % 1 < 0.6;
                }}
            />
        )
    }
    return <Skeleton variant="rounded" height={56} />
}

export default OpsCounterHoursMinutes;