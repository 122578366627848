import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";

// Images
const bgImage = "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_226419158sm.jpeg"

export default function Header() {
    return (<Box
        display="flex"
        alignItems="center"
        height="256px"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
        <Container>
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                mx="auto"
            >
            </Grid>
        </Container>
    </Box>)
}