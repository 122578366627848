import { Divider, Grid } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import KnowledgeCard from "components/Cards/InfoCards/KnowledgeCard";
import ComingSoon from "components/Common/ComingSoon";
import Input from "components/Input";
import Container from "@mui/material/Container";
import Typography from "components/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FaqSection from "./sections/FaqSection";

export default function Body() {
    const { t } = useTranslation();
    const [active, setActive] = useState(null);

    const faq = t("FAQPage:cards", { returnObjects: true })
    return (
        <Box py={12} px={{ xs: 3, lg: 0 }}>
            <Grid container spacing={3}>
                {/* <Grid item xs={12} sm={6} ml="auto">
                    <Typography variant="h4" mb={1}>
                        {t("FAQPage:searchbar_section.title")}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <Input label={t("FAQPage:searchbar_section.searchbar_placeholder")} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button variant="gradient" color="info" sx={{ height: "100%" }}>
                                {t("common:button.search")}
                            </Button>
                        </Grid>
                    </Grid> 
                </Grid> */}
                <Grid item xs={12} sm={6} ml="auto">
                    <Typography variant="h2" mb={1}>
                        {t("FAQPage:searchbar_section.title")}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} position="relative">
                    <Box
                        component="img"
                        src={"https://aeromatewesteurope.blob.core.windows.net/aeromate/other/control-tower-ga782ea830_640.jpg"}
                        alt="image"
                        maxWidth="18.75rem"
                        width="100%"
                        borderRadius="lg"
                        shadow="xl"
                        mt={-24}
                        display={{ xs: "none", md: "block" }}
                    />
                </Grid>
            </Grid>
            <Box p={3} />
            <Box component="section" py={12}>
                <Container>
                    <Grid container spacing={3} >
                        {faq.map((card, index) =>
                            <>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <KnowledgeCard
                                        title={card.title}
                                        description={card.subtitle}
                                        icon={card.icon}
                                        active={active === index}
                                        onClick={() => setActive(index === active ? null : index)}
                                    />
                                </Grid>
                                <Grid item xs={12}
                                    display={{
                                        xs: active !== null && index === active ? "block" : "none",
                                        sm: active !== null && Math.ceil((index + 1) / 2) === Math.ceil((active + 1) / 2) && ((index + 1) % 2 === 0 || (faq.length - 1 === index)) ? "block" : "none",
                                        md: active !== null && Math.ceil((index + 1) / 3) === Math.ceil((active + 1) / 3) && ((index + 1) % 3 === 0 || (faq.length - 1 === index)) ? "block" : "none",
                                        lg: active !== null && Math.ceil((index + 1) / 4) === Math.ceil((active + 1) / 4) && ((index + 1) % 4 === 0 || (faq.length - 1 === index)) ? "block" : "none",
                                    }}>
                                    {active !== null && <FaqSection faq={faq[active].faq} />}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Container>
            </Box>
        </Box >
    )
}