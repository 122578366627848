import { Container, Grid } from "@mui/material";
import Button from "components/Button";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation, Trans } from "react-i18next";
import { restGet } from "lib/rest";
import { useState, useEffect } from "react"
import { staticcdnurl } from "constants/global";
import { convertTimeDatabaseToFrontend } from "lib/functions";
import moment from "moment";


function LegalDocViewer(props) {
    const { docType, showPDF = true } = props;
    const { t } = useTranslation();
    const [docData, setDocData] = useState()
    const [legalData, setLegalData] = useState()
    const [pageLang, setPageLang] = useState('en')

    useEffect(() => {
        restGet(setLegalData, 'dataset/legal');
    }, [])

    useEffect(() => {
        !!legalData && !!legalData.legal_documents && setDocData(legalData.legal_documents.find(({ type }) => type === docType))
    }, [legalData])

    return (
        <Box component="section" py={{ xs: 3 }} id="info">
            <Container>
                <Grid container alignItems="top">
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            {!!docData && docData.name[pageLang]}
                        </Typography>
                        <Typography pl={1} variant="subtitle">
                            Version {!!docData && docData.version}, {t("common:label.effective")} {!!docData && moment(convertTimeDatabaseToFrontend(docData.effective)).format("LLL")} - {t("common:label.newversion")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={(showPDF) ? 4 : 12}>
                        {!!docData &&
                            <Box pt={3}>
                                <Typography pr={1} pt={1} variant="body2">
                                    <Trans
                                        components={{ a: <a /> }}
                                        i18nKey={"common:label.pdfreader"}
                                    />
                                </Typography>
                                <Typography pr={1} pt={1} variant="body2">
                                    {t("common:label.legaldisclaim")}
                                </Typography>

                                <Box p={2}>
                                    <Button
                                        variant="gradient"
                                        color="dark"
                                        onClick={() => window.open(staticcdnurl + docData.filename, '_blank')}
                                    >
                                        Download {!!docData && docData.name[pageLang]}
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Grid>

                    <Grid item xs={12} md={8}>
                        {!!docData && !!showPDF &&
                            <Box p={2}>
                                <iframe
                                    src={staticcdnurl + docData.filename}
                                    width={'100%'}
                                    height={900}
                                >
                                </iframe>
                            </Box>
                        }
                    </Grid>

                </Grid>
            </Container>
        </Box >
    );
}

export default LegalDocViewer