import { Grid, Stack, Stepper, Step, StepLabel, Skeleton } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import DefaultNextStepCard from "components/Cards/NextStepsCards/DefaultNextStepCard";
import LoadingModal from "components/Modal/LoadingModal";
import AeroLoading from "components/Progress/AeroLoading";
import { useModal } from "hooks/modalHook";
import { restPost, restGet } from "lib/rest";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckrideEditorItem from "./CheckrideEditorItem";
import { v4 as uuidv4 } from 'uuid';
import Checklist from "components/Checklist";
import { useParams } from "react-router-dom";
import TypographyBlock from "components/Typography/TypographyBlock";

export default function CheckrideChecklist() {
    const { t } = useTranslation();
    const { reg } = useParams();
    const { addModal, removeModal } = useModal();
    const [initItems, setInitItems] = useState();
    const [checklist, setChecklist] = useState();
    const [checklistID, setChecklistID] = useState();

    const handleSave = useCallback(async () => {
        let body = {
            reg: reg,
            type: "checkride",
            checklist: checklist.map((item) => item.data)
        }
        addModal(<LoadingModal />)
        await restPost(null, `checklist/${checklistID ? checklistID : ""}`, body);
        removeModal()
    }, [checklist, reg])

    const content = [
        {
            id: "select",
            label: t("dashboard:charter.aircrafts.checkride.select"),
            component: <Selection callback={(e) => { setInitItems(e); setActiveStep(activeStep + 1) }} setChecklistID={setChecklistID} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return true; }
        },
        {
            id: "edit",
            label: t("dashboard:charter.aircrafts.checkride.edit"),
            component:
                <Box mx={8} >
                    <TypographyBlock
                        title={t("dashboard:charter.aircrafts.checkride.title")}
                        description={t("dashboard:charter.aircrafts.checkride.description")}
                        action={handleSave}
                        actionName={t("common:button.save")}
                    />
                    <Checklist dataCallback={setChecklist} color="success" items={!!initItems && initItems.map((item) => { return { id: uuidv4() + "", component: <CheckrideEditorItem {...item} /> } })} defaultComponent={<CheckrideEditorItem />} />
                </Box>,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return true; }
        }
    ]

    const [activeStep, setActiveStep] = useState(0);

    return (
        <Box pt={3} pb={8} mx={8}>
            <Box mx={2} >
                <Stepper activeStep={activeStep} alternativeLabel>
                    {content.map((item) => (
                        <Step key={item.label}>
                            <StepLabel >{item.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box my={4}>
                {content[activeStep].component}
            </Box>
        </Box >)
}

function Selection({ callback, setChecklistID }) {
    const { t } = useTranslation();
    const { alias, reg } = useParams();

    const [regIsLoading, setRegIsLoading] = useState()
    const [oldChecklist, setOldChecklist] = useState();
    useEffect(async () => {
        if (!!reg) {
            setRegIsLoading(true)
            const getCallback = (resp) => {
                if (!!resp && resp.data && !!resp.data[0]) {
                    setChecklistID(resp.data[0]._id.$oid)
                    setOldChecklist(resp.data[0].checklist)
                }
            }
            await restGet(getCallback, 'checklist/', { reg: reg });
            setRegIsLoading(false)
        }
    }, [reg])

    const [aliasIsLoading, setAliasIsLoading] = useState()
    useEffect(async () => {
        if (!!alias) {
            setAliasIsLoading(true)
            await restGet(null, 'checklist/', { alias: alias });
            setAliasIsLoading(false)
        }
    }, [alias])


    return (
        <Grid container justifyContent="center" spacing={3} >
            {regIsLoading ? <Grid item xs={12} md={6} xl={3}><Skeleton height={"100%"} width={"100%"} /></Grid> :
                !!oldChecklist &&
                <Grid item xs={12} md={6} xl={3}>
                    <DefaultNextStepCard
                        color="dark"
                        icon="file_open"
                        title={t("")}
                        description={t("")}
                        button={
                            <Button
                                variant="gradient"
                                color="primary"
                                onClick={() => callback(oldChecklist)}
                            >
                                {t("common:button.checklist.continue")}
                            </Button>}
                    />
                </Grid>
            }
            <Grid item xs={12} md={6} xl={3}>
                <DefaultNextStepCard
                    color="dark"
                    icon="insert_drive_file"
                    title={t("")}
                    description={t("")}
                    button={
                        <Button
                            variant="gradient"
                            color="dark"
                            onClick={() => callback([])}
                        >
                            {t("common:button.checklist.blank")}
                        </Button>}
                />
            </Grid>
            {/* <Grid item xs={12} md={6} xl={3}>
                <DefaultNextStepCard
                    color="dark"
                    icon="file_copy"
                    title={t("")}
                    description={t("")}
                    button={
                        <Stack spacing={3}>
                            {aliasIsLoading ? <Skeleton /> :
                                <Button
                                    variant="gradient"
                                    color="dark"
                                >
                                    {t("REPLACE WITH SELECT")}
                                </Button>
                            }
                            <Button
                                variant="gradient"
                                color="dark"
                            >
                                {t("common:button.checklist.copy")}
                            </Button>
                        </Stack>
                    }
                />
            </Grid> */}
            {/* <Grid item xs={12} md={6} xl={3}>
                <DefaultNextStepCard
                    color="dark"
                    icon="task"
                    title={t("")}
                    description={t("")}
                    button={
                        <Button
                            variant="gradient"
                            color="dark"
                        >
                            {t("Use Default")}
                        </Button>
                    }
                />
            </Grid> */}
        </Grid>
    )
}