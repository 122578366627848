import { useState, useEffect } from "react"
import DataTable from "components/Tables/DataTable";
import { bookingActions, bookingColumns } from "../tables/bookingTable";
import AeroLoading from "components/Progress/AeroLoading";
import { restGet } from "lib/rest";
import { addActions } from "lib/functions/tables";
import { Card, Grid } from "@mui/material";
import RangeDatePicker from "components/DatePicker/RangeDatePicker";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";
import { convertFrontendToTimeDatabase } from "lib/functions";

export default function Bookings() {
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    let { addModal } = useModal();
    const { refresher } = useRefresher();

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp && !!resp.data) data = resp.data
                setTableData({ columns: bookingColumns, rows: addActions(data, bookingActions(addModal, data)) })
            }
            setIsLoading(true)
            await restGet(restCallback, `booking/_all?include=aircraft`,
                {
                    identity: '_self',
                    'ts_start': convertFrontendToTimeDatabase(start),
                    'ts_end': convertFrontendToTimeDatabase(end)
                }
            )
            setIsLoading(false)
        }
        const timer = setTimeout(() => {
            !!start && !!end && fetch()
        }, 1000)

        return () => clearTimeout(timer)
    }, [start, end, refresher])

    return (
        <>
            <Grid item xs={12} >
                <Card sx={{ padding: "16px" }}><RangeDatePicker setStart={setStart} setEnd={setEnd} /></Card>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "24px" }}>
                <Card>
                    {isLoading && <AeroLoading />}
                    {!isLoading && <DataTable canSearch={true} table={tableData} />}
                </Card>
            </Grid>
        </>)
}