import DefaultModal from "components/Modal/DefaultModal";
import { useTranslation } from "react-i18next";
import PostCancelationModal from "./PostCancelationModal";

function CancelRequestModal({ actionName, data }) {

    const { t } = useTranslation();

    return (
        <DefaultModal
            title={t("common:modal.join.title")}
            confirm={{
                show: true,
                text: t("common:button.confirm"),
                color: "info",
                modal: <PostCancelationModal reg={data.charter[0].alias}/>
            }}
        >
            {t("common:modal.join.description", { action: actionName })}

        </DefaultModal>
    );
}

export default CancelRequestModal;