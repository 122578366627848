import { Stack } from "@mui/material"
import Typography from "components/Typography"
import brand from "constants/brand"
import moment from "moment"
export default (
    <Stack>
        <Typography variant="h5" >
            {brand.name}&nbsp;-&nbsp;{brand.claim}
        </Typography>
        <Typography variant="button" fontWeight="regular">
            &copy;, &reg; and &trade; 2019-{moment().format("YYYY")}{" "} {brand.company}. All Rights Reserved. Made in Germany
        </Typography>
    </Stack>
)