import { Card, Grid } from "@mui/material";
import Box from "components/Box";
import AeroLoading from "components/Progress/AeroLoading";
import SimpleSidenav from "components/Sidenav/SimpleSidenav";
import DataTable from "components/Tables/DataTable";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useModal } from "hooks/modalHook";
import { addActions } from "lib/functions/tables";
import { restGet } from "lib/rest";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { joinActions, joinColumns } from "../../tables/joinTable";
import { userActions, userColumns } from "../../tables/userTable";
import _ from "lodash";
import { useRefresher } from "hooks/refreshHook";
import Restricted from "components/Common/Restricted";

export default function Member() {
    const { t } = useTranslation();
    const [loadingMember, setLoadingMember] = useState(true)
    const [memberData, setMemberData] = useState({ columns: [], rows: [] })
    const [loadingRequests, setLoadingRequests] = useState(true)
    const [joinRequests, setJoinRequests] = useState({ columns: [], rows: [] })

    let { alias } = useParams();
    let { addModal } = useModal();
    const { refresher } = useRefresher();

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp && !!resp.data) data = resp.data
                setMemberData({ columns: userColumns, rows: addActions(data, userActions(addModal, data)) })
            }
            setLoadingMember(true)
            await restGet(restCallback, `charter/${alias}?include=staff`)
            setLoadingMember(false)
        }

        !!alias && fetch()
    }, [alias, refresher])

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp && !!resp.data) data = resp.data
                data = data.map((d) => {
                    return { created: d.created, identity: d.identity, alias: alias, status: d.status ?? "pending" }
                })
                data = _.filter(data, (o) => { return o.status !== 'done' })
                setJoinRequests({ columns: joinColumns, rows: addActions(data, joinActions(addModal, data)) })
            }
            setLoadingRequests(true)
            await restGet(restCallback, `charter/${alias}/join?include=identity`)
            setLoadingRequests(false)
        }
        !!alias && fetch()
    }, [alias, refresher])

    return (
        <Grid item xs={12}>
            <Box mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={3}>
                        <Restricted role="admin">
                            <SimpleSidenav sidenavItems={[
                                { icon: "groups", label: t("dashboard:charter.member.title"), href: "member" },
                                { icon: "handshake", label: t("dashboard:charter.member.joinTitle"), href: "join-requests" },
                            ]} />
                        </Restricted>
                        <Restricted role="staff" explicit>
                            <SimpleSidenav sidenavItems={[
                                { icon: "handshake", label: t("dashboard:charter.member.joinTitle"), href: "join-requests" },
                            ]} />
                        </Restricted>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Box mb={3}>
                            <Grid container spacing={3}>
                                <Restricted role="admin">
                                    <Grid item xs={12} id="member">
                                        <Card>
                                            <TypographyBlock
                                                title={t("dashboard:charter.member.title")}
                                                description={t("dashboard:charter.member.description")}
                                            />
                                            {loadingMember && <AeroLoading />}
                                            {!loadingMember && <DataTable table={memberData} />}
                                        </Card>
                                    </Grid>
                                </Restricted>
                                <Restricted role="staff">
                                    <Grid item xs={12} id="join-requests">
                                        <Card>
                                            <TypographyBlock
                                                title={t("dashboard:charter.member.joinTitle")}
                                                description={t("dashboard:charter.member.joinDescription")}
                                            />
                                            {loadingRequests && <AeroLoading />}
                                            {!loadingRequests && <DataTable table={joinRequests} />}
                                        </Card>
                                    </Grid>
                                </Restricted>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}
