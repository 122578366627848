import { Stack } from "@mui/material";
import Box from "components/Box";
import DefaultModal from "components/Modal/DefaultModal";
import AeroLoading from "components/Progress/AeroLoading";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckrideEditorItem from "./components/CheckrideEditorItem";

function UserCheckrideModal({ data }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [checklist, setChecklist] = useState()

    useEffect(() => {
        if (!!data && !!data.checklist) {
            setChecklist(data.checklist)
        }
        setLoading(false)
    }, [data])

    const getChecklistContent = useCallback(() => {
        if (!!checklist) {
            return (
                <Stack spacing={1}>
                    {checklist?.items?.map((item) => <CheckrideEditorItem disabled {...item} />)}
                </Stack>
            )
        }
        return (<TypographyBlock
            title={t("no Checklist")}
            description={t("")}
        />)
    }, [checklist])

    return (
        <DefaultModal
            title={t("")}        >
            {loading && <AeroLoading />}
            {!loading && getChecklistContent()}
        </DefaultModal>
    );
}

export default UserCheckrideModal;