import PropTypes from "prop-types";
import MuiLink from "@mui/material/Link";
import Button from "components/Button";
import { Stack } from "@mui/material";
import { getExternalRedirect } from "lib/functions/aircraft";
import { getCheckride } from "lib/functions/aircraft";
import { useUser } from "hooks/userDataHook";
import { useTranslation } from "react-i18next";
import { AuthButton } from "components/Button/AuthButton";
import { useEffect, useState } from "react";
import { restPost } from "lib/rest";
import { restGet } from "lib/rest";
import { useModal } from "hooks/modalHook";
import { add } from "lodash";
import CalendarModal from "components/Modal/CalendarModal/CalendarModal";
import Box from "components/Box";
import ReqCheckModal from "components/Modal/ReqCheckModal/ReqCheckModal";

function DefaultActions({ aircraft }) {
    const { t } = useTranslation();
    const { addModal } = useModal();
    const { isSignedIn, hasRequestStatus, isAuthorized, refreshUserData, isVerified } = useUser();
    const [actions, setActions] = useState()

    const awaitActions = async () => {
        var isLoggedIn = await isSignedIn()
        var verification = await isVerified()
        var authorized = await isAuthorized(aircraft.reg)
        var requestStatus = !!isLoggedIn ? await hasRequestStatus(aircraft.reg) : null
        setActions(defineAction(isLoggedIn, requestStatus, verification, authorized))
    }

    const handleCheckride = () => {

        addModal(<ReqCheckModal reg={aircraft.reg} awaitActions={awaitActions} />)


    }
    const handleReservation = () => {
        addModal(<CalendarModal homebase={aircraft.homebase} reg={aircraft.reg} />)
    }

    useEffect(() => {
        awaitActions()
    }, [])

    function defineAction(isLoggedIn, requestStatus, verification, authorized) {
        /**
         * External Homepage
         */
        if (getExternalRedirect(aircraft)) {
            return (
                <Button
                    component={MuiLink}
                    href={getExternalRedirect(aircraft)}
                    target="_blank"
                    variant="outlined"
                    size="small"
                    color="info"
                >
                    {t("common:button.homepage")}
                </Button>
            )
        }
        /**
         * Auth Button
         */
        else if (!isLoggedIn) {
            return (<AuthButton />)
        }
        else if (verification !== true) {
            return (
                <Button
                    component={MuiLink}
                    href={verification}
                    variant="outlined"
                    size="small"
                    color="info"
                >
                    {t("common:button.verify")}
                </Button>)
        }
        /**
         * Request Checkride or Checkride in Progress
         */
        else if (!getCheckride(aircraft) && !authorized) {
            switch (requestStatus) {
                case "new":
                    return (
                        <Box p={1}>
                            <Button variant="gradient" size="small" color={"success"} disabled>
                                {t("common:button.checkrideInProgress")}
                            </Button>
                        </Box>
                    )
                default:
                    return (
                        <Box p={1}>
                            <Button variant="outlined" size="small" color={"info"} onClick={() => handleCheckride()}>
                                {t("common:button.checkride")}
                            </Button>
                        </Box>
                    )
            }
        }
        /**
         *  Reservation
         */
        else {
            return (
                <Box p={1}>
                    <Button variant="outlined" size="small" color={"info"} href={"/aircraft/" + aircraft.reg + "/calendar/"}>
                        {t("common:button.reservation")}
                    </Button>
                </Box>
            )
        }
    }

    return (
        <Stack>
            {actions}
        </Stack>
    );
}

// Typechecking props for the DefaultAircraftCard
DefaultActions.propTypes = {
    aircraft: PropTypes.object
};

export default DefaultActions;
