import Badge from "components/Badge";
import Box from "components/Box";
import Button from "components/Button";
import AeroLoading from "components/Progress/AeroLoading";
import Typography from "components/Typography";
import { capitalizeFirstLetter } from "lib/functions";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function Payment({ setData }) {
    const { t } = useTranslation();
    const darkMode = false
    const { alias, identity_id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState()
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        const fetch = async () => {
            const callback = (resp) => {
                !!resp && !!resp.data && setPaymentMethods(resp.data)
            }
            setIsLoading(true)
            await restGet(callback, 'identity/' + identity_id + '/paymentmethod/' + alias + '/charter')
            setIsLoading(false)
        }

        !!alias && !!identity_id && fetch();
    }, [alias, identity_id]);

    useEffect(() => {
        !!paymentMethods && setData(paymentMethods[selected])
    }, [paymentMethods, selected]);

    if (isLoading) return <AeroLoading />
    return (
        <Box>
            {!!paymentMethods && paymentMethods.map((paytMethod, i) =>
                <Box
                    key={"payment-box-" + i}
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={darkMode ? "transparent" : "grey-100"}
                    borderRadius="lg"
                    p={3}
                    mb={0}
                    mt={2}
                >
                    {!!paytMethod.billing_details &&
                        <Box width="100%" display="flex" flexDirection="column">
                            <Box mb={2}                            >
                                <Typography variant="button" fontWeight="medium" textTransform="capitalize">
                                    {t("common:label.billing_details")}
                                </Typography>
                            </Box>
                            <Box mb={1} lineHeight={0}>
                                <Typography variant="caption" color="text">
                                    {t("common:label.name")}:&nbsp;&nbsp;&nbsp;
                                    <Typography variant="caption" fontWeight="medium">
                                        {!!paytMethod.billing_details.name && (paytMethod.billing_details.name)}
                                    </Typography>
                                </Typography>
                            </Box>
                            <Box mb={1} lineHeight={0}>
                                <Typography variant="caption" color="text">
                                    {t("common:label.emailaddress")}:&nbsp;&nbsp;&nbsp;
                                    <Typography variant="caption" fontWeight="medium">
                                        {!!paytMethod.billing_details.email && paytMethod.billing_details.email}
                                    </Typography>
                                </Typography>
                            </Box>
                            <Box mb={1} lineHeight={0}>
                                <Typography variant="caption" color="text">
                                    {t("common:label.address")}:&nbsp;&nbsp;&nbsp;
                                    <Typography variant="caption" fontWeight="medium" textTransform="capitalize">
                                        {!!paytMethod.billing_details.address.line1 && (paytMethod.billing_details.address.line1 + '; ')}
                                        {!!paytMethod.billing_details.address.line2 && (paytMethod.billing_details.address.line2 + '; ')}
                                        {!!paytMethod.billing_details.address.postal_code && (paytMethod.billing_details.address.postal_code + ' ')}
                                        {!!paytMethod.billing_details.address.city && (paytMethod.billing_details.address.city)}
                                        {!!paytMethod.billing_details.address.country && (', ' + paytMethod.billing_details.address.country)}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Box>
                    }
                    {!!paytMethod.card &&
                        <Box width="100%" display="flex" flexDirection="column">
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems={{ xs: "flex-start", sm: "center" }}
                                flexDirection={{ xs: "column", sm: "row" }}
                                mb={2}
                            >
                                <Typography variant="button" fontWeight="medium" textTransform="capitalize">
                                    {t("common:label.payment_method")}
                                </Typography>

                                <Box display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
                                    {selected === i ?
                                        <Badge
                                            variant="contained"
                                            badgeContent={t("common:label.selected")}
                                            color="success"
                                            container />
                                        :
                                        <Button size="small" variant="contained" color="light" onClick={() => setSelected(i)}>
                                            {t("common:button.select")}
                                        </Button>
                                    }
                                </Box>
                            </Box>
                            <Box mb={1} lineHeight={0}>
                                <Typography variant="caption" color="text">
                                    {t("common:label.card")}:&nbsp;&nbsp;&nbsp;
                                    <Typography variant="caption" fontWeight="medium" textTransform="capitalize">
                                        {!!paytMethod.card.brand && capitalizeFirstLetter(paytMethod.card.brand)}
                                    </Typography>
                                </Typography>
                            </Box>
                            <Box mb={1} lineHeight={0}>
                                <Typography variant="caption" color="text">
                                    {t("common:label.card_number")}:&nbsp;&nbsp;&nbsp;
                                    <Typography variant="caption" fontWeight="medium" textTransform="capitalize">
                                        **** **** **** {!!paytMethod.card.last4 && paytMethod.card.last4}
                                    </Typography>
                                </Typography>
                            </Box>
                            <Box mb={1} lineHeight={0}>
                                <Typography variant="caption" color="text">
                                    {t("common:label.expires")}:&nbsp;&nbsp;&nbsp;
                                    <Typography variant="caption" fontWeight="medium">
                                        {!!paytMethod.card.exp_month && paytMethod.card.exp_month}/{!!paytMethod.card.exp_year && paytMethod.card.exp_year}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            )}
        </Box>
    );
}