/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";

// Timeline context
import { useTimeline } from "../context";

// Custom styles for the TimelineItem
import timelineItem from "./styles";
import { Stack } from "@mui/material";
import { isObject } from "lodash";



function TimelineItem({  color, icon, title, dateTime, description, lastItem, attachment }) {
  const isDark = useTimeline();

  return (
    <Box position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </Box>

      <Box ml={5.75} pt={description ? 0.7 : 0.5}  >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="column" spacing={2}>

            <Box mt={0.5}>
              <Typography variant="caption" color={isDark ? "secondary" : "text"}>
                {dateTime}
              </Typography>
            </Box>

            <Typography variant="h5" mt={3}>
              {title ?? ''}
            </Typography>


            <Box mt={2} >
              {description.map((v, i) =>
                <Typography
                  variant="body2"
                  mt={3}
                  key={'timelime-desc-'+ i}
                >
                  {v}
                </Typography>)
              }

              {isObject(attachment) ?
                <Typography variant="body2" mt={3}>
                  {attachment.label ?? ''}
                  <a href={attachment.href ?? ''} target={attachment.target ?? '_blank'}>{attachment.href ?? ''}</a>
                </Typography>
                : null}

            </Box>

          </Stack>
        </Stack>

      </Box>
    </Box >
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  dateTime: PropTypes.string,
  description: PropTypes.array,
  lastItem: PropTypes.bool
};

export default TimelineItem;
