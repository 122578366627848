import { useRef } from "react";
import PropTypes from "prop-types";

// SwiperJS
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css'
import 'swiper/css/navigation'
import "swiper/css/pagination";
import './style.css'

import Container from "@mui/material/Container";
import Box from "components/Box";

function DefaultSwiper({ slides }) {
    // install SwiperJS modules
    SwiperCore.use([Autoplay, Navigation, Pagination]);

    // SwiperJS navigation buttons ref
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    return (
        <Box py={8} position="relative">
            <Swiper
                onInit={({ params, navigation }) => {
                    const { navigation: nav } = params;
                    nav.prevEl = navigationPrevRef.current;
                    nav.nextEl = navigationNextRef.current;
                    navigation.init();
                    navigation.update();
                }}
                spaceBetween={5}
                slidesPerView={"auto"}
                centeredSlides={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
            >
                {slides.map((content, index) =>
                    <SwiperSlide key={"swipe-slider-" + index}  style={{ width: 500 }}>
                        <Container>
                            {content}
                        </Container>
                    </SwiperSlide>
                )}
            </Swiper>
        </Box>
    );
}

DefaultSwiper.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default DefaultSwiper;