/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
// Material Kit 2 PRO React components
import Input from "components/Input";
import { useCallback } from "react";
import _ from "lodash";
import moment from "moment";

function DatePicker({ input, onChange, ...rest }) {

  const debounceFn = useCallback(_.debounce(onChange, 2000), []);

  function handleChange(event) {
    var convertTime = moment.utc(event.target.value, ['L', "MM/DD/YYYY", "DD.MM.YYYY", 'YYYY-MM-DD', 'x'])
    var dateObject = convertTime.toDate()
    debounceFn([dateObject]);
  };

  return (
    <Flatpickr
      {...rest}
      render={({ defaultValue }, ref) => (
        <Input {...input} defaultValue={defaultValue} inputRef={ref} onChange={handleChange} />
      )}
    />
  );
}

// Setting default values for the props of DatePicker
DatePicker.defaultProps = {
  input: {},
};

// Typechecking props for the DatePicker
DatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default DatePicker;
