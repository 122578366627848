import { Container ,Grid } from '@mui/material'
import Badge from 'components/Badge'
import Box from 'components/Box'
import Typography from 'components/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'


const Partnership = () => {
    const { t } = useTranslation();

    return (
        <Box mb={5} component="section" py={6} >
            <Container>
                <Grid
                    container
                    item
                    xs={8}
                    flexDirection="column"
                    alignItems="center"
                    mx="auto"
                    textAlign="center"
                    mb={6}
                >
                {/*     <Badge
                        badgeContent="co-working"
                        variant="contained"
                        color="info"
                        container
                        sx={{ mb: 1 }}
                    /> */}
                    <Typography color="primary" variant="h2" mb={5}>
                    {t("sustainabiltyPage:partnership.title")}
                    </Typography>
                    <Typography  variant="body2" >
                    {t("sustainabiltyPage:partnership.text")}
                    </Typography>
                </Grid>
                <Grid container spacing={3} minHeight="40vh">
                    <Grid item xs={5} sm={4}>
                        <Box
                            width="100%"
                            height="100%"
                            borderRadius="lg"
                            shadow="md"
                            sx={{
                                backgroundImage: `url(${"https://aeromatestatic.blob.core.windows.net/img/sustainablility_aircraft_cockpit.png"})`,
                                backgroundSize: "cover",
                            }}
                        />
                    </Grid>
                    <Grid item xs={7} sm={3}>
                        <Box
                            width="100%"
                            height="100%"
                            borderRadius="lg"
                            shadow="md"
                            sx={{
                                backgroundImage: `url(${"https://media.westeurope.aero-services.net/aeromate/product/AdobeStock_139621851sm.jpeg"})`,
                                backgroundSize: "cover",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Box
                            width="100%"
                            height="100%"
                            borderRadius="lg"
                            shadow="md"
                            sx={{
                                backgroundImage: `url(${"https://aeromatestatic.blob.core.windows.net/img/sustainablility_aircraft_mountain.png"})`,
                                backgroundSize: "cover",
                            }}
                        />
                    </Grid>
                    <Grid item xs={7} sm={3}>
                        <Box
                            width="100%"
                            height="100%"
                            borderRadius="lg"
                            shadow="md"
                            sx={{
                                backgroundImage: `url(${"https://aeromatestatic.blob.core.windows.net/img/sustainablility_hand_plant.png"})`,
                                backgroundSize: "cover",
                            }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Box
                            width="100%"
                            height="100%"
                            borderRadius="lg"
                            shadow="md"
                            sx={{
                                backgroundImage: `url(${"https://aeromatestatic.blob.core.windows.net/img/sustainablility_river_forest.png"})`,
                                backgroundSize: "cover",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            width="100%"
                            height="100%"
                            borderRadius="lg"
                            shadow="md"
                            sx={{
                                backgroundImage: `url(${"https://aeromatestatic.blob.core.windows.net/img/sustainablility_river_mountain.png"})`,
                                backgroundSize: "cover",
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Partnership