/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Avatar from "components/Avatar";
import Typography from "components/Typography";
import StarRating from "components/Rating/StarRating";
import { Skeleton, Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

function ExternalReviewCard({ color, image, name, date, review, rating, verified }) {


  const { t } = useTranslation()

  return (
    <Box
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor={color}
      borderRadius="xl"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
    >
      {image && (
        <Avatar
          src={image}
          alt={name}
          variant="rounded"
          size="lg"
          shadow="md"
          sx={{ mt: -5, mb: 1 }}
        />
      )}
      <Box lineHeight={1}>
        <Stack direction="row" spacing={2}>
          {!!name ?
            <Typography
              display="block"
              variant={image ? "button" : "h6"}
              fontWeight="bold"
              color={color === "transparent" || color === "light" ? "dark" : "white"}
              mb={0.5}
            >
              {name}
            </Typography> :
            <Skeleton
              variant="h6"
              mb={0.5}
            />}
          {verified ?
            <Tooltip title={t("aircraftPage:review.verified")}>
              <Icon>verified</Icon>
            </Tooltip>
            : null}
        </Stack>
        <StarRating rating={rating} />
        <Typography
          variant={image ? "caption" : "button"}
          fontWeight="regular"
          lineHeight={1}
          color={color === "transparent" || color === "light" ? "text" : "white"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Icon>schedule</Icon>&nbsp;
          {date}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        &quot;{review}&quot;
      </Typography>
      <Typography
        variant="h4"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        sx={{
          display: "flex",
          alignItems: "center",
          ml: 0.375,

          "& .material-icons-round": {
            ml: -0.375,
          },
        }}
      >
      </Typography>
    </Box>
  );
}

// Setting default values for the props of DefaultReviewCard
ExternalReviewCard.defaultProps = {
  color: "transparent",
  image: "",
  rating: 5
};

// Typechecking props for the DefaultReviewCard
ExternalReviewCard.propTypes = {
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired
};

export default ExternalReviewCard;
