import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import Box from "components/Box";
import Typography from "components/Typography";

export default function FaqCollapse({ title, open, children, ...rest }) {
    return (
        <Box mb={2}>
            <Box
                {...rest}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={2}
                sx={{
                    cursor: "pointer",
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                }}
            >
                <Typography variant="h5" color={open ? "dark" : "text"} sx={{ userSelect: "none" }}>
                    {title}
                </Typography>
                <Box color={open ? "dark" : "text"}>
                    <Icon sx={{ fontWeight: "bold" }} fontSize="small">
                        {open ? "remove" : "add"}
                    </Icon>
                </Box>
            </Box>
            <Collapse timeout={400} in={open}>
                <Box py={2} lineHeight={1}>
                    <Typography variant="button" color="text" opacity={0.8} fontWeight="regular">
                        {children}
                    </Typography>
                </Box>
            </Collapse>
        </Box>
    );
}