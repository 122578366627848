import { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import Avatar from "components/Avatar";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useUser } from "hooks/userDataHook";
import Enrollment from "./sections/enrollment";
import { useParams } from "react-router-dom";
import { restGet } from "lib/rest";
import placeholderAvatar from "assets/images/avatar.png"
import { getBlobImageUrl } from "lib/functions";
// Images

function CharterBody() {
    const [avatar, setAvatar] = useState(placeholderAvatar);
    const [header, setHeader] = useState("https://media.westeurope.aero-services.net/aeromate/header/rough-horn-2146181_1920.jpg");
    const { identity_id, alias } = useParams();

    const [identity, setIdentity] = useState();

    useEffect(() => {
        !!identity_id && !!alias && restGet(setIdentity, `identity/${identity_id}/charter/${alias}`)
    }, [identity_id, alias]);

    useEffect(() => {
        identity?.image?.Avatar && setAvatar(getBlobImageUrl(identity?.image?.Avatar))
    }, [identity]);

    return (
        <Box position="relative" mb={5}>
            <Box
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{
                    backgroundImage: `url(${header})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            />
            <Card
                sx={{
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <Avatar src={avatar} alt="profile-image" size="xl" shadow="sm" />
                    </Grid>
                    <Grid item>
                        <Box height="100%" mt={0.5} lineHeight={1}>
                            <Typography variant="h5" fontWeight="medium">
                                {identity?.name ?? ""}
                            </Typography>
                            <Typography variant="button" color="text" fontWeight="regular">
                                {identity?.license ?? ""}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            <Box p={2}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={12} xl={12}>
                        <Enrollment identityId={identity_id} charterview />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default CharterBody;