import React from 'react'
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Box from "components/Box";
import _ from "lodash";
import { useRefresher } from 'hooks/refreshHook';
import { Card, Divider, Grid, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Input from 'components/Input';
import { restGet } from 'lib/rest';
import ServiceTable from 'components/Tables/ServiceTable';
import { restPost } from 'lib/rest';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from 'components/Button';
import { Save } from '@mui/icons-material';
import colors from 'assets/theme/base/colors';
import Alert from "components/Alert";
import AlertModal from './AlertModal';
import { useModal } from 'hooks/modalHook';
import moment from 'moment/moment';
import DatePicker from "components/DatePicker";


const Servicemodal = ({ onValueChange, reg, aircraft, service }) => {
    const { t } = useTranslation();
    const [last_flight, setLastFlight] = useState();
    const [rows, setRows] = useState([]);
    const { addModal, removeModal } = useModal();

    useEffect(() => {
        if (!!service) {
            setRows(service)
        }
    }, [service]);

    const handleDeleteClick = (id) => () => {
        let tmp = rows.filter((row) => row.id !== id)
        tmp = tmp.map((element, index) => { return { ...element, id: index, } })
        setRows(tmp);
    };

    const handleAlert = (id) => {
        addModal(<AlertModal onValueChange={Handlechange} title={"Are you sure you want to Save ??"} description={"If it is saved it cant be changed"} id={id}></AlertModal>)
    }

    const Handlechange = (value) => {
        if (!!aircraft) {
            let tmp = rows.filter((row) => row.id === value)
            tmp[0].saved = "saved"
            let array = rows.filter((row) => row.id !== value)
            let new_arr = _.concat(tmp[0], array);
            setRows(new_arr);
            const arr = {

                reg: aircraft[0]?.reg,
                data: tmp[0],
                type: "service"
            };
            restPost(
                null,
                `service/${aircraft[0]?.reg}`,
                arr
            );
        }
    }

    const { refresh } = useRefresher();

    let columns = [
        {
            field: "type", headerName: "Service Type", editable: true, width: 150, type: "singleSelect",
            valueOptions: ["Frame", "Engine", "Prop", "Parachute"]
        },
        { field: "description", headerName: "Description", editable: true, width: 200, },
        {
            field: "lod", headerName: "Last Overhaul Date", editable: true, width: 170,
            valueGetter: (params) => {
                if (!params.value) {
                    return;
                }
                return moment.utc(params.value, ['L', "MM/DD/YYYY", "DD.MM.YYYY", 'YYYY-MM-DD', 'x']).valueOf();
            },
            valueFormatter: (params) => {
                if (!params.value) {
                    return;
                }
                return `${moment.utc(params.value).format('L')}`;
            },
        },
        {
            field: "loh", headerName: "Last Overhaul Hours", editable: true, width: 170, type: "number",
        },
        {
            field: "Tbod",
            headerName: "Time between overhauls date",
            editable: true,
            width: 250,
            valueGetter: (params) => {
                if (!params.value) {
                    return;
                }
                return moment.utc(params.value, ['L', "MM/DD/YYYY", "DD.MM.YYYY", 'YYYY-MM-DD', 'x']).valueOf();
            },
            valueFormatter: (params) => {
                if (!params.value) {
                    return;
                }
                return `${moment.utc(params.value).format('L')}`;
            },
        },
        { field: "Tboh", headerName: "Time between overhauls hours", editable: true, width: 250, type: "number" },
        {
            field: "duration_days", headerName: "Days", editable: false, width: 100,
            valueGetter: (params) => {
                if (!params.row.Tbod || !params.row.lod) {
                    return;
                }
                var date1 = moment(params.row.Tbod, ['L', "MM/DD/YYYY", "DD.MM.YYYY", 'YYYY-MM-DD', 'x'])
                var date2 = moment(params.row.lod, ['L', "MM/DD/YYYY", "DD.MM.YYYY", 'YYYY-MM-DD', 'x'])
                return moment.duration(date1.diff(date2)).as('days');
            },
        },
        {
            field: "duration_hours", headerName: "Hours", editable: true, width: 100,

            valueGetter: (params) => {
                if (!params.row.Tboh || !params.row.loh) {
                    return;
                }
                return params.row.Tboh - params.row.loh;
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 100,
            cellClassName: "actions",
            renderCell: (params) => {
                if (params.row.saved !== "saved") {
                    return (
                        <>

                            <Button
                                variant="gradient" color="error" iconOnly size="small"
                                onClick={handleDeleteClick(params.row.id)}
                            ><DeleteIcon />
                            </Button >
                            <Button
                                variant="gradient" color="success" iconOnly size="small"
                                onClick={() => handleAlert(params.row.id)}
                            ><Save />
                            </Button >

                        </>
                    );
                }
            },
        },



    ];

    function handleAdd() {
        let tmp = [...rows]
        tmp.push({
            "id": tmp.length,
            "type": "",
            "description": "",
            "lod": "",
            "loh": "",
            "Tbod": "",
            "Tboh": "",
            'saved': "",
            "actions": ""
        })
        setRows(tmp)
    }


    function handleOnCommit(field, id, value) {
        //an welcher stelle steht das objekt mit der id
        const index = _.findIndex(rows, function (o) { return o.id == id })

        //Wenn keine Index gefunden wurde abbruch
        if (index < 0) return;

        //Kopiere das Array
        let tmo = _.cloneDeep(rows)

        //Hole das Element am Index
        let element = tmo[index]

        //Überschreibe das Feld des Elementes mit dem neuen Value 
        element[field] = value

        //Speichern des aktualisierten Arrays
        setRows(tmo)

    }

    return (


        <Grid item xs={12}>
            <Box ml={1} mr={1} mb={1}>
                <Stack>

                    <DataGrid
                        sx={{
                            border: "none",
                            "& .Mui-selected": { backgroundColor: colors.badgeColors.info.background + " !important" },
                            "& .MuiDataGrid-cell:focus": { outline: "solid " + colors.badgeColors.info.text + " 1px" },
                            '& .MuiDataGrid-cell': { bgcolor: colors.light.main },
                            '& .MuiDataGrid-cell--editable': { bgcolor: colors.white.main }
                        }}
                        autoHeight
                        rows={rows}
                        columns={columns}
                        onCellEditCommit={(e) => handleOnCommit(e.field, e.id, e.value)}
                        disableColumnFilter
                        disableColumnMenu
                        rowHeight={40}
                        isCellEditable={(params) => params.row.saved !== "saved"}
                    />
                    <Button onClick={handleAdd} color="success" variant="gradient" sx={{ marginLeft: "auto" }}>Add</Button>
                </Stack>
            </Box>
        </Grid>


    )
}

export default Servicemodal