import Icon from "@mui/material/Icon";
import { Rating, styled } from "@mui/material";


const StyledRating = styled(Rating)(({ theme, color }) => {
    const { palette } = theme;

    const validColors = [
        "transparent",
        "white",
        "black",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "text"
    ];
    let colorValue = color;

    if (validColors.find((el) => el === color)) {
        colorValue = palette[color] ? palette[color].main : greyColors[color];
    }
    return {
        '& .MuiRating-iconFilled': {
            color: colorValue,
        },
        '& .MuiRating-icon': {
            color: colorValue,
        },
        '& .MuiRating-iconHover': {
            color: colorValue,
        },
    }
});

export default function StarRatingInput({ value, onChange, color }) {

    return (
        <StyledRating
            value={value}
            onChange={(event, newValue) => {
                onChange(event, newValue);
            }}
            max={5}
            precision={0.5}
            color={color ?? "text"}
            icon={<Icon>star</Icon>}
            emptyIcon={<Icon>star_outline</Icon>}
        />
    )
}