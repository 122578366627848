import { useState, useEffect } from "react";
import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import { useLocation } from "react-router-dom";
import Aircrafts from "./sections/Aircrafts";
import Rates from "./sections/Rates";
import Dashboard from "./sections/Dashboard";
import Restricted from "components/Common/Restricted";

export default function Body() {
    const { hash } = useLocation();

    const getTab = () => {
        switch (hash) {
            case "#aircrafts": return (0)
            case "#rates": return (1)
        /*     case "#dashboard": return (2) */
            default: return (0)
        }
    }
    const [tabValue, setTabValue] = useState(getTab());
    const [content, setContent] = useState(null)
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = "#" + event.target.id
    }
    useEffect(() => {
        switch (tabValue) {
            case 0:
                setContent(<Aircrafts />)
                break;
            case 1:
                setContent(<Rates />)
                break;
         /*    case 2:
                setContent(<Dashboard />)
                break; */
            default:
                break;
        }
    }, [tabValue])

    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }
        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6} lg={8} >
                <AppBar position="static">
                    <Restricted role="staff" explicit>
                        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                            <Tab label="aircrafts" id="aircrafts" />
                           {/*  <Tab label="dashboard" id="dashboard" /> */}
                        </Tabs>
                    </Restricted>
                    <Restricted role="admin" >
                        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                            <Tab label="aircrafts" id="aircrafts" />
                            <Tab label="rates" id="rates" />
                       {/*      <Tab label="dashboard" id="dashboard" /> */}
                        </Tabs>
                    </Restricted>
                </AppBar>
            </Grid>
            <Grid item xs={12} >
                {content}
            </Grid>
        </Grid >
    )
}