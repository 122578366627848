import { restGet } from 'lib/rest';
import React from 'react'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyFrancIcon from '@mui/icons-material/CurrencyFranc';

export function legalObject(legalEntity, currency) {
    let data = { legalEntity: legalEntity };
    if (currency == 'EUR') {
        data = { ...data, currency: "EUR", currencyIcon: <EuroSymbolIcon></EuroSymbolIcon>, currencySymbol: "€" }
    }
    else if (currency == 'USD') {
        data = { ...data, currency: "USD", currencyIcon: <AttachMoneyIcon></AttachMoneyIcon>, currencySymbol: "$" }
    }
    else if (currency == 'CAD') {
        data = { ...data, currency: "CAD", currencyIcon: <AttachMoneyIcon></AttachMoneyIcon>, currencySymbol: "CAD" }
    }
    else if (currency == 'CHF') {
        data = { ...data, currency: "CHF", currencyIcon: <CurrencyFrancIcon></CurrencyFrancIcon>, currencySymbol: "CHF" }
    }
    return data;
}

export async function legalFromAircraft(aircraft) {
    if (aircraft?.charter[0]?.alias) {
        const res = await restGet(null, `charter/${aircraft.charter[0].alias}`)
        return legalObject(res.data[0].legal_entity, res.data[0].currency);
    }
}

export async function legalFromAlias(alias) {
    if (alias) {
        const res = await restGet(null, `charter/${alias}`)
        return legalObject(res.data[0].legal_entity, res.data[0].currency);
    }
}

export async function feeFromAlias(alias) {
    if (alias) {
        const res = await restGet(null, `charter/${alias}`)
        return await feeFromCharter(res.data[0]);
    }
}

export async function feeFromCharter(charter) {
    if (charter) {
        const res = await restGet(null, "account/" + charter.account_id + "/charter/" + charter.alias)
        return res.data.terms.fee;
    }
}

export function formatCurrency(price, currency) {
    let data = "";
    if (currency == 'EUR') {
        data = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price);
    }
    else if (currency == 'USD') {
        data = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
    }
    else if (currency == 'CAD') {
        data = new Intl.NumberFormat('en-CADs', { style: 'currency', currency: 'CAD' }).format(price);
    }
    else if (currency == 'CHF') {
        data = new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(price);
    }
    return data
}

export function currencyObject(currency) {

    let data = "";
    if (currency == 'EUR') {

        data = { name: "EUR", icon: <EuroSymbolIcon></EuroSymbolIcon>, symbol: "€" }
    }

    else if (currency == 'USD') {
        data = { name: "USD", icon: <AttachMoneyIcon></AttachMoneyIcon>, symbol: "$" }

    }

    else if (currency == 'CAD') {
        data = { name: "CAD", icon: <AttachMoneyIcon></AttachMoneyIcon>, symbol: "CAD" }
    }

    else if (currency == 'CHF') {
        data = { name: "CHF", icon: <CurrencyFrancIcon></CurrencyFrancIcon>, symbol: "CHF" }
    }

    return data;

}







