
import StickyColumnTable from "components/Tables/StickyColumnTable";
import { restGet } from "lib/rest";
import _, { forEach } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


function calculateCompletion(list) {
    var length = list.length
    var checked = _.filter(list, (e) => { return e.checked })
    return ((checked.length / length) * 100)
}

function calculateCompletionNested(list) {
    var length = list.length
    var sumCompletion = 0;
    list.forEach(element => {
        sumCompletion += calculateCompletion(element.checklist)
    });
    return (sumCompletion / length)
}

export default function Body() {
    const [keys, setKeys] = useState([]);
    const [header, setHeader] = useState([]);
    const [rows, setRows] = useState([]);
    const { alias } = useParams();

    useEffect(() => {
        const callback = async (resp) => {
            if (resp?.data) {
                var test = _.groupBy(resp.data, (e) => e?.enrollment[0]?.shop_item_id?.$oid)
                var allKeys = []
                var allHeader = []
                var allRows = []
                _.forEach(test, function (value, key) {

                    let tmpHeader = [];
                    let tmpTheory = [];
                    let tmpPractice = [];
                    let tmpExams = [];
                    value.forEach(element => {
                        tmpHeader.push({ ...element.identity[0], href: `/my/${alias}/enrollment/${element.identity[0]._id.$oid}` });
                        tmpTheory.push(calculateCompletionNested(element.theory_checklist))
                        tmpPractice.push(calculateCompletionNested(element.practice_checklist))
                        tmpExams.push(calculateCompletion(element.exams_checklist))
                    });
                    allHeader.push(tmpHeader)
                    allRows.push([{
                        title: "Theory",
                        data: tmpTheory
                    }, {
                        title: "Practice",
                        data: tmpPractice
                    }, {
                        title: "Exams",
                        data: tmpExams
                    }])
                    allKeys.push(key)
                });
                setRows(allRows)
                setKeys(allKeys)
                setHeader(allHeader)
            }
        }
        restGet((e) => callback(e), `studentfile/${alias}/charter`, { include: 'identity,enrollment' })
    }, [alias]);

    return (
        <div>
            {header?.map((e, i) => <StickyColumnTable header={header[i]} rows={rows[i]} itemKey={keys[i]} alias={alias} />)}
        </div>
    )
}