import { Card, Grid } from "@mui/material";
import Box from "components/Box";
import AeroLoading from "components/Progress/AeroLoading";
import TypographyBlock from "components/Typography/TypographyBlock";
import { restGet } from "lib/rest";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { restPost } from "lib/rest";
import { currencyObject } from "lib/legalInfo";
import colors from "assets/theme/base/colors";

export default function Rates() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true)

    let { alias } = useParams();

    const [cols, setCols] = useState([])
    const [rows, setRows] = useState([])

    useEffect(() => {
        const syncedCall = async () => {
            let fetchedRows = []
            let fetchedCols = [{ field: 'reg', headerName: 'Registration', width: 130, editable: false }, { field: 'wd', headerName: 'w/d', width: 130, editable: false }]
            let currency;
            /**
             * Callback Functions to format results
             */
            function restCallback(resp) {

                if (!!resp && !!resp.data) {
                    currency = currencyObject(resp.data[0].currency).symbol;

                }
            }
            function aircraftsCallback(resp) {
                !!resp && !!resp.data && resp.data.forEach((element, index) => {

                    let rate = { id: index, reg: element.reg, wd: t("common:label." + (element?.operational_data_setting?.wet_or_dry?.label.toLowerCase() ?? "dry")), };
                    !!element.rates && Array.isArray(element.rates) && element.rates.map((rt, x) => { rate[rt.flight_type] = rt.unit_amount / 100 })
                    fetchedRows.push(rate)
                })
            }
            function charterTypeCallback(resp) {
                resp.forEach((element) => {
                    fetchedCols.push({
                        field: element.key, headerName: element.label, type: 'number', width: 130, editable: true,
                        valueFormatter: (params) => {
                            let valueFormatted;
                            if (Number(params.value) < 0) {
                                valueFormatted = "NaN"

                            } else {


                                valueFormatted = Number(params.value).toLocaleString();
                            }
                            return `${valueFormatted}  ${currency}/min`;
                        }
                    })
                })
            }
            function flightTypeCallback(resp) {
                resp.forEach((element) => {
                    if (element.key != 'charter')
                        fetchedCols.push({
                            field: element.key, headerName: element.caption.en, type: 'number', width: 130, editable: true, disableColumnMenu: true,
                            valueFormatter: (params) => {
                                let valueFormatted;
                                if (Number(params.value) < 0) {
                                    valueFormatted = "NaN"

                                } else {
                                    valueFormatted = Number(params.value).toLocaleString();
                                }
                                return `${valueFormatted} ${currency}/min`;
                            }
                        })
                })
            }

            setIsLoading(true)
            await restGet(restCallback, `charter/${alias}`);

            await restGet(aircraftsCallback, 'aircraft?alias=' + alias)
            await restGet(charterTypeCallback, "dataset/bookingCharterType")
            await restGet(flightTypeCallback, "dataset/bookingFlightType")

            setRows(fetchedRows)
            setCols(fetchedCols)

            setIsLoading(false)
        }
        syncedCall()
    }, [alias])

    function handleEditCellChange(event) {
        restPost(null, `aircraft/${rows[event.id].reg}/rate/`, { flight_type: event.field, rate: event.value })
    }

    return (
        <Grid item xs={12}>
            <Box mt={4}>
                <Grid item xs={12} >
                    <Box mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <TypographyBlock
                                        title={t("dashboard:charter.rates.title")}
                                        description={t("dashboard:charter.rates.description")}
                                    />
                                    {isLoading && <AeroLoading />}
                                    {!isLoading &&
                                        <Box p={3}>
                                            <DataGrid
                                                sx={{
                                                    border: "none",
                                                    "& .Mui-selected": {
                                                        backgroundColor: colors.badgeColors.info.background + " !important"
                                                    },
                                                    "& .MuiDataGrid-cell:focus": {
                                                        outline: "solid " + colors.badgeColors.info.text + " 1px"
                                                    }
                                                }}
                                                autoHeight
                                                rows={rows}
                                                columns={cols}
                                                onCellEditCommit={(e) => handleEditCellChange(e)}
                                            />
                                        </Box>
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
        </Grid>
    )
}
