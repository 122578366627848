import { Container, Grid } from "@mui/material";
import ContactUsLandingPage from "aeromate-kit/LandingPage/sections/ContactUsLandingPage";
import FAQLandingPage from "aeromate-kit/LandingPage/sections/FAQLandingPage";
import Box from "components/Box";
import FeatureCard from "components/Cards/FeatureCard";
import ComingSoon from "components/Common/ComingSoon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function Body() {
    const { t } = useTranslation();
    const features = t("ResponsibilityPage:cards", { returnObjects: true })
    const [active, setActive] = useState(null);

    console.log(window.location.href)
    return (
        <Box sx={{ mt: 3 }}>
            <Container>
                <Grid container spacing={3} sx={{ mt: 3 }} justifyContent="space-evenly"
                    alignItems="center">

                    {features.map((card, index) =>
                        <>
                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mb: 6 }}
                                onClick={() => window.location.href = `${card.link}`}
                            >
                                <FeatureCard
                                    title={card.title}
                                    description={card.subtitle}
                                    image={card.image}
                                    active={active === index}
                                />
                            </Grid>

                        </>
                    )}

                </Grid>
                <ContactUsLandingPage></ContactUsLandingPage>
                <FAQLandingPage></FAQLandingPage>
            </Container>

        </Box>
    )
}