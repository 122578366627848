import i18n from "i18next";
import moment from "moment";
import 'moment/locale/de';
import { initReactI18next } from "react-i18next";

import common_en from "./en/common.json";
import routes_en from "./en/routes.json";
import glossary_en from "./en/glossary.json";
import landingPage_en from "./en/landingPage.json";
import aboutUsPage_en from "./en/aboutUsPage.json";
import aircraftPage_en from "./en/aircraftPage.json";
import charterPage_en from "./en/charterPage.json";
import voucherPage_en from "./en/voucherPage.json";
import dashboardPage_en from "./en/dashboard.json";
import culturePage_en from "./en/culturePage.json";
import partnerPage_en from "./en/partnerPage.json";
import gettingStartedPage_en from "./en/gettingStartedPage.json";
import errorCodes_en from "./en/errorCodes.json";
import errorPage_en from "./en/errorPage.json";
import FAQPage_en from "./en/FAQPage.json";
import forPilotsPage_en from "./en/forPilotsPage.json";
import forATOsPage_en from "./en/forATOsPage.json";
import advisoryPage_en from "./en/advisoryPage.json";
import dynamicForms_en from "./en/dynamicForms.json";
import sustainability_en from "./en/SustainabiltyPage.json";
import FeaturePage_en from "./en/FeaturePage.json";
import TrainingPage_en from "./en/TrainingsPage.json";
import ServicesPage_en from "./en/ServicesPage.json";
import SolutionsPage_en from "./en/SolutionsPage.json";
import ResponsibilityPage_en from "./en/ResponsibilityPage.json";

// import common_de from "./de/common.json";
// import routes_de from "./de/routes.json";
// import glossary_de from "./de/glossary.json";
import landingPage_de from "./de/landingPage.json";
// import aboutUsPage_de from "./de/aboutUsPage.json";
// import aircraftPage_de from "./de/aircraftPage.json";
// import charterPage_de from "./de/charterPage.json";
// import voucherPage_de from "./de/voucherPage.json";
// import dashboardPage_de from "./de/dashboard.json";
// import culturePage_de from "./de/culturePage.json";
// import partnerPage_de from "./de/partnerPage.json";
// import gettingStartedPage_de from "./de/gettingStartedPage.json";
// import errorCodes_de from "./de/errorCodes.json";
// import errorPage_de from "./de/errorPage.json";
// import FAQPage_de from "./de/FAQPage.json";
// import forPilotsPage_de from "./de/forPilotsPage.json";
// import forATOsPage_de from "./de/forATOsPage.json";
// import advisoryPage_de from "./de/advisoryPage.json";
// import dynamicForms_de from "./de/dynamicForms.json";
// import sustainability_de from "./de/SustainabiltyPage.json";

// import common_es from "./es/common.json";
// import routes_es from "./es/routes.json";
// import glossary_es from "./es/glossary.json";
import landingPage_es from "./es/landingPage.json";
// import aboutUsPage_es from "./es/aboutUsPage.json";
// import aircraftPage_es from "./es/aircraftPage.json";
// import charterPage_es from "./es/charterPage.json";
// import voucherPage_es from "./es/voucherPage.json";
// import dashboardPage_es from "./es/dashboard.json";
// import culturePage_es from "./es/culturePage.json";
// import partnerPage_es from "./es/partnerPage.json";
// import gettingStartedPage_es from "./es/gettingStartedPage.json";
// import errorCodes_es from "./es/errorCoess.json";
// import errorPage_es from "./es/errorPage.json";
// import FAQPage_es from "./es/FAQPage.json";
// import forPilotsPage_es from "./es/forPilotsPage.json";
// import forATOsPage_es from "./es/forATOsPage.json";
// import advisoryPage_es from "./es/advisoryPage.json";
// import dynamicForms_es from "./es/dynamicForms.json";
// import sustainability_es from "./es/SustainabiltyPage.json";

// import common_fr from "./fr/common.json";
// import routes_fr from "./fr/routes.json";
// import glossary_fr from "./fr/glossary.json";
import landingPage_fr from "./fr/landingPage.json";
// import aboutUsPage_fr from "./fr/aboutUsPage.json";
// import aircraftPage_fr from "./fr/aircraftPage.json";
// import charterPage_fr from "./fr/charterPage.json";
// import voucherPage_fr from "./fr/voucherPage.json";
// import dashboardPage_fr from "./fr/dashboard.json";
// import culturePage_fr from "./fr/culturePage.json";
// import partnerPage_fr from "./fr/partnerPage.json";
// import gettingStartedPage_fr from "./fr/gettingStartedPage.json";
// import errorCodes_fr from "./fr/errorCoess.json";
// import errorPage_fr from "./fr/errorPage.json";
// import FAQPage_fr from "./fr/FAQPage.json";
// import forPilotsPage_fr from "./fr/forPilotsPage.json";
// import forATOsPage_fr from "./fr/forATOsPage.json";
// import advisoryPage_fr from "./fr/advisoryPage.json";
// import dynamicForms_fr from "./fr/dynamicForms.json";
// import sustainability_fr from "./fr/SustainabiltyPage.json";

import landingPage_wookie from "./wookie/landingPage.json";
import landingPage_pirate from "./pirate/landingPage.json";

export var locale = window.navigator.userLanguage || window.navigator.language || "en";


var languages = [
  { value: "en", label: "english" },
  { value: "de", label: "deutsch" },
  //{ value: "fr", label: "français" },
  //{ value: "es", label: "español" }
]

var aprilFools = { month: 4, day: 1 }
var mayTheFourth = { month: 5, day: 4 }

var date = moment();
var day = date.date();
var month = (date.month() + 1);
if (month == aprilFools.month && day == aprilFools.day) {
  languages.push({ value: "pirate", label: "pirate" })
}
if (month == mayTheFourth.month && day == mayTheFourth.day) {
  languages.push({ value: "wookie", label: "wookie" })
}

export const availableLanguages = languages;

i18n
  .use(initReactI18next)
  .init({
    lng: moment.locale(locale.split('-')[0]),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        error: errorCodes_en,
        common: common_en,
        routes: routes_en,
        glossary: glossary_en,
        landingPage: landingPage_en,
        aboutUsPage: aboutUsPage_en,
        aircraftPage: aircraftPage_en,
        charterPage: charterPage_en,
        voucherPage: voucherPage_en,
        dashboard: dashboardPage_en,
        culturePage: culturePage_en,
        partnerPage: partnerPage_en,
        gettingStartedPage: gettingStartedPage_en,
        errorPage: errorPage_en,
        FAQPage: FAQPage_en,
        FeaturePage: FeaturePage_en,
        forPilotsPage: forPilotsPage_en,
        forATOsPage: forATOsPage_en,
        advisoryPage: advisoryPage_en,
        dynamicForms: dynamicForms_en,
        sustainabiltyPage: sustainability_en,
        TrainingPage: TrainingPage_en,
        ServicesPage: ServicesPage_en,
        SolutionPage:SolutionsPage_en,
        ResponsibilityPage:ResponsibilityPage_en
    
        
      },
      de: {
        // error: errorCodes_de,
        // common: common_de,
        // routes: routes_de,
        // glossary: glossary_de,
        landingPage: landingPage_de,
        // aboutUsPage: aboutUsPage_de,
        // aircraftPage: aircraftPage_de,
        // charterPage: charterPage_de,
        // voucherPage: voucherPage_de,
        // dashboard: dashboardPage_de,
        // culturePage: culturePage_de,
        // partnerPage: partnerPage_de,
        // gettingStartedPage: gettingStartedPage_de,
        // errorPage: errorPage_de,
        // FAQPage: FAQPage_de,
        // forPilotsPage: forPilotsPage_de,
        // forATOsPage: forATOsPage_de,
        // advisoryPage: advisoryPage_de,
        // dynamicForms: dynamicForms_de,
        // sustainabiltyPage: sustainability_de
      },
      es: {
        // error: errorCodes_es,
        // common: common_es,
        // routes: routes_es,
        // glossary: glossary_es,
        landingPage: landingPage_es,
        // aboutUsPage: aboutUsPage_es,
        // aircraftPage: aircraftPage_es,
        // charterPage: charterPage_es,
        // voucherPage: voucherPage_es,
        // dashboard: dashboardPage_es,
        // culturePage: culturePage_es,
        // partnerPage: partnerPage_es,
        // gettingStartedPage: gettingStartedPage_es,
        // errorPage: errorPage_es,
        // FAQPage: FAQPage_es,
        // forPilotsPage: forPilotsPage_es,
        // forATOsPage: forATOsPage_es,
        // advisoryPage: advisoryPage_es,
        // dynamicForms: dynamicForms_es,
        // sustainabiltyPage: sustainability_es},
      },
      fr: {
        // error: errorCodes_fr,
        // common: common_fr,
        // routes: routes_fr,
        // glossary: glossary_fr,
        landingPage: landingPage_fr,
        // aboutUsPage: aboutUsPage_fr,
        // aircraftPage: aircraftPage_fr,
        // charterPage: charterPage_fr,
        // voucherPage: voucherPage_fr,
        // dashboard: dashboardPage_fr,
        // culturePage: culturePage_fr,
        // partnerPage: partnerPage_fr,
        // gettingStartedPage: gettingStartedPage_fr,
        // errorPage: errorPage_fr,
        // FAQPage: FAQPage_fr,
        // forPilotsPage: forPilotsPage_fr,
        // forATOsPage: forATOsPage_fr,
        // advisoryPage: advisoryPage_fr,
        // dynamicForms: dynamicForms_fr,
        // sustainabiltyPage: sustainability_fr},
      },
      wookie: {
        landingPage: landingPage_wookie,
      },
      pirate: {
        landingPage: landingPage_pirate,
      },
    }
  });

export default i18n;
