
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { useModal } from "hooks/modalHook";
import AeroLoading from "components/Progress/AeroLoading";
import { restPut } from "lib/rest";
import _ from "lodash";
import { Stack } from "@mui/material";
import Alert from "components/Alert";
import ClearIcon from '@mui/icons-material/ErrorOutline';
import { restPost } from "lib/rest";
import { useRefresher } from "hooks/refreshHook";

function PostConfirmationModal({ type, id, body }) {
    const { t } = useTranslation();
    const { removeModal } = useModal();
    const { refresh } = useRefresher();
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState([]);

    useEffect(async () => {
        switch (type) {
            case "confirmBooking":
                await restPut(null, `booking/${id}/status/confirmed/`)
                break;
            case "deleteDocument":
                await restPost(null, `currency/${id}`)
                break;
            case "revoke":
                await restPut(null, `checkride/${id}/status/revoked/`)
                break;
            case "reject":
                await restPut(null, `checkride/${id}/status/rejected`)
                break;
            case "acceptJoin":
                await restPost(null, `charter/${id}/staff/`, body)
                break;
            case "declineJoin":
                await restPost(null, `charter/${id}/join/`, body)
                break;
            case "redeem":
                await restPost(null, `voucher/${id}/status/redeemed`)
                break;
            case "refund":
                await restPost(null, `voucher/${id}/status/refunded`)
                break;
            case "expire":
                await restPost(null, `voucher/${id}/status/expired`)
                break;
            case "payVoucher":
                await restPost(null, `voucher/${id}/status/paid`)
                break;
            case "cancelVoucher":
                await restPost(null, `voucher/${id}/status/paid`)
                break;
        }
        removeModal();
        refresh();
    }, [])

    return (
        <DefaultModal
            title={(!!body && (body.reg ?? ""))}
            closeExplicit={!(errors.length !== 0)}
            cancel={{
                show: errors.length !== 0,
                text: "Back",
                color: "error",
            }}
            confirm={{ show: false }}
        >{errors.length === 0 &&
            <AeroLoading customText={status} />
            }
            {errors.length !== 0 &&
                <Stack>{!!errors &&
                    errors.map((error, i) =>
                        <Alert color="error" key={"Booking-Alert-" + i}>
                            <ClearIcon fontSize="small" />&nbsp;
                            {t("error:" + error.code)}
                        </Alert>)}
                </Stack>
            }

        </DefaultModal >
    );
}

export default PostConfirmationModal;