import DefaultModal from "components/Modal/DefaultModal";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import TableCell from "components/Tables/TableCell";
import { Card, Stack, Switch } from "@mui/material";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { restPost } from "lib/rest";
import { restGet } from "lib/rest";
import Accordion from "components/Accordion";
import CharterNotification from "./Notifications/CharterNotification";
import PersonalNotification from "./Notifications/PersonalNotification";

function NoticationSettings() {

    const { t } = useTranslation();
    const checklist = [1, 3, 45, 5]

    return (
        <Card>
            <Box sx={{ position: "absolute", top: "-100px" }} id="notification"></Box>
            <TypographyBlock
                title={t("common:notifications.title")}
                actionName={t("common:button.save")}
            //    action={submitNotifications}

            />

            <Stack p={3} spacing={2}>

                <Accordion title={"Personal"}   >
                    <PersonalNotification></PersonalNotification>
                </Accordion>

                <Accordion title={"Organization"}  >
                    <CharterNotification />
                </Accordion>

            </Stack>

        </Card>

    );
}

export default NoticationSettings;