import Button from "components/Button";
import DefaultModal from "components/Modal/DefaultModal";
import Typography from "components/Typography";

function ContactSupportModal({ params }) {

    return (
        <DefaultModal
            title={"Something went wrong"}
            cancel={{ show: true }}
            confirm={{ show: true }}
        >
            <Typography>Please Contact our support.</Typography>
            <Button
                component='a'
                variant="gradient"
                color="dark"
                href={"mailto:support@aeromate.eu" + ((params && ("?body=%0D%0A%0D%0Aerror details (please do not delete. Details are necessary for our support.):%0D%0A" + JSON.stringify(params, null, 2))) ?? "")}
            >
                Send Email
            </Button>
        </DefaultModal >
    );
}

export default ContactSupportModal;
