import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Card from "@mui/material/Card";
import Typography from "components/Typography";
import { restGet } from "lib/rest"
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AirportInfo from "components/Airport/AirportInfo";
import AirportTabs from "./sections/AirportTabs";
import StarRating from "components/Rating/StarRating";
import AeroLoading from "components/Progress/AeroLoading"
import { DocumentTitle } from "hooks/useDocumentTitle";
import { isNotEmptyArray } from "lib/functions";
import { Stack } from "@mui/material";
import Button from "components/Button";
import { useUser } from "hooks/userDataHook";
import { useModal } from "hooks/modalHook";
import Newslettermodal from "components/Modal/NewsletterModal";
import { restPost } from "lib/rest";
import { toUpper } from "lodash";
import AirportHelmet from "components/Helmets/AirportHelmet";
import { useRefresher } from "hooks/refreshHook";

export default function Body() {
    const { t } = useTranslation();
    let { ICAO } = useParams();
    const [isloading, setIsLoading] = useState(false)
    const [airport, setAirport] = useState()
    const [subscribe, setSubscribe] = useState(false)
    const [subscribetxt, setSubscribetxt] = useState('subscribe')
    const [color, setColor] = useState('primary')
    const userData = useUser();
    let { addModal } = useModal();
    const [newsletter, setNewsletter] = useState()
    const { refresh } = useRefresher();


    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setAirport(resp.data[0])
            }
            setIsLoading(true)
            await restGet(restCallback, 'airport/' + ICAO)
            setIsLoading(false)
        }
        !!ICAO && fetch()
    }, [ICAO])

    useEffect(() => {

        if (userData?.data) {
            const fetch = async () => {
                function restCallback(resp) {
                    !!resp && !!resp.data && setNewsletter(resp.data[0])
                    !!resp && !!resp.data && !!resp.data.subscribe && setSubscribe(resp.data[0].subscribe)
                    if (!!resp?.data[0]?.subscribe == true) {
                        setColor('light')
                        setSubscribetxt('subscribed')
                    }
                }

                await restGet(restCallback, 'newsletter/' + userData.data.email + '/email/airport_' + toUpper(ICAO))

            }
            !!userData.data && fetch()

        }
        else {

            if (JSON.parse(localStorage.getItem(ICAO)) || false) {
                setColor('light')
                setSubscribetxt('subscribed')
            }

        }
    }, [userData.data])

    const [title, setTitle] = useState(t("routes:airport.title"))
    useEffect(() => {
        !!airport && setTitle(t("routes:airport.title") + " " + airport.icao + " " + airport.name)

    }, [airport])

    if (!airport || isloading) {
        return isloading ? <AeroLoading /> : null;
    }

    async function saveEmail() {

        var object = { icao: airport.icao, type: 'airport', subscribe: !subscribe, email: userData.data.email ,notify:userData.data.notify,code:2 }
        if (!!newsletter?._id) {
            var object = { icao: airport.icao, type: 'airport', subscribe: !subscribe, email: userData.data.email, id: newsletter._id.$oid ,notify:userData.data.notify ,code:2 }
        }
        await restPost(null, `newsletter/Newsletter`, object)
        refresh()
        


    }


    const onSubscribe = (color) => {

        if (userData.data != "") {
            if (!subscribe == true) {

                setColor('light')
                setSubscribetxt('subscribed')
                saveEmail();
                setSubscribe(!subscribe)

            }
            else {

                setColor('primary')
                setSubscribetxt('subscribe')
                setSubscribe(!subscribe)
                saveEmail();





            }
        }
        else {

            addModal(<Newslettermodal airport={airport.icao} type={"airport"}></Newslettermodal>)

        }

    };



    return (
        <Box p={3}>
            {airport && <AirportHelmet airport={airport} />}
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Card sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
                        <Box variant="gradient" bgColor="dark" height="100%">

                            <Stack p={3} lineHeight={1} direction="row" justifyContent="space-between">
                                <Box >
                                    <Typography variant="h1" fontWeight="bold" color="white" mb={0.5}>
                                        {airport.name}
                                    </Typography>
                                    <StarRating text={t('common:label.recension', { count: airport?.ratings_stat?.rating_cnt ?? 0 })} rating={airport?.ratings_stat?.avg_rating ?? 0} color="white" />
                                </Box>

                                <Box>
                                    <Button
                                        variant="gradient"
                                        color={color}
                                        onClick={onSubscribe}
                                    >
                                        {/* on subscribe add bell icon and change color to light */}
                                        {subscribetxt}
                                    </Button>
                                </Box>

                            </Stack>

                            <AirportInfo airport={airport} color="white" />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Box mt={8} mb={2}>
                <Box mb={1} ml={2}>
                    <AirportTabs airport={airport} />
                </Box>
            </Box>
        </Box>
    )
}