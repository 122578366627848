import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import { useTranslation } from "react-i18next";
import ComingSoon from "components/Common/ComingSoon";
import CharterAircrafts from "components/Charter/CharterAircrafts";
import CharterShopTab from "./CharterShopTab";
import { useLocation } from "react-router-dom";
import CharterReviewTab from "./CharterReviewsTab";
import CharterCourseTab from "./CharterCourseTab";

function CharterTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="CharterTabPanel"
            hidden={value !== index}
            id={`simple-CharterTabPanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index &&
                children
            }
        </div>
    );
}

function CharterTabs({ charter }) {
    const { t } = useTranslation();
    const { hash } = useLocation();
    const getTab = () => {
        switch (hash) {
            case "#recensions": return (0)
            case "#shop": return (1)
            case "#courses": return (2)
            case "#aircrafts": return (3)
            default: return (0)
        }
    }
    const [activeTab, setActiveTab] = useState(getTab());


    const handleTabType = (event, newValue) => {
        setActiveTab(newValue);
        window.location.hash = "#" + event.target.id
    }

    return (
        <Box>
            <AppBar position="static">
                <Tabs variant="fullWidth" value={activeTab} onChange={handleTabType} >
                    <Tab label={t('common:label.recensions')} icon={<Icon>reviews</Icon>} id="recensions" />
                    <Tab label={t('common:label.shop')} icon={<Icon>store</Icon>} id="shop" />
                    <Tab label={t('common:label.courses')} icon={<Icon>school</Icon>} id="courses" />
                    <Tab label={t('common:label.aircrafts')} icon={<Icon>flight</Icon>} id="aircrafts" />
                </Tabs>
            </AppBar>
            <CharterTabPanel value={activeTab} index={0}>
                <Box p={3} lineHeight={1}>
                    <CharterReviewTab charter={charter} />
                </Box>
            </CharterTabPanel>
            <CharterTabPanel value={activeTab} index={1}>
                <Box p={3} lineHeight={1}>
                    <CharterShopTab charter={charter} />
                </Box>
            </CharterTabPanel>
            <CharterTabPanel value={activeTab} index={2}>
                <Box p={3} lineHeight={1}>
                    <CharterCourseTab charter={charter} />
                </Box>
            </CharterTabPanel>
            <CharterTabPanel value={activeTab} index={3}>
                <Box p={3} lineHeight={1}>
                    <CharterAircrafts charter={charter} />
                </Box>
            </CharterTabPanel>
        </Box>
    );
}

export default CharterTabs;