import { Grid, AppBar, Tab, Tabs } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import { useCallback, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Cropper from 'react-easy-crop'
import PlaceHolderImage from "assets/images/aircraft.jpg";
import Input from "components/Input";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useModal } from "hooks/modalHook";
import { getBlobImageUrl } from "lib/functions";
import { Image } from "@mui/icons-material";
import Cropmodal from "components/Modal/CropperModal";
import CancelIcon from '@mui/icons-material/Cancel';

export default function ImagesStep({ setData }) {
    const { t } = useTranslation()
    const [description, setDescription] = useState({ en: "" })
    const [tabValue, setTabValue] = useState("en");
    const fileInputRef = useRef(null);
    const [aircraftPreviewImage, setAircraftPreviewImage] = useState(PlaceHolderImage);
    const [selectedImages, setSelectedImages] = useState([]);
    const { addModal } = useModal();
    const [croppedImg, setcroppedImg] = useState('');
  const handleClickBrowse = () => {
        fileInputRef.current.click();
    };
    const Handlechange = (value) => {
        setcroppedImg(value);
    };

     const SelectImage = (event) => {
        const selectedImg = event.target.files;
        const selectedImgArray = Array.from(selectedImg);
        const imagesArray = selectedImgArray.map((file) => {
            return URL.createObjectURL(file);
        });
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
        addModal(<Cropmodal onValueChange={Handlechange} img={imagesArray}></Cropmodal>)
        }
        event.target.value = "";
    };

    useEffect(() => {

        if (croppedImg != "") {
            setSelectedImages((previousImages) => previousImages.concat(croppedImg));
            setAircraftPreviewImage(croppedImg)
        }
    }, [croppedImg])

    useEffect(() => {
        !!selectedImages && setData({ description: description, imageData: selectedImages })

    }, [selectedImages,description]);

    function deleteHandler(image) {
        setSelectedImages(selectedImages.filter((e) => e !== image));
        URL.revokeObjectURL(image);
    }
  
    const Drag = (event, index) => {
        event.dataTransfer.setData('text/plain', index);
    };

    const Dragover = (event, index) => {
        event.preventDefault();
    };

    const Drop = (event, dropIndex) => {
        event.preventDefault();
        const draggedIndex = event.dataTransfer.getData('text/plain');
        const updatedImages = [...selectedImages];
        const draggedImage = updatedImages[draggedIndex];
        updatedImages.splice(draggedIndex, 1);
        updatedImages.splice(dropIndex, 0, draggedImage);
        setSelectedImages(updatedImages);
    };

    const Click = (image) => {
        setAircraftPreviewImage(image);
    } 

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:charter.aircrafts.steps.image.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:charter.aircrafts.steps.image.description")}
                </Typography>
            </Box>

            <Box mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TypographyBlock
                            title={"Description"}
                        />
                        <Box pl={3}>
                            <AppBar position="static">
                                <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
                                    <Tab label={"en"} value={"en"} />
                                    <Tab label={"de"} value={"de"} />
                                </Tabs>
                            </AppBar>
                        </Box>
                        <Box pl={3} pt={3}>
                            <Input
                                fullWidth
                                sx={{ width: "100% !important" }}
                                type="text"
                                multiline
                                rows={8}
                                value={description[tabValue]}
                                onChange={(event) => setDescription({ ...description, [tabValue]: event.target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} pr={3} >
                        <TypographyBlock
                            title={"Image upload"}
                        />
                        <Box sx={{
                            "position": "relative",
                            "width": "100%",
                            "height": "80%"
                        }}>
                            <img src={aircraftPreviewImage} style={{ overflow: "hidden", maxWidth: "100%", maxHeight: "100%" }} alt="upload" />

                        </Box>
                    </Grid>
                </Grid>
                <Grid mt={2} p={1} container sx={{ height: "auto" }} justifyContent="space-between" alignItems="center">

                    <Grid item xs={12} sm={9} p={3} sx={{ height: "100%" }}>
                         <Box sx={{ display: "flex", overflowX: "auto", scrollBehavior: "smooth", "&::-webkit-scrollbar": { height: "5px", width: "3px", backgroundColor: "#f5f5f5" }, "&::-webkit-scrollbar-thumb": { backgroundColor: "#7b809a" } }}>
                            {selectedImages.map((image, index) => (
                                <Box
                                    key={image}
                                    ml={1}
                                    sx={{ position: "relative" }}
                                    draggable
                                    onDragStart={(e) => Drag(e, index)}
                                    onDragOver={(e) => Dragover(e, index)}
                                    onDrop={(e) => Drop(e, index)}
                                >
                                    <img onClick={() => Click(image)} src={image} height="100" alt="upload" />
                                    <CancelIcon
                                        fontSize="medium"
                                        onClick={() => deleteHandler(image)}
                                        sx={{ position: "absolute", top: "1px", right: "1px", cursor: "pointer" }}
                                    />
                                </Box>
                            ))}
                        </Box> 
                    </Grid>

                     <Grid item xs={6} sm={3} p={3} sx={{ border: 2, borderRadius: "8px", height: "100%" }} onClick={handleClickBrowse}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                            <Image fontSize="large" mb={2} sx={{ marginBottom: "5px" }}></Image>
                            <Typography fontSize="12px">Add More Images</Typography>

                            <Typography sx={{ textAlign: "center" }} fontSize="10px" variant="button" component="span">
                                Click to browse , and order by dragging
                            </Typography>
                            <input
                                ref={fileInputRef}
                                type="file"
                                name="images"
                                onChange={SelectImage}
                                accept="image/png ,image/jpg,image/jpeg"
                                hidden
                            />
                        </Box>

                    </Grid>




                </Grid>
            </Box>
        </Box>
    )
}
