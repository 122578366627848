import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Button from "components/Button";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import Typography from "components/Typography";
import { bookings_url } from "constants/global";
import { useTranslation } from "react-i18next";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import { Stack } from "@mui/material";

function EnterpriseDescription() {
  const { t } = useTranslation();
  const icons = ["class", "cloud_done", "auto_graph", "settings"]
  return (
    <Box pl={6}>
      <Grid container justifyContent="center" spacing={3}>
        {icons.map((icon, index) => <Grid item xs={12} md={6} key={icon + ""}>
          <Box mb={5}>
            <DefaultInfoCard
              color="light"
              textColor="light"
              icon={icon}
              title={t("landingPage:enterprise.item" + (index + 1) + ".title")}
              description={t("landingPage:enterprise.item" + (index + 1) + ".description")}
            />
          </Box>
        </Grid>)}
      </Grid>
    </Box>
  )
}

function Enterprise() {
  const { t } = useTranslation();
  const image = "https://media.westeurope.aero-services.net/aeromate/product/83061FF2-88EC-4B52-AA32-8F087A8A3EA3_1_105_c.jpeg"

  return (
    <Box component="section" py={12}>
      <Grid
        container
        item
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        xs={10}
        sx={{ mx: "auto", textAlign: "center" }}
        pb={{ xs: 0, md: 5 }}
      >
        {/*
        <Typography variant="h2" mt={3}>
          {t("landingPage:enterprise.title")}
        </Typography>
        */}
      </Grid>
      <Grid container item xs={10} sx={{ mx: "auto" }}>
        <Box variant="gradient" bgColor="dark" borderRadius="lg" width="100%" position="relative" py={6}>
          <Box
            component="img"
            src={bgPattern}
            alt="pattern-lines"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            opacity={0.6}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} xl={6} >
              <Stack direction="column">
                <Box
                  component="img"
                  src={image}
                  alt="image"
                  sx={{
                    objectFit: "cover",
                    ml: { xs: 0, xl: 3 },
                    mt: { xs: -6, xl: -12 }
                  }}
                  borderRadius="md"
                  width="100%"
                  position="relative"
                />
                <Stack direction="row" spacing={3} alignItems="center" sx={{ p: 3, m: "auto" }}>
                  <Box>
                    <Typography variant="h3" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                      {t("landingPage:enterprise.call2action.title")}
                    </Typography>
                    <Typography component="p" variant="body2" color="white" opacity={0.8} mb={2}>
                      {t("landingPage:enterprise.call2action.description.0")}
                    </Typography>
                    <Typography component="p" variant="body2" color="white" opacity={0.8} mb={2}>
                      {t("landingPage:enterprise.call2action.description.1")}
                    </Typography>
                    <Typography component="p" variant="body2" color="white" opacity={0.8} mb={2}>
                      {t("landingPage:enterprise.call2action.description.2")}
                    </Typography>
                    <Button color="primary" onClick={() => { window.open(bookings_url, '_blank') }}>
                      {t("landingPage:enterprise.call2action.button")}
                    </Button>
                  </Box>

                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              xl={6}
              sx={{
                position: "relative",
                P: { xs: 3, md: 0 },
                px: { xs: 6, md: 2 },
                mt: { xs: 3, md: 0 },
                my: { xs: 0, md: "auto" },
                color: ({ palette: { white } }) => white.main,
              }}
            >
              <EnterpriseDescription />
            </Grid>
          </Grid>
        </Box>
      </Grid >
    </Box >
  );
}

export default Enterprise;