import { useState, useEffect, useRef } from "react";
import { Card, Grid, AppBar, Tabs, Tab } from "@mui/material";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import Typography from "components/Typography";
import Avatar from "components/Avatar";
import breakpoints from "assets/theme/base/breakpoints";

import TypographyBlock from "components/Typography/TypographyBlock";
import { useUser } from "hooks/userDataHook";
import { useLocation } from "react-router-dom";
import Profile from "./sections/profile";
import Enrollment from "./sections/enrollment";
import Settings from "./sections/settings";
import placeholderAvatar from "assets/images/avatar.png"
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CropmodalCircle from "components/Modal/CropperModalCircle";
import { useModal } from "hooks/modalHook";
import CropmodalHeader from "components/Modal/CropperModalHeader";
import Button from "components/Button";
import { getBlobImageUrl } from "lib/functions";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function Overview() {
    const [avatar, setAvatar] = useState(placeholderAvatar);
    const [header, setHeader] = useState("https://media.westeurope.aero-services.net/aeromate/header/rough-horn-2146181_1920.jpg");
    const fileInputRef = useRef(null);
    const fileInputHeader = useRef(null);

    const { data } = useUser();
    const { hash } = useLocation();
    const { addModal, removeModal } = useModal();
    const [croppedImg, setcroppedImg] = useState('');


    const getTab = () => {
        switch (hash) {
            case "#profile": return (0)
            case "#enrollment": return (1)
            case "#settings": return (2)
            default: return (0)
        }
    }

    const handleClickBrowse = () => {
        fileInputRef.current.click();
    };
    const handleClickBrowseHeader = () => {
        fileInputHeader.current.click();
    };
    const Handlechange = (value) => {
        setAvatar(value);

    };
    const HandleHeader = (value) => {
        setHeader(value);



    };

    useEffect(() => {
        if (!!data?.image?.Avatar) {
            setAvatar(getBlobImageUrl(data.image.Avatar))
        }
        if (!!data?.image?.Header) {
            setHeader(getBlobImageUrl(data.image.Header))
        }

    }, []);


    const [tabValue, setTabValue] = useState(getTab());
    const [content, setContent] = useState(null)
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = "#" + event.target.id
    }

    const SelectImage = (event) => {
        const selectedImg = event?.target?.files;
        if (selectedImg.length > 0) {
            const selectedImgArray = Array.from(selectedImg);
            const imagesArray = selectedImgArray.map((file) => {
                return URL.createObjectURL(file);
            });
            if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
            addModal(<CropmodalCircle onValueChange={Handlechange} img={imagesArray}></CropmodalCircle>)
            }
        }

    };

    const SelectHeader = (event) => {
        const selectedImg = event?.target?.files;
        if (selectedImg?.length > 0) {
            const selectedImgArray = Array.from(selectedImg);
            const imagesArray = selectedImgArray.map((file) => {
                return URL.createObjectURL(file);
            });
            if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
            addModal(<CropmodalHeader onValueChange={HandleHeader} img={imagesArray}></CropmodalHeader>)
            }
        }
    };

    useEffect(() => {
        switch (tabValue) {
            case 0:
                setContent(<Profile />)
                break;
            case 1:
                setContent(<Enrollment />)
                break;
            case 2:
                setContent(<Settings />)
                break;
            default:
                break;
        }
    }, [tabValue])

    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }
        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const copy = async () => {
        await navigator.clipboard.writeText(window.location.origin + "/user/" + data._id.$oid);
    }

    return (
        <Box position="relative" mb={5}>
            <Box
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{
                    backgroundImage: `url(${header})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}


            />
            <Button circular={true} disableRipple={true} color="primary" variant="gradient" size="small" iconOnly onClick={handleClickBrowseHeader} sx={{ position: "absolute", top: "8px", right: "8px", cursor: "pointer" }} >
                <input
                    ref={fileInputHeader}
                    type="file"
                    name="images"
                    onChange={SelectHeader}
                    accept="image/png ,image/jpg,image/jpeg"
                    hidden
                />
                <CameraAltIcon></CameraAltIcon>
            </Button>


            <Card
                sx={{
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <Box sx={{ position: "relative" }}>

                            <Avatar src={avatar} alt="profile-image" size="xl" shadow="sm" />
                            {/* <CameraIcon fontSize="medium" onClick={handleClickBrowse} sx={{ position: "absolute", top: "1px", right: "1px", cursor: "pointer" }}   ></CameraIcon> */}
                            <Button circular={true} disableRipple={true} color="primary" variant="gradient" size="small" iconOnly onClick={handleClickBrowse} sx={{ position: "absolute", top: "-6px", right: "-2px", cursor: "pointer" }} >
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    name="images"
                                    onChange={SelectImage}
                                    accept="image/png ,image/jpg,image/jpeg"
                                    hidden
                                />
                                <CameraAltIcon></CameraAltIcon>
                            </Button>

                        </Box>


                    </Grid>
                    <Grid item>
                        <Box height="100%" mt={0.5} lineHeight={1}>
                            <Typography variant="h5" fontWeight="medium">
                                {data?.name ?? ""} <Button iconOnly disableRipple onClick={copy}><ContentCopyIcon /></Button>
                            </Typography>
                            <Typography variant="button" color="text" fontWeight="regular">
                                {data?.license ?? ""}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}>
                        <AppBar position="static">
                            <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                                <Tab
                                    label="Profile"
                                    id="profile"
                                    icon={
                                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                            home
                                        </Icon>
                                    }
                                />
                                <Tab
                                    label="Enrollment"
                                    id="enrollment"
                                    icon={
                                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                            email
                                        </Icon>
                                    }
                                />
                                <Tab
                                    label="Settings"
                                    id="settings"
                                    icon={
                                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                            settings
                                        </Icon>
                                    }
                                />
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
            </Card>
            <Box p={2}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={12} xl={12}>
                        {content}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Overview;