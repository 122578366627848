/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import DefaultPricingCard from "components/Cards/PricingCards/DefaultPricingCard";
import { bookings_url } from "constants/global";
import { Stack } from "@mui/system";

// Material Kit 2 PRO React examples

function Pricing() {

  return (
    <Box component="section" pb={{ xs: 0, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <Stack>
            <Typography variant="h2">Pick the best plan for you</Typography>
            <Typography variant="body2" color="text">
              You have Free Updates and Support on each package.
            </Typography>
          </Stack>
        </Grid>
        <Grid container spacing={3} mt={6} justifyContent="center">
          <Grid item xs={12} sm={6} lg={3}>
            <DefaultPricingCard
              badge={{ color: "dark", label: "Monthly" }}
              price={{ oldValue: "13", value: "10", currency: "EUR", type: "month" }}
              action={{ color: "dark", type: "external", route: bookings_url, label: "Contact Sales" }}
              specifications={[
                { label: "+ one-time setup fee*" },
                { label: "Limited Offer: First 100", includes: true },
                { label: "Flexible", includes: true },
                { label: "50 Codes per month", includes: true },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DefaultPricingCard
              color="dark"
              badge={{ color: "primary", label: "Annual" }}
              price={{ oldValue: "149", value: "100", currency: "EUR", type: "year" }}
              action={{ color: "primary", type: "external", route: bookings_url, label: "Contact Sales" }}
              specifications={[
                { label: "+ one-time setup fee*" },
                { label: "Limited Offer: First 100", includes: true },
                { label: "Lower Cost", includes: true },
                { label: "Unlimited Codes", includes: true },
              ]}
            />
          </Grid>
        </Grid>
        <Grid mt={6} container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <Typography variant="h5" color="text">
            *Setup Fee and Variants
          </Typography>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="h5" color="text">
              Large
            </Typography>
            <Typography variant="body2" color="text">
              Setup Fee 130 €
            </Typography>
            <Typography variant="h6" color="text">
              Dimensions:
            </Typography>
            <Typography variant="body2" color="text">
              Outer dimensions: 138 (W) x 179 (H) x 89 (D) mm, (59 (D) mm without lock).
            </Typography>
            <Typography variant="body2" color="text">
              Internal dimensions per compartment: 130 (W) x 103 (H) x 54 (D) mm top - 118 (W) x 67 (H) x 54 (D) mm bottom
            </Typography>
            <Typography variant="body2" color="text">
              Weight: approx. 1.8 kg
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="h5" color="text">
              Extra Large
            </Typography>
            <Typography variant="body2" color="text">
              Setup Fee 260 €
            </Typography>
            <Typography variant="h6" color="text">
              Dimensions:
            </Typography>
            <Typography variant="body2" color="text">
              Outer dimensions: 238 (W) x 370 (H) x 112 (D) mm.
            </Typography>
            <Typography variant="body2" color="text">
              Internal dimensions: 220 (W) x 330 (H) x 60 (D) mm, (90 mm (D) in the lower half)
            </Typography>
            <Typography variant="body2" color="text">
              Weight: approx. 11.6 kg
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Pricing;
