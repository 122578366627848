import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { restGet } from 'lib/rest';
import Input from "components/Input";
import AeroLoading from 'components/Progress/AeroLoading';
import { Skeleton } from '@mui/material';
import _ from 'lodash';

export default function AirportSearch({ label, onChange, value, ...rest }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [defaultValue, setDefaultValue] = useState()
    useEffect(() => {
        const fetchDefault = async () => {
            setLoading(true)
            const callback = (res) => {
                if (!!res && !!res.data && !!res.data[0]) {
                    setDefaultValue(res.data[0]);
                    typeof (value) === "string" && value !== "" && onChange(res.data[0])
                }
            }
            await restGet(callback, "airport/" + value);
            setLoading(false)
        }
        typeof (value) === "string" && value !== "" && fetchDefault()
        _.isObject(value) && setDefaultValue(value)
        value === null && setDefaultValue(value)
    }, [value])

    const [searchTerm, setSearchTerm] = useState()
    useEffect(() => {
        if (searchTerm !== "")
            setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            (async () => {
                const callback = (res) => {
                    if (!!res && !!res.data && Array.isArray(res.data))
                        setOptions(res.data);
                }
                !!searchTerm && await restGet(callback, "airport", { fuzzy: searchTerm });
                setLoading(false)
            })();
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    if ((typeof (value) === "string" && !defaultValue)
        || (_.isObject(value) && !defaultValue))
        return <Skeleton variant="rectangular" fullWidth height={66} />
    return (
        <Autocomplete
            {...rest}
            id="asynchronous-demo"
            open={open}
            value={defaultValue}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            isOptionEqualToValue={(option, value) => option.icao === value.icao}
            getOptionLabel={(option) => option.icao + (option.iata ? "/" + option.iata : "") + " (" + option.name + ")"}
            onChange={(event, newValue) => { onChange(newValue); }}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <Input
                    {...params}
                    value={searchTerm}
                    onChange={handleChange}
                    label={label ?? "lable"}
                    type="text"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}