import { Grid, Table, TableBody, TableRow } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import { convertFrontendToTimeDatabase } from "lib/functions";
import { millisecondsToHHMM } from "lib/functions";
import { secondsToHHMM } from "lib/functions";
import { restPost } from "lib/rest";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRefresher } from "hooks/refreshHook";
import { useModal } from "hooks/modalHook";
import { convertTimeDatabaseToFrontend } from "lib/functions";

const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
        `${borderWidth[1]} solid ${light.main}`,
};

export default function CreateStepx({ booking, teclog, data }) {
    const { t } = useTranslation();
    const [pdf, setPdf] = useState(false)
    const { refresh } = useRefresher();
    const [image, setImage] = useState(false)
    const [docMetaData, setDocMetaData] = useState({})
    const [retPostMeta, setRetPostMeta] = useState()
    const [currencyId, setCurrencyId] = useState()
    const { addModal, removeModal } = useModal()

    async function create() {

        var tmp = { ...docMetaData }

        if (!!data.equipment.type) tmp.cur_type = data.equipment.type
        if (!!data.equipment.issuedate) tmp.doc_date = convertFrontendToTimeDatabase(data.equipment.issuedate)
        if (!!data.equipment.expirydate) tmp.doc_expire = convertFrontendToTimeDatabase(data.equipment.expirydate)


        const res = await restPost(null, 'currency/' + (currencyId ?? 'new') + '/document', data?.settings?.file, {}, { 'Content-Type': data?.settings?.mime })

        if (res.data.insertedId != null) {

            await restPost(setRetPostMeta, 'currency/' + (res.data.insertedId ?? 'new') + '/metadata', tmp)

            refresh()
            removeModal()
            location.reload()
        } else {
            //removeModal()
            //addModal(ErrorModal)
        }

    }
   

    useEffect(() => {
        if (data?.settings?.type == "pdf") {

            setPdf(true)
        } else {

            setImage(true)
        }
    }, []);

//imagestep typograpy block
// 31 14 7 abgelaufen
    return (
        <Box >
            {/* Aircraft Information */}
            <Grid container>
                <Grid item sm={4} >
                    <Box position="relative">
                        <Box width="100%" mb={3}>
                            <Typography variant="h5" fontWeight="medium">
                            {t("dashboard:personal.upload.certificate")}
                            </Typography>
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            alignItems={{ xs: "flex-start", md: "center" }}
                            mt={{ xs: 3, md: 0 }}
                        >
                            <Box width="100px">
                                <Typography
                                    variant="h6"
                                    color="secondary"
                                    fontWeight="regular"
                                >
                                   {t("dashboard:personal.upload.document")}
                                </Typography>
                            </Box>
                            <Box >
                                <Typography variant="h6" fontWeight="medium">
                                    {data.equipment.typelabel}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            alignItems={{ xs: "flex-start", md: "center" }}
                        >
                            <Box width="100px">
                                <Typography
                                    variant="h6"
                                    color="secondary"
                                    fontWeight="regular"
                                >
                                    {t("dashboard:personal.upload.issue_date")}
                                </Typography>
                            </Box>
                            <Box >
                                <Typography variant="h6" fontWeight="medium">
                                    {moment(data.equipment.issuedate).format("L")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            alignItems={{ xs: "flex-start", md: "center" }}
                        >
                            <Box width="100px">
                                <Typography
                                    variant="h6"
                                    color="secondary"
                                    fontWeight="regular"
                                >
                                    {t("dashboard:personal.upload.expiry_date")}
                                </Typography>
                            </Box>
                            <Box >
                                <Typography variant="h6" fontWeight="medium">
                                    {data.equipment.expirydate && moment(data.equipment.expirydate).format("L")}
                                </Typography>
                            </Box>
                        </Box>

                    </Box>

                </Grid>

                <Grid item sm={8}>


                    <Box sx={{
                        "position": "relative",
                        "width": "100%",
                        "height": "80%"
                    }}>
                        {image && <img src={data?.settings?.imageData} style={{ overflow: "hidden", maxWidth: "100%", maxHeight: "100%" }} alt="upload" />}
                        {pdf && <iframe
                            style={{ width: "100%", height: "75%", minHeight: "400px" }}
                            src={data?.settings?.imageData ?? ''}
                        >
                        </iframe>}

                    </Box>

                </Grid>
            </Grid>
        </Box >
    )
}
