import { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import AutoComplete from './AutoComplete';



function getStyles(name, sales, theme) {
  if (!!name) {
    return {
      fontWeight:
        name.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };

  }
}




export default function TaxCodeInput({ preset, label, onChange, codes, ...rest }) {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (!!preset && !!codes) {
      setSelectedValues(_.filter(codes, (c) => _.includes(preset, c.id)))
    }
  }, [preset, codes]);

  useEffect(() => {
    onChange(selectedValues)
  }, [selectedValues]);

  return (

    <Box sx={{ m: 1, width: { xs: "256px", lg: "512px" } }}>
      <AutoComplete
        multiple
        options={codes ?? []}
        value={selectedValues}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={setSelectedValues}
        getOptionLabel={(option) => "(" + option.country + ")" + " " + option.description}
      />
    </Box>
  );
}