import { useEffect, useState } from "react";
import { Card, Grid, Stepper, Step, StepLabel } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import InfoStep from "./steps/InfoStep";

import RequiredStep from "aeromate-dashboard/Charter/Aircrafts/AircraftCreateForm/components/RequiredStep";
import CreateStep from "aeromate-dashboard/Charter/Aircrafts/AircraftCreateForm/components/CreateStep";
import NextSteps from "aeromate-dashboard/Charter/Aircrafts/AircraftCreateForm/components/NextSteps";
import ImagesSteps from "./steps/imageStep";
import CreateStepx from "./steps/create";
import { useRefresher } from "hooks/refreshHook";
import { restPost } from "lib/rest";
import { convertFrontendToTimeDatabase } from "lib/functions";
import { useModal } from "hooks/modalHook";
import LoadingModal from "components/Modal/LoadingModal";

function AddDocument() {
    const { t } = useTranslation();
    const [stepData, setStepData] = useState({})
    const [docMetaData, setDocMetaData] = useState({})
    const [retPostMeta, setRetPostMeta] = useState()
    const [currencyId, setCurrencyId] = useState()
    const { addModal, removeModal } = useModal()

    const setData = (data) => {
        setStepData({ ...stepData, [content[activeStep].id]: data })
    }
    const reset = () => {
        setStepData({})
        setActiveStep(0)
    }

    console.log(stepData)
    const content = [
        {
            id: "settings",
            label: t("dashboard:personal.upload.upload"),
            component: <ImagesSteps data={stepData} setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => {
                return !!stepData && !!stepData.settings && !!stepData.settings.imageData && !!stepData.settings.type && !!stepData.settings.mime && !!stepData.settings.file

            }
        },
        {
            id: "equipment",
            label: t("dashboard:personal.upload.info"),
            component: <InfoStep data={stepData} images={stepData?.settings?.imageData} types={stepData?.settings?.type} setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => {
                return !!stepData && !!stepData.equipment && !!stepData.equipment.type && !!stepData.equipment.issuedate
            }
        },
        {
            id: "creation",
            label: t("dashboard:personal.upload.end"),
            component: <CreateStepx data={stepData} setData={setData} />,
            handleBack: () => { setActiveStep(activeStep - 1) },
            handleNext: () => { create() },
            isValid: () => { return true; },
        },
    ]


    async function create() {

        var tmp = { ...docMetaData }
        addModal(<LoadingModal></LoadingModal>)

        if (!!stepData.equipment.type) tmp.cur_type = stepData.equipment.type.key
        if (!!stepData.equipment.issuedate) tmp.doc_date = convertFrontendToTimeDatabase(stepData.equipment.issuedate)
        if (!!stepData.equipment.expirydate) tmp.doc_expire = convertFrontendToTimeDatabase(stepData.equipment.expirydate)


        const res = await restPost(null, 'currency/' + (currencyId ?? 'new') + '/document', stepData?.settings?.file, {}, { 'Content-Type': stepData?.settings?.mime })

        if (res.data.insertedId != null) {

            await restPost(setRetPostMeta, 'currency/' + (res.data.insertedId ?? 'new') + '/metadata', tmp)
            removeModal()
            location.reload()
        } else {
            //removeModal()
            //addModal(ErrorModal)
        }

    }



    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === content.length;
    return (
        <Box pb={8}>
            <Grid container justifyContent="center" >
                <Grid item xs={12} xl={8}>
                    <Box mb={8} textAlign="center">
                        <Box mb={1}>
                            <Typography variant="h3" fontWeight="bold">
                                {t("dashboard:personal.upload.add_new")}
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight="regular" color="secondary">

                        </Typography>
                    </Box>
                    <Card>
                        <Box mt={-3} mx={2}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {content.map((item) => (
                                    <Step key={item.label}>
                                        <StepLabel>{item.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        <Box p={2}>
                            <Box>
                                {content[activeStep].component}
                                {!isLastStep &&
                                    <Box mt={3} width="100%" display="flex" justifyContent="space-between">
                                        {activeStep === 0 ? (
                                            <Box />
                                        ) : (
                                            <Button
                                                disabled={!!content[activeStep].disbaledButtons}
                                                variant="outlined"
                                                color="dark"
                                                onClick={content[activeStep].handleBack}
                                            >
                                                {t("common:button.back")}
                                            </Button>
                                        )}
                                        <Box>
                                            <Button
                                                disabled={!content[activeStep].isValid() || !!content[activeStep].disbaledButtons}
                                                variant="gradient"
                                                color="dark"
                                                onClick={!isLastStep ? content[activeStep].handleNext : content[activeStep].handleNext}
                                            >
                                                {activeStep === 2 ? t("common:button.create") : t("common:button.next")}
                                            </Button>
                                        </Box></Box>
                                }
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AddDocument;