import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { __CONSOLE_LOG_DISABLED__ } from "lib/settings/settings";

if (__CONSOLE_LOG_DISABLED__) console.log = () => { };


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
