import Box from "components/Box";

function Header() {
    return (<Box
        minHeight="50vh"
        width="100%"
        sx={{
            backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                linearGradient(gradients.dark.main, gradients.dark.state),
        }} />
    );
}

export default Header;