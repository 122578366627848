import { Box, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import DefaultModal from "components/Modal/DefaultModal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import DownloadModal from "./DownloadModal";
import { useEffect, useState } from "react";
import Input from "components/Input";
import { useModal } from "hooks/modalHook";
import { restGet } from "lib/rest";

function CertificateModal({ data }) {

    const { t } = useTranslation();

    const { addModal } = useModal();
    const [charter, setCharter] = useState();
    const [name, setName] = useState()

    function downloadVoucher(event) {
        addModal(<DownloadModal data={data} type="voucher" name={name} />)
    }

    const [href, setHref] = useState("#");
    useEffect(async () => {
        !!data && !!data.code && setHref('/redeem?voucher_code=' + data.code)
        !!data
            && !!data.voucher_setting
            && !!data.voucher_setting.redeem_url
            && setHref(data.voucher_setting.redeem_url + '?voucher_code=' + voucher.code)
    }, [data])

    useEffect(async () => {
        await restGet((res) => setCharter(!!res && !!res.data && res.data[0]), 'charter/' + data.charter_id + "/byid")
    }, [data]);

    return (
        <DefaultModal
            title={t("common:modal.certificate.title")}
            confirm={{
                show: false
            }}
            cancel={{ show: true, text: t("common:button.done"), color: "info" }}
        >
            <Box>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="caption">
                        {t("common:modal.certificate.secondtitle")}
                    </Typography>
                    <Typography variant="overline">
                        {!!data && data.code}
                    </Typography>
                </Stack>
                <Divider sx={{ my: 0, mt: "20px", mb: "20px" }} />
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Typography variant="h5">
                        {t("common:modal.certificate.voucher")}
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        <Grid item>
                            <Input
                                label={t("common:label.name")}
                                multiline
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={(e) => downloadVoucher(e)}
                                color="info"
                            >
                                {t("common:label.download")}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
                {!!charter?.general_setting?.use_voucher_slots &&
                    <>
                        <Divider sx={{ my: 0, mt: "20px", mb: "20px" }} />

                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography variant="caption">
                                {t("common:modal.certificate.redeem")}
                            </Typography>
                            <Button href={href} variant="contained" color="info"
                            >
                                {t("common:modal.certificate.redeem")}
                            </Button>

                        </Stack>
                    </>
                }
            </Box>

        </DefaultModal>
    );
}

export default CertificateModal;