import { useEffect, useState } from "react";
import { Card, Grid, Stepper, Step, StepLabel } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import RequiredStep from "./components/RequiredStep";
import SettingsStep from "./components/SettingsStep";
import ImagesStep from "./components/ImagesStep";
import NextSteps from "./components/NextSteps";
import CreateStep from "./components/CreateStep";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import EquipmentStep from "./components/EquipmentStep";

function AircraftCreateForm() {
    const { t } = useTranslation();
    const [stepData, setStepData] = useState({})
    const setData = (data) => {
        setStepData({ ...stepData, [content[activeStep].id]: data })
    }
    const reset = () => {
        setStepData({})
        setActiveStep(0)
    }

    const content = [
        {
            id: "required",
            label: t("dashboard:charter.aircrafts.steps.required.steplabel"),
            component: <RequiredStep setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => {
                return !!stepData
                    && !!stepData.required
                    && !!stepData.required.reg
                    && !!stepData.required.name
                    && !!stepData.required.homebase
                    && !!stepData.required.homebase.icao
                    && !!stepData.required.class
                    && !!stepData.required.class.description
                    && !!stepData.required.class.type
                    && !!stepData.required.certification
                    && !!stepData.required.certification.certification
                    && !!stepData.required.reg_country
                    && !!stepData.required.reg_country.code
            }
        },
        {
            id: "equipment",
            label: t("dashboard:charter.aircrafts.steps.equipment.steplabel"),
            component: <EquipmentStep setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => {
                return !!stepData && !!stepData.equipment
            }
        },
        {
            id: "settings",
            label: t("dashboard:charter.aircrafts.steps.settings.steplabel"),
            component: <SettingsStep setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => {
                return !!stepData
                    && !!stepData.settings
                    && !!stepData.settings.booking_setting
                    && !!stepData.settings.operational_data_setting
            }
        },
        {
            id: "image",
            label: t("dashboard:charter.aircrafts.steps.image.steplabel"),
            component: <ImagesStep setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => {
                return !!stepData
                    && !!stepData.image
                    && !!stepData.image.description
                    && !!stepData.image.imageData
            }
        },
        {
            id: "creation",
            label: t("dashboard:charter.aircrafts.steps.creating.steplabel"),
            component: <CreateStep data={stepData} setData={setData} nextStep={() => { setActiveStep(activeStep + 1) }} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return false; },
            disbaledButtons: true
        },
        {
            id: "next",
            label: t("dashboard:charter.aircrafts.steps.next.steplabel"),
            component: <NextSteps data={stepData} reset={reset} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return true; }
        }
    ]


    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === content.length - 1;

    return (
        <Box pb={8}>
            <Grid container justifyContent="center" >
                <Grid item xs={12} xl={8}>
                    <Box mb={8} textAlign="center">
                        <Box mb={1}>
                            <Typography variant="h3" fontWeight="bold">
                                {t("dashboard:charter.aircrafts.steps.subtitle")}
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight="regular" color="secondary">
                            {t("dashboard:charter.aircrafts.steps.description")}
                        </Typography>
                    </Box>
                    <Card>
                        <Box mt={-3} mx={2}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {content.map((item) => (
                                    <Step key={item.label}>
                                        <StepLabel>{item.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        <Box p={2}>
                            <Box>
                                {content[activeStep].component}
                                {!isLastStep &&
                                    <Box mt={3} width="100%" display="flex" justifyContent="space-between">
                                        {activeStep === 0 ? (
                                            <Box />
                                        ) : (
                                            <Button
                                                disabled={!!content[activeStep].disbaledButtons}
                                                variant="outlined"
                                                color="dark"
                                                onClick={content[activeStep].handleBack}
                                            >
                                                {t("common:button.back")}
                                            </Button>
                                        )}
                                        <Button
                                            disabled={!content[activeStep].isValid() || !!content[activeStep].disbaledButtons}
                                            variant="gradient"
                                            color="dark"
                                            onClick={!isLastStep ? content[activeStep].handleNext : undefined}
                                        >
                                            {activeStep === 3 ? t("common:button.create") : t("common:button.next")}
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AircraftCreateForm;