import Box from "components/Box";

// Images
const bgImage = "https://aeromatewesteurope.blob.core.windows.net/aeromate/header/aeroplane-g2e25aa718_1920.jpg"

function Header() {
    return (<Box
        display="flex"
        alignItems="center"
        height="512px"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
    </Box>
    );
}

export default Header;