import Alert from "components/Alert";
import Box from "components/Box";
import TimelineJourneyItem from "components/Timeline/TimelineItem/TimelineJourneyItem";
import TimelineList from "components/Timeline/TimelineList";
import Typography from "components/Typography";
import { millisecondsToHHH } from "lib/functions";
import { convertFrontendToTimeDatabase } from "lib/functions";
import { decimalToMinutes } from "lib/functions";
import { convertTimeDatabaseToFrontend } from "lib/functions";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function JourneyStep({ setData, booking, teclog }) {
    const { t } = useTranslation()
    const [warning, setWarning] = useState(false)
    const [flights, setFlights] = useState()

    useEffect(() => {
        if (teclog.flight_data && teclog.flight_data.length > 0) {
            setFlights(teclog.flight_data)
        } else {
            setFlights([{
                dof: convertTimeDatabaseToFrontend(booking.handover),
                orig: booking.aircraft[0].homebase ?? '',
                ldg: 1,
                landing_fee_payed: false
            }])
        }
    }, [])

    function pushFlight() {
        let tmp = [...flights]
        tmp.push({
            dof: tmp[tmp.length - 1].aibt ? tmp[tmp.length - 1].aibt : tmp[tmp.length - 1].dof,
            orig: tmp[tmp.length - 1].dest,
            dest: booking.aircraft[0].homebase ?? '',
            ldg: 1
        })
        setFlights(tmp)
    }

    function spliceFlight(index) {
        let tmp = [...flights]
        tmp.splice(index, 1)
        setFlights(tmp)
    }

    function onChange(element, index) {
        let tmp = [...flights]
        tmp[index] = element
        setFlights(tmp)
    }

    useEffect(() => {
        !!flights && setData(flights)
    }, [flights])

    useEffect(() => {
        if (!!flights) {
            let totalTime = decimalToMinutes(millisecondsToHHH(
                (!!teclog.ops_data && (teclog.ops_data.ops_counter_end ?? 0)) -
                (!!teclog.ops_data && (teclog.ops_data.ops_counter_start ?? 0)))
            )
            let calcTime = 0
            flights.forEach(flight => {
                calcTime += flight.aldt - flight.atot
            });
            calcTime = convertFrontendToTimeDatabase(calcTime) / 60
            setWarning(calcTime !== totalTime)
        }
    }, [flights])

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:logtimes.journey.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:logtimes.journey.description")}
                    {t("common:label.total_time")}:&nbsp;
                    {millisecondsToHHH(
                        (!!teclog.ops_data && (teclog.ops_data.ops_counter_end ?? 0)) -
                        (!!teclog.ops_data && (teclog.ops_data.ops_counter_start ?? 0))
                    )} {t("common:label.hours")} &nbsp;
                    ({decimalToMinutes(millisecondsToHHH(
                        (!!teclog.ops_data && (teclog.ops_data.ops_counter_end ?? 0)) -
                        (!!teclog.ops_data && (teclog.ops_data.ops_counter_start ?? 0)))
                    )} {t("common:label.minutes")}) 
                </Typography>
            </Box>
            <Box mt={0}>
                <TimelineList title="">
                    {!!flights && flights.map((element, index) =>
                        <TimelineJourneyItem
                            key={"timeline-item-" + index}
                            color="info"
                            icon="airplanemode_active"
                            iconStyle={{ transform: "rotate(180deg)" }}
                            value={element}
                            onChange={(e) => onChange(e, index)}
                            removeAction={() => spliceFlight(index)}
                            firstItem={index === 0}
                        />)}
                    <TimelineJourneyItem
                        color="dark"
                        icon="airplanemode_active"
                        iconStyle={{ transform: "rotate(180deg)" }}
                        title=""
                        lastItem
                        value={{}}
                        addAction={pushFlight}
                    />
                </TimelineList>
            </Box>
            <Box mt={0}>
                {warning &&
                    <Alert color="warning">
                        {t("dashboard:logtimes.journey.warning")}
                    </Alert>}
            </Box>
        </Box>
    )
}
