/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import keyvault from "assets/keyvault/keyvault_open.png";
import { bookings_url } from "constants/global";

function Banner() {
  return (
    <Box
      variant="gradient"
      bgColor="dark"
      position="relative"
      borderRadius="xl"
      mx={{ xs: 2, xl: 3, xxl: 16 }}
      mt={-32}
      py={13}
      px={3}
      sx={{ overflow: "hidden" }}
    >
      <Box
        component="img"
        src={bgPattern}
        alt="pattern-lines"
        position="absolute"
        top={0}
        left={0}
        opacity={0.6}
        width={"100%"}
        height={"100%"}
      />
      <Container sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={7} lg={5} py={{ xs: 0, sm: 6 }} mr="auto" position="relative">
            <Typography variant="h2" color="white" mb={1}>
              Key Vault
            </Typography>
            <Typography variant="body1" color="white" mb={6}>
              Made of high quality brushed steel, our rugged key safe provides reliable security, weather resistant protection and maximum flexibility without internet or power supply to keep your keys safe and accessible
            </Typography>
            <Button variant="gradient"
              color="primary"
              component="a"
              href={bookings_url}
              target="_blank"
              rel="noreferrer">
              Contact Sales
            </Button>
          </Grid>
          <Grid item xs={12} position="absolute" left="50%" mr={-32} width="75%">
            <Box
              component="img"
              src={keyvault}
              alt="open keysafe"
              height="100%"
              display={{ xs: "none", md: "block" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Banner;
