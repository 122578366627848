import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import Typography from "components/Typography";
import { CardActionArea, Stack } from "@mui/material";
import { TouchApp } from "@mui/icons-material";

function ActionInfoCard({ color, icon, title, description, activ, onClick }) {
  let iconColor = color;

  let variant = activ ? "gradient" : "contained"

  if (variant === "gradient" && color !== "light") {
    iconColor = "white";
  } else if (variant === "gradient" && color === "light") {
    iconColor = "dark";
  }

  return (
    <CardActionArea sx={{ height: "100%" }} onClick={onClick}>
      <Box
        height={"100%"}
        display={{ xs: "block", md: "flex" }}
        variant={variant}
        bgColor={variant === "contained" ? "grey-100" : color}
        borderRadius="xl"
        pt={3.5}
        pb={3}
        px={3}
      >
        <Stack
          m="auto"
          direction="column"
          justifyContent="space-around"
          alignItems="center">
          <Typography
            display="block"
            variant="h2"
            color={iconColor}
            textGradient={variant === "contained"}
            mt={-0.625}
          >
            {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
          </Typography>
          <Typography
            align="center"
            variant="h4"
            color={variant === "contained" || color === "light" ? "dark" : "white"}
            fontWeight="bold"
            mb={1}
          >
            {title}
          </Typography>
          <Typography
            align="center"
            variant="body2"
            color={variant === "contained" || color === "light" ? "text" : "white"}
            mb={2}
          >
            {description}
          </Typography>
          {!activ && <Typography
            color={iconColor}
            textGradient={variant === "contained"}>
            <TouchApp />Read More
          </Typography>}
        </Stack>
      </Box>
    </CardActionArea>
  );
}

// Setting default props for the FilledInfoCard
ActionInfoCard.defaultProps = {
  color: "info"
};

// Typechecking props for the FilledInfoCard
ActionInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.func,
  activ: PropTypes.bool
};

export default ActionInfoCard;
