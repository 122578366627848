import { Container, Grid } from "@mui/material";
import Box from "components/Box";
import TimelineItem from "components/Timeline/TimelineItem";
import TimelineList from "components/Timeline/TimelineList";
import Typography from "components/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Timeline() {
    const { t } = useTranslation();
    const timelineItems = t("aboutUsPage:timeline.notes", { returnObjects: true })

    return (
        <Box component="section" py={12} id="timeline">
            <Container>
                <Grid
                    container
                    item
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={10}
                    lg={5}
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                    <Typography variant="h3" mt={3}>
                        {t("aboutUsPage:timeline.title")}
                    </Typography>
                    <Typography variant="body2" mt={3}>
                        {t("aboutUsPage:timeline.subtitle")}
                    </Typography>
                </Grid>
                <TimelineList noBackground={true}>
                    {timelineItems.map((x, i) => (
                        <TimelineItem
                            key={"timeline-item-" + i}
                            color="primary"
                            icon="code"
                            title={x.title}
                            dateTime={x.dateTime}
                            description={x.description}
                            badges={["design"]}
                            lastItem={timelineItems.length - 1 === i}
                            attachment={x.attachment}
                        />))
                    }
                </TimelineList>
            </Container>
        </Box>
    );
}