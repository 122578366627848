import Badge from "components/Badge";
import ConfirmationModal from "components/Modal/ConfirmationModal/ConfirmationModal";
import CertificateModal from "components/Modal/CertificateModal";
import { bookingWindowBuilder } from "lib/functions";
import i18n from "locals/config";

export const voucherColumns = [
    {
        Header: i18n.t("dashboard:charter.voucher.voucherCode"),
        accessor: "code"
    },
    {
        Header: i18n.t("dashboard:charter.voucher.voucherType"),
        accessor: "voucher_type"
    },
    {
        Header: i18n.t("common:label.aircraftType"),
        accessor: "type"
    },
    {
        Header: i18n.t("common:label.duration"),
        accessor: "duration"
    },
    {
        Header: i18n.t("common:label.pax"),
        accessor: "pax"
    },
    {
        Header: i18n.t("common:label.start"),
        accessor: d => bookingWindowBuilder(d.start, d.end),
    }, {
        Header: i18n.t("common:label.status"),
        accessor: "status",
        Cell: props => <Badge color={statusColor(props.value)} badgeContent={props.value} />
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
]

export const voucherActions = (addModal, data) => {
    return [
        {
            displayStatus: ["payment pending", "none"],
            title: i18n.t("common:button.pay"),
            action: (index) => {
                addModal(<ConfirmationModal
                    customText={i18n.t("common:modal.confirm.voucherpayment")}
                    actionName={i18n.t("common:button.paid")}
                    data={data[index]}
                    status="payVoucher"
                />)
            }
        },
        {
            displayStatus: ["scheduled"],
            title: i18n.t("common:button.cancel"),
            action: (index) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.cancel")}
                    data={data[index]}
                    status="cancelVoucher"
                />)
            }
        },
        {
            displayStatus: ["paid", "scheduled"],
            title: i18n.t("common:button.redeem"),
            action: (index) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.redeem")}
                    data={data[index]}
                    status="redeem"
                />)
            }
        },
        {
            displayStatus: ["paid"],
            title: i18n.t("common:button.refund"),
            action: (index) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.refund")}
                    data={data[index]}
                    status="refund"
                />)
            }
        },
        {
            displayStatus: ["paid"],
            title: i18n.t("common:button.expire"),
            action: (index) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.expire")}
                    data={data[index]}
                    status="expire"
                />)
            }
        },
        {
            displayStatus: ["paid"],
            title: i18n.t("common:button.certificate"),
            action: (index) => {
                addModal(<CertificateModal
                    data={data[index]}
                />)
            }
        }
    ]
};

function statusColor(status) {
    switch (status) {
        case "paid":
            return "success";
        case "scheduled":
            return "info";
        case "payment pending":
            return "error";
        case "none":
            return "error";
        default:
            return "light";
    }
}