import { Card, FormHelperText, Grid, Stack } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import Input from "components/Input";
import AeroLoading from "components/Progress/AeroLoading";
import DataTable from "components/Tables/DataTable";
import Typography from "components/Typography";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";
import { useUser } from "hooks/userDataHook";
import { addActions } from "lib/functions/tables";
import { restPost } from "lib/rest";
import { restGet } from "lib/rest";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { requestActions, requestColumns } from "../tables/requestsTable";

export default function JoinRequests() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    let { addModal } = useModal();
    const identity = useUser();
    const { refresher } = useRefresher();

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp) data = resp
                data = data.map((item) => ({ ...item, status: item.status ?? "pending" }))
                setTableData({ columns: requestColumns, rows: addActions(data, requestActions(addModal, data)) })
            }
            setIsLoading(true)
            await restGet(restCallback, "identity/_self/join")
            setIsLoading(false)
        }
        fetch()
    }, [refresher]);

    const [alias, setAlias] = useState();
    const [joinAlias, setJoinAlias] = useState();
    const [member, setMember] = useState(false);
    useEffect(() => {
        function restCallback(resp) {
            let data = undefined
            let exists = false
            let owner = false
            if (!!resp && !!resp.data && !!resp.data[0]) {
                if (!!resp.data[0].owner_id && resp.data[0].owner_id.$oid == identity.data._id.$oid) owner = true
                //Go over each item and look up the id from there
                if (!!resp.data[0].staff) {
                    for (let index = 0; index <= resp.data[0].staff.length - 1; index++) {
                        if (resp.data[0].staff[index].identity_id.$oid == identity.data._id.$oid) {
                            exists = true;
                            break;
                        }
                    }
                }
            }
            setMember(owner || exists)
            if (!!resp
                && !!resp.data
                && !!resp.data[0]
                && !owner && !exists) data = resp.data[0]
            setJoinAlias(data)
        }
        const handler = setTimeout(() => {
            if (!!alias) {
                restGet(restCallback, "charter/" + alias)
            } else {
                setMember(false)
            }
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [alias]);

    async function handleJoin() {
        function restCallback(resp) {
            let data = []
            if (!!resp) data = resp
            data = data.map((item) => ({ ...item, status: item.status ?? "pending" }))
            setTableData({ columns: requestColumns, rows: addActions(data, requestActions(addModal, data)) })
        }
        setIsLoading(true)
        await restPost(null, `charter/${joinAlias.alias}/join`)
        await restGet(restCallback, "identity/_self/join")
        setIsLoading(false)
    }

    return (
        <Grid item xs={12}>
            <Card>
                <Box
                    pr={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                >
                    <Box p={3} lineHeight={1}>
                        <Box mb={1}>
                            <Typography variant={"h5"}>{t("dashboard:organization.join_requests.title")}</Typography>
                        </Box>
                        <Typography variant="button" color="text">
                            {t("dashboard:organization.join_requests.description")}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Stack direction="column">
                                <Input
                                    type="text"
                                    label={t("dashboard:organization.join_requests.alias")}
                                    variant="outlined"
                                    helperText={t("dashboard:organization.join_requests.alias_hint")}
                                    sx={{ ml: 2 }}
                                    onChange={(e) => setAlias(e.target.value)}
                                />
                                {!!member && <Box pl={3.75}>
                                    <FormHelperText error={true}>
                                        {t("dashboard:organization.join_requests.joined")}
                                    </FormHelperText>
                                </Box>}
                            </Stack>
                            <Button
                                onClick={handleJoin}
                                variant="gradient"
                                disabled={!joinAlias}
                                color={!joinAlias ? "light" : "dark"}
                                sx={{ height: "100%", ml: 2 }}>
                                {t("dashboard:organization.join_requests.button")}
                            </Button>
                        </Stack>

                    </Box>
                </Box >
                {isLoading && <AeroLoading />}
                {!isLoading && <DataTable table={tableData} />}
            </Card>
        </Grid>
    )
}
