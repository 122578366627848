import CategoriesList from "components/Lists/CategoriesList";
import Typography from "components/Typography";

const categoriesListData = [
    {
        color: "dark",
        icon: "launch",
        name: "Bookings",
        description: (
            <>
                250 in stock,{" "}
                <Typography variant="caption" color="text" fontWeight="medium">
                    346+ sold
                </Typography>
            </>
        ),
        route: "/",
    },
    {
        color: "dark",
        icon: "book_online",
        name: "Log Times",
        description: (
            <>
                123 closed,{" "}
                <Typography variant="caption" color="text" fontWeight="medium">
                    15 open
                </Typography>
            </>
        ),
        route: "/",
    }
];

function ChecklistAdminActions() {
    return (
        <CategoriesList title="Admin Actions" categories={categoriesListData} />
    );
}

export default ChecklistAdminActions;
