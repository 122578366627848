import Box from "components/Box";
import bgImage from "assets/images/aircraft-construction.jpg"
import Typography from "components/Typography";
import { Container, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

// Images
//const bgImage = "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_194450239sm.jpeg"

function Header() {
    const { t } = useTranslation();
    return (<Box
        display="flex"
        alignItems="center"
        height="512px"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.5), rgba(gradients.dark.state, 0.5))}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
        <Box component="section" ml={8}>
            <Container>
                <Stack direction={"column"}>
                    <Typography variant="h1" color="white" >
                        {t("gettingStartedPage:header.title")}
                    </Typography>
                    <Typography variant="h4" color="white" >
                        {t("gettingStartedPage:header.subtitle")}
                    </Typography>
                </Stack>
            </Container>
        </Box>
    </Box >
    );
}

export default Header;