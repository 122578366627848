import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import FeatureContent from "../components/FeatureContent";

function Features({ pagename }) {
    const { t } = useTranslation()
    return (
        <Box component="section" py={3} mt={8}>
            <Grid container alignItems="baseline" spacing={3}>
                <Grid item md={12} lg={6}>
                    <FeatureContent
                        title={t(pagename + ":features.item1.title")}
                        description={t(pagename + ":features.item1.description")}
                        icon={t(pagename + ":features.item1.icon")}
                        tags={t(pagename + ":features.item1.tags", { returnObjects: true })}
                        iconSize="h1"
                    />
                </Grid>
                <Grid item md={12} lg={6}>
                    <FeatureContent
                        title={t(pagename + ":features.item2.title")}
                        description={t(pagename + ":features.item2.description")}
                        icon={t(pagename + ":features.item2.icon")}
                        tags={t(pagename + ":features.item2.tags", { returnObjects: true })}
                        iconSize="h1"
                    />
                </Grid>
                <Grid item md={12} lg={6}>
                    <FeatureContent
                        title={t(pagename + ":features.item3.title")}
                        description={t(pagename + ":features.item3.description")}
                        icon={t(pagename + ":features.item3.icon")}
                        tags={t(pagename + ":features.item3.tags", { returnObjects: true })}
                        iconSize="h1"
                    />
                </Grid>
                <Grid item md={12} lg={6}>
                    <FeatureContent
                        title={t(pagename + ":features.item4.title")}
                        description={t(pagename + ":features.item4.description")}
                        icon={t(pagename + ":features.item4.icon")}
                        tags={t(pagename + ":features.item4.tags", { returnObjects: true })}
                        iconSize="h1"
                    />
                </Grid>
                <Grid item md={12} lg={6}>
                    <FeatureContent
                        title={t(pagename + ":features.item5.title")}
                        description={t(pagename + ":features.item5.description")}
                        icon={t(pagename + ":features.item5.icon")}
                        tags={t(pagename + ":features.item5.tags", { returnObjects: true })}
                        iconSize="h1"
                    />
                </Grid>
            </Grid>
        </Box >
    );
}

export default Features;
