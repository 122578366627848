/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import { Stack } from "@mui/material";

function DefaultBackgroundCard2({ image, label, title, description, action, height }) {
  return (
    <Card
      sx={({
        functions: { rgba, linearGradient },
        palette: { black },
        borders: { borderRadius },
      }) => ({
        backgroundImage: `${linearGradient(
          rgba(black.main, 0.5),
          rgba(black.main, 0.5)
        )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: borderRadius.xl,
        height: height ?? "100%"
      })}
    >
      <Stack height="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}>

        <Box>
          <Typography textAlign="center" variant="h3" color="white" >
            {title}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}

// Setting default values for the props of DefaultBackgroundCard
DefaultBackgroundCard2.defaultProps = {
  label: "",
};

// Typechecking props for the DefaultBackgroundCard
DefaultBackgroundCard2.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

export default DefaultBackgroundCard2;
