import { useState } from "react";
import { Card, Grid, Stepper, Step, StepLabel } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import NameStep from "../components/NameStep";
import LegalStep from "../components/LegalStep";
import PaymentStep from "../components/PaymentStep";
import NextSteps from "../components/NextSteps";
import CreateStep from "../components/CreateStep";
import { useTranslation } from "react-i18next";
import _ from "lodash";

function CreateCharter() {
    const { t } = useTranslation();
    const [stepData, setStepData] = useState({})
    const setData = (data) => {
        setStepData({ ...stepData, [content[activeStep].id]: data })
    }

    const content = [
        {
            id: "name",
            label: t("dashboard:organization.create.name.steplabel"),
            component: <NameStep setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return (!!stepData["name"] && !!stepData["name"].aliasValid && !!stepData["name"].nameValid && !!stepData["name"].legalEntityValid); }
        },
        {
            id: "legal",
            label: t("dashboard:organization.create.legal.steplabel"),
            component: <LegalStep setData={setData} chartername={!!stepData["name"] && stepData["name"].name} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return (stepData["legal"]) }
        },
        {
            id: "next",
            label: t("dashboard:organization.create.creating.steplabel"),
            component: <CreateStep data={!!stepData && stepData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return true; }
        }
    ]

    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === content.length - 1;

    return (
        <Box pb={8}>
            <Grid container justifyContent="center" sx={{ my: 4 }}>
                <Grid item xs={12} lg={8}>
                    <Box mb={8} textAlign="center">
                        <Box mb={1}>
                            <Typography variant="h3" fontWeight="bold">
                                {t("dashboard:organization.create.subtitle")}
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight="regular" color="secondary">
                            {t("dashboard:organization.create.description")}
                        </Typography>
                    </Box>
                    <Card>
                        <Box mt={-3} mx={2}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {content.map((item) => (
                                    <Step key={item.label}>
                                        <StepLabel>{item.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        <Box p={2}>
                            <Box>
                                {content[activeStep].component}
                                {!isLastStep &&
                                    <Box mt={3} width="100%" display="flex" justifyContent="space-between">
                                        {activeStep === 0 ? (
                                            <Box />
                                        ) : (
                                            <Button
                                                disabled={!!content[activeStep].disbaledButtons}
                                                variant="outlined"
                                                color="dark"
                                                onClick={content[activeStep].handleBack}
                                            >
                                                {t("common:button.back")}
                                            </Button>
                                        )}
                                        <Button
                                            disabled={!content[activeStep].isValid() || !!content[activeStep].disbaledButtons}
                                            variant="gradient"
                                            color="dark"
                                            onClick={!isLastStep ? content[activeStep].handleNext : undefined}
                                        >
                                            {activeStep === 2 ? t("common:button.create") : t("common:button.next")}
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CreateCharter;