import { Container, Grid } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import brand from "constants/brand";
import { useTranslation } from "react-i18next";

const image = brand.static_cdn + "img/partner_sayagainaero.png";
function SayAgainAero() {
    const { t } = useTranslation()

    return (
        <Box component="section" pt={3} id="microsoft">
            <Container>
                <Grid container alignItems="center" spacing={4}>
                    <Grid item xs={6}>
                        <Box position="relative" borderRadius="1.5rem" mx={2} mt={-3}>
                            <Box
                                component="img"
                                src={image}
                                borderRadius="1.5rem"
                                shadow="md"
                                width="100%"
                                position="relative"
                                zIndex={1}
                            />
                            <Box
                                borderRadius="1.5rem"
                                shadow="md"
                                width="100%"
                                height="100%"
                                position="absolute"
                                left={0}
                                top={0}
                                sx={{
                                    backgroundImage: `url(${image})`,
                                    transform: "scale(0.97)",
                                    filter: "blur(12px)",
                                    backgroundSize: "cover",
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5">
                            {t("partnerPage:sayagainaero.title")}
                        </Typography>
                        <Typography variant="h2">
                            {t("partnerPage:sayagainaero.subtitle")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("partnerPage:sayagainaero.body")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default SayAgainAero