import brand from "constants/brand";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function KeyvaultHelmet({ }) {
    const { t } = useTranslation();

    let default_key_words = [
        "aeromate",
        "flugzeug chartern",
        "privatflugzeug mieten",
        "cessna mieten",
        "piper mieten",
        "cirrus mieten",
        "flugzeug mieten ohne pilot",
        "flugzeug vermieten",
        "privat flugzeug mieten",
        "aircraft",
        "aircraft charter",
        "aircraft for rent",
        "aircraft for hire",
        "aircraft for hire without pilot",
        "private aircraft for rent",
        "private plane",
        "private plane charter",
        "private plane for rent",
        "private plane for hire",
        "private plane for hire without pilot",
        "cessna for hire",
        "piper for hire",
        "cirrus for hire",
    ]

    return (
        <Helmet>
            <title>{brand.name_caps + " " + t("routes:keyvault.title")}</title>
            <meta name="keywords" content={default_key_words.toString()}></meta>
            <script type="application/ld+json">
                {JSON.stringify(
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "Introducing the keyvault for aircrafts",
                        "description": "They charter their aircraft and don't always want to drive to the airfield for the key handover. Then we present you here the key safe for a contactless handover of your aircraft to other pilots. Get the limited offer now.",
                        "thumbnailUrl": ["https://aeromatestatic.blob.core.windows.net/vid/keyvault-thumbnail.jpg"],
                        "uploadDate": "2023-09-08T09:05:00+02:00",
                        "duration": "PT0M51S",
                        "contentUrl": "https://aeromatestatic.blob.core.windows.net/vid/Keyvault.mp4"
                    }
                )}
            </script>
        </Helmet>
    )
}