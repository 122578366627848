import { Card, Collapse } from "@mui/material";
import Box from "components/Box";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Checklist from "components/Checklist";
import SubjectEditItem from "../components/SubjectEditItem";
import { v4 as uuidv4 } from 'uuid';
import Accordion from "components/Accordion";
import Input from "components/Input";

export default function Subjects({ setData, defaultData, nested, subtype }) {
    const { t } = useTranslation()
    const [checklist, setChecklist] = useState(defaultData);

    function handleChecklistCallback(list) {
        setChecklist(list.map((e) => { return e.data ?? e }))
    }

    useEffect(() => {
        checklist && setData({ "subjects": checklist })
    }, [checklist]);

    return (
        <Card>
            <Box p={3} >

                <TypographyBlock
                    nopadding
                    title={t("dashboard:shopitem.checklist_title")}
                    description={t("dashboard:shopitem.checklist_description")}
                />
                <Box mt={3}>
                    <Checklist
                        color="success"
                        dataCallback={_.debounce((list) => handleChecklistCallback(list), 300)}
                        items={checklist?.map((item) => {
                            return {
                                id: uuidv4() + "",
                                component: nested ? <ContainerChecklist {...item} /> : <SubjectEditItem {...item} subtype={subtype} />
                            }
                        })}
                        defaultComponent={nested ? <ContainerChecklist /> : <SubjectEditItem subtype={subtype} />}
                    />
                </Box>
            </Box>
        </Card>
    )
}

function ContainerChecklist(props) {
    const { dataCallback } = props;
    const [title, setTitle] = useState(props.title);
    const [checklist, setChecklist] = useState(props.checklist ?? []);

    function handleChecklistChange(val) {
        setChecklist(val?.map((e) => { return e.data ?? e }))
    }

    useEffect(() => {
        dataCallback({
            title,
            checklist
        })
    }, [title, checklist]);

    return (
        <Accordion title={<Input sx={{ width: "384px" }} type="text" defaultValue={title} onChange={_.debounce((e) => setTitle(e.target.value), 300)} />}>
            <Checklist
                color="success"
                dataCallback={_.debounce((list) => handleChecklistChange(list), 300)}
                items={checklist?.map((item) => { return { id: uuidv4() + "", component: <SubjectEditItem {...item} /> } })}
                defaultComponent={<SubjectEditItem />} />
        </Accordion>
    )
}