import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function AirportHelmet({ airport }) {
    const { t } = useTranslation();

    let default_key_words = [
        "airport",
        "runways",
        "coordinates",
        "elevation",
        "icao",
        "iata",
        "frequencies",

        "aeromate airport",
        "aeromate airports",
        "aeromate" + airport.iata,
        "aeromate" + airport.icao,
        "aeromate" + airport.municipality,
        "aeromate" + airport.name,

        airport.iata,
        airport.icao,
        airport.municipality,
        airport.name]


    return (
        <Helmet>
            <title>{t("routes:airport.title") + " " + airport.icao + " " + airport.name} </title>
            <meta name="keywords" content={default_key_words.toString()}></meta>
            <script type="application/ld+json">
                {JSON.stringify({
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": airport?.ratings_stat?.avg_rating ?? 0,
                        "ratingCount": airport?.ratings_stat?.rating_cnt ?? 0
                    }
                })}
            </script>
        </Helmet>
    )
}