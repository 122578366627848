import Box from "components/Box";
import TimelineList from "components/Timeline/TimelineList";
import TimelineFuelItem from "components/Timeline/TimelineItem/TimelineFuelItem";
import Typography from "components/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { restGet } from "lib/rest";

export default function FuelStep({ setData, teclog }) {
    const { t } = useTranslation()

    const [fuelData, setFuelData] = useState([])
    const [fuelStart, setFuelStart] = useState()
    const [fuelEnd, setFuelEnd] = useState(0)
    const [airportOptions, setAirportOptions] = useState([])
    const [gettingFuelData, setGettingFuelData] = useState(false)

    useEffect(() => {
        if (!!teclog && !Array.isArray(teclog.fuel_data) && !!teclog.fuel_data && !_.isEmpty(teclog.fuel_data)) {
            !!teclog.fuel_data.entries && setFuelData(teclog.fuel_data.entries)
            !!teclog.fuel_data.fuel_start && setFuelStart(teclog.fuel_data.fuel_start)
            !!teclog.fuel_data.fuel_end && setFuelEnd(teclog.fuel_data.fuel_end)
        } else {
            const callback = (resp) => {
                if (!!resp && !!resp.data && !!resp.data.fuel_data && !!resp.data.fuel_data.fuel_start) setFuelStart(resp.data.fuel_data.fuel_start)
                setGettingFuelData(false)
            }
            if (!!teclog.reg) {
                setGettingFuelData(true)
                restGet(callback, `teclog/${teclog.reg}/latest`)
            }
        }
        if (!!teclog && !!teclog.flight_data) {
            var tmp = []
            teclog.flight_data.forEach(element => {
                element.orig && tmp.push(element.orig)
                element.dest && tmp.push(element.dest)
            });
            tmp = _.uniq(tmp)
            //Get Airport Object from database
            setAirportOptions(tmp)
        }
    }, [teclog])

    function pushFuel() {
        let tmp = [...fuelData]
        tmp.push({})
        setFuelData(tmp)
    }

    function spliceFuel(index) {
        let tmp = [...fuelData]
        tmp.splice(index, 1)
        setFuelData(tmp)
    }

    function onChange(element, index) {
        let tmp = [...fuelData]
        tmp[index] = element
        setFuelData(tmp)
    }

    function convertFuelData(fuelData) {

        if (!!fuelData) {
            //removed price_unit
            //removed fuel_unit
            //convert other data
            return fuelData.map((element, index) => {
                return {
                    fuel: element.fuel * ((!!element.fuel_unit && element.fuel_unit.name === "gallons") ? 3.78541 : 1),//conv gallons => liter
                    price: element.price * ((!!element.price_unit && element.price_unit.label === "$") ? 0.94 : 1),//conv dollar => euro(cent)
                    pilot_payed: element.pilot_payed,
                    airport: element.airport,//conv to string(icao)
                    ops_count: element.ops_count
                }
            })
        }
        return []
    }

    useEffect(() => {
        setData({ fuel_start: fuelStart, fuel_end: fuelEnd, entries: convertFuelData(fuelData) })
    }, [fuelStart, fuelEnd, fuelData])

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:logtimes.fuel.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:logtimes.fuel.description")}
                </Typography>
            </Box>

            <Box mt={2}>
                <TimelineList title="Timeline">
                    <TimelineFuelItem
                        color="info"
                        icon="flight_takeoff"
                        title=""
                        firstItem
                        value={fuelStart ?? 0}
                        setFuelStart={setFuelStart}
                        isLoading={gettingFuelData}
                    />
                    {fuelData.map((element, index) =>
                        <TimelineFuelItem
                            fullwidth
                            key={"timeline-item-" + index}
                            color="info"
                            icon="local_gas_station"
                            value={element}
                            onChange={(e) => onChange(e, index)}
                            removeAction={() => spliceFuel(index)}
                            airports={airportOptions}
                        />)}
                    <TimelineFuelItem
                        color="dark"
                        icon="add"
                        title=""
                        addItem
                        value={{}}
                        addAction={pushFuel}
                    />
                    <TimelineFuelItem
                        color="info"
                        icon="flight_land"
                        title=""
                        lastItem
                        value={fuelEnd}
                        setFuelEnd={setFuelEnd}
                    />
                </TimelineList>
            </Box>
        </Box>
    )
}
