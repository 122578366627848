import { Grid, AppBar, Tab, Tabs } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import { useCallback, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Cropper from 'react-easy-crop'
import PlaceHolderImage from "assets/images/aircraft.jpg";
import Input from "components/Input";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useModal } from "hooks/modalHook";
import { getBlobImageUrl } from "lib/functions";
import { Image } from "@mui/icons-material";
import Cropmodal from "components/Modal/CropperModal";
import CancelIcon from '@mui/icons-material/Cancel';
import { toBase64 } from "lib/functions";
import { getBlobFromPdfString } from "lib/functions";

export default function ImagesSteps({ setData, data }) {
    const { t } = useTranslation()
    const fileInputRef = useRef(null);
    const [aircraftPreviewImage, setAircraftPreviewImage] = useState(PlaceHolderImage);
    const { addModal } = useModal();
    const [croppedImg, setcroppedImg] = useState('');
    const [img, setImg] = useState();
    const [curDoc, setCurDoc] = useState()
    const [curDocURL, setCurDocURL] = useState()
    const [pdf, setPdf] = useState(false)
    const [mime, setMime] = useState("")
    const [file, setFile] = useState()
    const [image, setImage] = useState(true)
    const handleClickBrowse = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        console.log(data?.settings?.type)
        if (data?.settings?.type == "pdf") {

            setPdf(true)
            setCurDocURL(data?.settings?.imageData)
            setImage(false)

        } else if (data?.settings?.type == "img") {

            setImage(true)
            setAircraftPreviewImage(data?.settings?.imageData)
            setPdf(false)

        }
    }, []);

    const Handlechange = (value) => {
        setcroppedImg(value);
    };


    const SelectImage = (event) => {
        const selectedImg = event.target.files;
        console.log(selectedImg)
        if (selectedImg[0].type != "application/pdf") {
            const selectedImgArray = Array.from(selectedImg);
            const imagesArray = selectedImgArray.map((file) => {
                return URL.createObjectURL(file);
            });


            addModal(<Cropmodal onValueChange={Handlechange} img={imagesArray}></Cropmodal>)
            event.target.value = "";
            setImage(true)
            setPdf(false)
            setFile(selectedImgArray[0])
            setMime(selectedImgArray[0].type)

        } else {


            toBase64(selectedImg[0])
                .then(base64 => {

                    setCurDoc(base64);
                })
                .catch(error => {

                    console.log(error, "err")
                })

            setImage(false)
            setPdf(true)
            setMime(selectedImg[0].type)
            setFile(selectedImg)


        }

    };

    useEffect(() => {
        if (curDoc === null) {

            setCurDocURL(null)
        }
        if (!!curDoc) {
            setCurDocURL(window.URL.createObjectURL(getBlobFromPdfString(curDoc)));
        }
    }, [curDoc]);

    useEffect(() => {

        if (croppedImg != "") {
            setAircraftPreviewImage(croppedImg)
            setImg(croppedImg)
        }
    }, [croppedImg])

    useEffect(() => {
        !!img && setData({ imageData: croppedImg, type: "img", mime: mime, file: file })

    }, [img]);
    useEffect(() => {
        !!curDoc && setData({ imageData: curDocURL, type: "pdf", mime: mime, file: curDoc })

    }, [curDoc, curDocURL]);


    return (
        <Box>
            <Box mt={2}>
                <Grid container spacing={3} justifyContent="center"
                    alignItems="center">
                    <Grid item xs={12} sm={6} md={9} pr={3} >
                    <TypographyBlock
                            title={t("dashboard:personal.upload.add_image")}
                            action={handleClickBrowse}
                            actionName={t("dashboard:personal.upload.add")}
                        />
                        <Box sx={{
                            "position": "relative",
                            "width": "100%",
                            "height": "80%"
                        }}>
                            {image && <img src={aircraftPreviewImage} style={{ overflow: "hidden", maxWidth: "100%", maxHeight: "100%" }} alt="upload" />}
                            {pdf && <iframe
                                style={{ width: "100%", height: "75%", minHeight: "400px" }}
                                src={curDocURL ?? ''}
                            >
                            </iframe>}

                        </Box>
                    </Grid>



                    <input
                        ref={fileInputRef}
                        type="file"
                        name="images"
                        onChange={SelectImage}
                        accept="image/png ,image/jpg,image/jpeg,application/pdf"
                        hidden
                    />






                </Grid>
            </Box>
        </Box>
    )
}
