import Container from "@mui/material/Container";
import Box from "components/Box";
import bgImage from "assets/images/aircraft-construction.jpg"

// Images
//const bgImage = "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_194450239sm.jpeg"

function Header() {
    return (<Box
        display="flex"
        alignItems="center"
        height="512px"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
    </Box>
    );
}

export default Header;