import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import DefaultNextStepCard from "components/Cards/NextStepsCards/DefaultNextStepCard";


function NextSteps({ reset, data }) {
    const { t } = useTranslation();

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:charter.aircrafts.steps.next.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:charter.aircrafts.steps.next.description")}
                </Typography>
            </Box>
            <Box mt={2}>
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={12} md={6} xl={3}>
                        <DefaultNextStepCard
                            icon="attach_money"
                            title={t("dashboard:charter.aircrafts.steps.next.rates.title")}
                            description={t("dashboard:charter.aircrafts.steps.next.rates.description")}
                            button={
                                <Button
                                    variant="gradient"
                                    color="info"
                                    href={"/my/" + data.creation.alias + "/aircrafts#rates"}
                                >
                                    {t("dashboard:charter.aircrafts.steps.next.rates.button")}
                                </Button>}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                        <DefaultNextStepCard
                            icon="flight"
                            title={t("dashboard:charter.aircrafts.steps.next.aircraft.title")}
                            description={t("dashboard:charter.aircrafts.steps.next.aircraft.description")}
                            button={
                                <Button
                                    variant="gradient"
                                    color="info"
                                    href={"/aircraft/" + data.creation.reg}
                                >
                                    {t("dashboard:charter.aircrafts.steps.next.aircraft.button")}
                                </Button>}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                        <DefaultNextStepCard
                            icon="add_box"
                            title={t("dashboard:charter.aircrafts.steps.next.more.title")}
                            description={t("dashboard:charter.aircrafts.steps.next.more.description")}
                            button={
                                <Button
                                    variant="gradient"
                                    color="info"
                                    onClick={reset}
                                >
                                    {t("dashboard:charter.aircrafts.steps.next.more.button")}
                                </Button>}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default NextSteps;