import { AppBar, Stack, Tab, Tabs, Grid } from "@mui/material";
import Box from "components/Box";
import Input from "components/Input";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Subjects from "aeromate-dashboard/ShopItem/Edit/sections/Subjects";

export default function ChecklistTab({ subtype, setData }) {
    const { t } = useTranslation()

    function callBack(val) {
        setData(val.subjects)
    }

    return (
        <Box p={3}>
            <Stack >
                <TypographyBlock
                    nopadding
                    title={t("common:label.information")}
                />
                <Subjects defaultData={[]} setData={callBack} nested={subtype !== "exams"} subtype={subtype} />
            </Stack>
        </Box>
    )
}