import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { Stack } from "@mui/material";

function OverviewCard({ color, price, specifications, action, shadow }) {
  const renderSpecifications = specifications.map(({ label, includes }, index) => (
    <Box key={index + "-" + label} display="flex" alignItems="center" p={1}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        mr={2}
        mt={-0.125}
      >
        {includes !== undefined &&
          <Typography
            variant="body1"
            color={color === "white" ? "text" : "white"}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{includes ? "done" : "remove"}</Icon>
          </Typography>
        }
      </Box>
      <Typography
        variant="body2"
        color={color === "white" ? "text" : "white"}
        fontWeight="regular"
      >
        {label ?? ""}
      </Typography>
    </Box>
  ));

  return (
    <Card sx={{ boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none") ,height: "100%", objectFit: "cover"}}>
      <Box
        style={{ height: "100%" }}
        bgColor={color}
        variant={color === "white" ? "contained" : "gradient"}
        borderRadius="xl"
      >
        <Stack
          style={{ height: "100%" }}
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Box pt={3} pb={2} px={2} textAlign="center">
            <Box my={1}>
              <Typography variant="h1" color={color === "white" ? "dark" : "white"}>
                <Typography
                  display="inline"
                  component="small"
                  variant="h5"
                  color="inherit"
                  verticalAlign="top"
                >
                  {price.label}
                </Typography>
                {price.value}
              </Typography>
            </Box>
          </Box>
          <Box mt={1} mb={3} style={{ height: "100%" }}>
            {renderSpecifications}
          </Box>
          <Box p={3} width="100%">
            {action.type === "internal" ? (
              <Button
                component={Link}
                to={action.route}
                variant="gradient"
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </Button>
            ) : (
              <Button
                onClick={() => { window.open(action.route, '_blank') }}
                variant="gradient"
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </Button>
            )}
          </Box>
        </Stack>
      </Box>
    </Card>
  );
}

// Setting default props for the DefaultPricingCard
OverviewCard.defaultProps = {
  color: "white",
  shadow: true,
};

// Typechecking props for the DefaultPricingCard
OverviewCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  price: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default OverviewCard;
