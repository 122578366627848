import { Table, TableRow, TableBody } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceFooter from "./InvoiceFooter";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { centToEuro } from "lib/functions";

const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
        `${borderWidth[1]} solid ${light.main}`,
};

function PreviewWatermark() {
    const { t } = useTranslation();
    return (
        <Box
            //Center Content
            alignItems="center"
            display="flex"
            justifyContent="center"
            //Absolute Position
            position="absolute"
            left="0px"
            top="0px"
            //Fullwidth
            height="100%"
            width="100%"
        >
            <Typography variant="h1" opacity={.2} size="3xl" sx={{ transform: "rotate(16deg)" }} textTransform="uppercase">
                {t("common:label.preview")}
            </Typography>
        </Box>
    )
}

function InvoiceTableRow({ data, index }) {
    return (
        <TableRow>
            <Box component="td" textAlign="left" p={1} sx={borderBottom}>
                <Typography variant="body2" color="text" fontWeight="regular">
                    {index + 1}
                </Typography>
            </Box>
            <Box
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                sx={borderBottom}
            >
                <Typography variant="body2" color="text" fontWeight="regular">
                    {data.factor}
                </Typography>
            </Box>
            <Box
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                sx={borderBottom}
            >
                <Typography variant="body2" color="text" fontWeight="regular">
                    {data.description.text}
                </Typography>
            </Box>
            <Box
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                sx={borderBottom}
            >
                <Typography variant="body2" color="text" fontWeight="regular">
                    {data.description.body}
                </Typography>
            </Box>
            <Box
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                sx={borderBottom}
            >
                <Typography variant="body2" color="text" fontWeight="regular">
                    {data.vat} %
                </Typography>
            </Box>
            <Box
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                sx={borderBottom}
            >
                <Typography variant="body2" color="text" fontWeight="regular">
                    {centToEuro(data.unit_amount)}
                </Typography>
            </Box>
            <Box
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                sx={borderBottom}
            >
                <Typography variant="body2" color="text" fontWeight="regular">
                    {centToEuro(data.amount_net)}
                </Typography>
            </Box>
        </TableRow>
    )
}

function Invoice({ legal, from, to, preview, data }) {
    const { t } = useTranslation();

    return (
        <Box >
            <Box position="relative">
                <InvoiceHeader from={from} to={to} />
                {preview && <PreviewWatermark />}
            </Box>
            {/* Invoice table */}
            <Box pt={3} position="relative">
                <Box width="100%" overflow="auto">
                    <Table sx={{ minWidth: "32rem" }}>
                        <Box component="thead">
                            <TableRow>
                                <Box
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <Typography variant="h6" color="text" fontWeight="medium">
                                        {t("common:label.position_short")}
                                    </Typography>
                                </Box>
                                <Box
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <Typography variant="h6" color="text" fontWeight="medium">
                                        {t("common:label.quantity_short")}
                                    </Typography>
                                </Box>
                                <Box
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <Typography variant="h6" color="text" fontWeight="medium">
                                        {t("common:label.product")}
                                    </Typography>
                                </Box>
                                <Box
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <Typography variant="h6" color="text" fontWeight="medium">
                                        {t("common:label.description")}
                                    </Typography>
                                </Box>
                                <Box
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <Typography variant="h6" color="text" fontWeight="medium">
                                        {legal?.legalEntity === "aeromate.eu" ? t("common:label.vat%") : t("common:label.tax%")}
                                    </Typography>
                                </Box>
                                <Box
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <Typography variant="h6" color="text" fontWeight="medium">
                                        {t("common:label.unit_netto")}
                                    </Typography>
                                </Box>
                                <Box
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <Typography variant="h6" color="text" fontWeight="medium">
                                        {t("common:label.sum_netto")}
                                    </Typography>
                                </Box>
                            </TableRow>
                        </Box>
                        <TableBody>
                            {!!data && data.map((e, i) => <InvoiceTableRow key={"table-row-" + i} data={e} index={i} />)}
                        </TableBody>
                    </Table>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <Box component="td" textAlign="left" width="80%" />
                                <Box
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                >
                                    <Typography variant="body2" color="text" fontWeight="regular">{t("common:label.sum_netto")}</Typography>
                                </Box>
                                <Box
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                >
                                    <Typography variant="body2" color="text" fontWeight="regular">{centToEuro(_.sumBy(data, function (o) { return o.amount_net; }))}</Typography>
                                </Box>
                            </TableRow>
                            <TableRow>
                                <Box component="td" textAlign="left" width="80%" />
                                <Box
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                >
                                    <Typography variant="body2" color="text" fontWeight="regular">{legal?.legalEntity === "aeromate.eu" ? t("common:label.vat%") : t("common:label.tax%")}</Typography>
                                </Box>
                                <Box
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                >
                                    <Typography variant="body2" color="text" fontWeight="regular">{centToEuro(_.sumBy(data, function (o) { return o.amount_vat; }))}</Typography>
                                </Box>
                            </TableRow>
                            <TableRow>
                                <Box component="td" textAlign="left" width="80%" />
                                <Box
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                >
                                    <Typography variant="h5">Total</Typography>
                                </Box>
                                <Box
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                >
                                    <Typography variant="h5">
                                        {centToEuro(_.sumBy(data, function (o) { return o.amount; }))} </Typography>
                                </Box>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                {preview && <PreviewWatermark />}
            </Box>

            {/* Invoice footer */}

            {/* <Box position="relative">
                <InvoiceFooter printComponent={printComponent} />
                {preview && <PreviewWatermark />}
            </Box> */}
        </Box>
    );
}

export default Invoice;