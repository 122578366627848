/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";

// Material Kit 2 PRO React examples
import DefaultNavbarDropdown from "./DefaultNavbarDropdown";
import { useTranslation } from 'react-i18next';
import { AuthButton } from "components/Button/AuthButton";
import { Divider } from "@mui/material";
import { availableLanguages } from "locals/config";
import Button from "components/Button";
import i18next from "i18next";
import moment from "moment";
import LanguageSelection from "components/Input/LanguageSelection";
import { useUser } from "hooks/userDataHook";
import placeholderAvatar from "assets/images/avatar.png"
import { getBlobImageUrl } from "lib/functions";
import Avatar from "components/Avatar";

function DefaultNavbarMobile({ routes, open }) {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState("");
  const [visible, setVisibility] = useState();
  const { isSignedIn, data } = useUser();
  const [avatar, setAvatar] = useState(placeholderAvatar);

  useEffect(() => {
    const showDash = async () => {
      var isLoggedIn = await isSignedIn()
      setVisibility(!!isLoggedIn)
    }
    showDash()
  }, [])

  useEffect(() => {
    if (!!data?.image?.Avatar) {
      setAvatar(getBlobImageUrl(data.image.Avatar))
    }
  }, [data]);

  const handleSetCollapse = (name) => (collapse === name ? setCollapse(false) : setCollapse(name));

  const renderNavbarItems = routes.map(
    ({ name, icon, collapse: routeCollapses, href, route, collapse: navCollapse }) => (
      name === 'routes:headings.account' && visible !== true ? null :
        <DefaultNavbarDropdown
          key={name}
          name={t(name)}
          icon={name === 'routes:headings.account' ? <Avatar sx={{ opacity: 1 }} size="xs" alt="Remy Sharp" src={avatar} /> : icon}
          collapseStatus={name === collapse}
          onClick={() => handleSetCollapse(name)}
          href={href}
          route={route}
          collapse={Boolean(navCollapse)}
        >
          <Box sx={{ height: "15rem", maxHeight: "15rem", overflowY: "scroll" }}>
            {routeCollapses &&
              routeCollapses.map((item) =>
                item.name === "DIVIDER" || item.name === "LOGOUT" ?
                  null
                  : (
                    <Box key={item.name} px={2}>
                      {item.collapse ? (
                        <>
                          {item.name !== "LANGUAGE" && <Typography
                            display="block"
                            variant="button"
                            fontWeight="bold"
                            textTransform="capitalize"
                            py={1}
                            px={0.5}
                          >
                            {t(item.name)}
                          </Typography>}
                          {
                            item.name === "LANGUAGE" ?
                              <Box>
                                <Divider />
                                <LanguageSelection /></Box>

                              :
                              item.collapse.map((el) => (
                                <Typography
                                  key={el.name}
                                  component={el.route ? Link : MuiLink}
                                  to={el.route ? el.route : ""}
                                  href={el.href ? el.href : ""}
                                  target={el.href ? "_blank" : ""}
                                  rel={el.href ? "noreferrer" : "noreferrer"}
                                  minWidth="11.25rem"
                                  display="block"
                                  variant="button"
                                  color="text"
                                  textTransform="capitalize"
                                  fontWeight="regular"
                                  py={0.625}
                                  px={2}
                                  sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                                    borderRadius: borderRadius.md,
                                    cursor: "pointer",
                                    transition: "all 300ms linear",

                                    "&:hover": {
                                      backgroundColor: grey[200],
                                      color: dark.main,
                                    },
                                  })}
                                >
                                  {t(el.name)}
                                </Typography>
                              ))
                          }
                        </>
                      ) : (
                        <Box
                          key={item.key}
                          display="block"
                          component={item.route ? Link : MuiLink}
                          to={item.route ? item.route : ""}
                          href={item.href ? item.href : ""}
                          target={item.href ? "_blank" : ""}
                          rel={item.href ? "noreferrer" : "noreferrer"}
                          sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                            borderRadius: borderRadius.md,
                            cursor: "pointer",
                            transition: "all 300ms linear",
                            py: 1,
                            px: 1.625,

                            "&:hover": {
                              backgroundColor: grey[200],
                              color: dark.main,

                              "& *": {
                                color: dark.main,
                              },
                            },
                          })}
                        >
                          <Typography
                            display="block"
                            variant="button"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t(item.name)}
                          </Typography>
                          <Typography
                            display="block"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            sx={{ transition: "all 300ms linear" }}
                          >
                            {t(item.description)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))}
          </Box>
        </DefaultNavbarDropdown>
    )
  );

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <Box width="calc(100% + 1.625rem)" my={2} ml={-2}>
        {renderNavbarItems}
        <Box pl={2} display={{ xs: "block", sm: "none" }}>
          <AuthButton />
        </Box>
      </Box>
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
