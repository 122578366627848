import brand from "constants/brand";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function LandingPageHelmet({ }) {
    const { t } = useTranslation();

    let default_key_words = [
        "aeromate",
        "flugzeug chartern",
        "privatflugzeug mieten",
        "cessna mieten",
        "piper mieten",
        "cirrus mieten",
        "flugzeug mieten ohne pilot",
        "flugzeug vermieten",
        "privat flugzeug mieten",
        "aircraft",
        "aircraft charter",
        "aircraft for rent",
        "aircraft for hire",
        "aircraft for hire without pilot",
        "private aircraft for rent",
        "private plane",
        "private plane charter",
        "private plane for rent",
        "private plane for hire",
        "private plane for hire without pilot",
        "cessna for hire",
        "piper for hire",
        "cirrus for hire",
    ]

    return (
        <Helmet>
            <title>{brand.name_caps}</title>
            <description>Our mission is to provide a digital mate for general aviation with our technical and professional expertise. Aero Mate is designed to digitalize flight schools and help aircraft owners and pilots with processes such as rental, invoicing, maintenance, administration, marketing and much more.</description>
            <meta name="keywords" content={default_key_words.toString()}></meta>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "https://www.aeromate.eu",
                    "logo": "https://aeromatewesteurope.blob.core.windows.net/aeromate/Logo/logo_1024.png"
                }
                )}
            </script>
        </Helmet>
    )
}