import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Box from "components/Box";
import Typography from "components/Typography";
import { getLanguageTextWithFallBack, getBlobImageUrl } from "lib/functions";
import { Stack, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "hooks/modalHook";
import ShopCheckoutModal from "components/Modal/ShopCheckoutModal";
import Input from "components/Input";
import Button from "components/Button";
import { AuthButton } from "components/Button/AuthButton";
import { useUser } from "hooks/userDataHook";
import { formatCurrency } from "lib/legalInfo";
import PlaceHolderImage from "assets/images/aircraft.jpg";
import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined } from "@mui/icons-material";

function ShopItemCard({ item, charter, setImageLoaded, currency }) {
    const { addModal } = useModal();
    const { t } = useTranslation();
    const { isSignedIn } = useUser();
    const [activeSelection, setActiveSelection] = useState(0);
    const [itemType, setItemType] = useState();
    const [currenc, setCurrenc] = useState();

    const [previewImageIndex, setPreviewImageIndex] = useState(0);
    const [previewImages, setPreviewImages] = useState([PlaceHolderImage]);
    useEffect(() => {
        if (!!item?.image) {
            setPreviewImages(_.values(item.image).map((x) => _.isString(x) ? x : getBlobImageUrl(x)))
        }
    }, [item.image]);

    useEffect(() => {
        setItemType(item.item_type)
    }, [item.item_type]);

    useEffect(() => {
        if (!!charter?.currency) {
            setCurrenc(charter.currency);
        }
        else {
            setCurrenc(currency);
        }
    }, []);

    const next = () => {
        setPreviewImageIndex(previewImageIndex + 1)
    }
    const prev = () => {
        setPreviewImageIndex(previewImageIndex - 1 < 0 ? previewImages.length - 1 : previewImageIndex - 1)
    }

    const [buttonType, setButtonType] = useState();
    useEffect(() => {
        if (!!item?.item_type) {
            if (item.item_type === "enrollment") {
                async function createEnrollmentButton() {
                    if (!await isSignedIn()) {
                        setButtonType(<AuthButton />)
                    } else {
                        setButtonType(
                            <Button
                                variant="outlined"
                                size="small"
                                color="info"
                                onClick={() => addModal(<ShopCheckoutModal item={{ ...item, variantId: activeSelection, variant: item.variant[activeSelection] }} charter={charter} />)}>
                                {t("common:label.checkout")}
                            </Button>)
                    }
                }
                createEnrollmentButton()
            } else {
                setButtonType(
                    <Button
                        variant="outlined"
                        size="small"
                        color="info"
                        onClick={() => addModal(<ShopCheckoutModal item={{ ...item, variantId: activeSelection, variant: item.variant[activeSelection] }} charter={charter} />)}>
                        {t("common:label.checkout")}
                    </Button>
                )
            }
        }
    }, [item.item_type, activeSelection]);

    return (
        <Card style={{ height: "100%" }}>
            <Box position="relative" borderRadius="lg" mx={2} mt={-3}>
                {/*                 <Box
                    width="150px"
                    height="150px"
                    overflow="hidden"
                    position="absolute"
                    top="-10px"
                    right="-10px"
                    zIndex="10"
                    sx={{
                        "::before": {
                            left: "14px",
                            top: "0",
                            position: "absolute",
                            zIndex: "-1",
                            content: `''`,
                            display: "block",
                            border: "5px solid #f65f53"
                        },
                        "::after": {
                            bottom: "14px",
                            right: "0",
                            position: "absolute",
                            zIndex: "-1",
                            content: `''`,
                            display: "block",
                            border: "5px solid #f65f53"
                        }
                    }}
                >
                    <Box
                        position="absolute"
                        display="block"
                        width="225px"
                        px="0"
                        py="8px"
                        left="-25px"
                        top="30px"
                        sx={{
                            transform: "rotate(45deg)",
                            boxShadow: "0 5px 10px rgba(0,0,0,0.15)",
                            "::before": {
                                borderTopColor: "transparent",
                                borderRightColor: "transparent",
                                top: "0",
                                left: "0"
                            },
                            "::after": {
                                borderTopColor: "transparent",
                                borderRightColor: "transparent",
                                bottom: "0",
                                right: "0"
                            }
                        }}
                        backgroundColor="#db391a !important"
                        boxShadow="0 5px 10px rgba(0,0,0,.1)"
                        textAlign="center"
                    >20%</Box>
                </Box> */}
                <Box
                    component="img"
                    src={previewImages[previewImageIndex % previewImages.length]}
                    alt={getLanguageTextWithFallBack(item.caption)}
                    borderRadius="lg"
                    shadow=""
                    width="100%"
                    position="relative"
                    zIndex={1}
                    onLoad={() => {
                        !!setImageLoaded && setImageLoaded(document.body.scrollHeight)
                    }}
                />
                <Box
                    borderRadius="lg"
                    shadow=""
                    width="100%"
                    height="100%"
                    position="absolute"
                    left={0}
                    top={0}
                    sx={{
                        backgroundImage: `url(${previewImages[previewImageIndex % previewImages.length]})`,
                        transform: "scale(0.94)",
                        filter: "blur(12px)",
                        backgroundSize: "cover",
                    }}
                />
                {previewImages.length > 1 && (
                    <Box  >
                        <ArrowBackIosNewOutlined
                            color="info"
                            onClick={() => prev()}
                            sx={{ float: "left", position: "absolute", cursor: "pointer", top: "40%", left: 2, cursor: "pointer", zIndex: "100" }}
                        >
                        </ArrowBackIosNewOutlined>
                        <ArrowForwardIosOutlined
                            size="small"
                            color="info"
                            onClick={() => next()}
                            sx={{ float: "right", position: "absolute", cursor: "pointer", top: "40%", right: 2, cursor: "pointer", zIndex: "100" }}
                        >
                        </ArrowForwardIosOutlined>
                    </Box>
                )}
            </Box>
            <Box style={{ height: "100%" }} p={3} mt={-2}>
                <Stack
                    style={{ height: "100%" }}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Typography display="inline" variant="h5" fontWeight="bold">
                        {getLanguageTextWithFallBack(item.caption)}
                    </Typography>
                    <Typography variant="body2" component="p" color="text"  >
                        {!!item.description && (getLanguageTextWithFallBack(item.description)?.split('\n')[0])}
                    </Typography>
                    <Box mt={1} mb={3}>
                        {item?._id?.$oid && <Typography variant="body2" component="a" href={`/org/${charter.alias}/${item._id.$oid}`} target='_blank' color="info">
                            {t("common:label.more_information")}
                        </Typography>}
                    </Box>

                    {item.variant.length > 1 && <Box width="100%" lineHeight={2.5}>
                        <Autocomplete
                            fullWidth
                            disableClearable={true}
                            options={item.variant.map((item, id) => ({ ...item, id: id }))}
                            onChange={(e, v) => setActiveSelection(v.id)}
                            value={item.variant[activeSelection]}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => getLanguageTextWithFallBack(option.caption) + " ( " + formatCurrency(option.price, currenc) + " )"}
                            renderInput={(params) => (
                                <Input
                                    {...params}
                                    label={t("common:label.variants")}
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                            )}
                        />
                    </Box>}

                    <Box my={1}>
                        {!!item.variant[activeSelection].price && !!currenc ? (
                            <Typography sx={{ fontSize: "inherit" }} variant="h1" color="dark">

                                {formatCurrency(item.variant[activeSelection].price, currenc) ?? 0}
                                <Typography display="inline" component="small" variant="h5" color="inherit">
                                    / {charter.legal_entity === "aeromate.us" ? t("common:label.exclusiv_tax") : t("common:label.inclusiv_vat")}
                                </Typography>
                            </Typography>) : (

                            <Typography variant="h5" color="dark">
                                {t("common:label.onRequest")}
                            </Typography>
                        )

                        }
                    </Box>

                    <Box mb={1.5} >
                        {buttonType}
                    </Box>
                </Stack>
            </Box>
        </Card>
    );
}

ShopItemCard.propTypes = {
    item: PropTypes.object.isRequired,
};

export default ShopItemCard;
