import { Grid } from "@mui/material";
import Box from "components/Box";
import BasicInfo from "./components/BasicInfo";
import ConnectAccounts from "./components/ConnectAccounts";
import DeleteAccount from "./components/DeleteAccount";
import PaymentMethod from "./components/PaymentMethods";
import SimpleSidenav from "components/Sidenav/SimpleSidenav";
import { useTranslation } from "react-i18next";
import IdentityCheck from "./components/IdentityCheck";
import NoticationSettings from "./components/Notification";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function PersonalInfo() {
    const { t } = useTranslation();
    const { pathname, hash, key } = useLocation();
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]);
    return (
        <Box mt={4}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={3}>
                    <SimpleSidenav sidenavItems={[
                        { icon: "receipt_long", label: t("dashboard:personal.info.title"), href: "basic-info" },
                        { icon: "fingerprint", label: t("dashboard:personal.identity.title"), href: "identity-check" },
                        { icon: "payment", label: t("dashboard:personal.payment.title"), href: "payment-methods" },
                        { icon: "badge", label: t("dashboard:personal.connect.title"), href: "accounts" },
                        { icon: "notifications", label: t("dashboard:personal.notifications.title"), href: "notification" },
                        { icon: "delete", label: t("dashboard:personal.delete.title"), href: "delete" },
                    ]} />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Box mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <BasicInfo />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ position: "relative", top: "-100px" }} id="identity-check" ></Box>
                                <IdentityCheck />
                            </Grid>
                            <Grid item xs={12}>

                                <PaymentMethod />
                            </Grid>
                            <Grid item xs={12}>
                                <ConnectAccounts />
                            </Grid>
                            <Grid item xs={12}>
                                <NoticationSettings />
                            </Grid>
                            <Grid item xs={12}>
                                <DeleteAccount />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>)
}
