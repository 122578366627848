import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SubPageResponsibility() {
    const params = useParams();
    const { t } = useTranslation();

    const features = t("ResponsibilityPage:cards", { returnObjects: true })
    let content = features.find(o => o.link === window.location.pathname);
    console.log(content.title)


    return (
        <KitLayout title={content.title}  header={<Header title={content.title} image={content.image} description={content.description} />} >
            <Body content={content} />
        </KitLayout>
    )
}
