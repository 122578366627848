import { Stack } from "@mui/material";
import Box from "components/Box";
import CustomerSearch from "components/Input/CustomerSearch";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function TrainingTab({ reg, setEventSettings }) {
    const { t } = useTranslation();
    const [customerIdentity, setCustomerIdentity] = useState()
    const [staffIdentity, setStaffIdentity] = useState()

    useEffect(() => {
        let eventSettings = { flight_type: "training" }

        if (!!customerIdentity) eventSettings.identity_id = customerIdentity
        if (!!staffIdentity) eventSettings.staff_identity_id = staffIdentity

        setEventSettings(eventSettings)
    }, [customerIdentity, staffIdentity]);

    return (
        <Stack>
            <Box pt={1}>
                <TypographyBlock
                    dense={true}
                    nopadding
                    title={t("common:modal.calendar.training.student")}
                />
                <CustomerSearch
                    fullWidth
                    reg={reg}
                    onChange={setCustomerIdentity}
                />
            </Box>
            <Box pt={1}>
                <TypographyBlock
                    dense={true}
                    nopadding
                    title={t("common:modal.calendar.training.instructor")}
                />
                <CustomerSearch
                    fullWidth
                    reg={reg}
                    onChange={setStaffIdentity}
                    staffOnly
                />
            </Box>
        </Stack >
    );
}