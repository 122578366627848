import React, { useEffect, useState } from "react";
import { restGet } from "lib/rest";
import { Grid } from "@mui/material";
import DefaultTable from "components/Tables/DefaultTable";
import Button from "components/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AeroLoading from "components/Progress/AeroLoading"


export default function AirportNearest({ airport }) {
    const { t } = useTranslation()
    const [isloading, setIsLoading] = useState(false)
    const [nearestAirports, setNearestAirport] = useState()

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                if (!!resp && !!resp.data) {
                    setNearestAirport(resp.data.map(
                        (element) =>
                        (
                            {
                                ...element, action: (<Button
                                    component={Link}
                                    to={"/airport/" + element.icao}
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    fullWidth
                                >
                                    {t('common:button.details')}
                                </Button>
                                )
                            }
                        )
                    ))
                }
            }
            setIsLoading(true)
            await restGet(restCallback, 'airport/' + airport.icao + '/nearest')
            setIsLoading(false)
        }
        !!airport && fetch()
    }, [airport])

    if (!nearestAirports || !airport || isloading) {
        return isloading ? <AeroLoading /> : null;
    }

    const { columns } = {
        columns: [
            { name: "ICAO", accessor: "icao", align: "left", marginLeft: "16px" },
            { name: "Name", accessor: "name", align: "left" },
            { name: "Distance", accessor: "distanceNM", align: "left" },
            { name: "", accessor: "action", align: "right" },
        ]
    }
    

    return (
        <Grid container>
            <DefaultTable columns={columns} rows={nearestAirports} />
        </Grid>
    );
}