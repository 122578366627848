import Disclaimer from "./sections/Disclaimer";
import Imprint from "./sections/Imprint";

export default function Body() {
    return (
    <div>
        <Imprint />
        <Disclaimer />
    </div>
    )
}