import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Typography from "components/Typography";
import AircraftImages from "./sections/AircraftImages";
import AircraftInfo from "./sections/AircraftInfo";
import { restGet } from "lib/rest"
import { useParams } from 'react-router-dom';
import AircraftTabs from "./sections/AircraftTabs";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "hooks/useDocumentTitle";
import { DocumentTitle } from "hooks/useDocumentTitle";
import AircraftHelmet from "components/Helmets/AircraftHelmet";

export default function Body() {
    const { t } = useTranslation();
    let { REG } = useParams();

    const [aircraft, setAircraft] = useState();
    useEffect(() => {
        function restCallback(resp) {
            !!resp && !!resp.data && setAircraft(resp.data[0])
        }
        restGet(restCallback, 'aircraft/' + REG)
    }, [REG])

    const [title, setTitle] = useState(t("routes:aircraft.title"))
    useEffect(() => {
        !!aircraft && setTitle(t("routes:aircraft.title") + " " + aircraft.reg + " " + aircraft.name)
    }, [aircraft])
    
    return (
        <Box p={3}>
            {aircraft && <AircraftHelmet aircraft={aircraft} />}
            <Box mb={3}>
                <Typography variant="h5" fontWeight="medium">
                    {t("aircraftPage:title")}
                </Typography>
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} lg={6} xl={5}>
                    <AircraftImages aircraft={aircraft} />
                </Grid>
                <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                    <AircraftInfo aircraft={aircraft} />
                </Grid>
            </Grid>

            <Box mt={8} mb={2}>
                <Box mb={1} ml={2}>
                    {!!aircraft && <AircraftTabs aircraft={aircraft} />}
                </Box>
            </Box>
        </Box>)
}