import Box from "components/Box";
import DynamicForm from "components/Forms/DynamicForm/DynamicForm";
import TypographyBlock from "components/Typography/TypographyBlock";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function SettingsTab({ setData, aircraft }) {
    const { t } = useTranslation()

    const [aircraftBookingSetting, setAircraftBookingSetting] = useState();
    const [newAircraftBookingSetting, setNewAircraftBookingSetting] = useState();

    useEffect(() => {
        restGet((res) => { res.shift(); setAircraftBookingSetting(res) }, "dataset/metaDataAircraftBookingSetting")
    }, []);


    useEffect(() => {
        setData(newAircraftBookingSetting ?? {})
    }, [newAircraftBookingSetting]);

    return (
        <Box p={2}>
            <TypographyBlock
                title={t("dashboard:charter.aircrafts.steps.settings.booking")}
            />
            <Box p={3} ml={-1}>
                <DynamicForm
                    i18nextPrefix={"booking_settings"}
                    formMetaData={aircraftBookingSetting}
                    formDefaultValues={!!aircraft && !!aircraft.booking_setting ? aircraft.booking_setting : {}}
                    formValuesCallback={setNewAircraftBookingSetting}
                />
            </Box>
        </Box >
    )
}
