
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import _ from "lodash";
import { restPost } from "lib/rest";
import { Stack } from "@mui/material";
import Alert from "components/Alert";
import ClearIcon from '@mui/icons-material/ErrorOutline';
import { delay } from "lib/functions";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";
import { convertFrontendToTimeDatabase } from "lib/functions";

function PostReservationModal({ alias, reg, eventStart, eventEnd, eventSettings, comment }) {
    const { t } = useTranslation();
    const { removeModal } = useModal()
    const { refresh } = useRefresher();
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState([]);

    useEffect(async () => {

        let body = {
            "handover": convertFrontendToTimeDatabase(eventStart),
            "return": convertFrontendToTimeDatabase(eventEnd),
            "msg": comment,
            "reg": reg
        }
        var tmp = { ...eventSettings }
        if (!!tmp.identity_id) tmp.identity_id = tmp.identity_id.value
        if (!!tmp.staff_identity_id) tmp.staff_identity_id = tmp.staff_identity_id.value
        if (!!tmp.slot) tmp.slot = tmp.slot.slot
        body = Object.assign(body, tmp);

        //Flight Types that charter org pays
        console.log(body)
        debugger;;
        if (_.includes(["ferry", "voucher", "company"], body.flight_type)
        ) {
            setStatus("Processing Reservation")
            await restPost(null, `booking/`, body)
            await delay(1000);
            setStatus("Finished")
        }


        //Flight Types that identity pays
        else {
            setStatus("Checking Payment")
            var identityID = body.identity_id ?? body.staff_identity_id ?? "_self"
            var hasValidSubscription = false
            if (identityID) {
                const fetchCallback = (resp) => {
                    var paymentMethod;
                    if (!!resp && !!resp.data)
                        paymentMethod = _.filter(resp.data, (dataObject) => { return dataObject.default; });
                    if (!!paymentMethod && !!paymentMethod[0]) {
                        hasValidSubscription = true;
                    } else {
                        errors.push({ code: 190 })
                    }

                }
                await restGet(fetchCallback, 'identity/' + identityID + '/paymentmethod/' + reg)
            }

            setStatus("Checking Identity")
            identityID = body.identity_id ?? body.staff_identity_id ?? "_self"
            var hasIdentityCheck = false
            if (identityID) {
                const fetchCallback = (resp) => {
                    if (resp?.status === "verified") {
                        hasIdentityCheck = true;
                    } else {
                        errors.push({ code: 180 })
                    }
                }
                await restPost(fetchCallback, `identity/${identityID}/verification/${alias}`)
            }

            setStatus("Processing Reservation")
            if (hasValidSubscription && hasIdentityCheck) {
                await restPost(null, `booking/`, body)
            }
            setStatus("Finished")
        }
        refresh()
        await delay(1000);
        removeModal()
    }, [])

    return (
        <DefaultModal
            title={(reg ?? "")}
            closeExplicit={!(errors.length !== 0)}
            cancel={{
                show: errors.length !== 0,
                text: "Back",
                color: "error",
            }}
            confirm={{ show: false }}
        >{errors.length === 0 &&
            <AeroLoading customText={status} />
            }
            {errors.length !== 0 &&
                <Stack>{!!errors &&
                    errors.map((error, i) =>
                        <Alert color="error" key={"Booking-Alert-" + i}>
                            <ClearIcon fontSize="small" />&nbsp;
                            {t("error:" + error.code)}
                        </Alert>)}
                </Stack>
            }

        </DefaultModal >
    );
}

export default PostReservationModal;