/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import Typography from "components/Typography";

// Custom styles for Progress
import LinearProgressRoot from "./LinearProgressRoot";
import CircularProgressRoot from "./CircularProgressRoot";
import Box from "components/Box";
import Icon from "components/Icon";

const Progress = forwardRef(({ variant, color, value, label, circular, ...rest }, ref) => (
  <>
    {circular ?
      <Box sx={{ position: 'relative', display: 'inline-flex' }} width="fit-content">
        <CircularProgressRoot
          {...rest
          }
          ref={ref}
          variant="determinate"
          value={value}
          ownerState={{ color, value, variant }}
        />{label && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {value >= 100 ?
              <Icon color={color} fontSize="8px">check</Icon>
              :
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(value)}%`}
              </Typography>
            }
          </Box>
        )}
      </Box>
      : <>
        {label && (
          <Typography variant="button" fontWeight="medium" color="text">
            {value}%
          </Typography>
        )}
        <LinearProgressRoot
          {...rest}
          ref={ref}
          variant="determinate"
          value={value}
          ownerState={{ color, value, variant }}
        />
      </>
    }
  </>
));

// Setting default values for the props of Progress
Progress.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  label: false,
  circular: false,
};

// Typechecking props for the Progress
Progress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  value: PropTypes.number,
  label: PropTypes.bool,
  circular: PropTypes.bool,
};

export default Progress;
