import PropTypes from "prop-types";
import Box from "components/Box";
import Typography from "components/Typography";
import Progress from "components/Progress";
import { useState } from "react";
import { Stack } from "@mui/material";
import { useModal } from "hooks/modalHook";
import ChecklistModal from "components/Modal/ChecklistModal";

function ChecklistInfos({ color, value, status, name, description, checklist, callback, charterview }) {
    const [isHover, setIsHover] = useState(false);
    const { addModal } = useModal();
    const boxStyle = {
        backgroundColor: isHover ? '#f0f2f5' : 'white',
    };

    function handleClick() {
        addModal(<ChecklistModal list={checklist} callback={callback} />)
    }

    return (
        <Box key={name} component="li" py={1} pr={2} mb={1} borderRadius={8}
            style={boxStyle}
            onClick={!!charterview ? handleClick : null}
            onMouseEnter={() => setIsHover(charterview ? true : false)}
            onMouseLeave={() => setIsHover(false)}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Progress color="success" value={value} label={true} circular={true} size={30} />
                    <Box display="flex" flexDirection="column" mt={description ? "" : "4px !important"}>
                        <Typography variant="button" fontWeight="medium" gutterBottom>
                            {name}
                        </Typography>
                        {description && <Typography variant="caption" color="text" fontWeight="regular">
                            {description}
                        </Typography>}
                    </Box>
                </Stack>
                <Typography variant="button" color={color} fontWeight="medium" textGradient>
                    {status}
                </Typography>
            </Box>
        </Box>
    );
}

// Typechecking props of the Transaction
ChecklistInfos.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]).isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.number.isRequired,
    status: PropTypes.oneOf([
        "Completed",
        "In Progress",
        "Pending"
    ]).isRequired,
};

export default ChecklistInfos;
