import Overview from "./sections/Overview";
import Enterprise from "./sections/Enterprise";
import Recommandations from "./sections/Recommandations";
import FAQLandingPage from "./sections/FAQLandingPage";
import ContactUsLandingPage from "./sections/ContactUsLandingPage";
import LandingPageHelmet from "components/Helmets/LandingPageHelmet";

export default function Body() {
    return (
        <>
            <LandingPageHelmet />
            <Overview />
            <Recommandations />
            <Enterprise />
            <ContactUsLandingPage />
            <FAQLandingPage />
        </>
    )
}