import Grid from "@mui/material/Grid";
import Box from "components/Box";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AeroLoading from "components/Progress/AeroLoading"
import DashboardAircraftCard from "components/Cards/AircraftCards/DefaultAircraftCard/DashboardAircraftCard";
import { useTranslation } from "react-i18next";
import TypographyBlock from "components/Typography/TypographyBlock";
import AircraftCreateForm from "../AircraftCreateForm/AircraftCreateForm";

export default function Body() {
    const { t } = useTranslation();
    const { alias } = useParams();
    const [aircrafts, setAircrafts] = useState()
    const [isloading, setIsLoading] = useState(false)
    const [createAircraft, setCreateAircraft] = useState(false)

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                if (!!resp == []) { setAircrafts(null) }
                else { !!resp && !!resp.data && setAircrafts(resp.data) }
            }
            setIsLoading(true)
            await restGet(restCallback, 'charter/' + alias + "/aircrafts")
            setIsLoading(false)
        }
        !!alias && fetch()
    }, [alias])

    return (
        <Box my={3}>
            {/* Restrict Aircraft creation for staff&viewer */}
            <TypographyBlock
                title={t("dashboard:charter.aircrafts.title")}
                description={t("dashboard:charter.aircrafts.description")}
                action={() => { setCreateAircraft(!createAircraft) }}
                actionName={createAircraft ? t("dashboard:charter.aircrafts.close") : t("dashboard:charter.aircrafts.create")}
            />
            <Grid container spacing={3} alignItems="stretch">
                {createAircraft &&
                    <Grid item xs={12}>
                        <AircraftCreateForm />
                    </Grid>
                }
                {isloading ? <AeroLoading /> : !aircrafts ? null : aircrafts.map((x, i) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} xl={3} key={"aircraft-card-" + i} >
                            <Box mt={6} pb={3} style={{ height: "100%" }}>
                                <DashboardAircraftCard
                                    image={x.image}
                                    title={x.name + " (" + x.reg + ")"}
                                    aircraft={x}
                                    alias={alias}
                                />
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}