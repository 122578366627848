import Card from "@mui/material/Card";
import Box from "components/Box";
import { useTranslation } from "react-i18next";
import { restPost } from "lib/rest";
import { useEffect, useState } from "react";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useUser } from "hooks/userDataHook";
import { Grid } from "@mui/material";
import AeroLoading from "components/Progress/AeroLoading";
import Typography from "components/Typography";
import Badge from "components/Badge";

function IdentityCheck() {
    const { t } = useTranslation();
    const userData = useUser();
    const [clicked, setClicked] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState()
    const [verificationURL, setVerificationURL] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                setVerificationURL(resp?.url)
                setVerificationStatus(resp?.status)
            }
            setIsLoading(true)
            await restPost(restCallback, 'identity/_self/verification/' + userData.verification_id)
            setIsLoading(false)
        }
        fetch()
    }, [])

    function startVerification() {
        setClicked(true)
        window.open(verificationURL)
    }

    if (isLoading)
        return (
            <Card>
                <TypographyBlock
                    title={t("dashboard:personal.identity.title")}
                    description={t("dashboard:personal.identity.description")}
                />
                <AeroLoading />
            </Card>
        )

    return (
        <Card >
            {!clicked && <TypographyBlock
                title={t("dashboard:personal.identity.title")}
                description={t("dashboard:personal.identity.description")}
                action={() => (startVerification())}
                actionName={t("common:button.verify")}
                disabled={verificationStatus === "verified"}
            />}
            {clicked && <TypographyBlock
                title={t("dashboard:personal.identity.title")}
                description={t("dashboard:personal.identity.refresh")}
                action={() => (window.location.reload())} // expliziter reload wegen identity change
                actionName={t("common:button.refresh")}
            />}
            {(verificationStatus) ?
                <Box p={3}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <Grid item>
                            <Typography variant="h5">
                                {t("dashboard:personal:identity.identity")}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Badge
                                container
                                variant="gradient"
                                color={verificationStatus !== "verified" ? 'warning' : 'success'}
                                badgeContent={verificationStatus !== "verified" ? 'Pending' : 'Done'}
                            />
                        </Grid>
                    </Grid>
                </Box>
                :
                null
            }
        </Card>
    );
}

export default IdentityCheck;
