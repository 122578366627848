import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useTranslation } from "react-i18next";

export default function GettingStartedPage() {
    const {t} = useTranslation();
    return (
        <KitLayout header={<Header />} title={t("routes:gettingStarted.title")}>
            <Body />
        </KitLayout>
    )
}
