import DefaultModal from "components/Modal/DefaultModal";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import TableCell from "components/Tables/TableCell";
import { Card, Switch } from "@mui/material";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { restPost } from "lib/rest";
import { restGet } from "lib/rest";

function PersonalNotification() {

    const { t } = useTranslation();
    const [checkride, setCheckride] = useState({ popup: false, sms: false, mail: false });
    const [booking, setBooking] = useState({ popup: false, sms: false, mail: false });
    const [document, setDocument] = useState({ popup: false, sms: false, mail: false });
    const [keyvault, setKeyvault] = useState({ popup: false, sms: false, mail: false });
    const [follow, setFollow] = useState({ popup: false, sms: false, mail: false });

    useEffect(() => {
        const fetch = async () => {

            function restCallback(resp) {

                var notifcation_settings = resp?.notifcation_settings

                setCheckride({ popup: notifcation_settings?.checkride?.popup, sms: notifcation_settings?.checkride.sms, mail: notifcation_settings?.checkride.mail })
                setBooking({ popup: notifcation_settings?.booking?.popup, sms: notifcation_settings?.booking.sms, mail: notifcation_settings?.booking.mail })
                setDocument({ popup: notifcation_settings?.document?.popup, sms: notifcation_settings?.document.sms, mail: notifcation_settings?.document.mail })
                setKeyvault({ popup: notifcation_settings?.keyvault?.popup, sms: notifcation_settings?.keyvault.sms, mail: notifcation_settings?.keyvault.mail })
                setFollow({ popup: notifcation_settings?.follow?.popup, sms: notifcation_settings?.follow.sms, mail: notifcation_settings?.follow.mail })

            }

            await restGet(restCallback, "Identity/NotificationRetrieve");
        }
        fetch();


    }, [])


    async function submitNotifications() {
        let object = {};

        object = { checkride, booking, document, keyvault, follow }

        await restPost(null, "Identity/hjbdfb/Notification", object)
    }


    const updateCheckride = e => {
        const name = e.target.name

        setCheckride(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateBooking = e => {
        const name = e.target.name

        setBooking(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateDocument = e => {
        const name = e.target.name

        setDocument(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateKeyvault = e => {
        const name = e.target.name

        setKeyvault(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateFollow = e => {
        const name = e.target.name

        setFollow(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }





    return (
        <Card>
            <Box sx={{ position: "absolute", top: "-100px" }} ></Box>
            <TypographyBlock
                title={t("common:notifications.title")}
                actionName={t("common:button.save")}
                action={submitNotifications}

            />
            <Box pb={3} px={3}>
                <Box minWidth="auto" sx={{ overflow: "scroll" }}>
                    <Table sx={{ minWidth: "36rem" }}>
                        <Box component="thead">
                            <TableRow>
                                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                    Activity
                                </TableCell>
                                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                    Email
                                </TableCell>
                                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                    Push
                                </TableCell>
                                {/* <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                    SMS
                                </TableCell> */}
                            </TableRow>
                        </Box>
                        <TableBody>
                            <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Checkride
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.checkride")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={checkride.mail}
                                        onChange={updateCheckride} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={checkride?.popup}
                                        onChange={updateCheckride} name="popup" />
                                </TableCell>
                                {/* <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={checkride.sms}
                                        onChange={updateCheckride} name="sms" />
                                </TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Booking
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.booking")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={booking.mail}
                                        onChange={updateBooking} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={booking?.popup}
                                        onChange={updateBooking} name="popup" />
                                </TableCell>
                                {/* <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={booking.sms}
                                        onChange={updateBooking} name="sms" />
                                </TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Document
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.document")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={document.mail}
                                        onChange={updateDocument} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={document?.popup}
                                        onChange={updateDocument} name="popup" />
                                </TableCell>
                                {/* <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={document.sms}
                                        onChange={updateDocument} name="sms" />
                                </TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Keyvault Codes
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.keyvaultcode")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={keyvault.mail}
                                        onChange={updateKeyvault} name="mail" />
                                </TableCell>
                                <TableCell  align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={keyvault?.popup}
                                        onChange={updateKeyvault} name="popup" />
                                </TableCell>
                                {/* <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={keyvault.sms}
                                        onChange={updateKeyvault} name="sms" />
                                </TableCell> */}
                            </TableRow>
                  {/*           <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Follows
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.follows")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={follow.mail}
                                        onChange={updateFollow} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={follow?.popup}
                                        onChange={updateFollow} name="popup" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={follow.sms}
                                        onChange={updateFollow} name="sms" />
                                </TableCell>
                            </TableRow> */}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </Card>

    );
}

export default PersonalNotification;