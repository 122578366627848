/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import Avatar from "components/Avatar";
import Input from "components/Input";
import Button from "components/Button";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import { FlightTakeoff, Route } from "@mui/icons-material";
import ComingSoon from "components/Common/ComingSoon";

function ConnectAccounts() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
        <Card>
            <Box sx={{ position: "absolute", top: "-100px" }} id="accounts"></Box>
            <Box p={3} lineHeight={1}>
                <Box mb={1}>
                    <Typography variant="h5">Accounts</Typography>
                </Box>
                <Typography variant="button" color="text">
                    Here you can setup and manage your integration settings.
                </Typography>
            </Box>
            {/* <Box pt={2} pb={3} px={3}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                >
                    <Box display="flex" alignItems="center">
                        <Avatar bgColor="info">
                            <FlightTakeoff />
                        </Avatar>
                        <Box ml={2}>
                            <Typography variant="h5" fontWeight="medium">
                                aeroPS
                            </Typography>
                            <Box display="flex" alignItems="flex-end">
                                <Typography variant="button" color="text">
                                    Show less
                                </Typography>
                                <Typography variant="button" color="text" sx={{ lineHeight: 0 }}>
                                    <Icon fontSize="small">expand_less</Icon>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        width={{ xs: "100%", sm: "auto" }}
                        mt={{ xs: 1, sm: 0 }}
                    >
                        <Button variant="gradient" color="dark">Connect</Button>
                    </Box>
                </Box>
                <Box ml={2} pl={6} pt={2} lineHeight={1}>
                    <Typography variant="button" color="text">
                        You haven&apos;t added your Slack yet or you aren&apos;t authorized. Please add our
                        Slack Bot to your account by clicking on here. When you&apos;ve added the bot, send your
                        verification code that you have received.
                    </Typography>
                    <Box
                        bgColor={darkMode ? "grey-900" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={3}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                    >
                        <Typography variant="button" fontWeight="medium" color="text">
                            Verification Code
                        </Typography>
                        <Box width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                            <Tooltip title="Copy" placement="top">
                                <Input size="small" value="1172913" />
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        bgColor={darkMode ? "grey-900" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={3}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                    >
                        <Typography variant="button" fontWeight="medium" color="text">
                            Connected account
                        </Typography>
                        <Box
                            display="flex"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <Box mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0}>
                                <Typography variant="button" fontWeight="medium">
                                    hello@creative-tim.com
                                </Typography>
                            </Box>
                            <Button variant="gradient" color="error" size="small">
                                delete
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Divider />
            </Box>
            <Box pt={2} pb={3} px={3}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                >
                    <Box display="flex" alignItems="center">
                        <Avatar bgColor="info">
                            <Route />
                        </Avatar>
                        <Box ml={2}>
                            <Typography variant="h5" fontWeight="medium">
                                Garmin
                            </Typography>
                            <Box display="flex" alignItems="flex-end">
                                <Typography variant="button" color="text">
                                    Show less
                                </Typography>
                                <Typography variant="button" color="text" sx={{ lineHeight: 0 }}>
                                    <Icon fontSize="small">expand_less</Icon>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        width={{ xs: "100%", sm: "auto" }}
                        mt={{ xs: 1, sm: 0 }}
                    >
                        <Button variant="gradient" color="dark">Connect</Button>
                    </Box>
                </Box>
                <Box ml={2} pl={6} pt={2} lineHeight={1}>
                    <Typography variant="button" color="text">
                        You haven&apos;t added your Slack yet or you aren&apos;t authorized. Please add our
                        Slack Bot to your account by clicking on here. When you&apos;ve added the bot, send your
                        verification code that you have received.
                    </Typography>
                    <Box
                        bgColor={darkMode ? "grey-900" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={3}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                    >
                        <Typography variant="button" fontWeight="medium" color="text">
                            Verification Code
                        </Typography>
                        <Box width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                            <Tooltip title="Copy" placement="top">
                                <Input size="small" value="1172913" />
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        bgColor={darkMode ? "grey-900" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={3}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                    >
                        <Typography variant="button" fontWeight="medium" color="text">
                            Connected account
                        </Typography>
                        <Box
                            display="flex"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <Box mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0}>
                                <Typography variant="button" fontWeight="medium">
                                    hello@creative-tim.com
                                </Typography>
                            </Box>
                            <Button variant="gradient" color="error" size="small">
                                delete
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box> */}
            <ComingSoon />
        </Card>
    );
}

export default ConnectAccounts;
