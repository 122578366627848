import { Skeleton } from "@mui/material";
import { hoursToMilliseconds } from "lib/time";
import { NumericFormat } from "react-number-format";

function OpsCounterHours({ customInput, defaultValue, onChange }) {
    if (defaultValue !== null && defaultValue !== undefined) {
        return (
            <NumericFormat
                defaultValue={defaultValue}
                decimalScale={2}
                fixedDecimalScale
                decimalSeparator="."
                customInput={customInput}
                onValueChange={(values) => {
                    const { floatValue } = values;
                    onChange(hoursToMilliseconds(floatValue))
                }}
            />
        )
    }
    return <Skeleton variant="rounded" height={56} />
}

export default OpsCounterHours;