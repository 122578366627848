import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useTranslation } from "react-i18next";

export default function KnowledgebasePage() {
    const { t } = useTranslation();
    return (
        <KitLayout header={<Header />} title={t("routes:knowledgeCenter.title")}>
            <Body />
            {/* <ComingSoon /> */}
        </KitLayout>
    )
}
