import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";

const bgImage = "https://aeromatewesteurope.blob.core.windows.net/aeromate/header/rough-horn-2146181_1920.jpg";

function FAQLandingPage() {
    const { t } = useTranslation();
    return (
        <Box component="section" py={12}>
            <Grid container item xs={10} sx={{ mx: "auto" }}>
                <Box
                    borderRadius="lg"
                    width="100%"
                    py={6}
                    sx={{
                        backgroundPosition: "center",
                        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))}, url(${bgImage})`,
                    }}
                >
                    <Container>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={8} lg={5}>
                                <Typography variant="h1" color="white" fontWeight="bold">
                                    {t("landingPage:faq.title")}
                                </Typography>
                                <Typography variant="h5" color="white" fontWeight="bold">
                                    {t("landingPage:faq.description")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
                                <Box width="12rem" ml="auto">
                                    <Button variant="gradient" color="primary" href="knowledgebase" fullWidth sx={{ boxShadow: "none" }}>
                                        {t("landingPage:faq.button")}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Grid>
        </Box>
    );
}

export default FAQLandingPage;