import { centToEuro } from "lib/functions";
import { getBlobImageUrl } from "lib/functions";
import { restGet } from "lib/rest";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function AircraftHelmet({ aircraft }) {
    const { t } = useTranslation();

    let default_key_words = [
        "certification",
        "class",
        "homebase",
        "type",
        "aeromate",
        "charter",
        "rent",
        "mieten",
        "aeromate flugzeug",
        "aeromate privat flugzeug",
        "aeromate aircraft",
        "aeromate private aircraft",
        "aeromate private plane",

        "aeromate charter flugzeug",
        "aeromate charter privat flugzeug",
        "aeromate charter aircraft",
        "aeromate charter private aircraft",
        "aeromate charter private plane",

        "aeromate rent flugzeug",
        "aeromate rent privat flugzeug",
        "aeromate rent aircraft",
        "aeromate rent private aircraft",
        "aeromate rent private plane",

        "aeromate charter",
        "aeromate rent",

        "flugzeug",
        "flugzeug chartern",
        "flugzeug vermieten",
        "flugzeug mieten ohne pilot",
        "privat flugzeug chartern",
        "privat flugzeug vermieten ",
        "privat flugzeug mieten ohne pilot ",
        aircraft.type + " chartern",
        aircraft.type + " vermieten ",
        aircraft.type + " mieten ohne pilot ",
        aircraft.name + " chartern",
        aircraft.name + " vermieten ",
        aircraft.name + " mieten ohne pilot ",
        "aircraft",
        "aircraft charter",
        "aircraft for rent",
        "aircraft for hire",
        "aircraft for hire without pilot",
        "private aircraft",
        "private aircraft charter",
        "private aircraft for rent",
        "private aircraft for hire",
        "private aircraft for hire without pilot",
        "private plane",
        "private plane charter",
        "private plane for rent",
        "private plane for hire",
        "private plane for hire without pilot",
        aircraft.type + " for rent",
        aircraft.type + " for hire",
        aircraft.type + " for hire without pilot",
        aircraft.name + " for rent",
        aircraft.name + " for hire",
        aircraft.name + " for hire without pilot",

        "flugzeug chartern " + aircraft.homebase,
        "flugzeug vermieten " + aircraft.homebase,
        "flugzeug mieten ohne pilot " + aircraft.homebase,
        "privat flugzeug chartern " + aircraft.homebase,
        "privat flugzeug vermieten  " + aircraft.homebase,
        "privat flugzeug mieten ohne pilot  " + aircraft.homebase,
        aircraft.type + " chartern " + aircraft.homebase,
        aircraft.type + " vermieten  " + aircraft.homebase,
        aircraft.type + " mieten ohne pilot  " + aircraft.homebase,
        aircraft.name + " chartern " + aircraft.homebase,
        aircraft.name + " vermieten  " + aircraft.homebase,
        aircraft.name + " mieten ohne pilot  " + aircraft.homebase,
        "aircraft " + aircraft.homebase,
        "aircraft charter " + aircraft.homebase,
        "aircraft for rent " + aircraft.homebase,
        "aircraft for hire " + aircraft.homebase,
        "aircraft for hire without pilot " + aircraft.homebase,
        "private aircraft " + aircraft.homebase,
        "private aircraft charter " + aircraft.homebase,
        "private aircraft for rent " + aircraft.homebase,
        "private aircraft for hire " + aircraft.homebase,
        "private aircraft for hire without pilot " + aircraft.homebase,
        "private plane " + aircraft.homebase,
        "private plane charter " + aircraft.homebase,
        "private plane for rent " + aircraft.homebase,
        "private plane for hire " + aircraft.homebase,
        "private plane for hire without pilot " + aircraft.homebase,
        aircraft.type + " for rent " + aircraft.homebase,
        aircraft.type + " for hire " + aircraft.homebase,
        aircraft.type + " for hire without pilot " + aircraft.homebase,
        aircraft.name + " for rent " + aircraft.homebase,
        aircraft.name + " for hire " + aircraft.homebase,
        aircraft.name + " for hire without pilot " + aircraft.homebase,

        "aeromate" + aircraft.class,
        "aeromate" + aircraft.homebase,
        "aeromate" + aircraft.name,
        "aeromate" + aircraft.reg,
        "aeromate" + aircraft.reg.replace('-', ''),
        "aeromate" + aircraft.type,

        aircraft.certification,
        aircraft.class,
        aircraft.homebase,
        aircraft.name,
        aircraft.reg,
        aircraft.reg.replace('-', ''),
        aircraft.type,
        aircraft.reg_country]

    function getAircraftCharterRate() {
        if (!!aircraft.rates) {
            var res = _.find(aircraft.rates, { 'flight_type': 'charter_self' })
            if (!!res && !!res.unit_amount) {
                return res.rate * 60
            }
        }
        return undefined;
    }

    useEffect(async () => {
        var airport = await restGet(null, `airport/${aircraft.homebase}`)
        airport = airport[0];
        if (airport) {
            var iata = [
                "flugzeug " + airport.iata,
                "flugzeug chartern " + airport.iata,
                "flugzeug vermieten " + airport.iata,
                "flugzeug mieten ohne pilot " + airport.iata,
                "privat flugzeug chartern " + airport.iata,
                "privat flugzeug vermieten  " + airport.iata,
                "privat flugzeug mieten ohne pilot  " + airport.iata,
                aircraft.type + " chartern " + airport.iata,
                aircraft.type + " vermieten  " + airport.iata,
                aircraft.type + " mieten ohne pilot  " + airport.iata,
                aircraft.name + " chartern " + airport.iata,
                aircraft.name + " vermieten  " + airport.iata,
                aircraft.name + " mieten ohne pilot  " + airport.iata,
                "aircraft " + airport.iata,
                "aircraft charter " + airport.iata,
                "aircraft for rent " + airport.iata,
                "aircraft for hire " + airport.iata,
                "aircraft for hire without pilot " + airport.iata,
                "private aircraft " + airport.iata,
                "private aircraft charter " + airport.iata,
                "private aircraft for rent " + airport.iata,
                "private aircraft for hire " + airport.iata,
                "private aircraft for hire without pilot " + airport.iata,
                "private plane " + airport.iata,
                "private plane charter " + airport.iata,
                "private plane for rent " + airport.iata,
                "private plane for hire " + airport.iata,
                "private plane for hire without pilot " + airport.iata,
                aircraft.type + " for rent " + airport.iata,
                aircraft.type + " for hire " + airport.iata,
                aircraft.type + " for hire without pilot " + airport.iata,
                aircraft.name + " for rent " + airport.iata,
                aircraft.name + " for hire " + airport.iata,
                aircraft.name + " for hire without pilot " + airport.iata,
            ]
            var municipality = [
                "flugzeug " + airport.municipality,
                "flugzeug chartern " + airport.municipality,
                "flugzeug vermieten " + airport.municipality,
                "flugzeug mieten ohne pilot " + airport.municipality,
                "privat flugzeug chartern " + airport.municipality,
                "privat flugzeug vermieten  " + airport.municipality,
                "privat flugzeug mieten ohne pilot  " + airport.municipality,
                aircraft.type + " chartern " + airport.municipality,
                aircraft.type + " vermieten  " + airport.municipality,
                aircraft.type + " mieten ohne pilot  " + airport.municipality,
                aircraft.name + " chartern " + airport.municipality,
                aircraft.name + " vermieten  " + airport.municipality,
                aircraft.name + " mieten ohne pilot  " + airport.municipality,
                "aircraft " + airport.municipality,
                "aircraft charter " + airport.municipality,
                "aircraft for rent " + airport.municipality,
                "aircraft for hire " + airport.municipality,
                "aircraft for hire without pilot " + airport.municipality,
                "private aircraft " + airport.municipality,
                "private aircraft charter " + airport.municipality,
                "private aircraft for rent " + airport.municipality,
                "private aircraft for hire " + airport.municipality,
                "private aircraft for hire without pilot " + airport.municipality,
                "private plane " + airport.municipality,
                "private plane charter " + airport.municipality,
                "private plane for rent " + airport.municipality,
                "private plane for hire " + airport.municipality,
                "private plane for hire without pilot " + airport.municipality,
                aircraft.type + " for rent " + airport.municipality,
                aircraft.type + " for hire " + airport.municipality,
                aircraft.type + " for hire without pilot " + airport.municipality,
                aircraft.name + " for rent " + airport.municipality,
                aircraft.name + " for hire " + airport.municipality,
                aircraft.name + " for hire without pilot " + airport.municipality,
            ]
            var name = [
                "flugzeug " + airport.name,
                "flugzeug chartern " + airport.name,
                "flugzeug vermieten " + airport.name,
                "flugzeug mieten ohne pilot " + airport.name,
                "privat flugzeug chartern " + airport.name,
                "privat flugzeug vermieten  " + airport.name,
                "privat flugzeug mieten ohne pilot  " + airport.name,
                aircraft.type + " chartern " + airport.name,
                aircraft.type + " vermieten  " + airport.name,
                aircraft.type + " mieten ohne pilot  " + airport.name,
                aircraft.name + " chartern " + airport.name,
                aircraft.name + " vermieten  " + airport.name,
                aircraft.name + " mieten ohne pilot  " + airport.name,
                "aircraft " + airport.name,
                "aircraft charter " + airport.name,
                "aircraft for rent " + airport.name,
                "aircraft for hire " + airport.name,
                "aircraft for hire without pilot " + airport.name,
                "private aircraft " + airport.name,
                "private aircraft charter " + airport.name,
                "private aircraft for rent " + airport.name,
                "private aircraft for hire " + airport.name,
                "private aircraft for hire without pilot " + airport.name,
                "private plane " + airport.name,
                "private plane charter " + airport.name,
                "private plane for rent " + airport.name,
                "private plane for hire " + airport.name,
                "private plane for hire without pilot " + airport.name,
                aircraft.type + " for rent " + airport.name,
                aircraft.type + " for hire " + airport.name,
                aircraft.type + " for hire without pilot " + airport.name,
                aircraft.name + " for rent " + airport.name,
                aircraft.name + " for hire " + airport.name,
                aircraft.name + " for hire without pilot " + airport.name,
            ]
            var diverse = [
                airport.iata,
                airport.municipality,
                airport.name
            ]
            default_key_words = default_key_words.concat(iata, municipality, name, diverse);
        }
    }, [aircraft.homebase])
    return (
        <Helmet>
            <title>{t("routes:aircraft.title") + " " + aircraft.reg + " " + aircraft.name} </title>
            <meta name="keywords" content={default_key_words.toString()}></meta>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": aircraft.name,
                    "image": _.values(aircraft.image).map((v) => getBlobImageUrl(v)),
                    "description": aircraft?.description?.en ?? "",
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": aircraft?.ratings_stat?.avg_rating ?? 0,
                        "ratingCount": aircraft?.ratings_stat?.rating_cnt ?? 0
                    },
                    "brand": {
                        "@type": "Brand",
                        "name": aircraft.charter[0].legal_info.chartername
                    },
                    "offers": {
                        "@type": "Offer",
                        "url": `https://${window.location.hostname}/aircraft/${aircraft.reg}`,
                        "priceCurrency": "EUR",
                        "price": getAircraftCharterRate()
                    }
                })}
            </script>
        </Helmet>
    )
}