import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";


export default function Selection({ label, value, onChange, options, ...rest }) {
    return (
        <FormControl fullWidth>
            <InputLabel id={label}>{label}</InputLabel>
            <Select
                labelId={label}
                id={label}
                value={value}
                label={label}
                onChange={onChange}
            >
                {
                    options.map((val, index) => <MenuItem key={"menu-item-" + { label } + "-" + index} value={val.value}>{val.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}