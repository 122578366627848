import { useState, useEffect, useCallback } from "react"
import { Slider, FormControlLabel, Checkbox, Stack, Grid } from "@mui/material";
import Input from "components/Input";
import Box from "components/Box";
import AutoComplete from "components/Input/AutoComplete";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import FudgeButtonGroup from "components/ButtonGroup";

export default function EditItem(props) {
    const { t } = useTranslation();
    const { dataCallback, allowedTypes } = props;
    const options = _.filter([{ value: 0, label: "default" },
    { value: 1, label: "fudge" },
    { value: 10, label: "number" },
    { value: 20, label: "checkbox" },
    { value: 30, label: "range" },
    { value: 40, label: "text" }], (v) => { return allowedTypes?.includes(v.value) ?? true })
    const defaults = {
        1: { val: 1, goal: 0 },
        10: { val: 0, goal: 0 },
        20: { val: false, goal: true },
        30: { val: [0, 30], goal: [10, 20] },
        40: { val: "", goal: "GOAL!" }
    }
    const [title, setTitle] = useState(props.text ?? "");
    const [inputType, setInputType] = useState(props.type ?? options[0].value);
    const [inputDefault, setInputDefault] = useState(props.default ?? defaults[inputType]?.val);
    const [inputGoal, setInputGoal] = useState(props.goal ?? defaults[inputType]?.goal);

    function getRangeArray(original, value, index) {
        let tmp = [...original];
        tmp.splice(index, 1, value)
        return tmp
    }

    useEffect(() => {
        dataCallback({
            "type": inputType,
            "text": title,
            "goal": inputGoal,
            "default": inputDefault
        })
    }, [title, inputType, inputDefault, inputGoal])

    const inputs = useCallback(() => {
        switch (inputType) {
            case 1:
                return (
                    <Stack direction="row" spacing={3}>
                        <FudgeButtonGroup onChange={(e) => setInputDefault(e.target.value)} value={inputDefault} />
                    </Stack>
                )
            case 10:
                return (
                    <Stack direction="row" spacing={3}>
                        <Input sx={{ width: { xs: "256px" } }} label={t("common:label.default")} type="number" onChange={(e) => setInputDefault(e.target.value)} value={inputDefault} />
                        <Input sx={{ width: { xs: "256px" } }} label={t("common:label.goal")} type="number" onChange={(e) => setInputGoal(e.target.value)} value={inputGoal} />
                    </Stack>
                )
            case 20:
                return (
                    <Stack direction="row" spacing={3}  >
                        <FormControlLabel sx={{ width: { xs: "256px" } }} control={<Checkbox onChange={(e) => setInputDefault(e.target.checked)} checked={inputDefault} />} label={t("common:label.default")} />
                        <FormControlLabel sx={{ width: { xs: "256px" } }} control={<Checkbox onChange={(e) => setInputGoal(e.target.checked)} checked={inputGoal} />} label={t("common:label.goal")} />
                    </Stack>
                )
            case 30:
                return (
                    <Stack direction="column" spacing={3}>
                        <Slider
                            disabled
                            value={inputGoal}
                            min={inputDefault[0]}
                            max={inputDefault[1]}
                            getAriaLabel={() => t("common:label.default")}
                            valueLabelDisplay="auto"
                            getAriaValueText={(value) => value + ""}
                            marks={inputDefault.map((e) => ({ value: e, label: e }))}
                        />
                        <Stack direction="row" spacing={3}>
                            <Input sx={{ width: { xs: "128px" } }} label={t("common:label.minimum")} type="number" onChange={(e) => setInputDefault(getRangeArray(inputDefault, e.target.value, 0))} value={inputDefault[0]} />
                            <Input sx={{ width: { xs: "128px" } }} label={t("common:label.minimum_goal")} type="number" onChange={(e) => setInputGoal(getRangeArray(inputGoal, e.target.value, 0))} value={inputGoal[0]} />
                            <Input sx={{ width: { xs: "128px" } }} label={t("common:label.maximum_goal")} type="number" onChange={(e) => setInputGoal(getRangeArray(inputGoal, e.target.value, 1))} value={inputGoal[1]} />
                            <Input sx={{ width: { xs: "128px" } }} label={t("common:label.maximum")} type="number" onChange={(e) => setInputDefault(getRangeArray(inputDefault, e.target.value, 1))} value={inputDefault[1]} />
                        </Stack>
                    </Stack >
                )
            case 40:
                return (
                    <Stack direction="row" spacing={3}>
                        <Input sx={{ width: { xs: "256px" } }} label={t("common:label.default")} type="text" onChange={(e) => setInputDefault(e.target.value)} value={inputDefault} />
                        <Input sx={{ width: { xs: "256px" } }} label={t("common:label.goal")} type="text" onChange={(e) => setInputGoal(e.target.value)} value={inputGoal} />
                    </Stack>
                )
            default:
                return null
        }
    }, [inputType, inputGoal, inputDefault])

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            p={1} pl={2} pr={2}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}>
                <Stack
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    spacing={3}>
                    <Checkbox disabled checked={inputDefault === inputGoal} />
                    <Input sx={{ minWidth: { xs: "320px" } }} label={t("common:label.description")} type="text" onChange={(e) => setTitle(e.target.value)} value={title} />
                    <AutoComplete
                        disableClearable
                        sx={{ width: { xs: "128px" } }}
                        label={t("common:label.select_type")}
                        value={_.find(options, { value: inputType })}
                        options={options}
                        onChange={(e) => {
                            setInputDefault(e.value !== 0 ? defaults[e.value].val : null)
                            setInputGoal(e.value !== 0 ? defaults[e.value].goal : null)
                            setInputType(e.value)
                        }}
                        getOptionLabel={(value) => t("common:label." + value.label)}
                    />
                </Stack>
                {inputs()}
            </Stack>
        </Grid>
    )
}