import Box from "components/Box";
import Invoice from "components/Invoice/DefaultInvoice";
import AeroLoading from "components/Progress/AeroLoading";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Summary({ stepData, legal }) {
    const { alias, identity_id } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [from, setFrom] = useState();
    const [to, setTo] = useState();

    useEffect(() => {
        const fetch = async () => {
            const callback = async (resp) => {
                if (resp?.data[0]) {
                    var stripeAccount = await restGet(null, `account/${resp?.data[0].account_id}/charter/${resp?.data[0].alias}`)

                    let tmp = {}
                    tmp.name = resp.data[0].legal_info.chartername
                    tmp.address1 = stripeAccount?.data?.terms?.address
                    setFrom(tmp)
                }
                setIsLoading(false)
            }
            setIsLoading(true)
            await restGet(callback, `charter/${alias}`)
        }
        !!alias && fetch()
    }, [alias])

    useEffect(() => {
        if (!!stepData.payment && !!stepData.payment.billing_details) {
            let tmp = {}
            tmp.name = stepData.payment.billing_details.name
            tmp.email = stepData.payment.billing_details.email
            tmp.address1 = stepData.payment.billing_details.address.line1
            tmp.address2 = stepData.payment.billing_details.address.line2
            tmp.postalcode = stepData.payment.billing_details.address.postal_code
            tmp.city = stepData.payment.billing_details.address.city
            tmp.country = stepData.payment.billing_details.address.country
            tmp.phone = stepData.payment.billing_details.address.phone
            setTo(tmp)
        }
    }, [stepData.payment])

    const [data, setData] = useState();
    const [totalVat, setTotalVat] = useState();
    const [totalNetto, setTotalNetto] = useState();
    const [totalBrutto, setTotalBrutto] = useState();
    useEffect(() => {
        if (!!stepData.factoring) {
            setData(stepData.factoring)
        }
    }, [stepData.factoring])

    if (isLoading) return <AeroLoading />

    return (
        <Box p={5}>
            <Invoice
                legal={legal}
                from={from}
                to={to}
                data={data}
                preview={true} />
        </Box>
    )
}