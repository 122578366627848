import { Grid, Stack, Slider } from "@mui/material";
import Container from "@mui/material/Container";
import Button from "components/Button";
import Box from "components/Box";
import DefaultAircraftCard from "components/Cards/AircraftCards/DefaultAircraftCard/DefaultAircraftCard";
import AircraftTypeSearch from "components/Input/AircraftTypeSearch";
import AirportSearch from "components/Input/AirportSearch";
import AutoComplete from "components/Input/AutoComplete";
import CertificationSearch from "components/Input/CertificationSearch";
import CountrySearch from "components/Input/CountrySearch";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { restPost, restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { getLanguageTextWithFallBack } from "lib/functions";
import * as React from 'react';
import AircraftClassSearch from "components/Input/AircraftClassSearch";
import Input from "components/Input";

export default function Body() {
    const { t } = useTranslation();

    const [homebase, setHomebase] = useState();
    const [range, setRange] = useState();
    const [airclass, setAirclass] = useState("");
    const [type, setType] = useState();
    const [cert, setCert] = useState({ certification: 'VFR' });
    const [country, setCountry] = useState("");
    const [aircrafts, setAircrafts] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (homebase === null) {
            setRange(null)
        }
    }, [homebase]);

    const marks = [
        {
            value: 0,
            label: "0",
        },
        {
            value: 25,
            label: "25",
        },
        {
            value: 50,
            label: "50",
        },
        {
            value: 75,
            label: "75",
        },
        {
            value: 100,
            label: "100",
        },
    ];

    async function submitSearch() {
        const callback = (response) => {
            if (!!response && !!response.data) {
                setAircrafts(response.data);
            }
            else {
                setAircrafts([])
            }
        }
        setIsLoading(true)
        //await restPost(callback, 'aircraftsearch', { homebase: !!homebase && homebase.icao });
        //await restPost(callback, 'recommendation/_aircraft', { limit: 6 });
        let body = {}
        if (!!homebase) body["homebase"] = homebase.icao;
        if (!!country) body["reg_country"] = country.code;
        if (!!airclass) body["class"] = airclass.description;
        if (!!type) body["type"] = type.name;
        if (!!cert) body["certification"] = cert.certification;
        await restPost(callback, 'aircraft/all/search', body)
        setIsLoading(false)
    }

    return (
        <Box>
            <Box px={16} mt={12}>
                <Grid container spacing={3}
                    alignItems="flex-end">
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.homebase")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <AirportSearch
                                        fullWidth
                                        label={""}
                                        onChange={setHomebase}
                                        value={homebase}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ width: "100%" }} ml={1} mr={1} mb={1}>
                            <Slider
                                disabled={!homebase}
                                aria-label="Restricted Values"
                                defaultValue={0}
                                step={0}
                                valueLabelDisplay="auto"
                                marks={marks}
                                value={range ?? 0}
                                onChange={(e) => setRange(e.target.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.country")}
                                    description={null}
                                />
                                <Box mt={-1}>
                                    <CountrySearch
                                        fullWidth
                                        label={""}
                                        onChange={setCountry}
                                        value={country}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={("Aircraft Model Spec")}
                                    description={null}
                                />
                                <Box mt={-1}>
                                    <AircraftTypeSearch
                                        fullWidth
                                        label={"Search"}
                                        onChange={setAirclass}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.class")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <Input
                                        //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                        label={""}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        options={[]}
                                        onChange={(e) => setAirclass(e.target.value)}
                                        value={airclass.description}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.type")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <Input
                                        //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                        label={""}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        options={[]}
                                        onChange={(e) => setType(e.target.value)}
                                        value={airclass.type}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.certification")}
                                    description={null}
                                />
                                <Box mt={-1}>
                                    <CertificationSearch
                                        fullWidth
                                        label={""}
                                        onChange={setCert}
                                        value={cert}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button onClick={submitSearch} variant="gradient" color="info" fullWidth disabled={isLoading}>
                            {t("common:button.search")}
                        </Button>
                    </Grid>
                </Grid>
            </Box >

            <Box component="section" py={3} id="recommendation">
                <Container>
                    <Grid
                        container
                        item
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={10}
                        lg={5}
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                    </Grid>
                    <Grid container spacing={3} sx={{ mt: 3 }}
                        alignItems="stretch">
                        {isLoading && <AeroLoading />}
                        {!!aircrafts && aircrafts.map((x, i) =>
                            <Grid item xs={12} md={6} lg={4} key={"aircraft-card-" + i} >
                                <Box mt={3} style={{ height: "100%", paddingBottom: "24px" }}>
                                    <DefaultAircraftCard
                                        image={x.image}
                                        title={x.name + " (" + x.reg + ")"}
                                        description={getLanguageTextWithFallBack(x.description)}
                                        categories={[x.homebase, x.certification, x.class, x.type]}
                                        aircraft={x}
                                    />
                                </Box>
                            </Grid>
                        )}
                        {!!aircrafts && aircrafts.length === 0 &&
                            "We did not found any aircrafts for your search parameters."
                        }
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}