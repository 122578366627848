import { Stack, Grid, Divider, FormControl, InputLabel, Select, MenuItem, } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import Input from "components/Input";
import Typography from "components/Typography";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { DataGrid, } from "@mui/x-data-grid";
import _ from "lodash";
import DeleteIcon from '@mui/icons-material/Delete';
import colors from "assets/theme/base/colors";

export default function EquipmentStep({ setData }) {
    const { t } = useTranslation()
    const [engine, setEngine] = useState("");
    const [propeller, setPropeller] = useState("");
    const [fuel, setFuel] = useState("");
    const [weight, setWeight] = useState(0);
    const [seats, setSeats] = useState(1);
    const [fuel_unit, setFuelunit] = useState("ltr");
    const [takeoff_weight_unit, setTakeoffWeightunit] = useState("kg");

    //Avionics Grid
    const [rows, setRows] = useState([]);
    let columns = [
        { field: "type", headerName: "Type", editable: true, width: 150, },
        { field: "model", headerName: "Model", editable: true, width: 150, },
        { field: "spec", headerName: "Spec", editable: true, width: 150, },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => [
                <Button
                    variant="gradient" color="error" iconOnly size="small"
                    onClick={handleDeleteClick(id)}
                ><DeleteIcon /></Button >],
        },
    ];

    const handleDeleteClick = (id) => () => {
        let tmp = rows.filter((row) => row.id !== id)
        tmp = tmp.map((element, index) => { return { ...element, id: index, } })
        setRows(tmp);
    };

    function handleAdd() {
        let tmp = [...rows]
        tmp.push({ "id": tmp.length, "type": "", "model": "" })
        setRows(tmp)
    }

    function handleOnCommit(field, id, value) {
        const index = _.findIndex(rows, function (o) { return o.id == id })
        if (index < 0) return;
        let tmo = _.cloneDeep(rows)
        let element = tmo[index]
        element[field] = value
        setRows(tmo)
    }


    useEffect(() => {
        let timeout = setTimeout(() => {
            var result = {
                engine: engine,
                propeller: propeller,
                fuel_capacity: fuel,
                takeoff_weight: weight,
                seats: seats,
                avionics: rows,
                fuel_unit: fuel_unit,
                takeoff_weight_unit: takeoff_weight_unit,
            }
            setData(result)
        }, 500);

        return () => { clearTimeout(timeout) }
    }, [rows])

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:charter.aircrafts.steps.equipment.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:charter.aircrafts.steps.equipment.description")}
                </Typography>
            </Box>

            <Box mt={2}>
                <Grid container spacing={3}
                    alignItems="flex-end">
                    <Grid item xs={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("common:label.engine")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <Input
                                        sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                        label={""}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        onChange={(e) => setEngine(e.target.value)}
                                        value={engine}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("common:label.propeller")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <Input
                                        sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                        label={""}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        onChange={(e) => setPropeller(e.target.value)}
                                        value={propeller}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("common:label.seats")}
                                description={null}
                            />
                            <Box mt={-1} width="100%">
                                <Input
                                    sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                    label={""}
                                    type="number"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    onChange={(e) => setSeats(e.target.value < 1 ? 1 : e.target.value)}
                                    value={seats}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                    <Grid item xs={12} >
                        <Divider sx={{ opacity: 0.75 }} />
                    </Grid>
                    <Grid item xs={6}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("common:label.fuel_capacity")}
                                description={null}
                            />
                            <Box mt={-1} width="100%" sx={{ display: "flex" }}>
                                <Input
                                    label={""}
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    onChange={(e) => setFuel(e.target.value)}
                                    value={fuel}
                                />
                                <FormControl sx={{ m: 1, minWidth: "50px" }}>
                                    <InputLabel id="select">Unit</InputLabel>
                                    <Select
                                        label="Unit"
                                        onChange={(event) => setFuelunit(event.target.value)}
                                        value={fuel_unit}
                                    >
                                        <MenuItem  value={"ltr"}>{t("common:label.ltr")}</MenuItem>
                                        <MenuItem value={"us.liq.gal"}>{t("common:label.us.liq.gal")}</MenuItem>
                                        <MenuItem value={"imp.gal"}>{t("common:label.imp.gal")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("common:label.takeoff_weight")}
                                description={null}
                            />
                            <Box mt={-1} width="100%" sx={{ display: "flex" }}>
                                <Input
                                    label={""}
                                    type="number"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    onChange={(e) => setWeight((!!e.target.value && e.target.value > 0) ? e.target.value : 0)}
                                    value={weight}
                                />
                                <FormControl sx={{ m: 1, minWidth: "50px" }}>
                                    <InputLabel id="select">Unit</InputLabel>
                                    <Select
                                        label="Unit"
                                        onChange={(event) => setTakeoffWeightunit(event.target.value)}
                                        value={takeoff_weight_unit}
                                    >
                                        <MenuItem value={"kg"}>{t("common:label.kg")}</MenuItem>
                                        <MenuItem  value={"lbs"}>{t("common:label.lbs")}</MenuItem>
                                        
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                    <Grid item xs={12} >
                        <Divider sx={{ opacity: 0.75 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title="Avionics"
                                    description={null}
                                />
                                <DataGrid
                                    sx={{
                                        border: "none",
                                        "& .Mui-selected": {
                                            backgroundColor: colors.badgeColors.info.background + " !important"
                                        },
                                        "& .MuiDataGrid-cell:focus": {
                                            outline: "solid " + colors.badgeColors.info.text + " 1px"
                                        }
                                    }}
                                    autoHeight
                                    rows={rows}
                                    columns={columns}
                                    onCellEditCommit={(e) => handleOnCommit(e.field, e.id, e.value)}
                                />
                                <Button onClick={handleAdd} color="success" variant="gradient" sx={{ marginLeft: "auto" }}>Add</Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
