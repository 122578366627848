import { Collapse, Container, Divider, Grid, Stack } from "@mui/material"
import Box from "components/Box"
import Button from "components/Button"
import DefaultReviewCard from "components/Cards/ReviewCards/DefaultReviewCard"
import Input from "components/Input"
import StarRatingInput from "components/Rating/StarRatingInput"
import Typography from "components/Typography"
import { useUser } from "hooks/userDataHook"
import { restPost } from "lib/rest"
import { restGet } from "lib/rest"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import LoopIcon from '@mui/icons-material/Loop';
import AeroLoading from "components/Progress/AeroLoading"
import SendIcon from '@mui/icons-material/Send';
import { convertTimeDatabaseToFrontend } from "lib/functions"

function AircraftReviewTab({ aircraft }) {
    const { t } = useTranslation()

    /**
     * Formular Data
     */
    const userData = useUser();
    const [user, setUser] = useState();
    const { isSignedIn } = useUser();

    useEffect(() => {
        !!userData.data && !user && setUser(userData.data)
    }, [userData])

    const [open, setOpen] = useState(false)
    const [comment, setComment] = useState("")
    const [starRating, setStarRating] = useState(5)
    const [loading, isLoading] = useState(true)
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        const fetch = async () => {
            setIsLoggedIn(await isSignedIn())
        }
        fetch()
    }, [])

    const submitReview = useCallback(async () => {
        isLoading(true)
        setOpen(false)
        setComment("")
        await restPost(null, `aircraft/${aircraft.reg}/rating`, { rating: starRating, text: comment })
        fetchRatings(0, true);
    }, [aircraft.reg, comment, starRating])

    /**
     * Ratings
     */
    const [ratings, setRatings] = useState([]);
    const [ratingsLoading, setRatingsLoading] = useState(true);
    const [isLast, setIsLast] = useState(false);
    const [count, setCount] = useState(1)
    const limit = 5;

    const fetchRatings = useCallback(async (skip, reset) => {
        const callback = (resp) => {
            if (!!resp && !!resp.data) {
                setRatings(ratings.concat(resp.data))
            } else {
                setIsLast(true)
            }
        }
        const resetCallback = (resp) => {
            if (!!resp && !!resp.data) {
                setRatings(resp.data)
            } else {
                setIsLast(true)
            }
        }
        setRatingsLoading(true)
        await restGet((e) => !!reset ? resetCallback(e) : callback(e), `aircraft/${aircraft.reg}/rating?skip=${skip ?? 0}&limit=${limit}&include=reviewer`)
        setRatingsLoading(false)
        isLoading(false)
    }, [aircraft.reg, ratings])

    useEffect(() => {
        !!aircraft && fetchRatings()
    }, [])

    function loadMore() {
        fetchRatings(count * limit)
        setCount(count + 1)
    }

    return (
        <Box py={6} id="review">
            { }
            <Container>
                {!!isLoggedIn &&
                    <Button
                        color={"info"}
                        onClick={() => setOpen(!open)}
                    >
                        {t("common:button.review")}
                    </Button>
                }
                <Collapse in={open} unmountOnExit>
                    <Box component="section" py={12}>
                        <Container>
                            <Grid container item
                                justifyContent="center"
                                xs={10}
                                lg={7}
                                mx="auto"
                                mb={{ xs: 0, md: 6 }}
                                textAlign="center"
                            >
                                <Typography variant="h3" mb={1}>
                                    {t("common:label.review.title")}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
                                <Box width="100%">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6} width="100%">
                                            <Input
                                                type="text"
                                                value={!!user && user.name}
                                                variant="outlined"
                                                fullWidth
                                                disabled />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <StarRatingInput value={starRating} onChange={(event, value) => setStarRating(value)} />
                                        </Grid>
                                        <Grid item xs={12} width="100%" >
                                            <Input
                                                type="text"
                                                label={t("common:label.review.comment")}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                onChange={(e) => setComment(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item justifyContent="center" xs={12} my={6}>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            onClick={() => submitReview()}
                                            endIcon={<SendIcon />}
                                        >
                                            {t("common:button.submit")}
                                        </Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Container>
                    </Box>
                </Collapse>
                <Stack
                    divider={<Divider flexItem />}
                >
                    {!loading && ratings.map((rating, index) =>
                        !!rating && !!rating.reviewer && !!rating.reviewer[0] && <DefaultReviewCard key={"comment-" + index} date={moment(convertTimeDatabaseToFrontend(rating._created)).format("L")} name={rating.reviewer[0].name} review={rating.text} rating={rating.rating} verified={rating.verified} />
                    )
                    }
                    {
                        loading ? <AeroLoading /> : (ratings.length < 1) && <Typography variant="h3">{t("common:label.review.noreview")}</Typography>
                    }
                </Stack>
                {!isLast &&
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            color={"info"}
                            onClick={() => loadMore()}
                            disabled={isLast || ratingsLoading}
                            startIcon={ratingsLoading ? null : <LoopIcon sx={{ fontWeight: "bold" }} />}
                        >
                            {ratingsLoading ? <AeroLoading /> : t("common:button.load")}
                        </Button>
                    </Grid>
                }
            </Container>
        </Box>
    )
}

export default AircraftReviewTab