import DashboardLayout from "aeromate-dashboard/DashboardLayout";
import { useLocation } from "react-router-dom";
import ShopItemCreator from "./Create";
import ShopItemEditor from "./Edit";

export function ShopItemEdit() {
    return (
        <DashboardLayout>
            <ShopItemEditor />
        </DashboardLayout>
    )
}

export function ShopItemCreate() {
    const { hash } = useLocation();
    return (
        <DashboardLayout>
            <ShopItemCreator type={hash.slice(1)} />
        </DashboardLayout>
    )
}
