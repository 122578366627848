import { useState, useEffect } from "react"
import DataTable from "components/Tables/DataTable";
import AeroLoading from "components/Progress/AeroLoading";
import { useParams } from "react-router-dom";
import { restGet } from "lib/rest";
import { addActions } from "lib/functions/tables";
import { checkrideActions, checkrideColumns } from "../tables/checkrideTable";
import { Card, Grid } from "@mui/material";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";

export default function CheckridesTable() {
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    let { alias } = useParams();
    let { addModal } = useModal();
    const{refresher} = useRefresher();

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = resp.data[0] ?? []
                data = resp.data[0].checkride ?? []
                setTableData({ columns: checkrideColumns, rows: addActions(data, checkrideActions(addModal, data)) })
            }
            setIsLoading(true)
            await restGet(restCallback, `charter/${alias}?include=checkride`)
            setIsLoading(false)
        }
        !!alias && fetch()
    }, [alias, refresher])

    return (
        <Grid item xs={12}>
            <Card>
                {isLoading && <AeroLoading />}
                {!isLoading && <DataTable canSearch={true} table={tableData} />}
            </Card>
        </Grid>)
}
