import { AppBar, FormControlLabel, Radio, RadioGroup, Stack, Tab, Tabs } from "@mui/material";
import Box from "components/Box";
import CustomerSearch from "components/Input/CustomerSearch";
import TypographyBlock from "components/Typography/TypographyBlock";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const charterTypes = [{ value: 10, label: 'w/o Pilot', default: true }, { value: 20, label: 'with Pilot' }, { value: 30, label: 'Staff Rental' }, { value: 40, label: 'Hour Building' }]

export default function CharterTab({ reg, setEventSettings }) {
    const { t } = useTranslation();
    const [customerIdentity, setCustomerIdentity] = useState()
    const [staffIdentity, setStaffIdentity] = useState()
    const [tabValue, setTabValue] = useState(10)
    const [tabSettings, setTabSetter] = useState([]);

    useEffect(() => {
        let eventSettings = { flight_type: "charter" }

        if (!!customerIdentity) eventSettings.identity_id = customerIdentity
        if (!!staffIdentity) eventSettings.staff_identity_id = staffIdentity
        if (!!tabValue) eventSettings.charter_type = tabValue

        setEventSettings(eventSettings)
    }, [tabValue, customerIdentity, staffIdentity]);

    useEffect(() => {
        function restCallback(resp) {
            !!resp && !!resp.data && Tabssetter(resp.data[0].rates);
        }
        restGet(restCallback, 'aircraft/' + reg)
    }, [])

    function Tabssetter(data) {
        if (data) {
            let newArr = [];
            for (let index = 0; index < data.length; index++) {

                if (data[index].rate > 0 || data[index].rate == 0 && data[index].flight_type == "company" || data[index].rate == 0 && data[index].flight_type == "charter_hour_building") {
                    if (data[index].flight_type.startsWith("charter")) {

                        if (data[index].flight_type == "charter_pilot") {
                            newArr.push(charterTypes[1]);
                        }
                        else if (data[index].flight_type == "charter_self") {
                            newArr.push(charterTypes[0]);

                        } else if (data[index].flight_type == "charter_staff") {
                            newArr.push(charterTypes[2]);

                        }
                        else if (data[index].flight_type == "charter_hour_building") {
                            newArr.push(charterTypes[3]);

                        }
                        
                    }

                }

            }
            setTabSetter(newArr);
        }


    }

    return (
        <Stack>
            <TypographyBlock
                dense={true}
                nopadding
                title={t("common:modal.calendar.charter.type")}
            />
            <Box mt={-1}>
                <AppBar position="static">
                    <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
                        {tabSettings.map((item, index) => (<Tab {...item} key={"charter-tab-" + index} />))}
                    </Tabs>
                </AppBar>
            </Box>

            {(tabValue <= 20 || tabValue == 40) &&
                <Box pt={1}>
                    <TypographyBlock
                        dense={true}
                        nopadding
                        title={t("common:modal.calendar.charter.customer")}
                    />
                    <CustomerSearch
                        fullWidth
                        reg={reg}
                        onChange={setCustomerIdentity}
                    />
                </Box>
            }
            {(tabValue >= 20 && tabValue != 40) &&
                <Box pt={1}>
                    <TypographyBlock
                        dense={true}
                        nopadding
                        title={t("common:modal.calendar.charter.staff")}
                    />
                    <CustomerSearch
                        fullWidth
                        reg={reg}
                        onChange={(tabValue === 20) ? setStaffIdentity : setCustomerIdentity}
                        staffOnly
                    />
                </Box>
            }
        </Stack >
    );
}