import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidenav from "components/Sidenav";
import { useMaterialUIController, setMiniSidenav } from "context";
import brand from "constants/brand";
import routes from "constants/routes.dashboard";
import { useUser } from "hooks/userDataHook";
import AeroLoading from "components/Progress/AeroLoading";
import ErrorPageContent from "components/Common/ErrorPageContent";
import DashboardLayout from "./DashboardLayout";

export default function DashboardApp() {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const userData = useUser();

    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        userData.signedInRequired(() => {
            setSignedIn(true);
        });
    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.route} />;
            }

            return null;
        });

    const [userRoutes, setUserRoutes] = useState([]);
    const [routesLoading, setRoutesLoading] = useState(true);
    useEffect(async () => {
        setRoutesLoading(true)
        setUserRoutes(await userData.getDashboardRoutesAsync())
        setRoutesLoading(false)
    }, [userData.getDashboardRoutesAsync])

    return (
        <>
            <Sidenav
                sx={{ display: "inherit!important" }}
                brand={brand.image}
                routes={userRoutes}
                loading={routesLoading}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            />
            {!signedIn &&
                <AeroLoading />
            }
            {signedIn &&
                <Routes>
                    {getRoutes(routes)}
                    <Route path="/my/account/*" element={<Navigate to="/my/account/cockpit" />} />
                    <Route path="/my/*" element={
                        <DashboardLayout>
                            <ErrorPageContent />
                        </DashboardLayout>}
                    />
                </Routes>
            }
        </>
    )
}