import { useState, useEffect, useCallback } from "react"
import DataTable from "components/Tables/DataTable";
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { useParams } from "react-router-dom";
import { addActions } from "lib/functions/tables";
import { voucherActions, voucherColumns } from "../tables/voucherTable";
import { useTranslation } from "react-i18next";
import { Card, Grid } from "@mui/material";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useModal } from "hooks/modalHook";
import _ from "lodash";
import CreateVoucherModal from "components/Modal/CreateVoucherModal/CreateVoucherModal";
import { useRefresher } from "hooks/refreshHook";

export default function Voucher() {
    const { t } = useTranslation();
    let { alias } = useParams();
    let { addModal } = useModal();
    const { refresher } = useRefresher();
    const [isLoading, setIsLoading] = useState(true);

    const [count, setCount] = useState(0)
    const [amount, setAmount] = useState(0)

    const [tableData, setTableData] = useState([])
    const [voucherResponse, setVoucherResponse] = useState()

    const [rows, setRows] = useState([])

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)
            if (!!alias) {
                setTableData([])
                setCount(0)
                setAmount(0)
                await getVoucherApiResult(null)
            }
        }
        fetch()
    }, [alias, refresher])

    const getVoucherApiResult = useCallback(async (lastId) => {
        let params = { alias: alias };
        if (lastId) params.last_id = lastId;
        await restGet(setVoucherResponse, "voucher", params);
    }, [alias])

    const updateDataTable = useCallback((data) => {
        setTableData([...tableData].concat(data));
    }, [tableData])

    useEffect(() => {
        const fetch = async () => {
            if (!!voucherResponse && !!voucherResponse.data) {
                setCount(count + voucherResponse.system.objects_returned)
                setAmount(voucherResponse.system.affected_documents)
                updateDataTable(voucherResponse.data)
                if (!voucherResponse.system.last_page) {
                    await getVoucherApiResult(voucherResponse.system.last_id);
                } else {
                    setIsLoading(false)
                }
            }
        }
        fetch()
    }, [voucherResponse])

    useEffect(() => { !!tableData && setRows(addActions(tableData, voucherActions(addModal, tableData))) }, [tableData])

    function handleClick() {
        addModal(
            <CreateVoucherModal alias={alias} />
        )

    }

    return (
        <Grid item xs={12}>
            <Card>
                <TypographyBlock
                    title={t("dashboard:charter.voucher.title")}
                    description={t("dashboard:charter.voucher.description")}
                    action={handleClick}
                    actionName={t("dashboard:charter.voucher.create")}
                />
                {isLoading ? <AeroLoading customText={t("common:label.loading") + ` (${count}/${amount})`} /> : <DataTable canSearch={true} table={{ columns: voucherColumns, rows: rows }} />}
            </Card>
        </Grid>
    )

}