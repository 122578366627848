import Badge from "components/Badge";
import UserCheckrideModal from "components/Modal/CheckrideModal/UserCheckrideModal";
import EditModal from "components/Modal/EditModal/EditModal";
import i18n from "locals/config";

export const checkrideColumns = [
    {
        Header: i18n.t("common:label.aircraft"),
        accessor: "reg"
    },
    {
        Header: i18n.t("common:label.charter"),
        accessor: "charter"
    },
    {
        Header: i18n.t("common:label.status"),
        accessor: "status",
        Cell: props => (
            <Badge
                color={
                    props.value === "new"
                        ? "warning"
                        : props.value === "approved"
                            ? "success"
                            : "dark"
                }
                badgeContent={props.value}
            />
        )
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
];

export const checkrideActions = (addModal, data) => {
    return [
        {
            displayStatus: ["new", "approved", "revoked"],
            title: i18n.t("common:label.show"),
            action: (index) => {
                addModal(<UserCheckrideModal
                    data={data[index]}
                />)
            }
        }
    ];
} 