import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CollapseRoot from "./CollapseRoot";
import PropTypes from "prop-types";
import Box from "components/Box";

function Accordion({ title, children, color, defaultOpen, dense, ...rest }) {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(defaultOpen)
    }, [])
    return (
        <Stack>
            <CollapseRoot ownerState={{ color }} {...rest}
                sx={dense ? { paddingTop: "4px", paddingBottom: "4px", minHeight: "unset" } : {}}>
                <Box
                    display="flex"
                    alignItems="center"
                    fontSize="1rem"
                    fontWeight="regular"
                    color={color === "light" ? "dark" : "white"}
                >
                    {title}
                </Box>
                <Box onClick={() => setOpen(!open)} width="100%">
                    {open ?
                        <ExpandLess sx={{ marginLeft: "95%" }} color={color === "light" ? "dark" : "white"} />
                        :
                        <ExpandMore sx={{ marginLeft: "95%" }} color={color === "light" ? "dark" : "white"} />
                    }
                </Box>
            </CollapseRoot>
            <Collapse in={open}>
                {children}
            </Collapse>
        </Stack >
    )
}
// Setting default values for the props of Alert
Accordion.defaultProps = {
    color: "light",
    defaultOpen: false,
    dense: false
};

// Typechecking props of the Alert
Accordion.propTypes = {
    title: PropTypes.node,
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    defaultOpen: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default Accordion;