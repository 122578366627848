import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Input from "components/Input";
import Button from "components/Button";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { restGet } from "lib/rest";
import DefaultTimeSlotCard from "components/Cards/TimeSlotCard/defaultTimeSlotCard";
import moment from "moment"
import { Container } from "@mui/material";
import AeroLoading from "components/Progress/AeroLoading";
import { useModal } from "hooks/modalHook";
import VoucherRedeemModal from "components/Modal/VoucherRedeemModal";
import ReactMarkdown from "react-markdown";

const image = "https://media.westeurope.aero-services.net/aeromate/product/shutterstock_1642042111.jpg"

export default function Body({ external, defaultCode }) {
    const { t } = useTranslation()
    const { addModal } = useModal();

    const [isLoading, setIsLoading] = useState(null);
    const [voucherSlots, setVoucherSlots] = useState(null);
    const [voucherCode, setVoucherCode] = useState(defaultCode);
    const [voucherCharter, setVoucherCharter] = useState(null);

   const [errorText, setErrorText] = useState();

    useEffect(() => {
        if (!!defaultCode) {
            getSlots()
        }else{  

            const query = new URLSearchParams(window.location.search);
            if(query.has("voucher_code")){
            setVoucherCode(query.get("voucher_code"));
        }
        }
    }, [defaultCode])

    function getSlots() {
        async function fetchData() {
            setIsLoading(true)
            let data = await restGet(null, "voucher/" + voucherCode + "?include=charter")
            if (!!data && !data.response && data.data) {
                data = data.data[0]
                if (!!data.status) {
                    switch (data.status) {
                        case "paid":
                            setErrorText(null)
                            await restGet(
                                (res) => {
                                    if (!!res && !!res.dara) {
                                        setVoucherSlots(res.data)
                                    }
                                    else {
                                        setErrorText(t("voucherPage:errorMessage.voucherSlotNotAvailable"))
                                    }
                                }, "voucher/" + voucherCode + "/slot")
                            break;
                        case "scheduled":
                        case "redeemed":
                            setErrorText(null)
                            setErrorText(t("voucherPage:errorMessage.voucherAlreadyUsed"))
                            break;
                        default:
                            setErrorText(null)
                            setErrorText(t("voucherPage:errorMessage.voucherCodeUnknown"))
                    }
                    !!data.charter_id && restGet(setVoucherCharter, "charter/" + data.charter_id.$oid + "/Byid")
                }
            }
            else {
                setErrorText(null)
                setErrorText(t("voucherPage:errorMessage.voucherCodeUnknown"))
            }
            setIsLoading(false)
        }
        fetchData()
    }

    function handleSlotClick(index) {
        var slot = voucherSlots[index]
        addModal(<VoucherRedeemModal charter={voucherCharter.data[0]} slot={slot} code={voucherCode} />)
    }

    return (
        <Box py={12} px={{ xs: 3, lg: 0 }}>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={6} ml="auto">
                    <Typography variant="h4" mb={1}>
                        {t("voucherPage:redeemTitle")}
                    </Typography>
                    <Typography variant="body2" color="text" mb={3}>
                        {t("voucherPage:redeemDescription")}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <Input label={t("common:label.code")} fullWidth value={voucherCode || ""} onChange={(e) => setVoucherCode(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button variant="gradient" color="info" sx={{ height: "100%" }} onClick={() => getSlots()}>
                                {t("common:button.redeem")}
                            </Button>
                        </Grid>
                    </Grid>
                    {!!errorText &&
                        <Grid item xs={12}>
                            <Typography variant="overline" color="error">{errorText}</Typography>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} sm={4} position="relative">
                    {!external && <Box
                        component="img"
                        src={image}
                        alt="image"
                        maxWidth="18.75rem"
                        width="100%"
                        borderRadius="lg"
                        shadow="xl"
                        mt={-24}
                        display={{ xs: "none", lg: "block" }}
                    />}
                </Grid>
            </Grid>
            <Box component="section" py={3}>
                <Container>
                    <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
                        <Grid container justifyContent="center" sx={{ textAlign: "center" }} spacing={3}>
                            {isLoading !== null && isLoading &&
                                <AeroLoading />
                            }
                            {isLoading !== null && !isLoading && !voucherSlots && !!voucherCharter &&
                                <Grid item xs={12} sm={6} md={4} lg={3} >
                                    <ReactMarkdown>
                                        {t("voucherPage:noSlots", { email: voucherCharter.data[0].voucher_setting.voucher_email })}
                                    </ReactMarkdown>
                                </Grid>
                            }
                            {!!voucherSlots && Array.isArray(voucherSlots) && voucherSlots.length !== 0 &&
                                voucherSlots.map((x, i) =>
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={"slot-grid-item-" + i}>
                                        <DefaultTimeSlotCard data={x} onClick={() => handleSlotClick(i)} />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>)
}