import { Typography } from "@mui/material";
import DefaultModal from "components/Modal/DefaultModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AutoComplete from "components/Input/AutoComplete";
import PostUserModal from "components/Modal/UserModal/PostUserModal";

const userRole = [
    { role: "viewer" },
    { role: "admin" },
    { role: "staff" }
]

function UserModal({ actionName, operation, data }) {

    const { t } = useTranslation();

    const [activeSelection, setActiveSelection] = useState("");

    const handleChange = (event) => setActiveSelection(event);

    switch (operation) {
        case "assign":
            return (
                <DefaultModal
                    title={t("common:modal.user.title")}
                    confirm={{
                        show: true,
                        text: t("common:button.confirm"),
                        color: "success",
                        modal: <PostUserModal action="assign" reg={data.identity[0]._id.$oid} charter={data.alias} type={activeSelection.role} />
                    }}
                >
                    <Typography variant="subtitle2" justifyContent="center">
                        {t("common:modal.user.assign", { action: actionName })}
                    </Typography>
                    <AutoComplete
                        disableClearable={true}
                        label={t("common:modal.user.choose")}
                        size="medium"
                        options={userRole.map(item => ({
                            label: t("common:modal.user.", item.role),
                            role: item.role
                        }))}
                        onChange={e => handleChange(e)}
                        isOptionEqualToValue={(option, value) => option.role === value.role}
                        getOptionLabel={(value) => value.label}
                    />
                </DefaultModal>
            );
        case "remove":
            return (
                <DefaultModal
                    title={t("common:modal.user.title")}
                    confirm={{
                        show: true,
                        text: t("common:button.confirm"),
                        color: "success",
                        modal: <PostUserModal action="remove" reg={data.identity[0]._id.$oid} charter={data.alias} />
                    }}
                >
                    <Typography variant="subtitle2" justifyContent="center">
                        {t("common:modal.user.remove", { action: actionName })}
                    </Typography>
                </DefaultModal>
            );
        default:
            return "";
    }

}

export default UserModal;