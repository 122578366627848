
import { useState, useEffect } from "react"
import { Card, Grid } from "@mui/material";
import Box from "components/Box";
import TypographyBlock from "components/Typography/TypographyBlock";
import DocumentUpload from "components/Upload/DocumentUpload";
import DataTable from "components/Tables/DataTable";
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { addActions } from "lib/functions/tables";
import { documentActions, documentColumns } from "../tables/documentTable";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";
import { useTranslation } from "react-i18next";
import DocumentCards from "components/Cards/DocumentCard/DocumentCards";
import AddDocument from "../create/AddDocument";

{/* useTranslation fehlt noch*/ }


export default function Documents() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    const [newDocument, setNewDocument] = useState(false)
    const [datas, setDatas] = useState([])
    const [pilot, setPilot] = useState([])
    const [medical, setMedical] = useState([])
    const [radio, setRadio] = useState([])
    const [other, setOthers] = useState([])

    const { addModal } = useModal();

    const { refresher } = useRefresher();



    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp && resp.data.length > 0) {
                    setDatas(resp.data)
                    const pilots = resp.data.filter((element) => element.cur_type == "faa_certificate" || element.cur_type == "easa_licence");
                    if (pilots.length > 0) {
                        setPilot(pilots)
                    }
                    const medical = resp.data.filter((element) => element.cur_type == "easa_medical");
                    if (medical.length > 0) {
                        setMedical(medical)
                    }
                    const radio = resp.data.filter((element) => element.cur_type == "radiotelephony");
                    if (radio.length > 0) {
                        setRadio(radio)
                    }
                    const other = resp.data.filter((element) => element.cur_type == "gtc" || element.cur_type == "privacy" || element.cur_type == "other_private" || element.cur_type == "other_public");
                    if (other.length > 0) {
                        setOthers(other)
                    }

                }
            }
            setIsLoading(true)
            await restGet(restCallback, 'currency')
            setIsLoading(false)
        }
        fetch()
    }, [refresher])

    console.log(datas)
    return (
        <Box my={3}>
            <TypographyBlock
                action={() => setNewDocument(!newDocument)}
                actionName={!newDocument ? t("dashboard:personal.upload.add") : t("dashboard:personal.upload.close")}
            />
            {newDocument ? (
                <Grid item xs={12}>
                    <AddDocument />
                </Grid>
            ) : (
                <Grid pt={3} container spacing={3} alignItems="stretch"  >
                    <Grid item xs={12} sm={6} lg={3} >
                        <Card p={1}>
                            <TypographyBlock
                                title={t("dashboard:personal.upload.pilot_certificate")}
                            />
                            <Box p={3}>
                                {pilot?.map((element, index) => {

                                    return (< DocumentCards id={element._id} type={element.cur_type} issuedate={element.doc_date} expirydate={element.doc_expire}  ></DocumentCards>)
                                }
                                )


                                }
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} >
                        <Card p={1}>
                            <TypographyBlock
                                title={t("dashboard:personal.upload.medical_certificate")}

                            />
                            <Box p={3}>
                                {medical.map((element, index) => {

                                    return (< DocumentCards id={element._id} type={element.cur_type} issuedate={element.doc_date} expirydate={element.doc_expire}   ></DocumentCards>)
                                }
                                )


                                }
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} >
                        <Card p={1}>
                            <TypographyBlock
                                title={t("dashboard:personal.upload.radio")}

                            />
                            <Box p={3}>
                                {radio.map((element, index) => {

                                    return (< DocumentCards id={element._id} type={element.cur_type} issuedate={element.doc_date} expirydate={element.doc_expire} ></DocumentCards>)
                                }
                                )


                                }
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} >
                        <Card p={1}>
                            <TypographyBlock
                                title={t("dashboard:personal.upload.others")}

                            />
                            <Box p={3}>
                                {other.map((element, index) => {

                                    return (< DocumentCards id={element._id} type={element.cur_type} issuedate={element.doc_date} expirydate={element.doc_expire}></DocumentCards>)
                                }
                                )


                                }
                            </Box>
                        </Card>
                    </Grid>
                </Grid >)}
        </Box>
    )
}