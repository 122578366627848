import DefaultModal from "components/Modal/DefaultModal";
import { t } from "i18next";
import PostConfirmationModal from "./PostConfirmationModal";

function ConfirmationModal({ actionName, status, data, customText }) {

    let id;
    let body;

    /**
     * Delete documents
     * 
     * Revoke, reject checkride
     * Confirm booking
     * 
     * Accept join request
     * 
     * Decline join request
     * 
     * Redeem, refund, expire voucher
     */
    if (status === "deleteDocument") {
        id = data.id
    } else if (['revoke', 'reject', 'confirmBooking'].includes(status)) {
        id = data._id.$oid
    } else if (['acceptJoin'].includes(status)) {
        id = data.alias
        body = { identity_id: data.identity[0]._id.$oid }
    } else if (['declineJoin'].includes(status)) {
        id = data.alias
        body = { identity_id: data.identity[0]._id.$oid, status: "rejected" }
    } else if (['redeem', 'refund', 'expire', 'payVoucher', 'cancelVoucher'].includes(status)) {
        id = data.code
    } else {

    }

    return (
        <DefaultModal
            title={t("common:modal.confirm.title")}
            confirm={{
                show: true,
                text: t("common:button.confirm"),
                color: "info",
                modal: <PostConfirmationModal type={status} id={id} body={body} />
            }}
        >
            {customText ? customText : t("common:modal.confirm.description", { action: actionName })}

        </DefaultModal>
    );
}

export default ConfirmationModal;