import Card from "@mui/material/Card";
import Typography from "components/Typography";
import PropTypes from 'prop-types';
import AeroLoading from "components/Progress/AeroLoading";
import { useEffect, useState } from "react";
import DataTable from "components/Tables/DataTable";
import { addActions } from "lib/functions/tables";
import { bookingActions } from "aeromate-dashboard/Personal/Cockpit/tables/bookingTable";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";
import { restGet } from "lib/rest";
import { bookingColumns } from "aeromate-dashboard/Personal/Cockpit/tables/bookingTable";

function RelatedBookingsCard({ studenfileId }) {
    const [isLoading, setIsLoading] = useState();
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    let { addModal } = useModal();
    const { refresher } = useRefresher();

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = resp ??[]
                if (!!resp && !!resp.data) data = resp.data
                setTableData({ columns: bookingColumns, rows: addActions(data, bookingActions(addModal, data)) })
            }
            setIsLoading(true)
            await restGet(restCallback, `booking/${studenfileId}/studentfile`)
            setIsLoading(false)
        }
        fetch()
    }, [refresher])

    return (
        <Card sx={{ height: "100%" }}>
            {isLoading && <AeroLoading />}
            {!isLoading && <DataTable canSearch={true} table={tableData} />}
        </Card>
    );
}

RelatedBookingsCard.propTypes = {
};

export default RelatedBookingsCard;