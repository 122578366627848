import DefaultModal from "components/Modal/DefaultModal";
import AeroLoading from "components/Progress/AeroLoading";

function LoadingModal({ customText }) {

    return (
        <DefaultModal
            title={""}
            closeExplicit={true}
            cancel={{ show: false }}
            confirm={{ show: false }}
        >
            {customText ? <AeroLoading customText={customText} /> : <AeroLoading />}
        </DefaultModal>
    );
}

export default LoadingModal;
