import Features from "./sections/Features";
import Introduction from "./sections/Introduction";
import GettingStarted from "./sections/GettingStarted";

export default function Body(props) {
    return (<div>
        <Introduction {...props} />
        <Features {...props} />
        <GettingStarted {...props} />
    </div>)
}