import Typography from "components/Typography";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

export default function StarRating({ rating, text, color, textcolor, size }) {

    function calcStar(i) {
        return (rating <= 0.25 + i ? "star_outline" : rating > 0.25 + i && rating < 0.75 + i ? "star_half" : "star")
    }

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}>
            <Typography variant="h4" color={color ?? "text"} fontSize={size}>
                <Icon >{calcStar(0)}</Icon>
                <Icon>{calcStar(1)}</Icon>
                <Icon>{calcStar(2)}</Icon>
                <Icon>{calcStar(3)}</Icon>
                <Icon>{calcStar(4)}</Icon>
            </Typography>
            <Typography variant="h6" color={textcolor ?? "text"}>
                {text}
            </Typography>
        </Stack>
    )
}