import SepaDeleteModal from "components/Modal/SepaModal/SepaDeleteModal";
import SepaEditModal from "components/Modal/SepaModal/SepaEditModal";
import i18n from "locals/config";

export const sepaColumns = [
    {
        Header: i18n.t("common:label.name"),
        accessor: "identity[0].name"
    },
    {
        Header: i18n.t("common:label.email"),
        accessor: "identity[0].email"
    },
    {
        Header: i18n.t("dashboard:label.ci"),
        accessor: "ci_display"
    },
    {
        Header: i18n.t("common:label.mr"),
        accessor: "mr_display"
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
]

export const sepaActions = (addModal, data) => {
    return [
        {
            displayStatus: ["*"],
            title: i18n.t("common:button.edit"),
            action: (index) => { addModal(<SepaEditModal data={data[index]} />) }
        },
        {
            displayStatus: ["*"],
            title: i18n.t("common:button.delete"),
            action: (index) => { addModal(<SepaDeleteModal data={data[index]} />) }
        }

    ]
};
