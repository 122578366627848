import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useIsMobile } from "hooks/isMobileHook";
import MobileBody from "./MobileBody";
import { useUser } from "hooks/userDataHook";
import { useParams } from "react-router-dom";
import NoPermission from "components/Common/NoPermission";
import { useEffect, useState } from "react";
import { restGet } from "lib/rest";
import { useTranslation } from "react-i18next";
import AeroLoading from "components/Progress/AeroLoading";

export default function AircraftCalendarPage() {
    const { isAuthorized, isAuthorizedAlias } = useUser();
    const { REG } = useParams();
    const [aircraftCharterAlias, setAircraftCharterAlias] = useState();
    const { t } = useTranslation();
    //const { isMobile } = useIsMobile()
    // if (isMobile) {
    //     return <MobileBody />
    // }

    useEffect(() => {
        function restCallback(resp) {
            if (!!resp && !!resp.data) {
                setAircraftCharterAlias(resp.data[0].charter[0].alias)
            }
        }
        !!REG && restGet(restCallback, 'aircraft/' + REG)
    }, [REG])

    const [hasPermission, setHasPermission] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)
            const regPer = await isAuthorized(REG);
            const rolePer = await isAuthorizedAlias(aircraftCharterAlias);
            setHasPermission(rolePer || regPer)
            setIsLoading(false)
        }
        REG && aircraftCharterAlias && fetch();
    }, [REG, aircraftCharterAlias]);

    return (
        <KitLayout header={<Header />} title={t("routes:calendar.title")}>
            {isLoading && <AeroLoading />}
            {isLoading ? null : (
                hasPermission
                    ?
                    <Body />
                    :
                    <NoPermission />
            )
            }
        </KitLayout>
    )
}
