import Autocomplete from '@mui/material/Autocomplete';
import Input from "components/Input";
import PropTypes from "prop-types";

function AutoComplete({ label, options, onChange, value, variant, ...rest }) {
    return (
        <Autocomplete
            fullWidth
            {...rest}
            value={value}
            options={options}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            renderInput={(params) => (
                <Input
                    {...params}
                    label={label ?? ""}
                    type="text"
                    variant={variant ?? "outlined"}
                    margin="dense"
                    fullWidth
                />
            )}
        />
    );
}
// Setting default values for the props of Progress
AutoComplete.defaultProps = {
    isOptionEqualToValue: (option, value) => option.value === value.value,
};

// Typechecking props for the Progress
AutoComplete.propTypes = {
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
};

export default AutoComplete;