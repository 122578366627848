import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Box from "components/Box";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TypographyBlock from "components/Typography/TypographyBlock";
import DefaultAircraftCard from "components/Cards/AircraftCards/DefaultAircraftCard/DefaultAircraftCard";
import { getLanguageTextWithFallBack } from "lib/functions";
import GeneralTab from "./components/GeneralTab";
import EquipmentTab from "./components/EquipmentTab";
import SettingsTab from "./components/SettingsTab";
import OperationalTab from "./components/OperationalTab";
import ImageTab from "./components/ImageTab";
import { useModal } from "hooks/modalHook";
import LoadingModal from "components/Modal/LoadingModal";
import _, { forEach } from "lodash"
import { restPost } from "lib/rest";
import Button from "components/Button";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useParams } from "react-router-dom";
import Typography from "components/Typography";
import { getBlobImageUrl } from "lib/functions";
import { getUrlFromBlob } from "lib/functions";
import { v4 as uuidv4 } from 'uuid';
import { useRefresher } from "hooks/refreshHook";

export default function AircraftEditForm({ aircraft }) {
    const { t } = useTranslation();
    const { addModal, removeModal } = useModal();
    const { alias } = useParams();
    const { refresh } = useRefresher();


    const [formData, setFormData] = useState({})
    const setData = (data) => {
        setFormData({ ...formData, [tabValue]: data })
    }

    const [previewImage, setPreviewImage] = useState();
    useEffect(() => {
        if (!!formData && !!formData.image) {
            setPreviewImage(formData.image)
        }
    }, [formData])

    useEffect(() => {
        !!aircraft && !!aircraft.image && setPreviewImage(aircraft.image)


    }, [])



    const [tabValue, setTabValue] = useState("general");
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
    }

    const [content, setContent] = useState(null)
    useEffect(() => {
        switch (tabValue) {
            case "general": setContent(<GeneralTab aircraft={aircraft} setData={setData} tabData={formData[tabValue]} />); break;
            case "equipment": setContent(<EquipmentTab aircraft={aircraft} setData={setData} tabData={formData[tabValue]} />); break;
            case "booking": setContent(<SettingsTab aircraft={aircraft} setData={setData} tabData={formData[tabValue]} />); break;
            case "operational": setContent(<OperationalTab aircraft={aircraft} setData={setData} tabData={formData[tabValue]} />); break;
            case "image": setContent(<ImageTab aircraft={aircraft} setData={setData} tabData={formData[tabValue]} />); break;
            default: break;
        }
    }, [tabValue])

    async function handleSave() {
        addModal(<LoadingModal />)
        var reg = aircraft.reg

        if (!_.isEmpty(formData.general)) {
            var aircraftBody = {
                "reg": formData.general.reg,
                "name": formData.general.name,
                "type": formData.general.type.name,
                "homebase": typeof formData.general.homebase === "string" ? formData.general.homebase : formData.general.homebase.icao,
                "fullclass": formData.general.fullclass,
                "certification": formData.general.certification.certification,
                "description": formData.general.description,
                "reg_country": formData.general.reg_country.code,
                "default_lang": "en"
            }
            await restPost(null, `aircraft/${reg}`, aircraftBody)
        }
        if (!_.isEmpty(formData.equipment)) {
            var equipmentBody = {
                "engine": formData.equipment.engine,
                "propeller": formData.equipment.propeller,
                "fuel_capacity": formData.equipment.fuel_capacity,
                "takeoff_weight": formData.equipment.takeoff_weight,
                "seats": formData.equipment.seats,
                "avionics": formData.equipment.avionics,
                "fuel_unit": formData.equipment.fuel_unit,
                "takeoff_weight_unit": formData.equipment.takeoff_weight_unit,


            }
            await restPost(null, `aircraft/${reg}/setting/equipment`, equipmentBody)
        }
        if (!_.isEmpty(formData.booking)) {
            var booking_setting = formData.booking.reduce((a, e) => ({ ...a, [e.name]: e.value }), {})
            await restPost(null, `aircraft/${reg}/setting/booking_setting`, booking_setting)
        }
        if (!_.isEmpty(formData.operational)) {
            var operational_data_setting = formData.operational.reduce((a, e) => ({ ...a, [e.name]: e.value }), {})
            await restPost(null, `aircraft/${reg}/setting/operational_data_setting`, operational_data_setting)
        }

        if (!!formData?.image) {
            if (aircraft?.image) {
                var oldImages = _.values(aircraft.image).map((v) => getBlobImageUrl(v))
                //var imagesToDelete = _.difference(oldImages, formData.image)
                //imagesToDelete = imagesToDelete.map((img) => getUrlFromBlob(img))
                var imagesToDelete = _.difference(oldImages, formData.image.map((v) => v.imageUrl))


                var imagesWithKeys = Object.entries(aircraft.image).map((e) => ({ ...e[1], key: e[0], imageUrl: getBlobImageUrl(e[1]) }))


                var imageKeysToDelete = _.filter(imagesWithKeys, (e) => { return (_.indexOf(imagesToDelete, e.imageUrl) !== -1) })

                //Create Delta to delete Images
                for (let index = 0; index < imageKeysToDelete.length; index++) {
                    await restPost(null, `aircraft/${reg}/delete/${imageKeysToDelete[index].key}`)
                }
            }

            //Upload new added Files (local blob)
            var uploadImages = _.filter(formData.image, (x) => { return _.isString(x) })
            for (let index = 0; index < uploadImages.length; index++) {
                let blob = await fetch(uploadImages[index]).then(r => r.blob()).then(blobFile => new File([blobFile], "croppedImage", { type: "image/png" }))
                await restPost(null, `aircraft/${reg}/image/${uuidv4()}`, blob, null, { "Content-Type": blob.type })
            }

        }

        removeModal()
        refresh()
    }


    return (
        <Box my={3}>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} >
                    <Box
                        mx={1}
                        p={1}
                        display="flex"
                        alignItems="baseline"
                        color="dark"
                        opacity={1}
                        sx={{ cursor: "pointer", userSelect: "none", width: 'fit-content' }}
                        href={`/my/${alias}/aircrafts`}
                        component="a"
                    >
                        <Typography
                            variant="body2"
                            lineHeight={1}
                            color="inherit"
                            sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
                        ><KeyboardBackspaceIcon />
                        </Typography>
                        <Typography
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            color="dark"
                            sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                        >
                            {t("common:button.back")}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TypographyBlock
                        title={t("dashboard:charter.edit_aircraft.preview.title")}
                        description={t("dashboard:charter.edit_aircraft.preview.description")}
                    />
                    <Box pt={3}>
                        <DefaultAircraftCard
                            image={previewImage}
                            title={aircraft.name + " (" + aircraft.reg + ")"}
                            description={getLanguageTextWithFallBack(aircraft.description)}
                            categories={[aircraft?.homebase, aircraft?.certification, aircraft?.fullclass?.description ?? aircraft.class, aircraft?.type]}
                            aircraft={aircraft}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <TypographyBlock
                        title={t("dashboard:charter.edit_aircraft.settings.title")}
                        description={t("dashboard:charter.edit_aircraft.settings.description")}
                        actionName={t("common:button.save")}
                        action={handleSave}
                    />
                    <AppBar position="static">
                        <Tabs value={tabValue} onChange={handleSetTabValue}>
                            <Tab label={t("dashboard:charter.edit_aircraft.tabs.general.tablabel")} value="general" />
                            <Tab label={t("dashboard:charter.edit_aircraft.tabs.equipment.tablabel")} value="equipment" />
                            <Tab label={t("dashboard:charter.edit_aircraft.tabs.booking.tablabel")} value="booking" />
                            <Tab label={t("dashboard:charter.edit_aircraft.tabs.operational.tablabel")} value="operational" />
                            <Tab label={t("dashboard:charter.edit_aircraft.tabs.image.tablabel")} value="image" />
                        </Tabs>
                    </AppBar>
                    <Box pt={3}>
                        <Card>
                            {content}
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}