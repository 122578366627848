import DashboardLayout from "aeromate-dashboard/DashboardLayout";
import Body from "./Body";

export default function Dummy() {
    return (
        <DashboardLayout>
            <Body />
        </DashboardLayout>
    )
}
