import { useState, useEffect, useCallback } from "react"
import DataTable from "components/Tables/DataTable";
import { bookingActions, bookingColumns } from "../tables/bookingTable";
import AeroLoading from "components/Progress/AeroLoading";
import { useParams } from "react-router-dom";
import { restGet } from "lib/rest";
import { addActions } from "lib/functions/tables";
import { Card, Grid } from "@mui/material";
import RangeDatePicker from "components/DatePicker/RangeDatePicker";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";
import _ from "lodash";
import { convertFrontendToTimeDatabase } from "lib/functions";
import { addMultiAction } from "lib/functions/tables";
import MultiSelectBookingsModal from "components/Modal/MultiSelectBookingsModal";
import ButtonDropDown from "components/Button/ButtonDropDown";
import { useTranslation } from "react-i18next";
import { restPut } from "lib/rest";

export default function Bookings() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    let { alias } = useParams();
    let { addModal } = useModal();
    const { refresher } = useRefresher();

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp && !!resp.data) data = resp.data
                data = _.filter(data, (entry) => { return !_.isEmpty(entry.identity_id) })
                setTableData({ columns: bookingColumns, rows: addActions(data, bookingActions(addModal, data)) })
            }
            setIsLoading(true)
            await restGet(restCallback, `booking/_all?include=aircraft,identity`,
                {
                    'alias': alias,
                    'ts_start': convertFrontendToTimeDatabase(start),
                    'ts_end': convertFrontendToTimeDatabase(end)
                }
            )
            setIsLoading(false)
        }
        const timer = setTimeout(() => {
            !!alias && !!start && !!end && fetch()
        }, 1000)

        return () => clearTimeout(timer)
    }, [alias, start, end, refresher])

    const [selected, setSelected] = useState([]);
    const [mulitActions, setMulitActions] = useState();
    useEffect(() => {
        var data = selected.map(x => tableData.rows[x]);
        data = data.map(x => {
            if (["confirmed", "waiting", "pending"].includes(x.status))
                return { ...x, status: "confirmed" }
            else if (["pending", "waiting"].includes(x.status))
                return { ...x, status: "pending" }
            else return x
        }
        )
        data = _.groupBy(data, "status")
        const actions = []; 
        if (data.active) {
            //NO Multiselect Action for Log Times
        }
        if (data.confirmed)
            actions.push({
                title: t("common:button.cancel") + "(" + data?.confirmed?.length + ")",
                action: () => addModal(<MultiSelectBookingsModal bookings={data.confirmed} actionName={t("common:button.cancel")} action={async (bookingId) => await restPut(null, `booking/${bookingId}/status/canceled/`)} />)
            })
        if (data.pending)
            actions.push({
                title: t("common:button.confirm") + "(" + data.pending.length + ")",
                action: () => addModal(<MultiSelectBookingsModal bookings={data.pending} actionName={t("common:button.confirm")} action={async (bookingId) => await restPut(null, `booking/${bookingId}/status/confirmed/`)} />)
            })
        actions.length > 0 && setMulitActions(<ButtonDropDown color="primary" options={actions} />)
    }, [selected]);

    return (
        <>
            <Grid item xs={12} >
                <Card sx={{ padding: "16px" }}><RangeDatePicker setStart={setStart} setEnd={setEnd} /></Card>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "24px" }}>
                <Card>
                    {isLoading && <AeroLoading />}
                    {!isLoading && <DataTable isSelectable={true} selectedAction={mulitActions} canSearch={true} table={tableData} getSelected={setSelected} />}
                </Card>
            </Grid>
        </>)
}