import { Card, Container, Fab, Grid, Stack, Tab, Tabs } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import brand from "constants/brand";
import { useTranslation } from 'react-i18next';


// import required modules
import TabPanel from "../components/TabPanel";
import { useState } from "react";

function Partners(props) {
    const { pageContent } = props;
    const [currentTab, setCurrentTab] = useState("0")
    const { t } = useTranslation();


    return (
        <Box component="section" py={{ xs: 3, md: 12 }} id="partners">
            <Container>
                <Box mb={5}>
                    <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
                        {
                            t("partnerPage:partner", { returnObjects: true }).map((element, index) => <Tab
                                key={"partner-panel-" + index}
                                label={
                                    <Box
                                        sx={{ margin: "auto" }}
                                        component="img"
                                        src={brand.static_cdn + element.logo}
                                        width="50% !important"
                                        position="relative"
                                        zIndex={1}
                                        p={4}
                                    />
                                }
                                value={index + ""}
                            />
                            )
                        }
                    </Tabs>
                </Box>
                <Card>
                    <Container>
                        <Box m={2} sx={{ float: "right", width: "100px" }}>
                            <Fab
                                size="small"
                                color="info"
                                onClick={() => setCurrentTab((Number(currentTab) - 1) + "")}
                                disabled={currentTab === "0"}
                            >
                                <ArrowBackIosNewIcon />
                            </Fab>
                            <Fab
                                size="small"
                                color="info"
                                onClick={() => setCurrentTab((Number(currentTab) + 1) + "")}
                                disabled={currentTab === (pageContent.length - 1) + ""}
                                sx={{ marginLeft: "8px" }}
                            >
                                <ArrowForwardIosIcon />
                            </Fab>
                        </Box>
                        <Grid>
                            {
                                t("partnerPage:partner", { returnObjects: true }).map((element, index) =>
                                    <TabPanel key={"partner-panel-" + index} index={index + ""} value={currentTab}>
                                        <Grid container justifyContent="space-between" alignItems="flex-start" >
                                            <Grid item xs={6} height="400px">
                                                <Typography pl={5} variant="h5" >
                                                    {t("partnerPage:partner_title")}
                                                </Typography>
                                                <Typography pl={5} variant="h4">
                                                    &nbsp;
                                                </Typography>
                                                <img
                                                    style={{ width: "50%" }}
                                                    src={brand.static_cdn + element.logo}
                                                />

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack pl={5} spacing={3}>
                                                    <Typography variant="h5">
                                                        {element.purpose ?? ""}
                                                    </Typography>
                                                    <Typography variant="h2">
                                                        {element.title}
                                                    </Typography>
                                                    <Typography variant="body2" color="text">
                                                        {element.description ?? ""}
                                                    </Typography>
                                                    {!!element.link &&
                                                        <Button
                                                            variant="gradient"
                                                            onClick={() => { window.open(element.link, '_blank') }}
                                                            color="info"
                                                            sx={{ width: "200px" }}
                                                        >
                                                            {t("partnerPage:more_button")}
                                                        </Button>
                                                    }
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                )
                            }
                        </Grid>
                    </Container>
                </Card>
            </Container>
        </Box>
    );
}

export default Partners