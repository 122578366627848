import DashboardLayout from "aeromate-dashboard/DashboardLayout";
import Body from "./Body";
import CharterBody from "./CharterBody";

export default function MyProfilePage() {
    return (
        <DashboardLayout>
            <Body />
        </DashboardLayout>
    )
}

export function MyCharterProfilePage() {
    return (
        <DashboardLayout>
            <CharterBody />
        </DashboardLayout>
    )
}
