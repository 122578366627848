import { useState } from "react";
import Grid from "@mui/material/Grid";
import FaqCollapse from "../components/FaqCollapse";
import ReactMarkdown from "react-markdown";

export default function FaqSection({ faq }) {
    const [collapse, setCollapse] = useState(false);

    return (
        <Grid container justifyContent="center">
            {faq.map((element, index) => (
                <Grid item xs={12} md={10}>
                    <FaqCollapse
                        title={element.q}
                        open={collapse === (index + 1)}
                        onClick={() => (collapse === (index + 1) ? setCollapse(false) : setCollapse((index + 1)))}
                    >
                        <ReactMarkdown>{element.a}</ReactMarkdown>
                    </FaqCollapse>
                </Grid>
            ))}
        </Grid>
    );
}
