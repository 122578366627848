import { useState, useEffect } from "react"
import DataTable from "components/Tables/DataTable";
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { useParams } from "react-router-dom";
import { addActions } from "lib/functions/tables";
import { invoiceActions, invoiceColumns } from "../tables/invoiceTable";
import { Card, Grid, Stack } from "@mui/material";
import DatePicker from "components/DatePicker";
import { useModal } from "hooks/modalHook";

import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
import "flatpickr/dist/plugins/monthSelect/style.css"
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { convertFrontendToTimeDatabase } from "lib/functions";
import { getBlobFromPdfString } from "lib/functions";
import moment from "moment";
import TypographyBlock from "components/Typography/TypographyBlock";

export default function Invoices() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    const [date, setDate] = useState(moment().subtract(1, "month").valueOf());
    let { alias } = useParams();
    let { addModal } = useModal();
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp && !!resp.data) data = resp.data
                data = data.map((element) => {
                    return { ...element, status: element.billing_type };
                })
                setTableData({ columns: invoiceColumns, rows: addActions(data, invoiceActions(addModal, data)) })
            }
            setIsLoading(true)
            restGet(restCallback, 'charter/' + alias + '/invoices');
            setIsLoading(false)
        }
        !!alias && fetch()
    }, [alias])

    return (
        <>
            <Grid item xs={12} >
                <Card sx={{ padding: "16px" }}>
                    <TypographyBlock
                        title={t("dashboard:charter.invoices.title")}
                        description={t("dashboard:charter.invoices.description")}
                    />
                    <Stack direction="row" spacing={3} pl={3}>
                        <DatePicker
                            value={date}
                            onChange={(event) =>
                                setDate(moment()
                                    .utc()
                                    .seconds(0)
                                    .minutes(0)
                                    .hours(12)
                                    .day(0)
                                    .month(event[0].getMonth())
                                    .year(event[0].getFullYear())
                                    .valueOf())
                            }
                            enableTime={true}
                            options={{
                                maxDate: "today",
                                plugins: [
                                    new monthSelectPlugin({})
                                ]
                            }}
                        />
                        <Button disabled={!date} onClick={() => restGet((res) => window.open(URL.createObjectURL(getBlobFromPdfString(res))), `billing/${alias}/charter/${convertFrontendToTimeDatabase(date)}/date`)} color="info">{t('common:label.download')}</Button>
                    </Stack>
                </Card>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "24px" }}>
                <Card>
                    {isLoading && <AeroLoading />}
                    {!isLoading && <DataTable canSearch={true} table={tableData} />}
                </Card>
            </Grid>
        </>
    )
}