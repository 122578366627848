import Grid from "@mui/material/Grid";
import Box from "components/Box";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AeroLoading from "components/Progress/AeroLoading"
import DashboardAircraftCard from "components/Cards/AircraftCards/DefaultAircraftCard/DashboardAircraftCard";
import { useTranslation } from "react-i18next";
import TypographyBlock from "components/Typography/TypographyBlock";
import AircraftCreateForm from "./AircraftCreateForm/AircraftCreateForm";
import AircraftEditForm from "./AircraftEditForm/AircraftEditForm";
import { useRefresher } from "hooks/refreshHook";

export default function BodyEdit() {
    const { t } = useTranslation();
    const [aircraft, setAircraft] = useState()
    const [isloading, setIsLoading] = useState(true)
    const { reg } = useParams();
    const { refresher } = useRefresher();

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && !!resp.data[0] && setAircraft(resp.data[0])
            }
            setIsLoading(true)
            await restGet(restCallback, 'aircraft/' + reg)
            setIsLoading(false)
        }
        !!reg && fetch()
    }, [reg ,refresher])

    return (
        <Box my={3}>
            {isloading && <AeroLoading />}
            {!isloading && <AircraftEditForm aircraft={aircraft} />}
        </Box>
    )
}