import DynamicFormInput from "./DynamicFormInput";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import TypographyBlock from "components/Typography/TypographyBlock";
import Box from "components/Box";
import { useTranslation } from "react-i18next";

function DynamicForm({ formMetaData, formDefaultValues, formValuesCallback, dense, i18nextPrefix }) {
    const { t } = useTranslation()
    const [formValues, setFormValues] = useState([])
    useEffect(() => {
        if (formMetaData) {
            setFormValues(
                formMetaData.map((metaData) => {
                    if (!!formDefaultValues) {
                        switch (metaData.type) {
                            case "country":
                                return { ...metaData, value: metaData.value ?? { name: formDefaultValues[metaData.name] } ?? metaData.default }
                            default:
                                return { ...metaData, value: metaData.value ?? formDefaultValues[metaData.name] ?? metaData.default }
                        }
                    }
                    return { ...metaData, value: metaData.value ?? metaData.default }
                })
            )
        }
    }, [formMetaData, formDefaultValues])

    function handleChange(index, value, type) {
        let tmp = [...formValues]
        switch (type) {
            case "bool":
                tmp[index].value = Boolean(value)
                break;
            case "int":
                tmp[index].value = Number(value)
                break;
            case "country":
                tmp[index].value = value
                break;
            case "airport":
                tmp[index].value = value
                break;
            case "list":
                tmp[index].value = value
                break;
            default:
                tmp[index].value = value
                break;
        }
        tmp[index].changed = true
        setFormValues(tmp)
        formValuesCallback(tmp)
    }

    return (
        <div>
            <Grid container >
                {formValues.map((inputMetaData, i) => (
                    <Grid item xs={inputMetaData.size ?? 12} key={"Grid-container-" + i}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack
                                direction={inputMetaData.type === "bool" ? "row" : "column"}
                                justifyContent={inputMetaData.type === "bool" ? "space-between" : "flex-start"}
                            >
                                <TypographyBlock
                                    dense={dense}
                                    nopadding
                                    title={t("dynamicForms:" + (!!i18nextPrefix ? i18nextPrefix + "." : "") + inputMetaData.name + ".title")}
                                    description={!!inputMetaData.details && t(inputMetaData.details)}
                                />
                                <Box mt={-1}>
                                    <DynamicFormInput
                                        inputMetaData={inputMetaData}
                                        dataCallback={(value, type) => handleChange(i, value, type)}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

DynamicForm.propTypes = {
    classes: PropTypes.object,
    formMetaData: PropTypes.array,
    formDefaultValues: PropTypes.object,
    formValues: PropTypes.func
};

export default DynamicForm