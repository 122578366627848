import brand from 'constants/brand';
import { useRef, useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = brand.name_caps + " " + title;
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = brand.name_caps;
        }
    }, [])
}
export function DocumentTitle(props) {
    if (!!props.title) useDocumentTitle(props.title)
    return <></>
}

export default useDocumentTitle