import { Card, Grid, Icon, Typography } from "@mui/material";
import Button from "components/Button";
import AeroLoading from "components/Progress/AeroLoading";
import DataTable from "components/Tables/DataTable";
import { restGet } from "lib/rest";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"

export default function Body() {
    function toDateTime(secs) {
        var t = moment(secs * 1000).format('L');
        return t;
    }

    function toDateTime_ms(ms) {
        var t = moment(ms).format('L');
        return t;
    }

    function toHourTime(secs) {
        var t = moment(secs * 1000).format('LT');
        return t;
    }

    function operating(aldt, atot) {
        var t = aldt - atot;
        var s = moment(t * 1000 - 3600000).format("LT");
        return s;
    }

    const { t } = useTranslation();
    const { reg } = useParams();
    const [teclogs, setTeclogs] = useState();
    const [isLoading, setIsLoading] = useState(true)

    useEffect(async () => {
        //restGet liest Daten aus Backend
        //setTeclogs speichert Daten in lokaler Variable "teclogs"
        await restGet(setTeclogs, "teclog/" + reg)
        setIsLoading(false)
    }, [])

    if (isLoading) {
        return <AeroLoading />
    }

    if (!teclogs) {
        return <Typography>
            {t("common:label.no_data")}
        </Typography>
    }

    let columns = [
        { Header: t("common:label.dof"), accessor: "date", align: "left", marginLeft: "16px" },
        { Header: t("common:label.name"), accessor: "name", align: "left" },
        { Header: t("common:label.crew"), accessor: "crewMembers", align: "left" },
        { Header: t("common:label.passengers"), accessor: "passengers", align: "left" },
        { Header: t("common:label.origin"), accessor: "origin", align: "left" },
        { Header: t("common:label.destination"), accessor: "destination", align: "left" },
        { Header: t("common:label.start_time"), accessor: "takeOff", align: "left" },
        { Header: t("common:label.landing_time"), accessor: "landing", align: "left" },
        { Header: t("common:label.landings"), accessor: "landingCount", align: "left" },
        { Header: t("common:label.operating_time"), accessor: "operating", align: "left" },
        { Header: t("common:label.malfunctions"), accessor: "malfunctions", align: "left" },
        { Header: t("common:label.maintenance"), accessor: "maintenance", align: "left" },
        {
            Header: t("common:label.actions"),
            accessor: "action",
            align: "left",
            Cell: props => (
                <Button
                    size="small"
                    variant="gradient"
                    color="info"
                    iconOnly
                    href={"/my/logtimes/" + props.value.$oid}
                >
                    <Icon>edit</Icon>
                </Button>
            )
        },
    ]

    let rows = !!teclogs && !!teclogs.data && teclogs.data.map((d, i) => {
        return {
            "date": !!d?.flight_data && !!d?.flight_data?.dof && d?.flight_data?.dof < 1000000000000 ? toDateTime(d?.flight_data?.dof) : toDateTime_ms(d?.flight_data?.dof),
            "name": !!d.pob_data && !!d.pob_data[0] && _.filter(d.pob_data, function (o) { return o.duty !== "PAX"; }).map((person, index) => (person.name + " ")),
            "crewMembers": !!d.pob_data && !!d.pob_data[0] && _.filter(d.pob_data, function (o) { return o.duty !== "PAX"; }).length,
            "passengers": !!d.pob_data && !!d.pob_data[0] && _.filter(d.pob_data, function (o) { return o.duty === "PAX"; }).length,
            "origin": !!d?.flight_data && !!d?.flight_data?.orig && (_.isObject(d?.flight_data?.orig) ? d?.flight_data?.orig.icao : d?.flight_data?.orig),
            "destination": !!d?.flight_data && !!d?.flight_data?.dest && (_.isObject(d?.flight_data?.dest) ? d?.flight_data?.dest.icao : d?.flight_data?.dest),
            "takeOff": !!d?.flight_data && !!d?.flight_data?.atot && toHourTime(d?.flight_data?.atot),
            "landing": !!d?.flight_data && !!d?.flight_data?.aldt && toHourTime(d?.flight_data?.aldt),
            "landingCount": !!d?.flight_data && !!d?.flight_data?.ldg && d?.flight_data?.ldg,
            "operating": !!d?.flight_data && !!d?.flight_data?.aldt && !!d?.flight_data?.atot && operating(d?.flight_data?.aldt, d?.flight_data?.atot),
            "malfunctions": "-",
            "maintenance": !!d.fuel_data && !!d.fuel_data.entries && _.isArray(d.fuel_data.entries) && d.fuel_data.entries.map((fuel, i) => (fuel.fuel + "l")),
            "action": !!d.booking_id && d.booking_id
        }
    })

    return (
        <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Card>
                <DataTable table={{ columns: columns, rows: rows }} isSorted={false} />
            </Card>
        </Grid>
    )
}
