import React, { useEffect, useState } from "react";
import Button from "./index";
import Auth from "lib/auth"
import { useUser } from "hooks/userDataHook";
import { useTranslation } from 'react-i18next';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Typography from "components/Typography";
import Box from "components/Box";

export const AuthButton = () => {
    const [authButton, setAuthButton] = useState();
    const { isSignedIn } = useUser()
    const { t } = useTranslation();

    useEffect(() => {
        const logginStatus = async () => {
            var isLoggedIn = await isSignedIn()
            setAuthButton(isLoggedIn ?
                <Box
                    mx={1}
                    p={1}
                    display="flex"
                    alignItems="baseline"
                    color="error"
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    onClick={() => Auth.logout()}
                >
                    <Typography
                        variant="body2"
                        lineHeight={1}
                        color="error"
                        sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
                    >
                        <ExitToAppIcon />
                    </Typography>
                    <Typography
                        variant="button"
                        color="error"
                        fontWeight="bold"
                        sx={{ ml: 1, mr: 0.25 }}
                    >
                        {t("common:button.logout")}
                    </Typography>
                </Box>
                :
                <Button
                    variant="gradient" color="info" size="small"
                    onClick={() => Auth.login()}
                >
                    {t("common:button.login")}
                </Button>)
        }
        logginStatus()
    }, [])

    return <>
        {authButton}
    </>
}