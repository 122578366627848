import Box from "components/Box";
import Typography from "components/Typography";
import { Button, Card, Stack } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { useTranslation } from 'react-i18next';

function InfoCard({ image, title, description, direction, url }) {
  const { t } = useTranslation();

  return (
    <Card
      lineheight={1}
      p={direction === "center" ? 2 : 0}
      textalign={direction}
      action={{
        type: "internal",
        color: "info",
        label: "Mehr erfahren",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        height="450px"
      >
        <Box pt={3} sx={{ width: "100%", backgroundColor: "white", borderRadius: "16px 16px 0px 0px" }}>
          <Box
            sx={{ margin: "auto" }}
            component="img"
            src={image}
            maxWidth="300px"
            position="relative"
            zIndex={1}
            pt={2}
            pb={2}
            display="flex"
          />
        </Box>

        <Typography pl={5} pr={5} variant="h5">{title}</Typography>
        <Typography pl={5} pr={5} variant="button" color="text">
          {description}&nbsp;
          <a href={url} target="_blank">
            {t("partnerPage:more_button")}
            <ArrowUpwardIcon
              sx={{ transform: "rotate(45deg)" }}
            />
          </a>
        </Typography>

      </Stack>
    </Card>
  );
}

export default InfoCard;