
import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import Restricted from "components/Common/Restricted";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Legal from "./sections/Legal";
import Member from "./sections/Member";
import General from "./sections/General";

export default function Body() {
    const { t } = useTranslation();
    const { hash } = useLocation();
    const getTab = () => {
        switch (hash) {
            case "#member": return (0)
            case "#legal": return (1)
            case "#general": return (2)
            default: return (0)
        }
    }
    const [tabValue, setTabValue] = useState(getTab());
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = "#" + event.target.id
    }
    const [content, setContent] = useState(null)
    useEffect(() => {
        switch (tabValue) {
            case 0:
                setContent(<Member />)
                break;
            case 1:
                setContent(<Legal />)
                break;
            case 2:
                setContent(<General />)
                break;
            default:
                break;
        }
    }, [tabValue])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <AppBar position="static">
                    <Restricted role="staff" explicit>
                        <Tabs value={tabValue} onChange={handleSetTabValue}>
                            <Tab label={t("dashboard:charter.settings.member")} id="member" />
                        </Tabs>
                    </Restricted>
                    <Restricted role="admin" explicit>
                        <Tabs value={tabValue} onChange={handleSetTabValue}>
                            <Tab label={t("dashboard:charter.settings.member")} id="member" />
                            <Tab label={t("dashboard:charter.settings.general")} id="general" />
                        </Tabs>
                    </Restricted>
                    <Restricted role="owner" explicit>
                        <Tabs value={tabValue} onChange={handleSetTabValue}>
                            <Tab label={t("dashboard:charter.settings.member")} id="member" />
                            <Tab label={t("dashboard:charter.settings.legal")} id="legal" />
                            <Tab label={t("dashboard:charter.settings.general")} id="general" />
                        </Tabs>
                    </Restricted>
                </AppBar>
            </Grid>
            <Grid item container xs={12} >
                {content}
            </Grid>
        </Grid>
    )
}