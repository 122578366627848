import Box from "components/Box";
import AeroLoading from "components/Progress/AeroLoading";
import Typography from "components/Typography";
import { delay } from "lib/functions";
import { restPost } from "lib/rest";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

async function getCroppedImg(imageSrc, pixelCrop) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
    })
}


export default function CreateStep({ data, nextStep, setData }) {
    const { t } = useTranslation()
    const [status, setStatus] = useState("");
    const { alias } = useParams();

    useEffect(async () => {
        var reg = data.required.reg

        var imageData = { ...data.image.imageData }
        var aircraftBody = {
            "reg": data.required.reg,
            "name": data.required.name,
            "type": data.required.class.type,
            "homebase": data.required.homebase.icao,
            "class": data.required.class.description,
            "fullclass": data.required.class,
            "certification": data.required.certification.certification,
            "description": { ...data.image.description },
            "reg_country": data.required.reg_country.code,
            "default_lang": "en"
        }
        var booking_setting = {}
        data.settings.booking_setting.forEach((e) => (booking_setting[e.name] = e.value ?? e.default))
        var operational_data_setting = {}
        data.settings.operational_data_setting.forEach((e) => (operational_data_setting[e.name] = e.value ?? e.default))

        setStatus("Creating aircraft")
        let aircraft = await restPost(null, `charter/${alias}/aircraft`, aircraftBody)

        if (!!aircraft) {
            setStatus("Cropping Image")
            /*  let previewImage = await getCroppedImg(
                 imageData.aircraftImage,
                 imageData.croppedAreaPixels
             ) */
            setStatus("Uploading Image")
            if (_.isObject(imageData))
                imageData = Object.values(imageData)
            for (let index = 0; index < imageData.length; index++) {
                let blob = await fetch(imageData[index]).then(r => r.blob()).then(blobFile => new File([blobFile], "croppedImage", { type: "image/png" }))
                await restPost(null, `aircraft/${reg}/image/${uuidv4()}`, blob, null, { "Content-Type": blob.type })
            }
            setStatus("Saving booking settings")
            await restPost(null, `aircraft/${reg}/setting/booking_setting`, booking_setting)
            setStatus("Saving operational settings")
            await restPost(null, `aircraft/${reg}/setting/operational_data_setting`, operational_data_setting)
            setStatus("Saving avionics")
            if (!_.isEmpty(data.equipment)) {
                var equipmentBody = {
                    "engine": data.equipment.engine,
                    "propeller": data.equipment.propeller,
                    "fuel_capacity": data.equipment.fuel_capacity,
                    "takeoff_weight": data.equipment.takeoff_weight,
                    "seats": data.equipment.seats,
                    "avionics": data.equipment.avionics,
                    "fuel_unit": data.equipment.fuel_unit,
                    "takeoff_weight_unit": data.equipment.takeoff_weight_unit,
                }
                await restPost(null, `aircraft/${reg}/setting/equipment`, equipmentBody)
            }
            setStatus("Finished")
            await delay(1000);
            setData({ alias, reg })
            nextStep();
        }
    }, [])

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:charter.aircrafts.steps.creating.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:charter.aircrafts.steps.creating.description")}
                </Typography>
            </Box>

            <Box mt={2} width="82%" textAlign="center" mx="auto" my={4}>
                <AeroLoading customText={status} />
            </Box>
        </Box>
    )
}
