import Badge from "components/Badge";
import DownloadModal from "components/Modal/DownloadModal";
import EditModal from "components/Modal/EditModal/EditModal";
import ConfirmationModal from "components/Modal/ConfirmationModal/ConfirmationModal";
import i18n from "locals/config";
import CharterCheckrideModal from "components/Modal/CheckrideModal/CharterCheckrideModal";

export const checkrideColumns = [
    {
        Header: i18n.t("common:label.aircraft"),
        accessor: "reg"
    },
    {
        Header: i18n.t("common:label.name"),
        accessor: "requested.name"
    },
    {
        Header: i18n.t("common:label.email"),
        accessor: "requested.email"
    },
    {
        Header: i18n.t("common:label.status"),
        accessor: "status",
        Cell: props => (
            <Badge
                color={
                    props.value === "new"
                        ? "warning"
                        : props.value === "approved"
                            ? "success"
                            : "dark"
                }
                badgeContent={props.value}
            />
        )
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
];

export const checkrideActions = (addModal, data) => {
    return [
        {
            displayStatus: ["new"],
            title: i18n.t("common:button.edit"),
            action: (index) => {
                addModal(<CharterCheckrideModal
                    data={data[index]}
                />)
            }
        },
        {
            displayStatus: ["new"],
            title: i18n.t("common:button.reject"),
            action: (index) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.reject")}
                    status="reject"
                    data={data[index]}
                />)
            }
        },
        {
            displayStatus: ["approved"],
            title: i18n.t("common:button.revoke"),
            action: (index) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.revoke")}
                    status="revoke"
                    data={data[index]}
                />)
            }
        },
        {
            displayStatus: ["new", "approved"],
            title: i18n.t("common:button.print"),
            action: (index) => {
                addModal(<DownloadModal
                    data={data[index]}
                    type="checkride"
                />)
            }
        }
    ]
};