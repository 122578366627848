import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTimeline } from "../context";
import timelineItem from "./styles";
import { Card, Checkbox, Divider, FormControlLabel, Grid, InputAdornment, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DatePicker from "components/DatePicker";
import AirportSearch from "components/Input/AirportSearch";
import Input from "components/Input";
import Button from "components/Button";
import moment from "moment";
import { minutesToMilliseconds } from "lib/time";


function TimelineJourneyItem({ color, icon, iconStyle, title, value, lastItem, firstItem, addAction, removeAction, onChange }) {
    const { t } = useTranslation();
    const isDark = useTimeline();

    const [orig, setOrig] = useState(value.orig ?? undefined)
    const [dest, setDest] = useState(value.dest ?? undefined)

    const [ldg, setLdg] = useState(value.ldg ?? 1)
    const [feePaid, setFeePaid] = useState(value.landing_fee_payed ?? false)

    const [advancedSettings, setAdvancedSettings] = useState(false)

    const [dof, setDof] = useState(value.dof ?? undefined)
    const [aobt, setAobt] = useState(value.aobt ?? undefined)
    const [atot, setAtot] = useState(value.atot ?? undefined)
    const [aldt, setAldt] = useState(value.aldt ?? undefined)
    const [aibt, setAibt] = useState(value.aibt ?? undefined)
    const [actualAobt, setActualAobt] = useState()
    const [actualAtot, setActualAtot] = useState()
    const [actualAldt, setActualAldt] = useState()
    const [actualAibt, setActualAibt] = useState()

    useEffect(() => {
        let currentAOBT = aobt ?? dof;
        let currentATOT = atot ?? dof;
        let currentALDT = aldt ?? dof;
        let currentAIBT = aibt ?? dof;
        if (!advancedSettings) {
            let utcAOBT = moment.utc(currentAOBT)
            let utcATOT = moment.utc(currentATOT)
            let utcALDT = moment.utc(currentALDT)
            let utcAIBT = moment.utc(currentAIBT)
            !!aobt && (currentAOBT = moment.utc(dof).minute(utcAOBT.minute()).hour(utcAOBT.hour()).valueOf())
            !!atot && (currentATOT = moment.utc(dof).minute(utcATOT.minute()).hour(utcATOT.hour()).valueOf())
            !!aldt && (currentALDT = moment.utc(dof).minute(utcALDT.minute()).hour(utcALDT.hour()).valueOf())
            !!aibt && (currentAIBT = moment.utc(dof).minute(utcAIBT.minute()).hour(utcAIBT.hour()).valueOf())
            if (currentAOBT > currentATOT) { currentAOBT = moment.utc(currentAOBT).subtract(1, "d").valueOf() }
            if (currentALDT < currentATOT) {
                currentALDT = moment.utc(currentALDT).add(1, "d").valueOf()
                currentAIBT = moment.utc(currentAIBT).add(1, "d").valueOf()
            }
            if (currentAIBT < currentALDT) { currentAIBT = moment.utc(currentAIBT).add(1, "d").valueOf() }
        }
        setActualAobt(currentAOBT)
        setActualAtot(currentATOT)
        setActualAldt(currentALDT)
        setActualAibt(currentAIBT)
    }, [dof, aobt, atot, aldt, aibt])

    // useEffect(() => {
    //     let data = {
    //         dof: dofUTC,
    //         orig: orig,
    //         dest: dest,
    //         aobt: aobtUTC,
    //         atot: atotUTC,
    //         aldt: aldtUTC,
    //         aibt: aibtUTC,
    //         ldg: ldg,
    //         landing_fee_payed: feePaid
    //     }
    //     onChange && onChange(data)

    // }, [dofUTC, orig, dest, aobtUTC, atotUTC, aldtUTC, aibtUTC, ldg, feePaid])

    useEffect(() => {
        let data = {
            dof: dof,
            orig: orig,
            dest: dest,
            aobt: actualAobt,
            atot: actualAtot,
            aldt: actualAldt,
            aibt: actualAibt,
            ldg: ldg,
            landing_fee_payed: feePaid
        }
        onChange && onChange(data)
    }, [dof, orig, dest, actualAobt, actualAtot, actualAldt, actualAibt, ldg, feePaid])


    function setEndAdronment(a, b) {
        let val = moment(a).startOf("day").diff(moment(b).startOf("day"), 'days')
        if (val !== 0) {
            return <InputAdornment position="end" ><Typography variant="h6">{val > 0 ? "+" : ""} {val}</Typography></InputAdornment>
        } else {
            return null
        }
    }

    if (lastItem) {
        return (
            <Box position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgColor={color}
                    color="white"
                    width="2rem"
                    height="2rem"
                    borderRadius="50%"
                    position="absolute"
                    top="0%"
                    left="2px"
                    zIndex={2}
                    sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                >
                    <Icon fontSize="inherit" sx={iconStyle}>{icon}</Icon>
                </Box>
                <Box ml={5.75} pt={5} lineHeight={0} >
                    <Card>
                        <Box p={3}>
                            <Button variant="gradient" color="dark" onClick={() => { addAction && addAction() }}>+ ADD</Button>
                        </Box>
                    </Card>
                </Box>
            </Box >
        );
    }

    return (
        <Box position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgColor={color}
                color="white"
                width="2rem"
                height="2rem"
                borderRadius="50%"
                position="absolute"
                top="0%"
                left="2px"
                zIndex={2}
                sx={{ fontSize: ({ typography: { size } }) => size.sm }}
            >
                <Icon fontSize="inherit" sx={iconStyle}>{icon}</Icon>
            </Box>
            <Box ml={5.75} pt={5} lineHeight={0} >
                <Card>
                    <Box p={3}>
                        <Stack direction="column">
                            <Stack
                                direction="column"
                                spacing={2}
                            >
                                <Typography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
                                    {title}
                                </Typography>
                                <DatePicker
                                    value={dof}
                                    onChange={(event) => setDof(event[0].getTime())}
                                    input={{
                                        InputLabelProps: { shrink: true },
                                        label: t("common:label.dof")
                                    }} />
                            </Stack>
                            <Divider sx={{ opacity: 0.75 }} />
                            <Grid container
                                spacing={2}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={advancedSettings} onChange={(e) => setAdvancedSettings(e.target.checked)} />}
                                        label={t('common:label.advanced_settings')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Stack
                                        direction="column"
                                        alignItems="stretch"
                                        spacing={2}>
                                        <AirportSearch
                                            fullWidth
                                            label={t("common:label.departure")}
                                            value={value.orig ?? undefined}
                                            onChange={e => setOrig(e)} />
                                        <AirportSearch
                                            fullWidth
                                            label={t("common:label.arrival")}
                                            value={value.dest ?? undefined}
                                            onChange={e => setDest(e)} />
                                    </Stack>
                                </Grid>
                                {advancedSettings && <Grid item xs={12} sm={6} md={4}>
                                    <Box mt={1.5}>
                                        <Stack
                                            direction="column"
                                            alignItems="stretch"
                                            spacing={5} >
                                            <DatePicker
                                                value={actualAobt - minutesToMilliseconds(moment().utcOffset())}
                                                onChange={(event) => setAobt(event[0].getTime() + minutesToMilliseconds(moment().utcOffset()))}
                                                input={{
                                                    InputLabelProps: { shrink: true },
                                                    label: t("common:label.aobt"),
                                                    InputProps: { endAdornment: setEndAdronment(actualAobt, actualAtot) }
                                                }}
                                                options={{
                                                    minuteIncrement: 1,
                                                    time_24hr: true,
                                                    enableTime: true,
                                                    noCalendar: false
                                                }} />
                                            <DatePicker
                                                value={actualAldt - minutesToMilliseconds(moment().utcOffset())}
                                                onChange={(event) => setAldt(event[0].getTime() + minutesToMilliseconds(moment().utcOffset()))}
                                                input={{
                                                    InputLabelProps: { shrink: true },
                                                    label: t("common:label.aldt"),
                                                    InputProps: { endAdornment: setEndAdronment(actualAldt, actualAtot) }
                                                }}
                                                options={{
                                                    minuteIncrement: 1,
                                                    time_24hr: true,
                                                    enableTime: true,
                                                    noCalendar: false
                                                }} />
                                        </Stack>
                                    </Box>
                                </Grid>}
                                {!advancedSettings && <Grid item xs={12} sm={6} md={4}>
                                    <Box mt={1.5}>
                                        <Stack
                                            direction="column"
                                            alignItems="stretch"
                                            spacing={5} >
                                            <DatePicker
                                                value={actualAobt - minutesToMilliseconds(moment().utcOffset())}
                                                onChange={(event) => setAobt(event[0].getTime() + minutesToMilliseconds(moment().utcOffset()))}
                                                input={{
                                                    InputLabelProps: { shrink: true },
                                                    label: t("common:label.aobt"),
                                                    InputProps: { endAdornment: setEndAdronment(actualAobt, actualAtot) }
                                                }}
                                                options={{
                                                    minuteIncrement: 1,
                                                    time_24hr: true,
                                                    enableTime: true,
                                                    noCalendar: true
                                                }} />
                                            <DatePicker
                                                value={actualAldt - minutesToMilliseconds(moment().utcOffset())}
                                                onChange={(event) => setAldt(event[0].getTime() + minutesToMilliseconds(moment().utcOffset()))}
                                                input={{
                                                    InputLabelProps: { shrink: true },
                                                    label: t("common:label.aldt"),
                                                    InputProps: { endAdornment: setEndAdronment(actualAldt, actualAtot) }
                                                }}
                                                options={{
                                                    minuteIncrement: 1,
                                                    time_24hr: true,
                                                    enableTime: true,
                                                    noCalendar: true
                                                }} />
                                        </Stack>
                                    </Box>
                                </Grid>}
                                {advancedSettings && <Grid item xs={12} sm={6} md={4}>
                                    <Box mt={1.5}>
                                        <Stack
                                            direction="column"
                                            alignItems="stretch"
                                            spacing={5} >
                                            <DatePicker
                                                value={actualAtot - minutesToMilliseconds(moment().utcOffset())}
                                                onChange={(event) => setAtot(event[0].getTime() + minutesToMilliseconds(moment().utcOffset()))}
                                                input={{
                                                    InputLabelProps: { shrink: true },
                                                    label: t("common:label.atot")
                                                }}
                                                options={{
                                                    minuteIncrement: 1,
                                                    time_24hr: true,
                                                    enableTime: true,
                                                    noCalendar: false
                                                }} />
                                            <DatePicker
                                                value={actualAibt - minutesToMilliseconds(moment().utcOffset())}
                                                onChange={(event) => setAibt(event[0].getTime() + minutesToMilliseconds(moment().utcOffset()))}
                                                input={{
                                                    InputLabelProps: { shrink: true },
                                                    label: t("common:label.aibt"),
                                                    InputProps: { endAdornment: setEndAdronment(actualAibt, actualAtot) }
                                                }}
                                                options={{
                                                    minuteIncrement: 1,
                                                    time_24hr: true,
                                                    enableTime: true,
                                                    noCalendar: false
                                                }} />
                                        </Stack>
                                    </Box>
                                </Grid>}
                                {!advancedSettings && <Grid item xs={12} sm={6} md={4}>
                                    <Box mt={1.5}>
                                        <Stack
                                            direction="column"
                                            alignItems="stretch"
                                            spacing={5} >
                                            <DatePicker
                                                value={actualAtot - minutesToMilliseconds(moment().utcOffset())}
                                                onChange={(event) => setAtot(event[0].getTime() + minutesToMilliseconds(moment().utcOffset()))}
                                                input={{
                                                    InputLabelProps: { shrink: true },
                                                    label: t("common:label.atot")
                                                }}
                                                options={{
                                                    minuteIncrement: 1,
                                                    time_24hr: true,
                                                    enableTime: true,
                                                    noCalendar: true
                                                }} />
                                            <DatePicker
                                                value={actualAibt - minutesToMilliseconds(moment().utcOffset())}
                                                onChange={(event) => setAibt(event[0].getTime() + minutesToMilliseconds(moment().utcOffset()))}
                                                input={{
                                                    InputLabelProps: { shrink: true },
                                                    label: t("common:label.aibt"),
                                                    InputProps: { endAdornment: setEndAdronment(actualAibt, actualAtot) }
                                                }}
                                                options={{
                                                    minuteIncrement: 1,
                                                    time_24hr: true,
                                                    enableTime: true,
                                                    noCalendar: true
                                                }} />
                                        </Stack>
                                    </Box>
                                </Grid>}
                            </Grid>
                            <Divider sx={{ opacity: 0.75 }} />
                            <Box>
                                <Grid container
                                    direction="row"
                                    alignItems="center"
                                    spacing={3}>
                                    <Grid item>
                                        <Input
                                            label={t("common:label.landings")}
                                            type="number"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            value={value.ldg}
                                            onChange={(event) => setLdg(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Checkbox checked={value.landing_fee_payed} onChange={(e) => setFeePaid(e.target.checked)} />}
                                            label={t('common:label.landingfee')}
                                        />
                                    </Grid>
                                    <Grid item sx={{ marginLeft: "auto" }}>
                                        <Button disabled={firstItem} variant="gradient" color="error" onClick={() => { removeAction && removeAction() }}><Icon>delete</Icon></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Box>
                </Card>
            </Box >
        </Box >
    );
}

// Setting default values for the props of TimelineItem
TimelineJourneyItem.defaultProps = {
    color: "info",
    lastItem: false
};

// Typechecking props for the TimelineItem
TimelineJourneyItem.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    icon: PropTypes.node.isRequired,
    iconStyle: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    lastItem: PropTypes.bool
};

export default TimelineJourneyItem;
