import { Stack, Grid, Divider, AppBar, Tabs, Tab, stepButtonClasses, Slider } from "@mui/material";
import Box from "components/Box";
import Input from "components/Input";
import AircraftTypeSearch from "components/Input/AircraftTypeSearch";
import AirportSearch from "components/Input/AirportSearch";
import AutoComplete from "components/Input/AutoComplete";
import CertificationSearch from "components/Input/CertificationSearch";
import CountrySearch from "components/Input/CountrySearch";
import TypographyBlock from "components/Typography/TypographyBlock";
import { getCountryByAlpha2 } from "lib/functions/country";
import { getCountryByReg } from "lib/functions/country";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

export default function GeneralTab({ setData, aircraft, tabData }) {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    const [tabValue, setTabValue] = useState("en");
    const [description, setDescription] = useState({ en: "" })
    const [name, setName] = useState("");
    const [airclass, setAirclass] = useState("");
    const [type, setType] = useState("");
    const [cert, setCert] = useState({ certification: 'VFR' });
    const [homebase, setHomebase] = useState();
    const [country, setCountry] = useState("");

    useEffect(() => {
        if (!!tabData) {
            setName(tabData.name)
            setAirclass(tabData?.fullclass)
            setType(tabData.type)
            setCert(tabData.certification)
            setHomebase(tabData.homebase)
            setCountry(tabData.reg_country)
            setDescription(tabData.description)
        }
        else if (!!aircraft) {
            setName(aircraft.name)
            setAirclass(aircraft?.fullclass)
            setType({ name: aircraft.type })
            setCert({ certification: aircraft.certification })
            setHomebase(aircraft.homebase)
            var tmpCountry = null;
            if (aircraft.reg_country) {
                tmpCountry = { alpha3Code: getCountryByAlpha2(aircraft.reg_country)?.ISO3166_1_Alpha3 }
            } else {
                tmpCountry = { alpha3Code: getCountryByReg(aircraft.reg)?.ISO3166_1_Alpha3 }
                if (!tmpCountry.alpha3Code) {
                    tmpCountry = { name: "Unknown" }
                }
            }
            setCountry(tmpCountry)
            setDescription(aircraft.description)
        }
    }, [aircraft, tabData])

    useEffect(() => {
        let timeout = setTimeout(() => {
            var result = {
                reg: aircraft.reg,
                name: name,
                default_lang: "en",
                fullclass: airclass,
                type: type,
                certification: cert,
                homebase: homebase,
                reg_country: country,
                description: description
            }
            setData(result)
        }, 500);

        return () => { clearTimeout(timeout) }
    }, [name, airclass, type, cert, homebase, country, description])

    return (
        <Box p={2}>
            <Grid container spacing={3}
                alignItems="flex-end">
                <Grid item xs={12} sm={4}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense
                                nopadding
                                title={t("dashboard:charter.aircrafts.steps.required.registration")}
                                description={null}
                            />
                            <Box mt={-1} width="100%">
                                <Input
                                    //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                    label={""}
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    value={aircraft.reg}
                                    disabled
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Divider sx={{ opacity: 0.75 }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box ml={1} mr={1} mb={1} >
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("dashboard:charter.aircrafts.steps.required.name")}
                                description={null}
                            />
                            <Box mt={-1} width="100%">
                                <Input
                                    //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                    label={""}
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("dashboard:charter.aircrafts.steps.required.homebase")}
                                description={null}
                            />
                            <Box mt={-1}>
                                <AirportSearch
                                    fullWidth
                                    label={""}
                                    onChange={setHomebase}
                                    value={homebase}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("dashboard:charter.aircrafts.steps.required.country")}
                                description={null}
                            />
                            <Box mt={-1}>
                                <CountrySearch
                                    fullWidth
                                    label={""}
                                    onChange={setCountry}
                                    value={country}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Divider sx={{ opacity: 0.75 }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={("Aircraft Model Spec")}
                                description={null}
                            />
                            <Box mt={-1}>
                                <AircraftTypeSearch
                                    fullWidth
                                    label={"Search"}
                                    onChange={setAirclass}
                                    value={airclass}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("dashboard:charter.aircrafts.steps.required.class")}
                                description={null}
                            />
                            <Box mt={-1} width="100%">
                                <Input
                                    //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                    label={""}
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    options={[]}
                                    onChange={(e) => setAirclass(e.target.value)}
                                    value={airclass?.description ?? ""}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("dashboard:charter.aircrafts.steps.required.type")}
                                description={null}
                            />
                            <Box mt={-1} width="100%">
                                <Input
                                    //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                    label={""}
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    options={[]}
                                    onChange={(e) => setType(e.target.value)}
                                    value={airclass?.type ?? ""}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Divider sx={{ opacity: 0.75 }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                dense={true}
                                nopadding
                                title={t("dashboard:charter.aircrafts.steps.required.certification")}
                                description={null}
                            />
                            <Box mt={-1}>
                                <CertificationSearch
                                    fullWidth
                                    label={""}
                                    onChange={setCert}
                                    value={cert}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Divider sx={{ opacity: 0.75 }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box ml={1} mr={1} mb={1}>
                        <Stack>
                            <TypographyBlock
                                nopadding
                                title={t("dashboard:charter.aircrafts.steps.settings.general.description")}
                            />
                            <AppBar position="static">
                                <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
                                    <Tab label={"en"} value={"en"} />
                                    <Tab label={"de"} value={"de"} />
                                </Tabs>
                            </AppBar>
                            <Box pt={3} width="100%">
                                <Input
                                    type="text"
                                    multiline
                                    rows={12}
                                    value={description[tabValue]}
                                    onChange={(event) => setDescription({ ...description, [tabValue]: event.target.value })}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}
