import Icon from "@mui/material/Icon";
import Box from "components/Box";
import { useTimeline } from "../context";
import timelineItem from "./styles";
import { Card, Checkbox, FormControlLabel, Grid, Skeleton, Divider, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { forwardRef, useEffect, useState } from "react";
import Input from "components/Input";
import Button from "components/Button";
import Autocomplete from '@mui/material/Autocomplete';
import AutoComplete from "components/Input/AutoComplete";
import OpsCounterHoursMinutes from "aeromate-dashboard/LogTimes/components/OpsCounterHoursMinutes";
import OpsCounterHours from "aeromate-dashboard/LogTimes/components/OpsCounterHours";
import { Timer, WatchLater } from "@mui/icons-material";
import InputRoot from "components/Input/InputRoot";
import { millisecondsToHHMM } from "lib/functions";
import { millisecondsToHHH } from "lib/functions";

const OpsInput = forwardRef(({ error, success, disabled, ...rest }, ref) => {
    const { t } = useTranslation()
    return (
        <InputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }}
            InputLabelProps={{ shrink: true }}
            label={t("common:label.ops_counter")}
            variant="outlined"
            margin="dense"
            fullWidth
        />
    )
});

function TimelineFuelItem(props) {
    const { airports, color,
        value, icon, iconStyle,
        lastItem, firstItem,
        addItem, removeAction,
        addAction, onChange,
        setFuelStart, setFuelEnd,
        isLoading } = props

    const { t } = useTranslation();
    const isDark = useTimeline();

    const [fuel, setFuel] = useState();
    const [price, setPrice] = useState();
    const [pilotPayed, setPilotPayed] = useState(false);
    const [airport, setAirport] = useState();
    const [unit, setUnit] = useState();
    const [currency, setCurrency] = useState();

    const [counter, setCounter] = useState(value.ops_count)
    const [counterHHMM, setCounterHHMM] = useState()
    const [counterFloat, setCounterFloat] = useState()

    useEffect(() => {
        if (counter !== undefined) {
            setCounterHHMM(millisecondsToHHMM(counter))
            setCounterFloat(millisecondsToHHH(counter).toFixed(2))
        }
    }, [counter])

    const [HHMM, setHHMM] = useState(true)

    useEffect(() => {
        console.log("counter", counter)
        if (!!fuel || !!price || airport || counter) {
            let data = {
                fuel: fuel ?? value.fuel,
                fuel_unit: unit,
                price: price ?? value.price,
                price_unit: currency,
                pilot_payed: pilotPayed ?? value.pilot_payed,
                airport: airport ?? value.airport,
                ops_count: counter ?? value.ops_count
            }
            onChange && onChange(data)
        }
    }, [fuel, price, pilotPayed, airport, counter, unit, currency])

    let content = () => {
        if (firstItem) {
            return (
                <Box p={3}>
                    {
                        isLoading ?
                            <Skeleton variant="rounded" width={188} height={56} />
                            :
                            <Input
                                InputLabelProps={{ shrink: true }}
                                label={t("common:label.fuel_start")}
                                type="number"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={value}
                                onChange={(event) => setFuelStart(event.target.value)}
                            />
                    }
                </Box>)
        }
        if (lastItem) {
            return (
                <Box p={3}>
                    {
                        isLoading ?
                            <Skeleton variant="rounded" width={188} height={56} />
                            :
                            <Input
                                InputLabelProps={{ shrink: true }}
                                label={t("common:label.fuel_end")}
                                type="number"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={value}
                                onChange={(event) => setFuelEnd(event.target.value)}
                            />
                    }
                </Box>)
        }
        if (addItem) {
            return (
                <Box p={3}>
                    <Button
                        variant="gradient"
                        color="dark"
                        onClick={() => { addAction && addAction() }}>
                        + ADD
                    </Button>
                </Box>)
        }
        return (
            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            fullWidth
                            value={value.airport}
                            options={airports}
                            isOptionEqualToValue={(option, value) => {
                                return (option === value)
                            }
                            }
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => { setAirport(value); }}
                            renderInput={(params) => (
                                <Input
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    label={t("common:label.airport")}
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />


                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction="row">
                            {HHMM ?
                                <OpsCounterHoursMinutes
                                    defaultValue={counterHHMM}
                                    onChange={setCounter}
                                    customInput={OpsInput} />
                                :
                                <OpsCounterHours
                                    defaultValue={counterFloat}
                                    onChange={setCounter}
                                    customInput={OpsInput} />
                            }
                            <Button onClick={() => setHHMM(!HHMM)}>
                                {HHMM ? <WatchLater /> : <Timer />}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={0} sm={0} md={4} />
                    <Grid item xs={12} sm={4} md={4}>
                        <Stack direction="row">
                            <Input
                                InputLabelProps={{ shrink: true }}
                                label={t("common:label.quantity")}
                                type="number"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={value.fuel}
                                onChange={(event) => setFuel(event.target.value)}
                            />
                            <AutoComplete
                                //units
                                sx={{ maxWidth: "48px", marginLeft: "4px" }}
                                disableClearable={true}
                                margin="dense"
                                variant="standard"
                                options={[{ label: "ltr", name: "liter" }, { label: "gal", name: "gallons" }]}
                                defaultValue={{ label: "ltr", name: "liter" }}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(value) => value.label}
                                onChange={setUnit}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Stack direction="row">
                            <Input
                                InputLabelProps={{ shrink: true }}
                                label={t("common:label.price")}
                                type="number"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={value.price}
                                onChange={(event) => setPrice(event.target.value)}
                            />
                            <AutoComplete
                                //currencies
                                sx={{ maxWidth: "48px", marginLeft: "4px" }}
                                disableClearable={true}
                                margin="dense"
                                variant="standard"
                                options={[{ label: "€", name: "euro" }, { label: "$", name: "dollar" }]}
                                defaultValue={{ label: "€", name: "euro" }}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(value) => value.label}
                                onChange={setCurrency}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ opacity: 0.75 }} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                            control={<Checkbox checked={value.landing_fee_payed} onChange={(e) => setPilotPayed(e.target.checked)} />}
                            label={t('common:label.paidbypilot')}
                        />
                    </Grid>
                    <Grid item sx={{ marginLeft: "auto" }}>
                        <Button disabled={firstItem} variant="gradient" color="error" onClick={() => { removeAction && removeAction() }}><Icon>delete</Icon></Button>
                    </Grid>
                </Grid>
            </Box>)
    }

    return (
        <Box position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgColor={color}
                color="white"
                width="2rem"
                height="2rem"
                borderRadius="50%"
                position="absolute"
                top="0%"
                left="2px"
                zIndex={2}
                sx={{ fontSize: ({ typography: { size } }) => size.lg }}
            >
                <Icon fontSize="inherit" sx={iconStyle}>{icon}</Icon>
            </Box>
            <Box ml={5.75} pt={5} lineHeight={0} >
                <Card>
                    {content()}
                </Card>
            </Box>
        </Box >
    );
}

export default TimelineFuelItem;