import { Icon } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(Icon)(({ theme, ownerState }) => {
    const { palette, functions, boxShadows } = theme;
    const { color } = ownerState;

    const { white, text, gradients } = palette;
    const { boxShadow, linearGradient } = functions;
    const { colored } = boxShadows;

    // background value
    const backgroundValue =
        color === "white" || !gradients[color]
            ? white.main
            : linearGradient(gradients[color].main, gradients[color].state);

    // boxShadow value
    const boxShadowValue = colored[color]
        ? `${boxShadow([0, 3], [3, 0], palette[color].main, 0.15)}, ${boxShadow(
            [0, 3],
            [1, -2],
            palette[color].main,
            0.2
        )}, ${boxShadow([0, 1], [5, 0], palette[color].main, 0.15)}`
        : "none";

    // boxShadow value when button is hovered
    const hoveredBoxShadowValue = colored[color]
        ? `${boxShadow([0, 14], [26, -12], palette[color].main, 0.4)}, ${boxShadow(
            [0, 4],
            [23, 0],
            palette[color].main,
            0.15
        )}, ${boxShadow([0, 8], [10, -5], palette[color].main, 0.2)}`
        : "none";

    // color value
    let colorValue = white.main;

    if (color === "white") {
        colorValue = text.main;
    } else if (color === "light") {
        colorValue = gradients.dark.state;
    }

    return {
        background: backgroundValue,
        color: colorValue,
        boxShadow: boxShadowValue,

        "&:hover": {
            backgroundColor: white.main,
            boxShadow: hoveredBoxShadowValue,
        },

        "&:focus:not(:hover)": {
            boxShadow: boxShadowValue,
        },

        "&:disabled": {
            background: backgroundValue,
            color: colorValue,
        },
    };
});