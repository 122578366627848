
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { useModal } from "hooks/modalHook";
import AeroLoading from "components/Progress/AeroLoading";
import { restPut } from "lib/rest";
import _ from "lodash";
import { Stack } from "@mui/material";
import Alert from "components/Alert";
import ClearIcon from '@mui/icons-material/ErrorOutline';
import { useRefresher } from "hooks/refreshHook";

function PostCancelationModal({ reg }) {
    const { t } = useTranslation();
    const { removeModal } = useModal();
    const { refresh } = useRefresher();
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState([]);

    const [details, setDetails] = useState("");
    const [reason, setReason] = useState("");

    useEffect(async () => {
        await restPut(null, `booking/${reg}/status/canceled/`, { reason: reason, detail: details })
        refresh();
        removeModal();
    }, [])

    return (
        <DefaultModal
            title={(reg ?? "")}
            closeExplicit={!(errors.length !== 0)}
            cancel={{
                show: errors.length !== 0,
                text: "Back",
                color: "error",
            }}
            confirm={{ show: false }}
        >{errors.length === 0 &&
            <AeroLoading customText={status} />
            }
            {errors.length !== 0 &&
                <Stack>{!!errors &&
                    errors.map((error, i) =>
                        <Alert color="error" key={"Booking-Alert-" + i}>
                            <ClearIcon fontSize="small" />&nbsp;
                            {t("error:" + error.code)}
                        </Alert>)}
                </Stack>
            }

        </DefaultModal >
    );
}

export default PostCancelationModal;