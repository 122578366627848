import { Container, Grid } from "@mui/material"
import Box from "components/Box"
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard"
import RotatingCard from "components/Cards/RotatingCard"
import RotatingCardBack from "components/Cards/RotatingCard/RotatingCardBack"
import RotatingCardFront from "components/Cards/RotatingCard/RotatingCardFront"
import React from 'react'
import { useTranslation } from "react-i18next"

export const Info = () => {
    const { t } = useTranslation();

    return (

        <Box component="section" py={6} my={6} bgColor="grey-100">
            <Container>
                <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
                    <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
                        <RotatingCard>
                            <RotatingCardFront
                                image={"https://media.westeurope.aero-services.net/aeromate/product/AdobeStock_139621851sm.jpeg"}
                                icon="park"
                                color="primary"
                                title={
                                    <>
                                        Sustainabilty
                                        <br />
                                        Initiatives
                                    </>
                                }
                                description="Our Sustainabilty Initiatives"
                            />
                            <RotatingCardBack
                                image={"https://media.westeurope.aero-services.net/aeromate/product/AdobeStock_139621851sm.jpeg"}
                                color="dark"

                                title={t("sustainabiltyPage:info.title2")}
                                description={t("sustainabiltyPage:info.description2")}

                            />
                        </RotatingCard>
                    </Grid>
                    <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <DefaultInfoCard
                                    color="dark"
                                    icon="flight"
                                    title={t("sustainabiltyPage:info.title3")}
                                    description={t("sustainabiltyPage:info.description3")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DefaultInfoCard
                                    color="dark"
                                    icon="connecting_airports"
                                    title={t("sustainabiltyPage:info.title4")}
                                    description={t("sustainabiltyPage:info.description4")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                            <Grid item xs={12} md={6}>
                                <DefaultInfoCard
                                    color="dark"
                                    icon="computer"
                                    title={t("sustainabiltyPage:info.title5")}
                                    description={t("sustainabiltyPage:info.description5")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DefaultInfoCard
                                    color="dark"
                                    icon="forest"
                                    title={t("sustainabiltyPage:info.title6")}
                                    description={t("sustainabiltyPage:info.description6")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
