/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import { bookings_url } from "./global";
import Avatar from "components/Avatar";
import placeholderAvatar from "assets/images/avatar.png"

const routes = [
  {
    name: "routes:headings.platform",
    icon: <Icon>widgets</Icon>,
    collapse: [
      {
        name: "routes:gettingStarted.title",
        description: "routes:gettingStarted.description",
        route: "/gettingstarted",
      },
      {
        name: "routes:features.title",
        description: "routes:features.description",
        route: "/features",
        dropdown: true,
        collapse: [
          {
            name: "routes:features.security",
            route: "/features/security",
          },
          {
            name: "routes:features.fleet_management",
            route: "/features/fleet_management",
          },
          {
            name: "routes:features.online_shop",
            route: "/features/online_shop",
          },
          {
            name: "routes:features.dto_ato",
            route: "/features/dto_ato",
          }
        ],
      },
      {
        name: "routes:solutions.title",
        description: "routes:solutions.description",
        route: "/solutions",
        dropdown: true,
        collapse: [
          {
            name: "routes:solutions.digital-fi",
            route: "/solutions/digital-fi",
          },
          {
            name: "routes:solutions.keyvault",
            route: "/keyvault",
          }
        ],
      },
    ],
  }, {
    name: "routes:headings.advisory",
    icon: <Icon>signpost</Icon>,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "routes:advisory.title",
        route: "/advisory",
        collapse: [
          {
            name: "routes:advisory.pmaas",
            route: "/advisory/pmaas",
          },
          {
            name: "routes:advisory.smaas",
            route: "/advisory/smaas",
          },
          {
            name: "routes:advisory.dataAnalytics",
            route: "/advisory/data-advisory",
          }
        ],
      },
      {
        name: "routes:services.title",
        route: "/services",
        collapse: [
          {
            name: "routes:services.softwareDev",
            route: "/services/software-development",
          },
          {
            name: "routes:services.appDev",
            route: "/services/app-development",
          }
        ],
      },
      {
        name: "routes:training.title",
        route: "/agile-trainings",
        collapse: [
          {
            name: "routes:training.scrum",
            route: "/agile-trainings/scrum",
          },
          {
            name: "routes:training.kanban",
            route: "/agile-trainings/kanban",
          },
          {
            name: "routes:training.okr",
            route: "/agile-trainings/okr",
          }
        ],
      }
    ],
  },
  {
    name: "routes:headings.aboutUs",
    icon: <Icon>people</Icon>,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "routes:company.title",
        collapse: [
          {
            name: "routes:aboutUs.title",
            description: "routes:aboutUs.description",
            route: "/aboutus",
          },
          {
            name: "routes:partner.title",
            description: "routes:partner.description",
            route: "/partner",
          },
          {
            name: "routes:contactUs.title",
            description: "routes:contactUs.description",
            href: bookings_url,
          }
        ],
      },
      {
        name: "routes:responsibility.title",
        collapse: [
          {
            name: "routes:sustainabilty.title",
            description: "routes:sustainabilty.description",
            route: "/sustainabilty",
          },
          {
            name: "routes:culture.title",
            description: "routes:culture.description",
            route: "/responsibility/culture",
          }
        ],
      },
      {
        name: "routes:career.title",
        collapse: [
          {
            name: "routes:career.join",
            href: "https://de.indeed.com/cmp/Aero-Mate-Gmbh/jobs"
          }
        ],
      },
      {
        name: "routes:legal.title",
        collapse: [
          {
            name: "routes:imprint.title",
            description: "routes:imprint.description",
            route: "/imprint",
          },
          {
            name: "routes:privacy.title",
            description: "routes:privacy.description",
            route: "/privacy",
          },
          {
            name: "routes:termsAndConditions.title",
            description: "routes:termsAndConditions.description",
            route: "/gtc",
          }
        ],
      }
    ],
  },
  {
    name: "routes:headings.account",
    icon: <Avatar size="xs" alt="Remy Sharp" src={placeholderAvatar} />,
    collapse: [
      {
        name: "routes:personal.cockpit",
        route: "/my/account/cockpit",
      },
      {
        name: "DIVIDER"
      },
      {
        name: "routes:personal.settings",
        route: "/my/account/settings",
      },
      {
        name: "LANGUAGE",
        dropdown: true,
        collapse: [],
      },
      {
        name: "DIVIDER"
      },
      {
        name: "LOGOUT"
      }
    ]
  }
];

export default routes;
