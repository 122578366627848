// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Box from "components/Box";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "components/Cards/CounterCards/DefaultCounterCard";
import { useTranslation } from "react-i18next";

function Stats() {
    const { t } = useTranslation();
    return (
        <Box component="section" py={3} id="stats">
            <Container>
                <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
                    <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
                        {t("aboutUsPage:stats", { returnObjects: true }).map((x,i) =>
                            <Grid item xs={12} md={3} key={"stat-grid-item-" + i}>
                                <DefaultCounterCard
                                    count={x.count}
                                    separator={x.separator}
                                    title={x.title}
                                    description={x.description}
                                />
                            </Grid>)}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Stats;