import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import colors from "assets/theme/base/colors";
import Alert from "components/Alert";
import Avatar from "components/Avatar";
import Badge from "components/Badge";
import Box from "components/Box";
import Typography from "components/Typography";
import placeholderAvatar from "assets/images/avatar.png"
import Progress from "components/Progress";
import { getBlobImageUrl } from "lib/functions";
import { useEffect, useState } from "react";
import { getLanguageTextWithFallBack } from "lib/functions";
import { restGet } from "lib/rest";

const { light } = colors;

const sticky = {
    position: "sticky",
    left: 0,
    background: light.main,
    boxShadow: "5px 2px 5px grey",
    width: "256px",
    zIndex: 10
}

function calcColorByValue(value) {
    if (value < 33) {
        return "error"
    } else if (value < 66) {
        return "warning"
    } else if (value < 100) {
        return "success"
    }
}

function StickyColumnTable({ header, rows, itemKey, alias }) {
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState();
    useEffect(async () => {
        console.log("key", itemKey)
        console.log("alias", alias)
        if (itemKey && alias) {
            setLoading(true)
            await restGet((resp) => (resp && setTitle(getLanguageTextWithFallBack(resp.data[0].caption))), `shop/${alias}/item/${itemKey}`)
            setLoading(false)
        }
    }, [itemKey, alias]);
    return (
        <Box p={5}>
            <TableContainer style={{ maxWidth: "100%" }}>
                <Table
                    aria-label="simple table"
                    sx={{ tableLayout: "fixed", width: "100%", minWidth: "650px" }}
                >
                    <Box component="thead">
                        <TableRow>
                            <TableCell sx={sticky}>
                                <Typography variant='h6'>{title ?? ""}</Typography>
                            </TableCell>
                            {header?.map((e, i) => (
                                <TableCell key={"user-column-" + i} align="right" width="128px">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center" >
                                        <Avatar src={e?.image?.Avatar ? getBlobImageUrl(e?.image?.Avatar) : placeholderAvatar} alt="Avatar" />
                                        {e.href ?
                                            <Typography component="a" href={e.href} variant="body2">{e.name}</Typography>
                                            :
                                            <Typography variant="body2">{e.name}</Typography>
                                        }
                                    </Stack>
                                </TableCell>
                            ))}
                        </TableRow>
                    </Box>
                    <TableBody>
                        {rows?.map((row, i) => (
                            <TableRow key={"user-row-" + i}>
                                <TableCell
                                    sx={sticky}
                                    component="th"
                                    scope="row"
                                >
                                    {row.title}
                                </TableCell>
                                {row.data?.map((v) => <TableCell align="center"><Progress color={calcColorByValue(v)} value={v} label={true} circular={true} size={30} /></TableCell>)}
                            </TableRow>
                        ))
                        }
                    </TableBody >
                </Table >
            </TableContainer >
        </Box >
    )
}
export default StickyColumnTable;