import ConfirmationModal from "components/Modal/ConfirmationModal/ConfirmationModal";
import i18n from "locals/config";
import Badge from "components/Badge";
import moment from "moment";
import { convertTimeDatabaseToFrontend } from "lib/functions";

export const joinColumns = [
    {
        Header: i18n.t("common:label.name"),
        accessor: "identity[0].name",
    },
    {
        Header: i18n.t("common:label.email"),
        accessor: "identity[0].email",
    },
    {
        Header: i18n.t("common:label.request_date"),
        accessor: "created",
        Cell: props => moment(convertTimeDatabaseToFrontend(parseInt(props.value))).format("LLL")
    },
    {
        Header: i18n.t("common:label.status"),
        accessor: "status",
        Cell: props => <Badge color={statusColor(props.value)} badgeContent={props.value} />
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
]

export const joinActions = (addModal, data) => {
    return [
        {
            displayStatus: ["pending"],
            title: i18n.t("common:button.accept"),
            action: (index) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.accept")}
                    status="acceptJoin"
                    data={data[index]}
                />)
            }
        },
        {
            displayStatus: ["pending"],
            title: i18n.t("common:button.decline"),
            action: (index) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.decline")}
                    status="declineJoin"
                    data={data[index]}
                />)
            }
        }
    ]
};

function statusColor(status) {
    switch (status) {
        case "done":
            return "light";
        case "pending":
            return "success";
        case "rejected":
            return "light";
        case "revoked":
            return "error";
        default:
            return "info";
    }
}