import { ConstructionOutlined } from '@mui/icons-material'
import { Container, Grid, Stack, Typography } from '@mui/material'
import Box from 'components/Box'
import DefaultCounterCard from 'components/Cards/CounterCards/DefaultCounterCard'
import { restGet } from 'lib/rest'
import React from 'react'
import { useTranslation } from 'react-i18next'


const Counter = ({ id, c02, count }) => {
  const { t } = useTranslation();

  return (
    <Box component="section" py={3} p={10} bgColor="grey-100">
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              color="primary"
              count={count}
              title={t("sustainabiltyPage:counter.title1")}
              description={t("sustainabiltyPage:counter.text1")}
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex" >
            <DefaultCounterCard
              color="primary"
              count={c02}
              suffix="to"
              title={t("sustainabiltyPage:counter.title2")}
              description={t("sustainabiltyPage:counter.text2")}
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="center">
            <Stack>
              <Box ml={5} sx={{display:'flex' , alignItems:"center" , justifyContent:"center"}}>
                <Typography variant="body2" color="text">
                  Our profil on
                </Typography>
              </Box>
              <Box>
                <a href='https://tree-nation.com/de/profil/aero-mate'><img style={{ maxWidth: "300px", height: "100.72px", paddingBottom: "16px", paddingTop: "16px" }} src={"https://aeromatestatic.blob.core.windows.net/img/partner_tree-nation.svg"}></img></a>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Counter