import { Grid, Skeleton, Stack } from "@mui/material";
import EvalButtonGroup from "components/ButtonGroup";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import Checklist from "components/Checklist";
import Progress from "components/Progress";
import { delay } from "lib/functions";
import { restPost } from "lib/rest";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import ChecklistAdminActions from "../components/ChecklistAdminActions";
import ChecklistCard from "../components/ChecklistCard";
import ExamsCard from "../components/ExamsCard";
import { useParams } from "react-router-dom";
import RelatedBookingsCard from "../components/RelatedBookingsCard";

export default function Enrollment({ identityId, charterview }) {
    const { alias } = useParams()
    const [practiceList, setPracticeList] = useState();

    const [theoryList, setTheoryList] = useState();
    const [exams, setExams] = useState();
    const [studenfileId, setStudenfileId] = useState();
    const [charterId, setCharterId] = useState();

    //Check Staff

    //Check editable

    useEffect(() => {
        const callBack = (resp) => {
            if (resp?.data) {
                let data = resp.data[0];
                setPracticeList(data.practice_checklist)
                setTheoryList(data.theory_checklist)
                setExams(data.exams_checklist)
                setStudenfileId(data._id.$oid)
                setCharterId(data.charter_id.$oid)
            }
        }
        alias ? restGet((e) => callBack(e), `studentfile/${alias}/charter/${identityId ?? ""}`) :
            restGet((e) => callBack(e), `studentfile${identityId ?? ""}`)
    }, [alias]);

    async function handleCallback(val, setter, type) {
        if (charterview) {
            setter(val)
            await restPost(null, `studentfile/${studenfileId}/checklist/${type}`, { checklist: val })
        }
    }

    return (
        <Stack>
            {/* 
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <DefaultStatisticsCard
                        title="Flight Hours"
                        count="102 Hours"
                        percentage={{
                            color: "secondary",
                            value: "",
                            label: "",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DefaultStatisticsCard
                        title="Solo "
                        count="87 Hours"
                        percentage={{
                            color: "secondary",
                            value: "85,29%",
                            label: "of total amount",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DefaultStatisticsCard
                        title="Dual"
                        count="25 Hours"
                        percentage={{
                            color: "secondary",
                            value: "14,71%",
                            label: "of total amount",
                        }}
                    />
                </Grid>
            </Grid>
            */}
            {/*             
            <Grid container spacing={3} sx={{ marginTop: "8px" }}>
                <Grid item xs={12} sm={4}>
                    <DefaultStatisticsCard
                        title="VFR Hours"
                        count="102 Hours"
                        percentage={{
                            color: "secondary",
                            value: "100%",
                            label: "of total amount",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DefaultStatisticsCard
                        title="NVFR Hours"
                        count="0 Hours"
                        percentage={{
                            color: "secondary",
                            value: "0%",
                            label: "of total amount",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DefaultStatisticsCard
                        title="IFR"
                        count="0 Hours"
                        percentage={{
                            color: "secondary",
                            value: "0%",
                            label: "of total amount",
                        }}
                    />
                </Grid>
            </Grid>
            */}
            {/*             
            <Grid container spacing={3} sx={{ marginTop: "8px" }}>
                <Grid item xs={12} sm={4}>
                    <ChecklistAdminActions />
                </Grid>
            </Grid> 
            */}
            <Grid container spacing={3} sx={{ marginTop: "8px" }}>
                <Grid item xs={12} >
                    {charterId ?
                        <RelatedBookingsCard identityId={identityId} charterId={charterId} studenfileId={studenfileId} /> :
                        <Skeleton height={400} />
                    }
                </Grid>
                <Grid item xs={12} >
                    {exams ?
                        <ExamsCard list={exams} callback={(e) => handleCallback(e, setExams, "exams")} charterview={charterview} /> :
                        <Skeleton height={400} />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {theoryList ?
                        <ChecklistCard list={theoryList} icon="school" callback={(e) => handleCallback(e, setTheoryList, "theory")} nested charterview={charterview} /> :
                        <Skeleton height={400} />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {practiceList ?
                        <ChecklistCard list={practiceList} icon="flight" callback={(e) => handleCallback(e, setPracticeList, "practice")} nested charterview={charterview} /> :
                        <Skeleton height={400} />
                    }
                </Grid>
            </Grid>
        </Stack>
    )
}
