import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Box from "components/Box";
import Button from "components/Button";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import _ from "lodash";
import { useEffect, useState } from "react";
import colors from "assets/theme/base/colors";

export default function VariantsTab({ setData, defaultData, fee }) {
    const { t } = useTranslation()
    const [variants, setVariants] = useState(defaultData?.variant ?? []);

    const handleDeleteClick = (id) => () => {
        let tmp = variants.filter((row) => row.id !== id)
        tmp = tmp.map((element, index) => { return { ...element, id: index, } })
        setVariants(tmp);
    };

    const columns = [
        {
            field: "de", headerName: t("common:label.title") + " (DE)", editable: true, width: 240,
            valueGetter: (params) => params.row.caption.de ?? "",
        },
        {
            field: "en", headerName: t("common:label.title") + " (EN)", editable: true, width: 240,
            valueGetter: (params) => params.row.caption.en ?? "",
        },
        { field: "price", headerName: t("common:label.price"), editable: true, width: 128, },
        {
            field: "fees", headerName: `Fees ${fee}%`, editable: false, width: 128,
            valueGetter: (params) => { return Math.round(((params.row.price * (fee / 100)) + Number.EPSILON) * 100) / 100 },
            cellClassName: 'disabled'
        },
        {
            field: "total", headerName: "Total", editable: false, width: 128,
            valueGetter: (params) => { return Math.round(((params.row.price * (1 - (fee / 100))) + Number.EPSILON) * 100) / 100 },
            cellClassName: 'disabled'
        },
        {
            field: "actions",
            headerName: t("common:label.actions"),
            type: "actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => [
                <Button
                    variant="gradient" color="error" iconOnly size="small"
                    onClick={handleDeleteClick(id)}
                ><DeleteIcon /></Button >],
        },
    ];

    function handleAdd() {
        let tmp = [...variants]
        tmp.push({
            "id": tmp.length,
            "caption": {
                "de": "",
                "en": ""
            },
            "price": 1,
            "unit_amount": 100
        })
        setVariants(tmp)
    }

    function handleOnCommit(field, id, value) {
        const index = _.findIndex(variants, function (o) { return o.id == id })
        if (index < 0) return;
        let tmo = _.cloneDeep(variants)
        let element = tmo[index]
        if (field === "price") {
            element[field] = Number(value.replace(",", "."))
            element['unit_amount'] = (Number(value.replace(",", "."))) * 100

        } else {
            element["caption"][field] = value
        }
        setVariants(tmo)
    }

    useEffect(() => {
        setData({ "variant": variants, })
    }, [variants]);

    return (
        <Box p={3}>
            <Stack>
                <TypographyBlock
                    nopadding
                    title={t("common:label.variants")}
                />
                <Box
                    sx={{
                        '& .disabled': {
                            backgroundColor: '#f0f2f5',
                            color: '#003253',
                            fontWeight: '600',
                        }
                    }}
                >
                    <DataGrid
                        sx={{
                            border: "none",
                            "& .Mui-selected": {
                                backgroundColor: colors.badgeColors.info.background + " !important"
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: "solid " + colors.badgeColors.info.text + " 1px"
                            }
                        }}
                        autoHeight
                        rows={variants}
                        columns={columns}
                        onCellEditCommit={(e) => handleOnCommit(e.field, e.id, e.value)}
                    />
                </Box>
                <Button onClick={handleAdd} color="success" variant="gradient" sx={{ marginLeft: "auto" }}>{t("common:button.add")}</Button>
            </Stack>
        </Box>
    )
}