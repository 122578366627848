/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Box from "components/Box";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 PRO React examples

function Information() {

  return (
    <Box component="section" pt={3} mt={8}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="primary"
              icon="shield"
              title="Secure"
              description="Reliable brushed steel key storage - safely protects your keys.."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="primary"
              icon="thunderstorm"
              title="Robust"
              description="The robust key safe made of high quality steel guarantees weatherproof protection."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="primary"
              icon="wifi_off"
              title="Flexible"
              description="Maximum flexibility without internet or electricity: our key safe allows easy mounting."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Information;
