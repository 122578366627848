import React from "react";
import { useDialog } from "hooks/dialogHook.jsx";
import KitFooter from "./KitFooter";
import PropTypes from "prop-types";
import KitNavbar from "./KitNavbar/index"
import Card from "@mui/material/Card";
import Box from "components/Box";
import useDocumentTitle from "hooks/useDocumentTitle";

function KitLayout(props) {
    const dialog = useDialog();
    const { nocard, title } = props;
    if (!!title) useDocumentTitle(title)

    return (
        <>
            <KitNavbar sticky />
            {!!dialog && dialog.currentDialog}
            {props.header}
            {nocard ? <Box>{props.children} </Box> : <Card
                sx={{
                    py: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                {props.children}
            </Card>}
            <Box px={1} mt={6}>
                <KitFooter />
            </Box>
        </>
    );
}


// Typechecking props for the DefaultNavbar
KitLayout.propTypes = {
    header: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
};

export default KitLayout;