import i18n from "locals/config";
import Badge from "components/Badge";
import CancelRequestModal from "components/Modal/CancelRequestModal/CancelRequestModal";
import { convertTimeDatabaseToFrontend } from "lib/functions";
import moment from "moment";

export const requestColumns = [
    {
        Header: i18n.t("common:label.alias"),
        accessor: "charter[0].alias"
    },
    {
        Header: i18n.t("common:label.name"),
        accessor: "charter[0].legal_info.chartername"
    },
    {
        Header: i18n.t("common:label.request_date"),
        accessor: "created",
        Cell: props => moment(convertTimeDatabaseToFrontend(parseInt(props.value))).format("LLL")
    },
    {
        Header: i18n.t("common:label.status"),
        accessor: "status",
        Cell: props => <Badge color={statusColor(props.value)} badgeContent={props.value} />
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions"
    }
]

export const requestActions = (addModal, data) => {
    return [{
        displayStatus: ["pending"],
        title: "Cancel Request",
        color: "error",
        action: (index) => {
            addModal(<CancelRequestModal
                data={data[index]}
                actionName={i18n.t("common:button.cancel")}
            />)
        }
    }/*, 
    //TODO Leave Org
    {
        displayStatus: ["done"],
        title: "Leave Organisation",
        color: "error",
        action: null
    }*/
    ]
};

function statusColor(status) {
    switch (status) {
        case "done":
            return "success";
        case "pending":
            return "warning";
        case "rejected":
            return "error";
        case "revoked":
            return "error";
        default:
            return "info";
    }
}