import { useEffect, useState } from "react";
import Box from "components/Box";
import { restGet } from "lib/rest"
import { useParams } from 'react-router-dom';
import { Container, Grid } from "@mui/material";
import AeroLoading from "components/Progress/AeroLoading"
import ShopItemCard from "components/Cards/ShopItemCard/ShopItemCard";

export default function CharterCourseTab({ charter, setImageLoaded }) {
    const [products, setProducts] = useState();
    const { ALIAS } = useParams();
    const [isloading, setIsLoading] = useState(false)
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setProducts(resp.data)
            }
            setIsLoading(true)
            await restGet(restCallback, 'shop/' + ALIAS + '/item', { "item_type": "enrollment" })
            setIsLoading(false)
        }
        !!ALIAS && fetch()
    }, [ALIAS])

    if (!products || isloading) {
        return isloading ? <AeroLoading /> : null;
    }

    return (
        <Container>
            <Grid container spacing={3} sx={{ mt: 3 }} alignItems="stretch">
                {products && products.map((item, i) =>
                    <Grid item xs={12} md={6} lg={4} key={"aircraft-card-" + i} >
                        <Box mt={3} style={{ height: "100%", paddingBottom: "24px" }}>
                            <ShopItemCard key={"shop-item-" + i} item={item} charter={charter} setImageLoaded={setImageLoaded} />
                        </Box>
                    </Grid>)
                }
            </Grid>
        </Container >
    )
}