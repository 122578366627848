
import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Bookings from "./sections/Bookings";
import Checkrides from "./sections/Checkrides";
import Factoring from "./sections/Factoring";
import Invoices from "./sections/Invoices";
import Voucher from "./sections/Voucher";
import { restGet } from "lib/rest";
import Sepa from "./sections/Sepa";

export default function Body() {
    const [sepaDirect, setSepaDirect] = useState(false);
    const { alias } = useParams()

    useEffect(() => {
        const fetch = async () => {
            console.log("start loading")
            await restGet((res) => {
                setSepaDirect(res?.data?.sepa_direct ?? false)
            }, `sepa/${alias}/charter`)
            console.log("finished loading")
        }
        !!alias && fetch()
    }, [alias])

    const { t } = useTranslation();
    const { hash } = useLocation();
    const getTab = () => {
        switch (hash) {
            case "#bookings": return (0)
            case "#factoring": return (1)
            case "#invoices": return (2)
            case "#checkrides": return (3)
            case "#voucher": return (4)
            case "#sepa": if (sepaDirect) { return (5) }
            default: return (0)
        }
    }
    const [tabValue, setTabValue] = useState(getTab());
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = "#" + event.target.id
    }
    const [content, setContent] = useState(null)
    useEffect(() => {
        switch (tabValue) {
            case 0:
                setContent(<Bookings />)
                break;
            case 1:
                setContent(<Factoring />)
                break;
            case 2:
                setContent(<Invoices />)
                break;
            case 3:
                setContent(<Checkrides />)
                break;
            case 4:
                setContent(<Voucher />)
                break;
            case 5:
                if (sepaDirect) {
                    setContent(<Sepa />)
                    break
                }
            default:
                break;
        }
    }, [tabValue, sepaDirect])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <AppBar position="static" sx={{ width: "100%", overflow: { xs: "scroll", md: "unset" } }}>
                        <Tabs value={tabValue} onChange={handleSetTabValue} sx={{ width: "max-content" }}>
                            <Tab sx={{ paddingLeft: "16px", paddingRight: "16px" }} label={t("common:label.bookings")} id="bookings" />
                            <Tab sx={{ paddingLeft: "16px", paddingRight: "16px" }} label={t("common:label.factoring")} id="factoring" />
                            <Tab sx={{ paddingLeft: "16px", paddingRight: "16px" }} label={t("common:label.invoices")} id="invoices" />
                            <Tab sx={{ paddingLeft: "16px", paddingRight: "16px" }} label={t("common:label.checkrides")} id="checkrides" />
                            <Tab sx={{ paddingLeft: "16px", paddingRight: "16px" }} label={t("common:label.voucher")} id="voucher" />
                            {sepaDirect && <Tab sx={{ paddingLeft: "16px", paddingRight: "16px" }} label={t("common:label.sepa")} id="sepa" />}
                        </Tabs>
                    </AppBar>
                </Grid>
                <Grid item container xs={12} >
                    {content}
                </Grid>
            </Grid>
        </div >
    )
}