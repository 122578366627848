import React, { useState, useContext, createContext } from 'react';

const RefreshContext = createContext(null);

export const RefreshProvider = ({ children }) => {
    const [refresher, setRefresher] = useState()

    function refresh() {
        setRefresher(Date.now())
    }

    return (
        <RefreshContext.Provider value={{ refresh, refresher }}>
            {children}
        </RefreshContext.Provider>
    )
}

export const useRefresher = () => useContext(RefreshContext);