import { Stack } from "@mui/material";
import Box from "components/Box";
import CustomerSearch from "components/Input/CustomerSearch";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FerryTab({ reg, setEventSettings }) {
    const { t } = useTranslation();
    const [staffIdentity, setStaffIdentity] = useState()

    useEffect(() => {
        let eventSettings = { flight_type: "ferry" }

        if (!!staffIdentity) eventSettings.identity_id = staffIdentity

        setEventSettings(eventSettings)
    }, [staffIdentity]);

    return (
        <Stack>
            <Box pt={1}>
                <TypographyBlock
                    dense={true}
                    nopadding
                    title={t("common:modal.calendar.ferry")}
                />
                <CustomerSearch
                    fullWidth
                    reg={reg}
                    onChange={setStaffIdentity}
                    staffOnly
                />
            </Box>
        </Stack >
    );
}