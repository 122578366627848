import { useState, useEffect, useCallback } from "react"
import DataTable from "components/Tables/DataTable";
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { useParams } from "react-router-dom";
import { addActions } from "lib/functions/tables";
import { useTranslation } from "react-i18next";
import { Card, Grid } from "@mui/material";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useModal } from "hooks/modalHook";
import _ from "lodash";
import { useRefresher } from "hooks/refreshHook";
import { sepaActions, sepaColumns } from "../tables/sepaTable";

export default function Sepa() {
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    let { alias } = useParams();
    let { addModal } = useModal();
    const { refresher } = useRefresher();

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp && !!resp.data) data = resp.data
                data = data.map((e) => ({ ...e, status: "*" }))
                setTableData({ columns: sepaColumns, rows: addActions(data, sepaActions(addModal, data)) })
            }
            setIsLoading(true)
            await restGet(restCallback, `sepa/${alias}/all?include=identity`)
            setIsLoading(false)
        }
        !!alias && fetch()
    }, [alias, refresher])

    return (
        <>
            <Grid item xs={12} sx={{ marginTop: "24px" }}>
                <Card>
                    {isLoading && <AeroLoading />}
                    {!isLoading && <DataTable canSearch={true} table={tableData} />}
                </Card>
            </Grid>
        </>)
}