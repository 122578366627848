import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Stack } from "@mui/material";
import { restGet } from "lib/rest";
import Input from "components/Input";
import AutoComplete from "components/Input/AutoComplete";
import _ from "lodash";

export default function LogTimesPOBs({ pob, setPob }) {
    const { t } = useTranslation()

    const [duties, setDuties] = useState();

    function validateTmp(g, tmp) {
        let lastElement = tmp[tmp.length - 1];
        if (!!lastElement.name && !!lastElement.duty) {
            tmp.push({ name: null, duty: null })
        }
        if (_.find(tmp, ()=>{return _.isEmpty(tmp.name) && _.isEmpty(tmp.duty)}) && !tmp[g].name && !tmp[g].duty) {
            tmp.splice(g, 1)
        }
        setPob(tmp)
    }

    function setName(g, h) {
        let tmp = [...pob]
        tmp[g].name = h
        validateTmp(g, tmp)
    }

    function setDuty(g, h) {
        let tmp = [...pob]
        tmp[g].duty = h
        validateTmp(g, tmp)
    }

    useEffect(() => {
        restGet(setDuties, "dataset/duty")
    }, []);

    return (
        <Stack>
            {pob.map((p, i) =>
                <div key={"pob-grid-" + i}>
                    {i !== 0 && <Divider />}
                    <Stack direction="row" alignItems="flex-end" spacing={3} key={"pob-grid-" + i}>
                        <Input
                            label={t("common:label.person")}
                            type="text"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) => setName(i, e.target.value)}
                            value={p.name ?? ""}
                            InputLabelProps={{ shrink: !!p.name }}
                        />
                        <AutoComplete
                            sx={{ minWidth: "200px" }}
                            value={p.duty}
                            label={t("common:label.duty")}
                            options={duties ?? []}
                            onChange={(value) => setDuty(i, value)}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(value) => value.name}
                        />
                    </Stack>
                </div>
            )
            }
        </Stack >
    );
}