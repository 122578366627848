import PropTypes from "prop-types";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { getLanguageTextWithFallBack, getBlobImageUrl } from "lib/functions";
import { Stack, Card, Switch, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import _ from "lodash";
import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined } from "@mui/icons-material";
import { restPost } from "lib/rest";

function DashboardShopItemCard({ item, alias }) {
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(item.disabled ?? false);
    const [isLoading, setIsLoading] = useState(false);

    const [previewImageIndex, setPreviewImageIndex] = useState(0);
    var previewImages = _.values(item.image).map((x) => getBlobImageUrl(x))

    const next = () => {
        setPreviewImageIndex(previewImageIndex + 1)
    }
    const prev = () => {
        setPreviewImageIndex(previewImageIndex - 1 < 0 ? previewImages.length - 1 : previewImageIndex - 1)
    }

    async function handleSwitch(e) {
        setIsLoading(true)
        setDisabled(e.target.checked)
        await restPost(null, `shop/${alias}/item/${item._id.$oid}/toggle`)
        setIsLoading(false)
    }

    async function handleDelete(e) {
        setIsLoading(true)
        await restPost(null, `shop/${alias}/item/${item._id.$oid}/delete`)
        setIsLoading(false)
    }

    return (
        <Card style={{ height: "100%", opacity: disabled ? "0.5" : "1" }}>
            <Box position="relative" borderRadius="lg" mx={2} mt={-3}>
                <Box
                    component="img"
                    src={previewImages[previewImageIndex % previewImages.length]}
                    alt={getLanguageTextWithFallBack(item.caption)}
                    borderRadius="lg"
                    shadow=""
                    width="100%"
                    position="relative"
                    zIndex={1}
                />
                <Box
                    borderRadius="lg"
                    shadow=""
                    width="100%"
                    height="100%"
                    position="absolute"
                    left={0}
                    top={0}
                    sx={{
                        backgroundImage: `url(${previewImages[previewImageIndex % previewImages.length]})`,
                        transform: "scale(0.94)",
                        filter: "blur(12px)",
                        backgroundSize: "cover",
                    }}
                />
                {previewImages.length > 1 && (
                    <Box  >
                        <ArrowBackIosNewOutlined
                            color="info"
                            onClick={() => prev()}
                            sx={{ float: "left", position: "absolute", cursor: "pointer", top: "40%", left: 2, cursor: "pointer", zIndex: "100" }}
                        >
                        </ArrowBackIosNewOutlined>
                        <ArrowForwardIosOutlined
                            size="small"
                            color="info"
                            onClick={() => next()}
                            sx={{ float: "right", position: "absolute", cursor: "pointer", top: "40%", right: 2, cursor: "pointer", zIndex: "100" }}
                        >
                        </ArrowForwardIosOutlined>
                    </Box>
                )}
            </Box>
            <Box style={{ height: "100%" }} p={3} mt={-2}>
                <Stack
                    style={{ height: "100%" }}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Typography display="inline" variant="h6" fontWeight="bold">
                        {getLanguageTextWithFallBack(item.caption)}
                    </Typography>

                    <Box mb={1.5} width="100%">
                        <Stack direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Button
                                disabled={isLoading}
                                href={`/my/${alias}/shop/edit/${item._id.$oid}`}
                                variant="outlined"
                                size="small"
                                color="info"
                            >
                                {t("common:button.edit")}
                            </Button>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center">
                                <Typography variant="overline">{t("common:button.disabled")}</Typography>
                                <Switch disabled={isLoading} checked={disabled} onChange={handleSwitch} />

                            </Stack>


                            <Button
                                disabled={isLoading || !disabled}
                                variant="gradient"
                                size="small"
                                color="error"
                                onClick={handleDelete}
                                sx={{ visibility: (!disabled) ? "hidden" : "visible" }}
                            >
                                {t("common:button.delete")}
                            </Button>

                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Card>
    );
}

DashboardShopItemCard.propTypes = {
    item: PropTypes.object.isRequired,
};

export default DashboardShopItemCard;
