import { Divider, Icon, Stack } from "@mui/material";
import Box from "components/Box";
import ChecklistContainerRoot from "./ChecklistContainerRoot";

export default function ChecklistContainer({ children, open, color, title, ...rest }) {

    return (<Box>{children}</Box>)

    return (
        <Stack>
            <ChecklistContainerRoot color={color} {...rest} >
                <Icon sx={{ fontWeight: "bold" }} fontSize="small">
                    {open ? "expand_less" : "expand_more"}
                </Icon>
                <Box
                    alignItems="center"
                    fontSize="1rem"
                    fontWeight="regular"
                    color={color === "light" ? "dark" : "white"}
                >
                    {title}
                </Box>
            </ChecklistContainerRoot>
            {open &&
                <Box >
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    {children}
                </Box>
            }
        </Stack>
    );
}