import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Typography from "components/Typography";
import { useEffect, useState } from "react";
import { getLanguageTextWithFallBack } from "lib/functions";
import DefaultAircraftCard from "components/Cards/AircraftCards/DefaultAircraftCard/DefaultAircraftCard";
import { restPost } from "lib/rest";


function Recommandations() {
    const { t } = useTranslation();
    const [aircrafts, setAircrafts] = useState();

    const [aircraftFetchData, setAircraftFetchData] = useState();
    useEffect(() => {
        restPost(setAircraftFetchData, 'recommendation/_aircraft', { limit: 6 });
    }, []);

    useEffect(() => {
        setAircrafts(!!aircraftFetchData && aircraftFetchData.data)
    }, [aircraftFetchData]);

    return (
        <Box component="section" py={3} id="recommendation">
            <Container>
                <Grid
                    container
                    item
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={10}
                    sx={{ mx: "auto", textAlign: "center" }}
                >

                    <Typography variant="h2" mt={3}>
                        {t("landingPage:recommandations.title")}
                    </Typography>

                </Grid>
                <Grid container spacing={3} sx={{ mt: 3 }}
                    alignItems="stretch">
                    {!!aircrafts && aircrafts.map((x, i) =>
                        <Grid item xs={12} md={6} lg={4} key={"aircraft-card-" + i} >
                            <Box mt={3} style={{ height: "100%", paddingBottom: "24px" }}>
                                <DefaultAircraftCard
                                    image={x.image}
                                    title={x.name + " (" + x.reg + ")"}
                                    description={getLanguageTextWithFallBack(x.description)}
                                    categories={[x.homebase, x.certification, x.class, x.type]}
                                    aircraft={x}
                                />
                            </Box>
                        </Grid>)
                    }
                </Grid>
            </Container>
        </Box>
    );
}

export default Recommandations;
