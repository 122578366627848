import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Typography from "components/Typography";
import { useMaterialUIController } from "context";

function DefaultStatisticsCard({ title, count, percentage, toggle }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card>
      <Box p={2}>
        <Grid container>
          <Grid item xs={7}>
            <Box mb={0.5} lineHeight={1}>
              <Typography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                {title}
              </Typography>
            </Box>
            <Box lineHeight={1}>
              <Typography variant="h5" fontWeight="bold">
                {count}
              </Typography>
              <Typography variant="button" fontWeight="bold" color={percentage.color}>
                {percentage.value}&nbsp;
                <Typography
                  variant="button"
                  fontWeight="regular"
                  color={darkMode ? "text" : "secondary"}
                >
                  {percentage.label}
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {toggle && (
              <Box width="100%" textAlign="right" lineHeight={1}>
                {/* TODO Add Toggle */}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
DefaultStatisticsCard.defaultProps = {
  percentage: {
    color: "success",
    value: "",
    label: "",
  },
  toggle: false,
};

// Typechecking props for the DefaultStatisticsCard
DefaultStatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  toggle: PropTypes.bool
};

export default DefaultStatisticsCard;
