import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "components/Box";
import { useTranslation } from "react-i18next";

function ErrorPageContent() {
    const { t } = useTranslation()
    return (
        <Box py={6} >
            <Container>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h1" >
                        {t("errorPage:title")}
                    </Typography>
                    <Typography variant="h2">
                        {t("errorPage:subtitle")}
                    </Typography>
                    <Typography variant="h4" >
                        {t("errorPage:description")}
                    </Typography>
                </Stack>
            </Container>
        </Box >
    );
}

export default ErrorPageContent;