import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Input from "components/Input";
import Typography from "components/Typography";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CharterLegalEntity from "components/Charter/CharterLegalEntity"

function NameStep({ setData }) {
  const delay = 500;
  const { t } = useTranslation();

  //Alias Check
  const [alias, setAlias] = useState();
  const [aliasCheck, setAliasCheck] = useState(false)
  const [aliasLoading, setAliasLoading] = useState(false)

  const [legalEntityCheck, setLegalEntityCheck] = useState(false)
  const [legalEntity, setLegalEntity] = useState()

  useEffect(() => {
    setAliasLoading(true)
    setAliasCheck(false)
    const handler = setTimeout(async () => {
      if (!!alias) {
        //Check Regex
        const re = /^[a-z\-]{4,}$/g;
        const found = alias.match(re);
        if (!!found) {
          //Check duplicate
          await restGet((res) => setAliasCheck(!res), 'charter/' + alias)
        } else {
          setAliasCheck(false)
        }
      }
      setAliasLoading(false)
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [alias])

  //Name Check
  const [name, setName] = useState();
  const [nameCheck, setNameCheck] = useState(false)
  const [nameLoading, setNameLoading] = useState(false)
  useEffect(() => {
    setNameLoading(true)
    setNameCheck(false)
    const handler = setTimeout(() => {
      if (!!name) {
        //Check Regex
        const re = /^[a-zA-ZÀ-ž\u0370-\u03FF\u0400-\u04FF\- ]{4,}$/g;
        const found = name.match(re);
        setNameCheck(!!found)
      }
      setNameLoading(false)
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [name])

  //Send data to parent if checks pass
  useEffect(() => {
    if (!aliasLoading && !nameLoading) {
      setData({ alias, name, legalEntity, aliasValid: aliasCheck, nameValid: nameCheck, legalEntityValid: legalEntityCheck })
    }
  }, [aliasCheck, nameCheck, aliasLoading, nameLoading, legalEntityCheck])

  useEffect(() => {
    setLegalEntityCheck(!!legalEntity?.legal_entity && !!legalEntity?.currency)
  }, [legalEntity])

  return (
    <Box>
      <Box width="82%" textAlign="center" mx="auto" my={4}>
        <Box mb={1}>
          <Typography variant="h5" fontWeight="regular">
            {t("dashboard:organization.create.name.title")}
          </Typography>
        </Box>
        <Typography variant="body2" color="text">
          {t("dashboard:organization.create.name.description")}
        </Typography>
      </Box>
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <Input
                error={alias && !aliasLoading && !aliasCheck}
                success={!aliasLoading && aliasCheck}
                label={t("dashboard:organization.create.name.alias")}
                helperText={t("dashboard:organization.create.name.alias_helper")}
                type="text"
                variant="outlined"
                margin="dense"
                fullWidth
                onChange={(e) => setAlias(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <Input
                error={name && !nameLoading && !nameCheck}
                success={!nameLoading && nameCheck}
                label={t("dashboard:organization.create.name.name")}
                helperText={t("dashboard:organization.create.name.name_helper")}
                type="text"
                variant="outlined"
                margin="dense"
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
          </Grid>

          <Grid item xs={12} >
            <CharterLegalEntity setLegalEntity={setLegalEntity} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default NameStep;