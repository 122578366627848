import { Grid, Stack } from "@mui/material";
import Box from "components/Box";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import ShopItemCard from "components/Cards/ShopItemCard/ShopItemCard";

export default function PreviewTab({ previewImage, defaultData, currency, charter }) {
    const { t } = useTranslation()
    return (
        <Box p={3}>
            <Stack >
                <TypographyBlock
                    title={t("common:label.preview")}
                />
                <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                        <ShopItemCard charter={charter} item={{...defaultData, image: previewImage}}  currency={currency} />
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}