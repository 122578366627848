import React from 'react'
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { Grid, Stack, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import ReactMarkdown from "react-markdown";
import TypographyBlock from "components/Typography/TypographyBlock";
import Box from "components/Box";
import Input from "components/Input";
import _ from "lodash";
import { restPost } from 'lib/rest';
import { useUser } from 'hooks/userDataHook';

const ReqCheckModal = ({ reg, awaitActions }) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState("");
    const [acceptPP, setAcceptPP] = useState(false);
    const { refreshUserData } = useUser();

    async function saveComment() {

        //await restPost(null, `checkride/new/email`, {  message: comment  })

        await restPost(null, `aircraft/${reg}/checkride/`, { message: comment })
        await refreshUserData()
        await awaitActions()

    }



    return (
        <DefaultModal
            title={"Request Checkride"}
            confirm={{
                text: t("common:button.confirm"),
                color: "success",
                show: acceptPP,
                action: () => { saveComment(); }

            }}>

            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={3}>
                        <Grid mt={1} container spacing={2}>


                            <Grid item xs={12} p={3}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={"Nachricht and den Eigentümer"}
                                    />
                                    <Box mt={-1} width="100%">
                                        <Input
                                            type="text"
                                            multiline
                                            rows={4}
                                            value={comment}
                                            onChange={(event) => setComment(event.target.value)}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={acceptPP} onChange={(e) => setAcceptPP(!acceptPP)} />}
                                        label={<ReactMarkdown >{t('common:optin.optinPrivacy') + " " + t('common:optin.optinGTC')}</ReactMarkdown>}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>

                    </Stack>
                </Grid>
            </Grid>



        </DefaultModal>
    )
}

export default ReqCheckModal