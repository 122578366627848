/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Box from "components/Box";
import Typography from "components/Typography";
import { useMaterialUIController } from "context";
import { TimelineProvider } from "../context";
import { CardContent } from "@mui/material";

function TimelineList({ title, dark, children, noBackground }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <TimelineProvider value={dark}>
      <CardContent>
        <Box
          bgColor={noBackground ? "none" : dark ? "dark" : "white"}
          variant="gradient"
          borderRadius="xl"
          sx={{ background: ({ palette: { background } }) => noBackground ? "none" : darkMode && background.card }}
        >
          <Box pt={3} px={3}>
            <Typography variant="h6" fontWeight="medium" color={dark ? "white" : "dark"}>
              {title}
            </Typography>
          </Box>
          <Box p={2}>{children}</Box>
        </Box>
      </CardContent>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
  noBackground: PropTypes.bool
};

export default TimelineList;
