/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { Stack } from "@mui/system";
import colors from "assets/theme/base/colors";

function DefaultPricingCard({ color, badge, price, specifications, action, shadow }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <Box key={label} display="flex" alignItems="center" p={1}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        mr={2}
        mt={-0.125}
      >
        <Typography
          variant="body1"
          color={color === "white" ? "text" : "white"}
          sx={{ lineHeight: 0 }}
        >
          <Icon>{includes === undefined ? "" : includes ? "done" : "remove"}</Icon>
        </Typography>
      </Box>
      <Typography
        variant="body2"
        color={color === "white" ? "text" : "white"}
        fontWeight="regular"
      >
        {label}
      </Typography>
    </Box>
  ));

  return (
    < Card sx={{ boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none") }
    }>


      {< Box
        width="150px"
        height="150px"
        overflow="hidden"
        position="absolute"
        top="-10px"
        right="-10px"
        zIndex="10"
        sx={{
          "::before": {
            left: "49px",
            top: "0",
            position: "absolute",
            zIndex: "-1",
            content: `''`,
            display: "block",
            border: "5px solid #f65f53"
          },
          "::after": {
            bottom: "49px",
            right: "0",
            position: "absolute",
            zIndex: "-1",
            content: `''`,
            display: "block",
            border: "5px solid #f65f53"
          }
        }}
      >
        <Box
          position="absolute"
          display="block"
          width="276px"
          px="0"
          py="8px"
          left="-25px"
          top="30px"
          sx={{
            transform: "rotate(45deg)",
            boxShadow: "0 5px 10px rgba(0,0,0,0.15)",
            "::before": {
              borderTopColor: "transparent",
              borderRightColor: "transparent",
              top: "0",
              left: "0"
            },
            "::after": {
              borderTopColor: "transparent",
              borderRightColor: "transparent",
              bottom: "0",
              right: "0"
            }
          }}
          backgroundColor={colors.primary.main + " !important"}
          boxShadow="0 5px 10px rgba(0,0,0,.1)"
          textAlign="center"
        ><Typography variant="h6" lineHeight={0.75}>Limited offer</Typography></Box>
      </Box >}
      <Box
        bgColor={color}
        variant={color === "white" ? "contained" : "gradient"}
        borderRadius="xl"
      >
        <Box
          bgColor={badge.color}
          width="max-content"
          px={4}
          pt={0}
          pb={0.5}
          mx="auto"
          mt={-1.375}
          borderRadius="section"
          lineHeight={1}
          zIndex={11}
        >
          <Typography
            variant="caption"
            textTransform="uppercase"
            fontWeight="medium"
            color={badge.color === "light" ? "dark" : "white"}
          >
            {badge.label}
          </Typography>
        </Box>
        <Box pt={3} pb={2} px={2} textAlign="center">
          <Stack my={1} direction="row"
            justifyContent="center">
            <Typography
              display="inline"
              component="small"
              variant="h5"
              color={color === "white" ? "dark" : "white"}
              verticalAlign="top"
            >
              {price.currency}
            </Typography>
            <Typography variant="h1" color={color === "white" ? "dark" : "white"} sx={{ textDecorationLine: 'line-through' }}>
              {price.oldValue}
            </Typography>
            <Typography
              display="inline"
              component="small"
              variant="h5"
              color={color === "white" ? "dark" : "white"}
              lineHeight="5rem">
              /{price.type}
            </Typography>
          </Stack>
          <Stack my={1} direction="row"
            justifyContent="center">
            <Typography
              display="inline"
              component="small"
              variant="h5"
              color={color === "white" ? "dark" : "white"}
              verticalAlign="top"
            >
              {price.currency}
            </Typography>
            <Typography variant="h1" color={color === "white" ? "dark" : "white"}>
              {price.value}
            </Typography>
            <Typography
              display="inline"
              component="small"
              variant="h5"
              color={color === "white" ? "dark" : "white"}
              lineHeight="5rem">
              /{price.type}
            </Typography>
          </Stack>
        </Box>
        <Box pb={3} px={3}>
          {renderSpecifications}
          {action.type === "internal" ? (
            <Box mt={3}>
              <Button
                component={Link}
                to={action.route}
                variant="gradient"
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </Button>
            </Box>
          ) : (
            <Box mt={3}>
              <Button
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Card >
  );
}

// Setting default props for the DefaultPricingCard
DefaultPricingCard.defaultProps = {
  color: "white",
  shadow: true,
};

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default DefaultPricingCard;
