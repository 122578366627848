import { useEffect, useState } from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import { restGet } from "lib/rest"
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Card, Grid, Stack } from "@mui/material";
import StarRating from "components/Rating/StarRating";
import CharterTabs from "./sections/CharterTabs";
import AeroLoading from "components/Progress/AeroLoading"
import { DocumentTitle } from "hooks/useDocumentTitle";
import { useModal } from "hooks/modalHook";
import CertificateModal from "components/Modal/CertificateModal";
import LoadingModal from "components/Modal/LoadingModal";
import { useUser } from "hooks/userDataHook";
import Button from "components/Button";
import { restPost } from "lib/rest";
import NewsletterChartermodal from "components/Modal/NewsletterCharterModal";
import CheckoutSuccessModal from "components/Modal/CheckoutSuccessModal";
import ContactSupportModal from "components/Modal/ContactSupportModal";

export default function Body() {
    const [charter, setCharter] = useState();
    const { t } = useTranslation();
    const { ALIAS } = useParams();
    const { addModal, removeModal } = useModal();
    const [isloading, setIsLoading] = useState(false)
    const [subscribe, setSubscribe] = useState(false)
    const [subscribetxt, setSubscribetxt] = useState('subscribe')
    const [color, setColor] = useState('primary')
    const userData = useUser();
    const [newsletter, setNewsletter] = useState()

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setCharter(resp.data[0])
            }
            setIsLoading(true)
            await restGet(restCallback, 'charter/' + ALIAS)
            setIsLoading(false)
        }
        !!ALIAS && fetch()
    }, [ALIAS])

    const [title, setTitle] = useState(t("routes:charter.title"))

    useEffect(() => {
        !!charter && !!charter.legal_info && setTitle(t("routes:charter.title") + " " + charter.legal_info.chartername)
    }, [charter])

    useEffect(() => {

        if (userData?.data) {

            const fetch = async () => {
                function restCallback(resp) {
                    if (resp?.data[0]?.chartername == ALIAS) {

                        !!resp && !!resp.data && setNewsletter(resp.data[0])
                        !!resp && !!resp.data && !!resp.data.subscribe && setSubscribe(resp.data[0].subscribe)
                        if (!!resp?.data[0]?.subscribe == true) {
                            setColor('light')
                            setSubscribetxt('subscribed')
                        }
                    }
                }

                await restGet(restCallback, 'newsletter/' + userData.data.email + '/email/charter_' + ALIAS)

            }
            !!userData.data && fetch()

        }
        else {

            if (JSON.parse(localStorage.getItem(ALIAS)) || false) {
                setColor('light')
                setSubscribetxt('subscribed')
            }

        }
    }, [userData.data])

    async function validateID(count) {
        const query = new URLSearchParams(window.location.search);
        if (count >= 3) {
            //TODO addModal contact support
            removeModal()
            var params = {};
            if (query.get("voucher_id")) params['voucher_id'] = query.get("voucher_id");
            if (query.get("enrollment_id")) params['enrollment_id'] = query.get("enrollment_id");
            addModal(<ContactSupportModal params={params} />)
            return;;
        }
        if (query.get("success") !== "true") return;;
        addModal(<LoadingModal />)

        if (query.has("voucher_id")) {
            var resp = await restGet(null, 'voucher/' + query.get("voucher_id") + '/validate')
        }
        else if (query.has("enrollment_id")) {
            var resp = await restGet(null, 'enrollment/' + query.get("enrollment_id") + '/validate')
        }
        else {
            return;;
        }
        if (!!resp && !!resp.data && !!resp.data[0]) {
            if (resp.data[0].status === "paid") {
                removeModal()
                addModal(<CertificateModal data={resp.data[0]} />)
            } else {
                await validateID(count + 1)
            }
        } else {
            removeModal()
        }
    }
    //Open CertificateModal if url has voucher_id
    useEffect(async () => {
        await validateID(0)
    }, []);

    if (!charter || isloading) {
        return isloading ? <AeroLoading /> : null;
    }

    async function saveEmail() {
        var object = { Alias: ALIAS, type: 'charter', subscribe: !subscribe, email: userData.data.email, notify: userData.data.notify, type: 2 }
        if (!!newsletter?._id) {

            var object = { Alias: ALIAS, type: 'charter', subscribe: !subscribe, email: userData.data.email, id: newsletter._id.$oid, notify: userData.data.notify, type: 2 }
        }
        await restPost(null, `newsletter/Newsletter`, object)


    }


    const onSubscribe = (color) => {

        if (userData.data != "") {
            if (!subscribe == true) {


                if (!!newsletter?._id) {

                    addModal(<NewsletterChartermodal charter={charter._id} alias={ALIAS} subscribe={!subscribe} type={"charter"} userdata={true} emails={userData.data.email} id={newsletter._id.$oid} code={2} notify={userData.data.notify}></NewsletterChartermodal>)
                } else {
                    addModal(<NewsletterChartermodal charter={charter._id} alias={ALIAS} subscribe={!subscribe} type={"charter"} userdata={true} emails={userData.data.email} code={2} notify={userData.data.notify}></NewsletterChartermodal>)

                }
                setColor('light')
                setSubscribetxt('subscribed')
                setSubscribe(!subscribe)


            }
            else {

                setColor('primary')
                setSubscribetxt('subscribe')
                setSubscribe(!subscribe)
                saveEmail();

            }
        }
        else {

            addModal(<NewsletterChartermodal charter={charter._id} alias={ALIAS} type={"charter"} userdata={false}></NewsletterChartermodal>)


        }

        /*     else {
                if (JSON.parse(localStorage.getItem(ALIAS)) == false || JSON.parse(localStorage.getItem(ALIAS)) == undefined) {
    
                    addModal(<NewsletterChartermodal charter={charter._id} alias={ALIAS} type={"charter"} userdata={false}></NewsletterChartermodal>)
                    setColor('light')
                    setSubscribetxt('subscribed')
    
                }
                localStorage.setItem(ALIAS, JSON.stringify(!JSON.parse(localStorage.getItem(ALIAS))));
    
                if (JSON.parse(localStorage.getItem(ALIAS)) == false) {
                    setColor('primary')
                    setSubscribetxt('subscribe')
                    localStorage.removeItem(ALIAS);
                    //delete from database
                }
    
    
    
            } */
    };




    return (
        <Box p={3}>
            <DocumentTitle title={title} />
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Card sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
                        <Box variant="gradient" bgColor="dark" height="100%">
                            <Stack p={3} lineHeight={1} direction="row" justifyContent="space-between">

                                <Box >
                                    <Typography variant="h5" fontWeight="bold" color="white" mb={0.5}>
                                        {charter.legal_info.chartername}
                                    </Typography>
                                    {!!charter?.address &&
                                        <>
                                            <Typography color="white" >
                                                {charter?.address[0]}
                                            </Typography>
                                            <Typography color="white" >
                                                {charter?.address[1]}
                                            </Typography>
                                            <Typography color="white" >
                                                {charter?.address[2]}
                                            </Typography>
                                            <Typography color="white" >
                                                {charter?.address[3]}
                                            </Typography>
                                            <Typography color="white" >
                                                {charter?.address[4]}
                                            </Typography></>
                                    }

                                    <StarRating text={t('common:label.recension', { count: charter?.ratings_stat?.rating_cnt ?? 0 })} rating={charter?.ratings_stat?.avg_rating ?? 0} color="white" />
                                </Box>
                                <Box>
                                    <Button
                                        variant="gradient"
                                        color={color}
                                        onClick={onSubscribe}
                                    >
                                        {/* on subscribe add bell icon and change color to light */}
                                        {subscribetxt}
                                    </Button>
                                </Box>
                            </Stack>

                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Box mt={8} mb={2}>
                <Box mb={1} ml={2}>
                    <CharterTabs charter={charter} />
                </Box>
            </Box>
        </Box>
    )
}