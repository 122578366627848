/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";

function HorizontalTeamCard({ image, name, position, description, flight_cert }) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -6 }}>
          <Box position="relative" width="100%" pt={2} pb={1} px={2}>
            <Box
              component="img"
              src={image}
              alt={name}
              position="relative"
              zIndex={1}
              width="100%"
            />
            <Box
              width="100%"
              height="100%"
              position="absolute"
              left={0}
              top={0}
              sx={{
                backgroundImage: `url(${image})`,
                transform: "scale(0.8)",
                filter: "blur(12px)",
                backgroundSize: "cover",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <Box pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <Typography variant="h5">{name}</Typography>
            <Typography variant="h6" color={position.color} >
              {position.label}
            </Typography>
            {!!flight_cert && <Typography variant="button" color="primary" mb={1}>
              {flight_cert}
            </Typography>
            }
            <Typography variant="body2" color="text" mt={1}>
              {description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
