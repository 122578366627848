import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import ReactMarkdown from "react-markdown";
import { getDocumentFromUrl } from "lib/functions";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import _ from "lodash"
/* import PayPalButton from "components/Button/PayPalButton"; */
import { CreditCard } from "@mui/icons-material";
import { useModal } from "hooks/modalHook";
import LoadingModal from "./LoadingModal";
import * as stripeJs from "@stripe/stripe-js";
import { restPost } from "lib/rest";
import moment from "moment";
import { __STRIPE_PUBLISHABLE_KEY__ } from 'lib/settings/settings';
import { getBlobImageUrl } from "lib/functions";
import { getBlobFromPdfString } from "lib/functions";
import ContactSupportModal from "./ContactSupportModal";
import { restGet } from "lib/rest";


function ShopCheckoutModal({ item, charter }) {
    const { t } = useTranslation();
    const { addModal, removeModal } = useModal();
    const [charterGTC, setCharterGTC] = useState("");
    const [validationError, setValidationError] = useState(true)
    const [optin, setOptin] = useState({ clientGTC: false, privacy: false, cancellation: false })
    const [markdown, setMarkdown] = useState()
    const [pdf, setPdf] = useState()
    const [warnings, setWarnings] = useState();

    useEffect(() => {
        if (!!charter) {
            getDocumentFromUrl((text) => { setCharterGTC(text) }, "https://media.westeurope-test.aero-services.net/5f57a7e4e6fd29d3a6b0862794720770/shop/legal/voucher-agb.md")

        }
        if (!!charter?.legal_documents?.document) {

            if (charter?.legal_documents?.filetype === "md") {

                fetchMarkdownFromUrl(getBlobImageUrl(charter.legal_documents.document))

            } else if (charter?.legal_documents?.filetype === "pdf") {
                fetchPdfFromUrl(charter.legal_documents.document);




            }

        }
    }, [charter])


    useEffect(() => {
        setValidationError(_.some(optin, _.negate(Boolean)))
    }, [optin])
    const fetchPdfFromUrl = async (url) => {
        try {
            const response = await fetch(getBlobImageUrl(charter.legal_documents.document));
            if (response.ok) {
                const PdfText = await response.text();
                setPdf(window.URL.createObjectURL(getBlobFromPdfString(PdfText)))
            } else {
                console.error('err');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchMarkdownFromUrl = async (url) => {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const markdownText = await response.text();
                setMarkdown(markdownText);
            } else {
                console.error('err');
            }
        } catch (error) {
            console.error(error);
        }
    };

    function handleChange(event) {
        let tmp = { ...optin }
        tmp[event.target.name] = !tmp[event.target.name]
        setOptin(tmp)
    }
    async function handleButtonClick() {
        if (validationError === false) {
            addModal(<LoadingModal />)
            const stripePromise = await stripeJs.loadStripe(__STRIPE_PUBLISHABLE_KEY__[charter.legal_entity]);
            let loc = window.location.href



            if (loc.includes("/shop/min")) {
                loc = loc.replace("/shop/min", "/");
            }            
            if (loc.includes("public/")) {
                loc = loc.replace("public/", "/");
                loc = loc.replace("/shop", "");
                loc = loc.replace("/courses", "");
            }
            if (loc.includes("charter/")) {
                loc = loc.replace("charter/", "org/");
            }

            const itemData = {
                callback: loc,
                itemId: item._id.$oid,
                variantId: item.variantId,
                lang: moment.locale()
            }


            const accountEnabled = await restGet((res) => setWarnings(res[0]), `account/${charter.account_id}/enabled/${charter.alias}`);

            const session = await restPost(null, `shop/${charter.alias}/checkout/${item.item_type}?lang=${moment.locale()}`, itemData)

            if (!!session && !!stripePromise && accountEnabled.data) {
                const result = await stripePromise.redirectToCheckout({
                    sessionId: session.id,
                });

                if (result.error) { console.log(result.error) }
            } else {
                const err = {
                    alias: charter.alias,
                    item: item.item_type,
                    message: "Checkout Error"
                }
                addModal(<ContactSupportModal params={err}></ContactSupportModal>)
            }
        }
    }

    return (
        <DefaultModal
            title={""}
            cancel={{ show: true, text: t("common:button.cancel"), color: "error" }}
            confirm={{ show: false }}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
            >
                <Grid xs={12} item>
                    <Box p={3} lineHeight={1} sx={{ borderStyle: 'ridge', borderColor: 'dark' }}>

                        {!!markdown || !!pdf ? (
                            <>
                                {markdown && (<Box p={3} lineHeight={1} sx={{ maxHeight: '25vh', overflow: 'auto' }}>
                                    <ReactMarkdown>{markdown}</ReactMarkdown>
                                </Box>)}
                                {pdf && (<iframe
                                    style={{ width: "100%", maxHeight: "25vh", overflow: 'auto' }}
                                    src={pdf ?? ''}
                                >
                                </iframe>)}
                            </>
                        ) : (
                            <>

                                <Box p={3} lineHeight={1} sx={{ maxHeight: '25vh', overflow: 'auto' }}>
                                    <ReactMarkdown>{charterGTC}</ReactMarkdown>
                                </Box>

                            </>
                        )}
                        <FormControlLabel
                            control={<Checkbox checked={optin.clientGTC} onChange={(e) => handleChange(e)} name='clientGTC' />}
                            label={t('common:optin.optinClient')}
                        />
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <FormControlLabel
                        labelPlacement="end"
                        control={<Checkbox checked={optin.privacy} onChange={(e) => handleChange(e)} name='privacy' />}
                        label={<ReactMarkdown >{t('common:optin.optinPrivacy') + " " + t('common:optin.optinGTC')}</ReactMarkdown>}
                    />

                    <FormControlLabel
                        control={<Checkbox checked={optin.cancellation} onChange={(e) => handleChange(e)} name='cancellation' />}
                        label={<ReactMarkdown >{t('common:optin.optinCancellation')}</ReactMarkdown>}
                    />
                    {validationError &&
                        <Typography
                            variant="overline"
                            color="error"
                        >
                            {t('charterPage:shoptab.validate')}
                        </Typography>
                    }
                </Grid>
                <Grid xs={12} item>
                </Grid>
                <Grid xs={12} item container
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}>
                    <Grid xs={12} sm={6} item>
                        <Button
                            style={{ height: "45px" }}
                            disabled={validationError}
                            variant='contained'
                            color='success'
                            onClick={() => handleButtonClick()}
                            fullWidth
                            name='stripe'
                        >
                            <CreditCard />
                            {t('charterPage:shoptab.order')}
                        </Button>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        {
                            /*
                            <PayPalButton disabled={validationError} charter={charter} checkoutItem={item} totalAmount={item.variant.price.toFixed(2)} />
                            */
                        }
                    </Grid>
                </Grid>
            </Grid>
        </DefaultModal >
    );
}

export default ShopCheckoutModal;
