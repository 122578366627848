import Icon from "@mui/material/Icon";
import Box from "components/Box";
import Typography from "components/Typography";

function Front({ color, image, icon, title }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      borderRadius="lg"
      coloredShadow={color}
      width="100%"
      height="100%"
      position="relative"
      zIndex={2}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
          )}, url(${image})`,
        backgroundSize: "cover",
        backfaceVisibility: "hidden",
      }}
    >
      <Box py={12} px={4} textAlign="center" lineHeight={1}>
        {icon && (
          <Typography variant="h2" color="white" my={2}>
            {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
          </Typography>
        )}
        <Typography variant="h3" color="white" gutterBottom>
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export default Front;
