import React, { useState, useContext, createContext, useCallback } from 'react';
import { useEffect } from 'react';
import { restGet } from 'lib/rest';
import Auth from "lib/auth"
import routes from 'constants/routes.dashboard';
import { restPost } from 'lib/rest';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [data, setData] = useState()
    const [timeout, setCurrentTimeout] = useState();

    function keepLoggedIn() {
        setCurrentTimeout(
            setTimeout(() => {
                Auth.refreshTokenAsync();
                keepLoggedIn();
            }, 150000) //900000)
        );
    }

    async function refreshUserData() {
        return await restGet(setData, 'identity');
    }

    useEffect(() => {
        keepLoggedIn()
        refreshUserData()
        return clearTimeout(timeout);
    }, [])

    async function isAuthorized(reg) {
        var tmp = data;
        if (!tmp) {
            tmp = await refreshUserData()
        }
        return (
            !!tmp &&
            !!tmp.authorized_ac &&
            !!tmp.authorized_ac[reg]
        )
    }
    
    async function isAuthorizedAlias(alias) {
        var tmp = data;
        if (!tmp) {
            tmp = await refreshUserData()
        }
        if (!!tmp && !!tmp.memberOf) {
            return tmp.memberOf[alias]
        }
        return null
    }


    async function hasRequestStatus(reg) {
        const res = await restGet(null, `aircraft/${reg}/checkride/`)
        if (!!res && !!res.data && !!res.data[0] && res.data[0].status)
            return res.data[0].status
        return false
    }
    async function isVerified() {
        var resp = await restPost(null, 'identity/_self/verification')

        if (resp?.status === "verified")
            return true;
        return resp?.url
    }
    async function isSignedIn() {
        let res = await Auth.refreshTokenAsync()
        refreshUserData()
        return res
    }
    async function signedInRequired(callback) {
        let res = await Auth.refreshTokenOrLogin(callback)
        refreshUserData()
        return res
    }

    function signIn() {
        return Auth.login()
    }

    function signOut() {
        clearTimeout(timeout);
        return Auth.logout()
    }

    const getRole = useCallback((alias) => {
        if (!!data && !!data.memberOf) {
            return data.memberOf[alias]
        }
        return null
    }, [data])

    const getDashboardRoutesAsync = useCallback(async () => {
        let userRoutes = []
        userRoutes.push(routes[0])
        userRoutes.push(routes[1])
        userRoutes.push(routes[2])
        if (!!data && !!data.memberOf) {
            let tmpt = Object.keys(data.memberOf);
            for (const alias of tmpt) {
                var charterResp = await restGet(null, `charter/${alias}`);
                var charter = charterResp?.data[0];
                let tmp = { ...routes[3] }
                tmp.name = alias
                tmp.key = "charter-" + alias
                tmp.collapse = await Promise.all(tmp.collapse.map(async (col) => {
                    if (col.key === "shop" && !charter?.general_setting?.shop_module) {
                        return;;
                    }
                    if (col.key === "ato" && !charter?.general_setting?.ato_module) {
                        return;;
                    }
                    let tmpCol = { ...col }
                    tmpCol.route = tmpCol.route.replace(":alias", alias)
                    return tmpCol
                }));
                tmp.collapse = _.compact(tmp.collapse);
                userRoutes.push(tmp)
            }
        }
        userRoutes.push(routes[4])
        return userRoutes
    }, [data])

    const getDashboardRoutes = useCallback(() => {
        let userRoutes = []
        userRoutes.push(routes[0])
        userRoutes.push(routes[1])
        userRoutes.push(routes[2])
        if (!!data && !!data.memberOf) {
            let tmpt = Object.keys(data.memberOf);
            tmpt.forEach((charter) => {
                let tmp = { ...routes[3] }
                tmp.name = charter
                tmp.key = "charter-" + charter
                tmp.collapse = tmp.collapse.map((col) => {
                    let tmpCol = { ...col }
                    tmpCol.route = tmpCol.route.replace(":alias", charter)
                    return tmpCol
                })
                userRoutes.push(tmp)
            })
        }
        userRoutes.push(routes[4])
        return userRoutes
    }, [data])

    return (
        <UserContext.Provider value={{ data, getRole, hasRequestStatus, isAuthorized, isAuthorizedAlias, isVerified, isSignedIn, signIn, signOut, refreshUserData, signedInRequired, getDashboardRoutes, getDashboardRoutesAsync }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => useContext(UserContext);