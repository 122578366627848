import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Box from "components/Box";
import Typography from "components/Typography";
import Badge from "components/Badge";
import DefaultActions from "components/Cards/AircraftCards/DefaultAircraftCard/DefaultActions";
import { useEffect, useState } from "react";
import { getLanguageTextWithFallBack } from "lib/functions";
import { useTranslation } from "react-i18next";
import StarRating from "components/Rating/StarRating";
import Accordion from "components/Accordion";
import AircraftEquipment from "../components/AircraftEquipment";
import { legalFromAircraft, formatCurrency } from "lib/legalInfo";




function ProductInfo({ aircraft }) {
    const { t } = useTranslation()

    const [categories, setCategories] = useState([]);
    const [charterRate, setCharterRate] = useState();
    const [aircraftRating, setAircraftRating] = useState();
    const [currencyicon, setCurrencyIcon] = useState();
    const [currencyname, setCurrencyName] = useState();
    const [legalEntity, setLegalEntity] = useState();

    useEffect(() => {
        if (!!aircraft) {
            var cat = []
            !!aircraft.homebase && cat.push(aircraft.homebase)
            !!aircraft.certification && cat.push(aircraft.certification)
            !!aircraft.class && cat.push(aircraft.class)
            !!aircraft.type && cat.push(aircraft.type)
            setCategories(cat)
            setCharterRate(getAircraftCharterRate())
            !!aircraft.ratings_stat && setAircraftRating(aircraft.ratings_stat.find(({ _id }) => _id === "overall"))
            !!aircraft && legalFromAircraft(aircraft).then(function (result) {
                setLegalEntity(result.legalEntity)
                setCurrencyIcon(result.currencyIcon);
                setCurrencyName(result.currency)

            })
        }
    }, [aircraft])

    function getAircraftCharterRate() {
        if (!!aircraft.rates) {
            var res = _.find(aircraft.rates, { 'flight_type': 'charter_self' })
            if (!!res && !!res.unit_amount) {
                return res.unit_amount
            }
        }
        return undefined;
    }

    if (!!aircraft)
        return (
            <Box>
                <Box mb={1}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}>
                        <Typography variant="h3" fontWeight="bold">
                            {aircraft.name}
                        </Typography>
                        <Tooltip title={t('aircraftPage:info.verified')} placement="top">
                            <Typography variant="h3" fontWeight="bold">
                                <Icon>verified</Icon>
                            </Typography>
                        </Tooltip>
                    </Stack>
                    <Typography variant="h5">
                        {aircraft.reg}
                    </Typography>
                </Box>
                <StarRating text={t('common:label.recension', { count: aircraftRating?.rating_cnt ?? 0 })} rating={aircraftRating?.avg_rating ?? 0} />
                <Box mt={1}>
                    <Typography >
                        {aircraft?.operational_data_setting?.wet_or_dry?.label ? (

                            <Typography variant="h6" fontWeight="medium"> {t('common:label.charterPrice')} / {aircraft.operational_data_setting.wet_or_dry.label}</Typography>
                        ) : (

                            <Typography variant="h6" fontWeight="medium"> {t('common:label.charterPrice')}</Typography>

                        )}
                    </Typography>
                </Box>
                {!!charterRate ?
                    < Box my={1}>
                        <Typography variant="h1" color="dark">
                            <Typography
                                display="inline"
                                component="small"
                                variant="h5"
                                color="inherit"
                                verticalAlign="top"
                            >

                            </Typography>
                            {formatCurrency(charterRate * 60 / 100, currencyname)}

                            <Typography display="inline" component="small" variant="h5" color="inherit">
                                / {legalEntity === "aeromate.us" ? t("common:label.exclusiv_tax") : t("common:label.inclusiv_vat")}
                            </Typography>
                        </Typography>
                    </Box>
                    :
                    < Box my={1}>
                        <Typography variant="h5" color="dark">
                            {t("common:label.onRequest")}
                        </Typography>
                    </Box>
                }

                {
                    categories.length > 0 &&
                    categories.map((category, i) => (
                        <Badge key={"product-badge-" + i} variant="contained" color="success" badgeContent={category} container style={{ marginRight: '8px' }} />
                    ))
                }

                {

                    aircraft?.operational_data_setting.wet_or_dry?.label && <Badge key={"wet-dry-badge"} variant="contained" color="success" badgeContent={aircraft.operational_data_setting.wet_or_dry.label} container style={{ marginRight: '8px' }} />

                }

                <Box mt={3} mb={1} ml={0.5}>
                    <Typography variant="button" fontWeight="regular" color="text">
                        {t('common:label.description')}
                    </Typography>
                </Box>
                <Box component="ul" m={0} pl={4} mb={2}>
                    <Typography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
                        {getLanguageTextWithFallBack(aircraft.description)}
                    </Typography>
                </Box>
                <Box mt={3}>
                    {aircraft?.equipment && <Accordion dense title={<Typography sx={{ width: "max-content" }} variant="body2">Technical Data</Typography>}>
                        <AircraftEquipment equipment={aircraft.equipment} />
                    </Accordion>}
                </Box>
                <Box mt={3}>
                    <Grid item xs={12} lg={5} container>
                        <DefaultActions aircraft={aircraft} />
                    </Grid>
                </Box>
            </Box >
        );
    return null;
}

export default ProductInfo;
