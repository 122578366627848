import { Container, Grid } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";

function InfoText() {
    const { t } = useTranslation()

    return (
        <Box component="section" py={{ xs: 3, md: 12 }} id="info">
            <Container>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {t("partnerPage:info_text.title")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t("partnerPage:info_text.subtitle")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default InfoText