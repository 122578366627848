import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SubPageAdvisory() {
    const params = useParams();
    const { t } = useTranslation();

    const features = t("advisoryPage:cards", { returnObjects: true })
    let content = features.find(o => o.link === window.location.pathname);
    console.log(window.location.pathname)


    return (
        <KitLayout header={<Header title={content.title} image={content.image} description={content.description} />} title={content.title} >
            <Body content={content} />
        </KitLayout>
    )
}
