import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import { bookings_url } from "constants/global";
import routesSocialmedia from "constants/routes.socialmedia";
import { useTranslation } from "react-i18next";
import EmailIcon from '@mui/icons-material/Email';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import RoomIcon from '@mui/icons-material/Room';

// Images
const bgImage = "https://media.westeurope.aero-services.net/aeromate/product/AdobeStock_119917082sm.jpeg";

function ContactUsLandingPage() {
    const { t } = useTranslation();

    const email = { name: "contact", domain: "aeromate", tld: "eu" }

    return (
        <Box component="section" py={{ xs: 0, lg: 6 }}>
            <Grid
                container
                item
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={10}
                sx={{ mx: "auto", textAlign: "center" }}
            >

                <Typography variant="h2" mt={3}>
                    {/*t("landingPage:contactus.title")*/}
                </Typography>

            </Grid>
            <Container>
                <Grid container item >
                    <Box
                        width="100%"
                        bgColor="white"
                        borderRadius="xl"
                        shadow="xl"
                        mb={6}
                        sx={{ overflow: "hidden" }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={7}>
                                <Box p={2} >
                                    <Box px={3} py={{ xs: 2, sm: 6 }}>
                                        <Typography variant="h2" mb={1}>
                                            {t("landingPage:contactus.left.title")}
                                        </Typography>
                                        <Typography variant="body1" color="text" mb={2}>
                                            {t("landingPage:contactus.left.subtitle")}
                                        </Typography>
                                    </Box>
                                    <Box pt={0.5} pb={3} px={3} justifyContent="center">
                                        <Button variant="gradient" color="primary"
                                            onClick={() => { window.open(bookings_url, '_blank') }}>
                                            {t("landingPage:contactus.left.button")}
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                position="relative"
                                px={0}
                                sx={{
                                    backgroundImage: ({
                                        palette: { gradients },
                                        functions: { rgba, linearGradient },
                                    }) => `${linearGradient(rgba(gradients.info.main, 0.8), rgba(gradients.info.state, 0.8))}, url(${bgImage})`,
                                    backgroundSize: "cover",
                                }}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    <Box py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                                        <Typography variant="h3" color="white" mb={1}>
                                            {t("landingPage:contactus.right.title")}
                                        </Typography>
                                        <Typography variant="body2" color="white" opacity={0.8} mb={3}>
                                            {t("landingPage:contactus.right.subtitle")}
                                        </Typography>
                                        <Box display="flex" color="white" p={1}>
                                            <Typography
                                                component="span"
                                                variant="button"
                                                color="white"
                                                opacity={0.8}
                                                ml={2}
                                                fontWeight="regular"
                                            >
                                                <Button
                                                    variant="gradient"
                                                    color="dark"
                                                    onClick={() => window.open("mailto:" + email.name + "@" + email.domain + "." + email.tld, '_blank')}
                                                >
                                                    <EmailIcon fontSize="small" />&nbsp; &nbsp;{email.name}&nbsp;[AT]&nbsp;{email.domain}&nbsp;.&nbsp;{email.tld}
                                                </Button>
                                            </Typography>
                                        </Box>
                                        <Box display="flex" color="white" p={1}>
                                            <Typography variant="button" color="white">
                                                <LocalAirportIcon fontSize="small" />
                                            </Typography>
                                            <Stack direction="column">
                                                <Typography
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                    opacity={0.8}
                                                    ml={2}
                                                    fontWeight="regular"
                                                >
                                                    {t("glossary:airport")}
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                    opacity={0.8}
                                                    ml={2}
                                                    fontWeight="regular"
                                                >
                                                    {t("glossary:counter")}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        <Box display="flex" color="white" p={1}>
                                            <Typography variant="button" color="white">
                                                <RoomIcon fontSize="small" />
                                            </Typography>
                                            <Stack direction="column">
                                                <Typography
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                    opacity={0.8}
                                                    ml={2}
                                                    fontWeight="regular"
                                                >
                                                    Flughafenstrasse 95
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                    opacity={0.8}
                                                    ml={2}
                                                    fontWeight="regular"
                                                >
                                                    41066 Mönchengladbach, {t("glossary:land")}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        <Box mt={3}>
                                            <Stack direction="row" spacing={6} mx="auto">
                                                {routesSocialmedia.map((item) => (
                                                    <Typography
                                                        key={item.classname}
                                                        component={Link}
                                                        href={item.link}
                                                        variant="body2"
                                                    >
                                                        <Box component="i" color="white" className={item.classname} />
                                                    </Typography>))}
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid >
            </Container >
        </Box >
    );
}

export default ContactUsLandingPage;