import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "components/Box";

function ComingSoon() {
    return (
        <Box py={6} id="partner">
            <Container>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >

                    <Typography variant="h6" opacity={0.5}>
                        Please be patient this feature is
                    </Typography>
                    <Typography variant="h1" opacity={0.5}>
                        COMING SOON
                    </Typography>
                </Stack>
            </Container>
        </Box >
    );
}

export default ComingSoon;