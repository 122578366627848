import { DataGrid } from "@mui/x-data-grid";
import colors from "assets/theme/base/colors";
import Box from "components/Box";
import Button from "components/Button";
import AeroLoading from "components/Progress/AeroLoading";
import { restPost } from "lib/rest";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";



export default function InvoiceGrid({ setData, legal }) {
    const { t } = useTranslation();

    const columns = [
        { field: 'id', headerName: t("common:label.position"), valueGetter: (params) => params.row.id + 1, flex: 0.1 },
        { field: 'factor', headerName: t("common:label.quantity"), flex: 0.1, editable: true },
        { field: 'text', headerName: t("common:label.product"), editable: true, valueGetter: (params) => params.row.description.text, flex: 0.1 },
        { field: 'body', headerName: t("common:label.description"), editable: (params) => (params.row.source !== "database"), valueGetter: (params) => params.row.description.body, flex: 0.4 },
        {
            field: 'unit_amount', headerName: t("common:label.unit_netto"), editable: true, flex: 0.1,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = Number(params.value / 100).toLocaleString();
                return `${valueFormatted} €`;
            },
        },
        {
            field: 'amount_net', headerName: t("common:label.sum_netto"), flex: 0.1,
            type: 'number',
            valueGetter: (params) => params.row.factor * params.row.unit_amount,
            valueFormatter: (params) => {
                const valueFormatted = Number(params.value / 100).toLocaleString();
                return `${valueFormatted} €`;
            },
        },
        {
            field: 'vat', headerName: legal.legalEntity === "aeromate.eu" ? t("common:label.vat%") : t("common:label.tax%"), flex: 0.1, editable: true,
            valueFormatter: (params) => {
                const valueFormatted = Number(params.value).toLocaleString();
                return `${valueFormatted} %`;
            },
        },
        {
            field: 'amount_vat', headerName: legal.legalEntity === "aeromate.eu" ? t("common:label.sum_vat") : t("common:label.sum_tax"), flex: 0.1,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = Number(params.value / 100).toLocaleString();
                return `${valueFormatted} €`;
            },
        },
        {
            field: 'amount', headerName: t("common:label.sum_brutto"), flex: 0.1,
            type: 'number',
            valueFormatter: (params) => {
                const valueFormatted = Number(params.value / 100).toLocaleString();
                return `${valueFormatted} €`;
            },
        },
    ]

    const [origLines, setOrigLines] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { alias, identity_id } = useParams();

    const handleCellEditCommit = useCallback(({ id, field, value }) => {
        var lines = _.cloneDeep(origLines)
        console.log("{ id, field, value }", { id, field, value })
        var unitAmount = Number(lines[id].unit_amount)
        var factor = Number(lines[id].factor)
        var vat = Number(lines[id].vat)
        var description = lines[id].description
        if (field === 'unit_amount') unitAmount = Number(value)
        if (field === 'factor') factor = Number(value)
        if (field === 'vat') vat = Number(value)
        if (field === 'body') description["body"] = value
        if (field === 'text') description["text"] = value
        console.log("lines", lines)
        var lineObject = { ...lines[id] }
        lineObject.factor = factor;
        lineObject.unit_amount = unitAmount;
        lineObject.amount_net = factor * unitAmount;
        lineObject.vat = vat;
        lineObject.amount = lineObject.amount_net * (1 + (vat / 100));
        lineObject.amount_vat = lineObject.amount - lineObject.amount_net;
        lineObject.description = description;
        lines[id] = lineObject
        console.log("lineObject", lineObject)

        setOrigLines(lines)

    }, [origLines])

    useEffect(() => {
        const fetch = async () => {
            const callback = (resp) => {
                if (!!resp && !!resp.data) {
                    setOrigLines(resp.data.line.map((e) => ({ ...e, source: "database" })))
                    //setInvoiceID(resp.data._id.$oid ?? null)
                }
            }
            setIsLoading(true)
            await restPost(callback, 'factoring/' + alias + '/identity/' + identity_id)
            setIsLoading(false)
        }

        !!alias && !!identity_id && fetch();
    }, [alias, identity_id]);

    useEffect(() => {
        !!origLines && setData(origLines)
    }, [origLines])

    function handleAdd() {
        let tmp = [...origLines]
        tmp.push({
            "amount": 0,
            "amount_net": 0,
            "amount_vat": 0,
            "description": { text: "", body: "" },
            "factor": 0,
            "unit_amount": "",
            "vat": 0,
        })
        setOrigLines(tmp)
    }

    return (
        <Box p={5}>
            {isLoading && <AeroLoading />}
            {!isLoading && (!!origLines ?
                <DataGrid
                    sx={{
                        border: "none",
                        '& .MuiDataGrid-cell': { bgcolor: colors.light.main },
                        '& .MuiDataGrid-cell--editable': { bgcolor: colors.white.main }
                    }}
                    autoHeight
                    fullwidth
                    rows={origLines.map((v, i) => { return { ...v, id: i } })}
                    columns={columns}
                    pageSize={origLines.length}
                    rowHeight={38}
                    //checkboxSelection
                    disableSelectionOnClick
                    disableColumnMenu
                    hideFooterPagination
                    onCellEditCommit={handleCellEditCommit}
                    isCellEditable={(params) => {
                        if (params.row.source !== "database") return true
                        if (params.field !== "body" && params.field !== "text") return true
                        return false;
                    }
                    }
                />
                :
                t("common:label.no_data_found")
            )
            }
            {!isLoading && <Button onClick={handleAdd} color="success" variant="gradient" size="small" sx={{ marginLeft: "auto", marginTop: "-90px", marginLeft: "8px" }}>Add Line</Button>}
        </Box>
    )
}