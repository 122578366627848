import { Container, Grid, Stack } from "@mui/material";
import ContactUsLandingPage from "aeromate-kit/LandingPage/sections/ContactUsLandingPage";
import Box from "components/Box";
import Typography from "components/Typography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";
import CardContainer from "../component/CardContainer";
import Panel from "../component/Panel";

export default function Body(props) {
    const content = props.content.content;
    // Modulo 2 rechts anders links
    return (
        <Box px={{ xs: 3, lg: 0 }}>
            <ArrowBackIcon onClick={() => window.location.href = `/advisory/`} fontSize="large" fontWeight="bold" sx={{ cursor: "pointer", marginLeft: "16px" }}></ArrowBackIcon>
            <Container>
                <Grid container spacing={3} sx={{ marginTop: "-52px" }}>
                    <Grid item xs={12} spacing={3} mb={5}>
                    </Grid>

                    {content.map((item, key) => {


                        if (key > 0) {
                            if (key % 2 == 0) {

                                return (
                                    <Box component="section" py={{ xs: 3, md: 12 }} id="companycultur" >
                                        <Container>
                                            <Stack >
                                                <Typography textAlign="center" variant="h1">
                                                    {item.title}
                                                </Typography>
                                                <Typography textAlign="center" variant="body2">
                                                    {item.description}
                                                </Typography>
                                            </Stack>
                                            <Grid container
                                                item spacing={3}
                                                direction="row"
                                                pt="20px"
                                            >
                                                {item.list.map((element, index) => (
                                                    <Grid
                                                        item xs={12} md={6} lg={4}
                                                        key={"rotating-card-" + index}
                                                        alignItems="flex-start"
                                                    >
                                                        <CardContainer>

                                                            <Panel
                                                                icon={element.icon}
                                                                image="https://images.pexels.com/photos/5428643/pexels-photo-5428643.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                                title={element.title}
                                                                description={element.description}
                                                                color="dark"
                                                            />
                                                        </CardContainer>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Container>
                                    </Box>

                                )


                            }
                            else {

                                return (
                                    <Grid container spacing={6} mb={12} ml={0} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={12}  >
                                            <Box component="section" py={12}>
                                                <Container>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        justifyContent="center"
                                                        mx="auto"
                                                        textAlign="center"
                                                        pb={6}
                                                    >
                                                        <Typography variant="h2" mb={1}>
                                                            {item.title}
                                                        </Typography>
                                                        <Typography variant="body1" color="text">
                                                            {item.description}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        {item.list.map((card, index) =>
                                                            <Grid item xs={12} md={6} lg={4}>
                                                                <SimpleInfoCard
                                                                    color="primary"
                                                                    icon={card.icon}
                                                                    title={card.title}
                                                                    description={card.description}
                                                                    direction="left"
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Container>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={12}>

                                            <Box
                                                component="img"
                                                src={item.image}
                                                alt={item.title}
                                                borderRadius="xl"
                                                shadow="xl"
                                                width="100%"
                                                position="relative"
                                                zIndex={1}
                                                sx={{ objectFit: "cover", height: "500px", bgcolor: "#F0F2F5" }}
                                            />

                                        </Grid>

                                        <Grid p={3} item xs={12} md={12}>

                                            <Typography>{item.subdescription}</Typography>

                                        </Grid>

                                    </Grid>
                                )
                            }
                        }

                    })}


                </Grid>
                <ContactUsLandingPage></ContactUsLandingPage>
            </Container>
        </Box >
    )
}

