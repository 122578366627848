import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import Typography from "components/Typography";
import { CardActionArea, Divider, Stack } from "@mui/material";

function KnowledgeCard({ color, icon, title, description, active, onClick }) {
  let iconColor = color;

  let variant = active ? "gradient" : "contained"

  if (variant === "gradient" && color !== "light") {
    iconColor = "white";
  } else if (variant === "gradient" && color === "light") {
    iconColor = "dark";
  }

  return (
    <CardActionArea sx={{ height: "100%" }} onClick={onClick}>
      <Box
        height={"100%"}
        display={{ xs: "block", md: "flex" }}
        variant={variant}
        bgColor={variant === "contained" ? "grey-100" : color}
        shadow="lg"
        borderRadius="x1"
        pt={3.5}
        pb={3}
        px={3}
      >
        <Stack
          sx={{ width: `100%` }}
          direction="column"
          justifyContent="space-around"
          alignItems="center">
          <Typography
            display="block"
            variant="h1"
            color={iconColor}
            textGradient={variant === "contained"}
            mt={-0.625}
          >
            {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
          </Typography>
          <Typography
            align="center"
            variant="h5"
            color={variant === "contained" || color === "light" ? "dark" : "white"}
            fontWeight="bold"
            mb={1}
          >
            {title}
          </Typography>
          <Divider sx={{ width: "40%", height: "2px", opacity: 0.75 }} />
          <Typography
            align="center"
            variant="button"
            color={variant === "contained" || color === "light" ? "text" : "white"}
            mb={2}
          >
            {description}
          </Typography>
        </Stack>
      </Box>
    </CardActionArea>
  );
}

// Setting default props for the FilledInfoCard
KnowledgeCard.defaultProps = {
  color: "info"
};

// Typechecking props for the FilledInfoCard
KnowledgeCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.func,
  active: PropTypes.bool
};

export default KnowledgeCard;
