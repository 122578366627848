import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react"
import { restGet } from "lib/rest";
import { useTranslation } from "react-i18next";
import { getLanguageTextWithFallBack } from "lib/functions";

import DefaultAircraftCard from "components/Cards/AircraftCards/DefaultAircraftCard/DefaultAircraftCard";
import Box from "components/Box";
import AirportSearch from "components/Input/AirportSearch";
import AeroLoading from "components/Progress/AeroLoading";

export default function Body() {
  const { t } = useTranslation()

  const [airport, setAirport] = useState();
  const [aircrafts, setAircrafts] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true)
    await restGet(setAircrafts, "airport/" + airport?.icao + "/aircrafts/100")
    setIsLoading(false)
  }, [airport])


  return (

    <Grid container spacing={3} sx={{ mt: 3 }}>
      <Grid item xs={12} md={6}>
        <Card sx={{ width: "100%", padding: 3, margin: 3 }}>
          {t("common:label.airport")}
          <AirportSearch
            fullWidth
            label={""}
            onChange={setAirport}
            value={airport}
          />
        </Card>
      </Grid>

      <Grid item xs={12} >
        {isLoading ?
          <AeroLoading />
          :
          aircrafts?.data?.map((ap, n) => (
            <Card sx={{ width: "100%", padding: 3, margin: 3 }} key={n}>
              <Grid item xs={12} >
                {(airport?.icao != ap?.icao)
                  ? <p>{(ap.dist / 1000).toFixed(1)} km away</p>
                  : ''
                }
                <h2>{ap.name} ({ap.icao}/{ap.iata})</h2>
              </Grid>

              <Grid item xs={12} >
                <Grid container spacing={3} sx={{ mt: 3 }} alignItems="stretch">
                  {ap?.aircrafts?.map((x, i) =>
                    <Grid item xs={12} md={6} lg={4} key={"aircraft-card-" + i + "-" + n} >
                      <Box mt={3} style={{ height: "100%", paddingBottom: "24px" }}>
                        <DefaultAircraftCard
                          image={x.image}
                          title={x.name + " (" + x.reg + ")"}
                          description={getLanguageTextWithFallBack(x.description)}
                          categories={[x.homebase, x.certification, x.class, x.type]}
                          aircraft={x}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Card>
          ))}
      </Grid>
    </Grid>

  )
}