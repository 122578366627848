import { useCallback, useEffect, useState } from "react";
import { Card, Grid, Stepper, Step, StepLabel } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Payment from "./steps/Payment";
import InvoiceGrid from "./steps/InvoiceGrid";
import Summary from "./steps/Summary";
import { restPost } from "lib/rest";
import { useModal } from "hooks/modalHook";
import LoadingModal from "components/Modal/LoadingModal";
import { useParams } from "react-router-dom";
import AeroLoading from "components/Progress/AeroLoading";
import { legalFromAlias } from "lib/legalInfo";

function Body() {
    const { t } = useTranslation();
    const { addModal } = useModal();
    const { alias, identity_id } = useParams();
    const [activeStep, setActiveStep] = useState(0);

    const [legalLoading, setLegalLoading] = useState(true);
    const [legal, setLegal] = useState();
    useEffect(() => {
        const waitForLegal = async () => {
            setLegalLoading(true)
            setLegal(await legalFromAlias(alias))
            setLegalLoading(false)
        }
        alias && waitForLegal();
    }, [alias]);

    const [stepData, setStepData] = useState({})
    const setData = (data) => {
        setStepData({ ...stepData, [content[activeStep].id]: data })
    }

    const content = [
        {
            id: "payment",
            label: t("dashboard:charter.factoring.steps.payment.steplabel"),
            component: <Payment setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return !!stepData["payment"] }
        },
        {
            id: "factoring",
            label: t("dashboard:charter.factoring.steps.factoring.steplabel"),
            component: <InvoiceGrid setData={setData} legal={legal} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return !!stepData["factoring"] }
        },
        {
            id: "summary",
            label: t("dashboard:charter.factoring.steps.summary.steplabel"),
            component: <Summary setData={setData} stepData={stepData} legal={legal} />,
            handleNext: () => { handleFinishClick() },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return true }
        }
    ]
    const isLastStep = activeStep === content.length - 1;

    const handleFinishClick = useCallback(async () => {
        addModal(<LoadingModal />)
        await restPost(null, `factoring/${alias}/invoice/${identity_id}`, { payment: stepData["payment"], line_items: stepData["factoring"] })
    }, [alias, identity_id, stepData])

    return (
        <Box pb={8}>
            <Grid container justifyContent="center" >
                <Grid item xs={12}  >
                    <Box mb={8} textAlign="center">
                        <Box mb={1}>
                            <Typography variant="h3" fontWeight="bold">
                                {t("dashboard:charter.factoring.title")}
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight="regular" color="secondary">
                            {t("dashboard:charter.factoring.description")}
                        </Typography>
                    </Box>
                    <Card>
                        <Box mt={-3} mx={2}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {content.map((item) => (
                                    <Step key={item.label}>
                                        <StepLabel>{item.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        {legalLoading ? <AeroLoading /> :
                            <Box p={2}>
                                <Box>
                                    {content[activeStep].component}

                                    <Box mt={3} width="100%" display="flex" justifyContent="space-between">
                                        {activeStep === 0 ? (
                                            <Box />
                                        ) : (
                                            <Button
                                                disabled={!!content[activeStep].disbaledButtons}
                                                variant="outlined"
                                                color="dark"
                                                onClick={content[activeStep].handleBack}
                                            >
                                                {t("common:button.back")}
                                            </Button>
                                        )}
                                        <Button
                                            disabled={!content[activeStep].isValid() || !!content[activeStep].disbaledButtons}
                                            variant="gradient"
                                            color="dark"
                                            onClick={content[activeStep].handleNext}
                                        >
                                            {!isLastStep ? t("common:button.next") : t("common:button.create_invoice")}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Body;