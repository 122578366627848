// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";

// Coworking page component
import InformationOptions from "../components/InformationOptions";

function FeaturesOne() {
    const { t } = useTranslation();
    return (
        <Box component="section" py={{ xs: 3, md: 12 }}>
            <Container>
                <Grid container alignItems="center">
                    <Grid item xs={12} lg={5}>
                        <Typography variant="h3" my={1}>
                            {t("aboutUsPage:information.title")}
                        </Typography>
                        <Typography variant="body2" color="text" mb={2}>
                            {t("aboutUsPage:information.description")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                        <Stack>
                            {t("aboutUsPage:information.options", { returnObjects: true }).map((x, i) =>
                                <InformationOptions
                                    key={"info-option-" + i}
                                    icon={x.icon}
                                    content={x.content}
                                />)
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default FeaturesOne;