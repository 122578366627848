import i18n from "locals/config";
import { bookingWindowBuilder } from "lib/functions";
import Badge from "components/Badge";
import CancelBookingModal from "components/Modal/CancelBookingModal/CancelBookingModal";
import ConfirmationModal from "components/Modal/ConfirmationModal/ConfirmationModal";

export const bookingColumns = [
    {
        Header: i18n.t("common:label.name"),
        accessor: "identity[0].name"
    },
    {
        Header: i18n.t("common:label.reg"),
        accessor: d => `${d.reg} (${!!d.aircraft && !!d.aircraft[0] && d.aircraft[0].type})`
    },
    {
        Header: i18n.t("common:label.booking"),
        accessor: d => bookingWindowBuilder(d.handover, d.return)
    },
    {
        Header: i18n.t("common:label.duration"),
        accessor: d => Math.ceil((d.return - d.handover) / 60) + " min"
    },
    {
        Header: i18n.t("common:label.slot"),
        accessor: "slot"
    },
    {
        Header: i18n.t("common:label.status"),
        accessor: "status",
        Cell: props => <Badge color={statusColor(props.value)} badgeContent={props.value} />
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
]

export const bookingActions = (addModal, data) => {
    return [
        {
            displayStatus: ["pending", "waiting"],
            title: i18n.t("common:button.confirm"),
            action: (index) =>
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.confirm")}
                    data={data[index]}
                    status="confirmBooking"
                />)
        },
        {
            displayStatus: ["confirmed", "waiting", "pending"],
            title: i18n.t("common:button.cancel"),
            action: (index) => {
                addModal(<CancelBookingModal
                    actionName={i18n.t("common:button.cancel")}
                    data={data[index]}
                />)
            }
        },
        {
            displayStatus: ["active"],
            title: i18n.t("common:button.logtimes"),
            action: (index) => window.location.href = "/my/logtimes/" + data[index]._id.$oid
        }
    ]
};

function statusColor(status) {
    switch (status) {
        case "pending":
            return "error";
        case "canceled":
            return "light";
        case "active":
            return "info";
        default:
            return "success";
    }
}