import { forwardRef } from "react";
import PropTypes from "prop-types";
import IconRoot from "./IconRoot";

const Icon = forwardRef(
    ({ color, fontSize, ...rest }, ref) => (
        <IconRoot
            {...rest}
            ref={ref}
            justifyContent="center"
            alignItems="center"
            sx={{
                width: "auto",
                height: "auto",
                display: "flex!important",
                padding: "8px",
                borderRadius: "100%",
                fontSize: fontSize + "!important"
            }}
            ownerState={{ color }}
        />
    )
);

// Setting default values for the props of Button
Icon.defaultProps = {
    color: "white",
};

// Typechecking props for the Button
Icon.propTypes = {
    color: PropTypes.oneOf([
        "default",
        "white",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ])
};

export default Icon;
