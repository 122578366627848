/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";

function FeatureCard({ image, title, description, action }) {
  return (
    <Card sx={{cursor:"pointer"}}>
      <Box position="relative" borderRadius="lg"  mx={2} mt={-3}>
        <Box
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="100%"
          maxHeight="235px"
          position="relative"
          zIndex={1}
          sx={{aspectRatio:"3/4", objectFit:"cover" , bgcolor: "#F0F2F5"}}
        />
        <Box
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />

      </Box>
      <Box p={3} mt={-1} textAlign="center" sx={{ height: "155px"  }} >
        <Typography display="inline" variant="h5" textTransform="capitalize" fontWeight="bold" color="dark">
          {title}
        </Typography>
        <Box mt={1} mb={3} >
          <Typography variant="body2" component="p" sx={{color:"dark"}}>
            {description}
          </Typography>
        </Box>

      </Box>
    </Card>
  );
}

// Typechecking props for the FeatureCard
FeatureCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeatureCard;
