import { Height, HelpOutlined } from "@mui/icons-material";
import { Card, Grid, Skeleton, TextField, Tooltip } from "@mui/material";
import Alert from "components/Alert";
import Badge from "components/Badge";
import Box from "components/Box";
import Restricted from "components/Common/Restricted";
import DynamicForm from "components/Forms/DynamicForm/DynamicForm";
import AlertModal from "components/Modal/AlertModal";
import CharterLogo from "components/Modal/CharterLogoCropper";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useModal } from "hooks/modalHook";
import { delay } from "lib/functions";
import { getBlobImageUrl } from "lib/functions";
import { restGet, restPost } from "lib/rest";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/ErrorOutline';

export default function General() {
    const { t } = useTranslation();
    const [charter, setCharter] = useState();
    const [image, setImage] = useState();
    const imageRef = useRef(null);
    const { addModal, removeModal } = useModal();
    const [textField, setTextField] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);


    const handleTextField = (event) => {
        (event.target.value);
        const text = event.target.value;
        const linesArray = text.split('\n');
        setTextField(linesArray);
    };

    const savePublicAddress = async () => {
        await restPost(
            null,
            `charter/${alias}/publicaddress`,
            textField
        );
    };

    const imageFile = () => {
        imageRef.current.click();
    };

    const Handlechange = (value) => {
        setImage(value);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/jpeg") {
            addModal(<CharterLogo onValueChange={Handlechange} img={URL.createObjectURL(selectedFile)} id={charter._id.$oid}></CharterLogo>)
            setAlert(false)
        } else {
            setAlert(true)
        }
    }


    useEffect(() => {
        if (!!charter?.logo) {
            setImage(getBlobImageUrl(charter.logo))
        }
        if (!!charter?.address) {
            try {
                setTextField(charter.address)
            }
            catch (error) {
                console.error(error);
            }
        }


    }, [charter]);

    const { alias } = useParams();

    useEffect(async () => {
        await restGet((res) => setCharter(!!res && !!res.data && res.data[0]), 'charter/' + alias)
        setLoading(true);
    }, [alias]);

    const [charterGeneralSetting, setcharterGeneralSetting] = useState();
    const [newcharterGeneralSetting, setNewcharterGeneralSetting] = useState();
    useEffect(() => {
        restGet((res) => { res.shift(); setcharterGeneralSetting(res) }, "dataset/metaDataGeneralSettings")
    }, []);

    const [generalSettings, setGeneralSettings] = useState();
    useEffect(async () => {
        if (!!newcharterGeneralSetting) {
            var general = newcharterGeneralSetting.reduce((a, e) => ({ ...a, [e.name]: e.value }), {})
            var newGeneral = null;
            if (general.ato_module && !general.shop_module) {

                setGeneralSettings({ ...charter?.general_setting, shop_module: true, ato_module: true })
                newGeneral = ({ ...charter?.general_setting, shop_module: true, ato_module: true })

            }
            await restPost(null, `charter/${alias}/setting/general_setting`, newGeneral ?? general)
        }
    }, [newcharterGeneralSetting]);

    return (
        <Restricted role="admin" nopermission>
            <Grid item xs={12}>
                <Box mt={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9} xl={9}>
                            <Card >
                                <Box p={3}>
                                    <TypographyBlock
                                        title={"Add Logo"}
                                        description={"Add Company Logo for invoices and profile page"}
                                        action={imageFile}
                                        actionName={"Add"} >
                                    </TypographyBlock>
                                    <input
                                        type="file"
                                        accept="image/png ,image/jpg,image/jpeg"
                                        ref={imageRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    {alert &&
                                    <Alert color="error">
                                            <ClearIcon fontSize="small" />&nbsp;
                                            {t("common:label.alert")}
                                        </Alert>}
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3} xl={3}>
                            <Card sx={{ height: "154.5px" }} >
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }} >
                                    {
                                        loading ? <img style={{ height: "120px" }} src={image}></img>
                                            :
                                            (
                                                <Skeleton animation="wave" variant="rectangular" width={170} height={80} />
                                            )
                                    }

                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={4}>
                    <Card mb={3}>
                        <>
                            <TypographyBlock
                                title='Public Address'
                                description={"Add your Public Address"}
                                action={() => (savePublicAddress())}
                                actionName={t("common:button.save")}
                            />

                            <Box p={3} sx={{ width: "100%" }}>
                                {
                                    loading ? <TextField
                                        id="outlined-multiline-flexible"
                                        label="Address"
                                        rows={5}
                                        value={textField.join('\n')}
                                        onChange={handleTextField}
                                        multiline
                                        fullWidth
                                    >

                                    </TextField>
                                        :
                                        (
                                            <>
                                                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />

                                            </>


                                        )
                                }

                            </Box>

                        </>
                    </Card >
                </Box>
                <Box mt={4}>
                    <Grid item xs={12}>
                        <Card >
                            <Box p={3}>
                                <DynamicForm
                                    i18nextPrefix={"general_settings"}
                                    formMetaData={charterGeneralSetting}
                                    formDefaultValues={generalSettings ?? charter?.general_setting}
                                    formValuesCallback={setNewcharterGeneralSetting}
                                />
                            </Box>
                        </Card>
                    </Grid>
                </Box>
            </Grid>
        </Restricted>
    )
}