import { useState, useEffect } from "react"
import DataTable from "components/Tables/DataTable";
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { addActions } from "lib/functions/tables";
import { invoiceActions, invoiceColumns } from "../tables/invoiceTable";
import { Card, Grid } from "@mui/material";
import { useModal } from "hooks/modalHook";

export default function Invoices() {
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    let { addModal } = useModal()
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = resp?.data ?? []
                data = data.map((element) => {
                    return {
                        ...element,
                        status: element.billing_type,
                        charter: element.charter[0].charter_legal_info.chartername
                    };
                })
                setTableData({ columns: invoiceColumns, rows: addActions(data, invoiceActions(addModal, data)) })
            }
            setIsLoading(true)
            restGet(restCallback, "identity/_self/invoices");
            setIsLoading(false)
        }
        fetch()
    }, [])

    return (
        <Grid item xs={12}>
            <Card>
                {isLoading && <AeroLoading />}
                {!isLoading && <DataTable canSearch={true} table={tableData} />}
            </Card>
        </Grid>
    )
}