
import { Stack, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { getLanguageTextWithFallBack } from "lib/functions";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "lib/legalInfo";
import { useModal } from "hooks/modalHook";
import { useUser } from "hooks/userDataHook";
import { AuthButton } from "components/Button/AuthButton";
import ShopCheckoutModal from "components/Modal/ShopCheckoutModal";
import StarRating from "components/Rating/StarRating";
import Button from "components/Button";
import Input from "components/Input";
import Box from "components/Box";
import Typography from "components/Typography";

function ShopItemInfo({ item, charter }) {
    const { t } = useTranslation()
    const { addModal } = useModal();
    const { isSignedIn } = useUser();
    const [activeSelection, setActiveSelection] = useState(0);
    const [currenc, setCurrenc] = useState();

    useEffect(() => {
        if (!!charter?.currency) {
            setCurrenc(charter.currency);
        }
        else {

            setCurrenc(currency);
        }
    }, []);

    const [buttonType, setButtonType] = useState();
    useEffect(() => {
        if (!!item?.item_type) {
            if (item.item_type === "enrollment") {
                async function createEnrollmentButton() {
                    if (!await isSignedIn()) {
                        setButtonType(<AuthButton />)
                    } else {
                        setButtonType(
                            <Button
                                variant="outlined"
                                size="small"
                                color="info"
                                onClick={() => addModal(<ShopCheckoutModal item={{ ...item, variantId: activeSelection, variant: item.variant[activeSelection] }} charter={charter} />)}>
                                {t("common:label.checkout")}
                            </Button>)
                    }
                }
                createEnrollmentButton()
            } else {
                setButtonType(
                    <Button
                        variant="outlined"
                        size="small"
                        color="info"
                        onClick={() => addModal(<ShopCheckoutModal item={{ ...item, variantId: activeSelection, variant: item.variant[activeSelection] }} charter={charter} />)}>
                        {t("common:label.checkout")}
                    </Button>
                )
            }
        }
    }, [item.item_type, activeSelection]);

    if (!!item)
        return (
            <Box>
                <Box mb={1}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}>
                        <Typography variant="h3" fontWeight="bold">
                            {getLanguageTextWithFallBack(item.caption)}
                        </Typography>
                    </Stack>
                </Box>
                <StarRating text={t('common:label.recension', { count: 0 })} rating={0} />
                <Box mt={3} mb={1} ml={0.5}>
                    <Typography variant="button" fontWeight="regular" color="text">
                        {t('common:label.description')}
                    </Typography>
                </Box>
                <Box component="ul" m={0} pl={4} mb={2}>
                    <Typography variant="body2" color="text" fontWeight="regular" verticalAlign="middle" style={{ whiteSpace: 'pre-line' }}>
                        {!!item.description && getLanguageTextWithFallBack(item.description)}
                    </Typography>
                </Box>
                {item.variant.length > 1 && <Box mt={3} width="100%" lineHeight={2.5}>
                    <Autocomplete
                        fullWidth
                        disableClearable={true}
                        options={item.variant.map((item, id) => ({ ...item, id: id }))}
                        onChange={(e, v) => setActiveSelection(v.id)}
                        value={item.variant[activeSelection]}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => getLanguageTextWithFallBack(option.caption) + " ( " + formatCurrency(option.price, currenc) + " )"}
                        renderInput={(params) => (
                            <Input
                                {...params}
                                label={t("common:label.variants")}
                                type="text"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            />
                        )}
                    />
                </Box>}

                <Box my={2}>
                    {!!item.variant[activeSelection].price && !!currenc ? (
                        <Typography sx={{ fontSize: "inherit" }} variant="h1" color="dark">

                            {formatCurrency(item.variant[activeSelection].price, currenc) ?? 0}
                            <Typography display="inline" component="small" variant="h5" color="inherit">
                                / {charter.legal_entity === "aeromate.us" ? t("common:label.exclusiv_tax") : t("common:label.inclusiv_vat")}
                            </Typography>
                        </Typography>) : (

                        <Typography variant="h5" color="dark">
                            {t("common:label.onRequest")}
                        </Typography>
                    )

                    }
                </Box>

                <Box mb={1.5} >
                    {buttonType}
                </Box>
            </Box >
        );
    return null;
}

export default ShopItemInfo;
