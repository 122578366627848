import i18n from "locals/config";

import { restPost } from "lib/rest";
import ConfirmationModal from "components/Modal/ConfirmationModal/ConfirmationModal";
import moment from "moment";
import { convertTimeDatabaseToFrontend } from "lib/functions";
import OpenDocModal from "components/Modal/OpenDocModal";






export const documentColumns = [

    {
        Header: i18n.t("common:label.cur_type"),
        accessor: "type"
    },
    {
        Header: i18n.t("common:label.date"),
        accessor: "date",
        Cell: props => moment(convertTimeDatabaseToFrontend(parseInt(props.value))).format("LL")
    },
    {
        Header: i18n.t("common:label.expires"),
        accessor: "expires",
        Cell: props => moment(convertTimeDatabaseToFrontend(parseInt(props.value))).format("LL")
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
]

export const documentActions = (addModal, data ) => {
 
    return [
        {
            displayStatus: ["draft"],
            title: i18n.t("common:button.delete"),
            action: (i) => {
                addModal(<ConfirmationModal
                    actionName={i18n.t("common:button.delete")}
                    data={data[i]}
                    status="deleteDocument"
                />)
            }
        },
        {
            
            displayStatus: ["draft"],
            title: i18n.t("common:button.view"),
            action: (i) => { 
                addModal(<OpenDocModal
                    data={data[i]}
                    
                />);
            }
        } 
    ]
};