import brand from "constants/brand";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function FeatureHelmet( customKeywords ) {
    const { t } = useTranslation();
    let default_key_words = [
        "aeromate",
        "features",
        "aeromate features",
        "aero mate",
        "aip",
        "aircraft info desk",
        "aviatize",
        "flottenmanagement",
        "flottenverwaltung",

    ]
  

    return (
        <Helmet>
            <title>{brand.name_caps + " " + t("routes:Features.title")}</title>
            <meta name="keywords" content={default_key_words.concat(customKeywords.props ?? [])}></meta>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "https://www.aeromate.eu",
                    "logo": "https://aeromatewesteurope.blob.core.windows.net/aeromate/Logo/logo_1024.png"
                }
                )}
            </script>
        </Helmet>
    )
}