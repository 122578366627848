import moment from "moment";

export function createDemo1Bookings(sunrise, sunset) {
    const weekStart = moment().startOf("day");

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    let events = [];
    for (let index = 0; index < 15; index++) {
        const start = weekStart.add(getRandomInt(5), "hours").valueOf();
        const end = weekStart.add(getRandomInt(3) + 1, "hours").valueOf();
        const element = eventBuilder(
            index,
            "Demo Data " + (index + 1),
            convertFrontendToTimeDatabase(start),
            convertFrontendToTimeDatabase(end)
        )
        if (moment(start).startOf("day").add(8, "hours").valueOf() > start ||
            moment(start).endOf("day").subtract(8, "hours").valueOf() < start
        ) {
            index--;
        } else {
            events.push(element)
        }
    }

    return events
}

export function isNotEmptyArray(array) {
    return Array.isArray(array) && !array.length
}

export function getBlobFromPdfString(data) {

    var byteCharacters = window.atob(data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });

    return file
}

export function getBlobUrl(region, container, blob) {
    return `https://media.${region}.aero-services.net/${container}/${blob}`;
}

export function getUrlFromBlob(bloburl) {
    var result = bloburl.replace('https://media.', '')
    result = result.replace('.aero-services.net', '')
    result = result.split("/")
    return { region: result[0], container: result[1], blob: result.splice(2).join('/') };
}


export const delay = ms => new Promise(res => setTimeout(res, ms));

export function getBlobImageUrl(image) {
    if (!!image && !!image.storage_region && !!image.container && !!image.filename)
        return getBlobUrl(image.storage_region, image.container, image.filename);
    return null
}

export function getBlobManifestUrl(manifest) {
    return getBlobUrl(manifest.storage_region, manifest.container, manifest.filename);
}

export function getBlobObjectUrl(object) {
    return getBlobUrl(object.region, object.container, (object.folder ?? "") + object.filename);
}

export function base64ToArrayBuffer(base64) {
    return Uint8Array.from(atob(base64), c => c.charCodeAt(0));
}

export function getLanguageTextWithFallBack(dataObject) {
    var lang = sessionStorage.getItem("lang")
    if (!!lang) lang = sessionStorage.getItem("lang").slice(0, 2);
    if (!_.isEmpty(dataObject[lang])) {
        return dataObject[lang];
    }
    let keys = Object.keys(dataObject);
    for (let index = 0; index < keys.length; index++) {
        if (!_.isEmpty(dataObject[keys[index]])) return dataObject[keys[index]];
    }
    return undefined;
}

export function getDocumentFromUrl(callbackFunction, source) {
    var xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) callbackFunction(xhr.responseText)
    }
    xhr.open('get', source, true)
    xhr.send()
}


export function getDMS(dd, longOrLat) {
    let hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
        ? dd < 0
            ? "W"
            : "E"
        : dd < 0
            ? "S"
            : "N";

    const absDD = Math.abs(dd);
    const degrees = truncate(absDD);
    const minutes = truncate((absDD - degrees) * 60);
    const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2);

    let dmsArray = [degrees, minutes, seconds, hemisphere];
    return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}"${dmsArray[3]}`;
}

//getDMS Helper
function truncate(n) {
    return n > 0 ? Math.floor(n) : Math.ceil(n);
}

export function eventBuilder(
    p_id,
    p_title,
    p_start,
    p_end,
    p_description,
    p_backgroundColor
) {
    return {
        id: p_id,
        title: p_title,
        start: moment(convertTimeDatabaseToFrontend(p_start)).format(),
        end: moment(convertTimeDatabaseToFrontend(p_end)).format(),
        extendedProps: { description: p_description || null },
        backgroundColor: p_backgroundColor,
        borderColor: p_backgroundColor,
        allDay: false
    };
}

export function businessHoursBuilder(p_day, p_start, p_end) {
    const st = moment(convertTimeDatabaseToFrontend(p_start));
    const en = moment(convertTimeDatabaseToFrontend(p_end));

    if (st.day() !== en.day())
        return [{
            daysOfWeek: [p_day],
            startTime: "00:00",
            endTime: en.format("HH:mm")
        }, {
            daysOfWeek: [p_day],
            startTime: st.format("HH:mm"),
            endTime: "24:00"
        }

        ]
    return {
        daysOfWeek: [p_day],
        startTime: st.format("HH:mm"),
        endTime: en.format("HH:mm")
    };
}

export function bookingWindowBuilder(p_start, p_end) {
    if (!p_start || !p_end)
        return ""
    const st = moment(convertTimeDatabaseToFrontend(p_start));
    const en = moment(convertTimeDatabaseToFrontend(p_end));
    let ret = "";

    ret += st.format("L") + " " + st.format("LT")
    ret += " to "

    if (st.format("L") !== en.format("L")) {
        ret += "\n\r";
        ret += en.format("L") + " "
    }
    ret += en.format("LT")

    return ret;
}

export function bookingDuration(p_start, p_end) {
    let d_sec = p_end - p_start;
    let d_min = Math.ceil(d_sec / 60);

    return (
        (d_min - (d_min % 60)) / 60 +
        ":" +
        (d_min % 60 < 10 ? "0" + (d_min % 60) : d_min % 60)
    );
}

export function centToEuro(value) {
    const valueFormatted = Number((value / 100)).toFixed(2).toLocaleString();
    return `${valueFormatted} €`;
}

export function convertTimeDatabaseToFrontend(timestamp) {
    return timestamp * 1000
}

export function convertFrontendToTimeDatabase(timestamp) {

    return Math.ceil(timestamp / 1000)
}

export function decimalToMinutes(decTime) {
    return Math.round((Math.trunc(decTime) * 60) + ((decTime - Math.trunc(decTime)) * 60))
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function secondsToHHMM(seconds) {
    let d_min = Math.ceil(seconds / 60);
    return (
        (d_min - (d_min % 60)) / 60 +
        ":" +
        (d_min % 60 < 10 ? "0" + (d_min % 60) : d_min % 60)
    );
}

export function millisecondsToHHMM(millisecondsToHHMM) {
    let seconds = Math.ceil(millisecondsToHHMM / 1000);
    return secondsToHHMM(seconds);
}

export function secondsToHHH(seconds) {
    return seconds / 3600;
}

export function millisecondsToHHH(millisecondsToHHMM) {
    let seconds = Math.ceil(millisecondsToHHMM / 1000);
    return secondsToHHH(seconds);
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
    reader.onerror = reject;
});
