// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Badge from "components/Badge";
import Typography from "components/Typography";
import { Icon } from "@mui/material";

function FeatureContent({ icon, iconSize, title, description, tags }) {
    return (
        <Grid container>
            <Grid item xs={12} lg={8} mx="auto">
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box ml={-1}>
                            {tags.map((value, index) => <Badge key={"badge-" + index} badgeContent={value} variant="contained" color="dark" />)}
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 1 }} />
                <Box display="flex" alignItems="center">
                    <Typography
                        display="block"
                        variant={iconSize}
                        color="primary"
                        textGradient
                    >
                        {" "}<Icon >{icon}</Icon>{" "}
                    </Typography>
                    <Box ml={3}>
                        <Typography variant="h5" mb={1}>
                            {title}
                        </Typography>
                        <Typography variant="button" color="text">
                            {description}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default FeatureContent;