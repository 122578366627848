import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { restGet } from 'lib/rest';
import Input from "components/Input";

export default function AircraftTypeSearch({ label, onChange, value, ...rest }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [searchTerm, setSearchTerm] = useState('')
    useEffect(() => {
        if (searchTerm !== "")
            setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            (async () => {
                const callback = (res) => {
                    if (Array.isArray(res))
                        setOptions(res ?? []);
                }
                await restGet(callback, `dataset/aircraftType/search/${searchTerm}/`)
                setLoading(false)
            })();
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <Autocomplete
            {...rest}
            id="asynchronous-demo"
            open={open}
            value={value}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            isOptionEqualToValue={(option, value) => (option?.name === value?.name)}
            getOptionLabel={(option) => (option.name ?? value) + " (" + (option.type ?? "") + ") [" + (option.manufacturer_code ?? "") + "]"}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            options={options ?? value}
            loading={loading}
            renderInput={(params) => (
                <Input
                    {...params}
                    value={searchTerm}
                    onChange={handleChange}
                    label={label ?? "lable"}
                    type="text"
                    variant="standard"
                    margin="dense"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}