import Grid from "@mui/material/Grid";
import Box from "components/Box";
import AeroLoading from "components/Progress/AeroLoading";
import Typography from "components/Typography";
import { baseurl } from "constants/global";
import { restPost } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function CreateStep({ data }) {
    const { t } = useTranslation();
    const [newCharter, setNewCharter] = useState();
    const [stripeAccountSetup, setStripeAccountSetup] = useState();
    const [customText, setCustomText] = useState();

    useEffect(() => {
        setCustomText(t("dashboard:organization.create.creating.prep"))

        if (!!data) {
            let body = {
                name: data['name'].name,
                legal_info: { chartername: data['name'].name },
                legal_entity: data['name'].legalEntity.legal_entity,
                currency: data['name'].legalEntity.currency,
                general_setting:{monthly_reconciliation:false , ato_module:false, shop_module:false}
            }
            restPost(setNewCharter, `charter/${data['name'].alias}`, body)
        }
    }, [data])

    useEffect(() => {
        setCustomText(t("dashboard:organization.create.creating.stripe"))

        if (!!newCharter && !!newCharter[0]) {
            restPost(setStripeAccountSetup, 'account', {
                alias: newCharter[0].alias,
                legal_entity: newCharter[0].legal_entity,
                url: baseurl + '/my/' + newCharter[0].alias + '/settings#legal'
            })
        }
    }, [newCharter])

    useEffect(() => {
        if (!!stripeAccountSetup && !!stripeAccountSetup.data && !!stripeAccountSetup.data.url) {
            window.location.replace(stripeAccountSetup.data.url)
        }
    }, [stripeAccountSetup]);

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:organization.create.creating.waiting_title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:organization.create.creating.waiting_subtitle")}
                </Typography>
            </Box>
            <Box mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <AeroLoading customText={customText} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default CreateStep;