import Box from "components/Box";
import { useUser } from "hooks/userDataHook";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LockIcon from '@mui/icons-material/Lock';

function Restricted({ role, children, explicit, nopermission }) {
    const { t } = useTranslation()
    const { alias } = useParams()
    const { getRole } = useUser()

    const RoleValue = {
        "owner": 40,
        "admin": 30,
        "staff": 20,
        "viwer": 10,
    };

    function checkRole() {
        if (!!alias) {
            let userRole = getRole(alias)
            if (explicit) {
                return RoleValue[userRole] === RoleValue[role]
            } else {
                return RoleValue[userRole] >= RoleValue[role]
            }
        }
        return false
    }

    return checkRole() ?
        children
        :
        nopermission ?
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}>
                <LockIcon color="error" sx={{ width: "4em", height: "4em" }} />
                <Typography variant={"h1"}>
                    {t("common:label.noPermissionTitle")}
                </Typography>
                <Typography variant={"subtilte1"}>
                    {t("common:label.noPermissionSubtitle")}
                </Typography>
            </Stack >
            :
            null;
}

export default Restricted;