import { Grid, Skeleton, Stack } from "@mui/material";
import loading from "assets/planeLoading.gif";
import React from "react";
import { useTranslation } from "react-i18next";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import './Loading.css';
//class="MuiSkeleton-root MuiSkeleton-text MuiSkeleton-pulse css-1n7gpw1-MuiSkeleton-root"
export default function AeroLoading(props) {
    const { t } = useTranslation();
    return (
        <Grid container direction={props?.direction ?? "column"} justifyContent="flex-start" alignItems="center" {...props}>
            <Grid item >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {[0, 1, 2, 3, 4, 5,].map((e) =>
                        <AirplanemodeActiveIcon className={"LoadingIcon Delay" + e} key={"plane-icon-" + e} fontSize={props?.size ?? "large"} />
                    )}
                </Stack>
            </Grid>
            {!props.textDisabled &&
                <Grid>
                    <h6>{props.customText ? props.customText : t("common:label.loading")}</h6>
                </Grid>
            }
        </Grid>
    );
}
