import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useTranslation } from "react-i18next";

export default function LandingPage() {
    const {t} = useTranslation();
    return (
        <KitLayout header={<Header/>} >
            <Body/>
        </KitLayout>
        )
}