import PropTypes from "prop-types";
import Box from "components/Box";
import Typography from "components/Typography";
import { Avatar, Stack } from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { convertTimeDatabaseToFrontend } from "lib/functions";

const images = [
    "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_226419158sm.jpeg",
    "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_194450239sm.jpeg",
    "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_211510087sm.jpeg",
    "https://media.westeurope.aero-services.net/aeromate/header/rough-horn-2146181_1920.jpg",

    "https://media.westeurope.aero-services.net/aeromate/product/AdobeStock_119917082sm.jpeg",
    "https://media.westeurope.aero-services.net/aeromate/product/AdobeStock_139621851sm.jpeg",
    "https://media.westeurope.aero-services.net/aeromate/product/83061FF2-88EC-4B52-AA32-8F087A8A3EA3_1_105_c.jpeg",
    "https://media.westeurope.aero-services.net/aeromate/product/shutterstock_1642042111.jpg"
]

function DefaultTimeSlotCard({ color, data, onClick }) {
    const { t } = useTranslation()
    const dateTime = moment(convertTimeDatabaseToFrontend(data.handover))

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            borderRadius="lg"
            coloredShadow={color}
            width="100%"
            position="relative"
            zIndex={2}
            sx={{
                backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                    `${linearGradient(
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
                    )}, url(${images[Math.floor(Math.random() * images.length)]})`,
                backgroundSize: "cover",
                backfaceVisibility: "hidden",
            }}
        >
            <Box py={12} px={3} textAlign="center" lineHeight={1}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}>
                    <Avatar sx={{
                        width: 84,
                        height: 84,
                        fontSize: '2.25rem',
                        bgcolor: ({ palette: { gradients } }) => gradients['dark'].main
                    }}
                    >{dateTime.format("dd").toUpperCase()}</Avatar>
                    <Typography variant="h3" color="white" gutterBottom>
                        {dateTime.format("L")}
                    </Typography>
                    <Typography variant="body2" color="white" opacity={0.8}>
                        {dateTime.format("LT")}
                    </Typography>
                    <Typography variant="h4" color="white" >
                        {data.aircraft.homebase}
                    </Typography>
                    <Typography variant="body2" color="white" opacity={0.8}>
                        {data.aircraft.airport[0].name}
                    </Typography>
                    <Button
                        size="small"
                        color="white"
                        fullWidth
                        onClick={() => onClick()}
                    >
                        {t("common:button.book")}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}

// Setting default props for the RotatingCardFront
DefaultTimeSlotCard.defaultProps = {
    color: "info"
};

// Typechecking props for the RotatingCardFront
DefaultTimeSlotCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ])
};

export default DefaultTimeSlotCard;