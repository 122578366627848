/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import { useEffect, useState } from "react";
import { restGet } from "lib/rest";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Check';
import TypographyBlock from "components/Typography/TypographyBlock";
import { useTranslation } from "react-i18next";

function DeleteAccount() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState();
    useEffect(() => {
        restGet(setData, "identity/_self/deletable")
    }, [])

    const [items, setItems] = useState();
    const [deletable, setDeletable] = useState();
    useEffect(() => {
        if (!!data && !!data.data) {
            setIsLoading(false)
            setItems(data.data.items)
            setDeletable(data.data.deletable)
        }
    }, [data])


    return (
        <Card >
            <Box sx={{ position: "absolute", top: "-100px" }} id="delete"></Box>
            <TypographyBlock
                title={t("dashboard:personal.delete.title")}
                description={t("dashboard:personal.delete.description")}
                action={() => console.log("Open Modal for Confirmation")}
                actionName={t("dashboard:personal.delete.button")}
                disabled={true}
            />
            <Box pl={3} pb={3}>
                <Typography variant="button" color="text">
                    <a href="mailto:support@aeromate.eu">{t("dashboard:personal.delete.contact")}</a>
                </Typography>
            </Box>
            <Box>
                {!!items && items.map((element, index) => (
                    <Box pl={3} pb={3}
                        key={"delete-item-" + index}
                        display="flex"
                        alignItems="baseline">
                        <Typography
                            variant="body2"
                            lineHeight={1}
                            color="inherit"
                            sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
                        >
                            {element.value ? <CheckIcon /> : <ClearIcon />}
                        </Typography>
                        <Typography
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                        >
                            {t("dashboard:personal.delete.items." + element.item.toLowerCase())}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Card>
    );
}

export default DeleteAccount;