import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button"

export default function TypographyBlock({ title, description, action, uploadaction, actionName, nopadding, disabled, dense, variant, padding }) {
    return (
        <Box
            pr={3}
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
        >
            <Box p={!!padding ? padding : (nopadding ? 0 : 3)} lineHeight={1}>
                {title &&
                    <Box mb={1}>
                        <Typography variant={!!variant ? variant : (dense ? "h6" : "h5")}>{title}</Typography>
                    </Box>
                }
                {!dense && description &&
                    <Typography variant="button" color="text">
                        {description}
                    </Typography>
                }
            </Box>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} p={{ xs: 3, sm: 0 }}>
                {action &&
                    <Button disabled={disabled ?? false} onClick={action} variant="gradient" color={disabled ? "light" : "dark"} sx={{ height: "100%" }}>{actionName ?? ""}</Button>
                }
                {uploadaction &&
                    <Button component="label" disabled={disabled ?? false} variant="gradient" color={disabled ? "light" : "dark"} sx={{ height: "100%" }}>
                        {actionName ?? ""}
                        <input
                            hidden
                            accept="image/png ,image/jpg,image/jpeg"
                            type="file"
                            onChange={uploadaction}
                        />
                    </Button>
                }
            </Box>
        </Box >
    )
}
