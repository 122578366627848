import { Select, Stack, ListSubheader, MenuItem, FormControl, InputLabel } from "@mui/material";
import Box from "components/Box";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "components/DatePicker"
import moment from "moment";

function RangeDatePicker({ setStart, setEnd }) {
    const { t } = useTranslation();

    const [selectedFrame, setSelectedFrame] = useState(5);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment().add(14, 'days'));

    useEffect(() => {
        !!setStart && setStart(startDate)
    }, [startDate])

    useEffect(() => {
        !!setEnd && setEnd(endDate)
    }, [endDate])

    useEffect(() => {
        if (selectedFrame) {
            let start;
            let end;
            switch (selectedFrame) {
                case 5:
                    start = moment(0);
                    end = moment(0);
                    break;
                case 10:
                    start = moment();
                    end = moment().add(14, 'days');;
                    break;
                case 20:
                    start = moment();
                    end = moment().add(30, 'days');;
                    break;
                case 30:
                    start = moment();
                    end = moment().add(60, 'days');;
                    break;
                case 40:
                    start = moment().subtract(1, 'month').startOf('month')
                    end = moment().subtract(1, 'month').endOf('month')
                    break;
                case 50:
                    start = moment().startOf('month')
                    end = moment().endOf('month')
                    break;
                case 60:
                    start = moment().add(1, 'month').startOf('month')
                    end = moment().add(1, 'month').endOf('month')
                    break;
                case 70:
                    start = moment().subtract(1, 'year').startOf('year')
                    end = moment().subtract(1, 'year').endOf('year')
                    break;
                case 80:
                    start = moment().startOf('year')
                    end = moment().endOf('year')
                    break;
                case 90:
                    start = moment().add(1, 'year').startOf('year')
                    end = moment().add(1, 'year').endOf('year')
                    break;
                default:
                    start = startDate;
                    end = endDate;
                    break;
            }
            setStartDate(start)
            setEndDate(end)
        }
    }, [selectedFrame]);

    function handleDatePickerChange(e) {
        if (e.length > 1) {
            let start = moment(e[0]);
            let end = moment(e[1] ?? e[0]);

            setStartDate(start)
            setEndDate(end)
            setSelectedFrame(-1)
        }
    }

    return (
        <Stack>
            <Box sx={{ minWidth: 120 }} lineHeight={2.5}>
                <FormControl variant="standard">
                    <InputLabel >{t("common:label.selection")}:</InputLabel>
                    <Select
                        value={selectedFrame}
                        onChange={(e) => setSelectedFrame(e.target.value)}
                    >
                        <MenuItem value={5}>
                            {t("common:label.datepicker.future")}
                        </MenuItem>
                        <ListSubheader>{t("common:label.datepicker.next_heading")}</ListSubheader>
                        <MenuItem value={10}>
                            {t("common:label.datepicker.next14")}
                        </MenuItem>
                        <MenuItem value={20} >
                            {t("common:label.datepicker.next30")}
                        </MenuItem>
                        <MenuItem value={30}>
                            {t("common:label.datepicker.next60")}
                        </MenuItem>
                        <ListSubheader>{t("common:label.datepicker.month_heading")}</ListSubheader>
                        <MenuItem value={40} >
                            {t("common:label.datepicker.last_month")}
                        </MenuItem>
                        <MenuItem value={50} >
                            {t("common:label.datepicker.this_month")}
                        </MenuItem>
                        <MenuItem value={60} >
                            {t("common:label.datepicker.next_month")}
                        </MenuItem>
                        <ListSubheader>{t("common:label.datepicker.year_heading")}</ListSubheader>
                        <MenuItem value={70}>
                            {t("common:label.datepicker.last_year")}
                        </MenuItem>
                        <MenuItem value={80}>
                            {t("common:label.datepicker.this_year")}
                        </MenuItem>
                        <MenuItem value={90} >
                            {t("common:label.datepicker.next_year")}
                        </MenuItem>
                        <ListSubheader>{t("common:label.datepicker.custom_heading")}</ListSubheader>
                        <MenuItem value={-1} >
                            {t("common:label.datepicker.custom")}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <DatePicker
                options={{
                    mode: "range",
                    showMonths: 2
                }}
                input={{ placeholder: t('common:label.select_date') }}
                value={selectedFrame === 5 ? undefined : [startDate.format(), endDate.format()]}
                onChange={handleDatePickerChange}
            />
        </Stack>
    );
}

export default RangeDatePicker;