/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import menus from "constants/routes.footer";
import brand from "constants/brand";
import copyright from "constants/copyright";
import socials from "constants/routes.socialmedia";
import { useTranslation } from 'react-i18next';
import { Stack } from "@mui/material";
import LanguageSelection from "components/Input/LanguageSelection";

function DashboardFooter() {
  const { t } = useTranslation();
  return (
    <Box component="footer" sx={{ paddingTop: "32px" }}>
      <Container>
        <Grid container spacing={3}
          justifyContent="center"
          alignItems="center">
          <Grid item xs={12}>
            <Box sx={{ float: "right" }}>
              <LanguageSelection />
            </Box>
          </Grid>
          <Grid item xs={12} md={3} >
            <Box>
              <Link to={brand.route}>
                <Box component="img" src={brand.slogan} alt={brand.name} maxWidth="100%" />
              </Link>
            </Box>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              paddingRight="48px"
            >
              {socials.map(({ icon, link }, key) => (
                <Typography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                >
                  {icon}
                </Typography>
              ))}
            </Stack>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ ml: "auto", mb: 3, pt: "48px!important" }}>
              <Typography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {t(title)}
              </Typography>
              <Box component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href, clickAction }) => (
                  <Box key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <Typography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {t(name)}
                      </Typography>
                    ) : (
                      <Typography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        onClick={!!clickAction && clickAction}
                      >
                        {t(name)}
                      </Typography>
                    )}

                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default DashboardFooter;