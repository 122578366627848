import { Grid, Stack } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography"
import DefaultBackgroundCard from "components/Cards/BackgroundCards/DefaultBackgroundCard";
import { Trans } from "react-i18next";

export default function StepCard({ content, index }) {
    return (
        <Grid
            container
            spacing={3}
            direction={{ sm: "row", md: index % 2 === 1 ? "row-reverse" : "row" }}
            justifyContent="center"
            alignItems="stretch"
            sx={{ mt: 6 }}
        >
            <Grid item xs={12} md={4} >
                <DefaultBackgroundCard
                    image={content.image}
                    label={content.label}
                    title={content.title}
                    description={content.description}
                />
            </Grid>
            <Grid item xs={12} md={5} >
                <Stack direction="column" >
                    {
                        content.steps.map((item, index) =>
                            <Stack direction="row" >
                                <Box p={2}>
                                    <Typography variant="h1" mb={1} color="primary">
                                        {(index + 1) + "."}
                                    </Typography>
                                    {/* <Icon fontSize="large" color="primary">{icon}</Icon> */}
                                </Box>
                                <Box p={2}>
                                    <Typography variant="h5" mb={1}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="text" mb={2}>
                                        < Trans
                                            defaults={item.description}
                                        />
                                    </Typography>
                                </Box>
                            </Stack>
                        )}
                </Stack>
            </Grid>
        </Grid>
    )
}