import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";

export default function DevelopmentPage() {
    return (
        <KitLayout header={<Header />} >
            <Body />
        </KitLayout>
    )
}
