import React from "react";
import { Droppable } from "react-beautiful-dnd";

const grid = 8;

const getListStyle = isDraggingOver => ({
    padding: grid,
});

export default function DropBox({children}) {

    return (
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {children}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}