import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "components/Box";
import { useMaterialUIController, setLayout } from "context";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DashboardFooter from "./DashboardFooter";
import { useUser } from "hooks/userDataHook";
import { restGet } from "lib/rest";
import Legal from "./Charter/Settings/sections/Legal";
import AeroLoading from "components/Progress/AeroLoading";
import NoPermission from "components/Common/NoPermission";
import { useTranslation } from "react-i18next";
import MissingInput from "components/Input/MissingInput";

function DashboardLayout({ children }) {
    const { t } = useTranslation();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav } = controller;
    const { pathname } = useLocation();
    const { data } = useUser();
    const [warnings, setWarnings] = useState();

    useEffect(async () => {

        if (data?._id?.$oid) {
            let ret = await restGet((res) => setWarnings(res[0]), `identity/${data._id.$oid}/warnings`)
        }

    }, [data]);

    useEffect(() => {
        setLayout(dispatch, "dashboard");
    }, [pathname]);

    const { alias } = useParams()
    const { getRole } = useUser()

    const [content, setContent] = useState(<AeroLoading />)
    useEffect(() => {
     
        async function checkRequirements() {

            if (!!alias) {
                let charter = await restGet(null, `charter/${alias}`)
                if (!!charter && !!charter.data && !!charter.data[0]) {
                    let charterMetaData = await restGet(null, `account/${charter.data[0].account_id}/charter/${alias}`)
                    if (charter.data[0].account_id === undefined ||
                        (!!charterMetaData &&
                            !(charterMetaData.data.payouts_enabled) &&
                            !(charterMetaData.data.charges_enabled ?? false) &&
                            !(charterMetaData.data.details_submitted))) {
                        if (data._id.$oid === charter.data[0].owner_id.$oid) {
                            setContent(<Legal />)
                        } else {
                            setContent(<NoPermission title={t("common:label.noLegalTitle")} subtitle={t("common:label.noLegalSubtitle")} />)
                        }
                    } else if (alias && !!getRole(alias)) {
                        setContent(<>{children}</>)
                    } else {
                        setContent(<NoPermission />)
                    }
                }
            } else {
                setContent(<>{children}</>)
            }
        }

        !!data && checkRequirements()
    }, [data, alias])

    return (
        <Box
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                p: 3,
                position: "relative",

                [breakpoints.up("xl")]: {
                    marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                    transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
            })}
        >
            <DashboardNavbar />
            <Box width="100%" background="red">
                <MissingInput warnings={warnings}></MissingInput>
            </Box>
            {content}
            <DashboardFooter />
        </Box>
    );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DashboardLayout;