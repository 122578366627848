import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import brand from "constants/brand";
import Box from "components/Box";
import { Tooltip } from "@mui/material";
import Typography from "components/Typography";
import { restGet } from "lib/rest";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "components/Button";
import AeroLoading from "components/Progress/AeroLoading"
import AeroMaker from "assets/images/AeroMarker.png"

function AirportMapMarker({ airport, icao }) {
    const { t } = useTranslation();
    const K_WIDTH = 35;
    const K_HEIGHT = 50;
    return (
        <Tooltip title={airport ?
            <Box p={3} lineHeight={1}>
                <Typography variant="h4" color="white">
                    {airport.icao}
                </Typography>
                <Typography variant="h6" color="white">
                    Distance
                </Typography>
                <Typography variant="h5" color="white">
                    {Number(airport.distanceNM).toFixed(2) + " nm."}
                </Typography>
                <Button
                    component={Link}
                    to={"/airport/" + airport.icao}
                    variant="contained"
                    size="small"
                    color="white"
                    fullWidth
                >
                    {t('common:button.details')}
                </Button>
            </Box>
            :
            <Box p={3} lineHeight={1}>
                <Typography variant="h4" color="white">
                    {icao}
                </Typography>
            </Box>
        } placement="top">
            <Box
                style={{
                    position: 'absolute',
                    background: "radial-gradient(circle, rgba(255,149,255,.75) 0%, rgba(0,0,0,0) 75%)",
                    width: K_WIDTH,
                    height: K_HEIGHT,
                    left: -K_WIDTH / 2,
                    top: -K_HEIGHT,
                }}
            >
                <Box
                    style={{
                        margin: "auto",
                        width: K_WIDTH,
                        height: K_HEIGHT,
                        fontSize: K_HEIGHT + "px !important",
                    }}
                    component="img"
                    src={AeroMaker}
                    alt={brand.name}
                />
            </Box>
        </Tooltip>
    )
}

export default function AirportMap({ airport }) {
    const [isloading, setIsLoading] = useState(false)
    const [nearestAirports, setNearestAirport] = useState()
    const [center, setCenter] = useState()

    useEffect(() => {
        const fetch = async () => {
            setCenter([airport.location.coordinates[1], airport.location.coordinates[0]])
            function restCallback(resp) {
                !!resp && !!resp.data && setNearestAirport(resp.data)
            }
            setIsLoading(true)
            await restGet(restCallback, 'airport/' + airport.icao + '/nearest')
            setIsLoading(false)
        }
        !!airport && fetch()
    }, [airport])

    if (!nearestAirports || !airport) {
        return isloading ? <AeroLoading /> : null;
    }

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyD0h_9Pe4YFwohu4EQJ8hZ_V21gZFR8nZ8&v=3.exp" }}
                center={[airport.location.coordinates[1], airport.location.coordinates[0]]}
                defaultZoom={10}
            >
                <AirportMapMarker
                    lat={airport.location.coordinates[1]}
                    lng={airport.location.coordinates[0]}
                    icao={airport.icao}
                />
                {nearestAirports.map((port, i) =>
                    <AirportMapMarker
                        key={"map-marker-" + i}
                        lat={port.location.coordinates[1]}
                        lng={port.location.coordinates[0]}
                        airport={port}
                    />
                )}
            </GoogleMapReact>
        </div>
    )
}