/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes, { object } from "prop-types";

// @mui material components
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React components
import Typography from "components/Typography";
import Box from "components/Box";
import { useState } from "react";
import { useEffect } from "react";
import Icon from "components/Icon";
import { Stack } from "@mui/material";
function ServiceTableCell({ title, content, image, noBorder, ...rest }) {

  let template;

  function priocolors(prio) {

    var val = "success"
    if (prio == "2") {

      val = "warning"

    }

    else if (prio == "3") {

      val = "error"

    };

    return val ;

  }

  template = (
    <TableCell {...rest} align="center" sx={{ border: noBorder && 0 }}>
 
      <Box display="flex" flexDirection="column">
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {!!content && Object.values(content).map((user) => (
            !user.hasOwnProperty('prio') ? (<Stack direction="row" justifyContent={"start"} alignItems={"center"} width={"100%"}>
              <Typography
                variant="H6"
                color={"dark"}
                sx={{ lineHeight: 0, fontWeight: "bold" }}
              >
                <Icon fontSize="14px"  >{user?.icon ?? "check"}</Icon>
              </Typography>



              <Typography
                variant="caption"
                color="text"
                fontWeight="medium"
                textTransform="capitalize"
              >  {user?.text ?? user} </Typography>
            </Stack>) : (

              <Icon fontSize="42px" color={priocolors(user?.prio)}>{user?.icon ?? "check"}</Icon>
            )
          ))}
        </Box>
      </Box>
    </TableCell>
  );


  return template;
}

// Setting default values for the props of ServiceTableCell
ServiceTableCell.defaultProps = {
  image: "",
  noBorder: false,
};

// Typechecking props for the ServiceTableCell
ServiceTableCell.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any,
  image: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default ServiceTableCell;
