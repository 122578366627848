/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useTranslation } from "react-i18next";

function SimpleSidenav({ sidenavItems }) {
    const { t } = useTranslation();
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
        const itemKey = `item-${key}`;

        return (
            <Box key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
                <Typography
                    component="a"
                    href={`#${href}`}
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    sx={({
                        borders: { borderRadius },
                        functions: { pxToRem },
                        palette: { light },
                        transitions,
                    }) => ({
                        display: "flex",
                        alignItems: "center",
                        borderRadius: borderRadius.md,
                        padding: `${pxToRem(10)} ${pxToRem(16)}`,
                        transition: transitions.create("background-color", {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.shorter,
                        }),

                        "&:hover": {
                            backgroundColor: light.main,
                        },
                    })}
                >
                    <Box mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                        <Icon fontSize="small">{icon}</Icon>
                    </Box>
                    {label}
                </Typography>
            </Box>
        );
    });

    return (
        <Card
            sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "11%",
            }}
        >
            <Box
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
            >
                {renderSidenavItems}
            </Box>
        </Card>
    );
}

export default SimpleSidenav;