import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import PropTypes from "prop-types";
import { useModal } from "hooks/modalHook";
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from "@mui/material";
import './SuccessCheck.css'

function DefaultModal(
    {
        width,
        title,
        children,
        cancel,
        confirm,
        closeExplicit,
        onClose,
        helper,
        success
    }) {
    const { addModal, removeModal } = useModal();

    const [show, setShow] = useState(true);
    const toggleModal = (modal) => {
        setShow(!show)
        removeModal()
        !!onClose && onClose();
        !!modal && addModal(modal);
    }

    const handleClose = (event, reason) => {
        if (closeExplicit && (reason && (reason == "backdropClick" || reason == "escapeKeyDown")))
            return;
        toggleModal();
    }

    const handleCancel = () => {
        if (!!cancel)
            cancel.action && cancel.action();
        toggleModal(!!cancel && cancel.modal)
    }
    const handleConfirm = () => {
        if (!!confirm) {
            confirm.action && confirm.action();
            if (confirm.explicitAction) {
                confirm.explicitAction()
                return;
            }
        }
        toggleModal(!!confirm && confirm.modal)
    }

    return (
        <Modal open={show} onClose={handleClose} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={show} timeout={500}>
                <Box
                    position="relative"
                    width={width}
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <Box display="flex" alginItems="center" justifyContent="space-between" p={2}>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h5">{title} </Typography>
                            {
                                helper !== "" && <Tooltip arrow title={helper} followCursor><HelpIcon sx={{ marginLeft: "5px", marginTop: "3px" }}></HelpIcon></Tooltip>

                            }
                        </Box>
                        <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => toggleModal(null)} />
                    </Box>
                    <Divider sx={{ my: 0 }} />
                    {success &&
                        <Box p={2}>
                            <div class="wrapper">
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                        </Box>
                    }
                    <Box p={2}>
                        {children}
                    </Box>
                    <Divider sx={{ my: 0 }} />
                    <Box display="flex" justifyContent="space-between" p={1.5}>
                        <Box>
                            {cancel.show &&
                                <Button disabled={cancel.disabled ?? false} variant="gradient" color={cancel.color} onClick={handleCancel}>
                                    {cancel.text}
                                </Button>
                            }
                        </Box>
                        {confirm.show &&
                            <Button disabled={confirm.disabled ?? false} variant="gradient" color={confirm.color} onClick={handleConfirm}>
                                {confirm.text}
                            </Button>
                        }
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}

DefaultModal.defaultProps = {
    title: "",
    width: "50%",
    cancel: {
        text: "Cancel",
        color: "error",
        show: true,
        disabled: false
    },
    confirm: {
        text: "Confirm",
        color: "success",
        show: true,
        disabled: false
    },
    closeExplicit: false,
    helper: "",
    success: false
};

DefaultModal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    width: PropTypes.string,
    cancel: PropTypes.shape({
        text: PropTypes.string,
        show: PropTypes.bool,
        action: PropTypes.func,
    }),
    confirm: PropTypes.shape({
        text: PropTypes.string,
        show: PropTypes.bool,
        action: PropTypes.func,
    }),
    closeExplicit: PropTypes.bool,
    helper: PropTypes.string,
    success: PropTypes.bool
};

export default DefaultModal