import { Container, Grid } from "@mui/material";
import Button from "components/Button";
import Box from "components/Box";
import Typography from "components/Typography";
import { t } from "i18next";
import { restGet } from "lib/rest";
import { useState, useEffect } from "react"
import { Trans } from "react-i18next";
import Offices from "aeromate-kit/AboutUsPage/sections/Offices";

function Imprint(props) {
    const [imprintData, setImprintData] = useState()
    const [pageLang, setPageLang] = useState('en')

    useEffect(() => {
        restGet(setImprintData, 'dataset/legal');
    }, [])

    return (
        <Box component="section" py={{ xs: 12 }} id="info">
            <Container>
                <Grid container alignItems="top">

                    <Grid item xs={12}>
                        <Offices />
                    </Grid>

                    <Grid item xs={12} pt={10}>
                        <Typography variant="h3">
                            {t("common:label.imprint")}
                        </Typography>
                    </Grid>

                    {!!imprintData &&
                        <Grid item xs={12}>
                            <Typography pt={1} variant="body2">
                                {t("common:label.otherdomains")}
                            </Typography>

                            <Typography pt={1} pl={1} variant="body2">
                                aeromate.asia, aeromate.at, aeromate.be, aeromate.cc, aeromate.ch, aeromate.co.uk, aeromate.co.za, aeromate.cz, aeromate.de, aeromate.es, aeromate.eu, aeromate.it, aeromate.kiwi, aeromate.nl, aeromate.pl, aeromate.uk, aeromate.us
                            </Typography>

                            <Typography pt={1} variant="h5">
                                Social Media
                            </Typography>

                            <Typography pt={1} pl={1} variant="body2">
                                {imprintData[pageLang].social.map((sm, i) => (
                                    <li key={i}>
                                        <a href={sm.url} target='_blank'>{sm.url}</a>
                                    </li>
                                ))}
                            </Typography>
                        </Grid>
                    }

                    {!!imprintData &&
                        <Grid item xs={12}>
                            <Typography pt={1} variant="h5">
                                {imprintData[pageLang].legal_name}
                            </Typography>

                            <Typography pt={1} variant="body2">
                                {imprintData[pageLang].legal_street} <br />
                                {imprintData[pageLang].legal_city}, {imprintData[pageLang].legal_country}
                            </Typography>

                            <Typography pt={1} variant="body2">
                                {imprintData[pageLang].legal_phone}
                            </Typography>

                            <Typography pt={1} variant="body2">
                                {t("common:label.tollfree")}: {imprintData[pageLang].legal_tollfree}
                            </Typography>


                            <Box p={2}>
                                <Button
                                    variant="gradient"
                                    color="dark"
                                    onClick={() => window.open("mailto:" + imprintData[pageLang].legal_email.name + "@" + imprintData[pageLang].legal_email.domain + "." + imprintData[pageLang].legal_email.tld, '_blank')}
                                >
                                    Email:&nbsp;
                                    {imprintData[pageLang].legal_email.name}
                                    &nbsp;[AT]&nbsp;
                                    {imprintData[pageLang].legal_email.domain}
                                    &nbsp;.&nbsp;
                                    {imprintData[pageLang].legal_email.tld}
                                </Button>
                            </Box>

                            <Typography pt={1} variant="body2">
                                {t("common:label.register")}: {imprintData[pageLang].legal_register_court} {imprintData[pageLang].legal_register_no}<br />
                                {t("common:label.vatno")}: {imprintData[pageLang].legal_tax_no}
                            </Typography>

                            {imprintData[pageLang].legal_representation.map((rep, i) => (
                                <Typography pt={1} variant="body2" key={i}>
                                    {t("common:label." + rep.position)}:&nbsp;
                                    {rep.name}
                                </Typography>
                            ))}

                            <Typography pt={1} variant="body2">
                                {t("common:label.service")}
                            </Typography>

                            <Typography pt={1} variant="body2">
                                {t("common:label.content")}: {imprintData[pageLang].legal_representation[0].name}
                            </Typography>

                            <Typography pt={1} variant="body2">
                                <Trans
                                    components={{ a: <a /> }}
                                    i18nKey={"common:label.dispute"}
                                />
                            </Typography>

                            <Typography pt={1} variant="body2">
                                {t("common:label.arbitration")}
                            </Typography>
                        </Grid>
                    }

                </Grid>
            </Container>
        </Box >
    );
}

export default Imprint