import { Grid, Stack } from "@mui/material";
import DefaultModal from "components/Modal/DefaultModal";
import AeroLoading from "components/Progress/AeroLoading";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useRefresher } from "hooks/refreshHook";
import { restPost } from "lib/rest";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckrideEditorItem from "./components/CheckrideEditorItem";
import SaveModal from "./SaveModal";
import { restGet } from "lib/rest";
import Input from "components/Input";
import Box from "components/Box";

function CharterCheckrideModal({ data }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [sepaDirect, setSepaDirect] = useState(false);
    const [mandateReference, setMandateReference] = useState();
    const [creditorIdentifier, setCreditorIdentifier] = useState();
    const [initMandateReference, setInitMandateReference] = useState();
    const [initCreditorIdentifier, setInitCreditorIdentifier] = useState();
    const [validMR, setValidMR] = useState(false);
    const [validCI, setValidCI] = useState(false);
    const [checklist, setChecklist] = useState()
    const { refresh } = useRefresher();

    useEffect(() => {
        const re = /^[\s\d\w+?/-:().,']{1,32}$/;
        const found = mandateReference?.match(re);
        setValidMR(found)
    }, [mandateReference]);

    useEffect(() => {
        const ci = creditorIdentifier?.toUpperCase()
        const isDE = ci?.match(/^DE\d\d[\d\w]{3}0\d{10}$/);
        const isAT = ci?.match(/^AT\d\d[\d\w]{3}0\d{10}$/);
        const isCH = ci?.match(/^CH\d\d[\d\w]{3}\d{11}$/);
        const isBE = ci?.match(/^BE\d\d[\d\w]{3}\d{10}$/);
        const isBE_private = ci?.match(/^BE\d\d[\d\w]{3}\d{3}D\d{9}$/);
        const isLU = ci?.match(/^LU\d\d[\d\w]{3}0\d{18}$/);
        const isNL = ci?.match(/^NL\d\d[\d\w]{3}\d{12}$/);
        setValidCI(isDE || isAT || isCH || isBE || isBE_private || isLU || isNL)
    }, [creditorIdentifier]);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true)

            //fetch sepa
            if (data?.charter_id?.$oid) {
                var sepa = await restGet((res) => {
                    setSepaDirect(res?.data?.sepa_direct ?? false)
                }, `sepa/${data.charter_id.$oid}/id`)

                if (sepa?.data?.sepa_direct) {
                    await restGet((res) => {
                        setInitMandateReference(res?.data?.mr_display)
                        setInitCreditorIdentifier(res?.data?.ci_display)
                    }, `sepa/${data.requested.identity_id.$oid}/identity/${data.charter_id.$oid}/charter`)
                }
            }

            //Fetch Checklist
            if (!!data?.checklist?.items) {
                setChecklist(data.checklist.items)
            }
            setLoading(false)
        }
        !!data && fetch()
    }, [data])

    const onCheck = useCallback((index, checked, value) => {
        const tmp = [...checklist]
        tmp[index] = { ...checklist[index], checked: checked ?? false, value: value }
        setChecklist(tmp)
    }, [checklist])


    const getChecklistContent = useCallback(() => {
        if (!!checklist) {
            return (
                <Stack spacing={1}>
                    {checklist.map((item, index) => <CheckrideEditorItem key={'checklist-item-' + index} disabled={false} {...item} onCheck={(checked, value) => onCheck(index, checked, value)} />)}
                </Stack>
            )
        }
        return (<TypographyBlock title={t("no Checklist")} />)
    }, [checklist])

    const [approvable, setApprovable] = useState(false)
    useEffect(() => {
        setApprovable(_.every(checklist, 'checked'))
    }, [checklist])

    const saveAction = useCallback(async () => {
        setLoading(true)
        if (
            (!!creditorIdentifier && creditorIdentifier !== initCreditorIdentifier) ||
            (!!mandateReference && mandateReference !== initMandateReference)
        ) {
            await restPost(null, `sepa/${data.requested.identity_id.$oid}/identity/${data.charter_id.$oid}/charter`, { ci: creditorIdentifier, mr: mandateReference })
        }
        await restPost(null, `checkride/${data._id.$oid}/checklist`, { checklist: { items: checklist } })
        refresh();
        setLoading(false)
    }, [checklist, creditorIdentifier, mandateReference, initCreditorIdentifier, initMandateReference])


    return (
        <DefaultModal
            closeExplicit
            title={t("")}
            confirm={{
                disabled: (loading || !checklist || sepaDirect ? ((initCreditorIdentifier && initMandateReference) ? false : (_.isEmpty(creditorIdentifier) !== _.isEmpty(mandateReference)) || !(validMR && validCI)) : false),
                text: (approvable && (!sepaDirect || (validMR && validCI))) ? t("common:button.approve") : t("common:button.save"),
                color: "success",
                show: true,
                modal: <SaveModal saveAction={saveAction} actionName={(approvable && (!sepaDirect || (validMR && validCI))) ? t("common:button.approve") : t("common:button.save")} />
            }}
        >
            {loading && <AeroLoading />}
            {
                !loading && sepaDirect &&
                <Box px={3} pb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TypographyBlock
                                nopadding
                                title="Your organization is legitimized for the Sepa mandate"
                                description="Enter the mandate reference and creditor identification number here and make sure they are correct."
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                sx={{ width: "100%" }}
                                label={t("common:label.mr")}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                type="text"
                                success={validMR}
                                error={!!mandateReference ? !validMR : !!initMandateReference}
                                value={mandateReference ?? initMandateReference}
                                onChange={(e) => setMandateReference(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                sx={{ width: "100%" }}
                                label={t("common:label.ci")}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                type="text"
                                success={validCI}
                                error={!!creditorIdentifier ? !validCI : !!initCreditorIdentifier}
                                value={creditorIdentifier ?? initCreditorIdentifier}
                                onChange={(e) => setCreditorIdentifier(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
            {!loading && getChecklistContent()}
        </DefaultModal >
    );
}

export default CharterCheckrideModal;