import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import ChecklistInfos from "./ChecklistInfos";
import PropTypes from 'prop-types';
import AeroLoading from "components/Progress/AeroLoading";
import { useEffect, useState } from "react";

function ChecklistCard({ list, icon, nested, callback, charterview }) {
    const [isLoading, setIsLoading] = useState();
    const [checklist, setChecklist] = useState();

    useEffect(() => {
        if (!!nested) {
            setChecklist(list.map((v) => {
                var length = v.checklist.length
                var checked = _.filter(v.checklist, (e) => { return e.checked })
                var completion = (checked.length / length) * 100
                return {
                    color: completion == 100 ? "success" : (completion == 0) ? "dark" : "warning",
                    name: v.title,
                    status: completion == 100 ? "Completed" : (completion == 0) ? "Pending" : "In Progress",
                    value: completion,
                    checklist: v.checklist
                }
            }))
        }
    }, []);

    async function handleCallback(val, index) {
        setIsLoading(true)
        list[index].checklist = val
        setChecklist(list.map((v) => {
            var length = v.checklist.length
            var checked = _.filter(v.checklist, (e) => { return e.checked })
            var completion = (checked.length / length) * 100
            return {
                color: completion == 100 ? "success" : (completion == 0) ? "default" : "warning",
                name: v.title,
                status: completion == 100 ? "Completed" : (completion == 0) ? "Pending" : "In Progress",
                value: completion,
                checklist: v.checklist
            }
        }))
        await callback(list, index)
        setIsLoading(false)
    }
    return (
        <Card sx={{ height: "100%" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Your Progress
                </Typography>
                {isLoading && <AeroLoading customText={"Saving"} size="small" />}
                <Box display="flex" alignItems="flex-start">
                    <Box color="text" mr={0.5} lineHeight={0}>
                        <Icon color="inherit" fontSize="small">
                            {icon}
                        </Icon>
                    </Box>
                    {/* <Button
                        size="small"
                        variant="gradient"
                        color="dark">
                        View/Edit
                    </Button> */}
                </Box>
            </Box>
            <Box pt={3} pb={2} px={2}>
                <Box
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                >
                    {checklist?.map((e, i) => <ChecklistInfos key={"checklist-info-" + i} {...e} nested={nested} charterview={charterview} callback={(v) => handleCallback(v, i)} />)}
                </Box>
            </Box>
        </Card>
    );
}

ChecklistCard.defaultProps = {
    nested: false,
};

ChecklistCard.propTypes = {
    nested: PropTypes.bool,
    list: PropTypes.array.isRequired,
    icon: PropTypes.string,
};

export default ChecklistCard;