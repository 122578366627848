/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { convertTimeDatabaseToFrontend } from "lib/functions";
import moment from "moment";
import { useEffect, useState } from "react";
import { restPost } from "lib/rest";
import { useRefresher } from "hooks/refreshHook";
import { useModal } from "hooks/modalHook";
import AlertModal from "components/Modal/AlertModal";
import { useTranslation } from "react-i18next";

function DocumentCards({ id, type, issuedate, expirydate, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [color, setColor] = useState(null);
  const [warn, setWarn] = useState(false);
  const [typocolor, setTypo] = useState("dark");
  const [icon, setIcon] = useState("check_circle");
  const [iconColor, setIconColor] = useState("success");
  const { addModal, removeModal } = useModal()
  const { refresh } = useRefresher();
  const [diff, setDiff] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    const start = moment(Date.now());
    const end = moment(convertTimeDatabaseToFrontend(expirydate));
    const diff = end.diff(start)
    const diffnumber = moment.duration(diff).asDays().toFixed();


    if (diffnumber >= 31) {
      setColor(null);
      setDiff(diffnumber)

    }
    if (diffnumber >= 100) {

      setDiff("+100")

    }
    if (diffnumber <= 31) {
      setColor("#F0AD4E");
      setIcon("warning_amber")
      setIconColor("light")
      setTypo("light")
      setDiff(diffnumber)


    }
    if (diffnumber <= 14) {
      setColor("#F0AD4E");
      setIcon("warning_amber")
      setIconColor("error")
      setTypo("light")
      setWarn(true)
      setDiff(diffnumber)

    }
    if (diffnumber <= 7) {
      setColor("#f94449");
      setTypo("light")
      setIcon("warning_amber")
      setIconColor("warning")
      setWarn(true)
      setDiff(diffnumber)

    }
    if (diffnumber <= 0) {
      setColor("#4a4a4a");
      setTypo("light")
      setIconColor("error")
      setIcon("clear")
      setDiff("")

    }

  }, [refresh]);

  // 100+ 
  function onDelete() {
    addModal(<AlertModal title="Are you sure you want to delete this certificate?" description="Are you sure you want to delete this certificate?" onValueChange={Handlechange}></AlertModal>)



  }

  const Handlechange = async (value) => {
    await restPost(null, 'currency/' + id)

    refresh()

  }

  return (
    <Box
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={color ? color : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <Box width="100%" display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          color="white"
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography color={iconColor} variant="button" fontWeight="large" textTransform="capitalize">
              <Icon fontSize="large">{icon}</Icon>
            </Typography>
            <Typography mr={5} color={iconColor} variant="button" fontWeight="large" textTransform="capitalize" fontSize="small">
              {diff} {diff && "days left"}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Box mr={1}>

              <Button variant="text" color="error" onClick={() => onDelete()}>
                <Icon>delete</Icon>&nbsp;delete
              </Button>
            </Box>
          </Box>
        </Box>
        <Box mb={1} lineHeight={0}>
          <Typography variant="caption" color={typocolor ? typocolor : "text"} >
          {t("dashboard:personal.upload.certificate")}:&nbsp;&nbsp;&nbsp;
            <Typography color={typocolor ? typocolor : "text"} variant="caption" fontWeight="medium" textTransform="capitalize">
              {t("common:label.license." + type)}
            </Typography>
          </Typography>
        </Box>
        <Box mb={1} lineHeight={0}>
          <Typography variant="caption" color={typocolor ? typocolor : "text"}>
          {t("dashboard:personal.upload.issue")}:&nbsp;&nbsp;&nbsp;
            <Typography variant="caption" fontWeight="medium" color={typocolor ? typocolor : "text"}>
              {moment(convertTimeDatabaseToFrontend(issuedate)).format("L")}
            </Typography>
          </Typography>
        </Box>
        <Typography variant="caption" color={typocolor ? typocolor : "text"}>
        {t("dashboard:personal.upload.expire")}:&nbsp;&nbsp;&nbsp;
          <Typography variant="caption" fontWeight="medium" color={typocolor ? typocolor : "text"}>
            {expirydate ? moment(convertTimeDatabaseToFrontend(expirydate)).format("L") : "No expiry"}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}

// Setting default values for the props of Bill
DocumentCards.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
DocumentCards.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default DocumentCards;
