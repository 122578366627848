import { Grid } from "@mui/material";
import PropTypes from 'prop-types';
import Typography from "components/Typography";
import { t } from "i18next";
import GoogleMapReact from 'google-map-react';
import Box from "components/Box";
import AeroMaker from "assets/images/AeroMarker.png"
import brand from "constants/brand";


function LegalEntityCard({ entity, pageLang }) {
    const K_WIDTH = 35;
    const K_HEIGHT = 50;

    return (
        <Grid item xs={12} lg={6} xl={4} pr={3}>
            <Typography variant="h6" pt={2}>
                {entity.caption[pageLang]}
            </Typography>

            <div style={{ height: '20vh', width: '90%', paddingLeft: 2, paddingRight: 2 }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyD0h_9Pe4YFwohu4EQJ8hZ_V21gZFR8nZ8&v=3.exp" }}
                    center={[entity.location[0], entity.location[1]]}
                    defaultZoom={15}
                >
                    <Box
                        lat={entity.location[0]}
                        lng={entity.location[1]}
                        style={{
                            position: 'absolute',
                            background: "radial-gradient(circle, rgba(255,149,255,.75) 0%, rgba(0,0,0,0) 75%)",
                            width: K_WIDTH,
                            height: K_HEIGHT,
                            left: -K_WIDTH / 2,
                            top: -K_HEIGHT,
                        }}
                    >
                        <Box
                            style={{
                                margin: "auto",
                                width: K_WIDTH,
                                height: K_HEIGHT,
                                fontSize: K_HEIGHT + "px !important",
                            }}
                            component="img"
                            src={AeroMaker}
                            alt={brand.name + " logo"}
                        />
                    </Box>
                </GoogleMapReact>
            </div>

            <Typography variant="h5" pl={1} pt={1}>
                {entity.name}
            </Typography>

            <Typography variant="body2" pl={2} pt={1}>
                {entity.street} <br />
                {entity.city} <br />
                {entity.country}
            </Typography>

            <Typography variant="body2" pl={1} pt={1}>
                <b>{t("common:label.contact")}</b>
            </Typography>

            <Typography variant="body2" pl={2} >
                {entity.phone && <><b>T: </b>{entity.phone}</>} <br />
                {entity.fax && <><b>F: </b>{entity.fax}</>} <br />
                <b>Email: </b>
                <a
                    href="javascript:void(0)"
                    onClick={() => window.open("mailto:" + entity.email.name + (String.fromCharCode(Math.pow(2, 6))) + entity.email.domain + "." + entity.email.tld, '_blank')}
                >
                    {entity.email.name} {String.fromCharCode(Math.pow(2, 6))} {entity.email.domain} . {entity.email.tld}
                </a>
            </Typography>

            <Typography variant="body2" pl={1} pt={1}>
                <b>{t("common:label.mgmt")}</b>
            </Typography>

            {entity?.management?.map((position, i) => (
                <Typography variant="body2" pl={2} key={'management-' + i}>
                    {t("common:label." + position.position)}: {position.name}
                </Typography>
            ))}

            <Typography variant="body2" pl={1} pt={1}>
                <b>{t("common:label.legal")}</b>
            </Typography>

            <Typography variant="body2" pl={2}>
                {!!entity.register_court && <>{t("common:label.register")}:<br /> {entity.register_court + ' ' + t("common:label.court") + ' ' + entity.register_no}<br /></>}
                {!!entity.vat_no && <>{t("common:label.vatno") + ' ' + entity.vat_no}<br /></>}
                {!!entity.state && <>{entity.state}<br /></>}

            </Typography>


        </Grid>
    );
}

LegalEntityCard.propTypes = {
    entity: PropTypes.object.isRequired,
    pageLang: PropTypes.string.isRequired
};

export default LegalEntityCard