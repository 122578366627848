import { Stack, Grid, Divider, FormControl, InputLabel, Select, MenuItem, Autocomplete } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import Input from "components/Input";
import Typography from "components/Typography";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { DataGrid, } from "@mui/x-data-grid";
import _ from "lodash";
import DeleteIcon from '@mui/icons-material/Delete';
import colors from "assets/theme/base/colors";
import DatePicker from "components/DatePicker";
import { restGet } from "lib/rest";

export default function InfoStep({ setData, data, images, types }) {
    const { t } = useTranslation()
    const [type, setType] = useState(null);
    const [issuedate, setIssuedate] = useState("");
    const [expiry, setExpiry] = useState("");
    const [weight, setWeight] = useState(0);
    const [docTypes, setDocTypes] = useState([])
    const [pdf, setPdf] = useState(false)
    const [image, setImage] = useState(false)

    console.log(types)

    const handleExpiry = (event) => {

        console.log(event)
        if (event.length != 0) {
            setExpiry(event[0].getTime())
        } else {

            setExpiry(null)
        }


    }

    useEffect(() => {
        restGet(setDocTypes, 'dataset/documentType')
    }, [])

    useEffect(() => {
        if (!!data?.equipment) {
            setType(data?.equipment?.type);
            setIssuedate(data?.equipment?.issuedate);
            setExpiry(data?.equipment?.expirydate);
        }
        if (types == "pdf") {
            setPdf(true)
        }
        if (types == "img") {
            setImage(true)
        }
    }, []);
    useEffect(() => {
        let timeout = setTimeout(() => {
            if (type?.no_expiry_date == true) {

                if (expiry != null) {

                    var result = {
                        type: type,
                        typelabel: type?.caption?.en,
                        issuedate: issuedate,
                        expirydate: expiry,
                    }
                }else
                {
                    var result = {
                        type: type,
                        typelabel: type?.caption?.en,
                        issuedate: issuedate,
                    }
                }

            } else if (!!expiry) {

                var result = {
                    type: type,
                    typelabel: type?.caption?.en,
                    issuedate: issuedate,
                    expirydate: expiry,
                }


            }

            console.log(expiry)
            console.log(result)
            setData(result)
        }, 500);

        return () => { clearTimeout(timeout) }
    }, [type, issuedate, expiry])

    
    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:charter.aircrafts.steps.equipment.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:charter.aircrafts.steps.equipment.description")}
                </Typography>
            </Box>

            <Box mt={2}>
                <Grid container spacing={1}>
                    <Grid item xs={4} spacing={3}
                        alignItems="flex-end">
                        <Grid item xs={12}>
                            <Box ml={1} mr={1} mb={1}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("dashboard:personal.upload.document")}
                                        description={null}
                                    />
                                    <Box mt={-1} width="100%">
                                        <Autocomplete
                                            id="cur_type"
                                            options={docTypes}
                                            value={type}
                                            groupBy={(docTypes) => docTypes.authority ?? 'undef'}
                                            getOptionLabel={(docTypes) => (docTypes.caption.en ?? docTypes.key)}
                                            onInputChange={(event, value) => {
                                                if (event?.type === "change") {
                                                 setType(value);
                                                }
                                            }}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <Input value={type} type="text" {...params} required />}
                                           // onChange={(event, selType) => setType(selType)}
                                            onChange={(event, value) => { setType(value); }}

                                            />
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml={1} mr={1} mb={1}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("dashboard:personal.upload.issue_date")}
                                        description={null}
                                    />
                                    <Box mt={-1} width="100%">
                                        <DatePicker
                                            options={{
                                                minuteIncrement: 60,
                                                noCalendar: false,
                                                allowInput: true

                                            }}
                                            value={issuedate}
                                            onChange={(event) => setIssuedate(event[0].getTime())}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml={1} mr={1} mb={1}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("dashboard:personal.upload.expiry_date")}
                                        description={null}
                                    />
                                    <Box mt={-1} width="100%">
                                        <DatePicker
                                            options={{
                                                minuteIncrement: 60,
                                                noCalendar: false,
                                                allowInput: true

                                            }}

                                            value={expiry}
                                            onChange={(event) => handleExpiry(event)}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>

                    </Grid>
                    <Grid item xs={8}>

                        {image && <img src={images} style={{ overflow: "hidden", maxWidth: "100%", maxHeight: "100%" }} alt="upload" />}
                        {pdf && <iframe
                            style={{ width: "100%", height: "75%", minHeight: "400px" }}
                            src={images ?? ''}
                        >
                        </iframe>}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
