import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import OverviewCard from "components/Cards/CustomCards/OverviewCard";
import { bookings_url } from "constants/global";
import { useTranslation } from "react-i18next";

function Overview() {
    const { t } = useTranslation();

    return (
        <Box component="section" py={{ xs: 0, lg: 7 }}>
            <Box mt={-16}>
                <Container>
                    <Box position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
                        <Grid container spacing={3} justifyContent="center" alignItems="stretch">
                            <Grid item xs={12} lg={4}>
                                <Box style={{ height: "100%" }}>
                                    <OverviewCard
                                        price={{
                                            label: t("landingPage:entry.atos.label"),
                                            value: t("landingPage:entry.atos.title")
                                        }}
                                        specifications={t("landingPage:entry.atos.specifications", { returnObjects: true })}
                                        action={{
                                            type: "internal",
                                            route: "for-atos",
                                            color: "primary",
                                            label: t("landingPage:entry.atos.button"),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Box mt={{ xs: 0, lg: -6 }} style={{ height: "100%" }}>
                                    <OverviewCard
                                        color="primary"
                                        price={{
                                            label: t("landingPage:entry.pilots.label"),
                                            value: t("landingPage:entry.pilots.title")
                                        }}
                                        specifications={t("landingPage:entry.pilots.specifications", { returnObjects: true })}
                                        action={{
                                            type: "internal",
                                            route: "for-pilots",
                                            color: "dark",
                                            label: t("landingPage:entry.pilots.button"),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Box  style={{ height: "100%" }}>
                                    <OverviewCard
                                        price={{
                                            label: t("landingPage:entry.owner.label"),
                                            value: t("landingPage:entry.owner.title")
                                        }}
                                        specifications={t("landingPage:entry.owner.specifications", { returnObjects: true })}
                                        action={{
                                            type: "external",
                                            route: bookings_url,
                                            color: "primary",
                                            label: t("landingPage:entry.owner.button"),
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default Overview;