import Container from "@mui/material/Container";
import Box from "components/Box";


function Header({ bgImage }) {
    return (<Box
        display="flex"
        alignItems="center"
        height="25.5rem"
        sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
        <Container>
            {/* brand.slogan */}
        </Container>
    </Box>
    );
}

export default Header;