
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "components/Box";
import CharterLegalInfo from "components/Charter/CharterLegalInfo";
import CharterAircrafts from "components/Charter/CharterAircrafts";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AeroLoading from "components/Progress/AeroLoading"

function AircraftCharterTab({ alias }) {
    const [charter, setCharter] = useState();
    const { t } = useTranslation();
    const [isloading, setIsLoading] = useState(false)

  
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setCharter(resp.data[0])
            }
            setIsLoading(true)
            await restGet(restCallback, 'charter/' + alias)
            setIsLoading(false)
        }
        !!alias && fetch()
    }, [alias]) 

    if (!charter|| isloading) {
        return isloading ? <AeroLoading /> : null;
    }
    return (
        <Box py={6} id="partner">
            <Container>
                <Stack spacing={2}>
                    <CharterLegalInfo charter={charter} />
                    <Typography variant="h4">
                        {t("aircraftPage:charter.aircrafts")}
                    </Typography>
                    <CharterAircrafts charter={charter} />
                </Stack>
            </Container>
        </Box >
    );
}

export default AircraftCharterTab;