/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";

function DefaultNextStepCard({ color, icon, title, description, button }) {
    return (
        <Card>
            <Box p={2} mx={3} display="flex" justifyContent="center">
                <Box
                    display="grid"
                    justifyContent="center"
                    alignItems="center"
                    bgColor={color}
                    color="white"
                    width="4rem"
                    height="4rem"
                    shadow="md"
                    borderRadius="lg"
                    variant="gradient"
                >
                    <Icon fontSize="default">{icon}</Icon>
                </Box>
            </Box>
            <Box pb={2} px={2} textAlign="center" lineHeight={1.25}>
                <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                </Typography>
                <Typography variant="caption" color="text" fontWeight="regular">
                    {description}
                </Typography>
                <Divider />
                {button}
            </Box>
        </Card>
    );
}

// Setting default values for the props of DefaultNextStepCard
DefaultNextStepCard.defaultProps = {
    color: "info",
};

// Typechecking props for the DefaultNextStepCard
DefaultNextStepCard.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.node.isRequired,
};

export default DefaultNextStepCard;
