import { Grid, Stack, Container, Card, AppBar, Tabs, Tab, Divider, Rating } from "@mui/material";
import Avatar from "components/Avatar";
import Box from "components/Box";
import Button from "components/Button";
import StarRating from "components/Rating/StarRating";
import Typography from "components/Typography";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import { useModal } from "hooks/modalHook";
import { useEffect, useState } from "react";
import { restPut } from "lib/rest";
import Alert from "components/Alert";


export default function Body(props) {
    const { addModal, removeModal } = useModal();
    const [warning, setWarn] = useState(false);

    const { icao, mail, id } = props;

    const handleUnsubscribe = async () => {

        const object = { icao: icao, id: id }
        await restPut(null, `newsletter/newsletter`, object)
        setWarn(true);
        refresh();
    }


    return (
        <Box >
            <Container>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}>
                    <Card
                        sx={{
                            position: "relative",
                            mt: -6.5,
                            mx: 3,
                            py: 2,
                            px: 2,
                            width: "100%",
                            opacity: "100%"
                        }}
                    >
                        <Grid container spacing={3} alignItems="center">


                            <Grid sm={12} item  >
                                <Stack direction="column">
                                    <Box alignItems="center" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                        <Typography variant="h6" sx={{ color: "primary", fontWeight: "bold" }} >Unsubscribe</Typography>
                                    </Box>
                                    <Box mt={0} width="100%" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant="h6">
                                            Are you sure you want to say Goodbye?
                                        </Typography>
                                    </Box>

                                    <Box mt={1} width="100%" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant="h6">
                                            We will be sad to see you go but if you no longer want to receive emails from {icao}
                                        </Typography>
                                    </Box>
                                    <Box mt={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Button onClick={() => handleUnsubscribe()} variant="contained" color="dark" sx={{ width: "120px" }} >
                                            Unsubscribe
                                        </Button></Box>
                                </Stack>


                            </Grid>
                          

                        </Grid>
                        {warning &&
                                    <Alert color="primary" p={2} mt={3}>
                                        You have been unsubcribed
                                    </Alert>}
                    </Card>
                </Stack>
            </Container >
        </Box >
    )
}