import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import ChecklistInfos from "./ChecklistInfos";
import PropTypes from 'prop-types';
import AeroLoading from "components/Progress/AeroLoading";
import { useEffect, useState, useCallback } from "react";
import { Stack } from "@mui/material";
import CheckrideViewItem from "components/Checklist/Checkride/ViewItem";

function ExamsCard({ list, charterview, callback }) {
    const [checklist, setChecklist] = useState([]);
    const [completion, setCompletion] = useState(0);
    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        setChecklist(list)
    }, []);

    async function onCheck(index, checked, value) {
        setIsLoading(true)

        const tmp = [...checklist]
        tmp[index] = { ...checklist[index], checked: checked ?? false, value: value }
        setChecklist(tmp)

        await callback(tmp)
        setIsLoading(false)
    }

    useEffect(() => {
        var length = checklist.length
        var checked = _.filter(checklist, (e) => { return e.checked })
        setCompletion((checked.length / length) * 100)
    }, [checklist]);

    return (
        <Card sx={{ height: "100%" }}>
            <Box pt={3} px={2}>
                <Box
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                >
                    <ChecklistInfos
                        color={completion == 100 ? "success" : (completion == 0) ? "default" : "warning"}
                        name={isLoading ? <AeroLoading customText={"Saving"} size="small" /> : "Your Exams"}
                        status={completion == 100 ? "Completed" : (completion == 0) ? "Pending" : "In Progress"}
                        value={completion} />
                </Box>
            </Box>
            <Box pb={2} px={2}>
                <Stack spacing={1}>
                    {checklist && checklist?.map((item, index) => <CheckrideViewItem key={"exam-item-" + index} disabled={!charterview} {...item} onCheck={(checked, value) => onCheck(index, checked, value)} />)}
                </Stack>
            </Box>
        </Card>
    );
}

ExamsCard.propTypes = {
    list: PropTypes.array.isRequired,
    icon: PropTypes.string,
};

export default ExamsCard;