import Grid from "@mui/material/Grid";
import PersonalInfo from "./sections/PersonalInfo";

export default function Body() {
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <PersonalInfo />
                </Grid>
            </Grid>
        </div>
    )
}