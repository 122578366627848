import { Container, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material"
import Box from "components/Box"
import { useTranslation } from "react-i18next"
import CheckIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/CheckBoxOutlineBlank';

function AircraftSettingTab({ booking_setting }) {

    const { t } = useTranslation()

    const settings = [
        { key: "bookleadtime", type: "hours" },
        { key: "minbooking", type: "minutes" },
        { key: "maxbooking", type: "minutes" },
        { key: "nocheckride", type: "boolean" },
        { key: "enablewaitinglist", type: "boolean" }
    ]

    function CheckType(index) {
        switch (index.type) {
            case "hours":
                return t("common:label.hours")
            case "minutes":
                return t("common:label.minutes")
            default:
                break;
        }
    }

    function CheckResult(index) {
        if (!!booking_setting) {
            switch (booking_setting[index.key]) {
                case true:
                    return <Tooltip title="YES"><CheckIcon /></Tooltip>
                case false:
                    return <Tooltip title="NO"><ClearIcon /></Tooltip>
                default:
                    return booking_setting[index.key];
            }
        }
    }

    return (
        <Box py={6} id="partner">
            <Container>
                <Table>
                    <TableBody>
                        {settings.map((index, i) =>
                            <TableRow key={"setting-" + i}>
                                <TableCell>
                                    {t("aircraftPage:settings." + index.key)}
                                </TableCell>
                                <TableCell>
                                    {CheckResult(index)}
                                </TableCell>
                                <TableCell>
                                    {CheckType(index)}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Container>
        </Box>
    )
}

export default AircraftSettingTab