import Box from "components/Box";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CalendarRoot from "components/Calendar/CalendarRoot";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { useEffect, useRef, useState } from "react";
import { restGet } from "lib/rest";
import { eventBuilder } from "lib/functions";
import { useUser } from "hooks/userDataHook";
import { businessHoursBuilder } from "lib/functions";
import moment from "moment";
import { Grid, Stack, Typography } from "@mui/material";
import TypographyBlock from "components/Typography/TypographyBlock";
import DatePicker from "components/DatePicker";
import Button from "components/Button";
import ClearIcon from '@mui/icons-material/ErrorOutline';
import Alert from "components/Alert";
import colors from "assets/theme/base/colors"
import { useModal } from "hooks/modalHook";
import CalendarModal from "components/Modal/CalendarModal/CalendarModal"
import { useRefresher } from "hooks/refreshHook";
import { DocumentTitle } from "hooks/useDocumentTitle";
import { convertFrontendToTimeDatabase } from "lib/functions";
import { daysToMilliseconds } from "lib/time";
import { createDemo1Bookings } from "lib/functions";

export default function Body() {
    const { t } = useTranslation();
    let { REG, ALIAS } = useParams();
    const calendarRef = useRef();
    const { addModal } = useModal();
    const [homebase, setHomebase] = useState();
    const [aircraftCharterAlias, setAircraftCharterAlias] = useState();
    const { refresher } = useRefresher();

    const [title, setTitle] = useState(t("routes:calendar.title"))
    useEffect(() => {
        !!REG && setTitle(t("routes:calendar.title") + " " + REG)
    }, [REG])
    /**
     * Get Aircraft
     * To get Homebase
     * for sunset/sunrise fetch
     */
    useEffect(() => {
        function restCallback(resp) {
            if (!!resp && !!resp.data) {
                setHomebase(resp.data[0].homebase)
                setAircraftCharterAlias(resp.data[0].charter[0].alias)
                setTitle()
            }
        }
        !!REG && restGet(restCallback, 'aircraft/' + REG)
    }, [REG, refresher])

    const { isSignedIn, data } = useUser();

    const [timezone, setTimezone] = useState("UTC")
    const [selectedDate, setSelectedDate] = useState();

    const [sunData, setSunData] = useState([]);
    const [sunEvents, setSunEvents] = useState([]);
    const [businessHours, setBusinessHours] = useState([]);

    var [eventStart, setEventStart] = useState();
    var [eventEnd, setEventEnd] = useState();
    function handleSelect(event) {
        setEventStart(event.start.getTime());
        setEventEnd(event.end.getTime());
    }

    function handleStart(event) {
        setEventStart(event[0].getTime());
    }

    function handleEnd(event) {
        setEventEnd(event[0].getTime());
    }

    const [errors, setErrors] = useState([])
    useEffect(() => {
        if (!!calendarRef && !!eventStart && !!eventEnd) {
            let calendarApi = calendarRef.current.getApi()
            calendarApi.select(eventStart, eventEnd)
            let body = {
                handover: convertFrontendToTimeDatabase(eventStart),
                return: convertFrontendToTimeDatabase(eventEnd)
            };
            restGet((e) => setErrors(!!e && !!e.data && !!e.data.errors && e.data.errors), `aircraft/${REG}/availability`, body)
        }
    }, [eventStart, eventEnd])

    useEffect(() => {
        if (!!homebase && !!selectedDate) {
            restGet(setSunData, "sun", {
                icao: homebase,
                ts_start: convertFrontendToTimeDatabase(selectedDate.start.getTime() - daysToMilliseconds(1)),
                ts_end: convertFrontendToTimeDatabase(selectedDate.end.getTime() + daysToMilliseconds(1))
            });
        }
    }, [selectedDate, homebase])

    useEffect(() => {
        if (!!sunData && sunData.data) {
            let newSunEvents = [];
            let tmpBusinessHours = [];
            sunData.data.map((item, id) => {
                let tmpbh = businessHoursBuilder(id, item.sunrise_utc - 30 * 60, item.sunset_utc + 30 * 60)
                if (Array.isArray(tmpbh)) {
                    tmpbh.forEach((x) => { tmpBusinessHours.push(x) })
                }
                else {
                    tmpBusinessHours.push(tmpbh)
                }
                newSunEvents.push(
                    eventBuilder(
                        "rs" + id,
                        "Sunrise " + item.icao,
                        item.sunrise_utc - 30 * 60,
                        item.sunrise_utc,
                        null,
                        colors.gradients.primary.state
                    )
                );
                newSunEvents.push(
                    eventBuilder(
                        "ss" + id,
                        "Sunset " + item.icao,
                        item.sunset_utc,
                        item.sunset_utc + 30 * 60,
                        null,
                        colors.gradients.primary.main
                    )
                );
                return item;
            });
            setBusinessHours(tmpBusinessHours)
            setSunEvents(newSunEvents);
        }
    }, [sunData]);

    const [bookingData, setBookingData] = useState([]);
    const [bookingEvents, setBookingEvents] = useState([]);
    useEffect(() => {
        if (!!selectedDate) {
            if (!!REG || !!ALIAS) {
                let param = {
                    ts_start: convertFrontendToTimeDatabase(selectedDate.start.getTime() + daysToMilliseconds(1)),
                    ts_end: convertFrontendToTimeDatabase(selectedDate.end.getTime() + daysToMilliseconds(1))
                };
                if (REG) param.reg = REG;
                if (ALIAS) param.alias = ALIAS;
                restGet(setBookingData, "booking/_all", param);
            }
        }
    }, [selectedDate, REG, ALIAS, refresher])

    useEffect(() => {
        if (!!bookingData) {
            if (bookingData.data) {
                const colors = [
                    "#808080",
                    "#800000",
                    "#808000",
                    "#008000",
                    "#008080",
                    "#000080",
                    "#800080",
                    "#404040",
                    "#400000",
                    "#404000",
                    "#004000",
                    "#004040",
                    "#000040",
                    "#400040"
                ];
                let newBookEvents = [];
                let ac = {};
                bookingData.data.map(item => {
                    if (item.identity_id.$oid !== data._id.$oid) {
                        if (!ac[item.reg]) ac[item.reg] = colors[Object.keys(ac).length];
                        newBookEvents.push(
                            eventBuilder(
                                item._id.$oid,
                                item.reg + " " + item.status + " " +
                                (!!item.identity &&
                                    (!!item.identity[0] && !!item.identity[0].name)
                                    ? item.identity[0].name
                                    : ""),
                                item.handover,
                                item.return,
                                null,
                                ac[item.reg]
                            )
                        );
                    }
                    return item;
                });
                setBookingEvents(newBookEvents);
            } else {
                //setBookingEvents(createDemo1Bookings())
            }
        }
    }, [bookingData]);

    const [identityBookingsData, setIdentityBookingsData] = useState([]);
    const [identityBookingsEvents, setIdentityBookingsEvents] = useState([]);
    useEffect(() => {
        if (!!selectedDate && !!isSignedIn()) {
            restGet(setIdentityBookingsData, "booking/_all", {
                identity: "_self",
                ts_start: convertFrontendToTimeDatabase(selectedDate.start.getTime() + daysToMilliseconds(1)),
                ts_end: convertFrontendToTimeDatabase(selectedDate.end.getTime() + daysToMilliseconds(1))
            });

        }
    }, [selectedDate, refresher])

    useEffect(() => {
        if (!!identityBookingsData && identityBookingsData.data) {
            let newIdentityEvents = [];
            identityBookingsData.data.map(item => {
                newIdentityEvents.push(
                    eventBuilder(
                        item._id.$oid,
                        item.status + " on " + item.reg,
                        item.handover,
                        item.return,
                        null,
                        "#7CBBD4"
                    )
                );
                return item;
            });
            setIdentityBookingsEvents(newIdentityEvents);
        }
    }, [identityBookingsData]);

    useEffect(() => {
        if (bookingEvents && identityBookingsEvents) {
            if (bookingEvents.length && identityBookingsEvents.length) {
                var tmp = bookingEvents.filter(item => !identityBookingsEvents.some(i => i.id === item.id));
                //if (tmp !== bookingEvents) setBookingEvents(tmp)
            }
        }
    }, [bookingEvents, identityBookingsEvents])

    return (
        <Box p={3}>
            <DocumentTitle title={title} />
            <Box>
                <TypographyBlock
                    variant="h4"
                    padding={2}
                    title={REG + ", " + aircraftCharterAlias}
                    description={t("common:info.utc_time")}
                />
            </Box>
            <Grid container direction={{ md: 'column-reverse', lg: 'row' }}>
                <Grid item md={12} lg={9}>
                    <CalendarRoot p={2} ownerState={{ darkMode: false }} sx={{ overflow: "auto" }}>
                        <FullCalendar
                            height={600}
                            eventShortHeight={35}
                            ref={calendarRef}
                            plugins={[
                                dayGridPlugin,
                                timeGridPlugin,
                                interactionPlugin,
                                momentPlugin,
                                momentTimezonePlugin
                            ]}
                            initialView="timeGridWeek"
                            eventSources={[
                                { events: sunEvents },
                                { events: bookingEvents },
                                { events: identityBookingsEvents }
                            ]}
                            businessHours={businessHours}
                            slotLabelFormat={
                                { hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false, meridiem: false, }
                            }
                            allDaySlot={false}
                            selectable
                            select={event => handleSelect(event)}
                            datesSet={e => setSelectedDate(e)}
                            firstDay={moment().isoWeekday()}
                            unselectAuto={false}
                        />
                    </CalendarRoot>
                </Grid>
                <Grid item md={12} lg={3}>
                    <Box mt={8} ml={2} mb={2}>
                        <TypographyBlock
                            nopadding
                            title={t("aircraftPage:calendar.title")}
                            description={t("aircraftPage:calendar.description")}
                        />
                        <Grid mt={1} container spacing={2}>
                            <Grid item md={6} lg={12}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("common:label.handover") + " " + t("common:label.date_time")}
                                    />
                                    <Box mt={-1}>
                                        <DatePicker
                                            value={eventStart ?? []}
                                            onChange={handleStart}
                                            options={{
                                                time_24hr: true,
                                                enableTime: true,
                                                minuteIncrement: 15
                                            }} />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item md={6} lg={12}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("common:label.return") + " " + t("common:label.date_time")}
                                    />
                                    <Box mt={-1}>
                                        <DatePicker
                                            value={eventEnd ?? []}
                                            onChange={handleEnd}
                                            options={{
                                                time_24hr: true,
                                                enableTime: true,
                                                minuteIncrement: 15
                                            }} />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item md={6} lg={12}>
                                <Stack>{!!errors &&
                                    errors.map((error, i) =>
                                        <Alert color="error" key={"Booking-Alert-" + i}>
                                            <ClearIcon fontSize="small" />&nbsp;
                                            {t("error:" + error.code)}
                                        </Alert>)}
                                </Stack>
                            </Grid>
                            <Grid item md={6} lg={12}>
                                <Button
                                    disabled={!!errors}
                                    variant="gradient"
                                    color="info"
                                    onClick={() => addModal(<CalendarModal aircraftCharterAlias={aircraftCharterAlias} reg={REG} alias={ALIAS} eventStart={eventStart} eventEnd={eventEnd} />)}
                                >
                                    {t("common:button.reservation")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box >)
}