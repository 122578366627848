import { AccountCircle, Add, Business } from "@mui/icons-material";
import AircraftJourneyLog from "aeromate-dashboard/AircraftJourneyLog";
import Administration from "aeromate-dashboard/Charter/Administration";
import Aircrafts from "aeromate-dashboard/Charter/Aircrafts";
import { AircraftsCode } from "aeromate-dashboard/Charter/Aircrafts";
import { AircraftsEdit } from "aeromate-dashboard/Charter/Aircrafts";
import CheckrideIndex from "aeromate-dashboard/Charter/Aircrafts/CheckrideIndex";
import Settings from "aeromate-dashboard/Charter/Settings";
import CharterFactoring from "aeromate-dashboard/CharterFactoring";
import CreateJoinCharter from "aeromate-dashboard/CreateJoinCharter";
import LogTimes from "aeromate-dashboard/LogTimes";
import MyCockpitPage from "aeromate-dashboard/Personal/Cockpit";
import MyProfilePage from "aeromate-dashboard/Personal/Dashboard";
import MySettingsPage from "aeromate-dashboard/Personal/Settings";
import { ShopItemCreate, ShopItemEdit } from "aeromate-dashboard/ShopItem";
import AircraftSearch from "aeromate-dashboard/AircraftSearch"
import i18n from "locals/config";
import { MyCharterProfilePage } from "aeromate-dashboard/Personal/Dashboard";
import StudentOverview from "aeromate-dashboard/Charter/ATO";
import Communication from "aeromate-dashboard/Communication";
import Shop from "aeromate-dashboard/Charter/Shop";
import MyService from "aeromate-dashboard/MyService";

const routes = [
    {
        type: "collapse",
        name: i18n.t("common:label.personal"),
        key: "personal",
        icon: <AccountCircle />,
        collapse: [
            {
                name: i18n.t("common:label.cockpit"),
                key: "my-cockpit",
                route: "/my/account/cockpit",
                component: <MyCockpitPage />,
            },
            {
                name: i18n.t("common:label.profile"),
                key: "my-profile",
                route: "/my/account/profile",
                component: <MyProfilePage />
            },
            {
                name: i18n.t("common:label.aircraftsearch"),
                key: "my-cockpit",
                route: "/my/account/search",
                component: <AircraftSearch />,
            },
            {
                name: i18n.t("common:label.settings"),
                key: "profile-settings",
                route: "/my/account/settings",
                component: <MySettingsPage />,
            },
        ],
    },
    { type: "divider", key: "divider-1" },
    { type: "title", title: i18n.t("common:label.charter"), key: "title-charter" },
    {
        type: "collapse",
        name: ":alias",
        key: "charter-:alias",
        icon: <Business />,
        collapse: [
            // {
            //     name: i18n.t("common:label.communication"),
            //     key: "communication",
            //     route: `/my/:alias/chat`,
            //     component: <Communication />,
            // },
            {
                name: i18n.t("common:label.aircrafts"),
                key: "aircrafts",
                route: `/my/:alias/aircrafts`,
                component: <Aircrafts />,
            },
            /*
            {
                name: i18n.t("common:label.bookings"),
                key: "bookings",
                route: `/my/:alias/bookings`,
                component: <Dummy />,
            },
            {
                name: i18n.t("common:label.invoices") + " / " + i18n.t("common:label.factoring"),
                key: "factoring",
                route: `/my/:alias/factoring`,
                component: <Dummy />,
            },
            {
                name: i18n.t("common:label.checkrides"),
                key: "checkrides",
                route: `/my/:alias/checkrides`,
                component: <Dummy />,
            },
            {
                name: i18n.t("common:label.voucher"),
                key: "voucher",
                route: `/my/:alias/voucher`,
                component: <Dummy />,
            },
            */
            {
                name: i18n.t("common:label.administration"),
                key: "administration",
                route: `/my/:alias/administration`,
                component: <Administration />,
            },
            {
                name: i18n.t("common:label.settings"),
                key: "settings",
                route: `/my/:alias/settings`,
                component: <Settings />,
            },
            {
                name: i18n.t("common:label.shop"),
                key: "shop",
                route: `/my/:alias/shop`,
                component: <Shop />,
            },
            {
                name: i18n.t("common:label.ato"),
                key: "ato",
                route: `/my/:alias/ato`,
                component: <StudentOverview />,
            }]
    },
    {
        type: "collapse",
        name: "Join/Create Org",
        key: "create-org",
        component: <CreateJoinCharter />,
        route: "/my/organizations",
        icon: <Add />,
        noCollapse: true,
    },
    {
        route: `/my/:alias/aircrafts/:reg`,
        component: <AircraftsEdit />,
    },
    {
        route: `/my/:alias/aircrafts/:reg/code`,
        component: <AircraftsCode />,
    },
    {
        route: `/my/:alias/aircrafts/:reg/checkride`,
        component: <CheckrideIndex />,
    },
    {
        route: `/my/:alias/aircrafts/:reg/journey-log`,
        component: <AircraftJourneyLog />,
    },
    {
        route: `/my/logtimes/:booking`,
        component: <LogTimes />,
    },
    {
        route: `/my/:alias/factoring/:identity_id`,
        component: <CharterFactoring />,
    },
    {
        route: `/my/:alias/shop/edit/:item_id`,
        component: <ShopItemEdit />,
    },
    {
        route: `/my/:alias/shop/create`,
        component: <ShopItemCreate />,
    },
    {
        route: `/my/:alias/enrollment/:identity_id`,
        component: <MyCharterProfilePage />,
    },
    {
        route: `/my/:alias/aircrafts/:reg/service`,
        component: <MyService/>,
    },
];

export default routes;
