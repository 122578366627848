import DashboardLayout from "aeromate-dashboard/DashboardLayout";
import { useParams } from "react-router-dom";
import Body from "./Body";
import BodyCode from "./BodyCode";
import BodyEdit from "./BodyEdit";

export default function Aircrafts() {
    const { alias } = useParams();

    return (<DashboardLayout>
        {!!alias && <Body />}
    </DashboardLayout>
    )
}

export function AircraftsEdit() {
    const { alias, reg } = useParams();

    return (<DashboardLayout>
        {!!alias && !!reg && <BodyEdit />}
    </DashboardLayout>
    )
}

export function AircraftsCode() {
    const { alias, reg } = useParams();

    return (
        <DashboardLayout>
            {!!alias && !!reg && <BodyCode />}
        </DashboardLayout>
    )
}
