import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { restGet } from 'lib/rest';
import Input from "components/Input";

export default function VoucherTypeSearch({ onChange, ...rest }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const fetchCallback = (response) => {
                setOptions(response)
            }
            setLoading(true)
            await restGet(fetchCallback, "dataset/bookingSlot")
            setLoading(false)
        }
        fetchData()
    }, []);



    return (
        <Autocomplete
            {...rest}
            id="asynchronous-demo"
            open={open}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            isOptionEqualToValue={(option, value) => option.slot === value.slot}
            getOptionLabel={(option) => option.slot}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <Input
                    {...params}
                    type="text"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}