/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React components
import Typography from "components/Typography";
import Box from "components/Box";

// Material Dashboard 2 PRO React examples
import ServiceTableCell from "components/Tables/ServiceTable/ServiceTableCell";
import { Collapse, TableCell } from "@mui/material";
import colors from "assets/theme/base/colors";
import Servicemodal from "components/Modal/ServiceModal";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

function ServiceTable({ columns, rows, shadow , aircraft , service }) {
  const [list, setList] = useState(false);
  const [arrow, setArrow] = useState(true);

  const HandleArrow = () => {
    setList(!list);
    setArrow(!arrow);
  }

  const renderTableCells = rows.map((row, key) => {
    const tableRows = [];
    const rowKey = `row-${key}`;

    Object.entries(row).map(([cellTitle, cellContent]) => {

      /*   Array.isArray(cellContent)
          & tableRows.push(
            <ServiceTableCell
              key={cellContent[1]}
              title={cellTitle}
              content={cellContent[1]}
              image={cellContent[0]}
              noBorder={key === rows.length - 1}
            />
          ) */

      typeof cellContent === 'object' &&
        !Array.isArray(cellContent) &&
        cellContent !== null
        & tableRows.push(
          <ServiceTableCell
            key={cellContent}
            title={cellTitle}
            content={cellContent}
            noBorder={key === rows.length - 1}
          />
        )



    });
    // "success warning error"

    return <TableRow key={rowKey}>{tableRows}</TableRow>;
  });

  return (
    <Box >
      <TableContainer sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <Table>
          <TableHead sx={{ display: "table-header-group", backgroundColor: colors.dark.main }}>
            <TableRow>
              {columns && columns.map((title) =>
                <TableCell >
                  <Typography color="white" variant="h6" component="td">
                    {title}
                  </Typography>
                </TableCell>
              )
              }
            </TableRow>
          </TableHead>
          <TableBody sx={{ position: "relative" }}>
            {arrow ?
              (<ArrowCircleDownIcon fontSize="large" color="dark" sx={{ transition: "transform 1s", transform: "rotate(0deg)", position: "absolute", bottom: "0", right: "0", marginRight: "10px", marginBottom: "10px" }} onClick={() => HandleArrow()} ></ArrowCircleDownIcon>
              ) : <ArrowCircleDownIcon fontSize="large" color="dark" sx={{ transition: "transform 1s", transform: "rotate(180deg)", position: "absolute", bottom: "0", right: "0", marginRight: "10px", marginBottom: "10px" }} onClick={() => HandleArrow()} ></ArrowCircleDownIcon>}
            {useMemo(() => renderTableCells, rows)}
          </TableBody>
        </Table>

        <Collapse in={list}>
          <Servicemodal service={service} aircraft={aircraft} />
        </Collapse>
      </TableContainer>

    </Box>
  );
}

// Setting default values for the props of ServiceTable
ServiceTable.defaultProps = {
  columns: [],
  rows: [{}] && {},
  shadow: true,
};

// Typechecking props for the ServiceTable
ServiceTable.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool,
};

export default ServiceTable;
