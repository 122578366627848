// @mui material components
import { Grid } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";

function InvoiceFooter() {
    return (
        <Box pt={3} mt={7} position="relative" zIndex={1}>
            <Grid container>
                <Grid item xs={12} lg={5}>
                    <Typography variant="h5" fontWeight="medium">
                        Thank you!
                    </Typography>
                    <Box mt={1} mb={2} lineHeight={0}>
                        <Typography variant="button" color="secondary">
                            If you encounter any issues related to the invoice you can contact us at:
                        </Typography>
                    </Box>
                    <Typography
                        component="span"
                        variant="h6"
                        fontWeight="regular"
                        color="secondary"
                    >
                        email:{" "}
                        <Typography component="span" variant="h6" fontWeight="regular">
                            support@aeromate.eu
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Box
                        width="100%"
                        height={{ xs: "auto", md: "100%" }}
                        display="flex"
                        justifyContent={{ xs: "flex-start", md: "flex-end" }}
                        alignItems="flex-end"
                        mt={{ xs: 2, md: 0 }}
                    >
                        <Button variant="gradient" color="info" onClick={() => window.print(this)}>
                            print
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default InvoiceFooter;
