import { Grid, Card, } from "@mui/material";
import { Image, Cancel as CancelIcon } from "@mui/icons-material";
import { useCallback, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "hooks/modalHook";
import _ from "lodash";
import TypographyBlock from "components/Typography/TypographyBlock";
import Cropmodal from "components/Modal/CropperModal";
import Box from "components/Box";
import Typography from "components/Typography";

import PlaceHolderImage from "assets/images/aircraft.jpg";

export default function ({ setCroppedImage }) {
    const { t } = useTranslation()
    const { addModal } = useModal()
    const fileInputRef = useRef(null);
    const [previewImageIndex, setPreviewImageIndex] = useState(0);
    const [imageArray, setImageArray] = useState([]);

    const handleClickBrowse = () => {
        fileInputRef.current.click();
    };

    const cropModalChange = (value) => {
        if (value && value != "") {
            setImageArray((previousImages) => previousImages.concat(value));
        }
    };

    const uploadImage = (event) => {
        const selectedImg = event.target.files;
        const selectedImgArray = Array.from(selectedImg);
        const imagesArray = selectedImgArray.map((file) => {
            return URL.createObjectURL(file);
        });
        addModal(<Cropmodal onValueChange={cropModalChange} img={imagesArray}></Cropmodal>)
        event.target.value = "";
    };

    function deleteImage(image, index) {
        if (index === previewImageIndex && index > 0) { setPreviewImageIndex(index - 1) }
        var tmpList = [...imageArray]
        tmpList.splice(index, 1)
        setImageArray(tmpList);
    }

    useEffect(() => {
        setCroppedImage(imageArray)
    }, [imageArray]);

    /**
     * 
     * Drag Drop Events
     * 
     */
    const Drag = (event, index) => {
        event.dataTransfer.setData('text/plain', index);
    };

    const Dragover = (event, index) => {
        event.preventDefault();
    };

    const Drop = (event, dropIndex) => {
        event.preventDefault();
        const draggedIndex = event.dataTransfer.getData('text/plain');
        const updatedImages = [...imageArray];
        const draggedImage = updatedImages[draggedIndex];
        updatedImages.splice(draggedIndex, 1);
        updatedImages.splice(dropIndex, 0, draggedImage);
        setImageArray(updatedImages);
    };

    return (
        <Card>
            <Box p={3}>
                <TypographyBlock
                    nopadding
                    title={"Image upload"}
                />
                <Box mt={3} sx={{
                    "position": "relative",
                    "width": "auto",
                    "height": "512px"
                }}>     <img src={imageArray.length < 1 ? PlaceHolderImage : _.isString(imageArray[previewImageIndex]) ? imageArray[previewImageIndex] : imageArray[previewImageIndex].imageUrl} style={{ overflow: "hidden", maxWidth: "100%", maxHeight: "100%" }} alt="upload" />
                </Box>
                <Grid mt={2} p={1} container sx={{ height: "auto" }} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={9} p={3} sx={{ height: "100%" }}>
                        <Box sx={{ display: "flex", overflowX: "auto", scrollBehavior: "smooth", "&::-webkit-scrollbar": { height: "5px", width: "3px", backgroundColor: "#f5f5f5" }, "&::-webkit-scrollbar-thumb": { backgroundColor: "#7b809a" } }}>
                            {imageArray.map((image, index) => (
                                <Box
                                    key={image}
                                    ml={1}
                                    sx={{ position: "relative" }}
                                    draggable
                                    onDragStart={(e) => Drag(e, index)}
                                    onDragOver={(e) => Dragover(e, index)}
                                    onDrop={(e) => Drop(e, index)}
                                >
                                    <img onClick={() => setPreviewImageIndex(index)} src={_.isString(image) ? image : image.imageUrl} height="100" alt="upload" />
                                    <CancelIcon
                                        fontSize="medium"
                                        onClick={() => deleteImage(image, index)}
                                        sx={{ position: "absolute", top: "1px", right: "1px", cursor: "pointer" }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} p={3} sx={{ border: 2, borderRadius: "8px", height: "100%" }} onClick={handleClickBrowse}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                            <Image fontSize="large" mb={2} sx={{ marginBottom: "5px" }}></Image>
                            <Typography fontSize="12px">Add More Images</Typography>

                            <Typography sx={{ textAlign: "center" }} fontSize="10px" variant="button" component="span" >
                                Click to browse , and order by dragging
                            </Typography>
                            <input
                                ref={fileInputRef}
                                type="file"
                                name="images"
                                onChange={uploadImage}
                                accept="image/png ,image/jpg,image/jpeg"
                                hidden
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}