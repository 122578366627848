import { Link } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import { useTranslation } from "react-i18next";

function Partners() {
    const { t } = useTranslation();
    const partnerItems = t("aboutUsPage:partners", { returnObjects: true })
    return (
        <Box py={6} id="partner">
            <Container>
                <Grid
                    container spacing={3}
                    sx={{ mt: 3 }}
                    justifyContent="center"
                >
                    {partnerItems.map((x,i) =>
                        <Grid item xs={6} md={4} lg={2} key={"partner-grid-item-" + i}>
                            <Link href={x.href} target="_blank">
                                <Box component="img" src={x.src} alt={x.alt} width="100%" opacity={0.9} />
                            </Link>
                        </Grid>)
                    }
                </Grid>
            </Container>
        </Box>
    );
}

export default Partners;