import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from "react-i18next";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export default function NoPermission({ title, subtitle, restricted }) {
    const { t } = useTranslation()
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            {Boolean(Math.round(Math.random())) ? < LockIcon color="error" sx={{ width: "4em", height: "4em" }} /> : < SentimentVeryDissatisfiedIcon color="error" sx={{ width: "4em", height: "4em" }} />}
            <Typography variant={"h1"}>
                {title ?? t("common:label.noPermissionTitle")}
            </Typography>
            <Typography variant={"subtilte1"}>
                {subtitle ?? t("common:label.noPermissionSubtitle")}
            </Typography>
        </Stack >
    );
}