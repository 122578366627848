import { useEffect, useState } from "react";
import Box from "components/Box";
import { restGet } from "lib/rest"
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AeroLoading from "components/Progress/AeroLoading"
import Alert from "components/Alert";
import ReactMarkdown from "react-markdown";
import CharterCourseTab from "./sections/CharterCourseTab";

export default function CharterCourses() {
    const [charter, setCharter] = useState();
    const { t } = useTranslation();
    const { ALIAS } = useParams();
    const [isloading, setIsLoading] = useState(false)
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setCharter(resp.data[0])
            }
            setIsLoading(true)
            await restGet(restCallback, 'charter/' + ALIAS)
            setIsLoading(false)
        }
        !!ALIAS && fetch()
    }, [ALIAS])

    //Reload Frame Size
    const [imgLoaded, setImageLoaded] = useState();
    useEffect(() => {
        parent.postMessage({ a: { h: document.body.scrollHeight + 'px' } }, "*")
    }, [imgLoaded]);

    if (!charter || isloading) {
        return isloading ? <AeroLoading /> : null;
    }

    return (
        <Box>
            <Alert color="warning" p={1}>
                <ReactMarkdown>{t("charterPage:minimal_shop.alert")}</ReactMarkdown>
            </Alert>
            <CharterCourseTab charter={charter} setImageLoaded={setImageLoaded} />
        </Box>
    )
}