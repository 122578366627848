import Box from "components/Box";
import Typography from "components/Typography";

function Header() {
    return (<Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="330px"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${"https://aeromatestatic.blob.core.windows.net/img/responsibility_header.jpg"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
        <Typography fontWeight="bold" color="light" fontSize="56px">Responsibility</Typography>

    </Box>
    );
}

export default Header;
