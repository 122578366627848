import DashboardLayout from "aeromate-dashboard/DashboardLayout";
import CheckrideChecklist from "./AircraftCheckrideForm/CheckrideChecklist";

export default function CheckrideIndex() {
    return (
        <DashboardLayout>
            <CheckrideChecklist />
        </DashboardLayout>
    )
}
