import Grid from "@mui/material/Grid";
import DefaultTable from "components/Tables/DefaultTable";

function AirportRunways({ airport }) {
    const { columns } = {
        columns: [
            { name: "Ident", accessor: "ident", align: "left", marginLeft: "16px" },
            { name: "Width", accessor: "width_ft", align: "left", suffix: " ft"},
            { name: "Length", accessor: "length_ft", align: "left", suffix: " ft"},
            { name: "Surface", accessor: "surface", align: "left" },
            { name: "RWY Heading", accessor: "heading_degT", align: "left", suffix: "°" },
        ]
    }

    return (
        <Grid container>
            <DefaultTable columns={columns} rows={airport.runways} />
        </Grid>
    );
}

export default AirportRunways;