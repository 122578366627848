import Box from "components/Box";
import bgPattern from "assets/images/shapes/pattern-lines.svg";

function Header() {
    return (<Box
        variant="gradient" bgColor="dark"
        height="256px" width="100%" position="relative"
    >
        <Box
            component="img"
            src={bgPattern}
            alt="pattern-lines"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            opacity={0.6}
        />
    </Box>
    );
}

export default Header;