import DashboardLayout from "aeromate-dashboard/DashboardLayout";
import Body from "./Body";

export default function Shop() {
    return (
        <DashboardLayout>
            <Body />
        </DashboardLayout>
    )
}
