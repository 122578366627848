/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";

// Images
import mastercard from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { Trans, useTranslation } from "react-i18next";
import { restPut, restGet, restPost } from "lib/rest";
import { useState, useEffect } from "react";
import TypographyBlock from "components/Typography/TypographyBlock";
import Badge from "components/Badge";
import { baseurl } from "constants/global";
import ButtonDropDown from "components/Button/ButtonDropDown";

function PaymentMethod() {
    const { t } = useTranslation();
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const [paymentMethods, setPaymentMethods] = useState()
    const [addPaymentMethods, setAddPaymentMethods] = useState()

    const actions = [
        {
            title: t("dashboard:personal.payment.trans_eu"),
            action: () => addMethod('aeromate.eu')
        },
        {
            title: t("dashboard:personal.payment.trans_us"),
            action: () => addMethod('aeromate.us')
        }
    ]

    function addMethod(legalEntity) {
        restPost(setAddPaymentMethods, 'identity/_self/setuppayment/' + legalEntity, {
            callback: baseurl + '/my/account/settings#payment-methods'
        });
    }

    function setDefaultMethod(paymentMethodId, legalEntity) {
        restPut(setPaymentMethods, 'identity/_self/defaultmethod/' + paymentMethodId, { legal_entity: legalEntity });
    }

    function deleteMethod(paymentMethodId, legalEntity) {
        restPut(setPaymentMethods, 'identity/_self/detachmethod/' + paymentMethodId, { legal_entity: legalEntity });
    }

    useEffect(() => {
        restGet(setPaymentMethods, 'identity/_self/paymentmethod/')
    }, []);

    useEffect(() => {
        if (!!addPaymentMethods && !!addPaymentMethods.url) {
            window.location.replace(addPaymentMethods.url)
        }
    }, [addPaymentMethods]);


    return (
        <Card >
            <Box sx={{ position: "absolute", top: "-100px" }} id="payment-methods"></Box>
            <TypographyBlock
                title={t("dashboard:personal.payment.title")}
                description={
                    <Trans
                        i18nKey={"dashboard:personal.payment.description"}
                    />
                }
            />

            <Box p={2} sx={{ align: "right" }}>
                <ButtonDropDown
                    options={actions}
                    buttonLabel={t("dashboard:personal.payment.button")}
                />
            </Box>

            <Box p={2}>
                <Grid container spacing={3}>
                    {!!paymentMethods && !!paymentMethods.data && paymentMethods.data.map((item, i) => (
                        (item.object == 'payment_method' && item.type == 'card') ?
                            <Grid item xs={12} md={6} key={i}>
                                <Box
                                    borderRadius="lg"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={2}
                                    sx={{
                                        border: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <Typography variant="h6" fontWeight="medium">
                                                {item.card.brand}
                                                &nbsp;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&nbsp;
                                                {item.card.last4}&nbsp;&nbsp;
                                                {(item.default ?? false) == true &&
                                                    <Badge
                                                        circular
                                                        color={'success'}
                                                        badgeContent={'DEFAULT'}
                                                    />}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2} align={'right'}>
                                            <Typography variant="caption" fontWeight="light">
                                                {(item.legal_entity).split('.').slice(-1)}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={'auto'}>
                                            <Typography variant="caption" fontWeight="light">
                                                {t("common:label.expires")}&nbsp;&nbsp;{item.card.exp_month}&nbsp;/&nbsp;{item.card.exp_year}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs align={'right'}>
                                            {(item.default ?? false) == false &&
                                                <Box pl={2} ml="auto" lineHeight={0} color={darkMode ? "white" : "dark"}>
                                                    <Tooltip pr={2} title="Make Default" placement="top">
                                                        <Button
                                                            color="info"
                                                            size="small"
                                                            onClick={() => setDefaultMethod(item.id, item.legal_entity)}
                                                        >
                                                            {t("common:button.default")}
                                                        </Button>
                                                    </Tooltip>
                                                    &nbsp;
                                                    <Tooltip pr={2} title="Delete Card" placement="top">
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            iconOnly
                                                            onClick={() => deleteMethod(item.id, item.legal_entity)}
                                                            sx={{ width: '32px', height: '32px', minWidth: '32px', minHeight: '32px' }}
                                                        >
                                                            <Icon sx={{ cursor: "pointer" }} fontSize={'0.75rem'} >
                                                                delete
                                                            </Icon>
                                                        </Button>
                                                    </Tooltip>
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                            :
                            <></>
                    ))}
                </Grid>
            </Box>
        </Card>
    );
}

export default PaymentMethod;
