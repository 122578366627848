import Container from "@mui/material/Container";
import Box from "components/Box";

// Images
const bgImage = "https://media.westeurope.aero-services.net/aeromate/header/rough-horn-2146181_1920.jpg"

function Header() {
    return (<Box
        display="flex"
        alignItems="center"
        height="256px"
        sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
    </Box>
    );
}

export default Header;