import { AppBar, Card, Grid, Tab, Tabs } from "@mui/material";
import Box from "components/Box";
import TypographyBlock from "components/Typography/TypographyBlock";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getBlobImageUrl } from "lib/functions";
import { restPost } from "lib/rest";
import _ from "lodash";
import ImageCrop from "./sections/ImageCrop";
import Information from "./sections/Information";
import Variants from "./sections/Variants";
import Typography from "components/Typography";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ShopItemCard from "components/Cards/ShopItemCard/ShopItemCard";
import AeroLoading from "components/Progress/AeroLoading";
import Subjects from "./sections/Subjects";
import { subjects } from "dev/mockdata/subjects";
import { v4 as uuidv4 } from 'uuid';
import { legalFromAlias } from "lib/legalInfo";

export default function ShopItemEditor() {
    const { t } = useTranslation()
    const { alias, item_id } = useParams();
    const [isloading, setIsLoading] = useState(true)
    const [tabValue, setTabValue] = useState("info");

    const [item, setItem] = useState();
    const [theoryChecklist, setTheoryChecklist] = useState();
    const [theoryChecklistId, setTheoryChecklistId] = useState();
    const [practiceChecklist, setPracticeChecklist] = useState();
    const [practiceChecklistId, setPracticeChecklistId] = useState();
    const [examChecklist, setExamChecklist] = useState();
    const [examChecklistId, setExamChecklistId] = useState();
    const [legalEntity, setLegalEntity] = useState();
    const [charter, setCharter] = useState();


    useEffect(async () => {
        await restGet((res) => setCharter(!!res && !!res.data && res.data[0]), 'charter/' + alias)
    }, [alias]);

    useEffect(async () => {
        if (!!alias) {
            let data = await legalFromAlias(alias)
            setLegalEntity(data);
        }
    }, []);


    /**
     * Init
     */
    useEffect(() => {
        const fetch = async () => {
            function restCallbackShop(resp) {
                if (!!resp && !!resp.data && !!resp.data[0]) {
                    setItem(resp.data[0])
                }
            }
            function restCallbackChecklist(resp) {
                resp?.data?.map((d) => {
                    if (d.subtype === "exams") {
                        setExamChecklist(d.checklist ?? [])
                        setExamChecklistId(d._id.$oid)
                    }
                    if (d.subtype === "practice") {
                        setPracticeChecklist(d.checklist ?? [])
                        setPracticeChecklistId(d._id.$oid)
                    }
                    if (d.subtype === "theory") {
                        setTheoryChecklist(d.checklist ?? [])
                        setTheoryChecklistId(d._id.$oid)
                    }
                })
            }
            setIsLoading(true)
            await restGet(restCallbackShop, `shop/${alias}/item/${item_id}`)
            await restGet(restCallbackChecklist, 'checklist', { shop_item_id: item_id });
            setIsLoading(false)
        }
        !!alias && !!item_id && fetch()
    }, [alias, item_id])

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
    }


    const [content, setContent] = useState(null)
    const [images, setImages] = useState()
    function setData(value) {
        switch (tabValue) {
            case "info":
                setItem({ ...item, "caption": value?.caption, "description": value?.description })
                break;
            case "variant":
                setItem({ ...item, "variant": value?.variant })
                break;
            case "image":
                setImages(value)
                break;
            case "theory":
                setTheoryChecklist(value?.subjects)
                break;
            case "practice":
                setPracticeChecklist(value?.subjects)
                break;
            case "exams":
                setExamChecklist(value?.subjects)
                break;

            default: break;
        }

    }

    function setContentByTabValue(val) {
        switch (val) {
            case "info": return (<Information defaultData={item} setData={setData} />); break;
            case "variant": return (<Variants defaultData={item} setData={setData} />); break;
            case "image": return (<ImageCrop defaultData={item} formData={images} setData={setData} />); break;
            case "theory": return (<Subjects key={val} defaultData={theoryChecklist} setData={setData} nested={true} />); break;
            case "practice": return (<Subjects key={val} defaultData={practiceChecklist} setData={setData} nested={true} />); break;
            case "exams": return (<Subjects key={val} defaultData={examChecklist} setData={setData} nested={false} subtype="exams" />); break;

            default: break;
        }
    }

    async function handleSave() {
        let body = {
            caption: item.caption,
            description: item.description,
            variant: item.variant,
        }
        setIsLoading(true)
        //Save Data
        if (!!item) {
            await restPost(null, `shop/${alias}/item/${item_id}`, body)
        }
        //Save Image
        if (!!images) {
            if (item?.image) {
                var oldImages = _.values(item.image).map((v) => getBlobImageUrl(v))
                var imagesToDelete = _.difference(oldImages, images.map((v) => v.imageUrl))

                var imagesWithKeys = Object.entries(item.image).map((e) => ({ ...e[1], key: e[0], imageUrl: getBlobImageUrl(e[1]) }))

                var imageKeysToDelete = _.filter(imagesWithKeys, (e) => { return (_.indexOf(imagesToDelete, e.imageUrl) !== -1) })

                //Create Delta to delete Images
                for (let index = 0; index < imageKeysToDelete.length; index++) {
                    await restPost(null, `shop/${item_id}/delete/${imageKeysToDelete[index].key}`)
                }
            }

            //Upload new added Files (local blob)
            var uploadImages = _.filter(images, (x) => { return _.isString(x) })
            for (let index = 0; index < uploadImages.length; index++) {
                let blob = await fetch(uploadImages[index]).then(r => r.blob()).then(blobFile => new File([blobFile], "croppedImage", { type: "image/png" }))
                await restPost(null, `shop/${item_id}/image/${uuidv4()}`, blob, null, { "Content-Type": blob.type })
            }
        }
        //Save Checklist
        if (!!theoryChecklist) {
            let checklistBody = {
                item_type: "enrollment",
                subtype: "theory",
                listtype: "nested",
                shop_item_id: item_id,
                checklist: theoryChecklist
            }
            await restPost(null, `checklist/${theoryChecklistId ? theoryChecklistId : ""}`, checklistBody);
        }
        //Save Checklist
        if (!!practiceChecklist) {
            let checklistBody = {
                item_type: "enrollment",
                subtype: "practice",
                listtype: "nested",
                shop_item_id: item_id,
                checklist: practiceChecklist
            }
            await restPost(null, `checklist/${practiceChecklistId ? practiceChecklistId : ""}`, checklistBody);
        }
        //Save Checklist
        if (!!examChecklist) {
            let checklistBody = {
                item_type: "enrollment",
                subtype: "exams",
                listtype: "flat",
                shop_item_id: item_id,
                checklist: examChecklist
            }
            await restPost(null, `checklist/${examChecklistId ? examChecklistId : ""}`, checklistBody);
        }
        setIsLoading(false)
    }

    if (isloading) {
        return <AeroLoading />;
    }
    return (
        <Box my={3}>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} >
                    <Box
                        mx={1}
                        p={1}
                        display="flex"
                        alignItems="baseline"
                        color="dark"
                        opacity={1}
                        sx={{ cursor: "pointer", userSelect: "none", width: 'fit-content' }}
                        href={`/my/${alias}/settings#shops`}
                        component="a"
                    >
                        <Typography
                            variant="body2"
                            lineHeight={1}
                            color="inherit"
                            sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
                        ><KeyboardBackspaceIcon />
                        </Typography>
                        <Typography
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            color="dark"
                            sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                        >
                            {t("common:button.back")}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TypographyBlock
                        title={t("dashboard:shopitem.preview_title")}
                        description={t("dashboard:shopitem.preview_description")}
                    />
                    <Box pt={3}>
                        {charter && <ShopItemCard item={item} charter={charter} previewImage={images ?? item.image} legalEntity={legalEntity} />}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <TypographyBlock
                        title={t("dashboard:shopitem.settings_title")}
                        description={t("dashboard:shopitem.settings_description")}
                        actionName={t("common:button.save")}
                        action={handleSave}
                    />
                    <AppBar position="static">
                        <Tabs value={tabValue} onChange={handleSetTabValue}>
                            <Tab label={t("dashboard:shopitem.general_tab_label")} value="info" />
                            <Tab label={t("dashboard:shopitem.variants_tab_label")} value="variant" />
                            <Tab label={t("dashboard:shopitem.image_tab_label")} value="image" />
                            {item?.item_type === "enrollment" && <Tab label={t("dashboard:shopitem.theory_tab_label")} value="theory" />}
                            {item?.item_type === "enrollment" && <Tab label={t("dashboard:shopitem.practice_tab_label")} value="practice" />}
                            {item?.item_type === "enrollment" && <Tab label={t("dashboard:shopitem.exams_tab_label")} value="exams" />}
                        </Tabs>
                    </AppBar>
                    <Box pt={3}>
                        <Card>
                            {setContentByTabValue(tabValue)}
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}