// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";


// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import routesSocialmedia from "constants/routes.socialmedia";
import { useTranslation } from "react-i18next";

// Images
const bgImage = "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_226419158sm.jpeg"

export default function Header() {
    const { t } = useTranslation();

    return (<Box
        display="flex"
        alignItems="center"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: { xs: '92vh', md: "72vh" }
        }}
    >
        <Container>
            <Grid
                container
                item
                xs={12}
                flexDirection="column"
                mx="auto"
                mt={1}
            >
                <Grid item xs={12}>
                    <Swiper
                        modules={[Autoplay, Pagination]}
                        spaceBetween={50}
                        slidesPerView="auto"
                        centeredSlides={true}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}

                    >
                        {[...Array(2)].map((x, i) =>
                            <SwiperSlide key={"swipe-slider-" + i}                        >
                                <Container style={{ background: "rgba(0, 50, 83, .4)", borderRadius: 25, paddingTop: 15, paddingBottom: 15 }}>
                                    <Typography variant="h2" color="white">
                                        {t("landingPage:header.swiperitem" + i + ".title")}
                                    </Typography>
                                    <Typography variant="body1" color="white" >
                                        {t("landingPage:header.swiperitem" + i + ".subtitle")}
                                    </Typography>
                                </Container>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </Grid>
            </Grid>
        </Container>
    </Box>)
}