import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AutoComplete from "components/Input/AutoComplete";
import { restGet } from "lib/rest";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CharterLegalEntity({ setLegalEntity }) {
    const { t } = useTranslation();
    const [legalEntities, setLegalEntities] = useState()
    const [entity, setEntity] = useState()
    const [currency, setCurrency] = useState()

    useEffect(() => {
        restGet(setLegalEntities, 'dataset/legalEntity')
    }, [])

    useEffect(() => {
        if (!!entity && !!currency) setLegalEntity({ legal_entity: entity.legal_entity, currency: currency })
    }, [entity, currency])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
                <AutoComplete
                    label={t("dashboard:organization.create.name.economic_area")}
                    options={legalEntities ?? []}
                    getOptionLabel={(value) => value?.caption?.en}
                    isOptionEqualToValue={(option, value) => option.legal_entity === value.legal_entity}
                    onChange={(value) => { setEntity(value); setCurrency(null); }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <AutoComplete
                    label={t("common:label.currency")}
                    options={entity?.currencies ?? []}
                    value={currency}
                    getOptionLabel={(value) => value}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(value) => setCurrency(value)}
                />
            </Grid>
        </Grid>
    )
}