import DefaultModal from "components/Modal/DefaultModal";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import TableCell from "components/Tables/TableCell";
import { Card, MenuItem, Select, Switch } from "@mui/material";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { restPost } from "lib/rest";
import { restGet } from "lib/rest";
import _, { forEach } from "lodash";
import AutoComplete from "components/Input/AutoComplete";

function CharterNotification() {

    const { t } = useTranslation();
    const [checkride, setCheckride] = useState({ popup: false, sms: false, mail: true });
    const [booking, setBooking] = useState({ popup: false, sms: false, mail: true });
    const [document, setDocument] = useState({ popup: false, sms: false, mail: true });
    const [keyvault, setKeyvault] = useState({ popup: false, sms: false, mail: true });
    const [follow, setFollow] = useState({ popup: false, sms: false, mail: true });
    const [member, setMember] = useState([]);
    const [curmember, setcurMember] = useState("");
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);




    useEffect(() => {
        const fetch = async () => {

            async function restCallback(resp) {
                if (resp?.notifcation_settings_charter) {

                    setData(resp.notifcation_settings_charter);
                } else {
                    if (resp?.memberOf) {
                        Object.keys(resp.memberOf).forEach(


                            async (v) => {
                                var object = { "member": v, checkride, booking, document, keyvault, follow }

                                var res = await restPost(null, "Identity/" + v + "/NotificationCharter", object)
                                setData(res.notifcation_settings_charter);
                            }
                        )
                    }
                }
                if (resp?.memberOf) {

                    setMember(Object.keys(resp.memberOf))
                    setcurMember(Object.keys(resp.memberOf)[0]);
                }
            }

            await restGet(restCallback, "Identity/NotificationRetrieve");
        }
        fetch();


    }, [])


    async function submitNotifications() {
        let object = {};

        object = { "member": curmember, checkride, booking, document, keyvault, follow }

        setIsLoading(true)
        await restPost(null, "Identity/" + curmember + "/NotificationCharter", object)
        setIsLoading(false)
        setIsSaved(true)

        setData({ ...data, [curmember]: object })
    }

    useEffect(() => {
        if (data && curmember) {

            var curData = data[curmember]
            if (curData) {
                setCheckride({ popup: curData.checkride.popup, sms: curData.checkride.sms, mail: curData.checkride.mail })
                setBooking({ popup: curData.booking.popup, sms: curData.booking.sms, mail: curData.booking.mail })
                setDocument({ popup: curData.document.popup, sms: curData.document.sms, mail: curData.document.mail })
                setKeyvault({ popup: curData.keyvault.popup, sms: curData.keyvault.sms, mail: curData.keyvault.mail })
                setFollow({ popup: curData.follow.popup, sms: curData.follow.sms, mail: curData.follow.mail })
            }
        }
    }, [curmember, data]);


    const updateCheckride = e => {
        const name = e.target.name

        setCheckride(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateBooking = e => {
        const name = e.target.name

        setBooking(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateDocument = e => {
        const name = e.target.name

        setDocument(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateKeyvault = e => {
        const name = e.target.name

        setKeyvault(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateFollow = e => {
        const name = e.target.name

        setFollow(x => ({
            ...x,
            [name]: e.target.checked,
        }))

    }

    const updateMember = e => {
        setIsSaved(false)
        setcurMember(e);
    }





    return (
        <Card>
            <Box sx={{ position: "absolute", top: "-100px" }} ></Box>

            <TypographyBlock
                title={t("common:notifications.title")}
                description={isLoading ? "Saving..." : isSaved ? "Saved" : ""}
                actionName={t("common:button.save")}
                action={submitNotifications}>


            </TypographyBlock>


            <AutoComplete
                sx={{ width: "150px", marginLeft: "25px", marginBottom: "10px" }}
                label={t("common:notifications.label")}
                options={member}
                onChange={updateMember}
                value={curmember} disablePortal
                isOptionEqualToValue={(option, value) => option === value}
                disableClearable



            >

            </AutoComplete>


            <Box pb={3} px={3}>
                <Box minWidth="auto" sx={{ overflow: "scroll" }}>
                    <Table sx={{ minWidth: "36rem" }}>
                        <Box component="thead">
                            <TableRow>
                                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                    Activity
                                </TableCell>
                                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                    Email
                                </TableCell>
                                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                    Push
                                </TableCell>
                               {/*  <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                    SMS
                                </TableCell> */}
                            </TableRow>
                        </Box>
                        <TableBody>
                            <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Checkride
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.checkride")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch  checked={checkride.mail}
                                        onChange={updateCheckride} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={checkride.popup}
                                        onChange={updateCheckride} name="popup" />
                                </TableCell>
                                {/* <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={checkride.sms}
                                        onChange={updateCheckride} name="sms" />
                                </TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Booking
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.booking")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={booking.mail}
                                        onChange={updateBooking} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={booking.popup}
                                        onChange={updateBooking} name="popup" />
                                </TableCell>
                             {/*    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={booking.sms}
                                        onChange={updateBooking} name="sms" />
                                </TableCell> */}
                            </TableRow>
                        {/*     <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Document
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.document")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={document.mail}
                                        onChange={updateDocument} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={document.popup}
                                        onChange={updateDocument} name="popup" />
                                </TableCell>
                       //         <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      //              <Switch checked={document.sms}
                      //                  onChange={updateDocument} name="sms" />
                     /7           </TableCell> 
                            </TableRow> */}
                          {/*   <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Keyvault Codes
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.keyvaultcode")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={keyvault.mail}
                                        onChange={updateKeyvault} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={keyvault.popup}
                                        onChange={updateKeyvault} name="popup" />
                                </TableCell>
                       //         <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                         //           <Switch checked={keyvault.sms}
                         //               onChange={updateKeyvault} name="sms" />
                          //      </TableCell> 
                            </TableRow> */}
                          {/*   <TableRow>
                                <TableCell padding={[1, 1, 1, 0.5]}>
                                    <Box lineHeight={1.4}>
                                        <Typography display="block" variant="button" fontWeight="regular">
                                            Follows
                                        </Typography>
                                        <Typography variant="caption" color="text" fontWeight="regular">
                                            {t("common:notifications.follows")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch checked={follow.mail}
                                        onChange={updateFollow} name="mail" />
                                </TableCell>
                                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                    <Switch disabled checked={follow.popup}
                                        onChange={updateFollow} name="popup" />
                                </TableCell>
                        //        <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                        //            <Switch checked={follow.sms}
                        //                onChange={updateFollow} name="sms" />
                        //        </TableCell> 
                            </TableRow> */}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </Card>

    );
}

export default CharterNotification;