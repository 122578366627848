import { Card, FormControl, Grid, InputLabel, MenuItem, Select, } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import Typography from "components/Typography";
import { restPost } from "lib/rest";
import { restGet } from "lib/rest";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export function AircraftCodeLock({ aircraft }) {
    const { t } = useTranslation()
    const [duration, setDuration] = useState(1)
    const [durationOptions, setDurationOptions] = useState([])
    const [date, setDate] = useState()
    const [mobile, setMobile] = useState()
    const [mail, setMail] = useState()

    const [code, setCode] = useState()

    const model = 'KL1060C2'
    const identifier = '461052'

    useEffect(() => {
        function restCallback(resp) {
            let data = []
            if (!!resp) data = _.filter(resp.data, (x) => { return x.SubMode === "alltime" })
            setDurationOptions(data)
        }
        restGet(restCallback, 'netcode/_all/durations', { 'lockmodel': model })
    }, []);

    function getBody() {
        let start = moment.utc(date).add(moment(date).isDST(), 'h')
        let params = {
            start: (start.format('YYYY-MM-DD HH:mm')),
            duration: duration,
            lockmodel: model,
            identifier: identifier
        }
        let body = {}
        if (!!mobile) body = { ...body, mobile: mobile }
        if (!!mail) body = { ...body, mail: mail }
        restPost((e) => setCode(e?.data), 'netcode/202306021036/code', body, params)
    }

    return (
        <Box pb={8}>
            <Grid container justifyContent="center" >
                <Grid item xs={12} xl={8}>
                    <Box mb={8} textAlign="center">
                        <Box mb={1}>
                            <Typography variant="h3" fontWeight="bold">
                                {aircraft.reg} {t("dashboard:charter.netcode.lock")}
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight="regular" color="secondary">
                            {t("dashboard:charter.netcode.title")}
                        </Typography>
                    </Box>
                    <Card  >
                        <Box p={3} >
                            {!!code &&
                                <Box mb={8} textAlign="center">
                                    <Box mb={1}>
                                        <Typography variant="h3" fontWeight="bold">
                                            Your unlock code
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" fontWeight="regular" color="secondary">
                                        {code?.ActualNetcode ?? ""}
                                    </Typography>
                                </Box>
                            }
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                        <InputLabel id="duration-select-label">{t("common:label.duration")}</InputLabel>
                                        <Select
                                            labelId="duration-select-label"
                                            id="duration-select"
                                            label={t("common:label.duration")}
                                            value={duration}
                                            onChange={(event) => setDuration(event.target.value)}
                                        >
                                            {durationOptions?.map((x) => {
                                                if (x.DurationHours !== 0)
                                                    return (<MenuItem key={"duration-option-" + x.DurationID} value={x.DurationID}>{x.DurationHours + " Hours"}</MenuItem>)
                                                if (x.DurationDays !== 0)
                                                    return (<MenuItem key={"duration-option-" + x.DurationID} value={x.DurationID}>{x.DurationDays + " Days"}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        input={{
                                            InputLabelProps: { shrink: true },
                                            label: t("common:label.start_time"),
                                        }}
                                        options={{
                                            minuteIncrement: 60,
                                            time_24hr: true,
                                            enableTime: true,
                                            noCalendar: false
                                        }}
                                        value={date}
                                        onChange={(event) => setDate(event[0].getTime())}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        label={t("common:label.mobilenumber")}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        onChange={(e) => setMobile(e.target.value)}
                                        value={mobile} 
                                        helperText="Format: +49123456789123"
                                        />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        disabled={!date || duration === null || duration === undefined}
                                        variant="gradient"
                                        color="primary"
                                        onClick={() => getBody()}
                                    >
                                        {t("common:button.generate")}{!!mobile && " & " + t("common:button.send")}
                                    </Button>
                                </Grid>
                                {/*             <Grid item xs={12}>
                <Input
                    label={t("common:label.email")}
                    type="text"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setMail(e.target.value)}
                    value={mail} />
            </Grid> */}
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box >
    )
}
