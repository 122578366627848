import { Checkbox, Divider, Stack } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import ChecklistContainer from "./ChecklistContainer";
import DropBox from "./DropBox";
import DragBox from "./DragBox";
import { Add } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default function Checklist({ items, color, defaultComponent, dataCallback }) {
    const [_items_, setItems] = useState(items)
    const [dragging, setDragging] = useState(false)

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const tmp_items_ = reorder(
            _items_,
            result.source.index,
            result.destination.index
        );

        setItems(tmp_items_);
        setDragging(false)
    }

    function add(index) {
        const tmp_items_ = [..._items_];
        tmp_items_.splice(index + 1, 0, { id: uuidv4() + "", component: defaultComponent });
        setItems(tmp_items_);
    }

    function remove(index) {
        const tmp_items_ = [..._items_];
        tmp_items_.splice(index, 1);
        setItems(tmp_items_);
    }

    function setData(data, index) {
        let tmp_items_ = [..._items_];
        tmp_items_[index].data = data
        setItems(tmp_items_);
    }

    const [open, setOpen] = useState(false)


    useEffect(() => { dataCallback(_items_) }, [_items_])

    return (
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={() => setDragging(true)}>
            <ChecklistContainer color={color} open={open} onClick={() => setOpen(!open)}>
                <DropBox>
                    {!!_items_ && _items_.map((item, index) => (
                        <DragBox isDragDisabled={_items_.length < 2} remove={() => remove(index)} addAfter={() => add(index)} key={item.id} draggableId={item.id} index={index} dragging={dragging}>{React.cloneElement(item.component, { dataCallback: (e) => setData(e, index) })}</DragBox>
                    ))}
                    {!!_items_ && _items_.length < 1 &&
                        <Divider
                            sx={{
                                background: "none",
                                height: "100%",
                                opacity: 1
                            }}
                        >
                            <Button onClick={() => add(0)} iconOnly color="dark" size="large">
                                <Add />
                            </Button>
                        </Divider>
                    }
                </DropBox>
            </ChecklistContainer>
        </DragDropContext>
    );
}