import { useTranslation } from "react-i18next";
import Box from "components/Box";
import DefaultModal from "components/Modal/DefaultModal";
import _ from "lodash";
import PostConfirmationModal from "./PostConfirmationModal";

export default function MultiSelectBookingsModal(props) {
    const { t } = useTranslation();
    const { bookings, actionName } = props

    return (
        <DefaultModal
            title={t("common:modal.multi_booking.title")}
            confirm={{
                show: true,
                text: t("common:button.confirm"),
                color: "success",
                modal: <PostConfirmationModal {...props} />
            }}
        >
            <Box>
                {t("common:modal.multi_booking.text", { count: bookings.length, action: actionName })}

            </Box>
        </DefaultModal >
    )
}