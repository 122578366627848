import ButtonDropDown from "components/Button/ButtonDropDown";

function isDemo(item) {
    if (item.demo) {
        return item.demo;
    }
    return false;
}

export function addActions(data, actions) {
    return data.map((item, key) => {
        let obj = item;

        if (!isDemo(item)) {
            var allowedActions = actions
                .map(currentAction => {
                    if (currentAction.displaySlot) {
                        if (currentAction.displayStatus.includes(obj["status"])) {
                            if (currentAction.displaySlot.includes(obj["slot"])) {
                                return currentAction;
                            } else if (currentAction.displaySlot.includes("*")) {
                                return currentAction;
                            }
                        }
                        return undefined
                    } else {
                        if (currentAction.displayStatus.includes(obj["status"])) {
                            return currentAction;
                        }
                        return undefined
                    }
                })
                .filter(function (element) {
                    return element !== undefined;
                });
            if (allowedActions.length > 0) {
                obj["actions"] = (
                    <ButtonDropDown
                        key={"row-" + key}
                        options={allowedActions}
                        actionIndex={key}
                    />
                );
            }
        } else {
            obj["actions"] = "Actions disabled for demo";
        }
        return obj;
    })
}