import { Container, Grid } from "@mui/material";
import ContactUsLandingPage from "aeromate-kit/LandingPage/sections/ContactUsLandingPage";
import Box from "components/Box";
import ComingSoon from "components/Common/ComingSoon";
import Typography from "components/Typography";
import ReactMarkdown from "react-markdown";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ImgsViewer from "react-images-viewer";
import { useState } from "react";
import { redirect } from "react-router-dom";

export default function Body(props) {
    const content = props.content.content;
    const [imgsViewer, setImgsViewer] = useState(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
    const openImgsViewer = (i) => { setImgsViewerCurrent(i ?? 0); setImgsViewer(true) };
    const closeImgsViewer = () => setImgsViewer(false);
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);
    if(Object.keys(content[0]).length == 1 && Object.keys(content[0])[0] == "link")
    {
        location.replace("/keyvault");
    }
    // Modulo 2 rechts anders links
    return (
        <Box px={{ xs: 3, lg: 0 }}>
            <ImgsViewer
                imgs={content.map((e) => ({ src: e.image }))}
                isOpen={imgsViewer}
                onClose={closeImgsViewer}
                currImg={imgsViewerCurrent}
                onClickPrev={imgsViewerPrev}
                onClickNext={imgsViewerNext}
                backdropCloseable
            />
            <ArrowBackIcon onClick={() => window.location.href = `/features`} fontSize="large" fontWeight="bold" sx={{ cursor: "pointer", marginLeft: "16px" }}></ArrowBackIcon>
            <Container>
                <Grid container spacing={3} sx={{ marginTop: "-72px" }}>
                    <Grid item xs={12} spacing={3} mb={5}>
                        <Typography variant="h1" >{content[0].title}</Typography>
                        <ReactMarkdown>{content[0].description}</ReactMarkdown>
                    </Grid>
                    <Grid item xs={12} mb={10}>

                        <Box
                            component="img"
                            src={content[0].image}
                            alt={content[0].title}
                            borderRadius="xl"
                            shadow="xl"
                            width="100%"
                            position="relative"
                            zIndex={1}
                            onClick={() => openImgsViewer(0)}
                        />

                    </Grid>
                    {content.map((item, key) => {

                        if (key > 0) {
                            if (key % 2 == 0) {


                                return (
                                    <Grid container spacing={6} mb={12} ml={0} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                component="img"
                                                src={item.image}
                                                alt={item.title}
                                                borderRadius="xl"
                                                shadow="xl"
                                                width="100%"
                                                position="relative"
                                                zIndex={1}
                                                sx={{ objectFit: "contain", height: "500px", bgcolor: "#F0F2F5" }}
                                                onClick={() => openImgsViewer(key)}
                                            />

                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h2" >{item.title}</Typography>
                                            <ReactMarkdown>{item.description}</ReactMarkdown>
                                        </Grid>
                                    </Grid>
                                )

                            }
                            else {

                                return (
                                    <Grid container spacing={6} mb={12} ml={0} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={6}  >
                                            <Typography variant="h2" >{item.title}</Typography>
                                            <ReactMarkdown>{item.description}</ReactMarkdown>
                                        </Grid>

                                        <Grid item xs={12} md={6}>

                                            <Box
                                                component="img"
                                                src={item.image}
                                                alt={item.title}
                                                borderRadius="xl"
                                                shadow="xl"
                                                width="100%"
                                                position="relative"
                                                zIndex={1}
                                                sx={{ objectFit: "contain", height: "500px", bgcolor: "#F0F2F5" }}
                                                onClick={() => openImgsViewer(key)}
                                            />

                                        </Grid>

                                    </Grid>)
                            }
                        }

                    })}


                </Grid>
                <ContactUsLandingPage></ContactUsLandingPage>
            </Container>
        </Box >
    )
}

