import { useEffect, useState } from "react";
import { Card, Grid, Stepper, Step, StepLabel } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import InformationTab from "./components/InformationTab";
import VariantsTab from "./components/VariantsTab";
import ImageTab from "./components/ImageTab";
import { useModal } from "hooks/modalHook";
import PreviewTab from "./components/PreviewTab";
import { useParams } from "react-router-dom";
import { restPost } from "lib/rest";
import ChecklistTab from "./components/ChecklistTab";
import { v4 as uuidv4 } from 'uuid';
import LoadingModal from "components/Modal/LoadingModal";
import { legalFromAlias } from "lib/legalInfo";
import { feeFromAlias } from "lib/legalInfo";
import { restGet } from "lib/rest";

function ShopItemCreator({ type }) {
    const { t } = useTranslation();
    const { alias } = useParams()
    const [legalEntity, setLegalEntity] = useState();
    const { addModal, removeModal } = useModal();
    const [stepData, setStepData] = useState({})
    const [croppedImage, setCroppedImage] = useState();
    const setData = (data) => { setStepData({ ...stepData, [content[activeStep].id]: data }) }
    const [item, setItem] = useState();
    const [charter,setCharter] = useState();
    useEffect(async () => {
        if (!!alias) {
            let data = await legalFromAlias(alias)
            setLegalEntity(data);
        }
    }, []);

    const [fee, setFee] = useState();
    useEffect(async () => {
        if (!!alias) {
            let data = await feeFromAlias(alias)
            setFee(data);
        }
    }, []);

    useEffect(async () => {
        await restGet((res) => setCharter(!!res && !!res.data && res.data[0]), 'charter/' + alias)
    }, [alias]);

    useEffect(() => {
        if (stepData["information"] && stepData["variants"]) {
            let newItem = {
                caption: stepData["information"]?.caption,
                description: stepData["information"]?.description,
                variant: stepData["variants"]?.variant,
            }
            setItem(newItem)
        }
    }, [stepData]);

    let content = [
        {
            id: "information",
            label: t("dashboard:shopitem.general_tab_label"),
            component: <InformationTab setData={setData} defaultData={stepData['information']} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => {
                return stepData
                    && stepData['information']
                    && stepData['information'].caption?.en
                    && stepData['information'].description?.en
            }
        },
        {
            id: "variants",
            label: t("dashboard:shopitem.variants_tab_label"),
            component: <VariantsTab setData={setData} defaultData={stepData['variants']} fee={fee} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => {
                return stepData
                    && stepData['variants']
                    && stepData['variants'].variant?.length > 0
            }
        },
        {
            id: "image",
            label: t("dashboard:shopitem.image_tab_label"),
            component: <ImageTab setCroppedImage={setCroppedImage} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return croppedImage; }
        }
    ]

    if (type === "enrollment") content = content.concat(
        {
            id: "theory",
            label: t("dashboard:shopitem.theory_tab_label"),
            component: <ChecklistTab key={"theory"} subtype={"theory"} setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return stepData && stepData['theory'] && !_.isEmpty(stepData['theory']) && !_.isEmpty(stepData['theory'][0]) && !_.isEmpty(stepData['theory'][0].checklist); }
        },
        {
            id: "practice",
            label: t("dashboard:shopitem.practice_tab_label"),
            component: <ChecklistTab key={"practice"} subtype={"practice"} setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return stepData && stepData['practice'] && !_.isEmpty(stepData['practice']) && !_.isEmpty(stepData['practice'][0]) && !_.isEmpty(stepData['practice'][0].checklist); }
        },
        {
            id: "exams",
            label: t("dashboard:shopitem.exams_tab_label"),
            component: <ChecklistTab key={"exams"} subtype={"exams"} setData={setData} />,
            handleNext: () => { setActiveStep(activeStep + 1) },
            handleBack: () => { setActiveStep(activeStep - 1) },
            isValid: () => { return stepData && !_.isEmpty(stepData['exams']); }
        })

    content.push({
        id: "preview",
        label: t("dashboard:shopitem.preview_title"),
        component: <PreviewTab charter={charter} defaultData={item} previewImage={croppedImage} legalEntity={legalEntity} />,
        handleNext: () => { setActiveStep(activeStep + 1) },
        handleBack: () => { setActiveStep(activeStep - 1) },
        isValid: () => { return true; }
    })

    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === content.length - 1;

    const [errors, setErrors] = useState();
    useEffect(() => {
        if (stepData) {
            let newErrors = [];
            switch (activeStep) {
                case 0:
                    _.isEmpty(stepData['information']?.caption?.en) && newErrors.push("English Caption missing")
                    _.isEmpty(stepData['information']?.description?.en) && newErrors.push("English Description missing")
                    break;
                case 1:
                    _.some(stepData['variants']?.variant, (v) => {
                        return _.isEmpty(v?.caption?.en)
                    }) && newErrors.push("English title missing")
                    break;
                default:
                    break;
            }
            setErrors(newErrors)
        }

    }, [stepData, activeStep]);

    async function handleCreate() {
        let body = {
            caption: stepData['information'].caption,
            description: stepData['information'].description,
            variant: stepData['variants'].variant,
            item_type: type,
            item_sub_type: type === "voucher" ? "scenic" : "licence",
        }
        addModal(<LoadingModal customText={"Creating Shop Item"} />)
        const shopitem = await restPost(null, `shop/${alias}/item`, body)
        removeModal()

        let item_id = shopitem?.data[0]?._id?.$oid

        if (item_id && croppedImage) {
            addModal(<LoadingModal customText={"Uploading Images"} />)
            for (let index = 0; index < croppedImage.length; index++) {
                let blob = await fetch(croppedImage[index]).then(r => r.blob()).then(blobFile => new File([blobFile], "croppedImage", { type: "image/png" }))
                await restPost(null, `shop/${item_id}/image/${uuidv4()}`, blob, null, { "Content-Type": blob.type })
            }
            removeModal()

            /**
             * Add Checklists
             */
            if (type === "enrollment") {
                addModal(<LoadingModal customText={"Saving checklists"} />)
                if (!_.isEmpty(stepData['theory'])) {
                    let checklistBody = {
                        type: "enrollment",
                        subtype: "theory",
                        listtype: "nested",
                        shop_item_id: item_id,
                        checklist: stepData['theory']
                    }
                    await restPost(null, `checklist/`, checklistBody);
                }
                //Save Checklist
                if (!_.isEmpty(stepData['practice'])) {
                    let checklistBody = {
                        type: "enrollment",
                        subtype: "practice",
                        listtype: "nested",
                        shop_item_id: item_id,
                        checklist: stepData['practice']
                    }
                    await restPost(null, `checklist/`, checklistBody);
                }
                //Save Checklist
                if (!_.isEmpty(stepData['exams'])) {
                    let checklistBody = {
                        type: "enrollment",
                        subtype: "exams",
                        listtype: "flat",
                        shop_item_id: item_id,
                        checklist: stepData['exams']
                    }
                    await restPost(null, `checklist/`, checklistBody);
                }
                removeModal()
            }
        }

        window.location.href = `/my/${alias}/shop`
    }


    return (
        <Box pb={8}>
            <Grid container justifyContent="center" >
                <Grid item xs={12} xl={8}>
                    <Box mb={8} textAlign="center">
                        <Box mb={1}>
                            <Typography variant="h3" fontWeight="bold">
                                {t("dashboard:shopitem.create.title")}
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight="regular" color="secondary">
                            {t("dashboard:shopitem.create.subtitle")}
                        </Typography>
                    </Box>
                    <Card>
                        <Box mt={-3} mx={2}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {content.map((item) => (
                                    <Step key={item.label}>
                                        <StepLabel>{item.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        <Box p={2}>
                            <Box>
                                {content[activeStep]?.component}
                                <Box mt={3} width="100%" display="flex" justifyContent="space-between">
                                    {activeStep === 0 ? (
                                        <Box />
                                    ) : (
                                        <Button
                                            disabled={!!content[activeStep].disbaledButtons}
                                            variant="outlined"
                                            color="dark"
                                            onClick={content[activeStep].handleBack}
                                        >
                                            {t("common:button.back")}
                                        </Button>
                                    )}
                                    <Box >

                                        {errors?.map((e, index) => <Typography key={"error-" + index} color="error" variant="button">{e}</Typography>)}
                                    </Box>
                                    <Button
                                        disabled={!content[activeStep].isValid() || !!content[activeStep].disbaledButtons || errors?.length > 0}
                                        variant="gradient"
                                        color="dark"
                                        onClick={!isLastStep ? content[activeStep].handleNext : handleCreate}
                                    >
                                        {isLastStep ? t("common:button.create") : t("common:button.next")}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ShopItemCreator;