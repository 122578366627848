import UserModal from "components/Modal/UserModal/UserModal";
import i18n from "locals/config";

export const userColumns = [
    {
        Header: i18n.t("common:label.name"),
        accessor: "identity[0].name",
    },
    {
        Header: i18n.t("common:label.email"),
        accessor: "identity[0].email",
    },
    {
        Header: i18n.t("common:label.role"),
        accessor: "staff.type",
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
]

export const userActions = (addModal, data) => {
    return [
        {
            displayStatus: [undefined],
            title: i18n.t("common:label.assignRole"),
            action: (index) => {
                addModal(<UserModal
                    operation= "assign"
                    data= {data[index]}
                />)
            }
        },
        {
            displayStatus: [undefined],
            title: i18n.t("common:label.remove"),
            action: (index) => {
                addModal(<UserModal
                    operation= "remove"
                    data= {data[index]}
                />)
            }
        }
    ]
};