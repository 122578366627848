import { UserAgentApplication } from "msal";
import decodeJWT from "jwt-decode";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { restPut } from "lib/rest";

// Config object to be passed to Msal on creation.
const config = {
  msal: {
    scopes: ["https://aeromate.onmicrosoft.com/ReactAppApi/user_impersonation"],
    instance: "https://aeromate.b2clogin.com/tfp/",
    tenant: "aeromate.onmicrosoft.com/",
    authorityLogin: "https://aeromate.b2clogin.com/tfp/aeromate.onmicrosoft.com/B2C_1_sign_in_sign_up",
    authorityEdit: "https://aeromate.b2clogin.com/tfp/aeromate.onmicrosoft.com/b2c_1_edit_profile",
    authorityResetPw: "https://aeromate.b2clogin.com/tfp/aeromate.onmicrosoft.com/b2c_1_reset_password",
    clientId: "e4133307-f123-45a4-9eb2-5a013ddf9ba0"
  }
};

class Auth {
  constructor(configuration) {
    //Aquire Token scopes
    this.signInOptions = { scopes: configuration.msal.scopes };
    this.renewToken = { scopes: [configuration.msal.clientId] };
    //Login authority
    this.authorityLogin = { authority: configuration.msal.authorityLogin };
    //Edit authority
    this.authorityEdit = { authority: configuration.msal.authorityEdit };
    this.authorityResetPw = { authority: configuration.msal.authorityResetPw };

    //User Agent config
    this.msalConfig = {
      auth: {
        clientId: configuration.msal.clientId,
        authority: "https://aeromate.b2clogin.com/tfp/aeromate.onmicrosoft.com/B2C_1_sign_in_sign_up",
        validateAuthority: false,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
      }
    };

    //MSAL Client
    this.msal = new UserAgentApplication(this.msalConfig);
    this.msal.handleRedirectCallback(message => {
      if (message) {
        if (message.errorMessage) {
          if (message.errorMessage.startsWith("AADB2C90118")) {
            this.msal.loginRedirect(this.authorityResetPw);
          }
        }
      }
    });
  }

  /**
   *
   * @param {*} successCallback
   */
  login() {
    if (!this.msal.getLoginInProgress()) {
      this.msal.loginRedirect();
    }
  }

  /**
   *
   */
  logout() {
    sessionStorage.removeItem("msal.idtoken");
    this.msal.logout();
  }

  /**
   *
   * @param {*} successCallback
   */
  async refreshTokenAsync() {
    return await this.msal
      .acquireTokenSilent(this.renewToken)
      .then(response => {
        sessionStorage.setItem("msal.idtoken", response.idToken.rawIdToken);
        return response.idToken.rawIdToken;
      })
      .catch(err => {
        console.log("refreshTokenAsync()", err);
        sessionStorage.removeItem("msal.idtoken");
        // could also check if err instance of InteractionRequiredAuthError if you can import the class.
      });
  }

  async updateProfile() {
    return await this.msal
      .acquireTokenSilent({ ...this.renewToken, forceRefresh: true })
      .then(async response => {
        sessionStorage.setItem("msal.idtoken", response.idToken.rawIdToken);
        await restPut(null, "identity/", {});
        //expliziter reload wegen identity change
        window.location.reload();
      })
      .catch(err => {
        console.log("refreshTokenAsync()", err);
        sessionStorage.removeItem("msal.idtoken");
        // could also check if err instance of InteractionRequiredAuthError if you can import the class.
      });
  }

  editProfil() {
    this.msal
      .loginPopup({ ...this.authorityEdit })
      .then(() => {
        this.updateProfile();
      })
      .catch(error => {
        console.log("editProfil()", error);
      });
  }

  editPassword() {
    this.msal
      .loginPopup({ ...this.authorityResetPw })
      .then(response => {
        sessionStorage.setItem("msal.idtoken", response.idToken.rawIdToken);
        this.updateProfile();
        //expliziter reload wegen identity change
        window.location.reload();
      })
      .catch(error => {
        console.log("editPassword()", error);
      });
  }

  getToken() {
    return sessionStorage.getItem("msal.idtoken");
  }

  isLoggedIn() {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  expiresIn() {
    let token = sessionStorage.getItem("msal.idtoken");
    if (token) {
      const decoded = decodeJWT(token);
      return decoded.exp;
    }
    return undefined;
  }

  currentUser() {
    const decoded = decodeJWT(this.getToken());
    return {
      name: decoded.name,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      emails: decoded.emails,
      city: decoded.city,
      country: decoded.country
    };
  }

  refreshTokenOrLogin(successCallback) {
    this.msal
      .acquireTokenSilent(this.renewToken)
      .then(response => {
        sessionStorage.setItem("msal.idtoken", response.idToken.rawIdToken);
        if (successCallback) successCallback(response);
        return response.idToken.rawIdToken;
      })
      .catch(err => {
        console.log("refreshTokenAsync()", err);
        this.login();
      });
  }

  required(WrappedComponent) {
    //return WrappedComponent
    return function WrapperResult(props) {
      const [signedIn, setSignedIn] = useState(false);

      useEffect(() => {
        instance.refreshTokenOrLogin(() => {
          setSignedIn(true);
        });
      }, []);

      if (signedIn) {
        return <WrappedComponent {...props} />;
      }
      return typeof renderLoading === "function" ? <CircularProgress /> : null;
    };
  }
}

const instance = new Auth(config);

export default instance;
