import AboutUsPage from "aeromate-kit/AboutUsPage";
import KeyVaultPage from "aeromate-kit/AddonPages/KeySafePage";
import AdvisoryPage from "aeromate-kit/AdvisoryPages";
import SubPageAdvisory from "aeromate-kit/AdvisoryPages/SubPage.js";
import AircraftCalendarPage from "aeromate-kit/AircraftCalendarPage";
import AircraftPage from "aeromate-kit/AircraftPage";
import AircraftSearchPage from "aeromate-kit/AircraftSearchPage";
import AirportPage from "aeromate-kit/AirportPage";
import CharterPage, { MinimalCharterShop, MinimalCharterCourses } from "aeromate-kit/CharterPage";
import DevelopmentPage from "aeromate-kit/DevelopmentPage";
import Dummy from "aeromate-kit/Dummy";
import Features from "aeromate-kit/FeaturePage";
import Featurepages from "aeromate-kit/FeaturePage/SubPages";
import { ForATOsPage, ForPilotsPage } from "aeromate-kit/ForXPage";
import GettingStartedPage from "aeromate-kit/GettingStartedPage";
import GTCPage from "aeromate-kit/GTCPage";
import ImprintPage from "aeromate-kit/ImprintPage";
import KnowledgebasePage from "aeromate-kit/KnowledgebasePage";
import LandingPage from "aeromate-kit/LandingPage";
import PartnerPage from "aeromate-kit/PartnerPage";
import PrivacyPage from "aeromate-kit/PrivacyPage";
import Responsibility from "aeromate-kit/ResponsibiltyPage";
import SubPageResponsibility from "aeromate-kit/ResponsibiltyPage/SubPage.js";
import Services from "aeromate-kit/ServicesPages";
import SubPageServices from "aeromate-kit/ServicesPages/SubPage.js";
import ShopItemPage from "aeromate-kit/ShopItemPage";
import Solutions from "aeromate-kit/SolutionPage";
import SubPageSolutions from "aeromate-kit/SolutionPage/SubPage.js";
import SustainabiltyPage from "aeromate-kit/SustainabiltyPage";
import AgileTraining from "aeromate-kit/TrainingsPages";
import SubPage from "aeromate-kit/TrainingsPages/SubPage.js";
import Unsubscribe from "aeromate-kit/Unsubscribe";
import UserProfilePage from "aeromate-kit/UserProfilePage";
import VoucherPage from "aeromate-kit/VoucherPage";
import { MinimalVoucherPage } from "aeromate-kit/VoucherPage";

export default [
    {
        name: "landingpage",
        route: "/",
        component: <LandingPage />
    },
    {
        name: "gtc",
        route: "/terms",
        component: <GTCPage />
    },
    {
        name: "gtc",
        route: "/gtc",
        component: <GTCPage />
    },
    {
        name: "gtc",
        route: "/legal",
        component: <GTCPage />
    },
    {
        name: "privacy",
        route: "/privacy",
        component: <PrivacyPage />
    },
    {
        name: "imprint",
        route: "/imprint",
        component: <ImprintPage />
    },
    {
        name: "aboutus",
        route: "/aboutus",
        component: <AboutUsPage />
    },
    {
        name: "gettingstarted",
        route: "/gettingstarted",
        component: <GettingStartedPage />
    },
    {
        name: "features",
        route: "/features/:pagename",
        component: <Featurepages />
    },
    {
        name: "features",
        route: "/features",
        component: <Features />
    },

    {
        name: "agile",
        route: "/agile-trainings/:Page",
        component: <SubPage />
    },
    {
        name: "agile-training",
        route: "/agile-trainings",
        component: <AgileTraining />
    },
    {
        name: "services",
        route: "/services",
        component: <Services />
    },
    {
        name: "services",
        route: "/services/:Page",
        component: <SubPageServices />
    },
    {
        name: "advisory",
        route: "/advisory",
        component: <AdvisoryPage />
    },
    {
        name: "advisory",
        route: "/advisory/:Page",
        component: <SubPageAdvisory />
    },
    {
        name: "solutions",
        route: "/solutions",
        component: <Solutions />
    },
    {
        name: "solutions",
        route: "/solutions/:Page",
        component: <SubPageSolutions />
    },
    {
        name: "responsibility",
        route: "/responsibility",
        component: <Responsibility />
    },
    {
        name: "responsibility",
        route: "/responsibility/:Page",
        component: <SubPageResponsibility />
    },
    {
        name: "integration",
        route: "/integration",
        component: <Dummy />
    },
    {
        name: "knowledgebase",
        route: "/knowledgebase",
        component: <KnowledgebasePage />
    },
    {
        name: "voucher",
        route: "/redeem",
        component: <VoucherPage />
    },
    {
        name: "pilots",
        route: "/for-pilots",
        component: <ForPilotsPage />
    },
    {
        name: "atos",
        route: "/for-atos",
        component: <ForATOsPage />
    },
    {
        name: "partner",
        route: "/partner",
        component: <PartnerPage />
    },
    // {
    //     name: "culture",
    //     route: "/culture",
    //     component: <CulturePage />
    // },
    {
        name: "keyvault",
        route: "/keyvault",
        component: <KeyVaultPage />
    },
    {
        name: "airportpage",
        route: "/airport/:ICAO",
        component: <AirportPage />
    },
    {
        name: "aircraftpage",
        route: "/aircraft/:REG",
        component: <AircraftPage />
    },
    {
        name: "aircraftcalendarpage",
        route: "/aircraft/:REG/calendar",
        component: <AircraftCalendarPage />
    },
    {
        name: "orgpage",
        route: "/org/:ALIAS",
        component: <CharterPage />
    },
    {
        name: "shopitemPage",
        route: "/org/:ALIAS/:ID",
        component: <ShopItemPage />
    },
    {
        name: "aircraftSearch",
        route: "/aircraftsearch",
        component: <AircraftSearchPage />
    },
    {
        name: "UserProfile",
        route: "/user/:identity",
        component: <UserProfilePage />

    },
    {
        name: "Unsubscribe",
        route: "/unsubscribe",
        component: <Unsubscribe />

    },
    {
        name: "SustainabiltyPage",
        route: "/sustainabilty",
        component: <SustainabiltyPage />

    },
    {/* Minimal Pages*/ },
    {
        name: "charterpublic",
        route: "public/charter/:ALIAS/shop",
        component: <MinimalCharterShop />
    },
    {
        name: "charterpublic",
        route: "public/charter/:ALIAS/courses",
        component: <MinimalCharterCourses />
    },
    {
        name: "orgpublic",
        route: "public/org/:ALIAS/shop",
        component: <MinimalCharterShop />
    },
    {
        name: "orgpublic",
        route: "public/org/:ALIAS/courses",
        component: <MinimalCharterCourses />
    },
    {
        name: "voucherpublic",
        route: "public/redeem",
        component: <MinimalVoucherPage />
    },
    // Old Pages
    {
        name: "charterpublic",
        route: "charter/:ALIAS/shop/min",
        component: <MinimalCharterShop />
    },
    {
        name: "voucherpublic",
        route: "public/voucher",
        component: <MinimalVoucherPage />
    }, {
        name: "orgpublic",
        route: "org/:ALIAS/shop/min",
        component: <MinimalCharterShop />
    },
    {
        name: "dev",
        route: "dev0815",
        component: <DevelopmentPage />
    },
    {
        name: "demo",
        route: "aero-expo-2024-demo",
        component: <DevelopmentPage />
    },
]