import { Grid, Table, TableBody, TableRow } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import { millisecondsToHHMM } from "lib/functions";
import { secondsToHHMM } from "lib/functions";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
        `${borderWidth[1]} solid ${light.main}`,
};

export default function SummaryStep({ booking, teclog }) {
    const { t } = useTranslation();
    const columns = [
        {
            label: t("dashboard:logtimes.general.table.date"),
            accessor: "dof",
            formatter: (param) => { return moment(param).format("L") }
        },
        {
            label: t("dashboard:logtimes.general.table.dep_place"),
            accessor: "orig"
        },
        {
            label: t("dashboard:logtimes.general.table.dep_time"),
            accessor: "atot",
            formatter: (param) => { return moment(param).format("LT") }
        },
        {
            label: t("dashboard:logtimes.general.table.arr_place"),
            accessor: "dest"
        },
        {
            label: t("dashboard:logtimes.general.table.arr_time"),
            accessor: "aldt",
            formatter: (param) => { return moment(param).format("LT") }

        },
        {
            label: t("dashboard:logtimes.general.table.time"),
            accessor: (params) => { return params.aldt - params.atot },
            formatter: (param) => { return millisecondsToHHMM(param) }
        },
        {
            label: t("dashboard:logtimes.general.table.landings"),
            accessor: "ldg"
        },
    ]

    const [aircraftReg, setAircraftReg] = useState();
    const [aircraftType, setAircraftType] = useState();
    const [aircraftClass, setAircraftClass] = useState();
    const [aircraftCert, setAircraftCert] = useState();
    useEffect(() => {
        if (!!booking && !!booking.aircraft && !!booking.aircraft[0]) {
            !!booking.aircraft[0].reg ? setAircraftReg(booking.aircraft[0].reg) : ""
            !!booking.aircraft[0].type ? setAircraftType(booking.aircraft[0].type) : ""
            !!booking.aircraft[0].class ? setAircraftClass(booking.aircraft[0].class) : ""
            !!booking.aircraft[0].certification ? setAircraftCert(booking.aircraft[0].certification) : ""
        }
    }, [booking])

    const [crew, setCrew] = useState([]);
    const [flights, setFlights] = useState([]);

    useEffect(() => {
        if (!!teclog) {
            !!teclog.pob_data && setCrew(teclog.pob_data)
            !!teclog.flight_data && setFlights(teclog.flight_data)
        }
    }, [teclog])

    return (
        <Box >
            {/* Aircraft Information */}
            <Box position="relative">
                <Box width="100%" >
                    <Typography variant="h5" fontWeight="medium">
                        {t("dashboard:logtimes.general.aircraft")}
                    </Typography>
                </Box>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                    mt={{ xs: 3, md: 0 }}
                >
                    <Box width="100px">
                        <Typography
                            variant="h6"
                            color="secondary"
                            fontWeight="regular"
                        >
                            {t("dashboard:logtimes.general.a_reg")}
                        </Typography>
                    </Box>
                    <Box >
                        <Typography variant="h6" fontWeight="medium">
                            {aircraftReg}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                >
                    <Box width="100px">
                        <Typography
                            variant="h6"
                            color="secondary"
                            fontWeight="regular"
                        >
                            {t("dashboard:logtimes.general.a_type")}
                        </Typography>
                    </Box>
                    <Box >
                        <Typography variant="h6" fontWeight="medium">
                            {aircraftType}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                >
                    <Box width="100px">
                        <Typography
                            variant="h6"
                            color="secondary"
                            fontWeight="regular"
                        >
                            {t("dashboard:logtimes.general.a_class")}
                        </Typography>
                    </Box>
                    <Box >
                        <Typography variant="h6" fontWeight="medium">
                            {aircraftClass}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                >
                    <Box width="100px">
                        <Typography
                            variant="h6"
                            color="secondary"
                            fontWeight="regular"
                        >
                            {t("dashboard:logtimes.general.a_cert")}
                        </Typography>
                    </Box>
                    <Box >
                        <Typography variant="h6" fontWeight="medium">
                            {aircraftCert}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/* Crew Information */}
            <Box position="relative">
                <Box width="100%" >
                    <Typography variant="h5" fontWeight="medium">
                        {t("dashboard:logtimes.general.crew")}
                    </Typography>
                </Box>
                {crew.map((element, index) =>
                    <Box
                        key={"crew-row-" + index}
                        width="100%"
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        alignItems={{ xs: "flex-start", md: "center" }}
                        mt={{ xs: 3, md: 0 }}
                    >
                        <Box width="100px">
                            <Typography
                                variant="h6"
                                color="secondary"
                                fontWeight="regular"
                            >
                                {element.duty.name}
                            </Typography>
                        </Box>
                        <Box >
                            <Typography variant="h6" fontWeight="medium">
                                {element.name}
                            </Typography>
                        </Box>
                    </Box>)
                }
            </Box>
            {/* Summary Table */}
            <Box pt={3} position="relative">
                <Box width="100%" overflow="auto">
                    <Table sx={{ minWidth: "32rem" }}>
                        <Box component="thead">
                            <TableRow>
                                {columns.map((element, index) =>
                                    <Box
                                        key={"table-headline-" + index}
                                        component="th"
                                        py={1.5}
                                        pl={3}
                                        pr={1}
                                        textAlign={element.textAlign ?? "left"}
                                        sx={borderBottom}
                                    >
                                        <Typography variant="h6" color="text" fontWeight="medium">
                                            {element.label}
                                        </Typography>
                                    </Box>)}
                            </TableRow>
                        </Box>
                        <TableBody>
                            {flights.map((element, index) => {

                                let content = (column) => {
                                    if (_.isString(column.accessor)) {
                                        return element[column.accessor]
                                    }
                                    if (_.isFunction(column.accessor)) {
                                        return column.accessor(element)
                                    }
                                    return null
                                }

                                let formatContent = (column) => {
                                    if (!!column.formatter) return column.formatter(content(column))
                                    return content(column)
                                }

                                return (
                                    <TableRow>
                                        {columns.map((col, idx) => (
                                            <Box
                                                key={"table-row-" + index}
                                                component="td"
                                                textAlign={element.textAlign ?? "left"}
                                                py={1}
                                                pr={1}
                                                pl={3}
                                                sx={borderBottom}
                                            >
                                                <Typography variant="body2" color="text" fontWeight="regular">
                                                    {formatContent(col)}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Box>
            </Box >
        </Box >
    )
}
