import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useTranslation } from "react-i18next";

export function ForPilotsPage() {
    const { t } = useTranslation();
    return (
        <KitLayout header={<Header />} title={t("routes:forPilots.title")}>
            <Body pagename="forPilotsPage" />
        </KitLayout>
    )
}

export function ForATOsPage() {
    const { t } = useTranslation();
    return (
        <KitLayout header={<Header />} title={t("routes:forATOs.title")}>
            <Body pagename="forATOsPage" />
        </KitLayout>
    )
}