import React from 'react';
import { Player, BigPlayButton } from 'video-react';

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Typography from "components/Typography";
import { Stack } from '@mui/material';
import "video-react/dist/video-react.css"
import DefaultInfoCard from 'components/Cards/InfoCards/DefaultInfoCard';
// Images
import icon from "assets/images/logo_32.png";
import brand from 'constants/brand';

function Presentation() {

  return (
    <Box component="section" py={{ xs: 6, lg: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6}>
            {/*    <Typography variant="h2" mb={1}>
              Lorem ipsum
            </Typography>
            <Typography variant="body2" mb={2}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.
            </Typography> */}
          </Grid>
        </Grid>
        <Grid container spacing={8} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Player src="https://aeromatestatic.blob.core.windows.net/vid/Keyvault.mp4"
              poster="https://aeromatestatic.blob.core.windows.net/vid/keyvault-thumbnail.jpg">
              <BigPlayButton position="center" />
            </Player>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} >
                <DefaultInfoCard
                  icon={<Box component="img" src={icon} alt={brand.name + " favicon"} width="6%" />}
                  title="Codes assigned remotely/automatically via web portal"
                  description="Automatically generated online codes sent remotely provide a convenient and secure method for access."
                  small
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <DefaultInfoCard
                  icon={<Box component="img" src={icon} alt={brand.name + " favicon"} width="6%" />}
                  title="Seven-digit online code"
                  description="Our seven-digit online digital codes ensure security through a variety of unique combinations and a time-limited validity period, providing protected access."
                  small
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <DefaultInfoCard
                  icon={<Box component="img" src={icon} alt={brand.name + " favicon"} width="6%" />}
                  title="Suitable for outdoor use"
                  description="Perfect for outdoor use - made of high quality stainless steel brushed."
                  small
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <DefaultInfoCard
                  icon={<Box component="img" src={icon} alt={brand.name + " favicon"} width="6%" />}
                  title="No internet or cellular connection required at the device"
                  description="Benefit from the flexibility of being independent of an Internet or cellular connection, which is not required at the device"
                  small
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <DefaultInfoCard
                  icon={<Box component="img" src={icon} alt={brand.name + " favicon"} width="6%" />}
                  title="No power connection required"
                  description="With its independence from power sources and ease of installation, this safe offers an extremely flexible solution for securely storing your keys."
                  small
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <DefaultInfoCard
                  icon={<Box component="img" src={icon} alt={brand.name + " favicon"} width="6%" />}
                  title="In operation for over 3 years"
                  description="Three years of use of this safe underlines its proven reliability, long-term value, established security solution and the experience gained in the safe storage of your keys."
                  small
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container >
    </Box >
  );
}

export default Presentation;