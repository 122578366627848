
import Typography from "components/Typography";
import { getDMS } from "lib/functions";
import { Grid } from "@mui/material";
import Box from "components/Box";
import { useTranslation } from "react-i18next";

export default function AirportInfo({ airport, color }) {
    const { t } = useTranslation();
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <Box p={3} lineHeight={1}>
                    <Typography color={color}>
                        {t('common:label.coordinates')}
                    </Typography>
                    <Typography variant="h3" color={color}>
                        {!!airport.location && getDMS(airport.location.coordinates[1], 'lat')} {!!airport.location && getDMS(airport.location.coordinates[0], 'long')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Box p={3} lineHeight={1}>
                    <Typography color={color}>
                        {t('common:label.elevation')}
                    </Typography>
                    <Typography variant="h3" color={color}>
                        {airport.elevation_ft}
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Box p={3} lineHeight={1}>
                    <Typography color={color}>
                        {t('common:label.icao')}
                    </Typography>
                    <Typography variant="h3" color={color}>
                        {airport.icao}
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Box p={3} lineHeight={1}>
                    <Typography color={color}>
                        {t('common:label.iata')}
                    </Typography>
                    <Typography variant="h3" color={color}>
                        {airport.iata}
                    </Typography>
                </Box>
            </Grid>
        </Grid >
    )
}
