import Box from "components/Box";
import Selection from "./Selection";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "locals/config";
import i18next from "i18next";
import moment from "moment";
import { locale } from "locals/config";
import { useState } from "react";

export default function LanguageSelection({ ...rest }) {
    const { t } = useTranslation();
    const [value, setValue] = useState(moment.locale(locale.split('-')[0]));

    function handleChange(event) {
        var lang = event.target.value.toLowerCase()
        i18next
            .changeLanguage(lang)
            .then((t) => { t('key'); /*  -> same as i18next.t */ });
        moment.locale(lang + "-" + lang)
        sessionStorage.setItem("lang", lang + "-" + lang);
        setValue(lang)
    }


    return (
        <Box width={'256px'} {...rest}>
            <Selection label={t("common:label.language")} options={availableLanguages} onChange={handleChange} value={value} />
        </Box>
    )
}