import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { restGet } from 'lib/rest';
import Input from "components/Input";
import _ from 'lodash';

export default function CountrySearch({ label, onChange, value, ...rest }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        if (searchTerm !== "")
            setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            (async () => {
                const callback = (res) => {
                    if (Array.isArray(res))
                        setOptions(res ?? []);
                }
                await restGet(callback, `dataset/country/search/${searchTerm}/`)
                setLoading(false)
            })();
        }, 800)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        if (options.length === 1) {
            onChange(options[0])
        }
    }, [options]);

    useEffect(() => {
        if (value?.alpha3Code && !value?.code && !value?.name) {
            setSearchTerm(value.alpha3Code)
        }
    }, [value]);

    return (
        <Autocomplete
            {...rest}
            id="asynchronous-demo"
            open={open}
            value={value}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            isOptionEqualToValue={(option, value) => option.name === value.name || option.code === value.code || options.alpha3Code === value.alpha3Code}
            getOptionLabel={(option) => option.name ?? ""}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <Input
                    {...params}
                    value={searchTerm}
                    onChange={handleChange}
                    label={label ?? "lable"}
                    type="text"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}