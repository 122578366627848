import Auth from "lib/auth";
import axios from "axios";
import Snackbar from "components/Snackbar";
import React from "react";
import ReactDOM from "react-dom";

class APICall {
  constructor() {
    this.apiBaseURL =
      window.location.protocol + "//" + window.location.host + "/api/v2/";
    this.apiAuth = Auth;
    this.apiConfig = {};
  }

  set apiBaseURL(value) {
    this._apiBaseURL = value;
  }

  get apiBaseURL() {
    return this._apiBaseURL;
  }

  set apiURL(value) {
    this._apiURL = this._apiBaseURL + value;
  }

  get apiURL() {
    return this._apiURL;
  }

  set apiBody(value) {
    this._apiBody = value;
  }

  get apiBody() {
    return this._apiBody;
  }

  set apiParam(value) {
    const defParam = {};
    this._apiConfig["params"] = Object.assign(defParam, value);
  }

  get apiParam() {
    return this._apiConfig["params"];
  }

  set apiHeader(value) {
    var defHeader = {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": this.getCookie("CSRF-TOKEN")
      /* "Auth-Token": this.apiAuth.getToken() */
    };
    if (!!this.apiAuth.getToken())
      defHeader = { ...defHeader, "auth-token": this.apiAuth.getToken() }
    this._apiConfig["headers"] = Object.assign(defHeader, value);
  }

  get apiHeader() {
    return this._apiConfig["headers"];
  }

  set apiResult(value) {
    this._apiResult = value || { data: null };
  }

  get apiResult() {
    return this._apiResult;
  }

  set apiConfig(value) {
    this._apiConfig = value;
  }

  get apiConfig() {
    return this._apiConfig;
  }

  checkResponseError(response) {
    var result = response;
    if (!!response && !!response.data) {
      if (typeof response.data === "string") {
        result = response.data.includes("Fatal error") ? null : response;
      }
    }
    return result
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(window.document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}

class APICallGet extends APICall {
  constructor(apiURL, apiParam, apiHeader, apiOption) {
    super();

    this.apiURL = apiURL;
    this.apiConfig = apiOption || {};
    this.apiParam = apiParam || {};
    this.apiHeader = apiHeader || {};
  }

  async execCall() {
    this.apiResult = await axios
      .get(this._apiURL, this._apiConfig)
      .then(response => {
        return this.checkResponseError(response);
      })
      .catch(error => {
        ReactDOM.render(
          <Snackbar
            color='error'
            icon='error'
            title="ERROR"
            content={error.message}
            dateTime=""
            close={() => null}
          />,
          document.getElementById("aerosb")
        );
        return error;
      });
  }
}

export async function restGet(setter, apiURL, apiParam, apiHeader, apiOption) {
  let apiCallGet = new APICallGet(apiURL, apiParam, apiHeader, apiOption);
  await apiCallGet.execCall();
  !!setter && setter(apiCallGet.apiResult.data);
  return apiCallGet.apiResult.data;
}

class APICallPost extends APICall {
  constructor(apiURL, apiBody, apiParam, apiHeader) {
    super();

    this.apiURL = apiURL;
    this.apiBody = apiBody || {};
    this.apiParam = apiParam || {};
    this.apiHeader = apiHeader || {};
  }

  async execCall() {
    this.apiResult = await axios
      .post(this._apiURL, this._apiBody, this._apiConfig)
      .then(response => {
        return this.checkResponseError(response);
      })
      .catch(error => {
        ReactDOM.render(
          <Snackbar
            color='error'
            icon='error'
            title="ERROR"
            content={error.message}
            dateTime=""
            close={() => null}
          />,
          document.getElementById("aerosb")
        );
        return error;
      });
  }
}

export async function restPost(setter, apiURL, apiBody, apiParam, apiHeader) {
  let apiCallPost = new APICallPost(apiURL, apiBody, apiParam, apiHeader);
  await apiCallPost.execCall();
  !!setter && setter(apiCallPost.apiResult.data);
  return apiCallPost.apiResult.data;
}

class APICallPut extends APICall {
  constructor(apiURL, apiBody, apiParam, apiHeader) {
    super();

    this.apiURL = apiURL;
    this.apiBody = apiBody || {};
    this.apiParam = apiParam || {};
    this.apiHeader = apiHeader || {};
  }

  async execCall() {
    this.apiResult = await axios
      .put(this._apiURL, this._apiBody, this._apiConfig)
      .then(response => {
        return this.checkResponseError(response);
      })
      .catch(error => {
        ReactDOM.render(
          <Snackbar
            color='error'
            icon='error'
            title="ERROR"
            content={error.message}
            dateTime=""
            close={() => null}
          />,
          document.getElementById("aerosb")
        );
        return error;
      });
  }
}

export async function restPut(setter, apiURL, apiBody, apiParam, apiHeader) {
  let apiCallPut = new APICallPut(apiURL, apiBody, apiParam, apiHeader);
  await apiCallPut.execCall();
  !!setter && setter(apiCallPut.apiResult.data);
  return apiCallPut.apiResult.data;
}

class APICallDelete extends APICall {
  constructor(apiURL, apiBody, apiParam, apiHeader) {
    super();

    this.apiURL = apiURL;
    this.apiBody = apiBody || {};
    this.apiParam = apiParam || {};
    this.apiHeader = apiHeader || {};
  }

  async execCall() {
    var blubber1 = this.apiURL
    var blubber2 = this.apiBody
    var blubber3 = this.apiParam
    var blubber4 = this.apiHeader
    var blubber5 = this._apiConfig
    this.apiResult = await axios
      .delete(this._apiURL, this._apiBody, this._apiConfig)
      .then(response => {
        return this.checkResponseError(response);
      })
      .catch(error => {
        ReactDOM.render(
          <Snackbar
            color='error'
            icon='error'
            title="ERROR"
            content={error.message}
            dateTime=""
            close={() => null}
          />,
          document.getElementById("aerosb")
        );
        return error;
      });
  }
}

export async function restDelete(setter, apiURL, apiBody, apiParam, apiHeader) {
  let apiCallDelete = new APICallDelete(apiURL, apiBody, apiParam, apiHeader);
  await apiCallDelete.execCall();
  !!setter && setter(apiCallDelete.apiResult.data);
  return apiCallDelete.apiResult.data;
}