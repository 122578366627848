import { Grid, Input } from '@mui/material'
import Box from 'components/Box'
import Button from 'components/Button'
import Typography from 'components/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'


const CallToAction = () => {

        const { t } = useTranslation();

  return (
    <Box component="section" py={12} >
    <Box py={12} px={{ xs: 3, lg: 0 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={6} ml="auto">
          <Typography color="primary"  variant="h4" mb={1}>
          {t("sustainabiltyPage:calltoaction.title")}
          </Typography>
          <Typography variant="body2" color="text" mb={3}>
          {t("sustainabiltyPage:calltoaction.text")}
          </Typography>
          <Grid container spacing={2}>
            
            <Grid item xs={12} sm={4}>
       
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={4} position="relative">
          <Box
            component="img"
            src={"https://aeromatestatic.blob.core.windows.net/img/sustainablility_river_mountain.png"}
            alt="image"
            maxWidth="18.75rem"
            width="100%"
            borderRadius="lg"
            shadow="xl"
            mt={-18}
            display={{ xs: "none", lg: "block" }}
          />
        </Grid>
      </Grid>
    </Box>
  </Box>
  )
}

export default CallToAction