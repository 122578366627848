
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { useModal } from "hooks/modalHook";
import AeroLoading from "components/Progress/AeroLoading";
import _ from "lodash";
import { useRefresher } from "hooks/refreshHook";

function PostConfirmationModal({ bookings, action }) {
    const { t } = useTranslation();
    const { removeModal } = useModal();
    const { refresh } = useRefresher();
    const [count, setCount] = useState(1);

    useEffect(async () => {
        for (let index = 0; index < bookings.length; index++) {
            const element = bookings[index];
            await action(element._id.$oid)
            setCount(count + 1)
        }
        removeModal();
        refresh();
    }, [])

    return (
        <DefaultModal
            title={t("common:label.processing")}
            closeExplicit={true}
            cancel={{ show: false }}
            confirm={{ show: false }}
        >
            <AeroLoading customText={`${t("common:label.processing")} (${count} \\ ${bookings.length})`} />
        </DefaultModal >
    );
}

export default PostConfirmationModal;