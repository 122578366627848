
import { Container } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import brand from "constants/brand";
import { useTranslation } from "react-i18next";
import DefaultSwiper from "../components/DefaultSwiper";
import MembershipCard from "../components/MembershipCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";

const image = brand.image

function Memberships() {
    const { t } = useTranslation()

    return (
        <Box
            component="section"
            pt={2}
            id="memberships"
        >
            <Box variant="gradient" bgColor="dark" borderRadius="lg" width="100%" position="relative" py={6}>
                <Box
                    component="img"
                    src={bgPattern}
                    alt="pattern-lines"
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    opacity={0.6}
                />
                <Container>
                    <Typography variant="h5" color="white">
                        {t("partnerPage:memberships.title")}
                    </Typography>
                    <Typography variant="h2" color="white">
                        {t("partnerPage:memberships.subtitle")}
                    </Typography>

                    <Box pt={1} pb={1}>
                        <DefaultSwiper
                            slides={t("partnerPage:memberships.list", { returnObjects: true }).map((element) =>
                                <MembershipCard
                                    title={element.title}
                                    description={element.description}
                                    image={brand.static_cdn + element.image}
                                    url={element.url}
                                />
                            )}
                        />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default Memberships;