import { Grid, Stack } from "@mui/material";
import ActionInfoCard from "components/Cards/InfoCards/ActionInfoCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import StepCard from "./components/StepCard";

export default function Body() {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);

    const cards = [
        {
            id: "pilot",
            color: "info",
            icon: "support_agent",
            title: t("gettingStartedPage:pilot.title"),
            description: t("gettingStartedPage:pilot.description"),
        },
        {
            id: "ato",
            color: "info",
            icon: "business",
            title: t("gettingStartedPage:ato.title"),
            description: t("gettingStartedPage:ato.description"),
        },
        {
            id: "owner",
            color: "info",
            icon: "airplane_ticket",
            title: t("gettingStartedPage:owner.title"),
            description: t("gettingStartedPage:owner.description"),
        }
    ]

    return (
        <Stack>
            <Grid
                container
                alignItems="stretch"
                spacing={2}
            >
                {cards.map(({ color, icon, title, description }, index) => (
                    <Grid key={icon} item xs={4} sm={4} lg={4}>
                        <ActionInfoCard
                            color={color}
                            icon={icon}
                            title={title}
                            description={description}
                            activ={active === index}
                            onClick={() => setActive(index)}
                        />
                    </Grid>
                ))}
            </Grid>
            {t("gettingStartedPage:" + cards[active].id + ".sections", { returnObjects: true }).map((content, index) =>
                <StepCard key={"stepcard-" + index} index={index} content={content} />
            )}
        </Stack>)
}