import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import AirportInfo from "components/Airport/AirportInfo";
import AirportRunways from "components/Airport/AirportRunways";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AeroLoading from "components/Progress/AeroLoading"

function AircraftAirportTab({ icao }) {
    const [airport, setAirport] = useState()
    const [isloading, setIsLoading] = useState(false)
    const { t } = useTranslation();
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setAirport(resp.data[0])
            }
            setIsLoading(true)
            await restGet(restCallback, 'airport/' + icao)
            setIsLoading(false)
        }
        !!icao && fetch()
    }, [icao])

    if (!airport|| isloading) {
        return isloading ? <AeroLoading /> : null;
    }
    return (
        <Box py={6} id="partner">
            <Container>
                <Stack spacing={2}>
                    <Card sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
                        <Box variant="gradient" bgColor="dark" height="100%">
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Box p={3} lineHeight={1}>
                                    <Typography variant="h1" fontWeight="bold" color="white" mb={0.5}>
                                        {airport.name}
                                    </Typography>
                                </Box>
                                <Box p={3} lineHeight={1}>
                                    <Button
                                        component={Link}
                                        to={"/airport/" + icao}
                                        variant="contained"
                                        size="small"
                                        color="white"
                                        fullWidth
                                    >
                                        {t('common:button.details')}
                                    </Button>
                                </Box>
                            </Stack>
                            <AirportInfo airport={airport} color="white" />
                        </Box>
                    </Card>
                    <Typography variant="h4">
                        {t("common:label.runways")}
                    </Typography>
                    <AirportRunways airport={airport} />
                </Stack>
            </Container>
        </Box >
    );
}

export default AircraftAirportTab;