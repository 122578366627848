import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { useModal } from "hooks/modalHook";
import LoadingModal from "./LoadingModal";

function VoucherConfirmationModal({ firstName, email, charter_name, dateTime }) {
    const { t } = useTranslation();
    const { addModal } = useModal();

    return (
        <DefaultModal
            title={t("voucherPage:confirmationTitle")}
            onClose={() => {
                addModal(<LoadingModal />)
                //expliziter reload um das state der components zu reseten
                location.reload()
            }}
            cancel={{ show: false}}
            confirm={{
                show: true,
                text: t("common:button.okay"),
                color: "info"
            }}
        >
            <Typography variant="h3">{t("voucherPage:confirmationGreetings", { name: firstName })}</Typography>
            <Typography variant="h5">{t("voucherPage:confirmationMail", { mail: email })}</Typography>
            <Typography variant="h5">{t("voucherPage:confirmationConclusion", { charter: charter_name, date: dateTime.format("L"), time: dateTime.format("LT") })}</Typography>
        </DefaultModal>
    );
}

export default VoucherConfirmationModal;