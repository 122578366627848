import { Grid } from "@mui/material";
import Box from "components/Box";
import Input from "components/Input";
import DefaultModal from "components/Modal/DefaultModal";
import AeroLoading from "components/Progress/AeroLoading";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";
import { restPost } from "lib/rest";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function SepaEditModal({ data }) {
    const { t } = useTranslation();
    const { removeModal } = useModal();
    const [loading, setLoading] = useState(false)
    const [mandateReference, setMandateReference] = useState();
    const [creditorIdentifier, setCreditorIdentifier] = useState();
    const [validMR, setValidMR] = useState(false);
    const [validCI, setValidCI] = useState(false);
    const { refresh } = useRefresher();

    useEffect(() => {
        const re = /^[\s\d\w+?/-:().,']{1,32}$/;
        const found = mandateReference?.match(re);
        setValidMR(found)
    }, [mandateReference]);

    useEffect(() => {
        const isDE = creditorIdentifier?.match(/^DE\d\d[\d\w]{3}0\d{10}$/);
        const isAT = creditorIdentifier?.match(/^AT\d\d[\d\w]{3}0\d{10}$/);
        const isCH = creditorIdentifier?.match(/^CH\d\d[\d\w]{3}\d{11}$/);
        const isBE = creditorIdentifier?.match(/^BE\d\d[\d\w]{3}\d{10}$/);
        const isBE_private = creditorIdentifier?.match(/^BE\d\d[\d\w]{3}\d{3}D\d{9}$/);
        const isLU = creditorIdentifier?.match(/^LU\d\d[\d\w]{3}0\d{18}$/);
        const isNL = creditorIdentifier?.match(/^NL\d\d[\d\w]{3}\d{12}$/);
        setValidCI(isDE || isAT || isCH || isBE || isBE_private || isLU || isNL)
    }, [creditorIdentifier]);

    const saveAction = useCallback(async () => {
        setLoading(true)
        if (
            (validCI && !!creditorIdentifier && creditorIdentifier !== data.ci_display) ||
            (validMR && !!mandateReference && mandateReference !== data.mr_display)
        ) {
            await restPost(null, `sepa/${data.identity_id.$oid}/identity/${data.charter_id.$oid}/charter`, { ci: creditorIdentifier, mr: mandateReference })
        }
        setLoading(false)
        refresh()
        removeModal()
    }, [data, creditorIdentifier, mandateReference])

    return (
        <DefaultModal
            title={t("common:modal.confirm.title")}
            confirm={{
                disabled: !(validCI && validMR),
                show: true,
                text: t("common:button.confirm"),
                color: "info",
                explicitAction: saveAction
            }}
        >
            {loading ? <AeroLoading /> :
                <Box px={3} pb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Input
                                sx={{ width: "100%" }}
                                label={t("common:label.mr")}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                type="text"
                                success={validMR}
                                value={mandateReference ?? data.mr_display}
                                onChange={(e) => setMandateReference(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                sx={{ width: "100%" }}
                                label={t("common:label.ci")}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                type="text"
                                success={validCI}
                                value={creditorIdentifier ?? data.ci_display}
                                onChange={(e) => setCreditorIdentifier(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Box>}
        </DefaultModal>
    );
}

export default SepaEditModal;