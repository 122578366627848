import { useState, useEffect, useCallback } from "react"
import { Slider, FormControlLabel, Checkbox, Stack } from "@mui/material";
import Input from "components/Input";
import Box from "components/Box";
import Typography from "components/Typography";
import _ from "lodash";
import FudgeButtonGroup from "components/ButtonGroup";

export default function CheckrideViewItem(props) {
    const { text, type, goal, disabled, checked, onCheck } = props;
    const [value, setValue] = useState(props.value ?? (type === 30 ? props.default[0] : props.default));

    const inputs = useCallback(() => {
        switch (type) {
            case 1:
                return (
                    <Stack direction="row" spacing={3} >
                        <FudgeButtonGroup
                            disabled={disabled}
                            sx={{ width: { xs: "256px" } }}
                            value={value ?? 1}
                            onChange={(e) => setValue(e.target.value)} />
                    </Stack>
                )
            case 10:
                return (
                    <Stack direction="row" spacing={3} >
                        <Input
                            disabled={disabled}
                            sx={{ width: { xs: "256px" } }}
                            label="Value"
                            type="number"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                            defaultValue={defaultValue} />
                    </Stack>
                )
            case 20:
                return (
                    <Stack direction="row" spacing={3} >
                        <FormControlLabel
                            disabled={disabled}
                            sx={{ width: { xs: "256px" } }}
                            control={<Checkbox
                                onChange={(e) => setValue(e.target.checked)}
                                value={value}
                                defaultValue={defaultValue}
                            />} />
                    </Stack>
                )
            case 30:
                return (
                    <Stack direction="column" spacing={3}>
                        <Input
                            disabled={disabled}
                            sx={{ width: { xs: "128px" } }}
                            label="Value"
                            type="number"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                            defaultValue={defaultValue[0]} />
                    </Stack >
                )
            case 40:
                return (
                    <Stack direction="row" spacing={3}>
                        <Input
                            disabled={disabled}
                            sx={{ width: { xs: "256px" } }}
                            label="Value"
                            type="text"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                            defaultValue={defaultValue}
                        />
                    </Stack>
                )
            default:
                return null
        }
    }, [type, value])

    useEffect(() => {
        if (!disabled) {
            if (type === 30) { onCheck(value >= goal[0] && value <= goal[1], value) }
            else if (type !== 0) { onCheck(value === goal, value) }
            else { onCheck(value, value) }
        }
    }, [value])

    return (
        <Stack
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            spacing={3}
            p={1} pl={2} pr={2}>
            {type === 0 ?
                <Checkbox disabled={disabled} checked={value === goal ?? false} onChange={(e) => setValue(e.target.checked)} />
                :
                <Checkbox disabled={disabled} checked={value === goal ?? false} />
            }
            <Typography variant={"button"} sx={{ minWidth: { xs: "320px" }, width: "100%" }} >{text} </Typography>
            <Box width="100%"></Box>
            {inputs()}
        </Stack>
    )
}