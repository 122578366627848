import { useEffect, useRef } from "react";

// typed-js
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";

// Images
const bgImage = "https://aeromatewesteurope.blob.core.windows.net/aeromate/other/takeOffSunset.jpg";

function Header() {
    const { t } = useTranslation();
    const typedJSRef = useRef(null);

    // Setting up typedJS
    useEffect(() => {
        const typedJS = new Typed(typedJSRef.current, {
            strings: t("aboutUsPage:header.typing", { returnObjects: true }),
            typeSpeed: 70,
            backSpeed: 70,
            backDelay: 300,
            startDelay: 500,
            loop: true,
        });

        return () => typedJS.destroy();
    }, []);

    return (
        <Box component="header" position="relative">
            <Box
                display="flex"
                alignItems="center"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.5), rgba(gradients.dark.state, 0.5))}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        mx="auto"
                    >
                        <Typography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            {t("aboutUsPage:header.title")}
                        </Typography>
                        <Typography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            <span ref={typedJSRef} />
                        </Typography>
                        <Typography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
                            {t("aboutUsPage:header.subtitle")}
                        </Typography>
                        <Button color="white" href="#booking">{t("common:button.contact")}</Button>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

export default Header;