import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import { useTranslation } from "react-i18next";
import AircraftAirportTab from "./AircraftAirportTab";
import AircraftCharterTab from "./AircraftCharterTab";
import { useLocation } from "react-router-dom";
import AircraftSettingTab from "./AircraftSettingTab";
import { useState } from "react";
import AircraftReviewTab from "./AircraftReviewsTab";


function AircraftTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="AircraftTabPanel"
      hidden={value !== index}
      id={`simple-AircraftTabPanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        children
      }
    </div>
  );
}

function AircraftTabs({ aircraft }) {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const getTab = () => {
    switch (hash) {
      case "#recensions": return (0)
      case "#airport": return (1)
      case "#org": return (2)
      case "#restrictions": return (3)
      default: return (0)
    }
  }
  const [activeTab, setActiveTab] = useState(getTab());
  const handleTabType = (event, newValue) => {
    setActiveTab(newValue);
    window.location.hash = "#" + event.target.id
  }

  return (
    <Box>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={activeTab} onChange={handleTabType} >
          <Tab label={t('common:label.recensions')} icon={<Icon>reviews</Icon>} id="recensions" />
          <Tab label={t('common:label.homebase')} icon={<Icon>location_on</Icon>} id="airport" />
          <Tab label={t('common:label.charter')} icon={<Icon>business</Icon>} id="org" />
          <Tab label={t('common:label.restrictions')} icon={<Icon>auto_stories</Icon>} id="restrictions" />
        </Tabs>
      </AppBar>
      <AircraftTabPanel value={activeTab} index={0} >
        <AircraftReviewTab aircraft={aircraft} />
      </AircraftTabPanel>
      <AircraftTabPanel value={activeTab} index={1} >
        <AircraftAirportTab icao={aircraft.homebase} />
      </AircraftTabPanel>
      <AircraftTabPanel value={activeTab} index={2} >
        <AircraftCharterTab alias={aircraft.charter[0].alias} />
      </AircraftTabPanel>
      <AircraftTabPanel value={activeTab} index={3} >
        <AircraftSettingTab booking_setting={aircraft.booking_setting} />
      </AircraftTabPanel>
    </Box>
  );
}

export default AircraftTabs;