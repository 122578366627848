import { useEffect, useState } from "react";
import Box from "components/Box";
import { restGet } from "lib/rest"
import { useParams } from 'react-router-dom';
import { Container, Grid } from "@mui/material";
import AeroLoading from "components/Progress/AeroLoading"
import DashboardShopItemCard from "components/Cards/ShopItemCard/DashboardShopItemCard";
import _ from "lodash";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useTranslation } from "react-i18next";
import { baseurl } from "constants/global";
import Icon from "components/Icon";

export default function Body() {
    const { t } = useTranslation()
    const [shopItems, setShopItems] = useState();
    const [courses, setCourses] = useState();
    const { alias } = useParams();
    const [isloading, setIsLoading] = useState(false)
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                if (!!resp && !!resp.data) {
                    let groupedData = _.groupBy(resp.data, (element) => element.item_type)
                    setShopItems(groupedData.voucher)
                    setCourses(groupedData.enrollment)
                }
            }
            setIsLoading(true)
            await restGet(restCallback, 'shop/' + alias + '/item', { disabled: true })
            setIsLoading(false)
        }
        !!alias && fetch()
    }, [alias])

    if (isloading) {
        return <AeroLoading />;
    }

    return (
        <Container>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} >
                    <TypographyBlock
                        title="Shop Items"
                        description={t("dashboard:shoptab.shoptab")}
                        action={() => window.open(baseurl + `/org/${alias}#shop`, '_blank').focus()}
                        actionName={t("dashboard:charter.settings.redirectShop")}
                    />
                </Grid>

                {shopItems && shopItems.map((item, i) =>
                    <Grid item xs={12} sm={6} md={4} xl={3} key={"shop-item-card-" + i} >
                        <Box mt={3} pb={3} style={{ height: "100%" }}>
                            <DashboardShopItemCard key={"shop-item-" + i} item={item} alias={alias} />
                        </Box>
                    </Grid>)
                }
                <Grid item xs={12} sm={6} md={4} xl={3}  >
                    <Box mt={3} pb={3} style={{ height: "100%" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => window.location.href = `/my/${alias}/shop/create#voucher`}
                    >
                        <Icon color="primary" fontSize="3rem">add</Icon>
                    </Box>
                </Grid>

                <Grid item xs={12} >
                    <TypographyBlock
                        title="Course Items"
                        description={t("dashboard:shoptab.coursetab")}
                        action={() => window.open(baseurl + `/org/${alias}#courses`, '_blank').focus()}
                        actionName={t("dashboard:charter.settings.redirectCourses")}
                    />
                </Grid>

                {courses && courses.map((item, i) =>
                    <Grid item xs={12} sm={6} md={4} xl={3} key={"course-item-card-" + i} >
                        <Box mt={3} pb={3} style={{ height: "100%" }}>
                            <DashboardShopItemCard key={"course-item-" + i} item={item} alias={alias} />
                        </Box>
                    </Grid>)
                }
                <Grid item xs={12} sm={6} md={4} xl={3}  >
                    <Box mt={3} pb={3} style={{ height: "100%" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => window.location.href = `/my/${alias}/shop/create#enrollment`}>
                        <Icon color="primary" fontSize="3rem">add</Icon>
                    </Box>
                </Grid>
            </Grid>
        </Container >
    )
}