import Box from "components/Box";
import DynamicForm from "components/Forms/DynamicForm/DynamicForm";
import TypographyBlock from "components/Typography/TypographyBlock";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function OperationalTab({ setData, aircraft, tabData }) {
    const { t } = useTranslation()
    const [aircraftOperationalSetting, setAircraftOperationalSetting] = useState();

    const [newAircraftOperationalSetting, setNewAircraftOperationalSetting] = useState(tabData);

    useEffect(() => {
        restGet((res) => { res.shift(); setAircraftOperationalSetting(res) }, "dataset/metaDataAircraftOperationalSetting")
    }, []);

    useEffect(() => {
        setData(newAircraftOperationalSetting ?? {})
    }, [newAircraftOperationalSetting]);


    return (
        <Box p={2}>
            <TypographyBlock
                title={t("dashboard:charter.aircrafts.steps.settings.operational")}
            />
            <Box p={3} ml={-1}>
                <DynamicForm
                    i18nextPrefix={"operational_settings"}
                    formMetaData={aircraftOperationalSetting}
                    formDefaultValues={!_.isEmpty(tabData) ? tabData.reduce((accumulator, current) => ({ ...accumulator, [current.name]: current.value }), {}) : (!!aircraft && !!aircraft.operational_data_setting ? aircraft.operational_data_setting : {})}
                    formValuesCallback={setNewAircraftOperationalSetting}
                />
            </Box>
        </Box >
    )
}
