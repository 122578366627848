import { Grid, Card, Divider } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import { useUser } from "hooks/userDataHook";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Auth from "lib/auth";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import AeroLoading from "components/Progress/AeroLoading";
import { convertTimeDatabaseToFrontend } from "lib/functions";

function BasicInfo() {
    const userData = useUser();
    const { t } = useTranslation();

    const [user, setUser] = useState();
    useEffect(() => {
        !!userData.data && setUser(userData.data)
    }, [userData])

    function editPersonalData() {
        Auth.editProfil()
    }

    function changePassword() {
        Auth.editPassword()
    }
    if (!user) return (
        <Card>
            <Box sx={{ position: "absolute", top: "-100px" }} id="basic-info"></Box>
            <TypographyBlock
                title={t("dashboard:personal.info.title")}
                action={editPersonalData}
                actionName={t("dashboard:personal.info.button")}
            />
            <AeroLoading />
        </Card >)

    return (
        <Card>
            <Box sx={{ position: "absolute", top: "-100px" }} id="basic-info"></Box>
            <TypographyBlock
                title={t("dashboard:personal.info.title")}
                action={editPersonalData}
                actionName={t("dashboard:personal.info.button")}
            />

            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <Typography variant="body2" color="text" sx={{ textTransform: 'uppercase' }}>
                                {t("common:label.name")}
                            </Typography>
                            <Box
                                display="flex"
                                alignItems={{ xs: "flex-start", sm: "center" }}
                                flexDirection={{ xs: "column", sm: "row" }}
                            >
                                <Box mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                                    <Typography variant="button" color="text" fontWeight="regular">
                                        {user.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <Typography variant="body2" color="text" sx={{ textTransform: 'uppercase' }}>
                                {t("common:label.email")}
                            </Typography>
                            <Box
                                display="flex"
                                alignItems={{ xs: "flex-start", sm: "center" }}
                                flexDirection={{ xs: "column", sm: "row" }}
                            >
                                <Box mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                                    <Typography variant="button" color="text" fontWeight="regular">
                                        {user.email}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <Typography variant="body2" color="text" sx={{ textTransform: 'uppercase' }}>
                                {t("common:label.joined")}
                            </Typography>
                            <Box
                                display="flex"
                                alignItems={{ xs: "flex-start", sm: "center" }}
                                flexDirection={{ xs: "column", sm: "row" }}
                            >
                                <Box mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                                    <Typography variant="button" color="text" fontWeight="regular">
                                        {moment(convertTimeDatabaseToFrontend(user.created)).format("LL")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <Typography variant="body2" color="text" sx={{ textTransform: 'uppercase' }}>
                                {t("common:label.password")}
                            </Typography>
                            <Box
                                display="flex"
                                alignItems={{ xs: "flex-start", sm: "center" }}
                                flexDirection={{ xs: "column", sm: "row" }}
                            >
                                <Box mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        onClick={changePassword}
                                    >
                                        {t("common:button.changePassword")}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

export default BasicInfo;