import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CreateCharter from "./sections/CreateCharter";
import JoinRequests from "./sections/JoinRequests";

export default function Body() {
    const { t } = useTranslation();
    const { hash } = useLocation();
    const getTab = () => {
        switch (hash) {
            case "#joinrequests": return (0)
            case "#create": return (1)
            default: return (0)
        }
    }

    const [tabValue, setTabValue] = useState(getTab());
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = "#" + event.target.id
    }

    const [content, setContent] = useState(null)
    useEffect(() => {
        switch (tabValue) {
            case 0:
                setContent(<JoinRequests />)
                break;
            case 1:
                setContent(<CreateCharter />)
                break;
            default:
                break;
        }
    }, [tabValue])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleSetTabValue}>
                        <Tab label={t("dashboard:organization.join_requests.tab_title")} id="joinrequests" />
                        <Tab label={t("dashboard:organization.create.title")} id="create" />
                    </Tabs>
                </AppBar>
            </Grid>
            <Grid item xs={12} >
                {content}
            </Grid>
        </Grid>
    )
}