import { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import Input from "components/Input";

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function EmailInput({ label, onChange, isValid, value, ...rest }) {
    const { t } = useTranslation();
    const [newEmail, setNewEmail] = useState(value);
    const [emailIsValid, setEmailIsValid] = useState();

    useEffect(() => {
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newEmail))) {
            onChange(newEmail)
            isValid(true)
            setEmailIsValid(true)
        } else {
            isValid(false)
            setEmailIsValid(false)
        }
    }, [newEmail]);

    return (
        <Input
            {...rest}
            error={!emailIsValid}
            label={label}
            placeholder={t('common:label.email_input_placeholder')}
            fullWidth
            type="mail"
            value={newEmail}
            onChange={(event) => setNewEmail(event.target.value)}
        />
    );
}

// Setting default values for the props of Input
EmailInput.defaultProps = {
    label: "",
    onChange: () => null,
    isValid: () => null,
    value: "",
};

// Typechecking props for the Input
EmailInput.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    isValid: PropTypes.func,
    value: PropTypes.string,
};

export default EmailInput;