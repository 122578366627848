import React, { useState, useContext, createContext } from "react";

const DialogContext = createContext(null);

export const DialogProvider = ({ dialog, children }) => {
  const [currentDialog, setCurrentDialog] = useState(dialog)
  return (
    <DialogContext.Provider value={{ currentDialog, setCurrentDialog }}>
      {children}
    </DialogContext.Provider>
  )
}

export const useDialog = () => useContext(DialogContext);