import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import ReactMarkdown from "react-markdown";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import _ from "lodash"
import Input from "components/Input";
import { useModal } from "hooks/modalHook";
import LoadingModal from "./LoadingModal";
import { restPost } from "lib/rest";
import VoucherConfirmationModal from "./VoucherConfirmationModal";
import moment from "moment";
import { convertTimeDatabaseToFrontend } from "lib/functions";

function VoucherRedeemModal({ slot, charter, code }) {
    if (!charter)
        return null
    const { t } = useTranslation();
    const { addModal } = useModal();

    const [clientTcLink, setClientTcLink] = useState("");
    useEffect(() => {
        if (!!charter && !!charter.document && !!charter.document.gtc) {
            if (charter.document.gtc.type === "url") setClientTcLink(charter.document.gtc.source)
        }
    }, [charter]);

    const [firstName, setFirstName] = useState("");
    const [firstNameIsValid, setFirstNameIsValid] = useState();
    useEffect(() => {
        setFirstNameIsValid(firstName.length > 1)
    }, [firstName]);

    const [lastName, setLastName] = useState("");
    const [lastNameIsValid, setLastNameIsValid] = useState();
    useEffect(() => {
        setLastNameIsValid(lastName.length > 1)
    }, [lastName]);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState();
    useEffect(() => {
        setPhoneNumberIsValid(!!phoneNumber)
    }, [phoneNumber]);

    const [mailAddress, setMailAddress] = useState("");
    const [mailAddressIsValid, setMailAddressIsValid] = useState();
    useEffect(() => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setMailAddressIsValid(re.test(String(mailAddress).toLowerCase()))
    }, [mailAddress]);

    const [comment, setComment] = useState("");

    const [aeromateChecked, setAeromateChecked] = useState(false);
    const [partnerChecked, setPartnerChecked] = useState(false);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setIsValid(firstNameIsValid && lastNameIsValid && phoneNumberIsValid && mailAddressIsValid && aeromateChecked && partnerChecked)
    }, [firstNameIsValid, lastNameIsValid, phoneNumberIsValid, mailAddressIsValid, aeromateChecked, partnerChecked]);

    const confirmAction = () => {
        addModal(<LoadingModal />)
        const asyncCall = async () => {
            let body =
            {
                firstName: firstName,
                lastName: lastName,
                phone: phoneNumber,
                email: mailAddress,
                comment: comment
            }
            await restPost(null, `voucher/${code}/slot/${slot._id.$oid}`, body)
            addModal(<VoucherConfirmationModal firstName={firstName} email={mailAddress} alias={charter.legal_info.chartername} dateTime={moment(convertTimeDatabaseToFrontend(slot.handover))} />)
        }
        asyncCall()
    }

    return (
        <DefaultModal
            title={t("voucherPage:modalTitle")}
            closeExplicit={true}
            cancel={{ show: true, text: t("common:button.cancel"), color: "error" }}
            confirm={{
                disabled: !isValid,
                show: true,
                text: t("common:button.confirm"),
                color: "info",
                explicitAction: confirmAction
            }}
        >
            <Grid container spacing={2}>
                <Grid xs={12} sm={6} item>
                    <Input fullWidth label={t("common:label.firstname")}
                        success={!!firstNameIsValid}
                        error={!firstNameIsValid}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <Input fullWidth label={t("common:label.lastname")}
                        success={!!lastNameIsValid}
                        error={!lastNameIsValid}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)} />
                </Grid>
                <Grid xs={12} item>
                    <Input fullWidth label={t("common:label.email")}
                        success={!!mailAddressIsValid}
                        error={!mailAddressIsValid}
                        value={mailAddress}
                        onChange={(e) => setMailAddress(e.target.value)} />
                </Grid>
                <Grid xs={12} item>
                    <Input fullWidth label={t("common:label.phonenumber")}
                        success={!!phoneNumberIsValid}
                        error={!phoneNumberIsValid}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)} />
                </Grid>
                <Grid xs={12} item>
                    <Input
                        fullWidth
                        label={t("common:label.commentVoucher")}
                        multiline rows={3}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)} />
                </Grid>
                <Grid xs={12} item>
                    <FormControlLabel
                        control={<Checkbox checked={aeromateChecked} onChange={(e) => setAeromateChecked(e.target.checked)} name='privacy' />}
                        label={<ReactMarkdown >{t('voucherPage:optinPrivacy')}</ReactMarkdown>}
                    />
                </Grid>
                <Grid xs={12} item>
                    <FormControlLabel
                        control={<Checkbox checked={partnerChecked} onChange={(e) => setPartnerChecked(e.target.checked)} name='privacy' />}
                        label={<ReactMarkdown >{t("voucherPage:optinGTC", { alias: (!!charter.legal_info && !!charter.legal_info.chartername ? charter.legal_info.chartername : charter.alias), url: clientTcLink })}</ReactMarkdown>}
                    />
                </Grid>
            </Grid>
        </DefaultModal>
    );
}

export default VoucherRedeemModal;
