import { useEffect } from "react";

// react-router components
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

// Material Kit 2 PRO React routes
import routes from "constants/routes";
import { I18nextProvider } from "react-i18next";
import i18nConfig from "locals/config";

import { DialogProvider } from "hooks/dialogHook";
import { UserProvider } from "hooks/userDataHook";
import { IsMobileProvider } from "hooks/isMobileHook";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import { ModalProvider } from "hooks/modalHook";
import DashboardApp from "aeromate-dashboard/DashboardApp";
import { RefreshProvider } from "hooks/refreshHook";
import ComingSoon from "components/Common/ComingSoon";
import KitLayout from "aeromate-kit/KitLayout";
import ErrorPage from "aeromate-kit/ErrorPage";

export default function App() {
  const { pathname, hash, key } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.route} />;
      }

      return null;
    });

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <ThemeProvider theme={theme}>
      <MaterialUIControllerProvider>
        <I18nextProvider i18n={i18nConfig}>
          <RefreshProvider>
            <UserProvider>
              <DialogProvider>
                <ModalProvider>
                  <IsMobileProvider>
                    <CssBaseline />
                    {pathname.startsWith("/my") ?
                      <DashboardApp />
                      :
                      <Routes basename="/">
                        {getRoutes(routes)}
                        <Route path="/404" element={<ErrorPage />} />
                        <Route path="/*" element={<Navigate to="/404" />} />
                      </Routes>
                    }
                  </IsMobileProvider>
                </ModalProvider>
              </DialogProvider>
            </UserProvider>
          </RefreshProvider>
        </I18nextProvider>
      </MaterialUIControllerProvider>
    </ThemeProvider >
  );
}