import { Box, Typography } from "@mui/material";
import DefaultModal from "components/Modal/DefaultModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AutoComplete from "components/Input/AutoComplete";
import PostCancelationModal from "./PostCancelationModal";
import Input from "components/Input";

function CancelBookingModal({ actionName, data }) {
    const [reason, setReason] = useState("");
    const [details, setDetails] = useState("");
    const { t } = useTranslation();

    const handleChange = (event) => {
        setReason(event.reason);
    }

    const reasonData = [
        { reason: "Weather", label: t("common:label.weather") },
        { reason: "Aircraft INOP", label: t("common:label.inop") },
        { reason: "Medical", label: t("common:label.medical") },
        { reason: "Other (please specify)", label: t("common:label.other") }
    ]

    return (
        <DefaultModal
            title={t("common:modal.cancelbooking.title")}
            confirm={{
                show: true,
                text: t("common:button.confirm"),
                color: "info",
                modal: <PostCancelationModal reg={data._id.$oid} />
            }}
        >
            {t("common:modal.cancelation.description", { action: actionName })}
            <Box>
                <Typography variant="caption" >
                    {t("common:modal.cancelbooking.reason")}
                </Typography>
                <AutoComplete
                    disablePortal
                    label={t("common:modal.cancelbooking.reason_2")}
                    options={reasonData}
                    onChange={e => handleChange(e)}
                />
                <Typography variant="caption">
                    {t("common:modal.cancelbooking.please")}
                </Typography>
                <Input
                    sx={{ width: "100%" }}
                    label={t("common:modal.cancelbooking.details")}
                    type="text"
                    multiline
                    rows={4}
                    onChange={(e) => setDetails(e.target.value)}
                />
            </Box>

        </DefaultModal>
    );
}

export default CancelBookingModal;