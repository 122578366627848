import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { Grid, Stack, FormGroup, FormControlLabel, Checkbox, AppBar, Tab, Tabs } from "@mui/material";
import ReactMarkdown from "react-markdown";
import Box from "components/Box";
import Input from "components/Input";
import { restGet } from "lib/rest";
import _ from "lodash";
import { restPost } from 'lib/rest';
import { useRefresher } from 'hooks/refreshHook';
import EmailInput from 'components/Input/EmailInput';
import { Description } from '@mui/icons-material';
import Typography from 'components/Typography';


const AlertModal = ({ title, description, onValueChange , id }) => {
    const { t } = useTranslation();


    const { refresh } = useRefresher();


    const response = () => {
        onValueChange(id);

    }

    return (
        <DefaultModal
            title={title}
            confirm={{
                text: t("common:button.confirm"),
                color: "success",
                show: true,
                action: () => { response(); }

            }}>
            <Box sx={{
                "position": "relative",
                "width": "auto",
                "height": "100px",
                "display":"flex",
                "justifyContent":"center",
                "alignItems":"center"

            }}>
            
            <Typography sx={{fontWeight:"strong"}} fontSize="22px">{description}</Typography> 


            </Box>




        </DefaultModal>
    )
}

export default AlertModal