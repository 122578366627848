import DefaultModal from "components/Modal/DefaultModal";
import { useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import AeroLoading from "components/Progress/AeroLoading";
import { restGet } from "lib/rest";
import moment from "moment";
import { base64ToArrayBuffer } from "lib/functions";
import { useModal } from "hooks/modalHook";
import { getBlobObjectUrl } from "lib/functions";

function DownloadModal({ data, type, name }) {
    const [responseMessage, setResponseMessage] = useState();
    const { removeModal } = useModal()

    const restCall = useCallback(async (callback) => {
        if (!!data) {
            switch (type) {
                case "invoice":
                    return await restGet(callback, 'billing/' + data._id.$oid + '/document', {}, { Accept: "application/pdf" })
                case "voucher":
                    return await restGet(callback, 'voucher/' + data.code + '/certificate', { voucher_name: name, lang: moment.locale() }, { Accept: 'application/pdf' })
                case "checkride":
                    return await restGet(callback, 'checkride/' + data._id.$oid + '/document', {}, { Accept: "application/pdf" })
                default:
                    return undefined
            }
        }
    }, [data])

    async function tryGettingDocument(count) {
        if (count >= 3) {
            setResponseMessage(t("common:modal.download.trylater"))
            return;;
        }
        var result = await restCall(null);
        if (typeof result == 'object') {
            if (result[0]) result = result[0]
            if (result.data) result = result.data
            if (result) {
                if (result?.document?.status === "processing") {
                    return setTimeout(() => tryGettingDocument(count + 1), 5000);
                } else {
                    if (result?.region) {
                        window.open(getBlobObjectUrl(result))
                    } else if (result?.document) {
                        window.open(getBlobObjectUrl(result.document))
                    }
                    removeModal()
                }
            }
        }
        else {
            const newBlob = new Blob([base64ToArrayBuffer(result)], { type: "application/pdf" });
            const url = window.URL.createObjectURL(newBlob);
            window.open(url)
            removeModal()
        }
    }
    useEffect(() => {
        tryGettingDocument(0)
    }, []);

    return (
        <DefaultModal
            title={t("common:modal.download.title")}
            confirm={{ show: false }}
        >
            {!!responseMessage ? responseMessage : t("common:modal.download.description")}
            {!responseMessage && <AeroLoading />}
        </DefaultModal>
    );
}

export default DownloadModal;