import DashboardLayout from "aeromate-dashboard/DashboardLayout";
import Restricted from "components/Common/Restricted";
import Body from "./Body";

export default function Settings() {
    return (
        <DashboardLayout>
            <Restricted role="staff" nopermission>
                <Body />
            </Restricted>
        </DashboardLayout>
    )
}
