import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { Grid, Stack, FormGroup, FormControlLabel, Checkbox, AppBar, Tab, Tabs, Tooltip } from "@mui/material";
import ReactMarkdown from "react-markdown";
import TypographyBlock from "components/Typography/TypographyBlock";
import DatePicker from "components/DatePicker";
import Box from "components/Box";
import Input from "components/Input";
import { restGet } from "lib/rest";
import ClearIcon from '@mui/icons-material/ErrorOutline';
import Alert from "components/Alert";
import CharterTab from "./components/CharterTab";
import CompanyTab from "./components/CompanyTab";
import TrainingTab from "./components/TrainingTab";
import FerryTab from "./components/FerryTab";
import VoucherTab from "./components/VoucherTab";
import PostReservationModal from "./PostReservationModal";
import { useUser } from "hooks/userDataHook";
import _, { forEach } from "lodash";
import { convertFrontendToTimeDatabase } from "lib/functions";
import Typography from "components/Typography";
import NoPermission from "components/Common/NoPermission";
import CustomerSearch from "components/Input/CustomerSearch";

function CalendarModal({ reg, alias, aircraftCharterAlias, eventStart, eventEnd }) {
    const { t } = useTranslation();
    const { getRole, data } = useUser();

    const [acceptPP, setAcceptPP] = useState(false);
    const [acceptGTC, setAcceptGTC] = useState(false);
    const [comment, setComment] = useState("");
    const [start, setStart] = useState(eventStart);
    const [end, setEnd] = useState(eventEnd);
    const [tabValue, setTabValue] = useState(0);
    const [tabValue2, setTabValue2] = useState(10);
    const [content, setContent] = useState(null)
    const [errors, setErrors] = useState([])
    const [eventSettings, setEventSettings] = useState();
    const [tab1, setTab1] = useState("none");
    const [tab2, setTab2] = useState("none");
    const [tab3, setTab3] = useState("none");
    const [tab4, setTab4] = useState("none");
    const [tab5, setTab5] = useState("none");
    const [rates, setRates] = useState();
    const [regAlias, setRegAlias] = useState();

    const [staffIdentity, setStaffIdentity] = useState()
    const [isStudent, setIsStudent] = useState(false)

    useEffect(() => {
        if (!!data && (!!alias || !!regAlias)) {
            restGet((e) => setIsStudent(e.length > 0), `charter/${alias ?? regAlias}/student`)
        }
    }, [data, alias, regAlias]);

    useEffect(() => {
        if (staffIdentity) {
            let eventSettings = {
                flight_type: "training",
                identity_id: { value: '_self' }
            }
            eventSettings.staff_identity_id = staffIdentity
            setEventSettings(eventSettings)
        }
    }, [staffIdentity]);

    useEffect(() => {
        if (!!start && !!end) {
            let body = {
                handover: convertFrontendToTimeDatabase(start),
                return: convertFrontendToTimeDatabase(end)
            };
            restGet((e) => setErrors((!!e && !!e.data && !!e.data.errors) ? e.data.errors : []), `aircraft/${reg}/availability`, body)
        }
    }, [start, end])

    useEffect(() => {
        function restCallback(resp) {
            if (!!resp && !!resp.data) {
                console.log("resp.data", resp.data)
                Tabssetter(resp.data[0].rates);
                if (!alias) setRegAlias(resp.data[0].charter[0].alias);
            }
        }
        restGet(restCallback, 'aircraft/' + reg)
    }, [])

    useEffect(() => {
        if (tabValue2 === 200) {
            let eventSettings = {
                flight_type: "training",
                identity_id: { value: '_self' }
            }
            setEventSettings(eventSettings)
        } else {
            let eventSettin = { flight_type: "charter" };
            eventSettin.charter_type = tabValue2;
            eventSettin.identity_id = setEventSettings(eventSettin)
        }
    }, [tabValue2]);

    function Tabssetter(data) {
        if (data) {
            let charterAssign = true;
            for (let index = 0; index < data.length; index++) {
                if (data[index].rate >= 0) {
                    if (data[index].flight_type.startsWith("charter") && charterAssign) {
                        data[index].flight_type = "charter";
                        charterAssign = false;
                        setTab1("block")
                    } else if (data[index].flight_type == "company") {
                        setTab2("block")
                    }
                    else if (data[index].flight_type == "ferry") {
                        setTab3("block")
                    }
                    else if (data[index].flight_type == "training") {
                        setTab4("block")
                    }
                    else if (data[index].flight_type == "voucher") {
                        setTab5("block")
                    }
                }
            }
            setRates(data)
        }
    }



    useEffect(() => {
        switch (tabValue) {
            case 0:
                setContent(<CharterTab reg={reg} setEventSettings={setEventSettings} />)
                break;
            case 1:
                setContent(<CompanyTab reg={reg} setEventSettings={setEventSettings} />)
                break;
            case 2:
                setContent(<FerryTab reg={reg} setEventSettings={setEventSettings} />)
                break;
            case 3:
                setContent(<TrainingTab reg={reg} setEventSettings={setEventSettings} />)
                break;
            case 4:
                setContent(<VoucherTab reg={reg} setEventSettings={setEventSettings} />)
                break;
            default:
                setContent(null)
                break;
        }
    }, [tabValue])

    function inputIsValidStaff() {
        if (isOwnerAdminStaff()) {
            if (!eventSettings) return false
            switch (eventSettings.flight_type) {
                case "charter":
                    switch (eventSettings.charter_type) {
                        case 10:
                            return !!eventSettings.identity_id
                        case 20:
                            return !!eventSettings.identity_id && !!eventSettings.staff_identity_id
                        case 30:
                            return !!eventSettings.identity_id
                    }
                case "company":
                    return !!eventSettings.identity_id
                case "ferry":
                    return !!eventSettings.identity_id
                case "training":
                    return !!eventSettings.identity_id && !!eventSettings.staff_identity_id
                case "voucher":
                    return !!eventSettings.identity_id && !!eventSettings.slot
                default:
                    return false
            }
        } else {
            switch (eventSettings.flight_type) {
                case "training":
                    return !!eventSettings.staff_identity_id
                default:
                    return true
            }

        }
    }


    function isOwnerAdminStaff() {
        if (alias)
            return (_.includes(["owner", "admin", "staff"], getRole(alias)))
        else {
            return (_.includes(["owner", "admin", "staff"], getRole(aircraftCharterAlias)))
        }
    }

    if (!rates) {
        return (
            <DefaultModal title={(reg ?? "") + " " + (alias ?? "") + " " + (!alias && aircraftCharterAlias)}>
                <NoPermission title={t("common:label.noRatesTitle")} subtitle={t("common:label.noRateSubtitle")} />
            </DefaultModal>
        )
    }

    return (
        <DefaultModal
            title={(reg ?? "") + " " + (alias ?? "") + " " + (!alias && aircraftCharterAlias)}
            confirm={{
                text: t("common:button.confirm"),
                color: "success",
                show: true,
                disabled: !(acceptPP && errors.length === 0 && inputIsValidStaff()),
                modal: <PostReservationModal alias={!alias && aircraftCharterAlias} reg={reg} eventStart={start} eventEnd={end} eventSettings={eventSettings} comment={comment} />
            }}
        >
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={3}>
                        <Grid mt={1} container spacing={2}>
                            {!isOwnerAdminStaff() && <Grid item xs={12}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("common:label.flight_type")}
                                    />
                                    <Box mt={-1}>
                                        <AppBar position="static">
                                            <Tabs value={tabValue2} onChange={(event, newValue) => setTabValue2(newValue)}>
                                                <Tab value={10} label={
                                                    <Tooltip title={t("common:label.rent_tooltip")}>
                                                        <span>{t("common:label.rent")}</span>
                                                    </Tooltip>} />
                                                <Tab value={40} label={
                                                    <Tooltip title={t("common:label.hour_building_tooltip")}>
                                                        <span>{t("common:label.hour_building")}</span>
                                                    </Tooltip>} />
                                                <Tab value={200} sx={{ display: !!isStudent ? "block" : "none" }} label={
                                                    <Tooltip title={t("common:label.training")}>
                                                        <span>{t("common:label.training")}</span>
                                                    </Tooltip>} />
                                            </Tabs>
                                        </AppBar>
                                    </Box>

                                </Stack>
                            </Grid>}
                            <Grid item xs={12}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("common:label.handover") + " " + t("common:label.date_time")}
                                    />
                                    <Box mt={-1} width="100%">
                                        <DatePicker
                                            value={start}
                                            onChange={(event) => setStart(event[0].getTime())}
                                            input={{ fullWidth: true }}
                                            options={{
                                                time_24hr: true,
                                                enableTime: true,
                                                minuteIncrement: 15
                                            }} />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("common:label.return") + " " + t("common:label.date_time")}
                                    />
                                    <Box mt={-1} width="100%">
                                        <DatePicker
                                            value={end}
                                            onChange={(event) => setEnd(event[0].getTime())}
                                            input={{ fullWidth: true }}
                                            options={{
                                                time_24hr: true,
                                                enableTime: true,
                                                minuteIncrement: 15
                                            }} />
                                    </Box>
                                </Stack>
                            </Grid>
                            {tabValue2 === 200 && <Grid item xs={12}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("common:label.flíght_instructor")}
                                    />
                                    <Box mt={-1} width="100%">
                                        <CustomerSearch
                                            fullWidth
                                            reg={reg}
                                            onChange={setStaffIdentity}
                                            staffOnly
                                        />
                                    </Box>
                                </Stack>
                            </Grid>}
                            <Grid item xs={12}>
                                <Stack>
                                    <TypographyBlock
                                        dense={true}
                                        nopadding
                                        title={t("common:label.comment")}
                                    />
                                    <Box mt={-1} width="100%">
                                        <Input
                                            type="text"
                                            multiline
                                            rows={4}
                                            value={comment}
                                            onChange={(event) => setComment(event.target.value)}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack>{!!errors &&
                                    errors.map((error, i) =>
                                        <Alert color="error" key={"Booking-Alert-" + i}>
                                            <ClearIcon fontSize="small" />&nbsp;
                                            {t("error:" + error.code)}
                                        </Alert>)}
                                </Stack>
                            </Grid>
                        </Grid>
                        {isOwnerAdminStaff() &&
                            <Grid mt={1} container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack>
                                        <TypographyBlock
                                            dense={true}
                                            nopadding
                                            title={t("common:label.flight_type")}
                                        />
                                        <Box mt={-1}>
                                            <AppBar position="static">
                                                <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
                                                    <Tab sx={{ display: tab1 }} label={t("common:label.charter")} />
                                                    <Tab sx={{ display: tab2 }} label={t("common:label.company")} />
                                                    <Tab sx={{ display: tab3 }} label={t("common:label.ferry")} />
                                                    <Tab sx={{ display: tab4 }} label={t("common:label.training")} />
                                                    <Tab sx={{ display: tab5 }} label={t("common:label.voucher")} />

                                                </Tabs>
                                            </AppBar>
                                        </Box>
                                        {content}
                                    </Stack>
                                </Grid>
                            </Grid>
                        }
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={acceptPP} onChange={(e) => setAcceptPP(e.target.checked)} />}
                            label={<ReactMarkdown >{t('common:optin.optinPrivacy') + " " + t('common:optin.optinGTC')}</ReactMarkdown>}
                        />
                    </FormGroup>
                </Grid>
            </Grid >
        </DefaultModal >
    );
}

export default CalendarModal;