import i18n from "locals/config";
import Badge from "components/Badge";
import CancelBookingModal from "components/Modal/CancelBookingModal/CancelBookingModal";
import { bookingWindowBuilder } from "lib/functions";

export const bookingColumns = [
    {
        Header: i18n.t("common:label.reg"),
        accessor: d => `${d.reg} ${(d.aircraft && !!d.aircraft[0] && d.aircraft[0].type) ? "(" + d.aircraft[0].type + ")" : ""}`,
    }, {
        Header: i18n.t("common:label.booking"),
        accessor: d => bookingWindowBuilder(d.handover, d.return)
    }, {
        Header: i18n.t("common:label.duration"),
        accessor: d => Math.ceil((d.return - d.handover) / 60) + " min"
    }, {
        Header: i18n.t("common:label.slot"),
        accessor: "slot"
    }, {
        Header: i18n.t("common:label.status"),
        accessor: "status",
        Cell: props => <Badge color={statusColor(props.value)} badgeContent={props.value} />
    }, {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false
    }
]

export const bookingActions = (addModal, data) => {
    return [
        {
            displayStatus: ["pending", "waiting", "confirmed"],
            title: i18n.t("common:label.cancel"),
            action: (idx) => {
                addModal(<CancelBookingModal
                    actionName={i18n.t("common:button.cancel")}
                    data={data[idx]}
                />)
            }
        }, {
            displayStatus: ["active"],
            title: i18n.t("common:label.logtimes"),
            action: (idx) => window.location.href = "/my/logtimes/" + data[idx]._id.$oid
        }
    ]
};

function statusColor(status) {
    switch (status) {
        case "pending":
            return "error";
        case "canceled":
            return "light";
        case "active":
            return "info";
        default:
            return "success";
    }
}