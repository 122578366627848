import Container from "@mui/material/Container";
import Box from "components/Box";
import bgImage from "assets/images/aircraft-construction.jpg"
import Typography from "components/Typography";

// Images
//const bgImage = "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_194450239sm.jpeg"

function Header(props) {
    console.log(props)
    return (<Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="512px"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${props.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
        <Container>
            <Typography fontWeight="bold" color="light" fontSize="56px">{props.title}</Typography>
            <Typography color="light" fontWeight="medium" fontSize="18px">{props.description}</Typography>
        </Container>
    </Box>
    );
}

export default Header;