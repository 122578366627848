import { centToEuro } from "lib/functions";
import { getBlobImageUrl } from "lib/functions";
import { restGet } from "lib/rest";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function ShopItemHelmet({ item, charter }) {
    const { t } = useTranslation();

    let default_key_words = [
        "Gutschein",
        "Rundflug",
        "Gutschein kaufen",
        "Gutschein Rundflug",
        "Rundflug Gutschein",
        "Rundflug kaufen",
        "Rundflug Gutschein kaufen",

        "Voucher",
        "Sightseen",
        "Sightseen Flight",
        "Buy voucher",
        "voucher sightseen",
        "sightseen voucher",
        "buy sightseen",
        "buy sightseen voucher",

        item?.caption?.en,
        item?.caption?.de,
        charter?.legal_info?.chartername,
        charter?.legal_info?.charterhomebase,
        charter?.legal_info?.charterzip,
        charter?.alias,
        "aeromate " + charter?.alias,

        "aeromate " + charter?.legal_info?.charterhomebase,

        "Gutschein " + charter?.legal_info?.charterhomebase,
        "Rundflug " + charter?.legal_info?.charterhomebase,
        "Gutschein kaufen " + charter?.legal_info?.charterhomebase,
        "Gutschein Rundflug " + charter?.legal_info?.charterhomebase,
        "Rundflug Gutschein " + charter?.legal_info?.charterhomebase,
        "Rundflug kaufen " + charter?.legal_info?.charterhomebase,
        "Rundflug Gutschein kaufen " + charter?.legal_info?.charterhomebase,

        "Voucher " + charter?.legal_info?.charterhomebase,
        "Sightseen " + charter?.legal_info?.charterhomebase,
        "Sightseen Flight " + charter?.legal_info?.charterhomebase,
        "Buy voucher " + charter?.legal_info?.charterhomebase,
        "voucher sightseen " + charter?.legal_info?.charterhomebase,
        "sightseen voucher " + charter?.legal_info?.charterhomebase,
        "buy sightseen " + charter?.legal_info?.charterhomebase,
        "buy sightseen voucher " + charter?.legal_info?.charterhomebase,
        charter?.legal_info?.charterhomebase + " Gutschein",
        charter?.legal_info?.charterhomebase + " Rundflug",
        charter?.legal_info?.charterhomebase + " Gutschein kaufen",
        charter?.legal_info?.charterhomebase + " Gutschein Rundflug",
        charter?.legal_info?.charterhomebase + " Rundflug Gutschein",
        charter?.legal_info?.charterhomebase + " Rundflug kaufen",
        charter?.legal_info?.charterhomebase + " Rundflug Gutschein kaufen",

        charter?.legal_info?.charterhomebase + " Voucher",
        charter?.legal_info?.charterhomebase + " Sightseen",
        charter?.legal_info?.charterhomebase + " Sightseen Flight",
        charter?.legal_info?.charterhomebase + " Buy voucher",
        charter?.legal_info?.charterhomebase + " voucher sightseen",
        charter?.legal_info?.charterhomebase + " sightseen voucher",
        charter?.legal_info?.charterhomebase + " buy sightseen ",
        charter?.legal_info?.charterhomebase + " buy sightseen voucher",
    ]

    useEffect(async () => {
        if (charter?.legal_info?.charterhomebase) {
            var homebase = await restGet(null, `airport/${charter.legal_info.charterhomebase}`)

            var homebasekeywords = [
                "aeromate",
                "Gutschein",
                "Rundflug",
                "Gutschein kaufen",
                "Gutschein Rundflug",
                "Rundflug Gutschein",
                "Rundflug kaufen",
                "Rundflug Gutschein kaufen",
                "Voucher",
                "Sightseen",
                "Sightseen Flight",
                "Buy voucher",
                "voucher sightseen",
                "sightseen voucher",
                "buy sightseen ",
                "buy sightseen voucher"
            ]

            var arr1 = homebasekeywords.map((element) => element + " " + homebase.data[0].iata)
            var arr2 = homebasekeywords.map((element) => element + " " + homebase.data[0].name)
            var arr3 = homebasekeywords.map((element) => element + " " + homebase.data[0].municipality)
            var arr4 = homebasekeywords.map((element) => homebase.data[0].iata + " " + element)
            var arr5 = homebasekeywords.map((element) => homebase.data[0].name + " " + element)
            var arr6 = homebasekeywords.map((element) => homebase.data[0].municipality + " " + element)

            default_key_words = default_key_words.concat(arr1, arr2, arr3, arr4, arr5, arr6)
        }

    }, [charter.legal_info.charterhomebase]);

    return (
        <Helmet>
            <title>{t("routes:aircraft.title") + " " + item?.caption?.en} </title>
            <meta name="keywords" content={default_key_words.toString()}></meta>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": item?.caption?.en,
                    "image": _.values(item?.image).map((v) => getBlobImageUrl(v)),
                    "description": item?.description?.en ?? "",
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": item?.ratings_stat?.avg_rating ?? 0,
                        "ratingCount": item?.ratings_stat?.rating_cnt ?? 0
                    },
                    "brand": {
                        "@type": "Brand",
                        "name": charter?.legal_info.chartername
                    },
                    "offers": item?.variant?.map((option) => {
                        return {
                            "@type": "Offer",
                            "url": `https://${window.location.hostname}/org/${charter?.alias}/${item?._id?.$oid}`,
                            "priceCurrency": "EUR",
                            "price": option?.price
                        }
                    })
                })}
            </script>
        </Helmet>
    )
}