import { HelpOutlined } from "@mui/icons-material";
import { Card, CircularProgress, Grid, Select, Skeleton, TextField, Tooltip } from "@mui/material";
import Badge from "components/Badge";
import Box from "components/Box";
import Restricted from "components/Common/Restricted";
import Button from "components/Button";
import EmailInput from "components/Input/EmailInput";
import TypographyBlock from "components/Typography/TypographyBlock";
import { baseurl } from "constants/global";
import { getBlobFromPdfString } from "lib/functions";
import { getBlobImageUrl } from "lib/functions";
import { toBase64 } from "lib/functions";
import { restGet, restPost } from "lib/rest";
import _ from "lodash";
import { delay } from "lib/functions";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useParams } from "react-router-dom";
import TaxCodeInput from "components/Input/TaxCodeInput";
import SimpleSidenav from "components/Sidenav/SimpleSidenav";
import AeroLoading from "components/Progress/AeroLoading";
import { useRefresher } from "hooks/refreshHook";

export default function Legal() {
    const { t } = useTranslation();
    const [charter, setCharter] = useState();
    const [tax, setTax] = useState();
    const [taxRate, setTaxRate] = useState();
    const inputFile = useRef(null)
    const { alias } = useParams();
    const [gtc, setGtc] = useState()
    const [stripeAccount, setStripeAccount] = useState();
    const [stripeAccountSetup, setStripeAccountSetup] = useState();
    const [buttonDisable, setButtonDisable] = useState(false);
    const [accountRestricted, setAccountRestricted] = useState(true);
    const [type, settype] = useState();
    const [viewPdf, setViewPdf] = useState();
    const [viewMardown, setViewMardown] = useState();
    const [textField, setTextField] = useState([]);
    const [email, setEmail] = useState();
    const [emailvalue, setEmailvalue] = useState();
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(true);
    const [version, setVersion] = useState(true);
    const { refresher } = useRefresher();

    const Handlechange = (value) => {
        setTaxRate(value)
    };
    function taxButtonClick() {
        restPost(null, 'tax/' + (alias ?? ''), taxRate.map((e) => e.id))
    }
    const handleTextField = (event) => {
        (event.target.value);
        const text = event.target.value;
        const linesArray = text.split('\n');
        setTextField(linesArray);
    };

    const saveLegalAddress = async () => {

        await restPost(
            null,
            `charter/${charter._id.$oid}/legaladdress`,
            textField
        );

    };

    function buttonClick() {
        setButtonDisable(true)
        restPost(setStripeAccountSetup, 'account/' + (charter.account_id ?? ''), {
            alias: alias,
            legal_entity: charter.legal_entity,
            url: baseurl + '/my/' + alias + '/settings#legal'
        })
    }

    const [emailIsSaving, setEmailIsSaving] = useState(false);
    async function saveChanges() {
        setEmailIsSaving(true)
        let payLoad = {
            alias: alias,
            legal_entity: charter.legal_entity,
            url: baseurl + '/my/' + alias + '/settings#legal'
        }

        if (!!email) payLoad.email = email

        await restPost(null, 'account/' + (charter.account_id ?? ''), payLoad)
        await restPost(null, `charter/${charter._id.$oid}/legalemail`, email);
        setEmailIsSaving(false)
    }


    useEffect(async () => {
        if (!!charter) {
            if (!!charter?.legal_documents?.version) {
                setVersion(charter?.legal_documents?.version)

            }
            else {

                setVersion(0);

            }
            if (charter.legal_documents?.filetype === "pdf") {

                try {
                    const response = await fetch(getBlobImageUrl(charter?.legal_documents?.document));
                    if (response.ok) {
                        const PdfText = await response.text();
                        setViewPdf(window.URL.createObjectURL(getBlobFromPdfString(PdfText)))
                    } else {
                        console.error('err');
                    }
                } catch (error) {
                    console.error(error);
                }
            }

            if (charter.legal_documents?.filetype === "md") {

                try {
                    const response = await fetch(getBlobImageUrl(charter?.legal_documents?.document));
                    if (response.ok) {
                        const markdownText = await response.text();
                        setViewMardown(markdownText);
                    } else {
                        console.error('err');
                    }
                } catch (error) {
                    console.error(error);
                }

            }
        }
    }, [charter]);
    useEffect(async () => {

        if (!!charter && charter?.legal_entity) {
            let ret = await restGet((res) => setTax(res.data), 'tax/' + charter?.legal_entity)
        }
    }, [charter]);

    function GTC() {
        inputFile.current.click();
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!!file?.type || file.type == "") {
            if (file?.type === "application/pdf") {
                toBase64(file)
                    .then(base64 => {
                        settype("application/pdf");
                        setGtc(base64);
                        setViewPdf(window.URL.createObjectURL(getBlobFromPdfString(base64)))
                        setViewMardown(null);

                    })
                    .catch(error => {
                        console.log(error, "err")
                    })

            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    settype(null)
                    setGtc(e.target.result);
                    setViewMardown(e.target.result)
                    setViewPdf(null)

                };
                reader.readAsText(file);
            }
        }
    };

    useEffect(() => {
        const AddGtc = async () => {
            if (!!gtc && !!charter) {
                try {
                    if (type === "application/pdf") {

                        const data = { value: gtc, filetype: "pdf", type: "gtc", version: version + 1 }
                        setVersion(version + 1);
                        await restPost(
                            null,
                            `charter/${charter._id.$oid}/gtc`,
                            data
                        );
                    } else {

                        const data = { value: gtc, filetype: "md", type: "gtc", version: version + 1 }
                        setVersion(version + 1);
                        await restPost(
                            null,
                            `charter/${charter._id.$oid}/gtc`,
                            data,
                            { 'Content-Type': 'application/json' }
                        );

                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        AddGtc();
    }, [gtc, refresher]);

    const [initLoading, setInitLoading] = useState(true);
    useEffect(() => {
        const fetch = async () => {
            setStripeAccount(undefined)
            setCharter(undefined)
            setAccountRestricted(true)
            setInitLoading(true)
            await restGet((res) => setCharter(!!res && !!res.data && res.data[0]), 'charter/' + alias)
            setInitLoading(false)
        }
        alias && fetch();
    }, [alias]);

    useEffect(() => {
        if (!!stripeAccountSetup && !!stripeAccountSetup.data && !!stripeAccountSetup.data.url) {
            window.location.replace(stripeAccountSetup.data.url)
        }
    }, [stripeAccountSetup]);


    const [stripeLoading, setStripeLoading] = useState(true);
    useEffect(async () => {
        if (!!charter && !!charter.account_id) {
            setStripeLoading(true)
            await restGet(setStripeAccount, "account/" + charter.account_id + "/charter/" + alias)
            setStripeLoading(false)
            setLoading(true);
        }
    }, [charter]);

    useEffect(() => {
        setLoading(false);
        if (!!stripeAccount && !!stripeAccount.data) {
            ((stripeAccount.data.charges_enabled ?? false) == true && (stripeAccount.data.details_submitted ?? false) == true) ? setAccountRestricted(false) : setAccountRestricted(true)
        }
        if (!!stripeAccount?.data?.terms?.address) {
            setTextField(stripeAccount?.data?.terms?.address)
        }
        if (!!stripeAccount?.data?.terms?.email) {
            setEmail(stripeAccount?.data?.terms?.email)
        }
        setLoading(true);

    }, [stripeAccount]);

    if (initLoading) {
        return <AeroLoading />
    }

    return (
        <Restricted role="owner" nopermission>
            <Box mb={3}>


                <Grid container spacing={3}>
                    <Grid item xs={12} lg={3} >

                        {accountRestricted ?

                            <SimpleSidenav sidenavItems={[
                                { icon: "groups", label: t("dashboard:charter.legal_info_settings.payment"), href: "payment" },

                            ]} />
                            :

                            <SimpleSidenav sidenavItems={[
                                { icon: "groups", label: t("dashboard:charter.legal_info_settings.payment"), href: "payment" },
                                { icon: "handshake", label: t("dashboard:charter.legal_info_settings.details"), href: "details" },
                                { icon: "groups", label: t("dashboard:charter.legal_info_settings.sales_tax"), href: "sales_tax" },
                                { icon: "handshake", label: t("dashboard:charter.legal_info_settings.legal_address"), href: "legal_address" },
                                { icon: "groups", label: t("dashboard:charter.legal_info_settings.custom_gtc"), href: "custom_gtc" },
                            ]} />


                        }


                    </Grid>
                    <Grid item xs={12} lg={9} spacing={3}>

                        <Card id="payment">
                            <TypographyBlock

                                title={t("dashboard:charter.legal.title")}
                                description={
                                    <Trans
                                        components={{ a: <a /> }}
                                        i18nKey={"dashboard:charter.legal.description"}
                                    />
                                }
                                action={() => (buttonClick())}
                                actionName={t("common:button.edit")}
                                disabled={buttonDisable}
                            />
                            {!stripeLoading ?
                                ((!!charter && !!charter.account_id) && !!stripeAccount ?
                                    <TypographyBlock
                                        title={
                                            <>Your Account
                                                <Badge
                                                    circular
                                                    color={(accountRestricted == true ? 'warning' : 'success')}
                                                    badgeContent={(accountRestricted == true) ? 'Restricted' : 'Enabled'}
                                                />
                                            </>
                                        }
                                        description={<div>
                                            <div><b>{t("common:label.fee")}</b> {(stripeAccount?.data?.terms?.fee) + '%'}</div>
                                            <div>
                                                <b>{t("common:label.accounts")}</b>
                                                {stripeAccount?.data?.external_accounts?.data?.map((acc, i) =>
                                                    <div key={i}>{acc?.bank_name} (***{acc?.last4})</div>
                                                )}
                                            </div>
                                        </div>
                                        }
                                    />
                                    :
                                    <TypographyBlock
                                        title={
                                            <Trans
                                                components={{ a: <a /> }}
                                                i18nKey={"dashboard:charter.legal.edit"}
                                            />
                                        }
                                    />)
                                :
                                <Box p={3}>
                                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                </Box>
                            }</Card>

                        <div style={{ marginBottom: 30 }}></div>
                        {accountRestricted != true && <>
                            <Card id="details">
                                <TypographyBlock
                                    title='Details'
                                    description={<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Box sx={{ m: 1 }}>
                                            <EmailInput
                                                label='Email'
                                                onChange={setEmail}
                                                isValid={setValid}
                                                value={email}
                                            />
                                        </Box>
                                        <Button
                                            disabled={!valid || emailIsSaving}
                                            color="dark"
                                            onClick={() => saveChanges()}
                                            sx={{ height: "10px" }}
                                        >
                                            {emailIsSaving ? <CircularProgress size="1rem" /> : t('common:button.save')}
                                        </Button></Box>}
                                /></Card>
                            <div style={{ marginBottom: 30 }}></div>

                            <Card id="sales_tax">

                                <TypographyBlock
                                    title='Sales Tax'
                                    description={
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <TaxCodeInput codes={tax} preset={charter?.tax_rates} onChange={Handlechange}></TaxCodeInput>
                                            <Button
                                                color="dark"
                                                onClick={() => taxButtonClick()}
                                                sx={{ height: "10px" }}
                                            >
                                                {t('common:button.save')}
                                            </Button>
                                        </Box>}
                                />
                            </Card>



                            <div style={{ marginBottom: 30 }}></div>

                            <Card id="legal_address">
                                <>
                                    <TypographyBlock
                                        title='Legal Address'
                                        description={"Add your Legal Address"}
                                        action={() => (saveLegalAddress())}
                                        actionName={t("common:button.save")}
                                    />

                                    <Box p={3} sx={{ width: "100%" }}>
                                        {
                                            loading ? <TextField
                                                id="outlined-multiline-flexible"
                                                label="Address"
                                                rows={5}
                                                value={textField.join('\n')}
                                                onChange={handleTextField}
                                                multiline
                                                fullWidth
                                            >

                                            </TextField>
                                                :
                                                (
                                                    <>
                                                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />

                                                    </>


                                                )
                                        }
                                    </Box>

                                </>
                            </Card >

                            <div style={{ marginBottom: 30 }}></div>


                            <Card mb={3}>
                                <>
                                    <TypographyBlock
                                        title='Custom GTC'
                                        id="custom_gtc"
                                        description={"Add a Custom GTC for Checkout accepted formats pdf and markdown"}
                                        action={() => (GTC())}
                                        actionName={t("common:button.add")}
                                    />

                                    <Box id="ppp" p={10} lineHeight={1} sx={{ minHeight: '50h', overflow: 'auto' }}>
                                        <ReactMarkdown >{viewMardown}</ReactMarkdown>
                                    </Box>
                                    {!!viewPdf && <iframe
                                        style={{ width: "100%", minHeight: "50vh", }}
                                        src={viewPdf ?? ''}
                                    >
                                    </iframe>}
                                    <input accept='.md, .pdf' type='file' id='file' ref={inputFile} onChange={handleFileChange} style={{ display: 'none' }} />
                                </>
                            </Card ></>}
                    </Grid>

                </Grid>

            </Box >
        </Restricted >
    )
}