import { AppBar, Stack, Tab, Tabs, Grid } from "@mui/material";
import Box from "components/Box";
import Input from "components/Input";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export default function InformationTab({ setData, defaultData }) {
    const { t } = useTranslation()
    const [language, setLanguage] = useState("en");
    const [title, setTitle] = useState(defaultData?.caption ?? { en: "" })
    const [description, setDescription] = useState(defaultData?.description ?? { en: "" })

    useEffect(() => {
        setData({
            "caption": title,
            "description": description,
        })
    }, [title, description]);

    return (
        <Box p={3}>
            <Stack >
                <TypographyBlock
                    nopadding
                    title={t("common:label.information")}
                />
                <AppBar position="static">
                    <Tabs value={language} onChange={(event, newValue) => setLanguage(newValue)}>
                        <Tab label={"en"} value={"en"} />
                        <Tab label={"de"} value={"de"} />
                    </Tabs>
                </AppBar>
                <Grid container spacing={3} pt={2}>
                    <Grid item xs={12}>
                        <Box width="100%">
                            <Input
                                fullWidth
                                variant="standard"
                                label={t("common:label.title")}
                                type="text"
                                value={title[language] ?? ""}
                                onChange={(event) => setTitle({ ...title, [language]: event.target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box width="100%">
                            <Input
                                fullWidth
                                variant="standard"
                                label={t("common:label.description")}
                                type="text"
                                multiline
                                rows={12}
                                value={description[language] ?? ""}
                                onChange={(event) => setDescription({ ...description, [language]: event.target.value })}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}