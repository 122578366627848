import DefaultModal from "components/Modal/DefaultModal";
import { useTranslation } from "react-i18next";

function SaveModal({ saveAction, actionName }) {
    const { t } = useTranslation();

    return (
        <DefaultModal
            title={t("common:modal.checkride.title", { action: actionName })}
            closeExplicit={true}
            confirm={{
                text: "Confirm",
                color: "success",
                show: true,
                disabled: false,
                action: () => { saveAction(); }
            }}
        >
            {t("common:modal.checkride.description", { action: actionName })}
        </DefaultModal >
    );
}

export default SaveModal;
