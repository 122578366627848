import { Stack, Grid, Divider, Fade } from "@mui/material";
import Alert from "components/Alert";
import Box from "components/Box";
import Button from "components/Button";
import Input from "components/Input";
import AircraftTypeSearch from "components/Input/AircraftTypeSearch";
import AirportSearch from "components/Input/AirportSearch";
import AutoComplete from "components/Input/AutoComplete";
import CertificationSearch from "components/Input/CertificationSearch";
import CountrySearch from "components/Input/CountrySearch";
import AeroLoading from "components/Progress/AeroLoading";
import Typography from "components/Typography";
import TypographyBlock from "components/Typography/TypographyBlock";
import { getCountryByReg } from "lib/functions/country";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import ClearIcon from '@mui/icons-material/ErrorOutline';

export default function RequiredStep({ setData }) {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState("");
    const [airclass, setAirclass] = useState("");
    const [type, setType] = useState("");
    const [cert, setCert] = useState({ certification: 'VFR' });
    const [homebase, setHomebase] = useState();
    const [country, setCountry] = useState("");

    const [reg, setReg] = useState();

    const [errors, setErrors] = useState([])
    useEffect(() => {
        let mounted = true;
        let timeout = setTimeout(async () => {
            const fetchRegCallback = (data) => {
                if (mounted) {
                    if (!!data && !!data.data && !!data.data[0]) {
                        setName(data.data[0].manufacturername + " " + data.data[0].model);
                        setType({ name: data.data[0].typecode })
                        setAirclass(data.data[0].icaoaircrafttype)
                        var tmp = { alpha3Code: getCountryByReg(reg).ISO3166_1_Alpha3 ?? "Unknown" }
                        if (country?.alpha3Code !== tmp.alpha3Code)
                            setCountry(tmp)
                    }
                }
            }
            const fetchAircraftCallback = (data) => {
                if (mounted) {
                    if (!!data && !!data.data && !!data.data[0]) {
                        setErrors([{ code: 10 }])
                    } else {
                        setErrors([])
                    }
                }
            }
            setIsLoading(true)
            if (!!reg) {
                await restGet(fetchRegCallback, "registration/" + reg)
                await restGet(fetchAircraftCallback, "aircraft/" + reg)
            }
            setIsLoading(false)
        }, 300);
        return () => {
            mounted = false
            clearTimeout(timeout)
        }
    }, [reg])

    useEffect(() => {
        console.log("country", country)
    }, [country]);

    useEffect(() => {
        let timeout = setTimeout(() => {
            var result = {
                reg: reg,
                name: name,
                default_lang: "en",
                class: airclass,
                type: type,
                certification: cert,
                homebase: homebase,
                reg_country: country
            }
            setData(result)
        }, 500);

        return () => { clearTimeout(timeout) }
    }, [reg, name, airclass, type, cert, homebase, country])

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:charter.aircrafts.steps.required.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:charter.aircrafts.steps.required.description")}
                </Typography>
            </Box>

            <Box mt={2}>
                <Grid container spacing={3}
                    alignItems="flex-end">
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.registration")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <Input
                                        sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                        label={""}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={reg}
                                        onChange={(e) => setReg(e.target.value.toUpperCase())}
                                    />
                                    {isLoading &&
                                        <AeroLoading />
                                    }
                                    <Stack>{!!errors &&
                                        errors.map((error, i) =>
                                            <Alert color="error" key={"Booking-Alert-" + i} sx={{ minHeight: "1rem", padding: "5px", paddingLeft: "15px" }}>
                                                <ClearIcon fontSize="small" />&nbsp;
                                                {t("error:" + error.code)}
                                            </Alert>)}
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider sx={{ opacity: 0.75 }} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.name")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <Input
                                        //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                        label={""}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.homebase")}
                                    description={null}
                                />
                                <Box mt={-1}>
                                    <AirportSearch
                                        fullWidth
                                        label={""}
                                        onChange={setHomebase}
                                        value={homebase}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.country")}
                                    description={null}
                                />
                                <Box mt={-1}>
                                    <CountrySearch
                                        fullWidth
                                        label={""}
                                        onChange={setCountry}
                                        value={country}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider sx={{ opacity: 0.75 }} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={("Aircraft Model Spec")}
                                    description={null}
                                />
                                <Box mt={-1}>
                                    <AircraftTypeSearch
                                        fullWidth
                                        label={"Search"}
                                        onChange={setAirclass}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.class")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <Input
                                        //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                        label={""}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        options={[]}
                                        onChange={(e) => setAirclass(e.target.value)}
                                        value={airclass?.description ?? ""}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.type")}
                                    description={null}
                                />
                                <Box mt={-1} width="100%">
                                    <Input
                                        //sx={{ '& .MuiInputBase-input': { height: "2em" } }}
                                        label={""}
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        options={[]}
                                        onChange={(e) => setType(e.target.value)}
                                        value={airclass?.type ?? ""}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider sx={{ opacity: 0.75 }} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box ml={1} mr={1} mb={1}>
                            <Stack>
                                <TypographyBlock
                                    dense={true}
                                    nopadding
                                    title={t("dashboard:charter.aircrafts.steps.required.certification")}
                                    description={null}
                                />
                                <Box mt={-1}>
                                    <CertificationSearch
                                        fullWidth
                                        label={""}
                                        onChange={setCert}
                                        value={cert}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
