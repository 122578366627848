import { useEffect, useState } from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import { Trans, useTranslation } from "react-i18next";
import _ from "lodash";
import LegalDocViewer from "components/LegalDocViewer/LegalDocViewer";
import { FormControlLabel, Checkbox } from "@mui/material";


function LegalStep({ setData }) {
    const { t } = useTranslation();
    const [acceptGTC, setAcceptGTC] = useState(false);

    useEffect(() => {
        setData(acceptGTC)
    }, [acceptGTC]);

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <LegalDocViewer docType='gtc' showPDF={false} />
                    <FormControlLabel
                        control={<Checkbox checked={acceptGTC} onChange={(e) => setAcceptGTC(e.target.checked)} />}
                        label={t("dashboard:charter.new.checkbox")}
                    />
                </Box>

                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:charter.new.title")}
                    </Typography>
                    <Typography variant="body2" color="text">
                        <Trans
                            components={{ a: <a /> }}
                            i18nKey={"dashboard:charter.new.description"}
                        />
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default LegalStep;