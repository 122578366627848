import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import DefaultAircraftCard from "components/Cards/AircraftCards/DefaultAircraftCard/DefaultAircraftCard";
import { getLanguageTextWithFallBack } from "lib/functions";
import { useParams } from "react-router";
import AeroLoading from "components/Progress/AeroLoading"
import { legalFromAlias } from "lib/legalInfo";

export default function CharterAircrafts({ charter }) {
    const [aircrafts, setAircrafts] = useState()
    const [isloading, setIsLoading] = useState(false)
    const [legalEntity, setLegalEntity] = useState();
    const { REG } = useParams();
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setAircrafts(resp.data)
            }
            setIsLoading(true)
            await restGet(restCallback, 'charter/' + charter.alias + "/aircrafts")
            setIsLoading(false)
        }
        !!charter && fetch()
    }, [charter])

    useEffect(async () => {
        if (!!charter.alias) {
            let data = await legalFromAlias(charter.alias)
            setLegalEntity(data);
        }
    }, []);

    if (!aircrafts || isloading) {
        return isloading ? <AeroLoading /> : null;
    }
    return (
        <Container>
            <Grid container spacing={3} sx={{ mt: 3 }}
                alignItems="stretch">
                {aircrafts.map((x, i) => {
                    if (x.reg === REG) return null
                    return (
                        <Grid item xs={12} md={6} lg={4} key={"aircraft-card-" + i} >
                            <Box mt={3} style={{ height: "100%", paddingBottom: "24px" }}>
                                <DefaultAircraftCard
                                    image={x.image}
                                    title={x.name + " (" + x.reg + ")"}
                                    description={getLanguageTextWithFallBack(x.description)}
                                    categories={[x.homebase, x.certification, x?.fullclass?.description ?? x?.class, x.type]}
                                    aircraft={x}
                                    legalObject={legalEntity}
                                />
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    )
}