import { Grid } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import brand from "constants/brand";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function InvoiceHeader({ from, to, logo }) {
    const { t } = useTranslation();
    return (
        <Box >
            <Grid container justifyContent="space-between">
                <Grid item xs={12} md={4}>
                    <Box
                        component="img"
                        src={logo}
                        width="25%"
                        p={1}
                        mb={1}
                    />
                    <Typography variant="h6" fontWeight="medium">
                        {from.name ?? ""}
                    </Typography>
                    {_.isArray(from.address1) ?
                        from.address1.map((i, index) =>
                            <Typography key={"address-line-" + index} variant="h6" fontWeight="medium">
                                {i ?? ""}
                            </Typography>)
                        :
                        <Typography variant="h6" fontWeight="medium">
                            {from.address1 ?? ""}
                        </Typography>
                    }
                    <Typography variant="h6" fontWeight="medium">
                        {from.address2 ?? ""}
                    </Typography>
                    <Typography variant="h6" fontWeight="medium">
                        {from.postalcode} {from.city}
                    </Typography>
                    <Typography variant="h6" fontWeight="medium">
                        {from.country}
                    </Typography>
                    <Box mt={1} mb={2}>
                        <Typography
                            display="block"
                            variant="body2"
                            color="secondary"
                        >
                            {from.phone ?? ""}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} lg={3}>
                    <Box width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
                        <Box mt={1}>
                            <Typography variant="h6" fontWeight="medium">
                                {t("common:label.bill_to")}: {to.name}
                            </Typography>
                        </Box>
                        <Box mb={1}>
                            <Typography variant="body2" color="secondary">
                                {to.address1}
                            </Typography>
                            <Typography variant="body2" color="secondary">
                                {to.address2}
                            </Typography>
                            <Typography variant="body2" color="secondary">
                                {to.postalcode} {to.city}
                            </Typography>
                            <Typography variant="body2" color="secondary">
                                {to.country}
                            </Typography>

                            <Typography
                                display="block"
                                variant="body2"
                                color="secondary">
                                {to.email}
                            </Typography>
                            <Box mt={1} mb={2}>
                                <Typography
                                    display="block"
                                    variant="body2"
                                    color="secondary"
                                >
                                    {to.phone ?? ""}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={{ xs: 5, md: 10 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="h6"
                            color="secondary"
                            fontWeight="regular"
                        >
                            {t("common:label.invoice_no")}
                        </Typography>
                        <Typography variant="h5" fontWeight="medium">
                            #XXXXXXX
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7} lg={5}>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            alignItems={{ xs: "flex-start", md: "center" }}
                            textAlign={{ xs: "left", md: "right" }}
                            mt={{ xs: 3, md: 0 }}
                        >
                            <Box width="50%">
                                <Typography
                                    variant="h6"
                                    color="secondary"
                                    fontWeight="regular"
                                >
                                    {t("common:label.invoice_date")}:
                                </Typography>
                            </Box>
                            <Box width="50%">
                                <Typography variant="h6" fontWeight="medium">
                                    {moment().format("L")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            alignItems={{ xs: "flex-start", md: "center" }}
                            textAlign={{ xs: "left", md: "right" }}
                        >
                            <Box width="50%">
                                <Typography
                                    variant="h6"
                                    color="secondary"
                                    fontWeight="regular"
                                >
                                    {t("common:label.due_date")}:
                                </Typography>
                            </Box>
                            <Box width="50%">
                                <Typography variant="h6" fontWeight="medium">
                                    {moment().add(14, "days").format("L")}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}

InvoiceHeader.defaultProps = {
    from: {
        name: "",
        address1: "",
        address2: "",
        postalcode: "",
        city: "",
        country: "",
        phone: "",
    },
    to: {
        name: "",
        email: "",
        address1: "",
        address2: "",
        postalcode: "",
        city: "",
        country: "",
        phone: "",
    },
};

// Typechecking props for the Calendar
InvoiceHeader.propTypes = {
    from: PropTypes.shape({
        name: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalcode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        phone: PropTypes.string,
    }),
    to: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalcode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        phone: PropTypes.string,
    }),
};

export default InvoiceHeader;