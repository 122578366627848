import KitLayout from "aeromate-kit/KitLayout";
import Body from "./Body";
import Header from "./Header";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { restGet } from "lib/rest";
import { getBlobImageUrl } from "lib/functions";
import placeholderAvatar from "assets/images/avatar.png"



export default function UserProfilePage() {

    let { identity } = useParams();
    const [user, setUser] = useState();
    const [header, setHeader] = useState("https://media.westeurope.aero-services.net/aeromate/header/rough-horn-2146181_1920.jpg");
    const [avatar, setAvatar] = useState(placeholderAvatar);

    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                setUser(resp);
                setAvatar(getBlobImageUrl(resp.image.Avatar))
                setHeader(getBlobImageUrl(resp.image.Header))
 
            }
            await restGet(restCallback, `identity/${identity}/Public`)

        }
        !!identity && fetch()
    }, [identity])

    return (
        <KitLayout header={<Header bgImage={header}></Header>} title={"user"} nocard>
            <Body header={Header} avatar={avatar} user={user}></Body>
        </KitLayout>
    )
}
