import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { Grid, Stack, FormGroup, FormControlLabel, Checkbox, AppBar, Tab, Tabs } from "@mui/material";
import ReactMarkdown from "react-markdown";
import Box from "components/Box";
import Input from "components/Input";
import { restGet } from "lib/rest";
import _ from "lodash";
import { restPost } from 'lib/rest';
import EmailInput from 'components/Input/EmailInput';


const Newslettermodal = ({ airport, type }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [acceptPP, setAcceptPP] = useState(false);
    const [valid, setValid] = useState(true);

    async function saveEmail() {

        //await restPost(null, `notificaton/Newsletterentry`, {  message: comment  })

        const object = { email: email, icao: airport, type: type }
        await restPost(null, `newsletter/Newsletter`, object)


    }



    return (
        <DefaultModal
            title={"Newsletter"}
            confirm={{
                text: t("common:button.confirm"),
                color: "success",
                show: acceptPP && valid,
                action: () => { saveEmail(); }

            }}>

            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={3}>
                        <Grid mt={1} container spacing={2}>
                            <Grid item xs={12} p={3}>
                                <Stack>

                                    <Box mt={-1} width="100%">
                                        <EmailInput
                                            label='Email'
                                            onChange={setEmail}
                                            isValid={setValid}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={acceptPP} onChange={(e) => setAcceptPP(!acceptPP)} />}
                                        label={<ReactMarkdown >{t('common:optin.optinPrivacy')}</ReactMarkdown>}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>

                    </Stack>
                </Grid>
            </Grid>



        </DefaultModal>
    )
}

export default Newslettermodal