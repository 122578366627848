import DownloadModal from "components/Modal/DownloadModal";
import { convertTimeDatabaseToFrontend } from "lib/functions";
import { centToEuro } from "lib/functions";
import i18n from "locals/config";
import moment from "moment";

export const invoiceColumns = [
    {
        Header: i18n.t("common:label.created"),
        accessor: "billing_document_date",
        Cell: props => moment(convertTimeDatabaseToFrontend(parseInt(props.value))).format("LLL")
    },
    {
        Header: i18n.t("common:label.charter"),
        accessor: "seller[0]"
    },
    {
        Header: i18n.t("common:label.amount"),
        accessor: "amount",
        Cell: props => centToEuro(props.value)
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
]

export const invoiceActions = (addModal, data) => {
    return [
        {
            displayStatus: ['INV'],
            title: i18n.t("common:label.download"),
            action: (index) => {
                addModal(<DownloadModal
                    data={data[index]}
                    type="invoice"
                />)
            }
        }
    ];
}