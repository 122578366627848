import logo from "assets/images/logo.png";
import ae_logo from "assets/images/ae_logo.png";
import slogan from "assets/images/slogan.png"

export default {
    name: "Æro Mate",
    name_caps: "Æro Mate",
    slogan: slogan,
    claim: "General Aviation's Digital Mate",
    company: "AERO MATE GmbH",
    route: "/",
    image: logo,
    image2: ae_logo,
    static_cdn: "https://static.aeromate.eu/"
}