import KitLayout from "aeromate-kit/KitLayout";
import Body from "./Body";
import Header from "./Header";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { restGet } from "lib/rest";
import { getBlobImageUrl } from "lib/functions";
import placeholderAvatar from "assets/images/avatar.png"


export default function Unsubscribe() {

    const [header, setHeader] = useState("https://media.westeurope.aero-services.net/aeromate/header/rough-horn-2146181_1920.jpg");
    const {search} =useLocation();
    const icao =  new URLSearchParams(search).get("icao")
    const id =  new URLSearchParams(search).get("id") 


    return (
        <KitLayout header={<Header bgImage={header}></Header>} title={"user"} nocard>
            <Body header={Header} icao={icao} id={id}></Body>
        </KitLayout>
    )
}
