import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import { Grid, Stack, FormGroup, FormControlLabel, Checkbox, AppBar, Tab, Tabs } from "@mui/material";
import ReactMarkdown from "react-markdown";
import Box from "components/Box";
import Input from "components/Input";
import { restGet } from "lib/rest";
import _ from "lodash";
import { restPost } from 'lib/rest';
import { useRefresher } from 'hooks/refreshHook';
import EmailInput from 'components/Input/EmailInput';


const NewsletterChartermodal = ({ alias, type, charter, userdata, emails, id, subscribe, code, notify }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [acceptPP, setAcceptPP] = useState(false);
    const [acceptshop, setAcceptshop] = useState(true);
    const [acceptaircraft, setAcceptaircraft] = useState(true);
    const [acceptcourse, setAcceptcourse] = useState(true);
    const [valid, setValid] = useState(true);

    const { refresh } = useRefresher();
    async function saveEmail() {



        const object = { _charterid: charter.$oid, subscribe: subscribe, email: emails || email, Alias: alias, type: type, shop: acceptshop, aircraft: acceptaircraft, course: acceptcourse, id: id, code: code || 0, notify: notify || null }
        await restPost(null, `newsletter/Newsletter`, object)
        refresh();



    }


    // setter auslagern in newsletter
    return (
        <DefaultModal
            title={"Subscribe to Newsletter"}
            confirm={{
                text: t("common:button.confirm"),
                color: "success",
                show: acceptPP && valid,
                action: () => { saveEmail(); }

            }}>

            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={3}>
                        <Grid mt={1} container spacing={2}>
                            <Grid item xs={12} p={3}>
                                <Stack>

                                    <Box mt={-1} width="100%">
                                        {
                                            userdata == false &&

                                            <EmailInput
                                                label='Email'
                                                onChange={setEmail}
                                                isValid={setValid}
                                            />

                                        }
                                    </Box>
                                </Stack>
                                <Stack mt={2} >
                                    <FormGroup>
                                        <FormControlLabel
                                            label={<ReactMarkdown >{t('common:Newsletter.aircraft')}</ReactMarkdown>}
                                            control={<Checkbox checked={acceptaircraft} onChange={(e) => setAcceptaircraft(!acceptaircraft)} />}

                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            label={<ReactMarkdown >{t('common:Newsletter.shopitem')}</ReactMarkdown>}
                                            control={<Checkbox checked={acceptshop} onChange={(e) => setAcceptshop(!acceptshop)} />}

                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            label={<ReactMarkdown >{t('common:Newsletter.course')}</ReactMarkdown>}
                                            control={<Checkbox checked={acceptcourse} onChange={(e) => setAcceptcourse(!acceptcourse)} />}
                                        />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={acceptPP} onChange={(e) => setAcceptPP(!acceptPP)} />}
                                        label={<ReactMarkdown >{t('common:optin.optinPrivacy')}</ReactMarkdown>}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>

                    </Stack>
                </Grid>
            </Grid>



        </DefaultModal>
    )
}

export default NewsletterChartermodal