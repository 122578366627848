import { Divider, Grid, Stack } from "@mui/material";
import Box from "components/Box";
import Input from "components/Input";
import InputRoot from "components/Input/InputRoot";
import Typography from "components/Typography";
import { convertTimeDatabaseToFrontend } from "lib/functions";
import { restGet } from "lib/rest";
import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LogTimesPOBs from "../components/LogTimesPOBs";
import OpsCounterHours from "../components/OpsCounterHours";
import OpsCounterHoursMinutes from "../components/OpsCounterHoursMinutes";
import { millisecondsToHHH, millisecondsToHHMM } from "lib/functions";
import { Timer, WatchLater } from "@mui/icons-material";
import Button from "components/Button";
import { getBlobImageUrl } from "lib/functions";

const OpsStartInput = forwardRef(({ error, success, disabled, ...rest }, ref) => {
    const { t } = useTranslation()
    return (
        <InputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }}
            InputLabelProps={{ shrink: true }}
            label={t("common:label.ops_counter_start")}
            variant="outlined"
            margin="dense"
            fullWidth
        />
    )
});
const OpsEndInput = forwardRef(({ error, success, disabled, ...rest }, ref) => {
    const { t } = useTranslation()
    return (
        <InputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }}
            InputLabelProps={{ shrink: true }}
            label={t("common:label.ops_counter_end")}
            variant="outlined"
            margin="dense"
            fullWidth
        />
    )
});


export default function OperationalStep({ setData, booking, teclog }) {
    const { t } = useTranslation()
    const [opsHandoverSelectedFile, setOpsHandoverSelectedFile] = useState()
    const [opsReturnSelectedFile, setOpsReturnSelectedFile] = useState()

    const [opsHandoverPreview, setOpsHandoverPreview] = useState()
    const [opsReturnPreview, setOpsReturnPreview] = useState()


    const [pob, setPob] = useState([{ name: null, duty: null }])
    const [lastTecLog, setLastTecLog] = useState()

    const [HHMM, setHHMM] = useState(true)
    const [opsHandoverNewFile, setOpsHandoverNewFile] = useState()
    const [opsReturnNewFile, setOpsReturnNewFile] = useState()


    const [counterEnd, setCounterEnd] = useState()
    const [counterStart, setCounterStart] = useState()

    const [counterEndHHMM, setCounterEndHHMM] = useState()
    const [counterStartHHMM, setCounterStartHHMM] = useState()

    const [counterEndFloat, setCounterEndFloat] = useState()
    const [counterStartFloat, setCounterStartFloat] = useState()

    /**
     * Init Data
   */
    useEffect(() => {
        if (!opsHandoverSelectedFile) {
            setOpsHandoverPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(opsHandoverSelectedFile)
        setOpsHandoverPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [opsHandoverSelectedFile])

    useEffect(() => {
        if (!opsReturnSelectedFile) {
            setOpsReturnPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(opsReturnSelectedFile)
        setOpsReturnPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [opsReturnSelectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setOpsHandoverSelectedFile(undefined)
            return
        }

        setOpsHandoverNewFile(e.target.files[0])

        setOpsHandoverSelectedFile(e.target.files[0])
    }
    const onSelectFile2 = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setOpsReturnSelectedFile(undefined)
            return
        }

        setOpsReturnNewFile(e.target.files[0])

        setOpsReturnSelectedFile(e.target.files[0])
    }

    useEffect(() => {
        setData({
            dev_data: {
                ops_counter_start: counterStart,
                ops_counter_end: counterEnd
            }
        })

        restGet(setLastTecLog, 'teclog/' + booking.reg + '/latest')

        if (!!booking &&
            !!booking.aircraft &&
            !!booking.aircraft[0] &&
            !!booking.aircraft[0].operational_data_setting &&
            !!booking.aircraft[0].operational_data_setting.operating_hours_scale) {
            setHHMM(booking.aircraft[0].operational_data_setting.operating_hours_scale.value === "h:mm")
        }
    }, [])

    useEffect(() => {
        if (teclog) {
            if (teclog.pob_data) {
                var tmp = [...teclog.pob_data]
                tmp.push({ name: null, duty: null })
                setPob(tmp)
            } else {
                if (booking && booking.identity && booking.identity[0]) {
                    setPob([
                        { name: booking.identity[0].name, duty: { name: "PIC" } },
                        { name: null, duty: null }
                    ])
                }
            }
            setCounterStart(teclog?.ops_data?.ops_counter_start ?? 0)
            setCounterEnd(teclog?.ops_data?.ops_counter_end ?? 0)

            if (teclog.image) {
                setOpsHandoverPreview(getBlobImageUrl(teclog.image.opshandover))
                setOpsReturnPreview(getBlobImageUrl(teclog.image.opsreturn))
            }
            return;
        }
        if (booking && booking.identity && booking.identity[0]) {
            setPob([
                { name: booking.identity[0].name, duty: { name: "PIC" } },
                { name: null, duty: null }
            ])
            return;
        }
    }, [booking, teclog])

    useEffect(() => {
        setData({
            pob_data: pob,
            ops_data: {
                ops_counter_start: counterStart,
                ops_counter_end: counterEnd
            },
            ops_img_data: {
                ops_counter_start_img: opsHandoverNewFile,
                ops_counter_end_img: opsReturnNewFile,
            }
        })
    }, [pob, counterStart, counterEnd, opsHandoverNewFile, opsReturnNewFile])

    useEffect(() => {
        if (counterStart !== undefined) {
            setCounterStartHHMM(millisecondsToHHMM(counterStart))
            setCounterStartFloat(millisecondsToHHH(counterStart).toFixed(2))
        }
    }, [counterStart])

    useEffect(() => {
        if (counterEnd !== undefined) {
            setCounterEndHHMM(millisecondsToHHMM(counterEnd))
            setCounterEndFloat(millisecondsToHHH(counterEnd).toFixed(2))
        }
    }, [counterEnd])

    useEffect(() => {
        if (!teclog && !!lastTecLog && !!lastTecLog.data && !!lastTecLog.data.ops_data) {
            if ((lastTecLog.data.ops_data.ops_counter_end ?? 0) != 0) {
                if ((counterStart ?? 0) == 0) setCounterStart(lastTecLog?.data?.ops_data?.ops_counter_end ?? 0)
                if ((counterEnd ?? 0) == 0) setCounterEnd(lastTecLog?.data?.ops_data?.ops_counter_end ?? 0)
            }
        }
    }, [lastTecLog])

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:logtimes.operational.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:logtimes.operational.description")}
                    {!!booking &&
                        <>
                            {t("common:label.booking_periode")}:&nbsp;
                            {moment(convertTimeDatabaseToFrontend(booking.handover)).format("LLL")}
                            &nbsp;-&nbsp;
                            {moment(convertTimeDatabaseToFrontend(booking.return)).format("LLL")}
                        </>
                    }
                </Typography>
            </Box>

            <Box mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12}
                        align="right">
                        <Button onClick={() => setHHMM(!HHMM)}>
                            {HHMM ? <WatchLater /> : <Timer />}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input
                            InputLabelProps={{ shrink: true }}
                            label={t("common:label.reg")}
                            defaultValue={booking.reg}
                            type="text"
                            variant="outlined"
                            margin="dense"
                            disabled
                            fullWidth
                        //value
                        //onChange
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input
                            InputLabelProps={{ shrink: true }}
                            label={t("common:label.flightType")}
                            defaultValue={t("common:flight_types." + booking.flight_type)}
                            type="text"
                            variant="outlined"
                            margin="dense"
                            disabled
                            fullWidth
                        //value
                        //onChange
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <Stack>
                            {HHMM ?
                                <OpsCounterHoursMinutes
                                    defaultValue={counterStartHHMM}
                                    onChange={setCounterStart}
                                    customInput={OpsStartInput} />
                                :
                                <OpsCounterHours
                                    defaultValue={counterStartFloat}
                                    onChange={setCounterStart}
                                    customInput={OpsStartInput} />
                            }
                            <Box p={3} sx={{}}>
                                <Stack direction="row"
                                    justifyContent="center"
                                    alignItems="center" spacing={2} >
                                    {opsHandoverPreview &&
                                        <Box>
                                            <Typography variant="body2" color="text" sx={{ fontSize: "11px" }}>
                                                {t("common:label.Attach_start_img")}
                                            </Typography>
                                            <img style={{ width: "auto", height: "50px" }} src={opsHandoverPreview} />
                                        </Box>
                                    }
                                    <Button variant="gradient" color="dark" component="label" >
                                        {t("common:label.Attach")}
                                        <input
                                            hidden
                                            accept="image/png ,image/jpg,image/jpeg,.pdf"
                                            type="file"
                                            onChange={onSelectFile}
                                        />


                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>

                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <Stack>
                            {HHMM ?
                                <OpsCounterHoursMinutes
                                    defaultValue={counterEndHHMM}
                                    onChange={setCounterEnd}
                                    customInput={OpsEndInput} />
                                :
                                <OpsCounterHours
                                    defaultValue={counterEndFloat}
                                    onChange={setCounterEnd}
                                    customInput={OpsEndInput} />
                            }
                            <Box p={3} sx={{}}>
                                <Stack direction="row"
                                    justifyContent="center"
                                    alignItems="center" spacing={2} >
                                    {opsReturnPreview && <Box>
                                        <Typography variant="body2" color="text" sx={{ fontSize: "11px" }}>
                                            {t("common:label.Attach_end_img")}
                                        </Typography>
                                        <img style={{ width: "auto", height: "50px" }} src={opsReturnPreview} />
                                    </Box>
                                    }
                                    <Button variant="gradient" color="dark" component="label" >
                                        {t("common:label.Attach")}
                                        <input
                                            hidden
                                            accept="image/png ,image/jpg,image/jpeg,.pdf"
                                            type="file"
                                            onChange={onSelectFile2}
                                        />


                                    </Button>
                                </Stack>
                            </Box>



                        </Stack>
                    </Grid>

                    <Grid item xs={12} >
                        <Divider sx={{ opacity: 0.75 }} />
                    </Grid>
                    <Grid item xs={12} >
                        <LogTimesPOBs pob={pob} setPob={setPob} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
