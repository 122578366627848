import { Divider, Grid } from "@mui/material";
import Box from "components/Box";
import DynamicForm from "components/Forms/DynamicForm/DynamicForm";
import Typography from "components/Typography";
import TypographyBlock from "components/Typography/TypographyBlock";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function SettingsStep({ setData }) {
    const { t } = useTranslation()
    const [aircraftBookingSetting, setAircraftBookingSetting] = useState();
    const [aircraftOperationalSetting, setAircraftOperationalSetting] = useState();

    const [newAircraftBookingSetting, setNewAircraftBookingSetting] = useState();
    const [newAircraftOperationalSetting, setNewAircraftOperationalSetting] = useState();

    useEffect(() => {
        restGet((res) => { res.shift(); setAircraftBookingSetting(res) }, "dataset/metaDataAircraftBookingSetting")
        restGet((res) => { res.shift(); setAircraftOperationalSetting(res) }, "dataset/metaDataAircraftOperationalSetting")
    }, []);

    useEffect(() => {
        setData({
            booking_setting: newAircraftBookingSetting ?? aircraftBookingSetting,
            operational_data_setting: newAircraftOperationalSetting ?? aircraftOperationalSetting
        })
    }, [newAircraftOperationalSetting, newAircraftBookingSetting, aircraftBookingSetting, aircraftOperationalSetting]);

    return (
        <Box>
            <Box width="82%" textAlign="center" mx="auto" my={4}>
                <Box mb={1}>
                    <Typography variant="h5" fontWeight="regular">
                        {t("dashboard:charter.aircrafts.steps.settings.title")}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text">
                    {t("dashboard:charter.aircrafts.steps.settings.description")}
                </Typography>
            </Box>

            <Box mt={2}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TypographyBlock
                            title={t("dashboard:charter.aircrafts.steps.settings.booking")}
                        />
                        <Box p={3} ml={-1}>
                            <DynamicForm
                                i18nextPrefix={"booking_settings"}
                                formMetaData={newAircraftBookingSetting ?? aircraftBookingSetting}
                                formDefaultValues={{}}
                                formValuesCallback={setNewAircraftBookingSetting}
                            />
                        </Box>
                    </Grid>
                    <Box mr={-4} ml={-4} mt={12}>
                        <Divider sx={{ opacity: 0.75 }} orientation={"vertical"} flexItem />
                    </Box>
                    <Grid item xs={12} md={6}>
                        <TypographyBlock
                            title={t("dashboard:charter.aircrafts.steps.settings.operational")}
                        />
                        <Box p={3}>
                            <DynamicForm
                                i18nextPrefix={"operational_settings"}
                                formMetaData={newAircraftOperationalSetting ?? aircraftOperationalSetting}
                                formDefaultValues={{}}
                                formValuesCallback={setNewAircraftOperationalSetting}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
