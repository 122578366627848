import React from 'react'
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modal/DefaultModal";
import Box from "components/Box";
import _ from "lodash";
import { useRefresher } from 'hooks/refreshHook';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from "lib/image";
import { restPost } from 'lib/rest';

const CharterLogo = ({ onValueChange, img , id }) => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedArea, setCroppedArea] = useState(null);
    const [aircraftPreviewImage, setAircraftPreviewImage] = useState();
    const [data, setData] = useState();
    const [isloading, setIsLoading] = useState(false)


    const onCropComplete = useCallback((croppedArea, areaPixels) => {
        setCroppedArea(croppedArea)
        setCroppedAreaPixels(areaPixels)
    }, [])

    useEffect(() => {
        setAircraftPreviewImage(img)
    }, [])


    const handleClick = async () => {

        let blob = await fetch(data).then(r => r.blob()).then(blobFile => new File([blobFile], "croppedImage", { type: "image/png" }))
        let res = restPost(null, `charter/${id}/Logo`, blob, null, { "Content-Type": blob.type })
        onValueChange(data); 

    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            (async () => {
                var imageData = {
                    aircraftImage: aircraftPreviewImage,
                    croppedAreaPixels: croppedAreaPixels
                }
                let previewImage = await getCroppedImg(
                    imageData.aircraftImage,
                    imageData.croppedAreaPixels
                )
                setData(previewImage)
            })();
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [aircraftPreviewImage, croppedAreaPixels]);


    const { refresh } = useRefresher();



    // setter auslagern in newsletter
    return (
        <DefaultModal
            title={"Crop Image"}
            confirm={{
                text: t("common:button.confirm"),
                color: "success",
                show: true,
                disabled: !data,
                action: () => { handleClick(); }

            }}>

            <Box sx={{
                "position": "relative",
                "width": "auto",
                "height": "512px"
            }}>
                <Box p={1} sx={{
                    "position": "absolute",
                    "top": "0px",
                    "color":"#ffff",
                    "zIndex":"100",
                 
                }}>
                    Scroll to Zoom <br></br>
                    Drag to Reposition

                </Box>
                <Cropper
                    zoomSpeed={0.25}
                    minZoom={0.5}
                    image={aircraftPreviewImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    objectFit="horizontal-cover"
                />
            </Box>


        </DefaultModal>
    )
}

export default CharterLogo