
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Bookings from "./sections/Bookings";
import Invoices from "./sections/Invoices";
import Checkrides from "./sections/Checkrides";
import Documents from "./sections/Documents";

export default function Body() {
    const { t } = useTranslation();
    const { hash } = useLocation();
    const getTab = () => {
        switch (hash) {
            case "#bookings": return (0)
            case "#checkrides": return (1)
            case "#invoices": return (2)
            case "#documents": return (3)
            default: return (0)
        }
    }

    const [tabValue, setTabValue] = useState(getTab());
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = "#" + event.target.id
    }
    const [content, setContent] = useState(null)
    useEffect(() => {
        switch (tabValue) {
            case 0:
                setContent(<Bookings />)
                break;
            case 1:
                setContent(<Checkrides />)
                break;
            case 2:
                setContent(<Invoices />)
                break;
            case 3:
                setContent(<Documents />)
                break;
            default:
                break;
        }
    }, [tabValue])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <AppBar position="static" sx={{width:"100%",overflow:{xs:"scroll" , md:"unset"}}}>
                        <Tabs value={tabValue} onChange={handleSetTabValue} sx={{width:"max-content" }}>
                            <Tab sx={{paddingLeft:"16px" , paddingRight:"16px" }} label={t("common:label.bookings")} id="bookings" />
                            <Tab sx={{paddingLeft:"16px" , paddingRight:"16px" }} label={t("common:label.checkrides")} id="checkrides" />
                            <Tab sx={{paddingLeft:"16px" , paddingRight:"16px" }} label={t("common:label.invoices")} id="invoices" />
                            <Tab sx={{paddingLeft:"16px" , paddingRight:"16px" }} label={t("common:label.documents")} id="documents" />
                        </Tabs>
                    </AppBar>
                </Grid>
                <Grid item xs={12} >
                    {content}
                </Grid>
            </Grid>
        </div >
    )
}