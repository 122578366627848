import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import Box from "components/Box";
import TypographyBlock from "components/Typography/TypographyBlock";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export default function AircraftEquipment({ equipment }) {
    const { t } = useTranslation();
    const general = { ...equipment };
    delete general.avionics;
    var units = {};
    _.forEach(general, (value, key) => {
        const filt = key.endsWith("unit")
        if (filt) {
            units[key] = value;
            delete general[key];
        }
    })
    const avionics = [...equipment.avionics];
    return (
        <Stack spacing={3}>
            {general && <Stack >
                <TypographyBlock dense nopadding title={"General"} />
                <TableBody>
                    {Object.entries(general).map((e, i) => <TableRow key={"general-row-" + i} direction="row">
                        <TableCell>{t("common:label." + e[0])}</TableCell>
                        <TableCell>{e[1]}</TableCell>
                        <TableCell>{_.filter(units, (value, key) => e[0].split("_")[0] == key.split('_')[0])}</TableCell> </TableRow>)}
                </TableBody>
            </Stack>}
            {avionics && <Stack >
                <TypographyBlock dense nopadding title={"Avionics"} />
                <TableBody>
                    {avionics.map((e, i) => <TableRow key={"general-row-" + i} direction="row"><TableCell>{e.type}</TableCell><TableCell>{e.model}</TableCell><TableCell>{e.spec}</TableCell> </TableRow>)}
                </TableBody>
            </Stack>}
        </Stack>
    )
}