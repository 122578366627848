import React, { useState, useContext, createContext, useEffect } from "react";

const IsMobileContext = createContext(null);

export const IsMobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", setIsMobile(window.innerWidth < 960))

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", setIsMobile);
    }
  });

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      {children}
    </IsMobileContext.Provider>
  )
}

export const useIsMobile = () => useContext(IsMobileContext);