export default [
  {
    name: 'routes:headings.company',
    items: [
      { name: 'routes:aboutUs.title', route: "/aboutus" },
      { name: 'routes:partner.title', route: "/partner" }
    ],
  },
  // {
  //   name: "routes:headings.product",
  //   items: [
  //     { name: "routes:gettingStarted.title", route: "" },
  //     { name: "routes:features.title", route: "" }
  //   ],
  // },
  {
    name: "routes:headings.helpAndSupport",
    items: [
      { name: "routes:contactUs.title", route: "", clickAction: () => { window.open("https://outlook.office365.com/owa/calendar/AEROMATE@aeromate.eu/bookings/", '_blank') } },
      { name: "WhatsApp", route: "", clickAction: () => { window.open("https://wa.me/13236570534", '_blank') } },
      //{ name: "routes:knowledgeCenter.title", route: "" }
    ],
  },
  {
    name: "routes:headings.legal",
    items: [
      { name: "routes:imprint.title", route: "/imprint" },
      { name: "routes:termsAndConditions.title", route: "/gtc" },
      { name: "routes:privacyPolicy.title", route: "/privacy" },
      { name: "routes:cookie.title", route: "", clickAction: () => { CCM.openWidget(); return false; } },
    ],
  },
]