import PropTypes from "prop-types";
import { Switch, FormControlLabel } from "@mui/material";
import Input from "components/Input";
import CountrySearch from "components/Input/CountrySearch";
import AirportSearch from "components/Input/AirportSearch";
import AutoComplete from "components/Input/AutoComplete";

function DynamicFormInput(props) {
    const { inputMetaData, dataCallback } = props;

    const SwitchHandleChange = event => {
        dataCallback(event.target.checked, inputMetaData.type);
    };

    const InputHandleChange = event => {
        dataCallback(event.target.value, inputMetaData.type);
    };

    const SelectHandleChange = event => {
        dataCallback(event, inputMetaData.type);
    };

    const CountryHandleChange = event => {
        dataCallback(event, inputMetaData.type);
    };

    const HomebaseHandleChange = event => {
        dataCallback(event, inputMetaData.type);
    };

    switch (inputMetaData.type) {
        case "bool":
            return <FormControlLabel
                control={<Switch
                    checked={inputMetaData.value}
                    onChange={e => SwitchHandleChange(e)}
                />}
                id={inputMetaData.name}
                label={inputMetaData.label ?? ""}
            />
        case "int":
            return <Input
                id={inputMetaData.name}
                label={inputMetaData.label ?? ""}
                type="number"
                variant="outlined"
                margin="dense"
                value={inputMetaData.value}
                helperText={inputMetaData.helperText ?? ""}
                fullWidth
                onChange={e => InputHandleChange(e)}
            />;
        case "text":
            return <Input
                id={inputMetaData.name}
                label={inputMetaData.label ?? ""}
                type="text"
                variant="outlined"
                margin="dense"
                value={inputMetaData.value}
                helperText={inputMetaData.helperText ?? ""}
                fullWidth
                onChange={e => InputHandleChange(e)}
            />;
        case "country":
            return <CountrySearch
                label={inputMetaData.label ?? ""}
                id={inputMetaData.name}
                value={inputMetaData.value}
                onChange={e => CountryHandleChange(e)}
            />
        case "airport":
            return <AirportSearch
                label={inputMetaData.label ?? ""}
                id={inputMetaData.name}
                value={inputMetaData.value}
                onChange={e => HomebaseHandleChange(e)}
            />
        case "list":
            return <AutoComplete
                label={inputMetaData.label ?? ""}
                value={inputMetaData.value}
                disableClearable
                options={!!inputMetaData.values &&
                    inputMetaData.values.map(item => ({
                        value: item.val,
                        label: item.text
                    }))}
                onChange={e => SelectHandleChange(e)}
            />
        default:
            return null
    }
}

DynamicFormInput.propTypes = {
    classes: PropTypes.object,
    inputMetaData: PropTypes.object,
    inputDefaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
    dataCallback: PropTypes.func
};

export default DynamicFormInput;
