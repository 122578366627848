import DefaultModal from "components/Modal/DefaultModal";
import AeroLoading from "components/Progress/AeroLoading";
import { useModal } from "hooks/modalHook";
import { useRefresher } from "hooks/refreshHook";
import { t } from "i18next";
import { restPost } from "lib/rest";
import { useCallback, useState } from "react";

function SepaDeleteModal({ data }) {
    const [loading, setLoading] = useState(false)
    const { refresh } = useRefresher();
    const { removeModal } = useModal();

    const deleteAction = useCallback(async () => {
        setLoading(true)
        await restPost(null, `sepa/${data.identity_id.$oid}/identity/${data.charter_id.$oid}/charter/_delete`)
        setLoading(false)
        refresh()
        removeModal()
    }, [data])

    return (
        <DefaultModal
            title={t("common:modal.confirm.title")}
            confirm={{
                show: true,
                text: t("common:button.confirm"),
                color: "info",
                explicitAction: deleteAction
            }}
        >
            {loading ? <AeroLoading /> :
                t("common:modal.confirm.description", { action: 'delete' })
            }
        </DefaultModal>
    );
}

export default SepaDeleteModal;