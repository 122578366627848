import { Container, Grid } from "@mui/material"
import Box from "components/Box"
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard"
import RotatingCard from "components/Cards/RotatingCard"
import RotatingCardFront from "components/Cards/RotatingCard/RotatingCardFront"
import { Info } from "./Sections.js/info"
import Partnership from "./Sections.js/Partnership"
import Counter from "./Sections.js/Counter"
import CallToAction from "./Sections.js/CallToAction"
import { restGet } from "lib/rest"
import { useEffect, useState } from "react"

export default function Body() {


    const [treeNationCount, setTreeNationCount] = useState();
    const [treeNationId, setTreeNationId] = useState();
    const [treeNationC02, setTreeNationC02] = useState();


    useEffect(async () => {
        fetch('https://tree-nation.com/api/forests/426185')
            .then((response) => response.text())
            .then((body) => {
                setTreeNationId(JSON.parse(body).id);
                setTreeNationC02(JSON.parse(body).co2_compensated_tons)
                setTreeNationCount(JSON.parse(body).tree_count)
            });
    }, [])



    return (

        <Box>

            <Info></Info>
            <Box>
            <Partnership></Partnership>
            </Box>
            <Box>
            <Counter id={treeNationId} c02={treeNationC02} count={treeNationCount}></Counter>
            </Box>
            <Box >
            <CallToAction></CallToAction>
            </Box>
        </Box>

    )
}