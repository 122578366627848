import Customer from "./sections/Customer";
import InfoText from "./sections/InfoText";
import Memberships from "./sections/Memberships";
import Microsoft from "./sections/Microsoft";
import SayAgainAero from "./sections/SayAgainAero";
import Partners from "./sections/Partners";
import TurnData from "./sections/TurnData";
import partnerContent from '../../locals/en/partnerPage';

export default function Body() {

    return (
        <div>
            <InfoText pageContent={partnerContent.info_text} />
            <Microsoft  />
            <SayAgainAero  />
            <Partners pageContent={partnerContent.partner} />
            <Memberships pageContent={partnerContent.memberships} />

            {/* <Customer /> */}
            {/* <TurnData /> */}

        </div>
    )
}