
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Icon } from "@mui/material";


export default [
    {
        icon: <FacebookIcon />,
        classname: "fab fa-facebook",
        link: "https://www.facebook.com/aeromate.eu/",
    },
    {
        icon: <LinkedInIcon />,
        classname: "fab fa-linkedin",
        link: "https://www.linkedin.com/company/aero-mate",
    },
    {
        icon: <InstagramIcon />,
        classname: "fab fa-instagram",
        link: "https://www.instagram.com/aeromate.eu/",
    },
    {
        icon: <YouTubeIcon />,
        classname: "fab fa-youtube",
        link: "https://www.youtube.com/channel/UCigFsYSujAVJrJfIIqJKGGA",
    }
]