import AboutUsHelmet from "components/Helmets/AboutUsHelmet";
import Booking from "./sections/Booking";
import Information from "./sections/Information";
import Partners from "./sections/Partners";
import Stats from "./sections/Stats";
import Team from "./sections/Team";
import Timeline from "./sections/Timeline";
import Offices from "./sections/Offices";
import { Box, Container } from "@mui/material";

const showInformation = true;
const showTeam = true;
const showOffices = true;
const showPartners = false;
const showStats = false;
const showTimeline = true;
const showBooking = true;

export default function Body() {
    return (<div>
        <AboutUsHelmet />
        {showInformation && <Information />}
        {showTeam && <Team />}
        {showOffices && <Box
            component="section"
            py={6}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        ><Container><Offices /></Container></Box>}
        {showPartners && <Partners />}
        {showStats && <Stats />}
        {showTimeline && <Timeline />}
        {/* showBooking && <Booking /> */}
    </div>)
}