import Container from "@mui/material/Container";
import Box from "components/Box";
import bgImage from "assets/images/aircraft-construction.jpg"
import Typography from "components/Typography";

// Images
//const bgImage = "https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_194450239sm.jpeg"

function Header() {
    return (<Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="330px"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${"https://images.unsplash.com/photo-1529119651565-dc15bd8c75fd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
        <Typography fontWeight="bold" color="light" fontSize="56px">Agile Training</Typography>

    </Box>
    );
}

export default Header;
