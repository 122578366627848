import { centToEuro } from "lib/functions";
import i18n from "locals/config";

export const factoringColumns = [

    {
        Header: i18n.t("common:label.name"),
        accessor: "identity[0].name"
    },
    {
        Header: i18n.t("common:label.email"),
        accessor: "identity[0].email"
    },
    {
        Header: i18n.t("common:label.active"),
        accessor: "active",
        width: 100,
        filterable: false
    },
    {
        Header: i18n.t("common:label.clearing"),
        accessor: "clearing",
        width: 100,
        filterable: false
    },
    {
        Header: i18n.t("common:label.cancelled"),
        accessor: "canceled",
        width: 100,
        filterable: false
    },
    {
        Header: i18n.t("common:label.actions"),
        accessor: "actions",
        sortable: false,
        filterable: false
    }
]

export const factoringActions = (addModal, alias, data) => {
    return [
        {
            displayStatus: [undefined],
            title: i18n.t("common:button.factoring"),
            action: (index) => window.location.href = `/my/${alias}/factoring/${data[index].identity_id.$oid}`
        }
    ]
};