import { AircraftsCode } from "aeromate-dashboard/Charter/Aircrafts";
import KeyVault from "./KeyVault";
import BodyCode from "aeromate-dashboard/Charter/Aircrafts/BodyCode";
import { AircraftCodeLock } from "aeromate-dashboard/Charter/Aircrafts/AircraftCodeLock";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { restGet } from "lib/rest";
import moment from "moment";
import { restPost } from "lib/rest";
import Box from "components/Box";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Typography from "components/Typography";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import Button from "components/Button";

export default function Body() {
    const { t } = useTranslation()
    const [mobile, setMobile] = useState()

    const [code, setCode] = useState()

    const model = 'KL1060C2'
    const identifier = '331364'

    function getBody() {
        let start = moment.utc().add(moment().isDST(), 'h')
        let params = {
            start: start.format('YYYY-MM-DD HH:mm'),
            duration: 1,
            lockmodel: model,
            identifier: identifier
        }
        let body = {}
        if (!!mobile) body = { ...body, mobile: mobile }
        restPost((e) => setCode(e?.data), 'netcode/202404091022/code', body, params)
    }

    return (
        <Box pb={8}>
            <Grid container justifyContent="center" >
                <Grid item xs={12} xl={8}>
                    <Box mb={8} textAlign="center">
                        <Box mb={1}>
                            <Typography variant="h3" fontWeight="bold">
                                TEST AIRCRAFT REG {t("dashboard:charter.netcode.lock")}
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight="regular" color="secondary">
                            {t("dashboard:charter.netcode.title")}
                        </Typography>
                    </Box>
                    <Card  >
                        <Box p={3} >
                            {!!code &&
                                <Box mb={8} textAlign="center">
                                    <Box mb={1}>
                                        <Typography variant="h3" fontWeight="bold">
                                            Your unlock code
                                        </Typography>
                                    </Box>
                                    <Typography variant="h1" fontWeight="regular" color="secondary">
                                        {code?.ActualNetcode ?? ""}
                                    </Typography>
                                </Box>
                            }
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}>
                                <Grid item xs={12}>
                                    <Box textAlign="center">
                                        <Typography variant="h4" fontWeight="bold">
                                            {moment().format("LLL")} {"+ 2H"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box textAlign="center">
                                        <Input
                                            label={t("common:label.mobilenumber")}
                                            sx={{ width: "400px" }}
                                            type="text"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            onChange={(e) => setMobile(e.target.value)}
                                            value={mobile}
                                            helperText="Format: +49123456789123"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box textAlign="center">
                                        <Button
                                            variant="gradient"
                                            color="primary"
                                            onClick={() => getBody()}
                                        >
                                            {t("common:button.generate")}{!!mobile && " & " + t("common:button.send")}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box >
    )
    return (
        <div>
            <AircraftCodeLock aircraft={{ reg: "TEST AIRCRAFT REG" }} />
        </div>
    )
}