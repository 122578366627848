import { Stack } from "@mui/material";
import DefaultModal from "components/Modal/DefaultModal";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckrideEditorItem from "../CheckrideModal/components/CheckrideEditorItem";

function ChecklistModal({ list, callback }) {
    const { t } = useTranslation();
    const [checklist, setChecklist] = useState(list)

    const onCheck = useCallback((index, checked, value) => {
        const tmp = [...checklist]
        tmp[index] = { ...checklist[index], checked: checked ?? false, value: value }
        setChecklist(tmp)
    }, [checklist])
    
    return (
        <DefaultModal title={t("")} confirm={{
            text: "Confirm",
            color: "success",
            show: true,
            disabled: false,
            action: () => callback(checklist)
        }}>
            <Stack spacing={1}>
                {checklist?.map((item, index) => <CheckrideEditorItem key={'checklist-item-' + index} disabled={false} {...item} onCheck={(checked, value) => onCheck(index, checked, value)} />)}
            </Stack>
        </DefaultModal >
    );
}

export default ChecklistModal;