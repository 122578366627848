import { Container, Divider, Grid, Icon, Stack } from "@mui/material";
import ContactUsLandingPage from "aeromate-kit/LandingPage/sections/ContactUsLandingPage";
import Box from "components/Box";
import ComingSoon from "components/Common/ComingSoon";
import Typography from "components/Typography";
import ReactMarkdown from "react-markdown";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";
import DefaultBackgroundCard from "components/Cards/BackgroundCards/DefaultBackgroundCard";
import DefaultItem from "components/Items/DefaultItem";
import DefaultItem2 from "components/Items/Itemtemp";

export default function Body(props) {
    const content = props.content.content;
    console.log(content[2].content[0].sectiontext[0].title)
    // Modulo 2 rechts anders links
    return (
        <Box px={{ xs: 3, lg: 0 }}>
            <ArrowBackIcon onClick={() => window.location.href = `/agile-trainings/`} fontSize="large" fontWeight="bold" sx={{ cursor: "pointer", marginLeft: "16px" }}></ArrowBackIcon>
            <Container>
                <Grid container spacing={3} sx={{ marginTop: "-52px" }}>
                    <Grid item xs={12} spacing={3} mb={5}>
                    </Grid>

                    {content.map((item, key) => {

                        if (key > 0) {
                            if (key % 2 == 0) {


                                return (
                                    <Grid container spacing={6} mb={12} ml={0} justifyContent="center" alignItems="center">

                                        <Grid item xs={12} md={12}>
                                            <Box component="section" py={12}>
                                                <Container>
                                                    <Grid
                                                        container
                                                        item
                                                        flexDirection="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        xs={10}
                                                        lg={5}
                                                        sx={{ mx: "auto", textAlign: "center" }}
                                                    >
                                                        <Box
                                                            width="3rem"
                                                            height="3rem"
                                                            borderRadius="lg"
                                                            shadow="md"
                                                            variant="gradient"
                                                            bgColor="info"
                                                            color="white"
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                         <Icon>preview</Icon>
                                                        </Box>
                                                        <Typography variant="h3" mt={3}>
                                                            {content[2].title}
                                                        </Typography>
                                                        <Typography variant="body2" color="text">
                                                            {content[2].description}
                                                        </Typography>
                                                    </Grid>
                                                    {content[2].content.map((card, index) =>
                                                        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>

                                                            <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
                                                                <DefaultBackgroundCard
                                                                    height="500px"
                                                                    image="https://media.westeurope.aero-services.net/aeromate/header/AdobeStock_194450239sm.jpeg"
                                                                    label="Scrum"
                                                                    title={card.section}
                                                                    description=""
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
                                                                <Stack spacing={3} direction="column">
                                                                    {card.sectiontext.map((card, index) =>
                                                                        <Box>
                                                                            <Typography fontWeight="bold">{card.title}</Typography>
                                                                            <Typography variant="body2" >{card.description}</Typography>
                                                                        </Box>
                                                                    )}

                                                                </Stack>
                                                            </Grid>
                                                        </Grid>)}
                                                    <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
                                                    <Grid
                                                        container
                                                        item
                                                        flexDirection="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        xs={10}
                                                        lg={8}
                                                        sx={{ mx: "auto", textAlign: "center" }}
                                                    >


                                                        <Typography variant="h3" mt={3}>
                                                            {content[2].section}
                                                        </Typography>
                                                        <Typography variant="body2" color="text">
                                                            {content[2].des}
                                                        </Typography>
                                                    </Grid>


                                                </Container>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )

                            }
                            else {

                                return (
                                    <Grid container spacing={6} mb={12} ml={0} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} md={12}  >
                                            <Box component="section" py={12}>
                                                <Container>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        justifyContent="center"
                                                        mx="auto"
                                                        textAlign="center"
                                                        pb={6}
                                                    >
                                                        <Typography variant="h2" mb={1}>
                                                            {item.title}
                                                        </Typography>
                                                        <Typography variant="body1" color="text">
                                                            {item.description}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        {item.list.map((card, index) =>
                                                            <Grid item xs={12} md={6} lg={4}>
                                                                <SimpleInfoCard
                                                                    color="primary"
                                                                    icon={card.icon}
                                                                    title={card.title}
                                                                    description={card.description}
                                                                    direction="left"
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Container>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={12}>

                                            <Box
                                                component="img"
                                                src={item.image}
                                                alt={item.title}
                                                borderRadius="xl"
                                                shadow="xl"
                                                width="100%"
                                                position="relative"
                                                zIndex={1}
                                                sx={{ objectFit: "cover", height: "500px", bgcolor: "#F0F2F5" }}
                                            />

                                        </Grid>

                                    </Grid>)
                            }
                        }

                    })}


                </Grid>
                <ContactUsLandingPage></ContactUsLandingPage>
            </Container>
        </Box >
    )
}

