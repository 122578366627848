import Banner from "./sections/Banner";
import Information from "./sections/Information";
import Presentation from "./sections/Presentation";
import Pricing from "./sections/Pricing";
import KeyvaultHelmet from "components/Helmets/KeyvaultHelmet";

export default function Body() {
    return (
        <div>
            <KeyvaultHelmet />
            <Banner />
            <Information />
            <Presentation />
            <Pricing />
        </div>
    )
}