import React, { useState, useContext, createContext } from "react";

const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
    const [modalStack, setModalStack] = useState([])

    function addModal(newModal) {
        var tmp = [...modalStack]
        tmp.push(newModal)
        setModalStack(tmp)
    }

    function removeModal() {
        var tmp = [...modalStack]
        tmp.pop()
        setModalStack(tmp)
    }

    return (
        <ModalContext.Provider value={{ addModal, removeModal }}>
            {modalStack.map((modal, i) => <div key={"modal-" + i}>{modal}</div>)}
            {children}
        </ModalContext.Provider>
    )
}

export const useModal = () => useContext(ModalContext);