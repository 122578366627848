import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from 'components/Typography';
import { IconButton, Toolbar } from '@mui/material';

function DataTableHeaderToolbar(props) {
    const { numSelected, children } = props;

    if (numSelected <= 0)
        return null
    return (
        <Toolbar
            sx={{
                width: '100%',
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => theme.palette.info.focus
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                color="white"
                variant="subtitle1"
                component="div"
            >
                {numSelected} selected
            </Typography>
            {children}
        </Toolbar>
    );
}

DataTableHeaderToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default DataTableHeaderToolbar