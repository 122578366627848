import { Fragment, useEffect, useState } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { getBlobImageUrl } from "lib/functions";
import PlaceHolderImage from "assets/images/aircraft.jpg";
import { Grid, Stack } from "@mui/material";
import DefaultActions from "./DefaultActions";
import { useTranslation } from "react-i18next";
import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined } from "@mui/icons-material";
import { formatCurrency } from "lib/legalInfo";
import { icaoToCountry } from "lib/functions/country";

function DefaultAircraftCard({ image, title, description, categories, aircraft, legalObject }) {
    const { t } = useTranslation();
    const [aircraftImage, setAircraftImage] = useState(PlaceHolderImage);
    const [currentTab, setCurrentTab] = useState([])
    const [lenght, setLenght] = useState(0)
    const [maxLenght, setMaxLenght] = useState(0)
    const [charterRate, setCharterRate] = useState();
    const [currenc, setCurrency] = useState();
    const [legalEntity, setLegalEntity] = useState();

    let len = 0;

    useEffect(() => {
        if (!!aircraft?.charter) {
            setCurrency(aircraft.charter[0].currency)
            setLegalEntity(aircraft.charter[0].legal_entity)

        } else {
            setCurrency(legalObject?.currency)
            setLegalEntity(legalObject?.legalEntity)
        }
    }, [legalObject]);
     
    //Setting Preview Image
    useEffect(() => {
        setCharterRate(getAircraftCharterRate())

        if (!!image) {
            if (_.values(image).length == 0) {
                setAircraftImage(PlaceHolderImage)
            } else {
                if (Array.isArray(image)) {
                    setCurrentTab(image)
                    setMaxLenght(image.length)
                }
                else if (typeof image == "object") {
                    setCurrentTab(_.values(image))
                    setMaxLenght(_.values(image).length)
                }

                if (typeof image === "string") {
                    setAircraftImage(image)
                } else {

                    if (image) {
                        let tmp = _.values(image)[0]
                        if (Array.isArray(image)) {
                            tmp = image[0]
                        }
                        if (typeof tmp === "string") {
                            setAircraftImage(tmp)
                        } else {
                            setAircraftImage(getBlobImageUrl(tmp))
                        }
                    }
                }
            }
        }
    }, [image]);

    function getAircraftCharterRate() {
        if (!!aircraft.rates) {
            var res = _.find(aircraft.rates, { 'flight_type': 'charter_self' })
            if (!!res && !!res.unit_amount) {
                return res.unit_amount
            }
        }
        return undefined;
    }

    const next = () => {
        len = lenght + 1;
        setLenght(len + 1)
        if (Array.isArray(image)) {
            setAircraftImage(currentTab[len % maxLenght])
        }
        else {
            setAircraftImage(getBlobImageUrl(currentTab[len % maxLenght]))
        }

    }
    const prev = () => {
        len = lenght - 1;
        setLenght(len)
        if (Array.isArray(image)) {
            setAircraftImage(currentTab[len % maxLenght])
        }
        else {
            setAircraftImage(getBlobImageUrl(currentTab[len % maxLenght]))
        }
    }

    return (
        <Card style={{ height: "100%", objectFit: "cover" }}>
            <Box position="relative" borderRadius="lg" mx={2} mt={-3} >
                <Box
                    component="img"
                    src={aircraftImage}
                    alt={title}
                    borderRadius="lg"
                    shadow=""
                    width="100%"
                    position="relative"
                    zIndex={1}
                    sx={{ objectFit: "cover" }}
                />
                <Box
                    borderRadius="lg"
                    shadow=""
                    width="100%"
                    height="100%"
                    position="absolute"
                    left={0}
                    top={0}
                    sx={{
                        backgroundImage: `url(${aircraftImage})`,
                        transform: "scale(0.94)",
                        filter: "blur(12px)",
                        backgroundSize: "cover",
                    }}
                />
                <Box
                    borderRadius="md"
                    position="absolute"
                    component="img"
                    width="24px"
                    height="24px"
                    src={`https://aeromatestatic.blob.core.windows.net/img/flags/1x1/${icaoToCountry(aircraft?.homebase)}.svg`}
                    alt={aircraft?.reg_country}
                    right={4}
                    top={4}
                    zIndex={2}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `https://aeromatestatic.blob.core.windows.net/img/flags/1x1/${legalEntity === "aeromate.us" ? "us" : "eu"}.svg`
                    }}
                />

                {maxLenght > 1 && (


                    <Box  >
                        <ArrowBackIosNewOutlined
                            color="info"
                            onClick={() => prev()}
                            sx={{ float: "left", position: "absolute", cursor: "pointer", top: "40%", left: 2, cursor: "pointer", zIndex: "100" }}
                        >
                        </ArrowBackIosNewOutlined>

                        <ArrowForwardIosOutlined
                            size="small"
                            color="info"
                            onClick={() => next()}
                            sx={{ float: "right", position: "absolute", cursor: "pointer", top: "40%", right: 2, cursor: "pointer", zIndex: "100" }}
                        >
                        </ArrowForwardIosOutlined>

                    </Box>

                )}


            </Box>
            <Box style={{ height: "100%" }} p={3} mt={-2}>
                <Stack
                    style={{ height: "100%" }}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    {categories.length > 0 && (
                        <Typography
                            display="block"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            mb={0.75}
                        >
                            {categories.map((category, i) => (
                                <Fragment key={category}>{category} • </Fragment>
                            ))}
                            {aircraft?.operational_data_setting?.wet_or_dry?.label && <Fragment key={"wetdry"}>{aircraft.operational_data_setting.wet_or_dry.label} </Fragment>}

                        </Typography>
                    )}

                    <Typography display="inline" variant="h5" fontWeight="bold">
                        {title}
                    </Typography>
                    <Box mt={1} mb={3} style={{ height: "100%" }}>
                        <Typography variant="body2" component="p" color="text" >
                            {description.length > 255 ? description.substring(0, 252) + "..." : description}
                        </Typography>
                        <Box mb={1}>
                            <Typography >
                                {aircraft?.operational_data_setting?.wet_or_dry?.label ? (

                                    <Typography variant="h6" fontWeight="medium"> {t('common:label.charterPrice')} / {aircraft.operational_data_setting.wet_or_dry.label}</Typography>
                                ) : (

                                    <Typography variant="h6" fontWeight="medium"> {t('common:label.charterPrice')}</Typography>

                                )}
                            </Typography>
                        </Box>

                        {!!charterRate && !!currenc ? (
                            <Typography variant="h3" color="dark">

                                {formatCurrency(charterRate * 60 / 100, currenc) ?? 0}
                                <Typography display="inline" component="small" variant="h5" color="inherit">
                                    / {legalEntity === "aeromate.us" ? t("common:label.exclusiv_tax") : t("common:label.inclusiv_vat")}
                                </Typography>
                            </Typography>
                        ) : (

                            <Typography display="inline" component="small" variant="h5" color="inherit">
                                {t("common:label.onRequest")}
                            </Typography>
                        )

                        }
                    </Box>
                    <Grid
                        container
                        style={{ width: "100%" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <DefaultActions aircraft={aircraft} /></Grid>
                        {aircraft.reg && !aircraft.external_redirect &&
                            <Grid item>
                                <Box p={1}>
                                    <Button
                                        component={Link}
                                        to={"/aircraft/" + aircraft.reg}
                                        variant="outlined"
                                        size="small"
                                        color={"info"}
                                    >
                                        {t("common:button.details")}
                                    </Button>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Stack>
            </Box>
        </Card >
    );
}

// Setting default props for the DefaultAircraftCard
DefaultAircraftCard.defaultProps = {
    categories: [],
    description: "",
};


Array.prototype.current = 0;
// Typechecking props for the DefaultAircraftCard
DefaultAircraftCard.propTypes = {
    image: PropTypes.object,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    aircraft: PropTypes.object.isRequired,
};

export default DefaultAircraftCard;
