import { Add, Delete } from "@mui/icons-material";
import { Divider, Skeleton, Stack } from "@mui/material";
import Box from "components/Box";
import Button from "components/Button";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, isDropAnimating, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    opacity: isDragging ? 0.5 : 1,

    // styles we need to apply on draggables
    ...draggableStyle
});

export default function DragBox({ children, remove, addAfter, dragging, draggableId, ...rest }) {

    return (
        <Draggable {...rest} draggableId={draggableId}>
            {(provided, snapshot) => (
                <Stack direction="column"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        snapshot.isDropAnimating,
                        provided.draggableProps.style
                    )}
                >
                    <Stack direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Box p={1} width="100%">
                            {children}
                        </Box>
                        <Button onClick={remove} iconOnly color="error" >
                            <Delete />
                        </Button>
                    </Stack>
                    <Divider
                        sx={{
                            background: "none",
                            margin: `0 0 ${grid / 2}px 0`,
                            height: "100%",
                            opacity: dragging ? 0 : 1
                        }}
                    >
                        <Button onClick={addAfter} iconOnly color="dark" size="small">
                            <Add />
                        </Button>
                    </Divider>
                </Stack>
            )
            }
        </Draggable >

    );
}