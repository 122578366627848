import * as React from 'react';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from 'components/Button';
import { useState } from 'react';
import { useEffect } from 'react';

export default function FudgeButtonGroup({ onChange, value, defaultValue, disabled }) {
    const [active, setActive] = useState(value ?? defaultValue);

    useEffect(() => {
        if (!disabled) {
            !!onChange && onChange({ target: { value: active } })
        }
    }, [active]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                    m: 1,
                },
            }}
        >
            <ButtonGroup size="small" aria-label="small button group">
                <Button disabled={disabled} variant={active !== 0 ? "contained" : "gradient"} size="small" color={active !== 0 ? "default" : "success"} onClick={() => setActive(0)}>pass</Button>
                <Button disabled={disabled} variant={active !== 1 ? "contained" : "gradient"} size="small" color={active !== 1 ? "default" : "warning"} onClick={() => setActive(1)}>-</Button>
                <Button disabled={disabled} variant={active !== 2 ? "contained" : "gradient"} size="small" color={active !== 2 ? "default" : "error"} onClick={() => setActive(2)}>fail</Button>
            </ButtonGroup>
        </Box>
    );
}