import { useCallback, useEffect, useState } from "react";
import { Card, Grid, Stepper, Step, StepLabel, Stack } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import OperationalStep from "./sections/OperationalStep";
import JourneyStep from "./sections/JourneyStep";
import FuelStep from "./sections/FuelStep";
import { restPost } from "lib/rest";
import { useParams } from "react-router-dom";
import AeroLoading from "components/Progress/AeroLoading";
import { restGet } from "lib/rest";
import { convertFrontendToTimeDatabase } from "lib/functions";
import { useModal } from "hooks/modalHook";
import LoadingModal from "components/Modal/LoadingModal";
import { restPut } from "lib/rest";
import LockIcon from "@mui/icons-material/Lock";
import { convertTimeDatabaseToFrontend } from "lib/functions";
import SummaryStep from "./sections/SummaryStep";

export default function Body() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true)
    const [stepData, setStepData] = useState({})
    const { booking } = useParams();
    const [teclogBooking, setTeclogBooking] = useState();
    const [teclog, setTeclog] = useState();
    const { addModal, removeModal } = useModal()

    const restCallbackTecLog = useCallback((resp) => {
        let data = {}
        if (!!resp && !!resp.data && !!resp.data[0]) data = resp.data[0]
        if (!!data.flight_data) data.flight_data = data.flight_data.map((entry) => {
            return {
                ...entry,
                aibt: convertTimeDatabaseToFrontend(entry.aibt),
                aldt: convertTimeDatabaseToFrontend(entry.aldt),
                aobt: convertTimeDatabaseToFrontend(entry.aobt),
                atot: convertTimeDatabaseToFrontend(entry.atot),
                dof: convertTimeDatabaseToFrontend(entry.dof),
            }
        })
        if (!!data.ops_data) {
            if (!!data.ops_data.ops_counter_start) data.ops_data.ops_counter_start = convertTimeDatabaseToFrontend(data.ops_data.ops_counter_start)
            if (!!data.ops_data.ops_counter_end) data.ops_data.ops_counter_end = convertTimeDatabaseToFrontend(data.ops_data.ops_counter_end)
        }
        setTeclog(data)
    }, [])

    useEffect(() => {
        const fetch = async () => {
            function restCallbackBooking(resp) {
                let data = {}
                if (!!resp && !!resp.data && !!resp.data[0]) data = resp.data[0]
                setTeclogBooking(data)
            }
            setIsLoading(true)
            await restGet(restCallbackBooking, "booking/" + booking, { include: 'identity,aircraft' });
            await restGet(restCallbackTecLog, "booking/" + booking + "/teclog")
            setIsLoading(false)
        }
        !!booking && fetch()
    }, [booking])

    const setData = (data) => {
        setStepData({ ...stepData, [content[activeStep].id]: data })
    }

    const content = [
        {
            id: "operational",
            label: t("dashboard:logtimes.operational.steplabel"),
            component: <OperationalStep setData={setData} booking={teclogBooking} teclog={teclog} />,
            isValid: () => {
                return (
                    !!stepData &&
                    !!stepData.operational &&
                    !!stepData.operational.ops_data &&
                    !!stepData.operational.ops_data.ops_counter_start &&
                    !!stepData.operational.ops_data.ops_counter_end &&
                    stepData.operational.ops_data.ops_counter_start < stepData.operational.ops_data.ops_counter_end &&
                    !!stepData.operational.pob_data &&
                    _.some(stepData.operational.pob_data, (e) => !!e.name && (!!e.duty && e.duty.name && ['PIC', 'SPIC', 'FI'].includes(e.duty.name))))
            }
        },
        {
            id: "journey",
            label: t("dashboard:logtimes.journey.steplabel"),
            component: <JourneyStep setData={setData} booking={teclogBooking} teclog={teclog} />,
            isValid: () => {
                return (
                    !!stepData &&
                    !!stepData.journey &&
                    !!stepData.journey.length > 0 &&
                    _.every(stepData.journey, (e) => {
                        return (
                            !!e.dof &&
                            !!e.orig &&
                            !!e.dest &&
                            !!e.aobt &&
                            !!e.atot &&
                            !!e.aldt &&
                            !!e.aibt &&
                            e.ldg > 0 &&
                            (e.aobt < e.atot) &&
                            (e.atot < e.aldt) &&
                            (e.aldt < e.aibt)
                        )
                    })
                )
            }
        },
        {
            id: "fuel",
            label: t("dashboard:logtimes.fuel.steplabel"),
            component: <FuelStep setData={setData} teclog={teclog} />,
            isValid: () => { return true; }
        },
        {
            id: "general",
            label: t("dashboard:logtimes.general.steplabel"),
            component: <SummaryStep booking={teclogBooking} teclog={teclog} />,
            isValid: () => { return true; }
        }
    ]

    async function handleDraft() {
        addModal(<LoadingModal />)
        await restPost(restCallbackTecLog, "booking/" + booking + "/teclog", getBody())
        removeModal()
        //window.location.href = "/my/account/cockpit"
    }

    async function handleNext() {
        addModal(<LoadingModal />)
        var teclog = await restPost(null, "booking/" + booking + "/teclog", getBody())
        if (content[activeStep].id === "operational" && teclog) {
            if (stepData?.operational?.ops_img_data?.ops_counter_start_img) {
                const res = await restPost(null, 'teclog/' + teclog.data[0]._id.$oid + '/images/opshandover', stepData.operational.ops_img_data.ops_counter_start_img, null, { "Content-Type": "image/png" })
            }

            if (stepData?.operational?.ops_img_data?.ops_counter_end_img) {
                const res = await restPost(null, 'teclog/' + teclog.data[0]._id.$oid + '/images/opsreturn', stepData.operational.ops_img_data.ops_counter_end_img, null, { "Content-Type": "image/png" })
            }

        }
        await restGet(restCallbackTecLog, "booking/" + booking + "/teclog")
        removeModal()
        setActiveStep(activeStep + 1)
    }

    async function handleBack() {
        addModal(<LoadingModal />)
        await restPost(restCallbackTecLog, "booking/" + booking + "/teclog", getBody())
        removeModal()
        setActiveStep(activeStep - 1)
    }

    async function handleFinish() {
        addModal(<LoadingModal />)
        await restPost(null, "booking/" + booking + "/teclog", getBody())
        await restPut(null, "booking/" + booking + "/status/clearing", getBody())
        removeModal()
        window.location.href = "/my/account/cockpit"
    }

    const getBody = useCallback(() => {
        var body = {}
        switch (content[activeStep].id) {
            case "operational":
                if (stepData.operational.pob_data) {
                    body.pob_data = stepData.operational.pob_data
                    body.pob_data.length > 1 && body.pob_data.pop()
                }
                if (stepData.operational.ops_data) {
                    body.ops_data = stepData.operational.ops_data
                    body.ops_data.ops_counter_start = convertFrontendToTimeDatabase(body.ops_data.ops_counter_start)
                    body.ops_data.ops_counter_end = convertFrontendToTimeDatabase(body.ops_data.ops_counter_end)
                }
                break;
            case "journey":
                body.flight_data = stepData.journey.map((entry) => {
                    return {
                        ...entry,
                        aibt: convertFrontendToTimeDatabase(entry.aibt),
                        aldt: convertFrontendToTimeDatabase(entry.aldt),
                        aobt: convertFrontendToTimeDatabase(entry.aobt),
                        atot: convertFrontendToTimeDatabase(entry.atot),
                        dest: _.isString(entry.dest) ? entry.dest : (!!entry && entry?.dest?.icao),
                        dof: convertFrontendToTimeDatabase(entry.dof),
                        orig: _.isString(entry.orig) ? entry.orig : (!!entry && entry?.orig?.icao),
                    }
                })
                break;
            case "fuel":
                if (stepData.fuel) { body.fuel_data = stepData.fuel }
                break;
            case "general":
                if (stepData.general) { body.general_info = stepData.general }
                break;

            default:
                break;
        }
        return body;
    }, [stepData, teclog])

    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === content.length - 1;

    if (teclog && teclog.status && teclog.status === "draft" && 1 == 2) {
        return (
            <Box pb={8}>
                <Grid container justifyContent="center" >
                    <Grid item xs={12} xl={8}>
                        <Box mb={8} textAlign="center">
                            <LockIcon color="error" sx={{ width: "4em", height: "4em" }} />
                            <Box mb={1}>
                                <Typography variant="h3" fontWeight="bold">
                                    {t("dashboard:logtimes.noaccess.title")}
                                </Typography>
                            </Box>
                            <Typography variant="h5" fontWeight="regular" color="secondary">
                                {t("dashboard:logtimes.noaccess.subtitle")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    }

    return (
        <Box pb={8}>
            <Grid container justifyContent="center" >
                <Grid item xs={12} xl={8}>
                    <Box mb={8} textAlign="center">
                        <Box mb={1}>
                            <Typography variant="h3" fontWeight="bold">
                                {t("dashboard:logtimes.title")}
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight="regular" color="secondary">
                            {t("dashboard:logtimes.subtitle")}
                        </Typography>
                    </Box>
                    {isLoading && <AeroLoading />}
                    {!isLoading &&
                        <Card>
                            <Box mt={-3} mx={2}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {content.map((item) => (
                                        <Step key={item.label}>
                                            <StepLabel>{item.label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <Box p={2}>
                                <Box>
                                    {content[activeStep].component}
                                    <Box mt={3} width="100%" display="flex" justifyContent="space-between">
                                        {activeStep === 0 ? (
                                            <Box />
                                        ) : (
                                            <Button
                                                disabled={!!content[activeStep].disbaledButtons}
                                                variant="outlined"
                                                color="dark"
                                                onClick={handleBack}
                                            >
                                                {t("common:button.back")}
                                            </Button>
                                        )
                                        }
                                        <Stack direction={"row"} spacing={2}>
                                            < Button
                                                disabled={!!content[activeStep].disbaledButtons}
                                                variant="gradient"
                                                color="dark"
                                                onClick={handleDraft}
                                            >
                                                {t("common:button.draft")}
                                            </Button>
                                            <Button
                                                disabled={!content[activeStep].isValid() || !!content[activeStep].disbaledButtons}
                                                variant="gradient"
                                                color="dark"
                                                onClick={!isLastStep ? handleNext : handleFinish}
                                            >
                                                {!isLastStep ? t("common:button.next") : t("common:button.submitClearing")}
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    }
                </Grid>
            </Grid>
        </Box >
    );
}