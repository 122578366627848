import { useState } from "react";
import Card from "@mui/material/Card";
import Box from "components/Box";

function CardContainer({ children }) {
  

  return (
    <Box sx={{ perspective: "50rem", height: "100%" }} >
      <Card
        sx={{
          height: "100%",
          backgroundColor: "transparent",
          boxShadow: "none",
          position: "relative",
          transition: "all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1)",
        }}
      >
        {children}
      </Card>
    </Box>
  );
}

export default CardContainer;
