import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Button from "components/Button";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";

function Introduction({ pagename }) {
    const { t } = useTranslation()
    return (
        <Box
            variant="gradient"
            bgColor="primary"
            position="relative"
            borderRadius="xl"
            mx={{ xs: 2, xl: 3, xxl: 16 }}
            mt={-32}
            py={10}
            px={3}
            sx={{ overflow: "hidden" }}
        >
            <Box
                component="img"
                src={bgPattern}
                alt="pattern-lines"
                position="absolute"
                top={0}
                left={0}
                width="100%"
                opacity={0.6}
            />
            <Container sx={{ position: "relative" }}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={7} lg={5} py={{ xs: 0, sm: 6 }} mr="auto" position="relative">
                        <Typography variant="h3" color="white" mb={1} >
                            {t(pagename + ":introduction.title")}
                        </Typography>
                        <Typography variant="h1" color="white" mb={6}>
                            {t(pagename + ":introduction.subtitle")}
                        </Typography>
                        <Typography variant="body2" color="white" mb={6}>
                            {t(pagename + ":introduction.description")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} display={{ md: "none", lg: "block" }} position="absolute" left="50%" mr={-32} width="75%">
                        <Box
                            borderRadius="xl"
                            component="img"
                            src={t(pagename + ":introduction.image")}
                            alt="introduction image"
                            width="100%"
                            display={{ xs: "none", md: "block" }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box >
    );
}

export default Introduction;
