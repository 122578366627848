import Container from "@mui/material/Container";
import Box from "components/Box";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";

// Images
const bgImage = "https://aeromatestatic.blob.core.windows.net/img/sustainability_plane_forest.jpg"

function Header() {
    const { t } = useTranslation();

    return (<Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="512px"
        sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
        <Typography fontWeight="bold" color="light" fontSize="56px">{t("sustainabiltyPage:header.title")}</Typography>
        <Typography color="primary" fontWeight="bold" fontSize="42px">{t("sustainabiltyPage:header.description")}</Typography>

    </Box>
    );
}

export default Header;