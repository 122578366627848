// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "components/Cards/TeamCards/HorizontalTeamCard";
import { useTranslation } from "react-i18next";

function Team() {
    const { t } = useTranslation();
    return (
        <Box
            id="team"
            component="section"
            variant="gradient"
            bgColor="dark"
            position="relative"
            py={6}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        >
            <Container>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch" >
                    <Grid item xs={12} md={8} sx={{ mb: 6 }}>
                        <Typography variant="h3" color="white">
                            {t("aboutUsPage:team.title")}
                        </Typography>
                        <Typography variant="body2" color="white" opacity={0.8}>
                            {t("aboutUsPage:team.subtitle")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}
                    justifyContent="center">
                    {t("aboutUsPage:team.member", { returnObjects: true }).map((x, i) =>
                        <Grid item xs={12} lg={6} key={"team-grid-item-" + i}>
                            <Box mb={1}>
                                <HorizontalTeamCard
                                    image={x.src}
                                    name={x.name}
                                    position={{ color: "primary", label: x.job }}
                                    description={x.slogan}
                                    flight_cert={x.flight_cert}
                                />
                            </Box>
                        </Grid>)
                    }
                </Grid>
            </Container>
        </Box>
    );
}

export default Team;