import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CharterShop from "./CharterShop";
import CharterCourses from "./CharterCourses";

export default function CharterPage() {
    const { t } = useTranslation();
    return (
        <KitLayout header={<Header />} title={t("routes:charter.title")}>
            <Body />
        </KitLayout>
    )
}

/**
 * 
 * @returns Minimal Charter Shop Page for external use
 */
export function MinimalCharterShop() {
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.has("backgroundcolor")) document.body.style.backgroundColor = '#' + query.get("backgroundcolor")
        if (query.has("border")) document.body.style.backgroundColor = query.get("border")
    }, []);

    return <CharterShop />
}

/**
 * 
 * @returns Minimal Charter Courses PAge for external use
 */
export function MinimalCharterCourses() {
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.has("backgroundcolor")) document.body.style.backgroundColor = '#' + query.get("backgroundcolor")
        if (query.has("border")) document.body.style.backgroundColor = query.get("border")
    }, []);

    return <CharterCourses />
}