import { useEffect, useState } from "react";
import ImgsViewer from "react-images-viewer";
import Stack from "@mui/material/Stack";
import Box from "components/Box";
import PlaceHolderImage from "assets/images/aircraft.jpg";
import { getBlobImageUrl } from "lib/functions";
import _ from "lodash";

function ProductImages({ aircraft }) {
    const [currentImage, setCurrentImage] = useState();
    const [imgsViewer, setImgsViewer] = useState(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
    const [images, setImages] = useState([{ src: PlaceHolderImage }])

    //Setting Preview Image
    /* useEffect(() => {
        if (!!aircraft && !!aircraft.image && !!aircraft.image.preview) {
            setImages([{ src: getBlobImageUrl(aircraft.image.preview) }])
        }
    }, [aircraft]);
 */
    useEffect(() => {
        if (!!aircraft && !!aircraft.image) {
            setImages([]);
            _.forEach(_.values(aircraft.image), function (value, index) {
                setImages(images => [...images, { src: getBlobImageUrl(value) }])
            })

        }
    }, [aircraft]);
    useEffect(() => {
        setCurrentImage(images[0].src)
    }, [images])

    const handleSetCurrentImage = ({ currentTarget }) => {
        setCurrentImage(currentTarget.src);
        setImgsViewerCurrent(Number(currentTarget.id));
    };

    const openImgsViewer = () => setImgsViewer(true);
    const closeImgsViewer = () => setImgsViewer(false);
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

    return (
        <Box>
            <ImgsViewer
                imgs={images}
                isOpen={imgsViewer}
                onClose={closeImgsViewer}
                currImg={imgsViewerCurrent}
                onClickPrev={imgsViewerPrev}
                onClickNext={imgsViewerNext}
                backdropCloseable
            />

            <Box
                component="img"
                src={currentImage}
                alt="aircraft preview image"
                shadow="lg"
                borderRadius="lg"
                width="100%"
                onClick={openImgsViewer}
            />
            <Box mt={2} pt={1}>
                <Stack direction="row" spacing={3} p={2} sx={{ overflowX: "auto" }}>
                    {!!images && images.length > 1 && images.map((x, i) => <Box
                        key={"aircraft-img-" + i}
                        component="img"
                        id={i}
                        src={x.src}
                        alt={"aircraft preview image small " + i}
                        borderRadius="lg"
                        shadow=""
                        height="5rem"
                        minHeight="5rem"
                        sx={{ cursor: "pointer", objectFit: "cover" }}
                        onClick={handleSetCurrentImage}
                    />)}
                </Stack>
            </Box>
        </Box>
    );
}

export default ProductImages;
