import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { restGet } from 'lib/rest';
import Input from "components/Input";

export default function CertificationSearch({ label, onChange, value, ...rest }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            const callback = (res) => {
                if (Array.isArray(res))
                    setOptions(res ?? []);
            }
            await restGet(callback, `dataset/aircraftCertificationType`)
            setLoading(false)
        }
        fetch()
    }, [])

    return (
        <Autocomplete
            {...rest}
            open={open}
            value={value}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            getOptionLabel={(option) => option.certification ?? ""}
            isOptionEqualToValue={(option, value) => option.certification === value.certification}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <Input
                    {...params}
                    label={label ?? "lable"}
                    type="text"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}