import { useState, useCallback, useEffect } from "react"
import { FormControlLabel, Checkbox, Stack, Divider, Card } from "@mui/material";
import Input from "components/Input";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography";

export default function CheckrideEditorItem(props) {
    const { text, type, goal, disabled, checked, onCheck, onChange, value } = props;
    const { t } = useTranslation();
    const [inputDefault, setInputDefault] = useState(!!value ? value : type === 30 ? props.default[0] : props.default);

    const inputs = useCallback(() => {
        switch (type) {
            case 10:
                return (<Input disabled={disabled} sx={{ width: { xs: "256px" } }} label={t("common:label.default")} type="number" onChange={(e) => setInputDefault(e.target.value)} value={inputDefault} />)
            case 20:
                return (<FormControlLabel sx={{ width: { xs: "256px" } }} control={<Checkbox disabled={disabled} onChange={(e) => setInputDefault(e.target.checked)} checked={inputDefault} />} label={t("common:label.default")} />)
            case 30:
                return (<Input disabled={disabled} sx={{ width: { xs: "128px" } }} label={t("common:label.default")} type="number" onChange={(e) => setInputDefault(e.target.value)} value={inputDefault} />)
            case 40:
                return (<Input disabled={disabled} sx={{ width: { xs: "256px" } }} label={t("common:label.default")} type="text" onChange={(e) => setInputDefault(e.target.value)} value={inputDefault} />)
            default:
                return null
        }
    }, [type, goal, inputDefault])

    useEffect(() => {
        if (!disabled) {
            if (type === 30) { onCheck(inputDefault >= goal[0] && inputDefault <= goal[1], inputDefault) }
            else if (type !== 0) { onCheck(inputDefault === goal, inputDefault) }
            else { onCheck(inputDefault, inputDefault) }
        }
    }, [inputDefault])

    return (
        <Card>
            <Stack direction="row" p={2}>
                {type === 0 ?
                    <Checkbox disabled={disabled} checked={checked ?? false} onChange={(e) => setInputDefault(e.target.checked)} />
                    :
                    <Checkbox disabled={disabled} checked={checked ?? false} />
                }
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={3}>
                    <Typography variant={"button"} sx={{ minWidth: { xs: "320px" }, width: "100%" }} >{text} </Typography>
                    {inputs()}
                </Stack>
            </Stack>
        </Card>
    )
}