import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Stack, Grid, Card, Tooltip, Divider } from "@mui/material";

import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";

import { getBlobImageUrl } from "lib/functions";

import PlaceHolderImage from "assets/images/aircraft.jpg";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";

function DashboardAircraftCard({ image, title, aircraft, alias }) {
    const { t } = useTranslation()
    const [aircraftImage, setAircraftImage] = useState(PlaceHolderImage);
    //Setting Preview Image
    useEffect(() => {
        if (!!image) {

            let tmp = _.values(image)[0]
            setAircraftImage(getBlobImageUrl(tmp))
        }
    }, [image]);

    return (
        <Card style={{ height: "100%" }}>
            <Box position="relative" borderRadius="lg" mx={2} mt={-3}>
                <Box component="a" href={`/aircraft/${aircraft.reg}`} target="_blank" position="absolute" top="8px" right="8px" zIndex={10}><Icon color="primary">launch</Icon></Box>
                <Box
                    component="img"
                    src={aircraftImage}
                    alt={title}
                    borderRadius="lg"
                    shadow=""
                    width="100%"
                    position="relative"
                    zIndex={1}
                    sx={{ objectFit: "cover" }}
                />
                <Box
                    borderRadius="lg"
                    shadow=""
                    width="100%"
                    height="100%"
                    position="absolute"
                    left={0}
                    top={0}
                    sx={{
                        backgroundImage: `url(${aircraftImage})`,
                        transform: "scale(0.94)",
                        filter: "blur(12px)",
                        backgroundSize: "cover",
                    }}
                />
            </Box>
            <Box style={{ height: "100%" }} p={3} mt={-2}>
                <Stack
                    style={{ height: "100%" }}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Typography display="inline" variant="h5" fontWeight="bold">
                        {title}
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        style={{ height: "100%" }}
                    >
                        <Grid item xs={12} md={6} >
                            <Button fullWidth variant="outlined" size="small" color={"info"} href={`/my/${alias}/aircrafts/${aircraft.reg}`}>
                                {t("common:button.edit")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Button fullWidth variant="outlined" size="small" color={"info"} href={"/aircraft/" + aircraft.reg + "/calendar/"}>
                                {t("common:button.reservation")}
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={-3} >
                            <Button fullWidth variant="outlined" size="small" color={"info"} href={`/my/${alias}/aircrafts/${aircraft.reg}/journey-log`}>
                                {t("common:button.journeyLog")}
                            </Button>
                        </Grid>
                       {/*  <Grid item xs={6} md={-3} >
                            <Button fullWidth variant="outlined" size="small" color={"info"} href={`/my/${alias}/aircrafts/${aircraft.reg}/service`}>
                                Service
                            </Button>
                        </Grid> */}
                        <Grid item xs={12} mb={-3}>
                            <Divider><Box mt={-4}>{t("common:label.checklists")}</Box></Divider>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Button fullWidth variant="outlined" size="small" color={"info"} href={`/my/${alias}/aircrafts/${aircraft.reg}/checkride`}>
                                {t("common:button.checklist.checkride")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Button disabled fullWidth variant="outlined" size="small" color={"info"} >
                                {t("common:button.checklist.cockpit")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Button disabled fullWidth variant="outlined" size="small" color={"info"} >
                                {t("common:button.checklist.emergency")}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </Card >
    );
}

// Typechecking props for the DefaultAircraftCard
DashboardAircraftCard.propTypes = {
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    aircraft: PropTypes.object.isRequired,
    alias: PropTypes.string.isRequired
};

export default DashboardAircraftCard;
