import { useTranslation } from 'react-i18next';
import Box from 'components/Box';
import Typography from 'components/Typography';
import { Alert, Stack } from '@mui/material';
import _ from 'lodash';

function MissingInput({ warnings }) {
    const { t } = useTranslation();

    return (
        <Box sx={{ maxHeight: "128px", width: '100%', mb: "15px", overflow: "auto", '& .MuiAlert-message': { overflow: "unset" } }}>
            {(warnings?.ident_check || warnings?.payment || warnings?.document) &&
                < Alert severity='warning' >
                    <Stack spacing={1} >
                        {t("common:warnings.titlePersonal")}
                        {warnings?.ident_check && <Typography fontWeight="bold" variant="caption">{t("common:warnings.missingIdentity")}</Typography>}
                        {warnings?.payment && <Typography fontWeight="bold" variant="caption">{t("common:warnings.missingPaymentMethod")}</Typography>}
                        {warnings?.document && <Typography fontWeight="bold" variant="caption">{t("common:warnings.expiredDocument")}</Typography>}
                    </Stack>
                </Alert>
            }
            {!_.isEmpty(warnings?.restricted) &&
                < Alert severity='warning' >
                    <Stack spacing={1} >
                        {t("common:warnings.titlerestriction")}
                        {warnings?.restricted.map((item, index) =>
                            <Typography key={"restricted-" + index} fontWeight="bold" variant="caption" >{t("common:warnings.restricted", { alias: item })}</Typography>

                        )}
                    </Stack>
                </Alert>
            }
            {(!_.isEmpty(warnings?.legal_info) || !_.isEmpty(warnings?.legal_mail) || !_.isEmpty(warnings?.general_address)) &&
                < Alert severity='warning' >
                    <Stack spacing={1} >
                        {t("common:warnings.titleOrg")}
                        {warnings?.legal_info.map((item, index) =>
                            <Typography key={"legal-missing-" + index} fontWeight="bold" variant="caption" >{t("common:warnings.missingLegal", { alias: item })}</Typography>

                        )}
                        {warnings?.legal_mail.map((item, index) =>
                            <Typography key={"legal-mail-" + index} fontWeight="bold" variant="caption" >{t("common:warnings.missingLegalMail", { alias: item })}</Typography>

                        )}
                        {warnings?.general_address.map((item, index) =>
                            <Typography key={"general-address-" + index} fontWeight="bold" variant="caption" >{t("common:warnings.missingGeneralAddress", { alias: item })}</Typography>

                        )}
                    </Stack>
                </Alert>
            }
            {!_.isEmpty(warnings?.aircraft_rates) &&
                < Alert severity='warning' >
                    <Stack spacing={1} >
                        {t("common:warnings.titleAircraft")}
                        {warnings?.aircraft_rates && Object.keys(warnings?.aircraft_rates).map((item, index) => {
                            return <Typography key={"rates-missing-" + index} fontWeight="bold" variant="caption">{t("common:warnings.missingRates", { reg: item })}</Typography>
                        }
                        )}
                    </Stack>
                </Alert>
            }
        </Box >
    );
}

export default MissingInput;