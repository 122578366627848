import { useState, useEffect } from "react"
import DataTable from "components/Tables/DataTable";
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { addActions } from "lib/functions/tables";
import { checkrideActions, checkrideColumns } from "../tables/checkrideTable";
import { Card, Grid } from "@mui/material";
import { useModal } from "hooks/modalHook";

export default function Checkrides() {
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    const { addModal } = useModal()
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (resp.data.length) {
                    data = resp.data.map(element => {
                        return {
                            id: element._id.$oid,
                            reg: element.reg ?? t("common:label.deleted"),
                            charter: !!element.charter[0] ? element.charter[0].alias : t("common:label.deleted"),
                            comment: element.requested.comment,
                            status: element.status,
                            checklist: element.checklist ? element.checklist : null
                        };
                    });
                } else {
                    data = [
                        {
                            id: resp.data._id.$oid,
                            reg: resp.data.reg ?? t("common:label.deleted"),
                            charter: !!resp.data.charter[0] ? resp.data.charter[0].alias : t("common:label.deleted"),
                            comment: resp.data.requested.comment,
                            status: resp.data.status,
                            checklist: resp.data.checklist ? resp.data.checklist : null
                        }
                    ];
                }
                setTableData({ columns: checkrideColumns, rows: addActions(data, checkrideActions(addModal, data)) })
            }
            setIsLoading(true)
            restGet(restCallback, "checkride")
            setIsLoading(false)
        }
        fetch()
    }, [])

    return (
        <Grid item xs={12}>
            <Card>
                {isLoading && <AeroLoading />}
                {!isLoading && <DataTable canSearch={true} table={tableData} />}
            </Card>
        </Grid>
    )
}