import { useState, useEffect } from "react"
import DataTable from "components/Tables/DataTable";
import { factoringActions, factoringColumns } from "../tables/factoringTable";
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { useParams } from "react-router-dom";
import { addActions } from "lib/functions/tables";
import { Card, Grid } from "@mui/material";

export default function Factoring() {
    const [isLoading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({ columns: [], rows: [] })
    let { alias } = useParams();
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                let data = []
                if (!!resp && !!resp.data) data = resp.data
                setTableData({ columns: factoringColumns, rows: addActions(data, factoringActions(null, alias, data)) })
            }
            setIsLoading(true)
            await restGet(restCallback, 'factoring/' + alias + '/customer');
            setIsLoading(false)
        }
        !!alias && fetch()
    }, [alias])

    return (
        <Grid item xs={12}>
            <Card>
                {isLoading && <AeroLoading />}
                {!isLoading && <DataTable canSearch={true} table={tableData} />}
            </Card>
        </Grid>
    )
}