/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import { Stack } from "@mui/material";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function CharterLegalInfo({ charter, variant, color }) {
    const { t } = useTranslation();
    let specifications = [
        "AGB",
        "Privacy Policy"
    ]

    let componentColor = "white";

    if (variant === "contained") {
        componentColor = color;
    } else if (variant === "gradient" && color === "light") {
        componentColor = "dark";
    }

    const renderSpecifications = specifications.map((specification) => (
        <Box key={specification} display="flex" alignItems="center" pb={2}>
        </Box>
    ));

    return (
        <Card sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <Box variant={variant} bgColor={variant === "gradient" ? color : "white"} height="100%">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Box p={3} lineHeight={1}>
                        <Typography variant="h5" fontWeight="bold" color={componentColor} mb={0.5}>
                            {charter.legal_info.chartername}
                        </Typography>
                        {charter?.address?.map((line, index) => <Typography color={componentColor}>
                            {line}
                        </Typography>)}
                    </Box>
                    <Box p={3} lineHeight={1}>
                        <Button
                            component={Link}
                            to={"/org/" + charter.alias}
                            variant={variant === "gradient" ? "contained" : "gradient"}
                            size="small"
                            color={componentColor}
                            fullWidth
                        >
                            {t('common:button.details')}
                        </Button>
                    </Box>
                </Stack>
                <Divider
                    light={variant === "gradient" || (variant === "gradient" && color === "light")}
                    sx={{ my: 0, opacity: variant === "gradient" ? 0.5 : 0.25 }}
                />
                <Box p={3}>{renderSpecifications}</Box>
            </Box>
        </Card>
    );
}

CharterLegalInfo.defaultProps = {
    color: "dark",
    variant: "gradient",
};

CharterLegalInfo.propTypes = {
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ])
};

export default CharterLegalInfo;