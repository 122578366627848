import { useState, useEffect } from "react"
import { restGet } from "lib/rest";
import AeroLoading from "components/Progress/AeroLoading";
import { useTranslation } from "react-i18next";
import { Skeleton, Stack } from "@mui/material";
import Box from "components/Box";
import DefaultModal from "components/Modal/DefaultModal";
import Autocomplete from "components/Input/AutoComplete"
import _ from "lodash";
import Button from "components/Button";
import Typography from "components/Typography";
import { restPost } from "lib/rest";
import PostCreationModal from "./PostCreationModal";
import { useRefresher } from "hooks/refreshHook";

export default function CreateVoucherModal({ alias }) {
    const { t } = useTranslation();
    const { refresh } = useRefresher();
    const [charter, setCharter] = useState();
    const [duration, setDuration] = useState();
    const [flightType, setFlightType] = useState();
    const [aircraftType, setAircraftType] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [voucherResponse, setVoucherResponse] = useState();

    const [aircraftTypes, setAircraftTypes] = useState();
    useEffect(() => {
        function restCallback(resp) {
            !!resp && !!resp.data && setAircraftTypes(_.uniq(resp.data.map((x) => x.type))/* .map((x) => ({ label: x, type: x })) */)
        }
        restGet(restCallback, 'charter/' + alias + '/aircrafts/')
        restGet(setCharter, 'charter/' + alias)
    }, [])

    const [durations] = useState([
        { value: 30, label: 30 },
        { value: 45, label: 45 },
        { value: 60, label: 60 },
        { value: 75, label: 75 },
        { value: 90, label: 90 },
        { value: 120, label: 120 }
    ]);

    const [flightTypes, setFlightTypes] = useState();
    useEffect(() => {
        function restCallback(resp) {
            !!resp && setFlightTypes(resp.map((ele) => ({ label: t("common:label." + ele.slot), slot: ele.slot })))
        }
        restGet(restCallback, 'dataset/bookingSlot')
    }, [])


    async function generateCode() {
        let body = {
            type: aircraftType,
            duration: duration.value,
            charter_id: charter.data[0]._id.$oid,
            status: "payment pending",
            voucher_type: flightType.slot
        }
        setIsLoading(true)
        await restPost(setVoucherResponse, 'voucher', body)
        setIsLoading(false)
    }

    return (
        <DefaultModal
            title={t("common:modal.voucher.title")}
            confirm={{
                disabled: !voucherResponse,
                show: true,
                text: t("common:button.pay"),
                color: "success",
                modal: !!voucherResponse && !!voucherResponse.data[0] && <PostCreationModal code={voucherResponse.data[0].code} />
            }}
            cancel={{
                text: t("common:button.back"),
                color: "error",
                show: true,
                disabled: false,
                action: () => refresh()
            }}
        >
            <Box>
                {
                    !(!!flightTypes && !!aircraftTypes)
                        ?
                        <Stack direction="column" spacing={2}>
                            <Skeleton variant="rectangular" height={66} />
                            <Skeleton variant="rectangular" height={66} />
                            <Skeleton variant="rectangular" height={66} />
                        </Stack>
                        :
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center" spacing={2}>
                            <Autocomplete
                                fullWidth
                                disablePortal
                                label={t("common:modal.voucher.aircraftType")}
                                options={aircraftTypes ?? []}
                                onChange={setAircraftType}
                            />
                            <Autocomplete
                                fullWidth
                                disablePortal
                                label={t("common:modal.voucher.duration")}
                                options={durations ?? []}
                                onChange={setDuration}
                            />
                            <Autocomplete
                                fullWidth
                                disablePortal
                                label={t("common:modal.voucher.type")}
                                options={flightTypes ?? []}
                                onChange={setFlightType}
                            />
                            <Button disabled={!(flightType && duration && aircraftType)} color="primary" onClick={() => generateCode()}>{t("common:button.generate")}</Button>
                            {isLoading && <AeroLoading />}
                            {!isLoading && !!voucherResponse && <Typography variant="h4">{voucherResponse.data ? voucherResponse.data[0].code : "Something went wrong"}</Typography>}
                        </Stack>
                }
            </Box>
        </DefaultModal >
    )
}