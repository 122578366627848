import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "components/Box";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import DefaultAircraftCard from "components/Cards/AircraftCards/DefaultAircraftCard/DefaultAircraftCard";
import { getLanguageTextWithFallBack } from "lib/functions";
import AeroLoading from "components/Progress/AeroLoading"

export default function AirportAircrafts({ airport }) {
    const [aircrafts, setAircrafts] = useState()
    const [isloading, setIsLoading] = useState(false)
    useEffect(() => {
        const fetch = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setAircrafts(resp.data)
            }
            setIsLoading(true)
            await restGet(restCallback, 'airport/' + airport.icao + "/aircrafts")
            setIsLoading(false)
        }
        !!airport && fetch()
    }, [airport])

    if (!aircrafts|| isloading) {
        return isloading ? <AeroLoading /> : null;
    }
    return (
        <Container>
            <Grid container spacing={3} sx={{ mt: 3 }}
                alignItems="stretch">
                {aircrafts.map((x, i) => (
                    <Grid item xs={12} md={6} lg={4} key={"aircraft-card-" + i} >
                        <Box mt={3} style={{ height: "100%", paddingBottom: "24px" }}>
                            <DefaultAircraftCard
                                image={x.image}
                                title={x.name + " (" + x.reg + ")"}
                                description={getLanguageTextWithFallBack(x.description)}
                                categories={[x.homebase, x.certification, x.class, x.type]}
                                aircraft={x}
                            />
                        </Box>
                    </Grid>
                ))
                }
            </Grid>
        </Container>
    )
}