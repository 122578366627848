import Grid from "@mui/material/Grid";
import Box from "components/Box";
import ShopItemHelmet from "components/Helmets/ShopItemHelmet";
import AeroLoading from "components/Progress/AeroLoading";
import { restGet } from "lib/rest";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ShopItemImage from "./sections/ShopItemImage";
import ShopItemInfo from "./sections/ShopItemInfo";
import CertificateModal from "components/Modal/CertificateModal";
import { useModal } from "hooks/modalHook";
import LoadingModal from "components/Modal/LoadingModal";
import ContactSupportModal from "components/Modal/ContactSupportModal";

export default function Body() {
    let { ALIAS, ID } = useParams();
    const { addModal, removeModal } = useModal();

    const [item, setItem] = useState();
    const [initalLoading, setInitalLoading] = useState(true);
    useEffect(async () => {
        function restCallback(resp) {
            !!resp && !!resp.data && setItem(resp.data[0])
        }
        setInitalLoading(true)
        await restGet(restCallback, `shop/${ALIAS}/item/${ID}?include=charter`)
        setInitalLoading(false)
    }, [])

    async function validateID(count) {
        const query = new URLSearchParams(window.location.search);
        if (count >= 3) {
            //TODO addModal contact support
            removeModal()
            var params = {};
            if (query.get("voucher_id")) params['voucher_id'] = query.get("voucher_id");
            if (query.get("enrollment_id")) params['enrollment_id'] = query.get("enrollment_id");
            addModal(<ContactSupportModal params={params} />)
            return;;
        }
        if (query.get("success") !== "true") return;;
        addModal(<LoadingModal />)

        if (query.has("voucher_id")) {
            var resp = await restGet(null, 'voucher/' + query.get("voucher_id") + '/validate')
        }
        else if (query.has("enrollment_id")) {
            var resp = await restGet(null, 'enrollment/' + query.get("enrollment_id") + '/validate')
        }
        else {
            return;;
        }
        if (!!resp && !!resp.data && !!resp.data[0]) {
            if (resp.data[0].status === "paid") {
                removeModal()
                addModal(<CertificateModal data={resp.data[0]} />)
            } else {
                await validateID(count + 1)
            }
        } else {
            removeModal()
        }
    }
    //Open CertificateModal if url has voucher_id
    useEffect(async () => {
        await validateID(0)
    }, []);

    if (initalLoading) return <AeroLoading />

    return (
        <Box p={3}>
            <ShopItemHelmet item={item} charter={item?.charter[0]} />
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6} xl={5}>
                    {item && <ShopItemImage item={item} />}
                </Grid>
                <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                    {item && <ShopItemInfo item={item} charter={item?.charter[0]} />}
                </Grid>
            </Grid>
        </Box>
    );
}
