import Box from "components/Box";
import { useCallback, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Cropper from 'react-easy-crop'
import PlaceHolderImage from "assets/images/aircraft.jpg";
import TypographyBlock from "components/Typography/TypographyBlock";
import { getBlobImageUrl } from "lib/functions";
import { getCroppedImg } from "lib/image";
import { Image } from "@mui/icons-material";
import Typography from "components/Typography";
import { Grid, Stack } from "@mui/material";
import Button from "components/Button";
import CancelIcon from '@mui/icons-material/Cancel';
import { useModal } from "hooks/modalHook";
import Cropmodal from "components/Modal/CropperModal";
import _ from "lodash";
export default function ImageTab({ setData, aircraft, tabData }) {
    const { t } = useTranslation()
    const fileInputRef = useRef(null);

    const [aircraftPreviewImage, setAircraftPreviewImage] = useState(PlaceHolderImage);
    const [selectedImages, setSelectedImages] = useState([]);
    const { addModal, removeModal } = useModal();
    const [croppedImg, setcroppedImg] = useState('');
    const onCropComplete = useCallback((croppedArea, areaPixels) => {
        setCroppedArea(croppedArea)
        setCroppedAreaPixels(areaPixels)
    }, [])

    useEffect(() => {
        let tmpImages
        if (tabData) {
            tmpImages = tabData
        } else {
            tmpImages = _.values(aircraft.image)
            tmpImages = tmpImages.map((img) => { return getBlobImageUrl(img) })
        }
        setSelectedImages(tmpImages)
        if (tmpImages[0] != undefined) {
            setAircraftPreviewImage(tmpImages[0])
        }
    }, [])

    const Handlechange = (value) => {
        setcroppedImg(value);
        setAircraftPreviewImage(value)
    };

    const SelectImage = (event) => {
        const selectedImg = event.target.files;
        const selectedImgArray = Array.from(selectedImg);
        const imagesArray = selectedImgArray.map((file) => {
            return URL.createObjectURL(file);
        });
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {

            addModal(<Cropmodal onValueChange={Handlechange} img={imagesArray}></Cropmodal>)

        }
        event.target.value = "";
    };

    useEffect(() => {

        if (croppedImg != "") {
            setSelectedImages((previousImages) => previousImages.concat(croppedImg));
        }
    }, [croppedImg])

    useEffect(() => {
        !!selectedImages && setData(selectedImages)
    }, [selectedImages]);


    function deleteHandler(image) {
        setSelectedImages(selectedImages.filter((e) => e !== image));
        URL.revokeObjectURL(image);
        setAircraftPreviewImage(_.nth(selectedImages.filter((e) => e !== image), 0));
        if (selectedImages.length == 1) {

            setAircraftPreviewImage(PlaceHolderImage)
        }

    }
    const handleClickBrowse = () => {
        fileInputRef.current.click();
    };


    const Drag = (event, index) => {
        event.dataTransfer.setData('text/plain', index);
    };

    const Dragover = (event, index) => {
        event.preventDefault();
    };

    const Drop = (event, dropIndex) => {
        event.preventDefault();
        const draggedIndex = event.dataTransfer.getData('text/plain');
        const updatedImages = [...selectedImages];
        const draggedImage = updatedImages[draggedIndex];
        updatedImages.splice(draggedIndex, 1);
        updatedImages.splice(dropIndex, 0, draggedImage);
        setSelectedImages(updatedImages);
    };

    const Click = (image) => {
        setAircraftPreviewImage(image);
    }

    return (
        <Box p={2}>
            <TypographyBlock
                title={"Image upload"}
            />
            <Stack alignItems="center">

                <img src={aircraftPreviewImage} style={{ overflow: "hidden", width: "100%" }} alt="upload" />

                <Grid mt={4} p={1} container sx={{ height: "auto" }} justifyContent="space-between" alignItems="center">

                    <Grid item xs={12} sm={9} p={3} sx={{ height: "100%" }}>
                        <Box sx={{ display: "flex", overflowX: "auto", scrollBehavior: "smooth", }}>
                            {selectedImages.map((image, index) => (
                                <Box
                                    key={image}
                                    ml={1}
                                    sx={{ position: "relative" }}
                                    draggable
                                    onDragStart={(e) => Drag(e, index)}
                                    onDragOver={(e) => Dragover(e, index)}
                                    onDrop={(e) => Drop(e, index)}
                                >
                                    <img onClick={() => Click(image)} src={image} height="100" alt="upload" />
                                    <CancelIcon
                                        fontSize="medium"
                                        onClick={() => deleteHandler(image)}
                                        sx={{ position: "absolute", top: "1px", right: "1px", cursor: "pointer" }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={3} p={3} sx={{ border: 2, borderRadius: "8px", height: "100%" }} onClick={handleClickBrowse}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                            <Image fontSize="large" mb={2} sx={{ marginBottom: "5px" }}></Image>
                            <Typography fontSize="12px">Add More Images</Typography>

                            <Typography sx={{ textAlign: "center" }} fontSize="10px" variant="button" component="span">
                                Click to browse , and order by dragging
                            </Typography>
                            <input
                                ref={fileInputRef}
                                type="file"
                                name="images"
                                onChange={SelectImage}
                                accept="image/png ,image/jpg,image/jpeg"
                                hidden
                            />
                        </Box>

                    </Grid>




                </Grid>
            </Stack>
        </Box >
    )
}
