import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AeroLoading from "components/Progress/AeroLoading";

export default function VoucherPage() {
    const { t } = useTranslation();
    return (
        <KitLayout header={<Header />} title={t("routes:redeem.title")}>
            <Body />
        </KitLayout>
    )
}

export function MinimalVoucherPage() {
    const [voucherDefaultCode, setVoucherDefaultCode] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const query = new URLSearchParams(window.location.search);
        if (query.has("backgroundcolor")) document.body.style.backgroundColor = '#' + query.get("backgroundcolor")
        if (query.has("border")) document.body.style.backgroundColor = query.get("border")

        if (query.has("voucher_code")) setVoucherDefaultCode(query.get("voucher_code"))
        setIsLoading(false)
    }, []);

    if (isLoading) return <AeroLoading />

    return <Body external defaultCode={voucherDefaultCode} />
}