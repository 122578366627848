import { useState, useEffect, useRef } from "react";
import { Card, Grid, AppBar, Tabs, Tab } from "@mui/material";
import Box from "components/Box";
import Avatar from "components/Avatar";
import TypographyBlock from "components/Typography/TypographyBlock";
import { useUser } from "hooks/userDataHook";
import placeholderAvatar from "assets/images/avatar.png"
import { useModal } from "hooks/modalHook";
import { getBlobImageUrl } from "lib/functions";
import Typography from "components/Typography";
import Servicemodal from "components/Modal/ServiceModal";
import ServiceTable from "components/Tables/ServiceTable";
import { restGet } from "lib/rest";
import moment, { duration } from "moment";

function Overview() {
    const [avatar, setAvatar] = useState(placeholderAvatar);
    const [header, setHeader] = useState("https://media.westeurope.aero-services.net/aeromate/header/rough-horn-2146181_1920.jpg");
    const { data } = useUser();
    const { addModal, removeModal } = useModal();
    const [service, setService] = useState([]);
    const [aircraft, setAircraft] = useState();
    const [teclog, setTeclog] = useState();
    const [rows, setRows] = useState();
    const [landings, setLandings] = useState();



    useEffect(() => {
        if (!!data?.image?.Avatar) {
            setAvatar(getBlobImageUrl(data.image.Avatar))
        }
    }, []);

    const handleSave = () => {

        addModal(<Servicemodal></Servicemodal>)


    }

    useEffect(() => {
        const fetchService = async () => {
            function restCallback(resp) {
                !!resp && resp.data.map(obj => {
                    setService(rows => [...rows, obj.data]);
                })
            }
            await restGet(restCallback, 'service/D-ESAD/type')
        }
        fetchService()
    }, [])

    useEffect(() => {
        const fetchTeclog = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setTeclog(resp.data)
            }
            await restGet(restCallback, 'Teclog/D-ESAD/Latest')
        }
        fetchTeclog()
    }, [])

    useEffect(() => {
        const fetchLandings = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setLandings(resp.data)
            }
            await restGet(restCallback, 'Teclog/D-ESAD/Landings')
        }
        fetchLandings()
    }, [])

    useEffect(() => {
        const fetchAircraft = async () => {
            function restCallback(resp) {
                !!resp && !!resp.data && setAircraft(resp.data)
            }
            await restGet(restCallback, 'Aircraft/D-ESAD')
        }
        fetchAircraft()
    }, [])

    useEffect(() => {
        if (!!aircraft && !!teclog) {           
            var date1 = moment(teclog?.flight_data[teclog?.flight_data?.length -1]?.atot)
            var date2 = moment(teclog?.flight_data[teclog?.flight_data?.length -1]?.aldt)
            const duration = moment.utc(moment.duration(date2.diff(date1)).asMilliseconds()).format("HH:mm");

            setRows( {
                aircraft: { AircraftName: { icon: "home", text: aircraft[0].reg }, Model: { icon: "flight", text: aircraft[0].name }, Ort: { icon: "place", text: aircraft[0].homebase } },
                Warnings: { Warnings: { icon: "handyman", prio: "1" } },
                LastFlight: { Landings: { icon: "flight", text: teclog?.flight_data[teclog?.flight_data?.length -1]?.ldg }, Duration: { icon: "timer", text: duration }, Date: { icon: "event", text: moment(teclog?.flight_data[teclog?.flight_data?.length -1]?.dof).format("L") } },
                CurrentFlight: { Landings: { icon: "flight", text: landings }, Totaltime: { icon: "timer", text: teclog?.ops_data?.ops_counter_end }, Date: { icon: "event", text: moment(Date.now()).format("L") } },
                NextService: { one: { icon: "av_timer", text: "13:09:2023" }, two: { icon: "av_timer", text: "230:00" }, three: { icon: "av_timer", text: "230:00" } },
                serviceDue: { days: { icon: "event", text: "13:09:2023" }, hours: { icon: "timer", text: "230:00h" } }

            })
        }
    }, [aircraft, teclog]);

    return (
        <Box position="relative" mb={5}>
            <Box
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{
                    backgroundImage: `url(${header})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}


            />
            <Card
                sx={{
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <Box sx={{ position: "relative" }}>
                            <Avatar src={avatar} alt="profile-image" size="xl" shadow="sm" />
                        </Box>


                    </Grid>
                    <Grid item>
                        <Box height="100%" mt={0.5} lineHeight={1}>
                            <Typography variant="h5" fontWeight="medium">
                                air
                            </Typography>
                        </Box>
                    </Grid>


                </Grid>
            </Card>
            <Box pt={2} px={2} lineHeight={1.25}>

            </Box>
            <Box p={2}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={12} xl={12}>
                        <Card>
                            <TypographyBlock
                                title={"Add Service"}
                                description={"Enter your Service Data"}
                                action={handleSave}
                                actionName={"add"}
                            >
                            </TypographyBlock>
                            {rows && <Box p={3} >
                                    <ServiceTable columns={["Aircraft", "Warnings", "Last Flight", "Total Flights", "Next Service", "Service Due"]}
                                    rows={[rows]} teclog={teclog} service={service} aircraft={aircraft}>


                                </ServiceTable>
                            

                            </Box>}

                        </Card>

                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Overview;