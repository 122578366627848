import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { restGet } from 'lib/rest';
import Input from "components/Input";

export default function CustomerSearch({ label, onChange, reg, alias, staffOnly, ...rest }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        if (searchTerm !== "")
            setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            (async () => {
                const callback = (res) => {
                    if (!!res && !!res.data && Array.isArray(res.data))
                        setOptions(res.data.map(cust => {
                            return {
                                value: cust.identity._id.$oid,
                                label:
                                    cust.identity.name +
                                    " (" +
                                    cust.identity.email +
                                    ")[" +
                                    (staffOnly ? cust.role : (cust.status ?? "nocheckride")) +
                                    "]" 
                            }
                        }))
                }
                let param = { fuzzy: searchTerm };
                if (reg) param.reg = reg;
                if (alias) param.alias = alias;
                if (staffOnly) param.staff_only = staffOnly;
                await restGet(callback, "customer", param);
                setLoading(false)
            })();
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm, reg, alias, staffOnly])

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };


    return (
        <Autocomplete
            {...rest}
            id="asynchronous-demo"
            open={open}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <Input
                    {...params}
                    value={searchTerm}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}