import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Box from "components/Box";
import { useTranslation } from "react-i18next";
import ComingSoon from "components/Common/ComingSoon";
import AirportRunways from "components/Airport/AirportRunways";
import AirportNearest from "components/Airport/AirportNearest";
import AirportMap from "components/Airport/AirportMap";
import AirportAircrafts from "components/Airport/AirportAircrafts";
import { useLocation } from "react-router-dom";
import AirportReviewTab from "./AirportReviewsTab";

function AirportTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="AirportTabPanel"
            hidden={value !== index}
            id={`simple-AirportTabPanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index &&
                children
            }
        </div>
    );
}

function AirportTabs({ airport }) {
    const { t } = useTranslation();
    const { hash } = useLocation();
    const getTab = () => {
        switch (hash) {
            case "#recensions": return (0)
            case "#runways": return (1)
            case "#nearest": return (2)
            case "#map": return (3)
            case "#aircrafts": return (4)
            default: return (0)
        }
    }
    const [activeTab, setActiveTab] = useState(getTab());

    const handleTabType = (event, newValue) => {
        setActiveTab(newValue);
        window.location.hash = "#" + event.target.id
    }

    return (
        <Box>
            <AppBar position="static">
                <Tabs variant="fullWidth" value={activeTab} onChange={handleTabType} >
                    <Tab label={t('common:label.recensions')} icon={<Icon>reviews</Icon>} id="recensions" />
                    <Tab label={t('common:label.runways')} icon={<Icon>flight_takeoff</Icon>} id="runways" />
                    <Tab label={t('common:label.nearest')} icon={<Icon>auto_stories</Icon>} id="nearest" />
                    <Tab label={t('common:label.map')} icon={<Icon>location_on</Icon>} id="map" />
                    <Tab label={t('common:label.aircrafts')} icon={<Icon>flight</Icon>} id="aircrafts" />
                </Tabs>
            </AppBar>
            <AirportTabPanel value={activeTab} index={0}>
                <Box p={3} lineHeight={1}>
                    <AirportReviewTab airport={airport} />
                </Box>
            </AirportTabPanel>
            <AirportTabPanel value={activeTab} index={1}>
                <Box p={3} lineHeight={1}>
                    <AirportRunways airport={airport} />
                </Box>
            </AirportTabPanel>
            <AirportTabPanel value={activeTab} index={2}>
                <Box p={3} lineHeight={1}>
                    <AirportNearest airport={airport} />
                </Box>
            </AirportTabPanel>
            <AirportTabPanel value={activeTab} index={3}>
                <Box p={3} lineHeight={1}>
                    <AirportMap airport={airport} />
                </Box>
            </AirportTabPanel>
            <AirportTabPanel value={activeTab} index={4}>
                <Box p={3} lineHeight={1}>
                    <AirportAircrafts airport={airport} />
                </Box>
            </AirportTabPanel>
        </Box>
    );
}

export default AirportTabs;