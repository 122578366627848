import KitLayout from "aeromate-kit/KitLayout";
import Header from "./Header";
import Body from "./Body";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FeatureHelmet from "components/Helmets/FeatureHelmet";

export default function Featurepages() {
    const params = useParams();
    const { t } = useTranslation();

    const features = t("FeaturePage:cards", { returnObjects: true })
    let content = features.find(o => o.link === params.pagename);

    return (
        <KitLayout header={<Header title={params.pagename} />} >
            <FeatureHelmet props={content?.keywords}></FeatureHelmet>
            <Body content={content} />
        </KitLayout>
    )
}
